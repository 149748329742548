/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ButtonGroup, ListGroup } from "react-bootstrap";
import PayrollEntry from "../Models/PayrollEntry";
import moment from "moment";


export default function PayrollEmployeeSelector({entries, employeeFilter, setEmployeeFilter, hideModal, originalReviewPayrollPeriod, handleAddEntries, originalPreviousPeriodArray, periodStart, periodEnd}) {

    const [listSwitch, setListSwitch] = useState(true);
    const [selectedUsers, setSelectedUsers] = useState([]);


    const handleSelectingUser = (companyUserIdentifier) =>{
        let newArr = Array.from(selectedUsers);
        if(newArr.includes(companyUserIdentifier)){
            newArr = newArr.filter((i)=> i !== companyUserIdentifier);
        } else {
            newArr.push(companyUserIdentifier);
        }
        setSelectedUsers(newArr);
        handleSetEmployeeFilter(companyUserIdentifier)
    }

    const handleSetEmployeeFilter = (companyUserIdentifier) => {
        let newArray = Array.from(employeeFilter);
        if (employeeFilter.includes(companyUserIdentifier)) {
            newArray = newArray.filter(r => r !== companyUserIdentifier);
        } else {
            newArray.push(companyUserIdentifier);
        }
        setEmployeeFilter(newArray);
    }

    const handleGenerateEntries = (companyUserIdentifierArray) =>{
        const entryArray = [];

        companyUserIdentifierArray.forEach((id)=>{
            const timesheetData = originalReviewPayrollPeriod.timesheetData.filter(r => r.companyUserIdentifier === id);
            let userPayData = originalReviewPayrollPeriod.payData.filter((p)=>p.companyUserIdentifier === id);
            let user = originalReviewPayrollPeriod.userData.find((u)=> u.companyUserUid === id);
            userPayData.sort((a, b) => {
                if (moment(a.date).isBefore(moment(b.date), 'day')) {
                    return -1;
                } else if (moment(a.date).isAfter(moment(b.date), 'day')) {
                    return 1;
                } else {
                    return a.uid - b.uid;
                }
            });
            
            userPayData = userPayData.filter((p)=>p.payType !== 'termination');
                
                
            // if (userPayData.find(p => p.title === 'AO')) { //exclude AOs
            //     return;
            // }
    
            const ptoBalance = originalReviewPayrollPeriod.ptoBalanceData[user.companyUserUid.toString()];
            const vehicleWeights = originalReviewPayrollPeriod.vehicleWeights.filter(r => r.companyUserIdentifier === user.companyUserUid);
            const csa = originalReviewPayrollPeriod.csas.find(csa => csa.uid === user.csaIdentifier);

            const previousEntry = originalPreviousPeriodArray?.find(r => r.companyUserIdentifier == user.companyUserUid);
            const isNew = originalPreviousPeriodArray && !previousEntry;
            const ptoData = originalReviewPayrollPeriod.pto.filter(si => si.companyUserIdentifier === user.companyUserUid);
            const loanData = originalReviewPayrollPeriod.loanData.filter(l => l.companyUserIdentifier == user.companyUserUid);
            const ticketData = originalReviewPayrollPeriod.ticketData.filter(t => t.companyUserIdentifier == user.companyUserUid);
            
            const entry = PayrollEntry.generate({
                user: user,
                vehicleWeights: vehicleWeights,
                payData: userPayData,
                timesheetData: timesheetData,
                ptoData: ptoData,
                loanData: loanData,
                ticketData: ticketData,
                previousEntry: previousEntry,
                isNew: isNew,
                csaName: csa.csaName,
                periodStart: periodStart,
                periodEnd: periodEnd,
                ptoBalance: ptoBalance,
                settlementReportData: originalReviewPayrollPeriod.settlementReportData,
                autoIncentiveWageAmount: originalReviewPayrollPeriod.autoIncentiveWageAmount,
                autoIncentiveWageThreshold: originalReviewPayrollPeriod.autoIncentiveWageThreshold,
                customIncentiveWages: originalReviewPayrollPeriod.customIncentiveWages,
                autoOvertimeThreshold: originalReviewPayrollPeriod.autoOvertimeThreshold, 
                autoOvertimeAmount: originalReviewPayrollPeriod.autoOvertimeAmount
            });
            entryArray.push(entry);
        });
        handleAddEntries(entryArray);
    }
   
    const rows = entries.sort((a,b)=>a.csaName.localeCompare(b.csaName)).map((entry) => {
        const isSelected = employeeFilter.includes(entry.companyUserIdentifier);
        
        return (
            <ListGroup.Item style={{display: 'flex', justifyContent: 'space-between', gap: 6}} key={entry.companyUserIdentifier} action active={isSelected} onClick={() => {handleSetEmployeeFilter(entry.companyUserIdentifier)}}>
                <span>{entry.lastName + ', ' + entry.firstName + ' ' +(entry.middleName ? entry.middleName.substring(0,1) : '')}</span>
                <span>{entry.csaName}</span>
            </ListGroup.Item>
        )
    });

  

    const usersNotInEntries = originalReviewPayrollPeriod.userData.sort((a,b)=>a.lastName.localeCompare(b.lastName)).filter((user) => user.isAdministrator === '0' && !entries.find((e) => e.companyUserIdentifier === user.companyUserUid)).map((user) => {
        const isSelected = employeeFilter.includes(user.companyUserUid);
        
        return (
            <ListGroup.Item style={{ display: 'flex', justifyContent: 'space-between', gap: 6 }} key={user.companyUserUid} action active={isSelected} onClick={() => {handleSelectingUser(user.companyUserUid)}}>
                <span>{user.lastName + ', ' + user.firstName + ' ' + (user.middleName ? user.middleName.substring(0,1) : '') + (user.companyUserIsEnabled === '0' ? ' (TERMINATED)' : '')}</span>
                <span>{user.csaName}</span>
            </ListGroup.Item>
        )
    });
    return (
        <>
            <Modal.Header style={{flexDirection:'column'}}>
                <Modal.Title>Select Employees to Include in Payroll</Modal.Title>
                {usersNotInEntries.length > 0 &&
                <>
                    <ButtonGroup style={{marginTop:5}}>
                        <Button style={listSwitch ? {color: 'white'} : {color: 'var(--bs-primary)', backgroundColor: 'white'}}  onClick={()=>setListSwitch(true)}>Employee Filter</Button>
                        <Button style={!listSwitch ? {color: 'white'} : {color: 'var(--bs-primary)', backgroundColor: 'white'}}  onClick={()=>setListSwitch(false)}>Add Other Employees</Button>
                    </ButtonGroup>
                </>
                }
            </Modal.Header>
            <Modal.Body>
                {listSwitch ?
                <ListGroup>
                    {rows}
                </ListGroup>
                :
                <ListGroup>
                    {usersNotInEntries}
                </ListGroup>
                }
            </Modal.Body>
            <Modal.Footer>
                {!listSwitch && <Button variant={'outline-primary'} onClick={()=>{handleGenerateEntries(selectedUsers);hideModal()}}>Submit</Button>}
            </Modal.Footer>
        </>
    )
}