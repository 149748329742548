/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import PageSpinner from '../../../components/PageSpinner';
import QuickTable from '../../../components/QuickTable';
import CustomControl from '../../../components/CustomControl';
import CustomButton from '../../../components/CustomButton';
import Big from 'big.js';
import { Container, Modal, Row } from 'react-bootstrap';
import { bigToUsd, validateBig, validateUsd } from '../../Payroll/payrollTools';
import moment from 'moment';
import { usdFormatter} from '../../../tools';
import AlertModal from '../../../components/AlertModals/AlertModal';
import SwitchControl from '../../../components/SwitchControl';
import HRUserTicketInstallmentModal from './HrUserTicketInstallmentModal';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApiRequest } from '../../../ApiManager.tsx';

export default function HRUserTicketEditor({companyUserIdentifier, selectedTicket, handleTicketCrud}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [name, setName] = useState('');
    const [amount, setAmount] = useState(0);
    const [installment, setInstallment] = useState(0);
    const [installments, setInstallments] = useState([]);
    const [deductFromSafetyBonuses, setDeductFromSafetyBonuses] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        if (selectedTicket) {
            loadData();
        }
    }, []);

    function loadData(){
        new ApiRequest('hr', 'getTicket', setIsLoading, (response) => {
            setName(response.ticket.name);
            setAmount(response.ticket.amount);
            setInstallment(response.ticket.installment);
            setInstallments(response.ticket.installments.sort(sortInstallments));
            setDeductFromSafetyBonuses(response.ticket.deductFromSafetyBonuses);
        }).withUid(selectedTicket).withNoAlertOnSuccess().send()
    }

    function handleSubmit(){
        const ticket = {
            uid: selectedTicket,
            name: name,
            companyUserIdentifier: companyUserIdentifier,
            amount: validateUsd(amount),
            installment: validateUsd(installment),
            deductFromSafetyBonuses: deductFromSafetyBonuses,
            installmentSum: 0,
        }

        if(selectedTicket){
            new ApiRequest('hr', 'updateTicket', setIsSubmitting, () => {handleTicketCrud('update', ticket)}).withData({ticket: ticket}).send();
        } else {
            new ApiRequest('hr', 'createTicket', setIsSubmitting, (response) => {ticket.uid = response.uid; handleTicketCrud('create', ticket)}).withData({ticket: ticket}).send();
        }
    }

    function deleteTicket(){
        new ApiRequest('hr', 'deleteTicket', setIsDeleting, () => handleTicketCrud('delete', selectedTicket)).withUid(selectedTicket).send();
    }

    const handleCreateInstallment = (installment) => {
        const newArray = Array.from(installments);
        newArray.push(installment);
        setInstallments(newArray.sort(sortInstallments));
        setShowModal(false);
        handleTicketCrud('installment', installment);
    }

    const sortInstallments = (a, b) => {
        if (a.payrollPeriod?.periodStart ?? a.date < b.payrollPeriod?.periodStart ?? b.date) {
            return -1;
        } else if(a.payrollPeriod?.periodStart ?? a.date > b.payrollPeriod?.periodStart ?? b.date) {
            return 1;
        } else
        return 0;
    }

    const payments = installments.map((i, index) => {
        let totalPaid = 0;
        for (let i = 0; i <= index; i++) {
            totalPaid += validateUsd(installments[i].amount);
        }
        let totalRemaining = validateUsd(amount) - totalPaid;
        return (
            <tr key={i.uid}>
                <td>{i.date ? moment(i.date).format('MMM D, YYYY') : moment(i.payrollPeriod.periodStart).format('MMM D, YYYY') + ' - ' + moment(i.payrollPeriod.periodEnd).format('MMM D, YYYY')}</td>
                <td>{usdFormatter.format(i.amount)}</td>
                <td>{usdFormatter.format(totalPaid)}</td>
                <td>{usdFormatter.format(totalRemaining)}</td>
            </tr>
        )
    });

    const amountPaid = installments.reduce((prev, curr) => prev.plus(new Big(curr.amount)), new Big('0.00'));
    const amountRemaining = validateBig(amount).sub(installments.reduce((prev, curr) => prev.plus(new Big(curr.amount)), new Big('0.00')));

    return (
        <>
            <Modal.Header closeButton>
                <div style={{display:'flex', gap:12}}>
                    <Modal.Title>{selectedTicket ? 'View/Edit Ticket' : 'Create Ticket'}</Modal.Title>
                    { selectedTicket && 
                        <CustomButton label={'Delete Ticket'} onClick={()=>setShowDeleteModal(true)}/>
                    }
                </div>
            </Modal.Header>
            <Modal.Body>
                { isLoading ? <PageSpinner/> : 
                    <>
                        <Container fluid>
                            <Row>
                                <CustomControl lg={6} type='text' title='Ticket Name' maxLength={30} value={name} onChange={(event)=>{setName(event.target.value)}}/>
                                <CustomControl lg={6} type='number' title='Amount Borrowed' maxDecimal={99999.99} value={amount} onChange={(event)=>{setAmount(event.target.value)}}/>
                                <CustomControl lg={6} type='number' title='Installment Amount' maxDecimal={99999.99} value={installment} onChange={(event)=>{setInstallment(event.target.value)}}/>
                                <SwitchControl lg={6} title={<>Deduct From Safety Bonuses? <DeductFromSafetyBonusesExplaination/></>} value={deductFromSafetyBonuses} setValue={setDeductFromSafetyBonuses}/>
                            </Row>
                        </Container>
                        
                        { selectedTicket &&
                            <>
                                <div style={{display:'flex', flexDirection:'column', gap:10}}>
                                    <span style={{fontWeight:'bold'}}>{'Total Amount Paid: ' + bigToUsd(amountPaid)}</span>
                                    <span style={{marginTop:10, marginBottom:10, fontWeight:'bold'}}>{'Total Amount Remaining: ' + bigToUsd(amountRemaining)}</span>
                                </div>
                                <QuickTable title='Ticket Payments' headers={['Payroll Period', 'Payment', 'Running Total', 'Running Ticket Balance']} rows={payments} size='sm'/>
                            </> 
                        }
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
            { isLoading ? <></> : 
                <>
                    { selectedTicket && 
                        <CustomButton isLoading={isLoading} label='Create Manual Installment' onClick={() => {setShowModal(true)}}/>
                    }
                    <CustomButton isLoading={isSubmitting || isLoading} label={selectedTicket ? 'Save Changes' : 'Create Ticket'} onClick={handleSubmit}/>
                </>
            }   
            </Modal.Footer>
            <Modal show={showDeleteModal} onHide={() => {setShowDeleteModal(false)}} >
                <AlertModal 
                    isLoading={isDeleting}
                    variant='outline-danger'
                    title='Delete Ticket?' 
                    message='This ticket data will not be recoverable upon deletion. Please be sure you want to delete this ticket.' 
                    callBack={deleteTicket}
                    buttonLabel='Delete Ticket'
                />
            </Modal>
            <Modal show={showModal} onHide={() => {setShowModal(false)}}>
                <HRUserTicketInstallmentModal ticketIdentifier={selectedTicket} handleCreateInstallment={handleCreateInstallment}/>
            </Modal>
        </>
    );
}

function DeductFromSafetyBonusesExplaination() {
    const [show, setShow] = useState(false);

    return (
        <>
            <FontAwesomeIcon className="cursor-pointer" onClick={() => {setShow(true)}} style={{color: 'var(--bs-primary)'}} icon={faQuestionCircle}/>
            <Modal show={show} onHide={() => {setShow(false)}} centered size="lg">
                <Modal.Header>
                    <Modal.Title>Deduct From Safety Bonuses</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>If enabled, the installment will only be deducted from the employee's <i>Safety Bonuses</i> when running payroll, otherwise, the installment will NOT be paid.</p>
                </Modal.Body>
            </Modal>
        </>
    )
}