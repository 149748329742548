/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Modal, Button } from 'react-bootstrap'
import ApiManager from '../ApiManager.tsx';

export default function ApiTimeoutModal({hideModal}) {
    return (
        <>
            <Modal.Header>
                <Modal.Title>Request Timeout</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Please ensure you are connected to the internet
            </Modal.Body>
            <Modal.Footer>
                <Button variant='outline-danger' onClick={hideModal}>
                    Dismiss
                </Button>
                <Button variant='outline-primary' onClick={() => {ApiManager.instance.resendRequest(); hideModal();}}>
                    Retry
                </Button>
            </Modal.Footer>
        </>
    )
}