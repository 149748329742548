/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import moment from "moment";
import Button from 'react-bootstrap/Button';
import { dateIsInRange, dateRangeToString } from "../../tools";
import DateRangeSelector from "../../components/DateRangeSelector";
import SearchBar from "../../components/SearchBar";
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import MyCompanyNotesModal from "./MyCompanyNotesModal";
import LoadingWrapper from "../../components/LoadingWrapper";
import { ApiRequest } from "../../ApiManager.tsx";
import Cookies from "universal-cookie";

export default function MyCompanyNotes({}){
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [notes, setNotes] = useState([]);
    const [filter, setFilter] = useState('');
    const [filterType, setFilterType] = useState('title');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [onlyShowAuthoredNotes, setOnlyShowAuthoredNotes] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedNote, setSelectedNote] = useState(undefined);
    const [showDateRangeFilter, setShowDateRangeFilter] = useState(false);
    const cookieMonster = new Cookies();

    useEffect(()=>{
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('myCompany', 'getCompanyNotes', setIsLoading, (response) => {setNotes(response.data); setUsers(response.users)}).withNoAlertOnSuccess().send();
    }

    function sortNotes(a, b) {
        if (moment(a.submissionDate).isBefore(moment(b.submissionDate), 'days')) {
            return 1;
        } else if (moment(a.submissionDate).isAfter(moment(b.submissionDate), 'days')) {
            return -1;
        } 
        return 0;
    }

    function handleAddNote(note) {
        const newArray = Array.from(notes);
        newArray.push(note);
        setNotes(newArray.sort(sortNotes));
    }

    function handleSetDateRange(startDate, endDate) {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    const filteredNotes = notes.filter((note) => {
        const isInRange = dateIsInRange(note.submissionDate, startDate, endDate);
        const isInSearch = !filter || note[filterType].toLocaleLowerCase().includes(filter.toLocaleLowerCase());
        const obeysOnlyShowAuthoredNotes = !onlyShowAuthoredNotes || cookieMonster.get('userData').companyUserUid == note.author;
        return isInRange && isInSearch && obeysOnlyShowAuthoredNotes;
    })

    const noteElements = filteredNotes.map((note) => {
        const author = users.find(u => u.companyUserUid == note.author);

        return (
            <Card key={note.uid} className='cursor-pointer' onClick={() => {setSelectedNote(note); setShowModal(true)}}>
                <Card.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                    <Card.Title>{note.title}</Card.Title>
                    <div style={{backgroundColor: 'rgba(0, 0, 0, 0.05)', borderRadius: 6, padding: 6}}>
                        <span>{note.body.length > 200 ? (note.body.slice(0, 200) + '...') : note.body}</span>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <span style={{opacity: 0.5}}>{(note.lastModified ? 'Last edited on ' + moment(note.lastModified).format('MMM D, YYYY') : 'Created on ' + moment(note.submissionDate).format('MMM D, YYYY'))}</span>
                        <span style={{color: 'var(--bs-primary)'}}>{'- ' + author.firstName + ' ' + author.lastName}</span>
                    </div>
                </Card.Body>
            </Card>
        )
    });

    const content = (
        <>
            <div style={{display: 'flex', gap: 12, alignItems: 'center', marginBottom: 12}}>
                <SearchBar 
                    label='Filter by...' 
                    value={filter} 
                    setValue={(value) => setFilter(value)} 
                    typeNames={['Title', 'Content']} 
                    typeValues={['title', 'body']} 
                    type={filterType} 
                    setType={(value) => setFilterType(value)}
                />
                <Button variant={'outline-primary'} style={{flexShrink: 0}} onClick={() => setShowDateRangeFilter(true)}>{'Submission Date Filter: ' + dateRangeToString(startDate, endDate)}</Button>
                <div style={{flexShrink: 0}}>
                    <Form.Check type='switch' label='Only Show My Notes' value={onlyShowAuthoredNotes} onChange={() => setOnlyShowAuthoredNotes(!onlyShowAuthoredNotes)}/>
                </div>
                <Button variant={'outline-primary'} style={{flexShrink: 0}} onClick={() => {setShowModal(true); setSelectedNote(undefined)}}>Create Note</Button>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                {noteElements}
            </div>
            <DateRangeSelector show={showDateRangeFilter} onHide={() => setShowDateRangeFilter(false)} centered handleSetDateRange={handleSetDateRange} presetOptions={['pastYear', 'pastMonth', 'pastWeek', 'allTime']}/>
        </>
    );

    return (
        <>
            <LoadingWrapper isLoading={isLoading}>
                {content}
            </LoadingWrapper>
            <Modal show={showModal} onHide={() => setShowModal(false)} size='lg'>
                <MyCompanyNotesModal onHide={() => setShowModal(false)} note={selectedNote} handleAddNote={handleAddNote}/>
            </Modal>
        </>
    )
}