/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/


import React, { useEffect, useState } from "react";
import {usdFormatter} from "../../../../../tools";
import {ListGroup,Tabs, Tab, Button } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import KeyValueRow from "../../../../../components/KeyValueRow";
import moment from "moment";
import { payTypeDictionary } from "../../../payrollTools";

export default function PayInfo({entry, handleSet, payData}){
    const [weeksImported, setWeeksImported] = useState([]);

    const handleSettingNotes = (notes, index) =>{
        const newNotes = entry.notes + ' ' + notes;
        handleSet(entry.companyUserIdentifier, 'notes', newNotes);
        const newArray = Array.from(weeksImported);
        newArray.push(index);
        setWeeksImported(newArray);
    }


    const payInfo = payData.sort((a,b)=>{
        if (moment(a.date).isBefore(moment(b.date), 'days')) {
            return -1;
        } else if (moment(a.date).isAfter(moment(b.date), 'days')) {
            return 1;

        }else if(moment(a.date).isSame(moment(b.date),'day')){
            if(a.uid < b.uid){
                return -1;
            } else {
                return 1;
            }
        } else {
            return 0;
        }
    }).map((pay, index)=>{
        return (
            <Tab key={index} title={moment(pay.date).format('MMM D, YYYY')} eventKey={index}>
                <div style={{display:'flex', gap:12 }}>
                    <div style={{width:'50%'}}>
                        <ListGroup>
                            <KeyValueRow forceRow isListItem title='Effective Date' value={moment(pay.date).format('MMM D, YYYY')} />
                            <KeyValueRow forceRow isListItem title='Title' value={pay.title} />
                            <KeyValueRow forceRow isListItem title='BWC Code' value={pay.bwcCode} />
                            <KeyValueRow forceRow isListItem title='Pay Rate' value={usdFormatter.format(pay.payRate)} />
                            <KeyValueRow forceRow isListItem title='Pay Type' value={payTypeDictionary[pay.payType]} />
                            <KeyValueRow forceRow isListItem title='Hourly Wage' value={usdFormatter.format(pay.hourlyWage)} />
                            <KeyValueRow forceRow isListItem title='PTO Accrual (Per 40 Hours)' value={pay?.ptoAccrual || 0}/>
                            <KeyValueRow forceRow isListItem title='Title' value={pay.title} />
                            <KeyValueRow forceRow isListItem title='Weekday Stop Threshold' value={pay?.stopBonusWeekdayThreshold || 0} />
                            <KeyValueRow forceRow isListItem title='Weekend Stop Threshold' value={pay?.stopBonusWeekendThreshold || 0} />
                            <KeyValueRow forceRow isListItem title='Weekday Stop Wage Dollar Amount' value={usdFormatter.format(pay?.stopBonusAmount || 0)} />
                            <KeyValueRow forceRow isListItem title='Weekend Stop Wage Dollar Amount' value={usdFormatter.format(pay?.stopBonusWeekendAmount || 0)}/>
                            <KeyValueRow forceRow isListItem title='Employee Type' value={pay.employeeType === '0' ? 'Full-Time' : 'Part-Time'} />
                            <KeyValueRow forceRow isListItem title='Medical Insurance' value={usdFormatter.format(pay?.medical|| 0)}/>
                            <KeyValueRow forceRow isListItem title='Dental Insurance' value={usdFormatter.format(pay?.dental || 0)}/>
                            <KeyValueRow forceRow isListItem title='Vision Insurance' value={usdFormatter.format(pay?.vision || 0)}/>
                            <KeyValueRow forceRow isListItem title='Child Support' value={pay.childSupport ? usdFormatter.format(JSON.parse(pay.childSupport).reduce((prev,curr)=>{
                                return prev + parseFloat(curr);
                            },0)) : '$0.00'}/>
                            <KeyValueRow forceRow isListItem title='Automatic Reimbursements' value={pay.automaticReimbursement? usdFormatter.format(JSON.parse(pay.automaticReimbursement).reduce((prev,curr)=>{
                                return prev + parseFloat(curr.amount);
                            },0)) : '$0.00'}/>
                            <KeyValueRow forceRow isListItem title='Automatic Deductions (Flat Rate)' value={pay.automaticDeductions ? usdFormatter.format(JSON.parse(pay.automaticDeductions).filter((d)=>d.label !== '401K (% of Gross)' && d.label !== 'Other (% of Gross)').reduce((prev,curr)=>{
                                return prev + parseFloat(curr.amount);
                            },0)) : '$0.00'}/>
                            <KeyValueRow forceRow isListItem title='Automatic Deductions (% of Gross)' value={pay.automaticDeductions ? usdFormatter.format(JSON.parse(pay.automaticDeductions).filter((d)=>d.label === '401K (% of Gross)' || d.label === 'Other (% of Gross)').reduce((prev,curr)=>{
                                return prev + parseFloat(curr.amount);
                            },0)) + '%' : '0%'}/>
                        </ListGroup>
                    </div>
                    <div style={{flex: 1, padding: 12, border: '1px lightgray solid', borderRadius: 6}}>
                        { pay.payType !== 'termination' ?
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <span>{pay.notes}</span>
                                <hr/>
                                {weeksImported.includes(index) ? 
                                    <span style={{color:'green'}}>Notes Successfully Imported</span>
                                    :
                                    <Button variant={'outline-primary'} onClick={()=>handleSettingNotes(pay.notes, index)}>Import Notes To Payroll</Button>
                                }
                            </div>
                            :
                            <div style={{display:'flex', flexDirection:'column'}}>
                                <span>{'This Employee was terminated on ' + moment(pay.date).format('MMM D, YYYY')}</span>
                            </div>
                        }
                    </div>
                </div>
            </Tab>
        )     
    });


    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{'Pay Effective From ' + moment(entry.periodStart).format('MMM D, YYYY') + ' - ' + moment(entry.periodEnd).format('MMM D, YYYY')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs defaultActiveKey={0} id='payInfo' className='mb-3'>
                    {payInfo}
                </Tabs>
            </Modal.Body>
        </>
    )
}