/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Col from 'react-bootstrap/Col';
import CustomButton from './CustomButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockOpen } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import CustomControl from './CustomControl';
import { ApiRequest } from '../ApiManager.tsx';

export default function PasswordProtectedControl({user, type, disabled, originalValue, label, value, onChange, xs, sm, md, lg, xl, xxl, validator, maxLength, maxInt, maxDecimal, handleSetOriginalUser, handleSetUser}) {
    const [unlocked, setUnlocked] = useState(false);
    const [password, setPassword] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    function handleGetSSN() {
        new ApiRequest('hr', 'ssn', setIsLoading, (response) =>{
            handleSetUser('ssn', response.ssn);
            handleSetOriginalUser('ssn', response.ssn);
            setShowModal(false);
            setUnlocked(true);
        }).withData({uid: user.uid, password: password}).withNoAlertOnSuccess().send();
    }

    const content = (
        <div style={{ position: 'relative' }}>
            <CustomControl
                type={unlocked ? type : 'password'}
                disabled={disabled || !unlocked}
                value={value}
                originalValue={originalValue}
                title={label}
                validator={validator}
                onChange={onChange}
                placeholder=' '
                maxLength={unlocked ? maxLength : undefined}
                maxInt={unlocked ? maxInt : undefined}
                maxDecimal={unlocked ? maxDecimal : undefined}
            />
            {unlocked ? '' :
                <Button variant={'outline-primary'} style={{ position: 'absolute', right: 8, top: 16, padding: '0px 4px 0px 4px' }} onClick={() => setShowModal(true)}>
                    <FontAwesomeIcon icon={faLockOpen} size='xs' />
                </Button>
            }
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Password Verification</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Please enter the password for your account to access this field</Form.Label>
                        <FloatingLabel label='Password'>
                            <Form.Control type='password' disabled={isLoading} value={password} onChange={(event) => { setPassword(event.target.value) }} placeholder=' ' />
                        </FloatingLabel>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton label='Submit' onClick={handleGetSSN} isLoading={isLoading} />
                </Modal.Footer>
            </Modal>
        </div>
    );

    const colWrappedContent = (
        <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} style={{ marginBottom: 8 }}>
            {content}
        </Col>
    )

    return (
        <>
            {xs || sm || md || lg || xl || xxl ?
                colWrappedContent : content
            }

        </>
    )
}