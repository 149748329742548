/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import QuickTable from "../../../../../components/QuickTable";
import { usdFormatter } from "../../../../../tools";
import HRUserLoan from "../../../../HumanResources/HRUserLoan/HRUserLoan";
import Ticket from "../../../Models/Ticket";
import Loan from "../../../Models/Loan";
import PayrollEmployeeTicketsTooltip from "./PayrollEmployeeTicketsTooltip";
import { ApiRequest } from "../../../../../ApiManager.tsx";

export default function PayrollEmployeeTicketsEditor({entry, handleSet, selectedPayPeriod}) {
    const [showModal, setShowModal] = useState(false);
    const [showError, setShowError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    function handleRecalculateEntry(){
        new ApiRequest('payroll', 'getEmployeePayrollLoans', setIsLoading, (response) => {
            const newLoans = response.loanData.map((loan) => {
                loan.isEnabled = entry.loans.find(l => l.uid == loan.uid)?.isEnabled ?? true;
                return Loan.decode(loan);
            });
            const newTickets = response.ticketData.map((ticket) => {
                ticket.isEnabled = entry.tickets.find(l => l.uid == ticket.uid)?.isEnabled ?? true;
                return Ticket.decode(ticket);
            });
            handleSet(entry.companyUserIdentifier, 'loans', newLoans);
            handleSet(entry.companyUserIdentifier, 'tickets', newTickets);
        }).withData({companyUserIdentifier: entry.companyUserIdentifier, payrollPeriod: selectedPayPeriod}).withNoAlertOnSuccess().send();
    }

    function handleSetTicketEnabled(uid){
        const newArray = Array.from(entry.tickets);
        const ticket = entry.tickets.find(t => t.uid == uid);
        if (ticket) {
            ticket.isEnabled = !ticket.isEnabled;
            handleSet(entry.companyUserIdentifier, 'tickets', newArray);
        }
    }

    const ticketRows = entry.tickets.map((t)=>{
        const value = usdFormatter.format(t.amount);
        const projectedValue = t.getAmountToPay(entry);

        return (
            <tr key={t.uid} style={{opacity: t.isEnabled ? 1.0 : 0.5}}>
                <td>{t.name}</td>
                <td>{value}</td>
                <td style={projectedValue != t.amount ? {color: 'green'} : {}}>{usdFormatter.format(projectedValue)}</td>
                <td>{t.deductFromSafetyBonuses ? 'Yes' : 'No'}</td>
                <td>
                    <Button onClick={() => {handleSetTicketEnabled(t.uid)}} style={{padding: '0px 8px 0px 8px', color: 'white', border: 'none', backgroundColor: t.isEnabled ? 'limegreen' : 'gray'}}>{t.isEnabled ? 'Yes' : 'No'}</Button>
                </td>
            </tr>
        )
    });

    return (
        <Card>
            <Card.Body>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 12}}>
                    <div style={{display: 'flex', alignItems: 'center', gap: 12}}>
                        <Card.Title style={{margin: 0}}>Fine Ticket Damage</Card.Title>
                        <PayrollEmployeeTicketsTooltip/>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', gap: 12}}>
                        <Button variant="outline-primary" onClick={() => {setShowModal(true)}}>View/Edit Loans & FTD</Button>
                    </div>
                </div>
                <div style={{display:'flex'}}>
                    { entry.tickets.length > 0 &&
                        <QuickTable title='Fine Ticket Damage' headers={['Name', 'Installment', 'Amount to Pay', 'Deduct From Safety Bonuses?', 'Include In Payroll?']} rows={ticketRows} size={'sm'}/>
                    }
                </div>
            </Card.Body>
            <Modal show={showModal} onHide={() => {setShowModal(false)}} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>{'Loans & Fine Ticket Damage for ' + entry.name()}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HRUserLoan selectedEmployee={{companyUserUid: entry.companyUserIdentifier}} handleRecalculateEntry={handleRecalculateEntry}/>
                </Modal.Body>
            </Modal>
            <Modal show={showError} onHide={() => {setShowError(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title style={{color: 'red'}}>Failed to update employee's payroll entry</Modal.Title>
                </Modal.Header>
            </Modal>
        </Card>
    )
}
