/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import CustomButton from "../../../components/CustomButton";
import QuickTable from "../../../components/QuickTable";
import moment from "moment";


export default function MultiplePayAlert({name, payData}) {


    const sortPaysAsc = (a, b) => {
        if (a.date < b.date) {
            return -1;
        } else if (b.date < a.date) {
            return 1;
        } else {
            return 0;
        }
    }

    const rows = Array.from(payData).sort(sortPaysAsc).map((pay) => {
        return (
            <tr key={pay.uid}>
                <td>{pay.title}</td>
                <td>{moment(pay.date).format('MMM D, YYYY')}</td>
            </tr>
        )
    })

    return(
        <>
            <Modal.Header closeButton>
                <Modal.Title>Multiple Effective Pay Definitions Detected</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={{fontWeight: 'bold'}}>{`${name} changed his or her pay definition during the payroll period. Please note the following:`}</p>
                <ul>
                    <li>Mutiple wages may have been generated for this employee</li>
                    <li><b>Reoccuring reimbursements & deductions</b> are generated based off of the most recent pay definition</li>
                    <li><b>child support</b>, and all <b>insurance</b> deductions are generated based off of the effective pay definition at the beginning of the pay period</li>
                    <li>You can view the details of the pay information used to generate this employees payroll entry by selecting <b>Pay Info</b> in the <b>Reports</b> menu</li>
                </ul>
                <QuickTable headers={['Title', 'Date Effective']} rows={rows}/>
            </Modal.Body>
        </>

    )
}