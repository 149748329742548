/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/


import React from "react";
import { Page, Document, View, Text, Image } from "@react-pdf/renderer";
import moment from "moment";
import logo from '../../../assets/TTA-Logo-150.png';
import Cookies from "universal-cookie";

export default function PDFTimesheet({data, routeData, vehicleData, workAreaData}) {

    const tables = [];
    
    data.csas.forEach((csa)=>{
        const usersWithRows = [];
        const usersWithoutRows = [];
        const usersInCsa = data.users.filter((user)=>{
            return user.csaIdentifier == csa.uid
        });

        const sortedUsers = usersInCsa.sort((a, b) => a.lastName.localeCompare(b.lastName));
        sortedUsers.forEach((user) => {
            
            const timeSheetEntriesForUser = data.timesheets.filter((timesheet)=>{
                return timesheet.companyUserIdentifier == user.companyUserUid;
            });
          
            const usersScheduledDates = routeData.filter((route)=>{
                return route.companyUserIdentifier == user.companyUserUid;
            });
            
            const rows = timeSheetEntriesForUser.map((entry)=>{
                const scheduleForPunch = usersScheduledDates.find((item)=> moment(entry.inTime).isSame(moment(item.date),'day'));
                const workAreaForSchedule = scheduleForPunch ? workAreaData.find((area) => area.uid == scheduleForPunch.workAreaIdentifier) : undefined;
                const csaWorked = workAreaForSchedule ? data.csas.find((csa) => csa.uid == workAreaForSchedule.csaIdentifier) : undefined;

                entry.workTime = entry.outTime ? moment(entry.outTime).diff(moment(entry.inTime), 'hours','true') : 0;
                let grossVehicleWeightRating = '';
                const matchingDate = usersScheduledDates.find((date) => moment(entry.inTime).isSame(moment(date.date), 'day'));

                if (matchingDate) {
                  const vehicleObj = vehicleData.find((obj) => obj.uid == matchingDate.vehicleIdentifier);
                  grossVehicleWeightRating = vehicleObj?.vehicleWeight || '';
                }

                return(
                    <View key={entry.uid} style={{display:'flex', flexDirection:'row', alignContent:'center', borderTop:'1px gray solid',padding:2}}>
                        <Text style={{width:'16.67%'}}>{moment(entry.inTime).format('MMM D, YYYY')}</Text>
                        <Text style={{width:'16.67%'}}>{moment(entry.inTime).format('h:mm A')}</Text>
                        {entry.outTime ? <Text style={{width:'16.67%'}}>{moment(entry.outTime).isSame(moment(entry.inTime),'day') ? (moment(entry.outTime).format('h:mm A')) : moment(entry.outTime).format('MMM D, YYYY hh:mm A')}</Text>:<View style={{width:'16.67%'}}/>}
                        {entry.outTime ? <Text style={{width:'16.67%'}}>{fractionalHoursConvertor(entry.workTime)}</Text>:<View style={{width:'16.67%'}}/>}
                        <Text style={{width:'16.67%'}}>{grossVehicleWeightRating}</Text>
                        {csaWorked ? <Text>{csaWorked.csaName}</Text> :<View style={{width:'16.67%'}}/>}
                    </View>
                ) 
            }); 

            const sum = timeSheetEntriesForUser.reduce((acc, el)=>{   
                return acc + el.workTime;    
            }, 0);

            if(rows.length > 0){
                usersWithRows.push(
                    <View key={user.uid} style={{display:'flex', flexDirection:'column',gap:8, marginBottom:10}} wrap={true}>
                        <View style={{display: 'flex', flexDirection:'row',justifyContent:'space-between', alignContent:'center'}}>
                            <Text>{user.lastName + ', ' + user.firstName + ' ' + (user.middleName ? user.middleName : '')}</Text>
                            <Text style={{fontSize:11}}>{csa.csaName}</Text>  
                        </View>
                        <View style={{border: '1px gray solid', width: '100%', fontSize: 9}}>
                            <View style={{flexDirection:'row', paddingBottom:2}}>
                                <View style={{width:'16.67%'}}>
                                    <Text style={{fontWeight: 'bold', fontSize:'11'}}>Date</Text>
                                </View>
                                <View style={{width:'16.67%%'}}>
                                    <Text style={{fontWeight: 'bold', fontSize:'11'}}>In Punch</Text>
                                </View>
                                <View style={{width:'16.67%%'}}>
                                    <Text style={{fontWeight: 'bold', fontSize: '11'}}>Out Punch</Text>
                                </View>
                                <View style={{width:'16.67%%'}}>
                                    <Text style={{fontWeight: 'bold', fontSize: '11', marginLeft:-5}}>Shift Time</Text>
                                </View>
                                <View style={{width:'16.67%%'}}>
                                    <Text style={{fontWeight: 'bold', fontSize: '11', marginLeft:-5}}>GVWR (lbs)</Text>
                                </View>
                                <View style={{width:'16.67%%'}}>
                                    <Text style={{fontWeight: 'bold', fontSize: '11', marginLeft:-2}}>CSA</Text>
                                </View>
                            </View>
                            {rows}
                        </View>
                        <View>
                            <Text style={{fontSize:11}}>Total Time: {sum ? fractionalHoursConvertor(sum) : 0}</Text>
                        </View>
                    </View>
                )
            }
        });
        tables.push(...usersWithRows, ...usersWithoutRows);
    });
    
    return (
        <Document> 
            <Page size='LETTER' style={{padding: '0.5in', flexDirection: 'column'}}>
            <View fixed style={{flexDirection: 'row', width: '100%', justifyContent: 'space-between', marginBottom:10}}>
                    <Image src={logo} style={{width: 100}}/>
                    <Text style={{fontSize: 12, color: 'gray'}}>{new Cookies().get('companyName')}</Text>
                </View>
                <Text style={{textAlign:'center', paddingBottom:4, textDecoration:'underline'}}>Timesheet Report</Text>
                <Text style={{opacity:0.5,textAlign:'center'}}>{moment(data.startDate).format('MMM D, YYYY')+' - '+moment(data.endDate).format('MMM D, YYYY')}</Text>
                {tables}
            </Page>
        </Document>
    )
}

function fractionalHoursConvertor(workTime){
   const hours = Math.floor(workTime);
   const fractional = workTime - hours;
   const minutes = Math.floor(fractional * 60)

   let time;
   if(minutes < 10){
        time=hours+':0'+minutes
   } else { 
        time=hours+':'+minutes;
   }
   return time;
}
  

