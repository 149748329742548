/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import { usdFormatter } from "../../../../tools";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Container, Card, ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import PayrollGrossCalculator from "./Modals/PayrollGrossCalculator";
import Modal from 'react-bootstrap/Modal';
import PayrollPeriodUserTimesheet from "./Modals/PayrollPeriodUserTimesheet";
import CustomToolTip from "../../../../components/CustomToolTip";
import PayrollEmployeeDeductionsEditor from "./Deductions/PayrollEmployeeDeductionsEditor";
import PayrollEmployeePayEditor from "./Pay/PayEditor/PayrollEmployeePayEditor";
// import PayrollEmployeeHolidaysEditor from "./Holidays/PayrollEmployeeHolidaysEditor";
import {Tabs, Tab } from "react-bootstrap";
import PayInfo from "./Modals/PayInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import CustomButton from "../../../../components/CustomButton";
import PayrollEmployeeDBonusesEditor from "./DBonuses/PayrollEmployeeDBonusesEditor";
import { adminMode } from "../../payrollTools";
import PayrollPeriodUserSettlementReport from "./Modals/PayrollPeriodUserSettlementReport";
import PayrollEmployeeLoansEditor from "./Loans/PayrollEmployeeLoansEditor";
import PayrollEmployeeTicketsEditor from "./Tickets/PayrollEmployeeTicketsEditor";

export default function PayrollEmployeeEditor({selectedPayPeriod, settlementReportData, handleSetEntry, entry, handleSet, handleProceed, timesheetData, handleSetTimesheetData, payData, ptoData, handleAddDBonusToAllEntries, handleAddAPToAllEntries, previousEntry, handleAddDeductionToAllEntries, handleAddNdToAllEntries, displayPtoInDays, setDisplayPtoInDays}) {
    const [modalSwitch, setModalSwitch] = useState('');

    const handleSetWeek = (index, key, value) => {
        const weeks = Array.from(entry.weeks);
        weeks[index][key] = value;
        handleSet(entry.companyUserIdentifier, 'weeks', weeks);
    }

    const hideModal = () => {
        setModalSwitch('');
    }

    const handleSubmit = () => {
        if (entry.status !== 'approved') {
            handleProceed();
        }
        handleSet(entry.companyUserIdentifier, 'status', (entry.status === 'approved' ? 'pending' : 'approved'));
    }

    const handleTabSelect = (index) => {
        handleSet(entry.companyUserIdentifier, 'weekIndex', index) 
    };

    const handleClearData = () =>{
        entry.clearPay();
        handleSetEntry(entry);
        setModalSwitch('');
    }

    const handleRecalculateStopWages = () => {
        entry.recalculateStopWages(settlementReportData, payData);
        handleSetEntry(entry);
    }

    const handleRecalculateDaysWorked = () => {
        entry.recalculateDaysWorkedFromSettlementReport(settlementReportData, payData);
        handleSetEntry(entry);
    }
   
    const payWeekElements = entry.weeks.map((week, index) => {
        return (
            entry.weeks.length > 1 ?
            <Tab key={index} title={'Week ' + (index + 1)} eventKey={index}>
                <PayrollEmployeePayEditor key={index} entry={entry} handleSet={handleSet} handleSetWeek={handleSetWeek} index={index} handleAddNdToAllEntries={handleAddNdToAllEntries} handleAddAPToAllEntries={handleAddAPToAllEntries} displayPtoInDays={displayPtoInDays} setDisplayPtoInDays={setDisplayPtoInDays} payData={payData} selectedPayPeriod={selectedPayPeriod} handleRecalculateStopWages={handleRecalculateStopWages} handleRecalculateDaysWorked={handleRecalculateDaysWorked}/>
            </Tab>
            :
            <PayrollEmployeePayEditor key={index} entry={entry} handleSet={handleSet} handleSetWeek={handleSetWeek} index={index} handleAddNdToAllEntries={handleAddNdToAllEntries} handleAddAPToAllEntries={handleAddAPToAllEntries} displayPtoInDays={displayPtoInDays} setDisplayPtoInDays={setDisplayPtoInDays} payData={payData} selectedPayPeriod={selectedPayPeriod} handleRecalculateStopWages={handleRecalculateStopWages} handleRecalculateDaysWorked={handleRecalculateDaysWorked}/>
        )
    });



        return (
            
            <div style={{overflowY: 'scroll', display: 'flex', flexDirection: 'column', gap: 12, flex: 1, padding: 8}}>
                <Card>
                    <Card.Body style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between', paddingBottom: 0}}>
                        <ButtonGroup style={{marginBottom: 16}}>
                            <Button variant={'outline-primary'}  disabled={!previousEntry} onClick={() => {setModalSwitch('previousPay')}}>Previous Payroll Entry</Button>
                            <DropdownButton variant={'outline-primary'} as={ButtonGroup} title='Tools' id="bg-nested-dropdown">
                                <Dropdown.Item eventKey='1'> <Button style={{background:'none', color:'black', border:'none', boxShadow:'none', width:'100%', textAlign:'left'}} onClick={() => {setModalSwitch('gross')}}>Gross Calculation</Button></Dropdown.Item>
                                <Dropdown.Item eventKey='2'> <Button  style={{background:'none', color:'black', border:'none', boxShadow:'none', width:'100%', textAlign:'left'}} onClick={() => {setModalSwitch('timesheet')}}>Timesheet</Button></Dropdown.Item>
                                <Dropdown.Item eventKey='5'><Button style={{background:'none', color:'black', border:'none', boxShadow:'none', width:'100%', textAlign:'left'}} onClick={() => {setModalSwitch('payInfo')}}>Pay Info</Button></Dropdown.Item>
                                <Dropdown.Item eventKey='5'><Button style={{background:'none', color:'black', border:'none', boxShadow:'none', width:'100%', textAlign:'left'}} onClick={() => {setModalSwitch('settlementReport')}}>Settlement Report Data</Button></Dropdown.Item>
                            </DropdownButton>
                            {/* <Button style={{color: 'white'}} onClick={() => {setModalSwitch('production')}}>Production Report</Button> */}
                        </ButtonGroup>
                        <span style={{fontWeight: 'bold', fontSize: 20, marginBottom: 16}}>
                            {entry.firstName + ' '+ entry.middleName + ' ' + entry.lastName + '\'s Gross Pay: ' + usdFormatter.format(entry.gross())}
                        </span>
                        <div style={{display:'flex', gap:8, alignItems:'center', marginBottom:16}}>
                            { adminMode &&
                                <Button style={{background:'none', color:'red', border:'none', boxShadow:'none'}} onClick={()=>setModalSwitch('clearPay')}>Clear Payroll Entry</Button>
                            }
                            <Button variant={'outline-primary'} style={{fontWeight: 'bold'}} onClick={handleSubmit}>{entry.status === 'approved' ? 'Undo Approval' : 'Approve'}</Button>
                        </div>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Title style={{padding:12}}>Wages, Nondiscretionary Bonuses, & Additional Pay</Card.Title>
                    <Card.Body>
                        {entry.weeks.length > 1 ?
                            <Tabs defaultActiveKey={entry.weekIndex} id='employeePay' className='mb-3' onSelect={(index)=>handleTabSelect(index)}>
                                {payWeekElements}
                            </Tabs>
                            :
                            payWeekElements
                        }
                    </Card.Body>
                </Card>
                
                <PayrollEmployeeDBonusesEditor entry={entry} handleSet={handleSet} handleAddDBonusToAllEntries={handleAddDBonusToAllEntries}/>
                <PayrollEmployeeDeductionsEditor entry={entry} handleSet={handleSet} handleAddDeductionToAllEntries={handleAddDeductionToAllEntries}/>
                <PayrollEmployeeLoansEditor entry={entry} handleSet={handleSet} selectedPayPeriod={selectedPayPeriod}/>
                <PayrollEmployeeTicketsEditor entry={entry} handleSet={handleSet} selectedPayPeriod={selectedPayPeriod}/>
                <Card>
                    <Card.Body>
                        <Container fluid>
                        <div style={{display: 'flex', alignItems:'center', gap: 12}}>
                            <Card.Title>Notes</Card.Title>
                            { previousEntry &&
                                <Button style={{padding: 4, marginBottom: 8}} variant="outline-primary" onClick={() => {handleSet(entry.companyUserIdentifier, 'notes', previousEntry.notes)}}>
                                    Import Notes from Previous Payroll Period
                                </Button>
                            }
                            <div style={{display: 'flex', alignItems: 'center', gap: 12, border: '1px solid lightgray', borderRadius: 6, padding: '3px 8px 3px 8px', marginBottom: 8}}>
                                <Form.Check type='switch' label='Reoccuring' checked={entry.reoccuringNotes} onChange={() => {handleSet(entry.companyUserIdentifier, 'reoccuringNotes', !entry.reoccuringNotes)}}/>
                                <CustomToolTip text={'Next payroll period, these notes will be automatically added to this employee'}/>
                            </div>
                        </div>
                        <Form.Control as='textarea' rows={3} value={entry.notes} onChange={(event) => {handleSet(entry.companyUserIdentifier, 'notes', event.target.value)}}/>
                        </Container>
                    </Card.Body>
                </Card>
                <Modal show={modalSwitch === 'gross'} onHide={hideModal} centered size='xl'>
                    <PayrollGrossCalculator entry={entry}/>
                </Modal>
                <Modal show={modalSwitch === 'timesheet'} onHide={hideModal} centered size='lg'>
                    <PayrollPeriodUserTimesheet 
                        handleSetWeek={handleSetWeek} 
                        timesheetData={timesheetData} 
                        payData={payData}
                        entry={entry}
                        handleSetTimesheetData={handleSetTimesheetData} 
                        hideModal={hideModal}
                    />
                </Modal>
                <Modal show={modalSwitch === 'previousPay'} onHide={()=>setModalSwitch('')} size='xl'>
                    <PayrollGrossCalculator entry={previousEntry}/>
                </Modal> 
                <Modal show={modalSwitch === 'payInfo'} onHide={()=>setModalSwitch('')} size='xl'>
                    <PayInfo entry={entry} handleSet={handleSet} payData={payData}/>
                </Modal>
                <Modal show={modalSwitch === 'clearPay'} onHide={()=>setModalSwitch('')}>
                    <Modal.Header closeButton>
                        <Modal.Title>Clear Preloaded Payroll Data?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{display:'flex', gap:12, alignItems:'center'}}>
                            <FontAwesomeIcon icon={faInfoCircle}/>
                            <span>This can not be undone. You will lose any unsaved data.</span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <CustomButton label={'Clear Entry'} onClick={()=>{handleClearData()}}/>
                    </Modal.Footer>
                </Modal> 
                <Modal show={modalSwitch === 'settlementReport'} onHide={()=>setModalSwitch('')} size='lg'>
                    <PayrollPeriodUserSettlementReport settlementReportData={settlementReportData} entry={entry}/>
                </Modal>
            </div>
    
        )

}