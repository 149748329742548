/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ButtonGroup } from "react-bootstrap";
import AddWageForm from "./AddWageForm";
import AddNDBonusForm from "./AddNDBonusForm";
import AddAdditionalPayForm from "./AddAdditionalPayForm";
import AddHolidayForm from "./AddHolidayForm";

export default function PayModal({disableAddingPay, periodStart, periodEnd, handleAddWages, handleAddNonDiscretionaryBonus, handleAddAdditionalPay, handleAddPto, displayPtoInDays, setDisplayPtoInDays, handleAddHoliday, qualifiesForPtoAndHolidays}) {

    const [activeTab, setActiveTab] = useState(0);

    const tabButtons = ['Wage', 'Nondiscretionary Bonus', 'Additional Pay', 'Holiday'].map((value, index) => {
        return (
            <Button key={value} style={activeTab !== index ? {color: 'var(--bs-primary)', backgroundColor: 'white'} : {color: 'white'}} onClick={() => {setActiveTab(index)}}>{value}</Button>
        )
    })
   
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Adding Pay</Modal.Title>
            </Modal.Header>
            <Modal.Header style={{textAlign: 'center', display: 'block'}}>
                <ButtonGroup style={{minWidth: '50%'}}>
                    {tabButtons}
                </ButtonGroup>
            </Modal.Header>
            { activeTab === 0 ?
                    <AddWageForm handleAddWages={handleAddWages} periodStart={periodStart} periodEnd={periodEnd} disableAddingPay={disableAddingPay}/>
                : activeTab === 1 ?
                    <AddNDBonusForm handleAddNonDiscretionaryBonus={handleAddNonDiscretionaryBonus}/>
                : activeTab === 2 ?
                    <AddAdditionalPayForm handleAddAdditionalPay={handleAddAdditionalPay}/>
                : activeTab === 3 ? 
                    <AddHolidayForm handleAddHoliday={handleAddHoliday} qualifiesForPtoAndHolidays={qualifiesForPtoAndHolidays}/>
                : ''
            }
        </>
    )
}