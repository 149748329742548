/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import { validateDecimal, validateInteger } from "../../../../tools";

export default class ChargeStatement{
    uid;
    csaFedexId;
    startDate;
    // brandPromotionUniforms;
    // brandPromotionVehicles;
    // vehicles;
    // serviceCharge;
    // peakServiceCharge;
    otherAmount;
    netAmount;
    // baseFuelPrice;
    // currentFuelPrice;
    // dailyStopThreshold;
    chargeStatementDrivers; // []
    chargeStatementDays; // []

    constructor(
        uid,
        csaFedexId,
        startDate,
        // brandPromotionUniforms,
        // brandPromotionVehicles,
        // vehicles,
        // serviceCharge,
        // peakServiceCharge,
        otherAmount,
        netAmount,
        // baseFuelPrice,
        // currentFuelPrice,
        // dailyStopThreshold,
        chargeStatementDrivers,
        chargeStatementDays,
    ){
        this.uid = uid;
        this.csaFedexId = csaFedexId;
        this.startDate = startDate;
        // this.brandPromotionUniforms = brandPromotionUniforms;
        // this.brandPromotionVehicles = brandPromotionVehicles;
        // this.vehicles = vehicles;
        // this.serviceCharge = serviceCharge;
        // this.peakServiceCharge = peakServiceCharge;
        this.otherAmount = otherAmount;
        this.netAmount = netAmount;
        // this.baseFuelPrice = baseFuelPrice;
        // this.currentFuelPrice = currentFuelPrice;
        // this.dailyStopThreshold = dailyStopThreshold;
        this.chargeStatementDrivers = chargeStatementDrivers;
        this.chargeStatementDays = chargeStatementDays;
    }

    static initDefault(){
        return new ChargeStatement(
            -1,
            -1,
            moment().format('YYYY-MM-DD'),
            // '',
            // '',
            // '',
            // '',
            // '',
            '',
            '',
            // '',
            // '',
            // '',
            [],
            []
        )
    }

    encode(){
        return {
            uid: this.uid,
            csaFedexId: this.csaFedexId,
            startDate: this.startDate,
            // brandPromotionUniforms: validateDecimal(this.brandPromotionUniforms),
            // brandPromotionVehicles: validateDecimal(this.brandPromotionVehicles),
            // vehicles: validateInteger(this.vehicles),
            // serviceCharge: validateDecimal(this.serviceCharge),
            // peakServiceCharge: validateDecimal(this.peakServiceCharge),
            otherAmount: validateDecimal(this.otherAmount),
            netAmount: validateDecimal(this.netAmount),
            // baseFuelPrice: validateDecimal(this.baseFuelPrice),
            // currentFuelPrice: validateDecimal(this.currentFuelPrice),
            // dailyStopThreshold: validateInteger(this.dailyStopThreshold),
            chargeStatementDrivers: this.chargeStatementDrivers.map(srd => srd.encode()),
            chargeStatementDays: this.chargeStatementDays.map(srd => srd.encode())
        }
    }
}