/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import MyCompanyVehicle from "./MyCompanyVehicle";
import CreateCompanyVehicles from "./CreateCompanyVehicles";
import { useEffect } from "react";
import QuickTable from "../../components/QuickTable";
import { Dropdown } from "react-bootstrap";
import DropdownItemWithCheck from "../../components/DropdownItemWithCheck";
import LoadingWrapper from "../../components/LoadingWrapper";
import { ApiRequest } from "../../ApiManager.tsx";

export default function MyCompanyVehicles({restrictedToCsa}) {
    const [showInactiveItems, setShowInactiveItems] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showMultipleModal, setShowMultipleModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const [csas, setCsas] = useState([]);
    const [selectedCsas, setSelectedCsas] = useState([]);

    useEffect(() => {
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('myCompany', 'getVehicles', setIsLoading, (response) => {
            const csas = (response.vehicles.reduce((prev, curr) => {
                if (!prev.includes(curr.csa.uid)) {
                    return prev.concat([curr.csa.uid]);
                }
                return prev;
            }, []));
            setCsas(csas)
            setVehicles(response.vehicles.sort(sortVehicles));
            if (restrictedToCsa) {
                setSelectedCsas([parseInt(restrictedToCsa)]);
            } else {
                setSelectedCsas(csas);
            }
        }).withNoAlertOnSuccess().send();
    }

    function handleVehicleSubmission(vehicle, isUpdating){
        let newArray = Array.from(vehicles);
        if (isUpdating) {
            newArray = newArray.filter(v => v.uid != vehicle.uid);
        }
        newArray.push(vehicle);
        newArray.sort(sortVehicles);
        setVehicles(newArray);
        setShowModal(false);
    }

    function handleMultipleVehicleSubmissions(vehicleArray){
        let newArray = vehicles.concat(vehicleArray);
        newArray.sort(sortVehicles);
        setVehicles(newArray);
        setShowModal(false);
    }

    function handleSelectCsa(uid){
        let newArr = Array.from(selectedCsas);
        if(newArr.find((u)=>u == uid)){
            newArr = newArr.filter((u)=> u != uid);
        } else {
            newArr.push(uid);
        }
        setSelectedCsas(newArr);
    }

    function sortVehicles(a, b){
        const textA = a.vehicleName.toUpperCase();
        const textB = b.vehicleName.toUpperCase();
        
        // Compare first characters of each string
        if (!isNaN(parseInt(textA.charAt(0))) && isNaN(textB.charAt(0))) {
            return -1;
        }
        if (isNaN(textA.charAt(0)) && !isNaN(parseInt(textB.charAt(0)))) {
            return 1;
        }
        
        // Compare rest of strings as numbers if both start with numbers
        if (!isNaN(parseInt(textA)) && !isNaN(parseInt(textB))) {
            return parseInt(textA) - parseInt(textB);
        }
        
        // Compare rest of strings as strings
        return textA.localeCompare(textB);
    };

    const csaDropdownMenu = (
        <Dropdown autoClose='outside'>
            <Dropdown.Toggle variant="outline-primary">
            {selectedCsas.length == 0 ? 'Filter CSA(s)' : selectedCsas.length > 1 ? `Filter CSA(s): ${vehicles.find(v => v.csa.uid === selectedCsas[0])?.csa?.csaName} +${selectedCsas.length - 1}` : `Filter CSA(s): ${vehicles.find(v => v.csa.uid === selectedCsas[0])?.csa?.csaName}`}
            </Dropdown.Toggle>
            <Dropdown.Menu>
            {csas.map((csa) => {
                const name = vehicles.find(v => v.csa.uid === csa)?.csa?.csaName;
                return <DropdownItemWithCheck key={csa} label={name} active={selectedCsas.includes(csa)} onClick={()=>handleSelectCsa(csa)} />
                })}
            </Dropdown.Menu>
        </Dropdown>
    )

    const vehicleRows = vehicles.filter(v => (showInactiveItems || v.isActive === 1) && selectedCsas.includes(v.csa.uid)).map((vehicle) => {
        return (
            <tr key={vehicle.uid} style={vehicle.isActive === 1 ? {} : {opacity: 0.5}} className='cursor-pointer' onClick={() => {setSelectedItem(vehicle); setShowModal(true);}}>
                <td>{vehicle.vehicleName + (vehicle.isActive === 1 ? '' : ' (inactive)')}</td>
                <td>{vehicle.vehicleWeight}</td>
                <td>{vehicle.isStraight == '1' ? 'Yes' : 'No'}</td>
                <td>{vehicle.csa.csaName}</td>
            </tr>
        )
    });

    const content = (
        <div>
            <div style={{display: 'flex', marginBottom: 12, justifyContent: 'space-between', alignItems: 'center'}}>
                 <div style={{display: 'flex', gap: 6}}>
                    { !restrictedToCsa && 
                        <div>
                            {csaDropdownMenu}
                        </div>
                    }
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: 'var(--bs-primary)', border: '1px solid var(--bs-primary', borderRadius: 6, padding: '0 12px'}}>
                        <Form.Check type='switch' label='Show inactive vehicles' value={showInactiveItems}  onChange={() => {setShowInactiveItems(!showInactiveItems)}}/>
                    </div>
                </div>
                <div style={{display: 'flex', gap: 6}}>
                    <Button variant={'outline-primary'} onClick={() => {setSelectedItem(undefined); setShowModal(true);}}>
                        Add Vehicle
                    </Button>
                    <Button variant={'outline-primary'} onClick={() => {setShowMultipleModal(true);}}>
                        Add Multiple Vehicles
                    </Button>
                </div>
            </div>
            <QuickTable headers={['Name', 'GVW', 'Straight Truck?', 'CSA']} rows={vehicleRows} widths={[null, null, 140, null]}/>
            <Modal show={showModal} onHide={() => {setShowModal(false)}} centered size='lg'>
                <MyCompanyVehicle restrictedToCsa={restrictedToCsa} data={selectedItem} handleVehicleSubmission={(vehicleToSave, isUpdating)=>{handleVehicleSubmission(vehicleToSave, isUpdating);setShowModal(false)}}/>
            </Modal>
            <Modal show={showMultipleModal} onHide={()=>{setShowMultipleModal(false)}} centered size='lg'>
                <CreateCompanyVehicles restrictedToCsa={restrictedToCsa} handleMultipleVehicleSubmissions={(vehicleArray)=>{handleMultipleVehicleSubmissions(vehicleArray);setShowMultipleModal(false)}}/>
            </Modal>

        </div>
    )
    return (
        <LoadingWrapper isLoading={isLoading}>
            {content}
        </LoadingWrapper>
    )
}