/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { usdFormatter } from "../../tools.js";

export default function BookkeepingReportSection({section, fontSize, isRoot = false}) {

    const rows = section.Rows?.Row.map((row, index) => {
        if (row.type == 'Section') {
            return (
                <BookkeepingReportSection key={index} section={row} fontSize={fontSize * 0.8}/>
            )
        } else {
            return (
                <div key={index} style={{display: 'flex', gap: 12, justifyContent: 'space-between', fontSize: fontSize * 0.8}}>
                    <span>{row.ColData[0].value}</span>
                    <span>{usdFormatter.format(row.ColData[1].value)}</span>
                </div>
            )
        }
    })

    return (
        <div style={{fontSize: fontSize}}>
            { !isRoot && 
                <hr style={{marginTop: 6, marginBottom: 6}}/>
            }
            <div style={{display: 'flex', gap: 12, justifyContent: 'space-between'}}>
                <span style={{fontSize: fontSize, opacity: 0.5, fontWeight: 'bold'}}>{section.Header?.ColData[0].value.toUpperCase()}</span>
                <span>{section.Header?.ColData[1]?.value ? usdFormatter.format(section.Header.ColData[1].value) : ''}</span>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', paddingLeft: 12}}>
                {rows}
            </div>
            <div style={{display: 'flex', gap: 6, fontWeight: 'bold', fontSize: fontSize * 0.9}}>
                <span style={{fontWeight: 'bold'}}>{section.Summary?.ColData[0].value}</span>
                <div style={{flex: 1, borderBottom: '2px dotted lightgray', marginBottom: 5}}/>
                <span style={{fontWeight: 'bold', color: isRoot ? 'red' : undefined}}>{section.Summary?.ColData[1]?.value ? usdFormatter.format(section.Summary.ColData[1].value) : ''}</span>
            </div>
            {/* { !isRoot && 
                <hr style={{marginTop: 6, marginBottom: 6}}/>
            } */}
        </div>
    )
}