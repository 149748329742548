/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import CustomControl from "../../../../../../components/CustomControl";
import Modal from 'react-bootstrap/Modal';
import { getUnitsWorkedTypeString, payTypeDictionary} from "../../../../payrollTools";
import Pay from "../../../../Models/Pay";
import { validateExistence, ValidationGroup } from "../../../../../../validation";
import { Container, Row, Form } from "react-bootstrap";
import Picker from "../../../../../../components/Picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export default function AddWageForm({handleAddWages, periodStart, periodEnd, disableAddingPay}) {
    const [payType, setPayType] = useState('');
    const [payRate, setPayRate] = useState('');
    const [unitsWorked, setUnitsWorked] = useState('');
    const [applyToAll, setApplyToAll] = useState(false);
   
    const validationGroup = new ValidationGroup(); 

    const handleSubmit = () => {
        if (validationGroup.isValid()) {
            handleAddWages(new Pay(payType, payRate, unitsWorked, periodStart, periodEnd), applyToAll);
        }
    }
    
    return (
        <>
            <Modal.Body>
                <Container>
                    <Row>
                        <Picker validator={validationGroup.createValidator(() => validateExistence(payType))} title='Pay Type' lg={4} selection={payType} setSelection={setPayType} itemNames={Object.values(payTypeDictionary)} itemValues={Object.keys(payTypeDictionary)}/>
                        <CustomControl type='number' lg={4} validator={validationGroup.createValidator(() => validateExistence(payRate))} value={payRate} onChange={(event) => {setPayRate(event.target.value)}} title='Pay Rate' />
                        {!['py', 'iw', 'sw'].includes(payType) && <CustomControl type='number' lg={4} validator={validationGroup.createValidator(() => validateExistence(unitsWorked))} value={unitsWorked} onChange={(event) => {setUnitsWorked(event.target.value)}} title='Units Worked' />}
                    </Row>
                </Container>
                {disableAddingPay && 
                <div style={{display:'flex', gap:5, alignItems:'center', justifyContent:'flex-end'}}>
                    <FontAwesomeIcon icon={faInfoCircle}/>
                    <span>Employees can only have up to 4 wage items</span>
                </div>
                }
            </Modal.Body>
            <Modal.Footer style={{gap:10}}>
                <Form.Check type='switch' checked={applyToAll} label='Add Pay To All Weeks' onChange={()=>{setApplyToAll(!applyToAll)}}/>
                <Button style={{color: 'white'}} disabled={!validationGroup.isValid() || disableAddingPay} onClick={handleSubmit}>Submit</Button>
            </Modal.Footer>
        </>
    )
}