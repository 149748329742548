/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect, useMemo, useState } from "react";
import ApiManager, { ApiRequest } from "../../ApiManager.tsx";
import { formatDateRange, usdFormatter, validateDecimal, validateDecimalFixed } from "../../tools.js";
import LoadingWrapper from "../../components/LoadingWrapper.js";
import { Card, Col, Container, Dropdown, Form, InputGroup, OverlayTrigger, Popover, Row } from "react-bootstrap";
import moment from "moment";
import QuickTable from "../../components/QuickTable.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import BookkeepingReportSection from "./BookkeepingReportSection.js";
import CustomControl from "../../components/CustomControl.js";
import CustomButton from "../../components/CustomButton.js";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import DropdownItemWithCheck from "../../components/DropdownItemWithCheck.js";
import ChargeStatementUpload from "./ChargeStatement/ChargeStatementUpload.js";
import BookkeepingChargeStatementsRevenue from "./BookkeepingChargeStatementsRevenue.js";
import BookkeepingCharts from "./BookkeepingCharts.js";

export default function Bookkeeping({}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isRequesting, setIsRequesting] = useState(false);
    
    const [chargeStatements, setChargeStatements] = useState([]);
    const [csas, setCsas] = useState([]);
    const [selectedCsaFedexIds, setSelectedCsaFedexIds] = useState([]);
    const [bookkeepingEnabled, setBookkeepingEnabled] = useState(0);
    const [companyIsQBAuthenticated, setCompanyIsQBAuthenticated] = useState(false);

    const [selectedCsaForConnection, setSelectedCsaForConnection] = useState(undefined);
    const [liveBudgetToggle, setLiveBudgetToggle] = useState(true);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [fallbackReports, setFallbackReports] = useState({});
    
    const [report, setReport] = useState(undefined);
    const [sReportData, setSReportData] = useState(undefined);
    const [totalRevenue, setTotalRevenue] = useState('');
    const [selectedCsaFedexId, setSelectedCsaFedexId] = useState(undefined);

    const showReportContent = useMemo(() => {
        if (bookkeepingEnabled == 1) {
            return companyIsQBAuthenticated;
        } else {
            return csas.some(csa => !!csa.realmId);
        }
    }, [csas, bookkeepingEnabled, companyIsQBAuthenticated])

    const mostRecentSaturday = moment().day() == 6 ? moment().subtract(7, 'days').format('YYYY-MM-DD') : moment().subtract(moment().day() + 8, 'days').format('YYYY-MM-DD');

    const submitDisabled = useMemo(() => {
        if (liveBudgetToggle) {
            return selectedCsaFedexIds.length == 0;
        } else {
            return !startDate || !endDate || selectedCsaFedexIds.length == 0 || moment(endDate).diff(moment(startDate), 'weeks') > 52;
        }
    }, [liveBudgetToggle, selectedCsaFedexIds, startDate, endDate])

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        if (liveBudgetToggle) {
            setSelectedCsaFedexIds(csas.filter(c => chargeStatements.some(s => s.csaFedexId == c.fedexId && s.startDate == mostRecentSaturday)).map(c => c.fedexId))
        } else {
            setSelectedCsaFedexIds(csas.filter(c => chargeStatements.some(s => s.csaFedexId == c.fedexId)).map(c => c.fedexId))
        }
    }, [liveBudgetToggle, csas])

    useEffect(() => {
        if (bookkeepingEnabled == 2) {
            setFallbackReports({});
        }
    }, [selectedCsaFedexIds])
    useEffect(() => {
        if (bookkeepingEnabled == 2) {
            setSelectedCsaFedexIds([]);
        }
    }, [liveBudgetToggle])

    function loadData() {
        new ApiRequest('bookkeeping', 'getResources', setIsLoading, (response, status) => {
            if (response.authorizationCodeUrl) {
                handleNavigateToQB(response.authorizationCodeUrl);
            } else if (status == 200) {
                setCsas(response.csas);
                setChargeStatements(response.chargeStatements);
                setBookkeepingEnabled(response.bookkeepingEnabled);
                setCompanyIsQBAuthenticated(response.companyIsQBAuthenticated);
            } else {
                toast.error(response.message);
            }
        }).withNoAlertOnSuccess().send();
    }

    function handleRequestQBAuthentication() {
        new ApiRequest('bookkeeping', 'requestAuthenticationCode', setIsRequesting, (response) => {
            if (response.authorizationCodeUrl) {
                handleNavigateToQB(response.authorizationCodeUrl);
            } else {
                setReport(response.profitAndLossReport);
                setSReportData(response.chargeStatements);
                if(Object.keys(response.chargeStatements).length === 1){
                    setSelectedCsaFedexId(Object.keys(response.chargeStatements)[0])
                }
            }
            setReport(response.profitAndLossReport);
        }).withData({csaIdentifier: selectedCsaForConnection}).send();
    }

    function handleGetReport() {
        const startDateToSend = liveBudgetToggle ? mostRecentSaturday : startDate;
        const endDateToSend = liveBudgetToggle ? moment(mostRecentSaturday).add(6, 'days').format('YYYY-MM-DD') : endDate;


        new ApiRequest('bookkeeping', 'getReport', setIsSubmitting, (response) => {
            if (response.authorizationCodeUrl) {
                handleNavigateToQB(response.authorizationCodeUrl);
            } else {
                setReport(response.profitAndLossReport);
                setSReportData(response.chargeStatements);
                if(Object.keys(response.chargeStatements).length === 1){
                    setSelectedCsaFedexId(Object.keys(response.chargeStatements)[0])
                } else {
                    setSelectedCsaFedexId(undefined);
                }
            }
            setReport(response.profitAndLossReport);
        }).withData({startDate: startDateToSend, endDate: endDateToSend, csaFedexIds: selectedCsaFedexIds, fallbackChargeStatements: fallbackReports}).send();
    }

    function handleNavigateToQB(authorizationCodeUrl) {
        const elem = window.document.createElement('a');
        elem.href = authorizationCodeUrl;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }


    function handleSetFallbackReport(csaFedexId, chargeStatementIdentifier) {
        const newObj = structuredClone(fallbackReports);
        newObj[csaFedexId.toString()] = chargeStatementIdentifier;
        setFallbackReports(newObj);
    }

    function handleToggleCsaFedexId(csaFedexId) {
        if (selectedCsaFedexIds.includes(csaFedexId)) {
            setSelectedCsaFedexIds(selectedCsaFedexIds.filter(e => e != csaFedexId));
        } else {
            setSelectedCsaFedexIds([...selectedCsaFedexIds, csaFedexId]);
        }
    }

    function handleReplaceCsaFedexId(csaFedexId) {
        setSelectedCsaFedexIds([csaFedexId]);
    }

    const otherExpensesSection = report?.Rows.Row.find(r => r.group == 'OtherExpenses');
    const otherExpenses = otherExpensesSection?.Summary?.ColData[1]?.value
    const expensesSection = report?.Rows.Row.find(r => r.group == 'Expenses');
    const totalExpenses = validateDecimal(otherExpenses) + validateDecimal(expensesSection?.Summary?.ColData[1]?.value)

    const csaElementsForQBConnection = csas.map((csa) => {
        return (
            <Dropdown.Item key={csa.uid} onClick={() => {setSelectedCsaForConnection(csa.uid)}} active={selectedCsaForConnection == csa.uid}>{csa.csaName}</Dropdown.Item>
        )
    })

    const csaRadioElements = csas.map((csa) => {
        const sReports = chargeStatements.filter(s => s.csaFedexId == csa.fedexId);
        const disabled = !liveBudgetToggle ? sReports.length == 0 : !sReports.some(sr => sr.startDate == mostRecentSaturday);

        return (
            <Form.Check inline disabled={disabled} type='radio' key={csa.uid} checked={!!selectedCsaFedexIds[0] && selectedCsaFedexIds[0] == csa.fedexId} label={csa.csaName} onChange={() => {handleReplaceCsaFedexId(csa.fedexId)}}/>
        )
    })

    const selectedCsaForCsaFedexId = useMemo(() => {
        return csas.find(c => c.fedexId == selectedCsaFedexIds[0]);
    }, [selectedCsaFedexIds])

    const sReportsForSelectedCsaIdentifier = chargeStatements.filter(s => s.csaFedexId == selectedCsaForCsaFedexId?.fedexId);
    const selectedCsaFallback = sReportsForSelectedCsaIdentifier.find(sr => fallbackReports[selectedCsaForCsaFedexId?.fedexId.toString()] == sr.uid);

    const sReportElementsOutsideDateRange = sReportsForSelectedCsaIdentifier.filter(sr => !startDate || !endDate || !(sr.startDate <= endDate && sr.endDate >= startDate)).map((sReport) => {
        const isSelected = selectedCsaFallback?.uid == sReport.uid;

        return (
            <Dropdown.Item key={sReport.uid} active={isSelected} onClick={() => {handleSetFallbackReport(selectedCsaForCsaFedexId.fedexId, sReport.uid)}} style={{display: 'flex', justifyContent: 'space-between', gap: 24}}>
                <span>{`${formatDateRange(sReport.startDate, moment(sReport.startDate).add(6, 'days').format('YYYY-MM-DD'))}`}</span>
                <span style={{color: isSelected ? 'white' : 'green'}}>{usdFormatter.format(sReport.netAmount)}</span>
            </Dropdown.Item>
        )
    })

    const sReportElementsInDateRange = sReportsForSelectedCsaIdentifier.filter(sr => startDate && endDate && sr.startDate <= endDate && sr.endDate >= startDate).map((sReport) => {
        const isSelected = selectedCsaFallback?.uid == sReport.uid;

        return (
            <Dropdown.Item key={sReport.uid} active={isSelected} onClick={() => {handleSetFallbackReport(selectedCsaForCsaFedexId.fedexId, sReport.uid)}} style={{display: 'flex', justifyContent: 'space-between', gap: 24}}>
                <span>{`${formatDateRange(sReport.startDate, moment(sReport.startDate).add(6, 'days').format('YYYY-MM-DD'))}`}</span>
                <span style={{color: isSelected ? 'white' : 'green'}}>{usdFormatter.format(sReport.netAmount)}</span>
            </Dropdown.Item>
        )
    })

    const csaFallbackElement = (
        <Dropdown>
            <Dropdown.Toggle variant="outline-primary" style={{flexWrap: 'nowrap', padding: '2px 6px'}}>
                { selectedCsaFallback ? 
                    `${formatDateRange(selectedCsaFallback.startDate, moment(selectedCsaFallback.startDate).add(6, 'days').format('YYYY-MM-DD'))} | ${usdFormatter.format(selectedCsaFallback.netAmount)}`
                    :
                    'None'
                }
            </Dropdown.Toggle>
            <Dropdown.Menu className="fixed-important">
                <Dropdown.Item onClick={() => {handleSetFallbackReport(selectedCsaForCsaFedexId.fedexId, null)}}>None</Dropdown.Item>
                <Dropdown.Divider/>
                { sReportElementsInDateRange.length > 0 && sReportElementsOutsideDateRange.length > 0 && 
                    <Dropdown.Header>Within Selected Date Range</Dropdown.Header>
                }
                {sReportElementsInDateRange}
                { sReportElementsInDateRange.length > 0 && sReportElementsOutsideDateRange.length > 0 && 
                    <Dropdown.Header>Other</Dropdown.Header>
                }
                {sReportElementsOutsideDateRange}
            </Dropdown.Menu>
        </Dropdown>
    )

    const csaElements = csas.map((csa) => {
        const sReports = chargeStatements.filter(s => s.csaFedexId == csa.fedexId);
        const disabled = !liveBudgetToggle ? sReports.length == 0 : !sReports.some(sr => sr.startDate == mostRecentSaturday);

        const selectedSReport = sReports.find(sr => fallbackReports[csa.fedexId.toString()] == sr.uid);

        const sReportElementsOutsideDateRange = sReports.filter(sr => !startDate || !endDate || !(sr.startDate <= endDate && sr.endDate >= startDate)).map((sReport) => {
            const isSelected = selectedSReport?.uid == sReport.uid;

            return (
                <Dropdown.Item key={sReport.uid} active={isSelected} onClick={() => {handleSetFallbackReport(csa.fedexId, sReport.uid)}} style={{display: 'flex', justifyContent: 'space-between', gap: 24}}>
                    <span>{`${formatDateRange(sReport.startDate, moment(sReport.startDate).add(6, 'days').format('YYYY-MM-DD'))}`}</span>
                    <span style={{color: isSelected ? 'white' : 'green'}}>{usdFormatter.format(sReport.netAmount)}</span>
                </Dropdown.Item>
            )
        })

        const sReportElementsInDateRange = sReports.filter(sr => startDate && endDate && sr.startDate <= endDate && sr.endDate >= startDate).map((sReport) => {
            const isSelected = selectedSReport?.uid == sReport.uid;

            return (
                <Dropdown.Item key={sReport.uid} active={isSelected} onClick={() => {handleSetFallbackReport(csa.fedexId, sReport.uid)}} style={{display: 'flex', justifyContent: 'space-between', gap: 24}}>
                    <span>{`${formatDateRange(sReport.startDate, moment(sReport.startDate).add(6, 'days').format('YYYY-MM-DD'))}`}</span>
                    <span style={{color: isSelected ? 'white' : 'green'}}>{usdFormatter.format(sReport.netAmount)}</span>
                </Dropdown.Item>
            )
        })

        return (
            <tr key={csa.uid} style={{verticalAlign: 'middle'}}>
                <td>
                    { !disabled ? 
                        <Form.Check checked={selectedCsaFedexIds.includes(csa.fedexId)} onChange={() => {handleToggleCsaFedexId(csa.fedexId)}}/>
                    :
                        <OverlayTrigger overlay={
                            <Popover style={{position: 'fixed'}}>
                                <Popover.Body>
                                    This CSA cannot be included because it has no charge statement data
                                </Popover.Body>
                            </Popover>
                        }>
                            <FontAwesomeIcon icon={faExclamationTriangle} style={{color: 'gold'}}/>
                        </OverlayTrigger>

                    }
                </td>
                <td>{csa.csaName}</td>
                { !liveBudgetToggle && 
                    <td>
                        { sReports.length > 0 && 
                            <Dropdown>
                                <Dropdown.Toggle variant="outline-primary" style={{flexWrap: 'nowrap', padding: '2px 6px'}}>
                                    { selectedSReport ? 
                                        `${formatDateRange(selectedSReport.startDate, moment(selectedSReport.startDate).add(6, 'days').format('YYYY-MM-DD'))} | ${usdFormatter.format(selectedSReport.netAmount)}`
                                        :
                                        'None'
                                    }
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fixed-important">
                                    <Dropdown.Item onClick={() => {handleSetFallbackReport(csa.fedexId, null)}}>None</Dropdown.Item>
                                    <Dropdown.Divider/>
                                    { sReportElementsInDateRange.length > 0 && sReportElementsOutsideDateRange.length > 0 && 
                                        <Dropdown.Header>Within Selected Date Range</Dropdown.Header>
                                    }
                                    {sReportElementsInDateRange}
                                    { sReportElementsInDateRange.length > 0 && sReportElementsOutsideDateRange.length > 0 && 
                                        <Dropdown.Header>Other</Dropdown.Header>
                                    }
                                    {sReportElementsOutsideDateRange}
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                    </td>
                }
            </tr>
        )
    })

    const fallbackTooltip = (
        <OverlayTrigger placement='bottom' overlay={
            <Popover style={{position: 'fixed'}}>
                <Popover.Body>
                    When generating a report, any missing charge statement reports will be substituted with the fallback charge statement report.
                </Popover.Body>
            </Popover>
        }>
            <div style={{display: 'flex', gap: 6, alignItems: 'center'}}>
                <span>Fallback</span>
                <FontAwesomeIcon style={{color: 'var(--bs-primary)'}} icon={faQuestionCircle}/>
            </div>
        </OverlayTrigger>
    )

    const numberOfDispatches = (!report && !sReportData) ? 0 : Object.values(sReportData).reduce((acc, el) => {return acc + el.reduce((acc2, el2) => {return acc2 + el2.drivers.filter((driver) => {
        const endDate = moment(el2.startDate).add(6, 'days').format('YYYY-MM-DD');
        if(moment(el2.startDate).isBefore(moment(report.Header.StartPeriod))){
            const dayDiff = moment(report.Header.StartPeriod).diff(moment(el2.startDate), 'days');
            return driver.dayIndex >= dayDiff

        }
        if(moment(endDate).isAfter(moment(report.Header.EndPeriod))){
            const dayDiff = moment(endDate).diff(moment(report.Header.EndPeriod), 'days');
            return driver.dayIndex < 7 - dayDiff
        }
        return true;
    }).length }, 0)}, 0)

    return (
        <LoadingWrapper isLoading={isLoading}>
            <div style={{padding: 16, overflowY: 'auto', height: '100%'}}>
                <Container fluid style={{padding: 0}}>
                    <Row>
                        { showReportContent && 
                            <Col xl={6} style={{marginBottom: 12}}>
                                { csas.length > 0 && 
                                    <Card>
                                        <Card.Header>
                                            <Card.Title>Generate Profit & Loss Report</Card.Title>
                                        </Card.Header>
                                        <Card.Header style={{backgroundColor: 'white', textAlign: 'center'}}>
                                            <Form.Check type='radio' inline checked={liveBudgetToggle} onChange={() => {setLiveBudgetToggle(true)}} label='Live Budget'/>
                                            <Form.Check type='radio' inline checked={!liveBudgetToggle} onChange={() => {setLiveBudgetToggle(false)}} label='Custom Report'/>
                                        </Card.Header>
                                        <Card.Body>
                                            { !liveBudgetToggle && 
                                                <InputGroup style={{marginBottom: 12}}>
                                                    <CustomControl type='date' title='Start Date' value={startDate} onChange={(event) => {setStartDate(event.target.value)}}/>
                                                    <CustomControl type='date' title='End Date' value={endDate} onChange={(event) => {setEndDate(event.target.value)}}/>
                                                </InputGroup>
                                            }
                                            { bookkeepingEnabled == 1 ? 
                                                <QuickTable size='sm' headers={liveBudgetToggle ? ['Include in Report', 'CSA'] : ['Include in Report', 'CSA', fallbackTooltip]} rows={csaElements} noWrap widths={[1, null]}/>
                                            :
                                                <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                                                    <div style={{textAlign: 'center'}}>
                                                        {csaRadioElements}
                                                    </div>
                                                    <p style={{margin: 0, fontWeight: 'bold', opacity: 0.5, fontSize: 14}}>CSAs with no charge statement data have been disabled</p>
                                                    { selectedCsaFedexIds.length > 0 && !liveBudgetToggle &&
                                                        <div style={{display: 'flex', gap: 12, justifyContent: 'space-between', padding: 4, border: '1px solid lightgray', borderRadius: 6}}>
                                                            {fallbackTooltip}
                                                            {csaFallbackElement}
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </Card.Body>
                                        <Card.Footer style={{display: 'flex', justifyContent: 'flex-end'}}>
                                            <div style={{display: 'flex', gap: 8, alignItems: 'center', justifyContent: 'center'}}>
                                                {moment(endDate).diff(moment(startDate), 'weeks') > 52 && <span style={{color: 'red', opacity: .7}}>Date range must be less than one year</span>}
                                                <CustomButton label='Submit' isLoading={isSubmitting} disabled={submitDisabled} onClick={handleGetReport}/>
                                            </div>
                                        </Card.Footer>
                                    </Card>
                                }
                            </Col>
                        }

                        <Col xl={6} style={{marginBottom: 12}}>
                            <Card style={{marginBottom: 12}}>
                                <Card.Header>
                                    <Card.Title>Connect to Quick Books</Card.Title>
                                </Card.Header>
                                <Card.Body style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                                    <span style={{fontWeight: 'bold', opacity: 0.5}}>Once your Quick Books account is connected to TTA, Your Quick Books expenses can be combined with your FedEx charge statement data to generate a profit and loss report</span>
                                    <span style={{fontWeight: 'bold', opacity: 0.5}}>{`The connection between your Quick Books account and your ${bookkeepingEnabled == 1 ? 'company' : 'CSAs'} on Tactical Contractor will expire after 100 days of inactivity. Use this to reconnect your Quick Books account`}</span>
                                </Card.Body>
                                <Card.Footer style={{display: 'flex', justifyContent: 'flex-end', gap: 12}}>
                                    { bookkeepingEnabled == 1 ?
                                        <CustomButton label='Connect Company to Quick Books' onClick={handleRequestQBAuthentication} isLoading={isRequesting}/>
                                        :
                                        <>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="outline-primary">{csas.find(csa => csa.uid == selectedCsaForConnection)?.csaName ?? 'Select CSA'}</Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {csaElementsForQBConnection}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <CustomButton label='Connect CSA to Quick Books' disabled={!selectedCsaForConnection} onClick={handleRequestQBAuthentication} isLoading={isRequesting}/>
                                        </>
                                        
                                    }
                                </Card.Footer>
                            </Card>
                            { showReportContent && 
                                <Card>
                                    <Card.Header>
                                        <Card.Title>Upload Charge Statement</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <ChargeStatementUpload createChargeStatementCallback={(cs) => {
                                            const newArray = Array.from(chargeStatements.filter(c => c.startDate != cs.startDate || c.csaFedexId != cs.csaFedexId));
                                            newArray.push(cs);
                                            setChargeStatements(newArray);
                                        }} matchFedexIdsCallback={loadData}/>
                                    </Card.Body>
                                </Card>
                            }
                        </Col>                        
                    </Row>
                </Container>
                { report && 
                    <Card>
                        <Card.Header>
                            <Card.Title>{`Profit & Loss for ${formatDateRange(report.Header.StartPeriod, report.Header.EndPeriod)}`}</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            { csas.filter((csa) => Object.keys(sReportData).some((key)=> csa.fedexId == key))?.length > 1 &&
                                <Dropdown style={{marginBottom: 12}}>
                                    <Dropdown.Toggle variant="outline-primary">
                                        {`Viewing revenue for ` + `${csas.find((csa) => csa.fedexId === selectedCsaFedexId)?.csaName ? csas.find((csa) => csa.fedexId === selectedCsaFedexId)?.csaName : 'all CSAs'}`}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <DropdownItemWithCheck label={'All'} active={selectedCsaFedexId == undefined} onClick={() => setSelectedCsaFedexId(undefined)}/>
                                        {csas.filter((csa) => Object.keys(sReportData).some((key)=> csa.fedexId == key)).map((csa) => {
                                            return <DropdownItemWithCheck key={csa.uid} label={csa.csaName} active={selectedCsaFedexId == csa.fedexId} onClick={() => setSelectedCsaFedexId(csa.fedexId)}/>
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>

                            }
                            <Container fluid style={{padding: 0}}>
                                <Row>
                                    <Col xl={6} style={{marginBottom: 12}}>
                                        <div style={{padding: '0 12px 12px 12px', display: 'flex', flexDirection: 'column', gap: 12, border: '1px solid lightgray', borderRadius: 6,}}>
                                            <BookkeepingChargeStatementsRevenue sReportData={sReportData} startDate={report.Header.StartPeriod} endDate={report.Header.EndPeriod} selectedCsaFedexId={selectedCsaFedexId} totalRevenue={totalRevenue} setTotalRevenue={setTotalRevenue}/>
                                            {Object.keys(sReportData).length > 1 && <BookkeepingCharts sReportData={sReportData} selectedCsaFedexId={selectedCsaFedexId} startDate={report.Header.StartPeriod} endDate={report.Header.EndPeriod} totalExpenses={totalExpenses}/>}
                                        </div>    
                                    </Col>
                                    <Col xl={6} style={{marginBottom: 12}}>
                                        { !!totalExpenses ? 
                                            <div style={{padding: 12, display: 'flex', flexDirection: 'column', gap: 12, border: '1px solid lightgray', borderRadius: 6,}}>
                                                <BookkeepingReportSection section={expensesSection} fontSize={18} isRoot/>
                                                {!!otherExpenses && <BookkeepingReportSection section={otherExpensesSection} fontSize={18} isRoot/>}
                                                <div style={{display: 'flex', gap: 6, fontWeight: 'bold', fontSize: 18}}>
                                                    <span style={{fontWeight: 'bold'}}>Grand Total Expenses</span>
                                                    <div style={{flex: 1, borderBottom: '2px dotted lightgray', marginBottom: 5}}/>
                                                    <span style={{fontWeight: 'bold', color: 'red'}}>{usdFormatter.format(totalExpenses)}</span>
                                                </div>
                                            </div>
                                        :
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                <span style={{fontWeight: 'bold', opacity: '0.5'}}>No Quick Books Expenses to Display</span>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                        { !!totalExpenses &&
                            <Card.Footer style={{backgroundColor: 'white'}}>
                            </Card.Footer>
                        }
                        { !!totalExpenses &&
                            <Card.Footer style={{backgroundColor: 'white'}}>
                                <div style={{display: 'flex', justifyContent: 'center', gap: 36}}>
                                    <h6 style={{fontWeight: 'bold', margin: 0}}>Revenue Per Dispatch: &nbsp;<span style={{color: 'green'}}>{usdFormatter.format(totalRevenue/numberOfDispatches)}</span></h6>
                                    <h6 style={{fontWeight: 'bold', margin: 0}}>Cost Per Dispatch: &nbsp;<span style={{color: 'red'}}>{usdFormatter.format(totalExpenses/numberOfDispatches)}</span></h6>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', gap: 36, marginTop: 8}}>
                                    <h5 style={{fontWeight: 'bold', margin: 0}}>Total Profit: &nbsp;<span style={{color: validateDecimal(totalRevenue-totalExpenses) > 0 ? 'green' : 'red'}}>{usdFormatter.format(totalRevenue-totalExpenses)}</span></h5>
                                    <h5 style={{fontWeight: 'bold', margin: 0}}>Profit Margin: &nbsp;<span style={{color: validateDecimal(totalRevenue-totalExpenses) > 0 ? 'green' : 'red'}}>{validateDecimalFixed((totalRevenue-totalExpenses)/totalRevenue, 3)*100+ '%'}</span></h5>
                                </div>
                            </Card.Footer>
                        }
                    </Card>
                }
            </div>
        </LoadingWrapper>
    )
}


