/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { addressToString } from '../../../tools';
import KeyValueRow from '../../../components/KeyValueRow';
import FileDownloadButton from '../../../components/FileDownloadButton';
import HRUserBWCVersionHeader from './HRUserBWCVersionHeader';
import { ApiRequest } from '../../../ApiManager.tsx';
import LoadingWrapper from '../../../components/LoadingWrapper';

export default function HRUserBWCDetails({users, bwcRecord, reviseRecord, userIsDisabled}){
    const [isLoading, setIsLoading] = useState(true);
    const [versions, setVersions] = useState([bwcRecord.latestVersion]);
    const [selectedVersion, setSelectedVersion] = useState(bwcRecord.latestVersion);

    useEffect(()=>{
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('hr', 'getBwcRecord', setIsLoading, (response) => {setVersions(response.data.versions); setSelectedVersion(response.data.versions[0])}).withUid(bwcRecord.uid).withNoAlertOnSuccess().send();
    }
    
    function getNumberOfDaysMissed(bwc){
        let daysOffWork = 0;
        if(!bwc.rtwLightDutyDate && !bwc.rtwFullDutyDate){
                daysOffWork = moment().diff(moment(bwc.injuryReportDate),'days');
        } else if (bwc.rtwLightDutyDate){
                daysOffWork = moment(bwc.rtwLightDutyDate).diff(moment(bwc.injuryReportDate),'days');
        } else if(!bwc.rtwLightDutyDate && bwc.rtwFullDutyDate){
                daysOffWork = moment(bwc.rtwFullDutyDate).diff(moment(bwc.injuryReportDate),'days');
        }
        return daysOffWork > 0 ? daysOffWork : 0;
    }

    const reportedTo = selectedVersion ? users.find(u => u.companyUserUid == selectedVersion.injuryReportedTo): undefined;
    const attachmentButtons = !selectedVersion || !selectedVersion?.attachments ? '' : JSON.parse(selectedVersion.attachments).map((attachment, index) => {
        return (
            <ListGroup.Item key={attachment}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <span>{'Custom Attachment #' + (index + 1)}</span>
                    <FileDownloadButton value={attachment} type='hr' target='downloadCustomDocument'/>
                </div>
            </ListGroup.Item>
        )
    });

    const content = !selectedVersion ? '' : (
        <>
            <Modal.Header closeButton>
                <Modal.Title>BWC Record</Modal.Title>
                { userIsDisabled ? '' :
                    <Button variant={'outline-primary'} style={{marginLeft: 12}} onClick={reviseRecord}>Revise Record</Button>
                }
            </Modal.Header>
            <HRUserBWCVersionHeader 
                versions={versions} 
                selectedVersion={selectedVersion} 
                setSelectedVersion={(value) => setSelectedVersion(value)} 
                isLoading={isLoading}
            />
            <Modal.Body>
                <ListGroup>
                    <KeyValueRow isListItem title={'Date of Injury'} value={selectedVersion.dateOfInjury ? moment(selectedVersion.dateOfInjury).format('MMM D, YYYY') : ''}/>
                    <KeyValueRow isListItem title={'Injury Reported to'} value={reportedTo ? reportedTo.firstName + ' ' + reportedTo.lastName : ''}/>
                    <KeyValueRow isListItem title={'Date Reported'} value={selectedVersion.injuryReportDate ? moment(selectedVersion.injuryReportDate).format('MMM D, YYYY') : ''}/>
                    { bwcRecord.driverStatement && 
                        <KeyValueRow isListItem title={'Driver Statement'} value={bwcRecord.driverStatement}/>
                    }
                    <KeyValueRow isListItem title={'Treatment Facility Name'} value={selectedVersion.treatmentFacilityName}/>
                    <KeyValueRow isListItem title={'Treatment Facility Address'} value={addressToString(selectedVersion.treatmentFacilityAddress)}/>
                    <KeyValueRow isListItem title={'Date & Time Admitted to Facility'} value={selectedVersion.treatmentFacilityAdmittedDateTime ? moment(selectedVersion.treatmentFacilityAdmittedDateTime).format('MMM D, YYYY [at] h:mm A') : ''}/>
                    <KeyValueRow isListItem title={'Salary Continuation?'} value={selectedVersion.salaryContinuation ? 'Yes' : 'No'}/>
                    <KeyValueRow isListItem title={'Date Returned to Work for Light Duty'} value={selectedVersion.rtwLightDutyDate ? moment(selectedVersion.rtwLightDutyDate).format('MMM D, YYYY') : ''}/>
                    <ListGroup.Item>
                        <p>Description of Light Duty</p>
                        <p style={{marginBottom: 0, opacity: 0.5}}>{selectedVersion.lightDutyDescription}</p>
                    </ListGroup.Item>
                    <KeyValueRow isListItem title={'Date Returned to Work for Full Duty'} value={selectedVersion.rtwFullDutyDate ? moment(selectedVersion.rtwFullDutyDate).format('MMM D, YYYY') : ''}/>
                    <KeyValueRow isListItem title={'Number of Days Missed'} value={getNumberOfDaysMissed(selectedVersion)}/>
                    <KeyValueRow isListItem title={'BWC Injury Claim #'} value={selectedVersion.bwcInjuryClaimNumber}/>
                    <KeyValueRow isListItem title={'Event Location'} value={selectedVersion.eventLocation}/>
                    <KeyValueRow isListItem title={'Injury or Illness'} value={selectedVersion.injuryOrIllness}/>
                    <KeyValueRow isListItem title={'Classify Case'} value={selectedVersion.classifyCase}/>
                    <ListGroup.Item>
                        <p>Injury / Illness Description</p>
                        <p style={{marginBottom: 0, opacity: 0.5}}>{selectedVersion.notes}</p>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <span>Physician's Report of Work Ability (MEDCO-14)</span>
                            { selectedVersion.medco14 ?
                                <FileDownloadButton value={selectedVersion.medco14} type='hr' target='downloadCustomDocument'/>
                            : 'None'}
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <span>Salary Continuation Agreement (C-55)</span>
                            { selectedVersion.c55 ?
                                <FileDownloadButton value={selectedVersion.c55} type='hr' target='downloadCustomDocument'/>
                            : 'None'}
                        </div>
                    </ListGroup.Item>
                    {attachmentButtons}
                </ListGroup>
            </Modal.Body>
        </>
    )

    return (
       <LoadingWrapper isLoading={isLoading}>
            {content}
       </LoadingWrapper>
    )
}