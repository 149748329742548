/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/


import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Dropdown, InputGroup, OverlayTrigger } from "react-bootstrap";
import PageSpinner from "../../../components/PageSpinner.js";
import moment from "moment";
import QuickTable from "../../../components/QuickTable.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { Popover } from "react-bootstrap";
import ChargeStatementUpload from '../../Bookkeeping/ChargeStatement/ChargeStatementUpload.js'
import { ApiRequest } from "../../../ApiManager.tsx";
import ChargeStatementReport from "./ChargeStatementReport.js";
import LoadingWrapper from "../../../components/LoadingWrapper.js";
import DropdownItemWithCheck from "../../../components/DropdownItemWithCheck.js";
import CustomControl from "../../../components/CustomControl.js";

export default function ChargeStatements() {
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [chargeStatements, setChargeStatements] = useState([]);
    const [selectedChargeStatement, setSelectedChargeStatement] = useState(undefined);

    const [csas, setCsas] = useState([])
    const [selectedCsas, setSelectedCsas] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('reports', 'getAllChargeStatements', setIsLoading, (response) => {
            setChargeStatements(response.chargeStatements)
            const csas = response.chargeStatements.reduce((acc, el) => {
                if(acc.includes(el.csaFedexId)){
                    return acc;
                }
                acc.push(el.csaFedexId);
                return acc;
            }, [])
            setCsas(csas)
            setSelectedCsas(csas)
        }).withNoAlertOnSuccess().send();
    }

    function createChargeStatementCallback(chargeStatement){
        let newArray = Array.from(chargeStatements);
        newArray = newArray.filter(cs => (cs.startDate != chargeStatement.startDate || cs.csaFedexId != chargeStatement.csaFedexId))
        newArray.push(chargeStatement);
        setChargeStatements(newArray);
    }

    function matchFedexIdsCallback(_matchedUsers, _matchedCsa, _users, _csas){
        loadData();
    }

    function sortSettlementReports(a, b) {
        if (a.startDate != b.startDate) {
            return a.startDate < b.startDate ? -1 : 1;
        } else {
            return a.csaName < b.csaName ? -1 : a.csaName > b.csaName ? 1 : 0;
        }
    }

    function handleSelectedCsas(csaFedexId){
        let newArray = Array.from(selectedCsas)
        if(newArray.includes(csaFedexId)){
            newArray = newArray.filter(id => id !== csaFedexId)
        } else{
            newArray.push(csaFedexId)
        }
        setSelectedCsas(newArray)
    }

    const chargeStatementRows = chargeStatements.filter(cs => {
        if(startDate && moment(cs.startDate).isBefore(startDate)){
            return false;
        }

        if(endDate && moment(cs.startDate).isAfter(endDate)){
            return false;
        }
        return selectedCsas.includes(cs.csaFedexId)
    }).sort(sortSettlementReports).map((cs) => {
        return (
            <tr key={cs.uid} style={{cursor: 'pointer'}} onClick={() => {setSelectedChargeStatement(cs); setShowModal(true)}}>
                <td>
                    {cs?.csaName 
                    ? cs.csaName 
                    :  
                        <>
                            {cs.csaFedexId}&nbsp;
                            <OverlayTrigger placement='bottom' overlay={
                                    <Popover style={{position: 'fixed'}} placement='bottom'>
                                        <Popover.Body>
                                            This charge statement is not currently matched with any CSAs.
                                        </Popover.Body>
                                    </Popover>
                                }>
                                <FontAwesomeIcon icon={faTriangleExclamation} style={{color: 'gold'}}/>
                            </OverlayTrigger>
                        </>
                    }
                </td>
                <td>{`${moment(cs.startDate).format('MMM D, YYYY')} - ${moment(cs.startDate).add(6, 'days').format('MMM D, YYYY')}`}</td>
            </tr>
        )
    }) 

    const csaFilters = (
        <Dropdown autoClose='outside'>
            <Dropdown.Toggle variant="outline-primary">
                Filter by CSAs
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Header>All</Dropdown.Header>
                <Dropdown.Item onClick={() => setSelectedCsas(csas)}>Select All</Dropdown.Item>
                <Dropdown.Item onClick={() => setSelectedCsas([])}>Unselect All</Dropdown.Item>
                <Dropdown.Divider/>
                <Dropdown.Header>CSAs</Dropdown.Header>
                {csas.filter(csaFedexId => chargeStatements.find(cs => cs.csaFedexId === csaFedexId)?.csaName).map(csaFedexId => {
                    const csaName = chargeStatements.find(cs => csaFedexId == cs.csaFedexId)?.csaName;
                    return <DropdownItemWithCheck key={csaFedexId} active={selectedCsas.includes(csaFedexId)} label={csaName ?? csaFedexId} onClick={() => handleSelectedCsas(csaFedexId)}/>
                })}
                <Dropdown.Divider/>
                <Dropdown.Header>Unmatched CSAs</Dropdown.Header>
                {csas.filter(csaFedexId => !chargeStatements.find(cs => cs.csaFedexId === csaFedexId)?.csaName).map(csaFedexId => {
                    return <DropdownItemWithCheck key={csaFedexId} active={selectedCsas.includes(csaFedexId)} label={csaFedexId} onClick={() => handleSelectedCsas(csaFedexId)}/>
                })}
            </Dropdown.Menu>
        </Dropdown>
    )

    
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Charge Statements</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoadingWrapper isLoading={isLoading}>
                <div>
                    <div style={{marginBottom: 16}}>
                        <ChargeStatementUpload createChargeStatementCallback={createChargeStatementCallback} matchFedexIdsCallback={matchFedexIdsCallback}/>
                    </div>
                    <div style={{marginBottom: 12}}>
                        <InputGroup style={{marginBottom: 12}}>
                            <CustomControl type='date' title='Start Date' value={startDate} onChange={(event) => {setStartDate(event.target.value)}}/>
                            <CustomControl type='date' title='End Date' value={endDate} onChange={(event) => {setEndDate(event.target.value)}}/>
                         </InputGroup>
                        {csaFilters}
                    </div>
                    <QuickTable hover headers={['CSA', 'Week']} widths={[null, null]} rows={chargeStatementRows}/>
                </div>
                </LoadingWrapper>
            </Modal.Body>
            <Modal show={showModal} onHide={() => setShowModal(false)} fullscreen>
                <ChargeStatementReport selectedChargeStatement={selectedChargeStatement} chargeStatements={chargeStatements} callback={loadData}/>
            </Modal>
        </>
    )
}