/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import './ScheduleMatch.css';
import React, {useState} from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import QuickTimeSelector from '../../components/QuickTimeSelector/QuickTimeSelector';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { scheduleTypeDictionary } from '../../tools';
import CustomButton from '../../components/CustomButton';
import { ApiRequest } from '../../ApiManager.tsx';

export default function ScheduleItemCreator({csas, selectedCsas, users, selectedDay, selectedScheduleItem, setSelectedScheduleItem, handleSetScheduleItems, setShowScheduleItemModal}) {
    const userForSelectedItem = selectedScheduleItem ? users.find(user => user.companyUserUid == selectedScheduleItem.companyUserIdentifier) : undefined;
    const [showUserDropdown, setShowUserDropdown] = useState(false);
    const [selectedScheduleType, setSelectedScheduleType] = useState(selectedScheduleItem ? selectedScheduleItem.scheduleType : 'managerOnDuty');
    const [selectedUser, setSelectedUser] = useState(userForSelectedItem);
    const [date, setDate] = useState(selectedScheduleItem ? selectedScheduleItem.date : selectedDay.format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(selectedScheduleItem && selectedScheduleItem.endDate ? selectedScheduleItem.endDate : date);
    const [timeScheduled, setTimeScheduled] = useState(selectedScheduleItem && selectedScheduleItem.timeScheduled ? selectedScheduleItem.timeScheduled : '08:00');
    const [notes, setNotes] = useState(selectedScheduleItem ? selectedScheduleItem.notes : '');
    const [usersCsasVisible, setUsersCsasVisible] = useState(false);
    const [userSearch, setUserSearch] = useState(userForSelectedItem ? userForSelectedItem.firstName + ' ' + userForSelectedItem.lastName : '');
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    function handleSave(){
        const itemToSave = {
            scheduleType: selectedScheduleType,
            companyUserIdentifier: selectedUser.companyUserUid,
            date: date,
            notes: notes,
            timeScheduled: timeScheduled
        }
        new ApiRequest('scheduleMatch', 'createScheduleItem', setIsLoading, (response) => {
            itemToSave.uid = response.uid;
                handleSetScheduleItems('create', itemToSave);
                setSelectedScheduleItem(undefined);
                setShowScheduleItemModal(false);
        })
        .withData({data: itemToSave}).send();
    }

    function handleDeleteScheduleItem(){
        new ApiRequest('scheduleMatch', 'deleteScheduleItem', setIsDeleting, () => {
            handleSetScheduleItems('delete', selectedScheduleItem);
            setSelectedScheduleItem(undefined);
            setShowScheduleItemModal(false);
        }).withUid(selectedScheduleItem.uid).send();
    }

    function handleSelectUser(user){
        setSelectedUser(user);
        setUserSearch(user.firstName + ' ' + user.lastName);
        setShowUserDropdown(false);
    }

    const scheduleTypeButtons = ['managerOnDuty', 'paidTraining', 'auxiliaryWorker'].map((key) => {
        return (
            <Form.Check 
                key={key} 
                disabled={selectedScheduleItem} 
                inline 
                type='radio' 
                name='scheduleType' 
                checked={selectedScheduleType ? selectedScheduleType === key : false} 
                value={key} 
                label={scheduleTypeDictionary[key].label} 
                onChange={(event) => {setSelectedScheduleType(event.target.value)}}
            />
        )
    });

    users.sort((a,b)=>{
        if (a.lastName < b.lastName) {
            return -1;
          } else if (a.lastName > b.lastName) {
            return 1;
          } else {
            return 0;
          }
    });
    const selectedCsaIds = selectedCsas.map(csa => csa.uid);
    const userDropDownItems = users.filter(u =>usersCsasVisible ? (u.companyUserIsEnabled !== 0 && (u.firstName + ' ' + u.lastName).toLowerCase().includes(userSearch.toLowerCase())) : selectedCsaIds.includes(u.csaIdentifier) && (u.companyUserIsEnabled !== 0 && (u.firstName + ' ' + u.lastName).toLowerCase().includes(userSearch.toLowerCase()))).map((user) => {
        return (
            <Dropdown.Item key={user.uid} eventKey={user.uid} active={selectedUser && user.uid === selectedUser.uid} onClick={() => handleSelectUser(user)}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <p style={{marginBottom: 0, marginRight: 8}}>{user.firstName + ' ' +(user.middleName ? user.middleName + ' ' : '') + user.lastName}</p>
                    <p style={{margin: 0}}>
                        {'(' + csas.find(csa => csa.uid === user.csaIdentifier).csaName + ')'}
                    </p>
                </div>
            </Dropdown.Item>
        )
    });

    return (
        <>
            <Modal.Header closeButton>
                { selectedScheduleItem ?
                    <div style={{display: 'flex', alignItems: 'center', gap: 12}}>
                        <Modal.Title>
                            {scheduleTypeDictionary[selectedScheduleItem.scheduleType].label + ' | ' + userForSelectedItem.firstName + ' ' + userForSelectedItem.middleName.substring(0,1) + ' ' + userForSelectedItem.lastName}
                        </Modal.Title>
                        { (['managerOnDuty', 'paidTraining', 'auxiliaryWorker', 'callOff', 'noShow'].includes(selectedScheduleItem.scheduleType)) &&
                            <CustomButton label='Delete Schedule Item' isLoading={isDeleting} variant={'outline-primary'} onClick={handleDeleteScheduleItem}/>
                        }
                    </div>
                    :
                    <Modal.Title>Creating New Schedule item</Modal.Title>
                }
            </Modal.Header>
            <Modal.Body>
                <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                    { (!selectedScheduleItem || (selectedScheduleItem.scheduleType === 'managerOnDuty' || selectedScheduleItem.scheduleType === 'paidTraining' || selectedScheduleItem.scheduleType === 'auxiliaryWorker')) &&
                        <Form.Group>
                            {scheduleTypeButtons}
                            <div style={{marginTop:5}}>
                            <Form.Check disabled={selectedScheduleItem} type='switch' checked={usersCsasVisible} label='View users from all CSAs' onChange={()=>setUsersCsasVisible(!usersCsasVisible)}/>
                            </div>
                        </Form.Group>
                    }
                    <Form.Group>
                        <Form.Label>User</Form.Label>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4}}>
                            { selectedUser && !selectedCsas.find(csa => csa.uid === selectedUser.csaIdentifier) ?
                                <OverlayTrigger overlay={
                                    <Tooltip>User does not belong to the selected CSA(s). This schedule item will not be visible unless the user's CSA is selected.</Tooltip>
                                }>
                                    <FontAwesomeIcon style={{color: 'gold'}} icon={faExclamationCircle}/>
                                </OverlayTrigger>
                                
                                : ''
                            }
                            
                            <Form.Control 
                                type='text' 
                                value={userSearch} 
                                placeholder='Type here to search'
                                onChange={(event) => setUserSearch(event.target.value)} 
                                onFocus={() => setShowUserDropdown(true)} 
                                onBlur={() => setTimeout(() => {setShowUserDropdown(false)}, 150)}
                                isValid={selectedUser && users.find(u => (u.firstName + ' ' + u.lastName) === userSearch)} 
                                disabled={selectedScheduleItem}
                            />
                        </div>
                        <Form.Control.Feedback type='invalid'>Required</Form.Control.Feedback>
                        <Dropdown.Menu onMouseDown={(e) => e.preventDefault()} show={showUserDropdown} style={{maxHeight: 300, overflowY: 'scroll'}}>
                            {userDropDownItems}
                        </Dropdown.Menu>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Date</Form.Label>
                        <Form.Control 
                            type='date' 
                            disabled={selectedScheduleItem} 
                            max={selectedScheduleType === 'vacation' || selectedScheduleType === 'requestOff' ? endDate : '9999-12-31'} 
                            value={date} 
                            onChange={(event) => {setDate(event.target.value)}} 
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Time Scheduled</Form.Label>
                        {
                            selectedScheduleItem ?
                            <Form.Control type='text' disabled={true} value={moment(moment().format('YYYY-MM-DD') + ' ' + timeScheduled).format('h:mm A')}/>
                            :
                            <QuickTimeSelector selectedTime={timeScheduled} setSelectedTime={setTimeScheduled}/>
                        }
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Notes</Form.Label>
                        <Form.Control 
                            type='text'
                            max={65535} 
                            value={notes} 
                            disabled={selectedScheduleItem}
                            onChange={(event) => {setNotes(event.target.value)}}
                        />
                    </Form.Group>
                    { selectedScheduleItem ? '' : <CustomButton disabled ={!selectedUser} label='Save' isLoading={isLoading} onClick={handleSave}/> }
                </div>
            </Modal.Body>
        </>
    )
}