/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import CustomControl from './CustomControl';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { stateDictionary } from '../tools';
import { ValidationGroup, validateStateSelection } from '../validation';
import { Dropdown, Col, FloatingLabel } from 'react-bootstrap';
import { useEffect } from 'react';


function AddressControl({title, originalValue, address, setAddress, xs, sm, md, lg, xl, xxl, validator, disabled, forceTouched}) {

    const originalStateSearch = () =>{
        let stateSearch='';
        for(const key in stateDictionary){
            if(address.administrativeArea === stateDictionary[key]){
                stateSearch = key;
            }
            if(address.administrativeArea == key){
                stateSearch = key;
            }
        }
        return stateSearch;
    }
    const[showDropdown, setShowDropdowm] = useState(false);
    const[stateSearch, setStateSearch] = useState(originalStateSearch);
    const [touched, setTouched] = useState(false);

    
    const addressComponents = [
        {key: 'thoroughfare', title: 'Street Address 1', max: 50},
        {key: 'premise', title: 'Street Address 2', max: 30},
        {key: 'locality', title: 'City', max: 20},
        {key: 'administrativeArea', title: 'State', max: 10},
        {key: 'postalCode', title: 'Postal Code', max: 5}
    ];

    const handleSelectState = (stateValue) => {
        const newAddress = structuredClone(address);
        newAddress.administrativeArea = stateDictionary[stateValue] ? stateDictionary[stateValue] : '';
        setAddress(newAddress);
        setStateSearch(stateValue);
    }

    const stateDropDownItems = Object.entries(stateDictionary).map(([key, value]) =>{
        return (
            <Dropdown.Item key={value} eventKey={key} active={stateSearch === key} onClick={() => {handleSelectState(key, value); setShowDropdowm(false)}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <p style={{marginBottom: 0, marginRight: 8}}>{key}</p> 
                </div>
            </Dropdown.Item>  
        )
    });

    const handleSetAddress = (key, value) => {
        const newAddress = structuredClone(address);
        newAddress[key] = value;
        setAddress(newAddress);
    }
    
    const addressComponentElements = addressComponents.map((item) => {
       
        return (
            
                item.key === 'administrativeArea' ?
                <Col key={item.key} xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} style={{marginBottom: 8, position: 'relative'}} >
                    <FloatingLabel label='State'>
                        <Form.Control readOnly placeholder='State' key={`form-control-${item.key}`} isInvalid={validator && !validator[item.key].isValid()} isValid={validator && validator[item.key].isValid()} disabled={disabled} type='text' value ={stateSearch} onChange={(event)=>{handleSelectState(event.target.value)}} onFocus={()=>setShowDropdowm(true)} onBlur={() => setTimeout(() => {setShowDropdowm(false);setTouched(true)}, 150)} />
                    </FloatingLabel>
                    <Dropdown.Menu onMouseDown={(e)=>e.preventDefault()} key="dropdown-menu" show={showDropdown} style={{maxHeight: 300, overflowY: 'scroll'}}>
                        {stateDropDownItems}
                    </Dropdown.Menu>
                </Col>
                :
                <CustomControl type='text' key={item.key} disabled={disabled} maxLength={item.max} validator={validator ? validator[item.key] : undefined} originalValue={originalValue ? originalValue[item.key] : undefined} title={item.title} value={address[item.key] ? address[item.key] : ''} onChange={(event) => {handleSetAddress(item.key, event.target.value)}} xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}/>
            
        )
    })    

    //{thoroughfare: '456 fake ave.', premise: '', administrativeArea: 'Ohio', locality: 'Hudson', postalCode: '44236'},
    return (
        <Form.Group>
            <Form.Label>{title}</Form.Label>
            <Container fluid style={{border: '1px solid lightgray', borderRadius: 6, padding: 12, marginBottom: 8}}>
                <Row>
                    {addressComponentElements}
                </Row>
            </Container>
        </Form.Group>
    )
}

export default AddressControl;