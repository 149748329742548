/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect, useState } from "react";
import { ApiRequest } from "../../../ApiManager.tsx";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";
import { getFakeUID, validateDecimal } from "../../../tools.js";
import QuickTable from "../../../components/QuickTable.js";
import WeeklyServiceWorksheetMatch from "../../Bookkeeping/WeeklyServiceWorksheets/WeeklyServiceWorksheetMatch.js";
import LoadingWrapper from "../../../components/LoadingWrapper.js";

export default function WeeklyServiceWorksheetReport({selectedWsw, callback}){
    const [isLoading, setIsLoading] = useState(false);
    const [csaIsUnmatched, setCsaIsUnmatched] = useState(selectedWsw?.csaName ? false : true);
    const [weeklyServiceWorksheet, setWeeklyServiceWorksheet] = useState(null);
    const [unmatchedWswNames, setUnmatchedWswNames] = useState([]);
    const [unmatchedCsaFedexIds, setUnmatchedCsaFedexIds] = useState(selectedWsw?.csaName ? [] : [selectedWsw.csaFedexId]);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const unmatchedWswNames = weeklyServiceWorksheet?.wswRows?.reduce((acc, el) => {
            if(el.firstName || acc.includes(el.wswName) || el.wswName === null){
                return acc;
            }
            acc.push(el.wswName);
            return acc;
        }, [])
        setUnmatchedWswNames(unmatchedWswNames);
    }, [showModal])

    useEffect(() => {
        loadData();
    }, []);

    function loadData(successCallback){
        new ApiRequest('reports', 'getWeeklyServiceWorksheet', setIsLoading, (response) => {
            setWeeklyServiceWorksheet(response.weeklyServiceWorksheet);
            const unmatchedWswNames = response.weeklyServiceWorksheet?.wswRows?.reduce((acc, el) => {
                if(el.firstName || acc.includes(el.wswName) || el.wswName === null){
                    return acc;
                }
                acc.push(el.wswName);
                return acc;
            }, [])
            setUnmatchedWswNames(unmatchedWswNames);
            if(successCallback){
                successCallback()
            }
        }).withUid(selectedWsw.uid).withNoAlertOnSuccess().send();
    }

    function matchWswNamesCallback(_matchedUsers, _matchedCsas, _users, _csas){
        loadData(callback);
        setShowModal(false);
    }

    function findDriverDayIndexValue(dayIndex, wswName, type){
        return weeklyServiceWorksheet?.wswRows?.find((row) => row.dayIndex == dayIndex && wswName === row.wswName)?.[type] ?? 0;
    }

    function getDriverTypeTotal(wswName, type){
        return weeklyServiceWorksheet?.wswRows?.reduce((acc, el) => {if(el.wswName !== wswName){return acc}{return acc + validateDecimal(el[type])}}, 0)
    }

    const driverTables = weeklyServiceWorksheet?.wswRows?.filter(wswRow => wswRow.wswName).reduce((acc, el) => {
            if(acc.some(obj => obj.wswName == el.wswName)){
                return acc;
            }
            acc.push(el);
            return acc;
    }, []).map((wswRow, index) => {

        const driverDayRows = [0, 1, 2, 3, 4, 5, 6].filter(dayIndex => weeklyServiceWorksheet?.wswRows.find(row => row.dayIndex === dayIndex && wswRow.wswName === row.wswName)).map((dayIndex) => {
            return (
                <tr key={dayIndex}>
                    <td style={{whiteSpace: 'nowrap'}}>{moment(weeklyServiceWorksheet.startDate).add(dayIndex, 'days').format('MMM D (ddd)')}</td>
                    <td>{findDriverDayIndexValue(dayIndex, wswRow.wswName, 'workAreaName')}</td>
                    <td>{findDriverDayIndexValue(dayIndex, wswRow.wswName, 'workAreaFedexId')}</td>
                    <td>{findDriverDayIndexValue(dayIndex, wswRow.wswName, 'vehicleFedexId')}</td>
                    <td>{findDriverDayIndexValue(dayIndex, wswRow.wswName, 'preloadVScanPackages')}</td>
                    <td>{findDriverDayIndexValue(dayIndex, wswRow.wswName, 'preloadDlStops')}</td>
                    <td>{findDriverDayIndexValue(dayIndex, wswRow.wswName, 'preloadPuStops')}</td>
                    <td>{findDriverDayIndexValue(dayIndex, wswRow.wswName, 'preloadDlStops') - findDriverDayIndexValue(dayIndex, wswRow.wswName, 'actualDlStops')}</td>
                    <td>{findDriverDayIndexValue(dayIndex, wswRow.wswName, 'actualDlStops')}</td>
                    <td>{findDriverDayIndexValue(dayIndex, wswRow.wswName, 'actualDlPackages')}</td>
                    <td>{findDriverDayIndexValue(dayIndex, wswRow.wswName, 'actualPuStops')}</td>
                    <td>{findDriverDayIndexValue(dayIndex, wswRow.wswName, 'actualPuPackages')}</td>
                    <td>{findDriverDayIndexValue(dayIndex, wswRow.wswName, 'actualPuStops') + findDriverDayIndexValue(dayIndex, wswRow.wswName, 'actualPuStops')}</td>
                    <td>{findDriverDayIndexValue(dayIndex, wswRow.wswName, 'actualPuPackages') + findDriverDayIndexValue(dayIndex, wswRow.wswName, 'actualDlPackages')}</td>
                    <td>{findDriverDayIndexValue(dayIndex, wswRow.wswName, 'miles')}</td>
                </tr>
            )
        });

        const totalRow = (
            <tr key={7}>
                <td>Totals</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>{getDriverTypeTotal(wswRow.wswName, 'preloadVScanPackages')}</td>
                <td>{getDriverTypeTotal(wswRow.wswName, 'preloadDlStops')}</td>
                <td>{getDriverTypeTotal(wswRow.wswName, 'preloadPuStops')}</td>
                <td>{getDriverTypeTotal(wswRow.wswName, 'preloadDlStops') - getDriverTypeTotal(wswRow.wswName, 'actualDlStops')}</td>
                <td>{getDriverTypeTotal(wswRow.wswName, 'actualDlStops')}</td>
                <td>{getDriverTypeTotal(wswRow.wswName, 'actualDlPackages')}</td>
                <td>{getDriverTypeTotal(wswRow.wswName, 'actualPuStops')}</td>
                <td>{getDriverTypeTotal(wswRow.wswName, 'actualPuPackages')}</td>
                <td>{getDriverTypeTotal(wswRow.wswName, 'actualDlStops') + getDriverTypeTotal(wswRow.wswName, 'actualPuStops')}</td>
                <td>{getDriverTypeTotal(wswRow.wswName, 'actualPuPackages') + getDriverTypeTotal(wswRow.wswName, 'actualDlPackages')}</td>
                <td>{getDriverTypeTotal(wswRow.wswName, 'miles')}</td>
            </tr>
        )

        return (
            <div key={getFakeUID()} style={{marginBottom: 36}}>
                <h5 style={{paddingLeft: 8, marginBottom: 12}}>{wswRow.firstName ? `${wswRow.firstName} ${wswRow.middleName} ${wswRow.lastName}` : wswRow.wswName ? `${wswRow.wswName} (Not Matched)` : ''}</h5>
                <div style={{}}>
                    <QuickTable size='sm' headers={['Date', 'Work Area Name', 'Work Area #', 'Vehicle #', 'VScan Packages', 'Delivery Stops', 'Pick-Up Stops', 'DIFF', 'Actual Delivery Stops', 'Actual Delivery Packages', 'Actual Pick-Up Stops', 'Actual Pick-Up Packages', 'P&D Stops', 'P&D Packages', 'Miles']} rows={[...driverDayRows, totalRow]}/>
                </div>
            </div>
        )
    })

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Viewing Weekly Service Worksheet</Modal.Title>
            </Modal.Header>
            {(csaIsUnmatched || unmatchedWswNames?.length > 0) && 
                <Modal.Header>
                    <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 16}}>
                        <span>
                            This Weekly Service Worksheet has incomplete data. You can match any unmatched WSW Names to have a complete report.
                        </span>
                        <Button onClick={() => setShowModal(true)} variant="outline-primary">Match WSW Names</Button>
                    </div>
                </Modal.Header>
            }
            <Modal.Body>
                <LoadingWrapper isLoading={isLoading}>
                    <h4 style={{textAlign: 'center', paddingBottom: 0, textDecoration: 'underline'}}>Weekly Serivce Worksheet Report</h4>
                    <h5 style={{opacity: 0.5, textAlign: 'center'}}>{`${selectedWsw?.csaName ?? selectedWsw.csaFedexId} | ${moment(weeklyServiceWorksheet?.startDate).format('MMM D, YYYY')} - ${moment(weeklyServiceWorksheet?.startDate).add(6, 'days').format('MMM D, YYYY')}`}</h5>
                    <h4 style={{textAlign: 'center', margin: 18, marginTop: 32}}>Driver Summaries</h4>
                    <div style={{}}>
                        {driverTables}
                    </div>
                </LoadingWrapper>
            </Modal.Body>
            <Modal centered size="xl" fullscreen='lg-down' show={showModal} onHide={() => setShowModal(false)}>
                <WeeklyServiceWorksheetMatch unmatchedWswNames={unmatchedWswNames} unmatchedCsaFedexIds={unmatchedCsaFedexIds} setUnmatchedWswNames={setUnmatchedWswNames} setUnmatchedCsaFedexIds={setUnmatchedCsaFedexIds} matchWswNamesCallback={matchWswNamesCallback}/>
            </Modal>
        </>
    )
}