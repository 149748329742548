/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './HumanResources.css';
import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import HRUserProfile from './HRUserProfile';
import HRUserPay from './HRUserPay/HRUserPay';
import HRUserSchedule from './HRUserSchedule/HRUserSchedule';
import HRUserBWC from './BWC/HRUserBWC';
import HRUserDocuments from './HRDocuments/HRUserDocuments';
import HRUserPermissions from './HRUserPermissions';
import HRUserTimesheet from './HRUserTimesheet/HRUserTimesheet';
import Cookies from 'universal-cookie';
import HRNotes from './HRNotes/HRNotes';
import HRUserPto from './HRUserPTO/HRUserPto';
import HRUserLoan from './HRUserLoan/HRUserLoan';
import { Dropdown, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export default function HumanResourcesUser({selectedEmployee, handleGoBackToHub, users, csas}) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [selectedBwcRecord, setSelectedBwcRecord] = useState(undefined); 
    const permissions = new Cookies().get('permissions');
    const userData = new Cookies().get('userData');
    const restrictWhoSeesPay = new Cookies().get('restrictWhoSeesPay');

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, [])


    function handleWindowResize() {
        setWindowWidth(window.innerWidth);
    }

    function handleChangeTab(tab) {
        setActiveTabKey(tab);
    }

    function handleJumpToBwcRecord(bwcRecord) {
        setSelectedBwcRecord(bwcRecord);
        setActiveTabKey('BWC');
    }


    const tabs = {
        'Profile': <HRUserProfile selectedEmployee={selectedEmployee}/>,
        'Schedule': <HRUserSchedule selectedEmployee={selectedEmployee}/>,
        'Timesheet': <HRUserTimesheet selectedEmployee={selectedEmployee}  handleJumpToBwcRecord={handleJumpToBwcRecord}/>,
        'Pay': <HRUserPay selectedEmployee={selectedEmployee} />,
        'PTO': <HRUserPto selectedEmployee={selectedEmployee}/>,
        'Loans & FTD': <HRUserLoan selectedEmployee={selectedEmployee} />,
        'BWC': <HRUserBWC selectedEmployee={selectedEmployee} windowWidth={windowWidth} selectedBwcRecord={selectedBwcRecord} setSelectedBwcRecord={setSelectedBwcRecord}/>,
        'Documents': <HRUserDocuments selectedEmployee={selectedEmployee}/>,
        'Notes': <HRNotes selectedEmployee={selectedEmployee} users={users}/>,
        'Permissions': <HRUserPermissions selectedEmployee={selectedEmployee}/>,
    }

    let tabNames = [];
    if (permissions.hr_profile == '1') {
        tabNames.push('Profile');
    }
    if (permissions.hr_schedule == '1') {
        tabNames.push('Schedule');
    }
    if (permissions.hr_timesheet == '1') {
        tabNames.push('Timesheet');
    }
    if (permissions.hr_pay == '1' && (restrictWhoSeesPay == '0' || userData.title == 'AO' || userData.title == 'Admin' || !['AO', 'Admin', 'BC'].includes(selectedEmployee?.title) || (restrictWhoSeesPay == '0' && userData.companyUserUid == selectedEmployee))) {
        tabNames.push('Pay');
    }
    if (permissions.hr_pay == '1') {
        tabNames.push('PTO');
    }
    if(permissions.hr_pay == '1'){
        tabNames.push('Loans & FTD');
    }
    if (permissions.hr_bwc == '1') {
        tabNames.push('BWC');
    }
    if (permissions.hr_documents == '1') {
        tabNames.push('Documents');
    }
    if (permissions.hr_profile == '1') {
        tabNames.push('Notes');
    }
    if (permissions.hr_permissions == '1') {
        tabNames.push('Permissions');
    }

    const [activeTabKey, setActiveTabKey] = useState(tabNames[0]);
    
    const tabElements = tabNames.map((tab) => {
        return (
            <Button key={tab} variant='outline-primary' style={activeTabKey == tab ? {paddingLeft: 0, paddingRight: 0, backgroundColor: 'var(--bs-primary)', color: 'white'} : {paddingLeft: 0, paddingRight: 0}} onClick={() => {handleChangeTab(tab)}}>
                {tab}
            </Button>
        )
    });

    const tabDropdownItems = tabNames.map((tab) => {
        return (
            <Dropdown.Item key={tab} active={activeTabKey == tab} onClick={() => {handleChangeTab(tab)}}>{tab}</Dropdown.Item>
        )
    })    

    return (
        <div style={{display: 'flex', flexDirection: 'column', padding: 12, height: '100%'}}>
            {windowWidth >= 1200 ? 
                <div style={{display: 'flex', alignItems: 'flex-start', gap: 12}}>
                    
                    <Button variant='outline-primary' style={{display: 'flex', gap: 4, alignItems: 'center'}} onClick={handleGoBackToHub}>
                        <FontAwesomeIcon icon={faChevronLeft}/>
                        <span>Back to Hub</span>
                    </Button>
                    <div style={{flex: 1}}>
                        <ButtonGroup style={{display: 'flex'}}>
                            {tabElements}
                        </ButtonGroup>
                    </div>
                    <h4 style={{textAlign: 'center', opacity: 0.65, fontWeight: 'bold', marginBottom: 0, marginTop: 4}}>{`${selectedEmployee.firstName} ${selectedEmployee.lastName}`}</h4>
                </div>
            :
                <div style={{display: 'flex', justifyContent: 'space-between', gap: 6}}>
                    <Button variant='outline-primary' style={{display: 'flex', gap: 4, alignItems: 'center'}} onClick={handleGoBackToHub}>
                        <FontAwesomeIcon icon={faChevronLeft}/>
                        <span>Back to Hub</span>
                    </Button>
                    <Dropdown>
                        <Dropdown.Toggle variant='outline-primary'>{`Viewing: ${activeTabKey}`}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {tabDropdownItems}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            }
            <hr/>
            <div style={{display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 12, paddingRight: 12, overflowY: 'hidden'}}>
                {tabs[activeTabKey] ?? tabs[tabNames[0]] ?? <span>No Permissions</span>}
            </div>
        </div>
    )
}