import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, Title,LinearScale, PointElement, LineElement } from 'chart.js';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { validateDecimal } from '../../tools';

export default function BookkeepingCharts({sReportData, selectedCsaFedexId}){
    const totalWeeks = selectedCsaFedexId 
    ? sReportData[selectedCsaFedexId].length
    : Object.values(sReportData).flatMap(arr => arr).length

    ChartJS.register(
        CategoryScale,
        LinearScale,
        Title, 
        Tooltip,
        Legend,
        ArcElement,
        PointElement,
        LineElement,
    );

    ////////////////
    /// LINE CHART
    ////////////////

    const lineOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Revenue Per Week',
          },
        },
      };
      
      const lineLabels = [];
      const revenuePerCS = [];

      for(let i = totalWeeks-1; i >= 0; i--){
        if(selectedCsaFedexId){
            revenuePerCS[i] = validateDecimal(sReportData[selectedCsaFedexId][i]?.netAmount);
            lineLabels[i] = moment(sReportData[selectedCsaFedexId][i]?.startDate).format('MMM D');
        }else{
            revenuePerCS[i] = Object.values(sReportData).flatMap(arr => arr)[i]?.netAmount;
            lineLabels[i] = moment(Object.values(sReportData).flatMap(arr => arr)[i]?.startDate).format('MMM D');
        }
      }
      
      const lineData = {
        labels: lineLabels,
        datasets: [
        {
            label: 'Revenue',
            data: revenuePerCS,
            backgroundColor: 'green',
        },
        ],
      };

    return (
        <div style={{minHeight: 300}}>
            <Line options={lineOptions} data={lineData} />
        </div>
    )
}