/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import QuickTable from "../../components/QuickTable";
import PCATField from "./PCATField";
import { toFixedMax, validateBig } from "../Payroll/payrollTools";

export default function HistoricalCSASummary({pcat, originalPcat, handleSetPcat}) {
    const nonEComPkgsPerStop = pcat.nonECommStops == 0 ? 'N/A' : toFixedMax(validateBig(pcat.nonECommPackages).div(validateBig(pcat.nonECommStops)).toNumber(), 3);
    const eComPkgsPerStop = pcat.eCommStops == 0 ? 'N/A' : toFixedMax(validateBig(pcat.eCommPackages).div(validateBig(pcat.eCommStops)).toNumber(), 3);

    const ttaHistoricalElements = [
        <tr key={1}>
            <td>Total Non E-Commerce Packages</td>
            <td>
                <PCATField commasOnBlur min={0} max={999999999} intOnly type='number' value={pcat.nonECommPackages} originalValue={originalPcat?.nonECommPackages} setValue={(value)=>{handleSetPcat('nonECommPackages', value)}}/>
            </td>
        </tr>,
        <tr key={2}>
            <td>Total Non E-Commerce Stops</td>
            <td>
                <PCATField commasOnBlur min={0} intOnly max={999999999} type='number' value={pcat.nonECommStops} originalValue={originalPcat?.nonECommStops} setValue={(value)=>{handleSetPcat('nonECommStops', value)}}/>
            </td>
        </tr>,
        <tr key={3}>
            <td>Non E-Commerce Packages / Stop</td>
            <td>{nonEComPkgsPerStop}</td>
        </tr>,
        <tr key={4}>
            <td>Daily Stop Threshold</td>
            <td><PCATField commasOnBlur min={0} intOnly max={99999} type='number' value={pcat.dailyStopThreshold} originalValue={originalPcat?.dailyStopThreshold} setValue={(value)=>{handleSetPcat('dailyStopThreshold', value)}}/></td>
        </tr>,
        <tr key={5}>
            <td>Total Delivery Packages</td>
            <td><PCATField commasOnBlur min={0} intOnly max={999999999} type='number' value={pcat.totalDeliveryPackages} originalValue={originalPcat?.totalDeliveryPackages} setValue={(value)=>{handleSetPcat('totalDeliveryPackages', value)}}/></td>
        </tr>,
        <tr key={6}>
            <td>Large Package Mix Delivery %</td>
            <td><PCATField commasOnBlur type='number' max={100} value={pcat.largePackageMixDeliveryPercentage} originalValue={originalPcat?.largePackageMixDeliveryPercentage} setValue={(value)=>{handleSetPcat('largePackageMixDeliveryPercentage', value)}}/></td>
        </tr>,
        <tr key={7}>
            <td>Total E-Commerce Packages</td>
            <td><PCATField commasOnBlur min={0} intOnly type='number' max={999999999} value={pcat.eCommPackages} originalValue={originalPcat?.eCommPackages} setValue={(value)=>{handleSetPcat('eCommPackages', value)}}/></td>
        </tr>,
        <tr key={8}>
            <td>Total E-Commerce Stops</td>
            <td><PCATField commasOnBlur min={0} intOnly type='number' max={999999999} value={pcat.eCommStops} originalValue={originalPcat?.eCommStops} setValue={(value)=>{handleSetPcat('eCommStops', value)}}/></td>
        </tr>,
        <tr key={9}>
            <td>E-Commerce Packages / Stop</td>
            <td>{eComPkgsPerStop}</td>
        </tr>
    ]

    return(
        <QuickTable title='12 Month Historical Summary of CSA Activity' titleColSpan={2} rows={ttaHistoricalElements} size='sm' responsive={false}/>        
    )
}
