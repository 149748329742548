/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import CustomButton from "../../../components/CustomButton";


export default function ClockOutCheck({notClockedOutUsers, goBack, continueAnyway}){

    const userNames = notClockedOutUsers.map((user)=>{
        return (
            <p key={user}>{user}</p>
        )
    });

    return(
        <>
            <Modal.Header>
                <Modal.Title>Outstanding Timesheet Data Detected</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={{fontWeight: 'bold'}}>The following employees must be clocked out prior to submitting payroll:</p>
                {userNames}
            </Modal.Body>
            <Modal.Footer>
                <button onClick={continueAnyway} style={{background: 'none', border:'none', color: 'red', fontSize: 15}}>Continue Anyway</button>
                <CustomButton label='Acknowledge & Exit' onClick={goBack}/>
            </Modal.Footer>
        </>

    )
}