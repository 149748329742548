/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './CalendarClassic.css'
import Table from 'react-bootstrap/Table';
import moment from 'moment';


function getDaysOfTheWeek(date) {
    const startOfWeek = (date.day() === 6 ? moment(date) : moment(date).subtract(date.day() + 1, 'days'))
    const daysOfWeek = [
        moment(startOfWeek), 
        moment(startOfWeek).add(1, 'days'), 
        moment(startOfWeek).add(2, 'days'), 
        moment(startOfWeek).add(3, 'days'), 
        moment(startOfWeek).add(4, 'days'), 
        moment(startOfWeek).add(5, 'days'), 
        moment(startOfWeek).add(6, 'days'),
    ];
    return daysOfWeek;
}

function getStartOfWeek(date) {
    return date.day() === 6 ? moment(date) : moment(date).startOf('week').subtract(1, 'days');
}

function CalendarClassic({datePointer, setDatePointer}) {
    
    const startOfMonth = moment(datePointer).startOf('month');
    const monthDayOffset = startOfMonth.day() === 6 ? 0 : startOfMonth.day() + 1;
    const currentWeek = parseInt((parseInt(moment(datePointer).format('D')) + monthDayOffset - 1) / 7);
    
    const rows = [0, 1, 2, 3, 4, 5].map((index) => {

        const columns = getDaysOfTheWeek(moment(startOfMonth).add(index, 'weeks')).map((date, index) => {
            return (
                <td 
                    key={index} 
                    className={'calendar-td' + (date.format('MMM D') === moment().format('MMM D') ? ' calendar-td-today' : '') + (date.format('MMM') !== datePointer.format('MMM') ? ' calendar-td-off-month' : '')}
                    >
                    {date.format('D')}
                </td>
            );
        });

        return (
            <tr 
                key={index} 
                className={'calendar-tr ' + (currentWeek === index ? 'calendar-tr-selected' : '')} 
                onClick={() => {setDatePointer(moment(getStartOfWeek(startOfMonth).add(index, 'weeks')))}}>
                {columns}
            </tr>
        );
    });

    const columnHeaders = ['Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri'].map((str) => {
        return (<th key={str} className='calendar-th'>{str}</th>);
    });


    return (            
        <Table bordered size='sm' hover>
            <thead>
                <tr>
                    {columnHeaders}
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </Table>
        
    );
}

export default CalendarClassic;