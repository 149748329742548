/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect, useState } from "react";
import ApiManager, { ApiRequest } from "../../ApiManager.tsx";
import LoadingWrapper from "../../components/LoadingWrapper.js";
import { toast } from "react-toastify";

export default function QuickBooksAuthentication({}) {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('code') && searchParams.has('realmId')) {
            handleAuthenticateCode(searchParams.get('code'), searchParams.get('realmId'));
        }
    }, [])

    function handleAuthenticateCode(code, realmId) {
        new ApiRequest('bookkeeping', 'authenticateCode', () => {}, (response, status) => {
            if (status == 200) {
                const elem = window.document.createElement('a');
                elem.href = ApiManager.DEV_MODE ? 'http://localhost:3000/bookkeeping' : 'https://tacticalcontractor.com/bookkeeping';
                document.body.appendChild(elem);
                elem.click();
                document.body.removeChild(elem);
            } else {
                setError(response.message);
                toast.error(response.message);
                setIsLoading(false);
            }
        }).withData({authorizationCode: code, realmId: realmId}).withNoAlertOnSuccess().withReturnStatuses([200, 400]).send();
    }

    return (
        <LoadingWrapper isLoading={isLoading}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, height: '100%'}}>
                <h4 style={{color: 'red'}}>{error}</h4>
            </div>
        </LoadingWrapper>
    )
}