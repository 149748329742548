/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import moment from "moment";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import List from '../../components/List/List';
import { scheduleTypeDictionary } from "../../tools";

export default function CalendarObject({object, popup}) {
    let title = scheduleTypeDictionary[object.scheduleType].label;
    let color = scheduleTypeDictionary[object.scheduleType].color;
    let icon = scheduleTypeDictionary[object.scheduleType].icon;
    let items;

    switch (object.scheduleType) {
        case 'requestOff':
            items = [
                ['Start Date', moment(object.date).format('MMM D, YYYY')],
                ['End Date', moment(object.endDate).format('MMM D, YYYY')],
                ['Requested As', (object.timeScheduled ? 'PTO' : 'Unpaid')],
                ['Notes', object?.notes ?? ''],
                ['Submission Date', moment(object.submissionDate).format('MMM D, YYYY')]
            ];
            break;
        case 'callOff':
            items = [
                ['Date', moment(object.date).format('MMM D, YYYY')],
                ['notes', object?.notes ?? ''],
            ];
            break;
        case 'pto':
            items = [
                ['Date', moment(object.date).format('MMM D, YYYY')],
                ['Notes', object?.notes ?? ''],
                ['Submission Date', moment(object.submissionDate).format('MMM D, YYYY')]
            ];
            break;
        case 'dayOff':
            items = [
                ['Date', moment(object.date).format('MMM D, YYYY')],
                ['Notes', object?.notes ?? ''],
                ['Submission Date', moment(object.submissionDate).format('MMM D, YYYY')]
            ];
            break;
        case 'noShow':
            items = [
                ['Date', moment(object.date).format('MMM D, YYYY')],
                ['Notes', object?.notes ?? ''],
            ];
            break;
        case 'rejectedRequest':
            items = [
                ['Date', moment(object.date).format('MMM D, YYYY')],
                ['End Date', moment(object.endDate).format('MMM D, YYYY')],
                ['Notes', object?.notes ?? ''],
                ['Submission Date', moment(object.submissionDate).format('MMM D, YYYY')]
            ];
            break;
        case 'managerOnDuty':
            items = [
                ['Date', moment(object.date).format('MMM D, YYYY')],
                ['Notes', object?.notes ?? ''],
                ['Submission Date', moment(object.submissionDate).format('MMM D, YYYY')]
            ];
            break;
        case 'paidTraining':
            items = [
                ['Date', moment(object.date).format('MMM D, YYYY')],
                ['Notes', object?.notes ?? ''],
                ['Submission Date', moment(object.submissionDate).format('MMM D, YYYY')]
            ];
            break;
        default:
            items = [];
            break;
    }

    const popover = (
        <Popover style={{position:'fixed'}}>
            <Popover.Body>
                <List items={items} responsive={true}/>
            </Popover.Body>
        </Popover>
    );

    return (
        <div className="cursor-pointer" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 4, backgroundColor: color, borderRadius: 6}}>
            <OverlayTrigger placement={popup ? 'top' : 'bottom'} overlay={popover}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', paddingLeft: 6, paddingRight: 6}}>
                    <p style={{margin: 0, color: 'black', opacity: 0.7, fontSize: '1.1vmax'}}>{title}</p>
                    <FontAwesomeIcon icon={icon} style={{height: '1.1vmax', color: 'black', opacity: 0.5}}/>
                </div>
            </OverlayTrigger>
        </div>
    )
}