/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react"
import { View, Text} from "@react-pdf/renderer";

export default function PDFTable({title, headers, rows, fontSize}) {
    
    const headerElements = headers ? headers.map((header, index) => {
        return (
            <Text key={index} style={{borderLeft: index > 0 ? '1px solid black' : undefined, padding: '0px 4px 0px 4px', fontSize: fontSize, flex: 1}}>{header}</Text>
        )
    }) : null;


    return (
        <View style={{width: '100%', border: '1px solid black'}}>
            { title &&
                <View style={{flexDirection: 'row', justifyContent: 'center', width: '100%', padding: 4}}>
                    <Text style={{fontSize: fontSize, fontWeight: 'bold'}}>{title}</Text>
                </View>
            }
            {headerElements &&
            <View style={{width: '100%', flexDirection: 'row', borderTop: title ? '1px solid black' : undefined}}>
                {headerElements}
            </View>
            }   
            {rows}
        </View>
    )
}

export function PDFTableRow({columns, fontSize, center, padding}) {

    const columnElements = columns.map((column, index) => {
        return (
            <Text key={index} style={{borderLeft: index > 0 ? '1px solid black' : undefined, padding: padding ? padding : '0px 4px 0px 4px', flex: 1, fontSize: fontSize, textAlign: center ? 'center' : ''}}>{column}</Text>
        )
    })

    return (
        <View style={{width: '100%', borderTop: '1px solid black', flexDirection: 'row'}}>
            {columnElements}
        </View>
    )
}