/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

export default function SwitchControl({value, setValue, originalValue, title, disabled, xs, sm, md, lg, xl, xxl, style, reverse = false}) {

    const handleSetValue = () => {
        if (value === '1') {
            setValue('0');
        } else if (value === '0') {
            setValue('1');
        } else if (value === 1) {
            setValue(0);
        } else if (value === 0) {
            setValue(1);
        } else {
            setValue(!value);
        }
    }

    const content = (
        <div style={style ? style :{display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: '1px solid lightgray', borderRadius: 6, padding: 15, backgroundColor: originalValue !== undefined && originalValue != value ? '#faffe3' : 'white'}}>
            <Form.Check label={title} type='switch' reverse={reverse} checked={value === '0' ? false : value === '1' ? true : value} onChange={handleSetValue} disabled={disabled}/>
        </div>
    );

    const contentWrapped = xs || sm || md || lg || xl || xxl ? (
        <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} style={{marginBottom: 8}}>
            {content}
        </Col>
    ) : '';
    return (
        <>
            {xs || sm || md || lg || xl || xxl ? contentWrapped : content}
        </>
    )
}