/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useMemo, useState } from "react";

export function useStateObject(initialState: any) {
// export function useStateObjectWithValidation(initialState: any, keysAndValidation: object) {
    const [value, setValue] = useState(initialState);

    // const validationMessages = useMemo(() => {
    //     const vMessages = {}
    //     Object.entries(keysAndValidation).forEach(([key, validationFunc]) => {
    //         vMessages[key] = validationFunc(value[key]);
    //     })
    //     return vMessages;
    // }, [value, keysAndValidation])

    // const isValid = useMemo(() => {
    //     return Object.entries(keysAndValidation).reduce((prev, [key, validationFunc]) => {
    //         if(Array.isArray(value[key])){
    //             return prev && validationFunc(value[key]).reduce((acc, el) => {
    //                 return acc && el == undefined;
    //             }, true)
    //         }
    //         return prev && (validationFunc(value[key]) == undefined)
    //     }, true)
    // }, [value, keysAndValidation])

    function handleSetKeyValue(key: string, newValue: any) {
        setValue((prevState: any) => {
            if (value.duplicate) {
                const newObject = prevState.duplicate();
                newObject[key] = newValue;
                return newObject;
            } else {
                const newObject = structuredClone(prevState);
                newObject[key] = newValue;
                return newObject;
            }
        });
    }

    // return [value, setValue, handleSetKeyValue, validationMessages, isValid];
    return [value, setValue, handleSetKeyValue];
}