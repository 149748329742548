/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import React from "react"
import { View, Page, Document, Text, Image } from "@react-pdf/renderer";
import Cookies from "universal-cookie";
import PDFTable, { PDFTableRow } from "../../components/PDFTable";
import { usdFormatter, validateDecimal, validateInteger } from "../../tools";

export default function PCATPdf({pcat}) {
    
    const getHeader = (index) => {
        if (pcat.proposals[index].isFedex) {
            if (index > 0) {
                let fedexProposalCount = 0;
                for (let i = 0; i < pcat.proposals.length; i++) {
                    if (i == index) {
                        break;
                    } else if (pcat.proposals[i].isFedex) {
                        fedexProposalCount++;
                    }
                }
                return `FedEx Counter Proposal #${fedexProposalCount}`;
            } else {
                return "Initial FedEx Proposal";
            }
        } else {
            let icProposalCount = 1;
            for (let i = 0; i < pcat.proposals.length; i++) {
                if (i == index) {
                    break;
                } else if (!pcat.proposals[i].isFedex) {
                    icProposalCount++;
                }
            }
            return `IC Counter Proposal #${icProposalCount}`;
        }
    }

    const getHeaderSimple = (index) => {
        if (pcat.proposals[index].isFedex) {
            let fedexProposalCount = 1;
            for (let i = 0; i < pcat.proposals.length; i++) {
                if (i == index) {
                    break;
                } else if (pcat.proposals[i].isFedex) {
                    fedexProposalCount++;
                }
            }
            return `FE #${fedexProposalCount}`;
        } else {
            let icProposalCount = 1;
            for (let i = 0; i < pcat.proposals.length; i++) {
                if (i == index) {
                    break;
                } else if (!pcat.proposals[i].isFedex) {
                    icProposalCount++;
                }
            }
            return `IC #${icProposalCount}`;
        }
    }
    /////////
    

    /// FIRST TABLE DATA
    const historicalSummaryRows = [
        {label: 'Total Non E-Commerce Packages', value: validateInteger(pcat.nonECommPackages).toLocaleString()},
        {label: 'Total Non E-Commerce Stops', value: validateInteger(pcat.nonECommStops).toLocaleString()},
        {label: 'Non E-Commerce Packages / Stop', value: pcat.nonEComPkgsPerStop()},
        {label: 'Daily Stop Threshold', value: validateInteger(pcat.dailyStopThreshold).toLocaleString()},
        {label: 'Total Delivery Packages', value: validateInteger(pcat.totalDeliveryPackages).toLocaleString()},
        {label: 'Large Package Mix Delivery %', value: validateDecimal(pcat.largePackageMixDeliveryPercentage).toLocaleString()},
        {label: 'Total E-Commerce Packages', value: validateInteger(pcat.eCommPackages).toLocaleString()},
        {label: 'Total E-Commerce Stops', value: validateInteger(pcat.eCommStops).toLocaleString()},
        {label: 'E-Commerce Packages / Stop', value: pcat.eComPkgsPerStop()},
    ].map((row) => {
        return (
            <PDFTableRow key={row.label} columns={[row.label, row.value]} fontSize={10}/>
        )
    })

    ////////
    //////// SECOND TABLE DATA
    const proposalInformationHeaders = pcat.proposals.map((_, index)=>{
        return getHeader(index);
    })

    const proposalInformationRows = [
        {label: 'Effective Date', key: 'dateEffective' },
        {label: 'Dock Loading Positions', key: 'dockLoadingPositions'},
        {label: 'Vehicles in Brand Promotion Program', key: 'brandVehicles'},
    ].map((row) => {
        const columns = pcat.proposals.map((proposal)=>{
            return validateInteger(proposal[row.key]);
        })
        return (
            <PDFTableRow key={row.label} columns={[row.label, ...columns]} fontSize={10}/>
        )
    })

    ///////
    ////// THIRD TABLE DATA
    const proposalElementHeaders = pcat.proposals.map((_, index)=>{
        return getHeaderSimple(index);
    })

    const proposalElementRows = [
        {label: 'Service Charge', frequency: 'Weekly', key: 'serviceCharge' },
        {label: 'Stop Charge', frequency: 'Activity Based', key: 'stopCharge'},
        {label: 'Surge Stop Charge', frequency: 'Activity Based', key: 'surgeStopCharge'},
        {label: 'Per Stop Fuel Surcharge', frequency: 'Activity Based', key: 'perStopFuelSurcharge' },
        {label: 'Package Charge', frequency: 'Activity Based', key: 'packageCharge'},
        {label: 'Large Package Mix Charge', frequency: 'Activity Based', key: 'largePackageMixCharge'},
        {label: 'Apparel Brand Promotion Charge', frequency: 'Weekly', key: 'apparelBrandCharge' },
        {label: 'Vehicle Brand Promotion Charge', frequency: 'Weekly/Vehicle', key: 'vehicleBrandCharge'},
        {label: 'E-Commerce Stop Charge', frequency: 'Activity Based', key: 'eCommStopCharge'},
        {label: 'E-Commerce Package Charge', frequency: 'Activity Based', key: 'eCommPackageCharge' },
        {label: 'Advance Contract Service Charge', frequency: 'Activity Based', key: 'advanceServiceCharge'},
    ].map((row) => {
        const columns = pcat.proposals.map((proposal)=>{
            return usdFormatter.format(proposal[row.key]);
        })
        return (
            <PDFTableRow key={row.label} columns={[row.label, row.frequency, ...columns]} fontSize={10}/>
        )
    })

    ///////
    ////// FOURTH TABLE DATA
    const projectedServiceHeaders = pcat.proposals.map((_, index)=>{
        return getHeaderSimple(index);
    })

    const projectedServiceRows = [
        {label: 'Non E-Commerce Package Growth %', key: 'nonECommPackageGrowth', percentage: true},
        {label: 'Non E-Commerce Stop Growth %', key: 'nonECommStopGrowth', percentage: true},
        {label: 'Over Threshold Stops', key: 'overThresholdStops'},
        {label: 'Non E-Commerce Packages/Stop', key: 'nonECommPackagesPerStop' },
        {label: 'Total Non E-Commerce Stops', key: 'totalNonECommStops'},
        {label: 'Total Non E-Commerce Packages', key: 'totalNonECommPackages'},
        {label: 'Total Delivery Packages', key: 'totalDeliveryPackages'},
        {label: 'Large Package Mix Delivery %', key: 'largePackageMixDeliveryPercentage', percentage: true},
        {label: 'E-Commerce Package Growth %', key: 'eCommPackageGrowth', percentage: true},
        {label: 'E-Commerce Stop Growth %', key: 'eCommStopGrowth', percentage: true},
        {label: 'Total E-Commerce Packages', key: 'totalECommPackages'},
        {label: 'Total E-Commerce Stops', key: 'totalECommStops'},
        {label: 'E-Commerce Package/Stop', key: 'eCommPackagesPerStop'}
    ].map((row) => {
        const columns = pcat.proposals.map((proposal)=>{
            const extra = row.percentage ? '%' : '';

            if(row.key === 'nonECommPackagesPerStop')
                return proposal.nonECommPackagesPerStop(pcat);    
            if(row.key === 'totalNonECommStops')
                return parseInt(proposal.totalNonECommStops(pcat).toFixed(0)).toLocaleString();
            if(row.key === 'totalNonECommPackages')
                return parseInt(proposal.totalNonECommPackages(pcat).toFixed(0)).toLocaleString();
            if(row.key === 'totalDeliveryPackages')
                return parseInt(proposal.totalDeliveryPackages(pcat).toFixed(0)).toLocaleString();
            if(row.key === 'largePackageMixDeliveryPercentage')
                return validateDecimal(pcat.largePackageMixDeliveryPercentage) + '%';
            if(row.key === 'totalECommPackages')
                return parseInt(proposal.totalECommPackages(pcat).toFixed(0)).toLocaleString();
            if(row.key === 'totalECommStops')
                return parseInt(proposal.totalECommStops(pcat).toFixed(0)).toLocaleString();
            if(row.key === 'eCommPackagesPerStop')
                return proposal.eCommPackagesPerStop(pcat);

            return validateDecimal(proposal[row.key]) + extra;
        })
        return (
            <PDFTableRow key={row.label} columns={[row.label, ...columns]} fontSize={10}/>
        )
    })

    /////////////////////////////
    ///FIFTH DATA TABLE
    /////////////////////////////

    const futureAnnualHeaders = pcat.proposals.map((_, index)=>{
        return getHeaderSimple(index);
    })

    const futureAnnualRows = [
        {label1: 'Service Revenue', label2: 'Fixed', key: 'serviceRevenue'},
        {label1: 'Non E-Commerce Stop Revenue', label2: 'Variable', key: 'nonECommStopRevenue'},
        {label1: 'Surge Stop Revenue', label2: 'Variable', key: 'surgeStopRevenue'},
        {label1: 'Fuel Stop Surcharge Revenue', label2: 'Variable', key: 'fuelStopSurchargeRevenue'},
        {label1: 'Non E-Commerce Package Revenue', label2: 'Variable', key: 'nonECommPackageRevenue'},
        {label1: 'Large Package Mix Revenue', label2: 'Variable', key: 'largePackageMixRevenue'},
        {label1: 'Apparel Brand Promotion Revenue', label2: 'Fixed', key: 'apparelBrandPromotionRevenue'},
        {label1: 'Vehicle Brand Promotion Revenue', label2: 'Other Veh. Dep.', key: 'vehicleBrandPromotionRevenue'},
        {label1: 'E-Commerce Stop Revenue', label2: 'Variable', key: 'eCommStopRevenue'},
        {label1: 'E-Commerce Package Revenue', label2: 'Variable', key: 'eCommPackageRevenue'},
        {label1: 'Variable Revenue', label2: '', key: 'variableRevenue'},
        {label1: 'Fixed/Other Revenue', label2: '', key: 'fixedAndOtherRevenue'},
        {label1: 'Total Revenue', label2: '', key: 'totalRevenue'},
        {label1: 'Variable Revenue %', label2: '', key: 'variableRevenuePercentage'},
        {label1: 'Fixed/Other Revenue %', label2: '', key: 'fixedOtherRevenuePercentage'},
        {label1: 'Annual Expenses', label2: '', key: 'annualExpenses'},
        {label1: 'Annual Profit', label2: '', key: 'profit'},
        {label1: 'Annual Margin %', label2: '', key: 'margin'},
        {label1: 'Cost Per Dispatch', label2: '', key: 'costPerDispatch'},
        {label1: 'Revenue Per Dispatch', label2: '', key: 'revenuePerDispatch'},
    ].map((row) => {
        const columns = pcat.proposals.map((proposal, i)=>{
            if(row.key === 'serviceRevenue')
                return usdFormatter.format(proposal.serviceRevenueAndAdvanceServiceChargeRevenue().toNumber());
            if(row.key === 'nonECommStopRevenue')
                return usdFormatter.format(proposal.nonECommStopRevenue(pcat).toNumber());
            if(row.key === 'surgeStopRevenue')
                return usdFormatter.format(proposal.surgeStopRevenue().toNumber());
            if(row.key === 'fuelStopSurchargeRevenue')
                return usdFormatter.format(proposal.fuelStopSurchargeRevenue(pcat).toNumber());
            if(row.key === 'nonECommPackageRevenue')
                return usdFormatter.format(proposal.nonECommPackageRevenue(pcat).toNumber());
            if(row.key === 'largePackageMixRevenue')
                return usdFormatter.format(proposal.largePackageMixRevenue(pcat).toNumber())
            if(row.key === 'apparelBrandPromotionRevenue')
                return usdFormatter.format(proposal.apparelBrandPromotionRevenue().toNumber());
            if(row.key === 'vehicleBrandPromotionRevenue')
                return usdFormatter.format(proposal.vehicleBrandPromotionRevenue().toNumber());
            if(row.key === 'eCommStopRevenue')
                return usdFormatter.format(proposal.eCommStopRevenue(pcat).toNumber());
            if(row.key === 'eCommPackageRevenue')
                return usdFormatter.format(proposal.eCommPackageRevenue(pcat).toNumber());
            if(row.key === 'variableRevenue')
                return usdFormatter.format(proposal.variableRevenue(pcat).toNumber());
            if(row.key === 'fixedAndOtherRevenue')
                return usdFormatter.format(proposal.fixedAndOtherRevenue().toNumber());
            if(row.key === 'totalRevenue')
                return usdFormatter.format(proposal.totalRevenue(pcat).toNumber())
            if(row.key === 'variableRevenuePercentage')
                return proposal.variableRevenuePercentage(pcat);
            if(row.key === 'fixedOtherRevenuePercentage')
                return proposal.fixedAndOtherRevenuePercentage(pcat);
            if(row.key === 'annualExpenses')
                return proposal.getTotalExpenses();
            if(row.key === 'profit')
                return proposal.getProfit(pcat, i);
            if(row.key === 'margin')
                return proposal.getMargin(pcat, i);
            if(row.key === 'costPerDispatch')
                return proposal.getYearlyExpensesPerDispatch(pcat, i)
            if(row.key === 'revenuePerDispatch')
                return proposal.getYearlyRevenuePerDispatch(pcat, i)
            return null;
        })
        return (
            <PDFTableRow key={row.label1} columns={[row.label1, row.label2, ...columns]} fontSize={10}/>
        )
    })

    return (
    <Document> 
        <Page size='LETTER' style={{padding: '0.5in', flexDirection: 'column'}} orientation='landscape'>
            <View fixed style={{flexDirection: 'row', width: '100%', justifyContent: 'space-between', marginBottom:15}}>
                {/* <Image src={logo} style={{width:150}}/> */}
                <Text style={{fontSize: 12, color: 'gray'}}>{new Cookies().get('companyName')}</Text>
            </View>
            <View style={{flexDirection: 'row', justifyContent: 'center', width: '100%', marginBottom: 12}}>
                <Text>{pcat.title}</Text>
            </View>
            <View wrap={false} style={{width: '100%', marginBottom: 24}}>
                <PDFTable title='12 Month Historical Summary of CSA Activity' rows={historicalSummaryRows} fontSize={10}/>
            </View>
            {pcat.proposals.length > 0  &&
                <>
                    <View wrap={false} style={{width: '100%', marginBottom: 24}}>
                        <PDFTable columns={4} title='Proposal Information' headers={[' ', ...proposalInformationHeaders]} rows={proposalInformationRows} fontSize={10}/>
                    </View>
                    <View wrap={false} style={{width: '100%', marginBottom: 24}}>
                        <PDFTable columns={4} title='Proposal Elements' headers={['Element', 'Frequency', ...proposalElementHeaders]} rows={proposalElementRows} fontSize={10}/>
                    </View>
                    <View wrap={false} style={{width: '100%', marginBottom: 24}}>
                        <PDFTable columns={4} title='Projected Service Area Growth & Matrix' headers={['', ...projectedServiceHeaders]} rows={projectedServiceRows} fontSize={10}/>
                    </View>
                    <View wrap={false} style={{width: '100%'}}>
                        <PDFTable columns={4} title='Future Annual Revenue Matrix' headers={[' ', ' ', ...futureAnnualHeaders]} rows={futureAnnualRows} fontSize={10}/>
                    </View>
                </>
            }       
        </Page>
    </Document>
    )
}