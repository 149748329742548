/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import Table from 'react-bootstrap/Table';


function QuickTable({title, headers, widths, rows, size, hover, fontSize, noWrap, noMargin, titleColSpan, responsive = true, bordered = true, striped = true, style}) {

    const headerElements = headers?.map((h, index) => {
        return (
            <th key={index} style={{width: widths && widths[index] ? widths[index] : undefined, whiteSpace: noWrap ? 'nowrap' : 'normal'}}>{h}</th>
        )
    });

    return (
        <div style={{flex: 1, ...style}}>
            <Table responsive={responsive} bordered={bordered} striped={striped} hover={hover} size={size} style={{fontSize: fontSize ? fontSize : undefined, marginBottom: noMargin ? 0 : undefined}}>
                <thead>
                    { title ?
                        <tr>
                            <th colSpan={headers?.length ?? titleColSpan} style={{textAlign: 'center'}}>{title}</th>
                        </tr>

                    : ''}
                    { headers && 
                        <tr>
                            {headerElements}
                        </tr>
                    }
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </Table>
        </div>
    )
}

export default QuickTable;