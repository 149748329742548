/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { addressToString,} from "../../tools";
import Form from 'react-bootstrap/Form';
import MyCompanyCSA from "./MyCompanyCSA";
import CreateCompanyCsas from "./CreateCompanyCsas";
import { useEffect } from "react";
import { ApiRequest } from "../../ApiManager.tsx";
import LoadingWrapper from "../../components/LoadingWrapper.js";

export default function MyCompanyCSAs() {
    const [showInactiveItems, setShowInactiveItems] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showCSATableModal, setShowCSATableModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [csas, setCsas] = useState([]);

    const sortCsas = (a, b) => {
        var textA = a.csaName.toUpperCase();
        var textB = b.csaName.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    }

    useEffect(() => {
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('myCompany', 'getCsas', setIsLoading, (response) => setCsas(response.csas.sort(sortCsas))).withNoAlertOnSuccess().send();
    }

    function handleCSASubmission(csa, isUpdating){
        let newArray = Array.from(csas);
        if (isUpdating) {
            newArray = newArray.filter(c => c.uid !== csa.uid);
        }
        newArray.push(csa);
        newArray.sort(sortCsas);
        setCsas(newArray);
        setShowModal(false);
    }
    
    function handleMultipleCSASubmissions(csaArray){
        let newArray = csas.concat(csaArray);
        newArray.sort(sortCsas);
        setCsas(newArray);
        setShowModal(false);
    }

    const csaRows = csas.filter(csa => (showInactiveItems || csa.isActive === 1)).map((csa) => {
        return (
            
            <tr key={csa.uid} style={csa.isActive === 1 ? {} : {opacity: 0.5}} className='cursor-pointer' onClick={() => {setSelectedItem(csa); setShowModal(true);}}>
                <td>{csa.csaName + (csa.isActive === 1 ? '' : ' (inactive)')}</td>
                <td>{addressToString(csa.address)}</td>
                <td>{csa.clockInRadius}</td>
            </tr>
        )
       
    });

    const csaTable = (
        <Table responsive striped bordered hover>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Terminal Address</th>
                    <th>Clock-In Radius (mi)</th>
                </tr>
            </thead>
            <tbody>
                {csaRows}
            </tbody>
        </Table>
    );
        const content = (
            <div>
                <div style={{display: 'flex', marginBottom: 12, justifyContent: 'space-between', alignItems: 'center'}}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: 'var(--bs-primary)', border: '1px solid var(--bs-primary', borderRadius: 6, padding: '6px 12px'}}>
                        <Form.Check type='switch' label='Show inactive CSAs' value={showInactiveItems}  onChange={() => {setShowInactiveItems(!showInactiveItems)}}/>
                    </div>
                    <div style={{display: 'flex', gap: 8}}>
                        <Button variant={'outline-primary'} onClick={() => {setSelectedItem(undefined); setShowModal(true);}}>
                            Add CSA
                        </Button>
                        <Button variant={'outline-primary'} onClick={() => {setShowCSATableModal(true);}}>
                            Add Multiple CSAs
                        </Button>
                    </div>
                </div>

                {csaTable}

                <Modal show={showModal} onHide={() => {setShowModal(false)}} centered size='lg'>
                    <MyCompanyCSA data={selectedItem} handleCSASubmission={(csaToSave, isUpdating)=>{handleCSASubmission(csaToSave, isUpdating); setShowModal(false)}}/>
                </Modal>
                <Modal show={showCSATableModal} onHide={()=>{setShowCSATableModal(false)}} centered size='lg'>
                    <CreateCompanyCsas  handleMultipleCSASubmissions={(csaArray)=>{handleMultipleCSASubmissions(csaArray);setShowCSATableModal(false)}}/>
                </Modal>
            </div>
        )
    return (
        <LoadingWrapper isLoading={isLoading}>
            {content}
        </LoadingWrapper>
    )
}