/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import { ListGroup, Modal, Button, OverlayTrigger} from 'react-bootstrap';
import moment from 'moment';
import CustomButton from '../../../../components/CustomButton';
import KeyValueRow from '../../../../components/KeyValueRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation, faCircleHalfStroke, faCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle as faCircleEmpty } from '@fortawesome/free-regular-svg-icons';
import { Popover } from 'react-bootstrap';
import { adminMode } from '../../payrollTools';
import ChargeStatementUpload from '../../../Bookkeeping/ChargeStatement/ChargeStatementUpload';
import WeeklyServiceWorksheetUpload from '../../../Bookkeeping/WeeklyServiceWorksheets/WeeklyServiceSheetUpload';
import { ApiRequest } from '../../../../ApiManager.tsx';

export default function PayrollSettlementReportModal({payData, hideModal, settlementReportData, handleUpdateUserFedexIds, handleUpdateUserWswNames, userData, payrollPeriod, handleSetSettlementReportData, csas, setCsas, entries, handleSetEntry, employeeFilter}) {
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [usersToRecalculate, setUsersToRecalculate] = useState(Array.from(employeeFilter));
    const [showRecalculateModal, setShowRecalculateModal] = useState(false);
    const [showRecalculateDaysWorked, setShowRecalculateDaysWorked] = useState(false);

    function handleRecalculateSettlementReportData() {
        new ApiRequest('payroll', 'getPayrollSettlementData', () => {}, (response) => {
            handleSetSettlementReportData(response.settlementReportData);
            setCsas(response.csas);
        }).withData({payrollPeriod: payrollPeriod}).withNoAlertOnSuccess().sendInBackground();
    }

    function handleAddUserToRecalculate(user){
        let newArr = Array.from(usersToRecalculate);
        if(!newArr.includes(user)){
            newArr.push(user);
        } else {
            newArr = newArr.filter((u)=>u !== user);
        }
        setUsersToRecalculate(newArr);
    }

    function handleRecalculateStopWages(){
        usersToRecalculate.forEach((companyUserIdentifier)=>{
            const entry = entries.find((e)=>e.companyUserIdentifier == companyUserIdentifier);
            entry.recalculateStopWages(settlementReportData, payData);
            handleSetEntry(entry);
        });
        setShowRecalculateModal(false);
    }

    function handleCalculateDaysWorked(){
        usersToRecalculate.forEach((companyUserIdentifier) => {
            const entry = entries.find((e)=>e.companyUserIdentifier == companyUserIdentifier);
            entry.recalculateDaysWorkedFromSettlementReport(settlementReportData, payData);
            handleSetEntry(entry);
        })
        setShowRecalculateDaysWorked(false);
    }

    const usersToRecalculateElements = entries.filter(e => employeeFilter.includes(e.companyUserIdentifier)).map(e => e.companyUserIdentifier).map((e)=>{
        const isActive = usersToRecalculate.includes(e);
        const user = entries.find((u)=>u.companyUserIdentifier == e);
        return (
            <ListGroup.Item key={e} action active={isActive} onClick={()=>{handleAddUserToRecalculate(e)}}>{user.lastName + ', ' + user.firstName + ' ' + user.middleName.substring(0,1)}</ListGroup.Item>
        )
    });


    const userListItems = entries.filter(e => employeeFilter.includes(e.companyUserIdentifier)).map(e => e.companyUserIdentifier).map((companyUserIdentifier) => {
        const entry = entries.find(e => e.companyUserIdentifier == companyUserIdentifier);
        const availableStatus = entry.getEmployeeAvailableDataStatus(settlementReportData);

        return (
            <ListGroup.Item key={companyUserIdentifier} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <span>{entry.name()}</span>
                <div style={{display: 'flex', gap: 12}}>
                    <OverlayTrigger overlay={
                        <Popover style={{position: 'fixed'}}>
                            <Popover.Header style={{padding: 6}}>{entry.settlementReportStatus == 'complete' ? 'Complete Data Utilized' : entry.settlementReportStatus == 'partial' ? 'Partial Data Utilized' : 'No Data Utilized'}</Popover.Header>
                            <Popover.Body style={{padding: 6}}>{`This represents the settlement data used to generate the stop wages for this employee.`}</Popover.Body>
                        </Popover>
                    }>
                        <div className='cursor-pointer' style={{border: '1px solid lightgray', borderRadius: 12, padding: 2}}>
                            <span style={{opacity: 0.5, fontStyle: 'italic', marginRight: 4}}>Utilized:</span>
                            <FontAwesomeIcon style={{color: entry.settlementReportStatus == 'complete' ? 'limegreen' : entry.settlementReportStatus == 'partial' ? 'gold' : 'lightgray'}} icon={entry.settlementReportStatus == 'complete' ? faCircle : entry.settlementReportStatus == 'partial' ? faCircleHalfStroke : faCircleEmpty}/>
                        </div>
                    </OverlayTrigger>
                    <div style={{width: 1, backgroundColor: 'black'}}/>
                    <OverlayTrigger overlay={
                        <Popover style={{position: 'fixed'}}>
                            <Popover.Header style={{padding: 6}}>{availableStatus == 'complete' ? 'Complete Data Available' : availableStatus == 'partial' ? 'Partial Data Available' : 'No Data Available'}</Popover.Header>
                            <Popover.Body style={{padding: 6}}>{`This represents the settlement data in our system between ${moment(payrollPeriod.periodStart).format('MMM D, YYYY')} - ${moment(payrollPeriod.periodEnd).format('MMM D, YYYY')} for this employee.`}</Popover.Body>
                        </Popover>
                    }>
                        <div className='cursor-pointer' style={{border: '1px solid lightgray', borderRadius: 12, padding: 2}}>
                            <span style={{opacity: 0.5, fontStyle: 'italic', marginRight: 4}}>Available:</span>
                            <FontAwesomeIcon style={{color: availableStatus == 'complete' ? 'limegreen' : availableStatus == 'partial' ? 'gold' : 'lightgray'}} icon={availableStatus == 'complete' ? faCircle : availableStatus == 'partial' ? faCircleHalfStroke : faCircleEmpty}/>
                        </div>
                    </OverlayTrigger>
                </div>
            </ListGroup.Item>
        )
    });

    const settlementReportElements = settlementReportData.map((s)=>{
        const csaName = csas.find((c)=>c.fedexId == s.csaFedexId)?.csaName;

        return (
            <KeyValueRow key={`${s.startDate} ${s.csaFedexId} ${s.isWsw}`} isListItem title={`${s.isWsw ? 'Weekly Settlement Report' : 'Charge Statement'} | ${csaName}`} value={`${moment(s.startDate).format('MMM D, YYYY')} - ${moment(s.startDate).add(6, 'days').format('MMM D, YYYY')}`}/>
        )
    });

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Settlement Report Data</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <span>Please upload all settlement reports for this payroll period. As you upload reports you can view the affected employees and days below.</span>
                <ListGroup>{userListItems}</ListGroup>
                <span style={{fontWeight: 'bold', opacity: '0.5', fontSize: 14}}>LOADED SETTLEMENT REPORT DATA</span>
                <ListGroup>{settlementReportElements}</ListGroup>
                <span style={{fontWeight: 'bold', opacity: '0.5', fontSize: 14}}>UPLOAD CHARGE STATEMENT</span>
                <ChargeStatementUpload createChargeStatementCallback={handleRecalculateSettlementReportData} matchFedexIdsCallback={handleRecalculateSettlementReportData}/>
                <span style={{fontWeight: 'bold', opacity: '0.5', fontSize: 14}}>UPLOAD WEEKLY SERVICE WORKSHEET</span>
                <WeeklyServiceWorksheetUpload createWswCallback={handleRecalculateSettlementReportData} matchWswNamesCallback={handleRecalculateSettlementReportData}/>
            </Modal.Body>
             {!adminMode && 
                <Modal.Footer>
                    <CustomButton label={'Recalculate Days Worked'} onClick={()=>setShowRecalculateDaysWorked(true)}/>
                    <CustomButton label={'Recalculate Stop Wages'} onClick={()=>setShowRecalculateModal(true)}/>
                </Modal.Footer>
            }
            <Modal show={showRecalculateModal} onHide={() => {setShowRecalculateModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Recalculate Stop Wages</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {usersToRecalculateElements}
                    </ListGroup>
                    <FontAwesomeIcon icon={faTriangleExclamation} style={{color: 'gold', marginRight: 8}}/>
                    Upon recalculating an employee's stop wages, any preexisting stops & stop wages will be deleted and recreated based upon the uploaded settlement data.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='outline-primary' onClick={handleRecalculateStopWages}>Proceed</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showRecalculateDaysWorked} onHide={() => {setShowRecalculateDaysWorked(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Recalculate Days Worked From Settlement Report Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {usersToRecalculateElements}
                    </ListGroup>
                    <FontAwesomeIcon icon={faTriangleExclamation} style={{color: 'gold', marginRight: 8}}/>
                    Upon recalculating an employee's days worked, any preexisting daily wages will be deleted and recreated based upon the uploaded settlement data.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='outline-primary' onClick={handleCalculateDaysWorked}>Proceed</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}