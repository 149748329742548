/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import CustomButton from "../../components/CustomButton";
import CustomControl from '../../components/CustomControl';
import SwitchControl from "../../components/SwitchControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { ApiRequest } from "../../ApiManager.tsx";

export default function MyCompanyDocumentEditor({document, handleEditDocument}) {
    const [isLoading, setIsLoading] = useState(false);
    const [documentName, setDocumentName] = useState(document.documentName);
    const [isRequired, setIsRequired] = useState(document.isRequired);

    function handleSubmit(){
        const newDocument = {
            ...document,
            documentName: documentName,
            isRequired: isRequired,
        }
        new ApiRequest('myCompany', 'editCompanyDocument', setIsLoading, () =>  handleEditDocument(newDocument)).withData({data: newDocument}).send()
    }

    return (
        <>
            <Modal.Header>
                <Modal.Title>Editing Company Document</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <CustomControl disabled={isLoading} value={documentName} maxLength={100} onChange={(event) => {setDocumentName(event.target.value)}} originalValue={document.documentName} title='Document Name'/>
                <SwitchControl disabled={isLoading || document.documentType !== 'pdf'} value={isRequired} setValue={setIsRequired} originalValue={document.isRequired} title='Require Employees to Acknowledge Document'/>
                { document.documentType === 'pdf' &&
                <div style={{display: 'flex', alignItems: 'center', gap: 12, color: 'gray'}}>
                        <FontAwesomeIcon icon={faInfoCircle}/>
                        <span>Make Sure Document Name is Correct if Enabling Acknowledgements</span>
                    </div>
                }
                { document.documentType !== 'pdf' &&
                    <div style={{display: 'flex', alignItems: 'center', gap: 12, color: 'gray'}}>
                        <FontAwesomeIcon icon={faInfoCircle}/>
                        <span>Only .pdf files are eligible for this feature</span>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <CustomButton isLoading={isLoading} label='Save Changes' onClick={handleSubmit}/>
            </Modal.Footer>
        </>
    )
}