/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { createRef, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { employeeTypeDictionary, payTypeDictionary, stateDictionary, validateDecimal } from "../../../../tools";
import CustomButton from "../../../../components/CustomButton";
import onboardingTemplate from '../../../../assets/OnboardingTemplate.xlsx'
import moment from "moment";
import QuickTable from "../../../../components/QuickTable";
import { Dropdown, InputGroup } from "react-bootstrap";
import { ValidationGroup, validateEmail, validateExistence, validatePhoneNumber } from "../../../../validation";
import { ApiRequest } from "../../../../ApiManager.tsx";

export default function HRHireCSV({csas}){
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [fileError, setFileError] = useState(undefined);
    const fileRef = useRef();
    const validationGroup = new ValidationGroup();

    async function handleFileChange() {
        const file = fileRef.current.files[0];
        if (file) {
            const indexOfDot = file.name.lastIndexOf('.');
            const fileType = file.name.slice(indexOfDot + 1);
            if (fileType !== 'csv') {
                setFileError('Invalid file type. Please upload a .csv file.')
                return;
            } else if (fileError) {
                setFileError(undefined);
            }
            
            const employeeArray = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onerror = () => {}
                reader.onload = () => {
                    const str = reader.result;
                    let rows = str.split(/\r\n|\r|\n/);
                    rows = rows.slice(5);
                    for (let i = 0; i < rows.length; i++) {
                        rows[i] = rows[i].split(',');
                        rows[i] = rows[i].slice(0, 18);
                    }
                    rows = rows.filter(r => r.find(c => c)); //filters out rows which have no data in any cell
                    const employees = [];
                    for (let i = 0; i < rows.length; i++) {
                        const row = rows[i];
                        const csa = csas.find(csa => csa.csaName.toLowerCase() === row[13].toLowerCase());
                        const employee = {
                            firstName: row[0] ?? '',
                            middleName: row[1] ?? '',
                            lastName: row[2] ?? '',
                            email: row[3] ?? '', 
                            phoneNumber: row[4] ?? '', 
                            dateOfBirth: moment(row[5], 'M/DD/YYYY').isValid() ? moment(row[5], 'M/DD/YYYY').format('YYYY-MM-DD') : '', 
                            ssn: row[6] ?? '', 
                            thoroughfare: row[7] ?? '', 
                            premise: row[8] ?? '', 
                            administrativeArea: Object.values(stateDictionary).includes(row[9]) ? row[9] : '', 
                            locality: row[10] ?? '', 
                            postalCode: row[11] ?? '',
                            payType: row[12] ?? '',
                            csaIdentifier: csa?.uid,
                            employeeType: row[14] === 'Full-Time' ? '0' : row[14] === 'Part-Time' ?  '1' : '',
                            title: row[15] ?? '',
                            payRate: row[16] ?? '',
                            hourlyWage: row[17] ?? '',            
                        } 
                        employees.push(employee);
                    }
                    resolve(employees);
                }
                reader.readAsText(file);
            });
            setEmployees(employeeArray);
        }
    }

    function handleSubmit(){
        const mappedEmployees = employees.map((e) => {
            return {...e, payRate: validateDecimal(e.payRate), hourlyWage: validateDecimal(e.hourlyWage), address: {
                thoroughfare: e.thoroughfare,
                premise: e.premise,
                administrativeArea: e.administrativeArea,
                locality: e.locality,
                postalCode: e.postalCode,
            }}
        })
        new ApiRequest('hr', 'csvHire', setIsSubmitting, () => window.location.reload()).withData({employees: mappedEmployees}).send();
    }

    function handleSetEmployee(index, key, value){
        const newEmployees = Array.from(employees);
        newEmployees[index][key] = value;
        setEmployees(newEmployees);
    }

    const employeeRows = employees.map((employee, index) => {
        return (
            <tr key={index}>
                <td>
                    <TableField value={employee.firstName} setValue={(value) => {handleSetEmployee(index, 'firstName', value)}} validator={validationGroup.createValidator(() => validateExistence(employee.firstName))}/>
                </td>
                <td>
                    <TableField value={employee.middleName} setValue={(value) => {handleSetEmployee(index, 'middleName', value)}} validator={validationGroup.createValidator(() => null)}/>
                </td>
                <td>
                    <TableField value={employee.lastName} setValue={(value) => {handleSetEmployee(index, 'lastName', value)}} validator={validationGroup.createValidator(() => validateExistence(employee.lastName))}/>
                </td>
                <td>
                    <TableField value={employee.email} setValue={(value) => {handleSetEmployee(index, 'email', value)}} validator={validationGroup.createValidator(() => validateEmail(employee.email))}/>
                </td>
                <td>
                    <TableField value={employee.phoneNumber} setValue={(value) => {handleSetEmployee(index, 'phoneNumber', value)}} validator={validationGroup.createValidator(() => validatePhoneNumber(employee.phoneNumber))}/>
                </td>
                <td>
                    <TableField value={employee.dateOfBirth} setValue={(value) => {handleSetEmployee(index, 'dateOfBirth', value)}} type='date'/>
                </td>
                <td>
                    <TableField value={employee.ssn} setValue={(value) => {handleSetEmployee(index, 'ssn', value)}}/>
                </td>
                <td>
                    <TableField value={employee.thoroughfare} setValue={(value) => {handleSetEmployee(index, 'thoroughfare', value)}}/>
                </td>
                <td>
                    <TableField value={employee.premise} setValue={(value) => {handleSetEmployee(index, 'premise', value)}}/>
                </td>
                <td>
                    <TablePicker value={employee.administrativeArea} setValue={(value) => {handleSetEmployee(index, 'administrativeArea', value)}} itemNames={Object.keys(stateDictionary)} itemValues={Object.values(stateDictionary)}/>
                </td>
                <td>
                    <TableField value={employee.locality} setValue={(value) => {handleSetEmployee(index, 'locality', value)}}/>
                </td>
                <td>
                    <TableField value={employee.postalCode} setValue={(value) => {handleSetEmployee(index, 'postalCode', value)}} type='number'/>
                </td>
                <td>
                    <TablePicker value={employee.payType} setValue={(value) => {handleSetEmployee(index, 'payType', value)}} itemNames={Object.values(payTypeDictionary)} itemValues={Object.keys(payTypeDictionary)} validator={validationGroup.createValidator(() => validateExistence(employee.payType))}/>
                </td>
                <td>
                    <TablePicker value={employee.csaIdentifier} setValue={(value) => {handleSetEmployee(index, 'csaIdentifier', value)}} itemNames={csas.map(c => c.csaName)} itemValues={csas.map(c => c.uid)} validator={validationGroup.createValidator(() => validateExistence(employee.csaIdentifier))}/>
                </td>
                <td>
                    <TablePicker value={employee.employeeType} setValue={(value) => {handleSetEmployee(index, 'employeeType', value)}} itemNames={Object.values(employeeTypeDictionary)} itemValues={Object.keys(employeeTypeDictionary)} validator={validationGroup.createValidator(() => validateExistence(employee.employeeType))}/>
                </td>
                <td>
                    <TablePicker value={employee.title} setValue={(value) => {handleSetEmployee(index, 'title', value)}} itemNames={['Driver', 'AVP Driver', 'Jumper', 'BC']} itemValues={['Driver', 'AVP Driver', 'Jumper', 'BC']} validator={validationGroup.createValidator(() => validateExistence(employee.title))}/>
                </td>
                <td>
                    <TableField value={employee.payRate} setValue={(value) => {handleSetEmployee(index, 'payRate', value)}} type='number'/>
                </td>
                <td>
                    <TableField value={employee.hourlyWage} setValue={(value) => {handleSetEmployee(index, 'hourlyWage', value)}} type='number'/>
                </td>
            </tr>
        )
    })

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Upload Onboarding CSV</Modal.Title>
            </Modal.Header>
            <Modal.Header style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                <p style={{marginBottom: 0}}>1. Download the <a download='OnboardingTemplate.xlsx' href={onboardingTemplate}>onboarding template</a></p>
                <p style={{marginBottom: 0}}>2. Fill it out with the employees you need to hire</p>
                <p>3. Upload onboarding CSV</p>
                <Form.Control type='file' ref={fileRef} accept=".csv" onChange={handleFileChange}/>
                { fileError && 
                    <span style={{color: 'red'}}>{fileError}</span>
                }
            </Modal.Header>
            { employeeRows.length > 0 && 
                <>
                    <Modal.Body style={{width: '100%', overflowX: 'auto'}}>
                        <div style={{width: 3500}}>
                            <QuickTable 
                                responsive={false}
                                headers={['First Name', 'Middle Name', 'Last Name', 'Email Address', 'Phone Number', 'Date of Birth', 'SSN', 'Street Address 1', 'Street Address 2', 'State', 'City', 'Postal Code', 'Pay Type', 'CSA', 'Employee Type', 'Title', 'Pay Rate', 'Hourly Wage']}
                                rows={employeeRows}
                                widths={[null, null, null, null, null, 110, null, null, null, null, null, 120, 170, null, 170, 180, 120, 120]}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <CustomButton label='Submit' disabled={!validationGroup.isValid() || employees.length == 0} isLoading={isSubmitting} onClick={handleSubmit}/>
                    </Modal.Footer>
                </>
            }
        </>
    )
}  


// export default class HRHireCSV extends React.Component {
//     constructor(props) {
//         super(props);
//         this.fileRef = createRef();
//         this.state = {
//             isLoading: false,
//             employees: [],
//             fileError: undefined,
//             apiError: undefined,
//         }
//         this.handleFileChange = this.handleFileChange.bind(this);
//         this.handleSubmit = this.handleSubmit.bind(this);
//         this.handleSetEmployee = this.handleSetEmployee.bind(this);
//     }

//     async handleFileChange() {
//         const file = this.fileRef.current.files[0];
//         if (file) {
//             const indexOfDot = file.name.lastIndexOf('.');
//             const fileType = file.name.slice(indexOfDot + 1);
//             if (fileType !== 'csv') {
//                 this.setState({fileError: 'Invalid file type. Please upload a .csv file.'});
//                 return;
//             } else if (this.state.fileError) {
//                 this.setState({fileError: undefined});
//             }
            
//             const employeeArray = await new Promise((resolve, reject) => {
//                 const reader = new FileReader();
        
//                 reader.onerror = () => {
                    
//                 }
//                 reader.onload = () => {
        
//                     const str = reader.result;
        
//                     let rows = str.split(/\r\n|\r|\n/);
//                     rows = rows.slice(5);
//                     for (let i = 0; i < rows.length; i++) {
//                         rows[i] = rows[i].split(',');
//                         rows[i] = rows[i].slice(0, 17);
//                     }
    
//                     rows = rows.filter(r => r.find(c => c)); //filters out rows which have no data in any cell
                   
//                     const employees = [];
//                     for (let i = 0; i < rows.length; i++) {
//                         const row = rows[i];
//                         const csa = csas.find(csa => csa.csaName.toLowerCase() === row[13].toLowerCase());
//                         const employee = {
//                             firstName: row[0] ?? '',
//                             middleName: row[1] ?? '',
//                             lastName: row[2] ?? '',
//                             email: row[3] ?? '', 
//                             phoneNumber: row[4] ?? '', 
//                             dateOfBirth: moment(row[5], 'M/DD/YYYY').isValid() ? moment(row[5], 'M/DD/YYYY').format('YYYY-MM-DD') : '', 
//                             ssn: row[6] ?? '', 
//                             thoroughfare: row[7] ?? '', 
//                             premise: row[8] ?? '', 
//                             administrativeArea: Object.values(stateDictionary).includes(row[9]) ? row[9] : '', 
//                             locality: row[10] ?? '', 
//                             postalCode: row[11] ?? '',
//                             payType: row[12] ?? '',
//                             csaIdentifier: csa?.uid,
//                             employeeType: row[14] === 'Full-Time' ? '0' : row[14] === 'Part-Time' ?  '1' : '',
//                             title: row[15] ?? '',
//                             payRate: row[16] ?? '',
//                             hourlyWage: row[17] ?? '',            
//                         }
                        
//                         employees.push(employee);
//                     }
    
//                     resolve(employees);
    
//                 }

//                 reader.readAsText(file);
//             });

//             this.setState({employees: employeeArray});
//         }
//     }

//     async handleSubmit() {
//         this.setState({isLoading: true, apiError: undefined});

//         const mappedEmployees = this.state.employees.map((e) => {
//             return {...e, payRate: validateDecimal(e.payRate), hourlyWage: validateDecimal(e.hourlyWage), address: {
//                 thoroughfare: e.thoroughfare,
//                 premise: e.premise,
//                 administrativeArea: e.administrativeArea,
//                 locality: e.locality,
//                 postalCode: e.postalCode,
//             }}
//         })

//         const response = await csvHire(mappedEmployees);
//         if (response.status == '200') {
//             window.location.reload();
//         } else {
//             this.setState({apiError: response.message});
//         }
//         this.setState({isLoading: false});
//     }

//     handleSetEmployee = (index, key, value) => {
//         const newEmployees = Array.from(this.state.employees);
//         newEmployees[index][key] = value;
//         this.setState({employees: newEmployees});
//     }

//     render() {

//         const validationGroup = new ValidationGroup();


//         const employeeRows = this.state.employees.map((employee, index) => {
//             return (
//                 <tr key={index}>
//                     <td>
//                         <TableField value={employee.firstName} setValue={(value) => {this.handleSetEmployee(index, 'firstName', value)}} validator={validationGroup.createValidator(() => validateExistence(employee.firstName))}/>
//                     </td>
//                     <td>
//                         <TableField value={employee.middleName} setValue={(value) => {this.handleSetEmployee(index, 'middleName', value)}} validator={validationGroup.createValidator(() => null)}/>
//                     </td>
//                     <td>
//                         <TableField value={employee.lastName} setValue={(value) => {this.handleSetEmployee(index, 'lastName', value)}} validator={validationGroup.createValidator(() => validateExistence(employee.lastName))}/>
//                     </td>
//                     <td>
//                         <TableField value={employee.email} setValue={(value) => {this.handleSetEmployee(index, 'email', value)}} validator={validationGroup.createValidator(() => validateEmail(employee.email))}/>
//                     </td>
//                     <td>
//                         <TableField value={employee.phoneNumber} setValue={(value) => {this.handleSetEmployee(index, 'phoneNumber', value)}} validator={validationGroup.createValidator(() => validatePhoneNumber(employee.phoneNumber))}/>
//                     </td>
//                     <td>
//                         <TableField value={employee.dateOfBirth} setValue={(value) => {this.handleSetEmployee(index, 'dateOfBirth', value)}} type='date'/>
//                     </td>
//                     <td>
//                         <TableField value={employee.ssn} setValue={(value) => {this.handleSetEmployee(index, 'ssn', value)}}/>
//                     </td>
//                     <td>
//                         <TableField value={employee.thoroughfare} setValue={(value) => {this.handleSetEmployee(index, 'thoroughfare', value)}}/>
//                     </td>
//                     <td>
//                         <TableField value={employee.premise} setValue={(value) => {this.handleSetEmployee(index, 'premise', value)}}/>
//                     </td>
//                     <td>
//                         <TablePicker value={employee.administrativeArea} setValue={(value) => {this.handleSetEmployee(index, 'administrativeArea', value)}} itemNames={Object.keys(stateDictionary)} itemValues={Object.values(stateDictionary)}/>
//                     </td>
//                     <td>
//                         <TableField value={employee.locality} setValue={(value) => {this.handleSetEmployee(index, 'locality', value)}}/>
//                     </td>
//                     <td>
//                         <TableField value={employee.postalCode} setValue={(value) => {this.handleSetEmployee(index, 'postalCode', value)}} type='number'/>
//                     </td>
//                     <td>
//                         <TablePicker value={employee.payType} setValue={(value) => {this.handleSetEmployee(index, 'payType', value)}} itemNames={Object.values(payTypeDictionary)} itemValues={Object.keys(payTypeDictionary)} validator={validationGroup.createValidator(() => validateExistence(employee.payType))}/>
//                     </td>
//                     <td>
//                         <TablePicker value={employee.csaIdentifier} setValue={(value) => {this.handleSetEmployee(index, 'csaIdentifier', value)}} itemNames={this.props.csas.map(c => c.csaName)} itemValues={this.props.csas.map(c => c.uid)} validator={validationGroup.createValidator(() => validateExistence(employee.csaIdentifier))}/>
//                     </td>
//                     <td>
//                         <TablePicker value={employee.employeeType} setValue={(value) => {this.handleSetEmployee(index, 'employeeType', value)}} itemNames={Object.values(employeeTypeDictionary)} itemValues={Object.keys(employeeTypeDictionary)} validator={validationGroup.createValidator(() => validateExistence(employee.employeeType))}/>
//                     </td>
//                     <td>
//                         <TablePicker value={employee.title} setValue={(value) => {this.handleSetEmployee(index, 'title', value)}} itemNames={['Driver', 'AVP Driver', 'Jumper', 'BC']} itemValues={['Driver', 'AVP Driver', 'Jumper', 'BC']} validator={validationGroup.createValidator(() => validateExistence(employee.title))}/>
//                     </td>
//                     <td>
//                         <TableField value={employee.payRate} setValue={(value) => {this.handleSetEmployee(index, 'payRate', value)}} type='number'/>
//                     </td>
//                     <td>
//                         <TableField value={employee.hourlyWage} setValue={(value) => {this.handleSetEmployee(index, 'hourlyWage', value)}} type='number'/>
//                     </td>
//                 </tr>
//             )
//         })

//         return (
//             <>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Upload Onboarding CSV</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Header style={{flexDirection: 'column', alignItems: 'flex-start'}}>
//                     <p style={{marginBottom: 0}}>1. Download the <a download='OnboardingTemplate.xlsx' href={onboardingTemplate}>onboarding template</a></p>
//                     <p style={{marginBottom: 0}}>2. Fill it out with the employees you need to hire</p>
//                     <p>3. Upload onboarding CSV</p>
//                     <Form.Control type='file' ref={this.fileRef} accept=".csv" onChange={this.handleFileChange}/>
//                     { this.state.fileError && 
//                         <span style={{color: 'red'}}>{this.state.fileError}</span>
//                     }
//                 </Modal.Header>
//                 { employeeRows.length > 0 && 
//                     <>
//                         <Modal.Body style={{width: '100%', overflowX: 'auto'}}>
//                             <div style={{width: 3500}}>
//                                 <QuickTable 
//                                     responsive={false}
//                                     headers={['First Name', 'Middle Name', 'Last Name', 'Email Address', 'Phone Number', 'Date of Birth', 'SSN', 'Street Address 1', 'Street Address 2', 'State', 'City', 'Postal Code', 'Pay Type', 'CSA', 'Employee Type', 'Title', 'Pay Rate', 'Hourly Wage']}
//                                     rows={employeeRows}
//                                     widths={[null, null, null, null, null, 110, null, null, null, null, null, 120, 170, null, 170, 180, 120, 120]}
//                                 />
//                             </div>
//                         </Modal.Body>
//                         <Modal.Footer>
//                             { this.state.apiError &&
//                                 <span style={{color: 'red'}}>{this.state.apiError}</span>
//                             }
//                             <CustomButton label='Submit' disabled={!validationGroup.isValid() || this.state.employees.length == 0} isLoading={this.state.isLoading} onClick={this.handleSubmit}/>
//                         </Modal.Footer>
//                     </>
//                 }
//             </>
//         )
//     }

// }

function TableField({value, setValue, type, validator}) {
    return (
        <Form.Control 
            value={value} 
            onChange={(event) => {setValue(event.target.value)}} 
            type={type} 
            isInvalid={validator && !validator.isValid()} 
            isValid={validator && validator.isValid()}
        />
    )
}

function TablePicker({value, setValue, itemNames, itemValues, validator}) {
    const [show, setShow] = useState(false);



    const getNameForValue = (v) => {
        const index = itemValues.indexOf(v);
        if (index >= 0) {
            return itemNames[index];
        } else {
            return '';
        }
    }
    
    const getActiveName = () => {
        for (let i = 0; i < itemValues.length; i++) {
            if (itemValues[i] == value) {
                return itemNames[i];
            }
        }
        return '';
    }
    const [search, setSearch] = useState(getActiveName());
    
    const handleSearch = (newValue) => {
        const index = itemNames.indexOf(newValue);
        if (index >= 0) {
            setValue(itemValues[index]);
        } else {
            setValue('');
        }
        setSearch(newValue);
        if (!show) {
            setShow(true);
        }
    }

    const filteredItems = itemValues.filter((e, i) => {
        return itemNames[i].toLowerCase().includes(search.toLowerCase());
    })
    
    const dropdownItems = filteredItems.map((key, index) => {
        return (
            <Dropdown.Item key={key} active={value == key || (!value && search && index == 0)} onClick={() => {setValue(key); setSearch(getNameForValue(key)); setShow(false)}}>{getNameForValue(key)}</Dropdown.Item>
        )
    });

    return (
        <Dropdown as={InputGroup} show={show} onBlur={(event) => {
            if (!event.currentTarget.contains(event.relatedTarget)) {
                setShow(false);
            }
        }}>
            <Form.Control 
                value={search}
                onFocus={() => {setShow(true)}}
                onChange={(event) => {handleSearch(event.target.value)}}
                onKeyDown={(event) => {
                    if (event.key === 'Enter' && search) {
                        const match = filteredItems.find(v => getNameForValue(v).toLowerCase().includes(search.toLowerCase()));
                        if (match) {
                            setValue(match);
                            setSearch(getNameForValue(match));
                            setShow(false);
                        }
                    }
                }}
                style={{backgroundColor: 'white'}} 
                isInvalid={validator && !validator.isValid()} 
                isValid={validator && validator.isValid()} 
            />
            <Dropdown.Toggle variant={'outline-primary'} onClick={() => {setShow(!show)}}/>
            <Dropdown.Menu>
                {dropdownItems}
            </Dropdown.Menu>
        </Dropdown>
    )
}