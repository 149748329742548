/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

export default function PayrollPeriodNonBonusAPColumnInclusion(entries) {

    const additionalPayMaxCountByType = {
        'Per Diem': 0,
        'Expense Reimbursement': 0,
        'Extra Day Wage': 0,
        'Backpay': 0,
    }

    entries.forEach((entry) => {
        const typeCounts = {
            'Per Diem': 0,
            'Expense Reimbursement': 0,
            'Extra Day Wage': 0,
            'Backpay': 0
        };
        entry.getAllAdditionalPay().forEach((ap) => {
            typeCounts[ap.type]++;
        });
        
        Object.keys(additionalPayMaxCountByType).forEach((key) => {
            if (typeCounts[key] > additionalPayMaxCountByType[key]) {
                additionalPayMaxCountByType[key] = typeCounts[key];
            }
        })
    });

    return additionalPayMaxCountByType;
}