/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import moment from 'moment';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import HRUserSchedulePTODaySelector from './HRUserSchedulePTODaySelector';
import { scheduleTypeDictionary } from '../../../tools';
import Cookies from 'universal-cookie';
import TextAreaControl from '../../../components/TextAreaControl';
import CustomButton from '../../../components/CustomButton';
import HRTimeOffModal from './HRTimeOffModal';
import KeyValueRow from '../../../components/KeyValueRow';
import CustomDatePicker from '../../../components/CustomDatePicker/CustomDatePicker';
import { ApiRequest } from '../../../ApiManager.tsx';

export default function HRUserScheduleModal({selectedItem, handleAnswerRequest, handleAddPTO, handleUpdateSelectedItem, handleDeleteScheduleItem, timeOff, hideModal}) {
    const [isLoading, setIsLoading] = useState(false);
    const [showDaySelector, setShowDaySelector] = useState(false);
    const [showTimeOffModal, setShowTimeOffModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [date, setDate] = useState(selectedItem.date);
    const [endDate, setEndDate] = useState(selectedItem.endDate);
    const cookieMonster = new Cookies();
    const isAo = cookieMonster.get('userData').title === 'AO';
    const isBc = cookieMonster.get('userData').title === 'BC';

    function handleRejectRequest(){
        new ApiRequest('hr', 'rejectRequestOff', setIsLoading, () => {
            handleAnswerRequest(selectedItem.uid, 'rejectedRequest'); 
        }).withData({scheduleItem: selectedItem}).withUid(selectedItem.uid).send();
    }

    function handleDeleteItem(){
        new ApiRequest('hr', 'deleteScheduleItem', setIsDeleting, () => {
            handleDeleteScheduleItem(selectedItem.uid);
            hideModal();
        }).withData({scheduleItemIdentifier: selectedItem.uid}).send();
    }

    function handleSettingNotes(value){
        handleUpdateSelectedItem('notes',value);
    }
    
    let listItems = [];
    listItems.push({title: selectedItem.endDate ? 'Start Date' : 'Date', value: moment(selectedItem.date).format('MMM D, YYYY')});
    if (selectedItem.endDate) {
        listItems.push({title: 'End Date', value: selectedItem.endDate ? moment(selectedItem.endDate).format('MMM D, YYYY') : undefined});
    }
    listItems.push({title: 'Submission Date', value: moment(selectedItem.submissionDate).format('MMM D, YYYY')});
    listItems.push({title: 'Notes', value: selectedItem.notes});

    const disabledDates = timeOff.filter(si => si.uid !== selectedItem.uid).reduce((prev, curr) => {
        if (curr.scheduleType === 'requestOff') {
            const dates = [];
            const numberOfDays = moment(curr.endDate).diff(curr.date, 'days') + 1;
            for (let i = 0; i < numberOfDays; i++) {
                dates.push(moment(curr.date).add(i, 'days').format('YYYY-MM-DD'));
            }
            return prev.concat(dates);

        } else {
            return prev.concat([curr.date]);
        }
    }, []);

    const listItemElements = listItems.map((item) => {
        return (
            <ListGroup.Item key={item.title}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <p style={{margin: 0, marginRight: 8}}>{item.title}</p>
                    <p style={{margin: 0}}>{item.value}</p>
                </div>
            </ListGroup.Item>
        )
    });

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{scheduleTypeDictionary[selectedItem.scheduleType].label}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { selectedItem.scheduleType === 'requestOff' &&
                    <Button style={{marginBottom: 12}} variant='outline-primary' onClick={() => {setShowTimeOffModal(true)}}>View Pending & Accepted Time Off During This Date Range</Button>
                }
                <ListGroup>
                    {(isAo || isBc) && selectedItem.scheduleType === 'requestOff' ? (
                        <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                            
                            <CustomDatePicker 
                                title='Date Range' 
                                minDate={moment().add(1, 'day').format('YYYY-MM-DD')} 
                                startDate={date} 
                                setStartDate={setDate} 
                                setEndDate={setEndDate} 
                                endDate={endDate}
                                disabledDates={disabledDates}
                            />
                            
                            <ListGroup>
                                <ListGroup.Item>
                                    <TextAreaControl label='Notes' disabled={moment(selectedItem.date).isBefore(moment(), 'day')} max={65535} rows={6} value={selectedItem.notes} setValue={(event)=>handleSettingNotes(event.target.value)}/> 
                                </ListGroup.Item>
                                <KeyValueRow isListItem title='Requested As' value={selectedItem.timeScheduled ? 'PTO' : 'Unpaid Time Off'}/>
                                <KeyValueRow isListItem title='Submission Date' value={moment(selectedItem.submissionDate).format('MMM D, YYYY')}/>
                            </ListGroup>
                        </div>
                    ):(
                        listItemElements
                    )}
                </ListGroup>
            </Modal.Body>
            {selectedItem.scheduleType === 'requestOff' && moment(selectedItem.date).isAfter(moment(), 'day') ? 
                <Modal.Footer>
                    <Button variant={'outline-primary'} onClick={() => {setShowDaySelector(true)}}>Accept Request</Button>
                    <CustomButton label='Reject Request' isLoading={isLoading} onClick={handleRejectRequest}/>
                </Modal.Footer>
                : 
                <Modal.Footer>
                    <Button variant='outline-danger' onClick={()=>setShowDeleteModal(true)}>
                        Delete Item
                    </Button>
                </Modal.Footer>
            }
            <Modal show={showDaySelector} onHide={() => {setShowDaySelector(false)}}>
                <HRUserSchedulePTODaySelector selectedItem={{...selectedItem, date: date, endDate: endDate}} handleAddPTO={() => {setShowDaySelector(false); handleAddPTO()}}/>
            </Modal>
            <Modal show={showTimeOffModal} onHide={() => {setShowTimeOffModal(false)}} size='lg'>
                <HRTimeOffModal startDate={selectedItem.date} endDate={selectedItem.endDate}/>
            </Modal>
            <Modal show={showDeleteModal} onHide={()=>setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Delete Confirmation
                    </Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete this item?
                    </Modal.Body>
                <Modal.Footer>
                    <CustomButton variant='outline-danger' isLoading={isDeleting} label='Delete' onClick={handleDeleteItem}/>
                </Modal.Footer>
            </Modal>
        </>
    )
}