/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import Button from 'react-bootstrap/Button';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import MyCompanyCSAs from "./MyCompanyCSAs";
import MyCompanyWorkAreas from "./MyCompanyWorkAreas";
import MyCompanyVehicles from "./MyCompanyVehicles";
import MyCompanyProfile from "./MyCompanyProfile";
import MyCompanySettings from "./MyCompanySettings";
import MyCompanyNotes from "./MyCompanyNotes";
import MyCompanyDocuments from "./MyCompanyDocuments";
import Cookies from "universal-cookie";

class MyCompany extends React.Component {
    constructor(props) {
        super(props);
        
        this.isRestrictedBc = new Cookies().get('userData').title === 'BC' && new Cookies().get('restrictBcsToCsa') == '1';
        this.restrictedToCsa = this.isRestrictedBc ? new Cookies().get('userData').csaIdentifier : undefined;

        this.state = {
            activeTab: this.isRestrictedBc ? 'Work Areas' : 'Company Profile'
        }
    }


    render() {
       
        const tabs = this.isRestrictedBc ? {
            'Work Areas': <MyCompanyWorkAreas restrictedToCsa={this.restrictedToCsa}/>,
            'Vehicles': <MyCompanyVehicles restrictedToCsa={this.restrictedToCsa}/>,
            'Documents': <MyCompanyDocuments/>,
            'Notes': <MyCompanyNotes/>,
        } : {
            'Company Profile': <MyCompanyProfile />,
            'Settings': <MyCompanySettings />,
            'Documents': <MyCompanyDocuments/>,
            'Notes': <MyCompanyNotes/>,
            'CSAs': <MyCompanyCSAs />,
            'Work Areas': <MyCompanyWorkAreas restrictedToCsa={this.restrictedToCsa}/>,
            'Vehicles': <MyCompanyVehicles restrictedToCsa={this.restrictedToCsa}/>
        };

        const tabButtons = Object.keys(tabs).map((key) => {
            return (
                <Button 
                    key={key}
                    style={this.state.activeTab === key ? {color: 'white'} : {color: 'var(--bs-primary)', backgroundColor: 'white'}} 
                    onClick={() => {this.setState({activeTab: key})}}
                    >
                    {key}
                </Button>
            )
        });
        
        return (
            <div className="page-wrapper" style={{padding: 12, flexDirection: 'column'}}>
                <ButtonGroup>
                    {tabButtons}
                </ButtonGroup>
                <div style={{height: '100%', margin: '12px 0px 0px 12px',  overflowY: 'scroll'}}>
                    <div style={{height: '100%', marginRight: 12}}>
                        {tabs[this.state.activeTab]}
                    </div>
                </div>
            </div>
        )
    }

}

export default MyCompany;