/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './ScheduleMatch.css';
import React, {useState, useRef} from 'react';
import Form from 'react-bootstrap/Form';
import Wizard from '../../components/Wizard/Wizard';
import Table from 'react-bootstrap/Table';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX, faSearch, faArrowUp, faEllipsis, faCheckCircle, faCheck, faWarning } from '@fortawesome/free-solid-svg-icons';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import QuickTimeSelector from '../../components/QuickTimeSelector/QuickTimeSelector';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Card from 'react-bootstrap/Card';
import CloseButton from 'react-bootstrap/CloseButton';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Cookies from 'universal-cookie';
import { Popover } from 'react-bootstrap';
import { ApiRequest } from '../../ApiManager.tsx';
import { naturalSort } from '../../tools.js';

export default function ScheduleMatchTemplateCreator({csas, users, vehicles, workAreas, hideModal, selectedTemplate, deselectTemplate, limitViewToCSA}) {
    const [wizardStepIndex, setWizardStepIndex] = useState(0);
    const [templateName, setTemplateName] = useState(selectedTemplate ? selectedTemplate.templateName : '');
    const [templateDescription, setTemplateDescription] = useState(selectedTemplate ? selectedTemplate.templateDescription : '');
    const [showExitAlert, setShowExitAlert] = useState(false);
    const [showSaveAlert, setShowSaveAlert] = useState(false)
    const [routes, setRoutes] = useState(selectedTemplate ? selectedTemplate.routes : []);
    const [nextID, setNextID] = useState(0);
    const [isCreating, setIsCreating] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    function handleCreateTemplate(){
        const newTemplate = {
            templateName: templateName,
            templateDescription: templateDescription,
            routes: routes
        };

        new ApiRequest('scheduleMatch', 'createTemplate', setIsCreating, () => {
            if (showSaveAlert) {
                setShowSaveAlert(false);
            }
            hideModal();
        }).withData({data: newTemplate}).send();
    }

    function handleUpdateTemplate(){
        const newTemplate = {
            uid: selectedTemplate.uid,
            templateName: templateName,
            templateDescription: templateDescription,
            routes: routes
        };

        new ApiRequest('scheduleMatch', 'updateTemplate', setIsUpdating, () => {
            if (showSaveAlert) {
                setShowSaveAlert(false);
            }
            deselectTemplate();
            hideModal();
        }).withData({data: newTemplate}).send();
    }

    function handleSetRoutes(newRoutes){
        setRoutes(newRoutes);
    }

    function getAvailableId(){
        const idToReturn = nextID;
        setNextID(nextID + 1);
        return idToReturn;
    }

    function handleCloseModal(){
        if (selectedTemplate) {
            deselectTemplate();
        }
        setShowExitAlert(false);
        hideModal();
    }

    const csaTabs = (limitViewToCSA ? [csas.find(csa => csa.uid == new Cookies().get('userData').csaIdentifier)] : csas).map((csa) => {
        const activeOrUsedWorkAreas = workAreas.filter(w => w.isActive == '1' || routes.find(r => w.uid == r.workAreaIdentifier));
        const workAreasInCsa = activeOrUsedWorkAreas.filter(wa => wa.csaIdentifier == csa.uid);
        const routesInCSA = routes.filter(r => workAreasInCsa.find(wa => wa.uid == r.workAreaIdentifier));
        const totalRoutesToComplete = workAreasInCsa.length * 7;
        const tabTitle = (
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 8}}>
                <p style={{margin: 0}}>{csa.csaName}</p>
                { routesInCSA.length === 0 ? '' :
                    <FontAwesomeIcon 
                        icon={routesInCSA.length === totalRoutesToComplete ? faCheckCircle : faEllipsis} 
                        style={{
                            color: routesInCSA.length === totalRoutesToComplete ? 'green' : 'white', 
                            backgroundColor: routesInCSA.length < totalRoutesToComplete ? 'gold' : '',
                            borderRadius: 10,
                            aspectRatio: 1,
                        }}
                    />
                }      
            </div>
        )
        return (
            <Tab key={csa.uid} eventKey={csa.uid} title={tabTitle}>
                <TemplateCreatorCSA 
                    csa={csa} 
                    users={users} 
                    vehicles={vehicles} 
                    workAreas={workAreasInCsa} 
                    routes={routes} 
                    handleSetRoutes={handleSetRoutes} 
                    getAvailableId={getAvailableId}
                />            
            </Tab>
        )
    });

    const wizardChildren = [
        <Tabs key={0} fill>
                {csaTabs}
        </Tabs>,
        <TemplateCreatorReview csas={csas} routes={routes} workAreas={workAreas} users={users} vehicles={vehicles} limitViewToCSA={limitViewToCSA}/>,
        <TemplateCreatorFinalize 
            templateName={templateName} 
            setTemplateName={setTemplateName} 
            templateDescription={templateDescription} 
            setTemplateDescription={setTemplateDescription} 
            handleCreateTemplate={handleCreateTemplate} 
            isEditing={selectedTemplate}
        />
    ]

    return (
        <>
            <Modal.Header>
                <Modal.Title>{!selectedTemplate ? 'Creating Template' : 'Editing Template'}</Modal.Title>
                <CloseButton onClick={() => setShowExitAlert(true)}/>
            </Modal.Header>
            <Modal.Body>
                <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                    <Wizard steps={['Create Routes', 'Review Routes', 'Label and Submit']} stepIndex={wizardStepIndex} setStepIndex={setWizardStepIndex}/>
                    <div style={{height: 1, backgroundColor: 'lightgray', marginTop: 12, marginBottom: 12}}/>
                    {wizardChildren[wizardStepIndex]}
                </div>
            </Modal.Body>
            {!selectedTemplate ? '' :
                <Modal.Footer>
                    <Button variant={'outline-primary'} disabled={!templateName} onClick={() => {setShowSaveAlert(true)}}>Save Changes</Button>
                </Modal.Footer>
            }
            <Modal show={showExitAlert} onHide={() => {setShowExitAlert(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Close Without Saving?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Warning - All unsubmitted changes in the template creator will be lost!</Modal.Body>
                <Modal.Footer>
                    <Button variant={'outline-primary'} onClick={() => setShowExitAlert(false)}>Cancel</Button>
                    <Button variant={'outline-primary'} onClick={handleCloseModal}>Close Without Saving</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showSaveAlert} onHide={() => {setShowSaveAlert(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Saving Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>Would you like to overwrite the existing template or save your changes as a new template?</Modal.Body>
                <Modal.Footer>
                    <Button variant={'outline-primary'} onClick={handleCreateTemplate}>Create New Template</Button>
                    <Button variant={'outline-primary'} onClick={handleUpdateTemplate}>Overwrite Existing Template</Button>
                </Modal.Footer>
            </Modal>
        </>  
    )
}

function TemplateCreatorWeekDay({csa, weekDayIndex, users, vehicles, workAreas, routes, handleSetRoutes, handleCopyPasteRoutes}) {
    const [filterByCsa, setFilterByCsa] = useState(true);
    const routeCreatorRef = useRef(null);
    const [selectedWorkArea, _setSelectedWorkArea] = useState(undefined);
    const [selectedUser, _setSelectedUser] = useState(undefined);
    const [selectedVehicle, _setSelectedVehicle] = useState(null);
    const [selectedTime, setSelectedTime] = useState('08:00');
    const [userSearch, setUserSearch] = useState('');
    const [isAvpDriver, setIsAvpDriver] = useState(false);
    const [AddRouteTab, setAddRouteTab] = useState('workArea');
    const daysOfTheWeek = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

    function AutoComplete(autoCompletedRoutes = []){
        const availableUsersForAutoComplete = availableUsers.filter((u) => {
            return !autoCompletedRoutes.find(r => r.companyUserIdentifier == u.companyUserUid) && u.availability[weekDayIndex];
        });
        const drivers = availableUsersForAutoComplete.filter(u => u.title === 'Driver');
        const fulltimeDrivers = drivers.filter(u => u.employeeType == '0');
        const parttimeDrivers = drivers.filter(u => u.employeeType == '1');
        const user = fulltimeDrivers.length > 0 ? fulltimeDrivers[0] : parttimeDrivers.length > 0 ? parttimeDrivers[0] : undefined;
        const workArea = workAreas.find(wa => !autoCompletedRoutes.find(r => r.workAreaIdentifier == wa.uid) && !routesInCsaOnDay.find(r => r.workAreaIdentifier == wa.uid));
        const vehicle = vehicles.find(v => !autoCompletedRoutes.find(r => r.vehicleIdentifier == v.uid) && !routesInCsaOnDay.find(r => r.vehicleIdentifier == v.uid));

        if (user && workArea && vehicle) {
            autoCompletedRoutes.push({
                uid: getFakeUid(autoCompletedRoutes.map((r)=>{return r.uid}).concat(routes.map((r)=>{return r.uid}))),
                dayIndex: weekDayIndex,
                companyUserIdentifier: user.companyUserUid,
                workAreaIdentifier: workArea.uid,
                vehicleIdentifier: vehicle.uid,
                timeScheduled: selectedTime
            });
            AutoComplete(autoCompletedRoutes);
        } else {
            handleSetRoutes(routes.concat(autoCompletedRoutes));
        }
    }

    function handleAddRoute(){
        let newRoutes = Array.from(routes);
        newRoutes.push({
            uid: getFakeUid(newRoutes.map((r)=>{return r.uid})),
            dayIndex: weekDayIndex,
            companyUserIdentifier: selectedUser.companyUserUid,
            workAreaIdentifier: selectedWorkArea.uid,
            vehicleIdentifier: selectedVehicle ? selectedVehicle.uid : null,
            timeScheduled: selectedTime
        });

        handleSetRoutes(newRoutes);
        setSelectedUser(undefined);
        setSelectedWorkArea(undefined);
        setSelectedVehicle(undefined);
        setAddRouteTab('workArea');
    }

    function handleRemoveRoute(uid){
        let newRoutes = routes.filter(r => r.uid != uid);
        handleSetRoutes(newRoutes);
    }

    function handleEditRoutes(uid, key, value){
        let newRoutes = routes.map((route)=>{
            if(route.uid == uid){
                return{
                    ...route,
                    [key]:value,
                };
            }
            return route;
        });
        handleSetRoutes(newRoutes);
    }

    function setSelectedWorkArea(value){
        if (selectedWorkArea === undefined) {
            setAddRouteTab('user');
        }
        _setSelectedWorkArea(value);
    }

    function setSelectedUser(value){
        if (selectedUser === undefined) {
            setAddRouteTab('vehicle');
        }
        _setSelectedUser(value);
    }

    function setSelectedVehicle(value){
        setAddRouteTab('time');
        _setSelectedVehicle(value);
    }

    const routesInCsaOnDay = routes.filter(r => workAreas.find(wa => wa.uid == r.workAreaIdentifier) && r.dayIndex == weekDayIndex);
    const availableWorkAreas = workAreas.filter(wa => !routesInCsaOnDay.find(r => r.workAreaIdentifier == wa.uid));
    const workAreaElements = availableWorkAreas.map((wa) => {
        return (
            <ListGroup.Item key={wa.uid} action active={selectedWorkArea && wa.uid == selectedWorkArea.uid} onClick={() => {setSelectedWorkArea(wa)}}>
                {wa.workAreaName}
            </ListGroup.Item>
        )
    });
   
    const usersInCsa = users.filter(u => u.csaIdentifier == csa.uid || !filterByCsa);
    const availableUsers = usersInCsa.filter(u => !routesInCsaOnDay.find(r => r.companyUserIdentifier == u.companyUserUid));
    const filteredUsers = availableUsers.filter(u => (u.firstName + ' ' + (u.showMiddleName ? u.middleName.substring(0, 1) + '. ' : '') + u.lastName).toLowerCase().includes(userSearch.toLowerCase()));
    
    const reducedUsers = filteredUsers.filter((u) => u.companyUserIsEnabled == "1").map((u) => {
        const status = u.onLoa == '1' ? 'Leave of Absence' : u.availability[weekDayIndex] ? "Available" : "Unavailable";
        return {
            companyUserUid: u.companyUserUid,
            userName:
            u.firstName +
            " " +
            (u.showMiddleName ? u.middleName.substring(0, 1) + ". " : "") +
            u.lastName,
            title: u.title,
            employeeType: u.employeeType,
            status: status,
        };
    })
    
    reducedUsers.sort((a, b) => {
        return a.status === b.status ? 0 : a.status === 'Available' ? -1 : 1;
    });
    
    const drivers = reducedUsers.filter(u => u.title === 'Driver');
    const avpDrivers = reducedUsers.filter(u => u.title === 'AVP Driver');
    const fulltimeDrivers = drivers.filter(u => u.employeeType == '0');
    const parttimeDrivers = drivers.filter(u => u.employeeType == '1');
    const otherUsers = reducedUsers.filter(u => u.title !== 'Driver' && u.title !== 'AVP Driver');

    const availableVehicles = vehicles.filter(v => !routesInCsaOnDay.find(r => r.vehicleIdentifier == v.uid));
    const filteredVehicles = availableVehicles.filter(v => !filterByCsa || v.csaIdentifier == csa.uid);
    const vehicleElements = naturalSort(filteredVehicles, 'vehicleName').map((v) => {
        return (
            <ListGroup.Item key={v.uid} action active={selectedVehicle && v.uid == selectedVehicle.uid} onClick={() => {setSelectedVehicle(v)}}>
                {v.vehicleName + ' (' + v.vehicleWeight + ' lbs)'}
            </ListGroup.Item>
        )
    });

    const catagories = [{users: fulltimeDrivers, title: 'Full-time Drivers'}, {users: parttimeDrivers, title: 'Part-time Drivers'}, {users: avpDrivers, title: 'AVP Drivers'},  {users: otherUsers, title: 'Other Users'}];
    const userCatagoryTabs = catagories.map((c) => {
        const listItems = c.users.map((u) => {
            return (
                <ListGroup.Item 
                    key={u.companyUserUid} 
                    action 
                    active={selectedUser && u.companyUserUid == selectedUser.companyUserUid} 
                    onClick={() => {setSelectedUser(u); if(u.title === 'AVP Driver'){setIsAvpDriver(true); setSelectedVehicle(null)}else{setIsAvpDriver(false)}}}
                >
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <p style={{margin: 0}}>{u.userName + (c.title === 'Other Users' ? ' (' + u.title + ')' : '')}</p>
                        <p style={{margin: 0, color: selectedUser && u.companyUserUid == selectedUser.companyUserUid ? 'white' : u.status === 'Available' ? 'green'  : 'gold'}}>{u.status}</p>
                    </div>
                </ListGroup.Item>
            )
        });

        return (
            <Tab key={c.title} eventKey={c.title} title={c.title} style={{padding: 8}}>
                <ListGroup style={{maxHeight: 400, overflowY: 'scroll'}}>
                    {listItems}
                </ListGroup>
            </Tab>
        )
    });


    const createdRoutes = routesInCsaOnDay.map((r) => {
        const wa = workAreas.find(wa => wa.uid == r.workAreaIdentifier);
        const user = users.find(u => u.companyUserUid == r.companyUserIdentifier);
        const vehicle = vehicles.find(v => v.uid == r.vehicleIdentifier);
        if (!wa || !user) {
            return null; 
        }
        
        return (
            <tr key={r.companyUserIdentifier}>
                <td>{wa.workAreaName}</td>
                <td>{user.firstName + ' ' + (user.showMiddleName ? user.middleName.substring(0, 1) + '. ' : '')+ user.lastName}</td>
                <td>{vehicle ? vehicle.vehicleName + ' (' + vehicle.vehicleWeight + ' lbs)' : 'None'}</td>
                <td><Form.Control type='time' placeholder={moment(moment().format('YYYY-MM-DD' + ' ' + r.timeScheduled)).format('h:mm A')} value={r.timeScheduled} onChange={(event)=>handleEditRoutes(r.uid,'timeScheduled',event.target.value)}/></td>
                <td>
                    {
                        (user.availability[weekDayIndex] && user.companyUserIsEnabled == 1) ? 
                        <FontAwesomeIcon icon={faCheck} style={{color: 'green'}}/>
                        :
                        <>
                            <OverlayTrigger overlay={
                                <Popover style={{position:'fixed'}}>
                                    <Popover.Header></Popover.Header>
                                    <Popover.Body>
                                        {!user.availability[weekDayIndex] && <>Driver does not have {daysOfTheWeek[weekDayIndex]} in his or her availability </>}
                                        {user.companyUserIsEnabled == 0 && <>Driver is terminated</>}
                                    </Popover.Body>
                                </Popover>
                            }>
                                <FontAwesomeIcon icon={faWarning} style={{color: 'red'}}/>
                            </OverlayTrigger>
                        </>
                    }
                </td>
                <td>
                    <Button variant={'outline-primary'} style={{padding: 2, display: 'flex'}} onClick={() => handleRemoveRoute(r.uid)}>
                        <FontAwesomeIcon icon={faX}/>
                    </Button>
                </td>
            </tr>
        )
    });

    const routeCreationCard = (
        <Card ref={routeCreatorRef}>
            <Card.Body>
                <Card.Title>Route Creator</Card.Title>
                <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 4}}>
                    <Form.Check type='switch' checked={filterByCsa} onChange={() => {setFilterByCsa(!filterByCsa)}} reverse label='Only Show Drivers and Vehicles in CSA'/>
                </div>
                <Tabs activeKey={AddRouteTab} onSelect={(k) => setAddRouteTab(k)}>
                    <Tab eventKey='workArea' title='Work Area' style={{padding: 8}}>
                        <ListGroup>{workAreaElements}</ListGroup>
                    </Tab>
                    <Tab eventKey='user' title='Driver' style={{padding: 8}}>
                        <InputGroup style={{marginBottom: 8}}>
                            <InputGroup.Text><FontAwesomeIcon icon={faSearch}/></InputGroup.Text>
                            <Form.Control type='text' value={userSearch} onChange={(event) => {setUserSearch(event.target.value)}}/>
                        </InputGroup>
                        <Tabs defaultActiveKey={catagories[0].title}>
                            {userCatagoryTabs}
                        </Tabs>
                    </Tab>
                    {!isAvpDriver &&
                        <Tab eventKey='vehicle' title='Vehicle' style={{padding: 8}}>
                            <ListGroup>
                                <ListGroup.Item action active={!selectedVehicle} onClick={()=>{setSelectedVehicle(null)}}>None</ListGroup.Item>
                                {vehicleElements}
                            </ListGroup>
                        </Tab>
                    }
                    <Tab eventKey='time' title='Time Scheduled' style={{padding: 8}}>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <QuickTimeSelector selectedTime={selectedTime} setSelectedTime={setSelectedTime}/>
                        </div>
                    </Tab>
                </Tabs>
                <div style={{display: 'flex', flexDirection: 'column', marginTop: 12}}>
                    <Button variant={'outline-primary'} onClick={handleAddRoute} disabled={!selectedWorkArea || !selectedUser}>Add Route</Button>
                </div>
            </Card.Body>
        </Card>
    )

    const createdRoutesCard = (
        <Card style={{height: '100%'}}>
            <Card.Body style={{display: 'flex', flexDirection: 'column'}}>
                <Card.Title>{'Routes for ' + daysOfTheWeek[weekDayIndex]}</Card.Title>
                <Table bordered striped responsive='true' size='sm'>
                    <thead>
                        <tr>
                            <th>WA</th>
                            <th>Driver</th>
                            <th>Vehicle</th>
                            <th>Time</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {createdRoutes}
                    </tbody>
                </Table>
                
                {availableWorkAreas.length === 0 ? '' :
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: 1}}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <FontAwesomeIcon size='2x' icon={faArrowUp} style={{marginBottom: 8}}/>
                            <Table bordered striped responsive='true' size='sm'>
                                <thead>
                                    <tr>
                                        <th>WA</th>
                                        <th>Driver</th>
                                        <th>Vehicle</th>
                                        <th>Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{selectedWorkArea ? selectedWorkArea.workAreaName : ''}</td>
                                        <td>{selectedUser ? selectedUser.userName : ''}</td>
                                        <td>{selectedVehicle ? selectedVehicle.vehicleName : 'None'}</td>
                                        <td>{moment(moment().format('YYYY-MM-DD' + ' ' + selectedTime)).format('h:mm A')}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                            <Button variant={'outline-primary'} onClick={() => {AutoComplete()}}>Auto-Complete remaining routes for this day</Button>
                            <Button variant={'outline-primary'} onClick={() => {handleCopyPasteRoutes(weekDayIndex)}}>{'Apply schedule for ' + daysOfTheWeek[weekDayIndex] + ' to remaining ' + (6 - weekDayIndex) + ' days'}</Button>
                        </div>
                    </div>
                }
            </Card.Body>
        </Card>
    )

    return (
        <Container fluid>
            <Row>
                <Col lg={6} style={{marginBottom: 8}}>
                    {routeCreationCard}
                </Col>
                <Col lg={6} style={{marginBottom: 8}}>
                    {createdRoutesCard}
                </Col>
            </Row>
        </Container>
    )

}

function TemplateCreatorCSA({csa, users, vehicles, workAreas, routes, handleSetRoutes, getAvailableId}) {
    const handleCopyPasteRoutes = (weekDayIndex) => {
        const autoCompletedRoutes = [];
        const routesInCSAOnWeekDayIndex = routes.filter(r => r.dayIndex === weekDayIndex && workAreas.find(wa => wa.uid == r.workAreaIdentifier));
        routesInCSAOnWeekDayIndex.forEach((r) => {
            for (let i = weekDayIndex + 1; i < 7; i++) {
                const driverIsAvailable = users.find(u => u.companyUserUid == r.companyUserIdentifier).availability[i];
                if (driverIsAvailable) {
                    autoCompletedRoutes.push({
                        uid: getFakeUid(routes.map((r)=>{return r.uid}).concat(autoCompletedRoutes.map((r)=>{return r.uid}))),
                        dayIndex: i,
                        companyUserIdentifier: r.companyUserIdentifier,
                        workAreaIdentifier: r.workAreaIdentifier,
                        vehicleIdentifier: r.vehicleIdentifier,
                        timeScheduled: r.timeScheduled
                    });
                }
            }
        });
        const routesAfterRemovingOverwrittenRoutes = routes.filter(r => !workAreas.find(wa => wa.uid == r.workAreaIdentifier) || r.dayIndex <= weekDayIndex);
        handleSetRoutes(routesAfterRemovingOverwrittenRoutes.concat(autoCompletedRoutes));
    }

    const weekDayButtons = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].map((dayName, index) => {
        const routesInCSAOnDay = routes.filter(r => r.dayIndex === index && workAreas.find(wa => wa.uid == r.workAreaIdentifier));
        const tabTitle = (
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8}}>
                <p style={{margin: 0}}>{dayName}</p>
                { routesInCSAOnDay.length > 0 ?
                    <FontAwesomeIcon 
                        icon={routesInCSAOnDay.length === workAreas.length ? faCheckCircle : faEllipsis} 
                        style={{
                            color: routesInCSAOnDay.length === workAreas.length ? 'green' : 'white', 
                            backgroundColor: routesInCSAOnDay.length < workAreas.length ? 'gold' : '',
                            borderRadius: 10,
                            aspectRatio: 1,
                        }}
                    />
                    : ''
                }
            </div>
        )

        return (
            <Tab key={dayName} eventKey={dayName} title={tabTitle} style={{paddingTop: 12}}>
                <TemplateCreatorWeekDay 
                    csa={csa} 
                    weekDayIndex={index} 
                    users={users} 
                    vehicles={vehicles} 
                    workAreas={workAreas} 
                    routes={routes} 
                    handleSetRoutes={handleSetRoutes} 
                    getAvailableId={getAvailableId} 
                    handleCopyPasteRoutes={handleCopyPasteRoutes}
                />
            </Tab>
        )
    });

    return (
        <div>
            <Tabs fill>
                {weekDayButtons}
            </Tabs>
        </div>
    )
}

function TemplateCreatorFinalize({templateName, setTemplateName, templateDescription, setTemplateDescription, handleCreateTemplate, isEditing}) {
    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: 12, width: 600, alignSelf: 'center'}}>
            <Form.Group>
                <Form.Label>Template Name</Form.Label>
                <Form.Control type='text' maxLength={30} placeholder='Enter a name for your template' value={templateName} onChange={(event) => setTemplateName(event.target.value)}/>
            </Form.Group>
            <Form.Group>
                <Form.Label>Template Description</Form.Label>
                <Form.Control type='text' maxLength={50} placeholder='Enter a description for your template' value={templateDescription} onChange={(event) => setTemplateDescription(event.target.value)}/>
            </Form.Group>
            {isEditing ? '' :
                <Button variant={'outline-primary'} disabled={!templateName} onClick={handleCreateTemplate}>{'Save Template'}</Button>
            }
        </div>
    )
}


function TemplateCreatorReview({csas, routes, workAreas, users, vehicles, limitViewToCSA}) {
    const [selectedCsa, setSelectedCsa] = useState(csas.length ? csas[0] : '');
    const csaButtons = (limitViewToCSA ? [csas.find(csa => csa.uid == new Cookies().get('userData').csaIdentifier)] : csas).map((csa) => {
        return (
            <Button key={csa.csaName} type='button' style={csa.uid == selectedCsa.uid ? {color: 'white'} : {color: 'var(--bs-primary)', backgroundColor: 'white'}} onClick={() => setSelectedCsa(csa)}>
                {csa.csaName}
            </Button>
        )
    });


    let dayOfWeekSections = [];

    ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].forEach((dayOfWeek, dayIndex) => {

        dayOfWeekSections.push(
            <tr key={dayOfWeek} style={{backgroundColor: '#fff7ba'}}>
                <th colSpan={4} style={{textAlign: 'center'}}>{dayOfWeek}</th>
            </tr>
        );

        const routesInCSAOnDay = routes.filter(r => r.dayIndex === dayIndex && workAreas.filter(wa => wa.csaIdentifier === selectedCsa.uid).find(wa => wa.uid == r.workAreaIdentifier));
        routesInCSAOnDay.forEach((r) => {
            const workArea = workAreas.find(wa => wa.uid == r.workAreaIdentifier);
            const driver = users.find(u => u.companyUserUid == r.companyUserIdentifier);
            const vehicle = vehicles.find(v => v.uid == r.vehicleIdentifier);

            dayOfWeekSections.push(
                <tr key={r.uid}>
                    <td>{workArea.workAreaName}</td>
                    <td>{driver.firstName + ' ' + (driver.showMiddleName ? driver.middleName.substring(0, 1) + '. ' : '') + driver.lastName}{driver.title === 'AVP Driver' ? ' (AVP)' : ''}</td>
                    <td>{vehicle ? vehicle.vehicleName : 'None'}</td>
                    <td>{r.timeScheduled}</td>
                </tr>
            )
        });
    });

    
    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
            <ButtonGroup>
                {csaButtons}
            </ButtonGroup>

            <div style={{maxHeight: 600, overflowY: 'scroll'}}>
                <Table bordered striped responsive size='sm'>
                    <thead>
                        <tr>
                            <th>Work Area</th>
                            <th>Driver</th>
                            <th>Vehicle</th>
                            <th>Time Scheduled</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dayOfWeekSections}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

function getFakeUid(oldIds){
    var newId = -1;
    while (oldIds.includes(newId)) {
        newId--;
    }
    return newId;
}