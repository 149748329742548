/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './MFA.css';
import { Form, Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import CustomButton from '../../components/CustomButton.js';
import { ApiRequest } from '../../ApiManager.tsx';

function MFA({tokenIdentifier, handleCheckAuthentication}) {
    const [mfa, setMfa] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    function handleSubmit(event){
        event?.preventDefault();
        new ApiRequest('authentication', 'mfa', setIsLoading, (response) => {
            new Cookies().set('token', response.token, {path: '/'});
            handleCheckAuthentication();   
        }).withNoAlertOnSuccess().withData({tokenIdentifier, code: mfa}).send();
    } 

    function handleChange(value){
        while (value.length > 6 || (value.length > 0 && !'1234567890'.includes(value[value.length - 1]))) {
            value = value.slice(0, value.length - 1);
        }
        setMfa(value)
    }

    return(
        <>
            <Modal.Header>
                <Modal.Title>Multi-Factor Authentication</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={{alignSelf: 'center'}}>Please enter the code that has been sent to your mobile device.</p>
                <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                    <div style={{display: 'flex', flexDirection: 'row', maxWidth: 800, gap: 4}}>
                        <Form.Control autoFocus style={{fontSize: '15vmin', textAlign: 'center'}} type='number' maxLength={6} value={mfa} onChange={(event) => {handleChange(event.target.value)}}/>
                    </div>
                    <CustomButton disabled={mfa.length < 6} label='Submit' isLoading={isLoading} onClick={handleSubmit}/>
                </form>
            </Modal.Body>
        </>
    )
}

export default MFA;