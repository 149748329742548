/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import TimesheetCalendar from "./TimesheetCalendar";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import TimeClockTicker from "./TimeClockTicker";
import TimeClockQuestionnaire from "./TimeClockQuestionnaire";
import TimeClockRouteModal from "./TimeClockRouteModal";
import Cookies from "universal-cookie";
import { ApiRequest } from "../../ApiManager.tsx";
import LoadingWrapper from "../../components/LoadingWrapper";

export default function TimeClock({}){
    const punchCooldown = 5; // in seconds
    const [lastTimesheet, setLastTimesheet] = useState(undefined);
    const [entries, setEntries] = useState([],);
    const [workAreas, setWorkAreas] = useState([],);
    const [vehicles, setVehicles] = useState([],);
    const [csas, setCsas] = useState([],);
    const [isLoading, setIsLoading] = useState(true);
    const [isPunchLoading, setIsPunchLoading] = useState(false);
    const [buttonPressTimeStamp, setButtonPressTimeStamp] = useState(moment().subtract(punchCooldown - 1, 'seconds'));
    const [modalSwitch, setModalSwitch] = useState('none');
    const [route, setRoute] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const userData = new Cookies().get('userData');

    useEffect(() => {
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('timeclock', 'getTimeclockData', setIsLoading, (response) => {
            setRoute(response.route);
            setLastTimesheet(response.lastTimesheet);
            setEntries(response.timesheets);
            setVehicles(response.vehicles);
            setWorkAreas(response.workAreas);
            setCsas(response.csas);
        }).withData({
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            endDate: moment().endOf('month').format('YYYY-MM-DD')
        }).withNoAlertOnSuccess().send();
    }

    function handleMonthChange(monthStart){
        let monthEnd = moment(monthStart).endOf('month');
        new ApiRequest('timeclock', 'getTimeclockData', setIsLoading, (response) => {
            setEntries(response.timesheets);
            setSelectedMonth(moment(monthStart).format('YYYY-MM-DD'));
        }).withData({
            startDate: moment(monthStart).format('YYYY-MM-DD'),
            endDate: moment(monthEnd).format('YYYY-MM-DD')
        }).withNoAlertOnSuccess().send()
    }

    async function handlePunch(hadIncident, hadAccident, hadInjury, incidentStatement, accidentStatement, injuryStatement, workAreaIdentifier, vehicleIdentifier){
         if (!isPunchLoading) {
            if (moment().diff(buttonPressTimeStamp, 'seconds') < punchCooldown) {
                setModalSwitch('cooldown')
                return;
            }
            setButtonPressTimeStamp(moment());

            const coordinates = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition((position) => {
                    resolve({lat: position.coords.latitude, long: position.coords.longitude});
                }, (error) => {
                    resolve({lat: undefined, long: undefined});
                }, {timeout: 10000});
            })

            const punchToSend = {
                ...coordinates,
                hadIncident,
                hadAccident,
                hadInjury,
                incidentStatement,
                accidentStatement,
                injuryStatement,
                workAreaIdentifier,
                vehicleIdentifier
            }

            new ApiRequest('timeclock', 'punch', setIsPunchLoading, (response) => {
                let newEntries = Array.from(entries);
                if (response.uid) {
                    const newPunch = {
                        uid: response.uid,
                        inTime: moment().format('YYYY-MM-DD HH:mm'),
                        outTime: undefined,
                        inLat: coordinates.lat,
                        inLong: coordinates.long,
                        outLat: undefined,
                        outLong: undefined,
                    }
                    newEntries.unshift(newPunch);
                    setLastTimesheet(newPunch);
                } else {
                    newEntries = newEntries.filter(e => e.uid != lastTimesheet.uid);
                    const newLastTimesheetEntry = structuredClone(lastTimesheet);
                    newLastTimesheetEntry.outTime = moment().format('YYYY-MM-DD HH:mm');
                    newLastTimesheetEntry.outLat = coordinates.lat;
                    newLastTimesheetEntry.outLong = coordinates.long;
                    newEntries.unshift(newLastTimesheetEntry);
                    setLastTimesheet(newLastTimesheetEntry);
                }
                setEntries(newEntries);
            }).withData({
                ...punchToSend,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }).send();
        }
    }

    function handleButtonPress() {
        if (!isPunchLoading) {
            if (moment().diff(buttonPressTimeStamp, 'seconds') < punchCooldown) {
                setModalSwitch('coolDown');
            } else if (lastTimesheet && !lastTimesheet.outTime) {
                setModalSwitch('driverClockOut')
            } else if (!route || (!route.vehicleIdentifier && userData.title !== 'AVP Driver')){
                setModalSwitch('routeModal');
            } else {
                handlePunch();
            }
        }
    }

    function handleQuestionnaireSubmission(hadIncident, hadAccident, hadInjury, incidentStatement, accidentStatement, injuryStatement) {
        hideModal();
        handlePunch(hadIncident, hadAccident, hadInjury, incidentStatement, accidentStatement, injuryStatement);
    }

    function handleRouteSubmission(workAreaIdentifier, vehicleIdentifier) {
        hideModal();
        handlePunch( undefined, undefined, undefined, undefined, undefined, undefined, workAreaIdentifier, vehicleIdentifier);
    }

    function hideModal() {
        setModalSwitch('none')
    }

    const isClockedIn = lastTimesheet && !lastTimesheet.outTime; 
    const PunchIOCard = (
        <div style={{display: 'flex', flexDirection:'column', alignContent:'center', alignItems:'center', width:'20%'}}>
            <p style={{fontWeight:'bold', fontSize:30, flexWrap:'wrap'}}>{'You are currently clocked ' + (isClockedIn ? 'in' : 'out') + '.'}</p>
            <Button
                onClick={handleButtonPress}
                style={{
                    width: 250,
                    height:250,
                    borderRadius: '50%',
                    backgroundColor: isClockedIn ? '#ff8a8a' : '#a7ff8a',
                    border: 'none',
                    fontSize: 24,
                    color: isClockedIn ? 'white' : 'black',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    marginTop:10,
                    marginLeft:10,
                    boxShadow: '16px 24px 32px 8px rgba(255, 255, 255, 0.5) inset', 
            }}>
                {isPunchLoading ? (
                    <Spinner />
                ) : (
                    <>
                        <span style={{ margin: 0, textAlign: 'center' }}>
                            {isClockedIn ? 'Clock Out' : 'Clock In'}
                            <br />
                        <FontAwesomeIcon
                            icon={isClockedIn ? faStop : faPlay}
                            style={{
                                fontSize: 80,
                                marginBottom: 8,
                            }}
                        />
                            <TimeClockTicker />
                        </span>
                    </>
                )}
            </Button> 
            <div style={{padding:10, textAlign:'center'}}>
                <p style={{fontWeight:'bold', fontSize:24}}>Last Punch</p>
                { lastTimesheet ?                        
                    <span style={{fontSize: 20}}>{(isClockedIn ? 'In on ' : 'Out on ') + moment(isClockedIn ? lastTimesheet.inTime : lastTimesheet.outTime).format('MMM D, YYYY [at] h:mm A')}</span>
                : ''}
            </div>
        </div>
    );

    const timesheetCard = (
        <div style={{display: 'flex', flex:1}}>
            <div style={{flex:1}}>
                <TimesheetCalendar timesheetData={entries} handleMonthChange={handleMonthChange} selectedMonth={selectedMonth}/>
            </div>
        </div> 
    );

    const content = (
        <div style={{width: '100%', backgroundColor: 'white', display: 'flex', padding: 12, gap:20}}>
            {PunchIOCard}
            {timesheetCard}
        </div>
    );

    return (
        <div className="page-wrapper">
            <LoadingWrapper isLoading={isLoading}>
                {content}
            </LoadingWrapper>
            <Modal show={modalSwitch === 'cooldown'} onHide={hideModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Punch Cooldown</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {'Please wait ' + (punchCooldown - moment().diff(buttonPressTimeStamp, 'seconds')) + ' seconds before punching in/out.'}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={'outline-primary'} onClick={hideModal}>Dismiss</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalSwitch === 'driverClockOut'} onHide={hideModal} centered>
                <TimeClockQuestionnaire handleQuestionnaireSubmission={handleQuestionnaireSubmission}/>
            </Modal>
            <Modal show={modalSwitch === 'routeModal'} onHide={hideModal} size='lg' centered>
                <TimeClockRouteModal handleRouteSubmission={handleRouteSubmission} vehicles={vehicles} route={route} workAreas={workAreas} csas={csas}/>
            </Modal>
        </div>
    )
}