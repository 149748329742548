/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

export default function PayrollPeriodColumnInclusion(entries) {

    const entryColumnInclusion = entries.map(e => e.getColumnInclusion());

    const includeOvertimeColumns = entryColumnInclusion.find(eci => eci.includeOvertimeColumns) !== undefined;
    const includeSalary = entryColumnInclusion.find(eci => eci.includeSalary) !== undefined;
    const includeDaily = entryColumnInclusion.find(eci => eci.includeDaily) !== undefined;

    const columnInclusion = {
        includeSalary: includeSalary,
        includeDaily: includeDaily,
        includeOvertimeColumns: includeOvertimeColumns,
        includeHourly: includeOvertimeColumns || entryColumnInclusion.find(eci => eci.includeHourly) !== undefined,
        includeStop: entryColumnInclusion.find(eci => eci.includeStop) !== undefined,
        includeOTStopWages: entryColumnInclusion.find(eci => eci.includeOTStopWages) !== undefined,
        includeMile: entryColumnInclusion.find(eci => eci.includeMile) !== undefined,
        includeExtraDay: entryColumnInclusion.find(eci => eci.includeExtraDay) !== undefined,
        includeIncentiveWages: entryColumnInclusion.find(eci => eci.includeIncentiveWages) !== undefined,
        includeStandByWages: entryColumnInclusion.find(eci => eci.includeStandByWages) !== undefined,
        includeAutoOvertime: entryColumnInclusion.find(eci => eci.includeAutoOvertime) !== undefined,
        includePto: entryColumnInclusion.find(eci => eci.includePto) !== undefined,
        includeRegularWages: includeSalary || includeDaily,
        includeHolidayWages: entryColumnInclusion.find(eci => eci.includeHolidayWages) !== undefined,
        inlcudeChildSupport: entryColumnInclusion.find(eci => eci.inlcudeChildSupport) !== undefined,
        includeMedical: entryColumnInclusion.find(eci => eci.includeMedical) !== undefined,
        includeDental: entryColumnInclusion.find(eci => eci.includeDental) !== undefined,
        includeVision: entryColumnInclusion.find(eci => eci.includeVision) !== undefined,
        includeHolidayBonus: entryColumnInclusion.find(ci => ci.includeHolidayBonus) !== undefined,
        includeOtherDiscretionaryBonus: entryColumnInclusion.find(ci => ci.includeOtherDiscretionaryBonus) !== undefined,
        includeLoans: entryColumnInclusion.find(ci => ci.includeLoans) !== undefined,
        includeTickets: entryColumnInclusion.find(ci => ci.includeTickets) !== undefined,
    }

    return columnInclusion;
}