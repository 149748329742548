/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserInjured } from '@fortawesome/free-solid-svg-icons';
import Card from 'react-bootstrap/Card';
import HRUserBWCDetails from './HRUserBWCDetails';
import HRUserBWCForm from './HRUserBWCForm';
import { ApiRequest } from '../../../ApiManager.tsx';
import LoadingWrapper from '../../../components/LoadingWrapper';

export default function HRUserBWC({selectedEmployee, windowWidth, userIsDisabled, selectedBwcRecord, setSelectedBwcRecord}) {
    const [isLoading, setIsLoading] = useState(false);
    const [BWCs, setBWCs] = useState([]);
    const [modalSwitch, setModalSwitch] = useState('');
    const [selectedBWC, setSelectedBWC] = useState(undefined);
    const [users, setUsers] = useState([]);
    const [userInfo, setUserInfo] = useState([]);

    useEffect(() => {
        loadData();
    }, [selectedEmployee])

    function loadData() {
        new ApiRequest('hr', 'getAllBwcRecords', setIsLoading, (response) => {
            setBWCs(response.data.sort(sortBWCs));
            setUsers(response.users);
            setUserInfo(response.userInfo);
            if (selectedBwcRecord) {
                setSelectedBWC(selectedBwcRecord);
                setModalSwitch('details');
                setSelectedBwcRecord(undefined);
            }
        }).withData({companyUserUid: selectedEmployee.companyUserUid}).withNoAlertOnSuccess().send()
    }

    function sortBWCs(a, b) { 
        if (moment(a.latestVersion.injuryReportDate).isBefore(moment(b.latestVersion.injuryReportDate), 'hours')) {
            return 1;
        } else if (moment(a.latestVersion.injuryReportDate).isAfter(moment(b.latestVersion.injuryReportDate), 'hours')) {
            return -1;
        }
        return 0;
    }

    function handleAddRecord(record) {
        const newArray = Array.from(BWCs);
        newArray.push(record);
        newArray.sort(sortBWCs);
        setBWCs(newArray);
        hideModal();
    }

    function handleReviseRecord(record) {
        const newArray = Array.from(BWCs);
        const bwc = newArray.find(b => b.uid == record.bwcRecordsIdentifier);
        bwc.latestVersion = record;
        newArray.sort(sortBWCs);
        setBWCs(newArray);
        setSelectedBWC(bwc);
        setModalSwitch('details')
    }

    function hideModal(){
        setModalSwitch('')
    }

    const status = BWCs.length > 0 ? (BWCs[0].rtwFullDutyDate ? 'full' : BWCs[0].rtwLightDutyDate ? 'light' : 'none') : undefined;

    const bwcElements = BWCs.map((bwcRecord) => {
        const item = bwcRecord.latestVersion;
        const info = {};
        info['Date of Injury'] = moment(item.dateOfInjury).format('MMM D, YYYY');
        info['Date Reported'] = moment(item.injuryReportDate).format('MMM D, YYYY');

        if (windowWidth >= 992) {
            info['RTW (Light Duty)'] = item.rtwLightDutyDate ? moment(item.rtwLightDutyDate).format('MMM D, YYYY') : 'N/A';
            info['RTW (Full Duty)'] = item.rtwFullDutyDate ? moment(item.rtwFullDutyDate).format('MMM D, YYYY') : 'Unknown';
            info['Last Updated'] = moment(item.submissionDateTime).format('MMM D, YYYY');
        }
            
        const infoElements = Object.keys(info).map((key) => {
            const value = info[key];
            return (
                <div key={key} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', flex: 1, borderLeft: '1px solid var(--bs-primary)', fontSize: 12}}>
                    <p style={{marginBottom: 0, fontWeight: 'bold'}}>{key}</p>
                    <p style={{marginBottom: 0}}>{value}</p>
                </div>
            )
        });

        return (
            <Card key={bwcRecord.uid} className='cursor-pointer'  onClick={() => {setSelectedBWC(bwcRecord); setModalSwitch('details')}} style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{display: 'flex', height: '100%', padding: 18, gap: 12, borderTopLeftRadius: 6, borderBottomLeftRadius: 6, backgroundColor: 'var(--bs-primary)', alignItems: 'center', flexShrink: 0}}>
                    <FontAwesomeIcon icon={faUserInjured} style={{color: 'white'}}/>
                </div>
                <div style={{borderTopRightRadius: 6, borderBottomRightRadius: 6, border: '2px solid var(--bs-primary)', borderLeft: 'none', flex: 1, minWidth: 0, alignItems: 'center', display: 'flex'}}>
                    {infoElements}
                </div>
            </Card>     
        )
    });

    const content = (
        <div style={{display: 'flex', flexDirection: 'column', gap: 12, height: '100%'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                { status && status !== 'full' ?
                    <Alert style={{marginBottom: 0, flex: 1}} variant='warning'>{status === 'light' ? 'According to the most recent BWC report, employee is currently performing light duty.' : 'According to the most recent BWC claim, employee has not yet returned to work.'}</Alert>
                    : ''
                }
                <div/>
                { userIsDisabled ? '' :
                    <Button variant={'outline-primary'} style={{marginLeft: 12}} onClick={() => {setModalSwitch('form'); setSelectedBWC(undefined)}}>Create New BWC Report</Button>
                }
            </div>
            <div style={{display: 'flex', flexDirection: 'column', gap: 12, backgroundColor: 'rgb(240, 240, 240)', padding: 8, borderRadius: 6, flex: 1, overflowY: 'auto'}}>
                {bwcElements}
            </div>
        </div>
    )

    return (
        <>
            <LoadingWrapper isLoading={isLoading} onlyRenderIfTruthy={selectedEmployee ?? false}>
                {content}
            </LoadingWrapper>
            <Modal show={modalSwitch === 'details'} onHide={hideModal} size='lg' centered>
                <HRUserBWCDetails users={users} bwcRecord={selectedBWC} reviseRecord={() => setModalSwitch('form')} userIsDisabled={userIsDisabled}/>
            </Modal>
            <Modal show={modalSwitch === 'form'} onHide={hideModal} size='lg' centered>
                <HRUserBWCForm user={userInfo} users={users} selectedBWC={selectedBWC?.latestVersion} handleAddRecord={handleAddRecord} handleReviseRecord={handleReviseRecord}/>
            </Modal>
        </>
    )
}