/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import { validateDecimal, validateInteger } from "../../../../tools";

export default class SettlementReportDriver{
    chargeStatementIdentifier;
    dayIndex;
    fedexId;
    puPackages;
    puStops;
    dlPackages;
    dlStops;
    ecPackages;
    ecStops;

    constructor(
        chargeStatementIdentifier,
        dayIndex,
        fedexId,
        puPackages,
        puStops,
        dlPackages,
        dlStops,
        ecPackages,
        ecStops,
    ){
        this.chargeStatementIdentifier = chargeStatementIdentifier;
        this.dayIndex = dayIndex;
        this.fedexId = fedexId;
        this.puPackages = puPackages;
        this.puStops = puStops;
        this.dlPackages = dlPackages;
        this.dlStops = dlStops;
        this.ecPackages = ecPackages;
        this.ecStops = ecStops;
    }

    static initDefault(){
        return new SettlementReportDriver(
            -1,
            moment().format('YYYY-MM-DD'),
            '',
            '',
            '',
            '',
            '',
            '',
            '',
        '',
        )
    }

    static initFromCsvRow(row, startDate){
        const dayIndex = moment(row[0]).diff(moment(startDate), 'days');
        const newObject = new SettlementReportDriver(
            -1,
            dayIndex,
            row[1],
            row[4],
            row[5],
            row[6],
            row[7],
            row[8],
            row[9],
        )

        newObject.name = row[2];
        return newObject;
    }

    encode(){
        return {
            chargeStatementIdentifier: this.chargeStatementIdentifier,
            dayIndex: this.dayIndex,
            fedexId: this.fedexId,
            puPackages: this.puPackages,
            puStops: this.puStops,
            dlPackages: this.dlPackages,
            dlStops: this.dlStops,
            ecPackages: this.ecPackages,
            ecStops: this.ecStops,
        }
    }
}