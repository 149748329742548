/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import React from "react"
import Decoder from "../../../decoding";
import { validateDecimal, validateInteger } from "../../../tools";
import PCATProposal from "./PCATProposal";
import { toFixedMax, validateBig } from "../../Payroll/payrollTools";

export default class PCAT {
    uid;
    title;
    dateCreated;
    acceptedProposal;
    basePumpPrice;
    threeOfferDeadline;
    negotiationsDeadline;
    nonECommStops;
    nonECommPackages;
    eCommStops;
    eCommPackages;
    dailyStopThreshold;
    totalDeliveryPackages;
    largePackageMixDeliveryPercentage;
    deliveryPercentage;
    proposals;

    constructor(uid, title, dateCreated, acceptedProposal, basePumpPrice, threeOfferDeadline, negotiationsDeadline, nonECommStops, nonECommPackages, eCommStops, eCommPackages, dailyStopThreshold, totalDeliveryPackages, largePackageMixDeliveryPercentage, deliveryPercentage, proposals) {
        this.uid = uid;
        this.title = title;
        this.dateCreated = dateCreated;
        this.acceptedProposal = acceptedProposal;
        this.basePumpPrice = basePumpPrice;
        this.threeOfferDeadline = threeOfferDeadline;
        this.negotiationsDeadline = negotiationsDeadline;
        this.nonECommStops = nonECommStops;
        this.nonECommPackages = nonECommPackages;
        this.eCommStops = eCommStops;
        this.eCommPackages = eCommPackages;
        this.dailyStopThreshold = dailyStopThreshold;
        this.totalDeliveryPackages = totalDeliveryPackages;
        this.largePackageMixDeliveryPercentage = largePackageMixDeliveryPercentage;
        this.deliveryPercentage = deliveryPercentage;
        this.proposals = proposals;
    }

    static initDefault() {
        return new PCAT(-1, 'Untitled PCAT', '', undefined, '', '', '', '', '', '', '', '', '', '', '', []);
    }


    static decode(json) {
        const decoder = new Decoder(json);
        const uid = decoder.decode('uid', Decoder.Uid);
        const title = decoder.decode('title', Decoder.String);
        const dateCreated = decoder.decode('dateCreated', Decoder.String);
        const acceptedProposal = decoder.decode('acceptedProposal', Decoder.Uid, {defaultValue: undefined, warn: false});
        const basePumpPrice = decoder.decode('basePumpPrice', Decoder.Decimal);
        const threeOfferDeadline = decoder.decode('threeOfferDeadline', Decoder.String, {defaultValue: '', warn: false});
        const negotiationsDeadline = decoder.decode('negotiationsDeadline', Decoder.String, {defaultValue: '', warn: false});
        const nonECommStops = decoder.decode('nonECommStops', Decoder.Integer);
        const nonECommPackages = decoder.decode('nonECommPackages', Decoder.Integer);
        const eCommStops = decoder.decode('eCommStops', Decoder.Integer);
        const eCommPackages = decoder.decode('eCommPackages', Decoder.Integer);
        const dailyStopThreshold = decoder.decode('dailyStopThreshold', Decoder.Integer);
        const totalDeliveryPackages = decoder.decode('totalDeliveryPackages', Decoder.Integer);
        const largePackageMixDeliveryPercentage = decoder.decode('largePackageMixDeliveryPercentage', Decoder.Decimal);
        const deliveryPercentage = decoder.decode('deliveryPercentage', Decoder.Decimal);
        const proposals = decoder.decode('proposals', Decoder.Array).map(p => PCATProposal.decode(p));

        if (decoder.checkForErrors()) {
            return new PCAT(uid, title, dateCreated, acceptedProposal, basePumpPrice, threeOfferDeadline, negotiationsDeadline, nonECommStops, nonECommPackages, eCommStops, eCommPackages, dailyStopThreshold, totalDeliveryPackages, largePackageMixDeliveryPercentage, deliveryPercentage, proposals);
        } else {
            return PCAT.initDefault();
        }
    }

    encode() {
        return {
            uid: this.uid,
            title: this.title,
            acceptedProposal: this.acceptedProposal,
            basePumpPrice: validateDecimal(this.basePumpPrice),
            threeOfferDeadline: this.threeOfferDeadline ? this.threeOfferDeadline : null,
            negotiationsDeadline: this.negotiationsDeadline ? this.negotiationsDeadline : null,
            nonECommStops: validateInteger(this.nonECommStops),
            nonECommPackages: validateInteger(this.nonECommPackages),
            eCommStops: validateInteger(this.eCommStops),
            eCommPackages: validateInteger(this.eCommPackages),
            dailyStopThreshold: validateInteger(this.dailyStopThreshold),
            totalDeliveryPackages: validateInteger(this.totalDeliveryPackages),
            largePackageMixDeliveryPercentage: validateDecimal(this.largePackageMixDeliveryPercentage),
            deliveryPercentage: validateDecimal(this.deliveryPercentage),
            proposals: this.proposals.map((p, index) => {return p.encode(index)})
        }
    }

    duplicate() {
        return new PCAT(
            this.uid,
            this.title,
            this.dateCreated,
            this.acceptedProposal,
            this.basePumpPrice,
            this.threeOfferDeadline,
            this.negotiationsDeadline,
            this.nonECommStops,
            this.nonECommPackages,
            this.eCommStops,
            this.eCommPackages,
            this.dailyStopThreshold,
            this.totalDeliveryPackages,
            this.largePackageMixDeliveryPercentage,
            this.deliveryPercentage,
            this.proposals.map(p => p.duplicate())
        )
    }

    equals(pcat) {
        if (this.proposals.length !== pcat.proposals.length) {
            return false;
        } else {
            for (let i = 0 ; i < this.proposals.length; i++) {
                if (!this.proposals[i].equals(pcat.proposals[i])) {
                    return false;
                }
            }
    
            return this.uid === pcat.uid &&
                this.title === pcat.title &&
                this.dateCreated === pcat.dateCreated &&
                this.acceptedProposal === pcat.acceptedProposal &&
                this.basePumpPrice === pcat.basePumpPrice &&
                this.threeOfferDeadline === pcat.threeOfferDeadline &&
                this.negotiationsDeadline === pcat.negotiationsDeadline &&
                this.nonECommStops === pcat.nonECommStops &&
                this.nonECommPackages === pcat.nonECommPackages &&
                this.eCommStops === pcat.eCommStops &&
                this.eCommPackages === pcat.eCommPackages &&
                this.dailyStopThreshold === pcat.dailyStopThreshold &&
                this.totalDeliveryPackages === pcat.totalDeliveryPackages &&
                this.largePackageMixDeliveryPercentage === pcat.largePackageMixDeliveryPercentage && 
                this.deliveryPercentage === pcat.deliveryPercentage
            ;
        }
    }


    nonEComPkgsPerStop() {
        return this.nonECommStops == 0 ? 'N/A' : toFixedMax(validateBig(this.nonECommPackages).div(validateBig(this.nonECommStops)).toNumber(), 3);
    }

    eComPkgsPerStop() {
        return this.eCommStops == 0 ? 'N/A' : toFixedMax(validateBig(this.eCommPackages).div(validateBig(this.eCommStops)).toNumber(), 3);
    }

}