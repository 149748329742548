/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import React, { useState } from "react"
import { Button, ButtonGroup, Dropdown, Modal } from "react-bootstrap"
import BudgetExpenses from "../Budget/BudgetExpenses";
import BudgetProductivity from "../Budget/BudgetProductivity";
import BudgetDerivatives from "../Budget/BudgetDerivatives";
import Budget from "../Budget/Models/Budget";
import BudgetLoadModal from "../Budget/BudgetLoadModal";
import BudgetAssumptions from "../Budget/Models/BudgetAssumptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import AlertModal from "../../components/AlertModals/AlertModal";

export default function PCATProposalBudget({pcat, proposalIndex, handleSetProposal}) {
    const [budget, setBudget] = useState(pcat.proposals[proposalIndex].budget?.duplicate());
    const [modalSwitch, setModalSwitch] = useState('');
    const [selectedTab, setSelectedTab] = useState(0);

    const handleSetBudget = (key, value) => {
        const newBudget = budget.duplicate();
        newBudget[key] = value;
        setBudget(newBudget);
    }

    const handleSave = () => {
        handleSetProposal(proposalIndex, 'budget', budget.duplicate());
    }

    const handleLoadBudget = (budget) => {
        setBudget(Budget.decode(budget, `Budget for PCAT \"${pcat.title}\" | Proposal #${proposalIndex + 1} (${pcat.proposals[proposalIndex].isFedex ? 'FedEx' : 'IC'})`));
        handleSetProposal(proposalIndex, 'budget', Budget.decode(budget, `Budget for PCAT \"${pcat.title}\" | Proposal #${proposalIndex + 1} (${pcat.proposals[proposalIndex].isFedex ? 'FedEx' : 'IC'})`));
        setModalSwitch('');
    }

    const handleCreateBudget = async () => {
        setBudget(Budget.initFromPcat(pcat, proposalIndex));
        handleSetProposal(proposalIndex, 'budget', Budget.initFromPcat(pcat, proposalIndex));
        setModalSwitch('')
        return true;
    }

    const handleRemoveBudget = async () => {
        setBudget(undefined);
        handleSetProposal(proposalIndex, 'budget', undefined);
        setModalSwitch('')
        return true;
    }

    const handleApplyPcatToProduction = () => {
        setBudget(budget.applyPcatToProduction(pcat, proposalIndex));
    }

    const budgetWithAssumptions = budget?.duplicate();
    const newAssumptions = BudgetAssumptions.initFromPcat(pcat, proposalIndex);
    if (budgetWithAssumptions) {
        budgetWithAssumptions.budgetAssumptions = newAssumptions;
    }

    const tabButtons = ['Expenses', 'Productivity', 'Results'].map((title, index) => {
        const isActive = index === selectedTab;
        return (
            <Button key={title} onClick={() => {setSelectedTab(index)}} style={isActive ? {color: 'white'} : {backgroundColor: 'white', color: 'var(--bs-primary)'}}>{title}</Button>
        )
    })

    const unsavedChanges = budget && (!pcat.proposals[proposalIndex].budget || !budget.equals(pcat.proposals[proposalIndex].budget));

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>PCAT Proposal Budget</Modal.Title>
                { budget && 
                    <div style={{display: 'flex', justifyContent: 'space-between', flex: 1, padding: '0px 12px 0px 12px'}}>
                        
                        <Button variant="outline-primary" disabled={!unsavedChanges} onClick={handleSave}>{unsavedChanges ? 'Apply Budget Changes' : 'Saved'}</Button>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-primary"><FontAwesomeIcon icon={faBars}/></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => {setModalSwitch('load')}}>Import New Budget</Dropdown.Item>
                                <Dropdown.Item onClick={() => {setModalSwitch('create')}}>Create New Budget</Dropdown.Item>
                                <Dropdown.Divider/>
                                <Dropdown.Item onClick={() => {setModalSwitch('delete')}}>Remove Budget</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                }
            </Modal.Header>
            <Modal.Body>

                { budget ? 
                    <>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 12}}>
                            <ButtonGroup style={{maxWidth: 800, width: '100%'}}>
                                {tabButtons}
                            </ButtonGroup>
                        </div>
                        { selectedTab === 0 ?
                            <BudgetExpenses budgetExpenses={budget.budgetExpenses} originalBudgetExpenses={pcat.proposals[proposalIndex].budget?.budgetExpenses} handleSetBudget={handleSetBudget}/>
                            : selectedTab === 1 ?
                                <div>
                                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: 12}}>
                                        <Button variant="outline-primary" onClick={handleApplyPcatToProduction}>Import Stops & Packages From PCAT Propsal</Button>
                                    </div>
                                    <BudgetProductivity budget={budget} originalBudget={pcat.proposals[proposalIndex].budget} handleSetBudget={handleSetBudget}/>
                                </div>
                            : 
                            <BudgetDerivatives budget={budgetWithAssumptions}/>
                        }
                    </>
                    :
                    <div style={{display: 'flex', justifyContent: 'center', gap: 12, marginTop: 100, marginBottom: 100}}>
                        <Button variant="outline-primary" onClick={handleCreateBudget}>Create New Budget</Button>
                        <Button variant="outline-primary" onClick={() => {setModalSwitch('load')}}>Import Existing Budget</Button>
                    </div>

                }

            </Modal.Body>
            <Modal show={modalSwitch === 'load'} onHide={() => {setModalSwitch('')}}>
                <BudgetLoadModal handleLoadBudget={handleLoadBudget}/>
            </Modal>
            <Modal show={modalSwitch === 'create'} onHide={() => {setModalSwitch('')}}>
                <AlertModal title='Create New Budget' message={'This cannot be undone.'} buttonLabel='Create New Budget' callBack={handleCreateBudget}/>
            </Modal>
            <Modal newApiCall show={modalSwitch === 'delete'} onHide={() => {setModalSwitch('')}} >
                <AlertModal title='Remove Budget?' message={'This cannot be undone.'} buttonLabel='Remove Budget' callBack={handleRemoveBudget}/>
            </Modal>

        </>
    )
}