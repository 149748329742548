/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__∂
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTrashCan, faPencil, faDownload, faUpload, faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons';
import FileUploadControl from '../../../components/FileUploadControl';
import AlertModal from '../../../components/AlertModals/AlertModal';
import Card from 'react-bootstrap/Card';
import { OnboardingDocumentsReadOnly, getFileIcon, taxDocumentDictionary } from '../../../tools';
import FileDownloadButton from '../../../components/FileDownloadButton';
import moment from 'moment';
import Cookies from 'universal-cookie';
import DocumentPreview from '../../../components/DocumentPreview';
import QuickTable from '../../../components/QuickTable';
import DocumentRequestModal from './DocumentRequestModal';
import HRUserDocumentEditor from './HRUserDocumentEditor';
import DocumentRequestDetails from './DocumentRequestDetails';
import PDFMerger from 'pdf-merger-js/browser';
import CustomButton from '../../../components/CustomButton';
import { ApiRequest } from '../../../ApiManager.tsx';
import LoadingWrapper from '../../../components/LoadingWrapper.js';

export default function HRUserDocuments({selectedEmployee}){
    const [isLoading, setIsLoading] = useState(true);
    const [documents, setDocuments] = useState([]);
    const [search, setSearch] = useState('');
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [docToDelete, setDocToDelete] = useState(undefined);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState(undefined);
    const [equalCompanyIds, setEqualCompanyIds] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [pandaDocs, setPandaDocs] = useState([]);
    const [edDocumentRequests, setEdDocumentRequests] = useState([]);
    const [showDocumentRequestModal, setShowDocumentRequestModal] = useState(false);
    const [showDocumentRequestDetails, setShowDocumentRequestDetails] = useState(false);
    const [selectedEdDocumentRequest, setSelectedEdDocumentRequest] = useState(undefined);
    const [isDeleting, setIsDeleting] = useState(undefined);
    const cookieMonster = new Cookies();

    useEffect(() => {
        loadData();
    }, [selectedEmployee])

    function loadData(){
        const clockInPicture = 'clockInPicture';
        setEqualCompanyIds(false);
        if(cookieMonster.get('userData').companyUserUid == selectedEmployee.companyUserUid){
            setEqualCompanyIds(true);
        }
        new ApiRequest('hr', 'getAllDocuments', setIsLoading, (response) => {
            setDocuments(response.customDocuments.filter((doc)=>doc.documentName.toLowerCase() !== clockInPicture.toLowerCase()));
            setPandaDocs(response.pandaDocs);
            setEdDocumentRequests(response.edDocumentRequests);
        }).withData({companyUserUid: selectedEmployee.companyUserUid}).withNoAlertOnSuccess().send();
    }

    function handleDeleteDocument(){
        new ApiRequest('hr', 'deleteCustomDocument', setIsDeleting, () => {
            const newArray = Array.from(documents).filter(r => r.uid != docToDelete);
            setDocuments(newArray);
            setDocToDelete(undefined);
        }).withUid(docToDelete).send();
    }

    function handleUploadDocuments(docObjs){
        const newArray = Array.from(documents);
        docObjs.forEach((obj)=>{
            newArray.push(obj);
        })
        setDocuments(newArray);
        setShowUploadModal(false);
    }

    function handleRemoveEdDocument(documentRequestIdentifier, documentType) {
        const newArray = Array.from(edDocumentRequests);
        const request = newArray.find(r => r.uid == documentRequestIdentifier);
        delete request.documents[documentType];
        setEdDocumentRequests(newArray);
    }

    function handleSetEDDocumentIncomplete(documentRequestIdentifier, documentType) {
        const newArray = Array.from(edDocumentRequests);
        const request = newArray.find(r => r.uid == documentRequestIdentifier);
        request.documents[documentType].employeeCompleted = null;
        setEdDocumentRequests(newArray);
    }

    function handleEditButtonClick(doc){
        setSelectedDoc(doc);
        setShowEditModal(true);
    }

    function handleEditHrDocumentName(document){
        const newArray = documents.map((doc)=>{
            if(doc.uid == document.uid){
                return document;
            }
            return doc;
        });
        setDocuments(newArray);
        setShowEditModal(false);
    }

   function handlePreviewButtonClick(doc){
        setSelectedDoc(doc)
        setShowPreviewModal(true);
   }

    const filteredDocuments = documents.filter((doc) => {
        return doc.documentName.toLowerCase().includes(search.toLowerCase());
    });

    const documentsElements = filteredDocuments.sort((a, b) => moment(b.timestamp).valueOf() - moment(a.timestamp).valueOf()).map((doc) => {
        return (
        <Card key={doc.uid} style={{ display: 'flex', flexDirection: 'column', marginBottom: 12 }}>
            <div style={{ display: 'flex', flexDirection:'row', padding: '12px 18px', backgroundColor: 'var(--bs-primary)', alignItems: 'center', borderTopLeftRadius: 6, borderTopRightRadius: 6 }}>
            <FontAwesomeIcon icon={getFileIcon(doc.documentType)} style={{ color: 'white', marginRight: 8 }} />
            {doc.timestamp ? (
                <span style={{ color: 'white', fontSize: '0.8rem' }}>
                {moment(doc.timestamp).format('MM/DD/YYYY hh:mm A')}
                </span>
            ) : (
                <span style={{ color: 'white', fontSize: '0.8rem' }}>
                {moment().format('MM/DD/YYYY hh:mm A')}
                </span>
            )}
            </div>
            <div style={{display: 'flex', alignItems: 'center', padding: '8px 18px', border: '2px solid var(--bs-primary)', borderBottomLeftRadius: 6,borderBottomRightRadius: 6, flexWrap: 'wrap'}} >
            <div style={{ flex: 1 }}>
                <span style={{ fontSize: '1rem' }}>{doc.documentName}</span>
            </div>
            <div style={{ display: 'flex', gap: 8 }}>
                {doc.documentType === 'pdf' &&<Button variant={'outline-primary'} style={{fontSize: '0.8rem', padding: '4px 8px' }} onClick={() => handlePreviewButtonClick(doc)}><FontAwesomeIcon size='xl' icon={faMagnifyingGlass}/></Button>}
            {(doc.documentType === 'jpeg' || doc.documentType === 'png' || doc.documentType === 'jpg') && <Button variant={'outline-primary'} style={{fontSize: '0.8rem', padding: '4px 8px' }} onClick={() => handlePreviewButtonClick(doc)}><FontAwesomeIcon size='xl' icon={faMagnifyingGlass}/></Button>}
                <FileDownloadButton doc={doc} type='hr' target='downloadCustomDocument' data={{uid: doc.uid}}label={<FontAwesomeIcon size='lg' icon={faDownload}/>}/>
                <Button variant={'outline-primary'} style={{fontSize: '0.8rem', padding: '4px 8px' }} onClick={() => handleEditButtonClick(doc)}><FontAwesomeIcon size='xl' icon={faPencil}/></Button>
                <Button variant={'outline-primary'} style={{fontSize: '0.8rem', padding: '4px 8px' }} onClick={() => setDocToDelete(doc.uid)}><FontAwesomeIcon size='xl' icon={faTrashCan}/></Button>
            </div>
            </div>
        </Card>
        );
    });

    const filteredPandaDocs = pandaDocs.filter((doc) => {
        return doc.name.toLowerCase().includes(search.toLowerCase());
    });

    const pandaDocsList = filteredPandaDocs.map((p)=>{
        return (
            <tr key={p.uid}>
                <td>{p.name}</td>
                <td>{moment(p.dateCreated).format('MMM D, YYYY')}</td>
                <td>{p.completed ? 'Completed' : 'Sent'}</td>
                <td><a href={p.link} target='_blank'>{'View'}</a></td>
            </tr>
        )
    });

    const edRequestElements = edDocumentRequests.map((request) => {
        return (
            <DocumentRequestElement key={request.uid} request={request} selectedEmployee={selectedEmployee} setSelectedEdDocumentRequest={setSelectedEdDocumentRequest} setShowDocumentRequestDetails={setShowDocumentRequestDetails}/>
        )
    })

    const content = (
        <div style={{display: 'flex', flexDirection: 'column', gap: 12, height: '100%', overflowY: 'hidden'}}>
            <div className="d-flex flex-column flex-lg-row gap-3 align-items-center">
                <InputGroup className="flex-grow-1 mb-3 mb-lg-0">
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch}/>
                    </InputGroup.Text>
                    <Form.Control type='text' placeholder='Search for a document by title' value={search} onChange={(event) => setSearch(event.target.value)}/>
                </InputGroup>
                { (!selectedEmployee.isTerminated || selectedEmployee.firstUpcomingPayDateEffective) &&
                    <div className="d-flex gap-3">
                        <Button variant={'outline-primary'} style={{ whiteSpace: 'nowrap'}} onClick={() => setShowUploadModal(true)}><FontAwesomeIcon size='xl' icon={faUpload}/></Button>
                        <Button variant='outline-primary' style={{ whiteSpace: 'nowrap'}} onClick={() => setShowDocumentRequestModal(true)}>Send Employee Document Request</Button>
                    </div>
                }
            </div>
            <div style={{flex: 1, overflowY: 'auto'}}>
                { edRequestElements.length > 0 && 
                    <QuickTable noWrap title='Document Requests' headers={['Date Created', 'Documents Requested', 'Status', '']} rows={edRequestElements} size='sm' hover/>
                }
                {pandaDocs.length > 0 &&
                    <QuickTable title='Onboarding Document (Panda Docs)' headers={['Name', 'Date Sent', 'Status', 'Link']} rows={pandaDocsList} widths={[null, null, null, null]} size={'sm'}/>
                }
                { documentsElements.length > 0 && 
                    <div style={{display: 'flex', flexDirection: 'column', gap: 12, backgroundColor: 'rgb(240, 240, 240)', padding: 8, borderRadius: 6, marginTop: 8, maxHeight: '70vh', overflowY: 'scroll'}}>
                        {documentsElements}
                    </div>
                }
            </div>
        </div>
    );

    return (
        <>
            <LoadingWrapper isLoading={isLoading}>{content}</LoadingWrapper>
            <Modal size='lg' show={showUploadModal} onHide={() => setShowUploadModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Document(s)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FileUploadControl multiple companyUserUid={selectedEmployee.companyUserUid} handleUploadDocuments={handleUploadDocuments}/>
                </Modal.Body>
            </Modal>
            <Modal centered show={docToDelete} onHide={() => setDocToDelete(undefined)}>
                <AlertModal isLoading={isDeleting} variant={'outline-danger'} title='Deletion Confirmation' message='Permanently delete document?' buttonLabel='Delete' callBack={handleDeleteDocument}/>
            </Modal>
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)} centered>
                <HRUserDocumentEditor document={selectedDoc} handleEditHrDocumentName={handleEditHrDocumentName}/>  
            </Modal>
            <Modal size='xl' show={showPreviewModal} onHide={() => setShowPreviewModal(false)}>
                <DocumentPreview document={selectedDoc} hr={true}/>
            </Modal>
            <Modal size='xl' show={showDocumentRequestDetails} onHide={() => setShowDocumentRequestDetails(false)}>
                <DocumentRequestDetails selectedEmployee={selectedEmployee} documentRequest={selectedEdDocumentRequest} handleRemoveEdDocument={handleRemoveEdDocument} handleSetEDDocumentIncomplete={handleSetEDDocumentIncomplete}/>
            </Modal>
            <Modal size='xl' show={showDocumentRequestModal} onHide={() => setShowDocumentRequestModal(false)}>
                <DocumentRequestModal companyUserIdentifier={selectedEmployee.companyUserUid} hideModal={() => {setShowDocumentRequestModal(false); loadData()}}/>
            </Modal>
        </>
    )

}


function DocumentRequestElement({request, selectedEmployee, setSelectedEdDocumentRequest, setShowDocumentRequestDetails}) {
    const [isDownloadingRequest, setIsDownloadingRequest] = useState(undefined);

    function handleDownloadDocumentRequest(documentRequest){
        new ApiRequest('hr', 'downloadDocumentRequest', setIsDownloadingRequest, async (response) => {
            const merger = new PDFMerger();
            const sortedDocumentTypes = ['offerLetter', 'i9', 'w4', ...Object.keys(taxDocumentDictionary), 'dd', 'mdd', 'f8850'].filter(d => Object.keys(response.documents).includes(d));

            for (let i = 0; i < sortedDocumentTypes.length; i++) {
                const document = response.documents[sortedDocumentTypes[i]];
                if (document.base64) {
                    const fetchResponse = await fetch(document.base64);
                    const blob = await fetchResponse.blob();
                    await merger.add(blob, null);
                }
            }
    
            const mergedPdf = await merger.saveAsBlob();
            const elem = window.document.createElement('a');
            elem.target = '_blank';
            elem.href = window.URL.createObjectURL(mergedPdf);
            elem.download = `${selectedEmployee?.firstName ?? ''} ${selectedEmployee?.lastName ?? ''} Document Request ${moment(documentRequest.dateCreated).format('MMM D, YYYY')}`;        
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        }).withUid(documentRequest.uid).withNoAlertOnSuccess().send()
    }

    const includedDocuments =  Object.keys(request.documents);
    let documents = ['offerLetter', 'i9', 'w4', ...Object.keys(taxDocumentDictionary), 'dd', 'f8850'].filter(d => includedDocuments.includes(d)).map(d => OnboardingDocumentsReadOnly[d]);

    if(request.documents.i9){
        documents.push(`Driver's License`)
        documents.push('Social Security Card')
    }
    documents = documents.join(', ');

    const status = Object.keys(request.documents).reduce((prev, curr) => {
        if (prev === 'Awaiting Employee') {
            return prev;
        } else {
            if (!request.documents[curr].employeeCompleted && !request.documents[curr].archived) {
                return 'Awaiting Employee';
            } else if ('employerCompleted' in request.documents[curr] && !request.documents[curr].employerCompleted && !request.documents[curr].archived) {
                return 'Awaiting Employer';
            } else if((request.i9 && !request.dlDataIdentifier) || (request.i9 && !request.ssnDataIdentifier)){
                return 'Awaiting Employee';
            } else {
                return prev;
            }
        }
    }, 'Complete');
    
    return (
        <tr className='cursor-pointer' key={request.uid} onClick={() => {setSelectedEdDocumentRequest(request); setShowDocumentRequestDetails(true)}}>
            <td>{moment(request.dateCreated).format('MMM D, YYYY')}</td>
            <td>{documents}</td>
            <td>{status}</td>
            <td>
                <CustomButton 
                    title='Download' 
                    style={{padding: '0px 6px 0px 6px'}} 
                    label={<FontAwesomeIcon icon={faDownload}/>} 
                    isLoading={isDownloadingRequest} 
                    onClick={(event) => {handleDownloadDocumentRequest(request); event.stopPropagation()}}
                />
            </td>
        </tr>
    )
}