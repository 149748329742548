/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import { validateDecimal, validateBig } from "../payrollTools";
import Big from "big.js";
import Decoder from "../../../decoding";
import { getFakeUID } from "../../../tools";

export default class Pay {
    id;
    payType;
    payRate;
    unitsWorked;
    periodStart;
    periodEnd;

    constructor(payType, payRate, unitsWorked, periodStart, periodEnd) {
        this.id = getFakeUID();
        this.payType = payType;
        this.payRate = payRate;
        this.unitsWorked = unitsWorked;
        this.periodEnd = periodEnd;
        this.periodStart = periodStart;
    }

    static initDefault() {
        return new Pay('pd', 0.0, 0, 'ERR', 'ERR');
    }

    static decodeFromEntry(json, periodStart, periodEnd) {
        const decoder = new Decoder(json);
        const array = decoder.decode('pay', Decoder.Array, {defaultValue: undefined, warn: false});
        if (array) {
            return array.map(payJson => Pay.decode(payJson));
        } else {
            const payType = decoder.decode('payType', Decoder.StringStrict);
            const payRate = decoder.decode('payRate', Decoder.Decimal);
            let unitsWorked;
            if (payType) {
                switch (payType) {
                    case 'ph':
                        unitsWorked = decoder.decode('hoursWorked', Decoder.Decimal, {defaultValue: 0, warn: false});
                    case 'pd':
                        unitsWorked = decoder.decode('daysWorked', Decoder.Integer, {defaultValue: 0, warn: false});
                    case 'ps':
                        unitsWorked = decoder.decode('stops', Decoder.Integer, {defaultValue: 0, warn: false});
                    case 'pm':
                        unitsWorked = decoder.decode('miles', Decoder.Decimal, {defaultValue: 0, warn: false});
                    default: 
                        unitsWorked = 0;
                }
            }
            if (decoder.checkForErrors()) {
                return [new Pay(payType, payRate, unitsWorked, periodStart, periodEnd)];
            } else {
                return [];
            }
        }
    }

    static decode(json) {
        const decoder = new Decoder(json);

        const payType = decoder.decode('payType', Decoder.StringStrict);
        const payRate = decoder.decode('payRate', Decoder.Decimal);
        const unitsWorked = decoder.decode('unitsWorked', Decoder.Decimal);
        const periodStart = decoder.decode('periodStart', Decoder.StringStrict);
        const periodEnd = decoder.decode('periodEnd', Decoder.StringStrict);

        if (decoder.checkForErrors()) {
            return new Pay(payType, payRate, unitsWorked, periodStart, periodEnd);
        } else {
            return Pay.initDefault();
        }
    }

    encode() {
        return {
            payType: this.payType,
            payRate: validateDecimal(this.payRate),
            unitsWorked: validateDecimal(this.unitsWorked),
            periodStart: this.periodStart,
            periodEnd: this.periodEnd
        }
    }

    duplicate() {
        return new Pay(this.payType, this.payRate, this.unitsWorked, this.periodStart, this.periodEnd);
    }

    payPeriodLength() {
        return moment(this.periodEnd).diff(moment(this.periodStart), 'days') + 1;
    }

    getPayPeriodsPerYear() {
        return 52 / (this.payPeriodLength() / 7);
    }

    getWages() {
        switch (this.payType) {
            case 'py':
                return validateBig(this.payRate).div(this.getPayPeriodsPerYear());
            case 'iw': case 'sw': case 'ot':
                return validateBig(this.payRate);
            default: 
                return validateBig(this.payRate).times(validateBig(this.unitsWorked));
        }
    }
}