/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import { usdFormatter, validateDecimal, validateDecimalFixed } from "../../tools";
import { useEffect, useMemo } from "react";

export default function BookkeepingChargeStatementsRevenue({sReportData, startDate, endDate, selectedCsaFedexId, setTotalRevenue}){

    const totalProfitData = useMemo(() => {
        return {
            puPackageAmount:  getFlatAmount('puPackageAmount', true),
            puStopAmount: getFlatAmount('puStopAmount', true),
            dlPackageAmount: getFlatAmount('dlPackageAmount', true),
            dlStopAmount: getFlatAmount('dlStopAmount', true),
            desigAmount: getFlatAmount('desigAmount', true),
            ecPackageAmount: getFlatAmount('ecPackageAmount', true),
            ecStopAmount: getFlatAmount('ecStopAmount', true),
            fuelAmount: getFlatAmount('fuelAmount', true),
            surgeAmount: getFlatAmount('surgeAmount', true),
            otherAmount: getRatioAmount('otherAmount', true),
        }
    }, [sReportData])

    const profitData = useMemo(() => {
        return {
            puPackageAmount:  getFlatAmount('puPackageAmount'),
            puStopAmount: getFlatAmount('puStopAmount'),
            dlPackageAmount: getFlatAmount('dlPackageAmount'),
            dlStopAmount: getFlatAmount('dlStopAmount'),
            desigAmount: getFlatAmount('desigAmount'),
            ecPackageAmount: getFlatAmount('ecPackageAmount'),
            ecStopAmount: getFlatAmount('ecStopAmount'),
            fuelAmount: getFlatAmount('fuelAmount'),
            surgeAmount: getFlatAmount('surgeAmount'),
            otherAmount: getRatioAmount('otherAmount'),
            desigPercentage: getAverageDayAmount('desigPercentage'),
        }
    }, [sReportData, selectedCsaFedexId])

    useEffect(() =>{
        setTotalRevenue(Object.values(totalProfitData).reduce((acc, el) => acc + el), 0);
    }, [sReportData])

    function getFlatAmount(type, all = false){
    const chargeStatements = (selectedCsaFedexId && !all) ? Object.entries(sReportData).filter(([key, _]) => key == selectedCsaFedexId).map(([_, value]) => value) : Object.values(sReportData);
        return chargeStatements.reduce((acc, el) => {
            // Get total TYPE amount for csa settlement reports
            return acc + el.reduce((acc, el) => {

                // Filter out day indexes that fall out of date range
                let days = Array.from(el.days);
                const chargeStatementEndDate = moment(el.startDate).add(6, 'days').format('YYYY-MM-DD');

                if(moment(el.startDate).isBefore(moment(startDate))){
                    // only for first settlement report and falls outside date range
                    const dayDiff = moment(startDate).diff(moment(el.startDate), 'days');
                    days = days.filter(d => d.dayIndex >= dayDiff); 

                }
                if(moment(chargeStatementEndDate).isAfter(moment(endDate))){
                    // only for last settlement report and falls outside date range
                    const dayDiff = moment(chargeStatementEndDate).diff(moment(endDate), 'days');
                    days = days.filter(d => d.dayIndex < 7-dayDiff);
                }
    
                // grab sum of TYPE from settlement report days
                return acc + days.reduce((acc, el) => {
                    return acc + validateDecimal(el[type]);
                }, 0)
            }, 0)
        }, 0)
    }

    function getRatioAmount(type, all = false){
        const chargeStatements = (selectedCsaFedexId && !all) ? Object.entries(sReportData).filter(([key, _]) => key == selectedCsaFedexId).map(([_, value]) => value) : Object.values(sReportData);
        return chargeStatements.reduce((acc, el) => {
            // Get total TYPE amount for csa settlement reports
            return acc + el.reduce((acc, el) => {
                let amount = validateDecimal(el[type]);
                const chargeStatementEndDate = moment(el.startDate).add(6, 'days').format('YYYY-MM-DD');
                // Check if startDate or endDate fall out of range and mulitply TYPE by ratio
                if(moment(el.startDate).isBefore(moment(startDate))){
                    // only for first settlement report and falls outside date range
                    const dayDiff = moment(startDate).diff(moment(el.startDate), 'days');
                    amount *= (7-dayDiff)/7;

                }else if(moment(chargeStatementEndDate).isAfter(moment(endDate))){
                    // only for last settlement report and falls outside date range
                    const dayDiff = moment(chargeStatementEndDate).diff(moment(endDate), 'days');
                    amount *= (7-dayDiff)/7;
                }
                return acc + amount;
            }, 0)
        }, 0)
    }

    // function getAverageAmount(type){
    //     let count = 0;
    //     const chargeStatements = selectedCsaFedexId ? Object.entries(sReportData).filter(([key, _]) => key == selectedCsaFedexId).map(([_, value]) => value) : Object.values(sReportData);
    //     const value = chargeStatements.reduce((acc, el) => {
    //         return acc + el.reduce((acc, el) => {
    //             count++;
    //             return acc + validateDecimal(el[type]);
    //         }, 0)
    //     }, 0)

    //     return validateDecimalFixed(value/count, 2);
    // }

    function getAverageDayAmount(type){
        let count = 0;
        const chargeStatements = selectedCsaFedexId ? Object.entries(sReportData).filter(([key, _]) => key == selectedCsaFedexId).map(([_, value]) => value) : Object.values(sReportData);
        const value = chargeStatements.reduce((acc, el) => {
            // Get total TYPE amount for csa settlement reports
            return acc + el.reduce((acc, el) => {

                // Filter out day indexes that fall out of date range
                let days = Array.from(el.days);
                const chargeStatementEndDate = moment(el.startDate).add(6, 'days').format('YYYY-MM-DD');

                if(moment(el.startDate).isBefore(moment(startDate))){
                    // only for first settlement report and falls outside date range
                    const dayDiff = moment(startDate).diff(moment(el.startDate), 'days');
                    days = days.filter(d => d.dayIndex >= dayDiff);

                }

                if(moment(chargeStatementEndDate).isAfter(moment(endDate))){
                    // only for last settlement report and falls outside date range
                    const dayDiff = moment(chargeStatementEndDate).diff(moment(endDate), 'days');
                    days = days.filter(d => d.dayIndex < 7-dayDiff);
                }
                
                // grab sum and increment count of TYPE from settlement report days
                return acc + days.reduce((acc, el) => {
                    count++;
                    return acc + validateDecimal(el[type]);
                }, 0)
            }, 0)
        }, 0)

        return validateDecimalFixed(value/count, 2);
    }

    const mainRows = [
        {label: 'Pickup Packages', key: 'puPackageAmount'},
        {label: 'Pickup Stops', key: 'puStopAmount'},
        {label: 'Delivery Packages', key: 'dlPackageAmount'},
        {label: 'Delivery Stops', key: 'dlStopAmount'},
        {label: 'Large Packages', key: 'desigAmount'},
        {label: 'E-commerce Packages', key: 'ecPackageAmount'},
        {label: 'E-commerce Deliveries', key: 'ecStopAmount'},
        {label: 'Fuel', key: 'fuelAmount'},
        {label: 'Surge', key: 'surgeAmount'},
        
    ].map((row, index) => {
        return (
            <div key={index} style={{display: 'flex', gap: 12, justifyContent: 'space-between', fontSize: 13, paddingLeft: 12}}>
                <span>{row.label}</span>
                <span>{usdFormatter.format(profitData[row.key])}</span>
            </div>
        )
    })

    const secondaryRowsArray = [
        {label: 'Average Large Package Percentage', key: 'desigPercentage', formatter: (value) => value + '%'},
    ];


    const secondaryRows = secondaryRowsArray.map((row, index) => {
        return (
            <div key={index} style={{display: 'flex', gap: 12, justifyContent: 'space-between', fontSize: 18 * 0.8, paddingLeft: 12}}>
                <span>{row.label}</span>
                <span>{row.formatter(profitData[row.key])}</span>
            </div>
        )
    })
    
    return (
        <>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', gap: 12}}>
            </div>
                <div style={{fontSize: 18}}>
                <span style={{fontSize: 18, opacity: 0.5, fontWeight: 'bold'}}>{'REVENUE'}</span>
                <div style={{display: 'flex', flexDirection: 'column', paddingLeft: 12}}>
                    <span style={{fontSize: 15, opacity: 0.5, fontWeight: 'bold'}}>{'Pick-Up And Delivery'}</span>
                    {mainRows.slice(0, 9)}
                    <div style={{display: 'flex', gap: 6, fontWeight: 'bold', fontSize: 15}}>
                        <span style={{fontWeight: 'bold'}}>Pick-Up And Delivery Total</span>
                        <div style={{flex: 1, borderBottom: '2px dotted lightgray', marginBottom: 5}}/>
                        <span style={{fontWeight: 'bold'}}>{usdFormatter.format(Object.values(profitData).slice(0, 9).reduce((acc, el) => {return acc + el}, 0))}</span>
                    </div>
                    <hr style={{marginTop: 6, marginBottom: 6}}/>
                    <span style={{fontSize: 15, opacity: 0.5, fontWeight: 'bold'}}>{'Other P&D Charges'}</span>
                    {mainRows.slice(9)}
                    <div style={{display: 'flex', gap: 6, fontWeight: 'bold', fontSize: 15}}>
                        <span style={{fontWeight: 'bold'}}>Other P&D Charges Total</span>
                        <div style={{flex: 1, borderBottom: '2px dotted lightgray', marginBottom: 5}}/>
                        <span style={{fontWeight: 'bold'}}>{usdFormatter.format(profitData['otherAmount'])}</span>
                    </div>
                    <hr style={{marginTop: 6, marginBottom: 6}}/>
                    <span style={{fontSize: 15, opacity: 0.5, fontWeight: 'bold'}}>{'Misc.'}</span>
                    {secondaryRows}
                    <hr style={{marginTop: 6, marginBottom: 6}}/>
                    <div style={{display: 'flex', gap: 6, fontWeight: 'bold', fontSize: 18}}>
                        <span style={{fontWeight: 'bold'}}>Grand Total Revenue:</span>
                        <div style={{flex: 1, borderBottom: '2px dotted lightgray', marginBottom: 5}}/>
                        <span style={{fontWeight: 'bold', color: 'green'}}>{usdFormatter.format(Object.values(profitData).slice(0, 10).reduce((acc, el) => {return acc + el}, 0))}</span>
                    </div>
                </div>
            </div>
        </>
    )
}