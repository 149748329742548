/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ApiRequest } from "../ApiManager.tsx";
import LoadingWrapper from "../components/LoadingWrapper";
import { Letter } from "react-letter";

export default function Mail() {
    const { token } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [mail, setMail] = useState(undefined);

    useEffect(() => {
        loadData();
    }, [])

    function loadData() {
        new ApiRequest('mail', 'getMail', setIsLoading, (response) => {
            setMail(response.mail);
        }).withNoAlertOnSuccess().withData({token: token}).send();
    }

    return (
        <div style={{padding: 12}}>
            <LoadingWrapper isLoading={isLoading}>
                { mail && 
                    <Letter html={mail.html}/>
                }
            </LoadingWrapper>
        </div>
    )

}