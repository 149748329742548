/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../decoding";
import { validateDecimal, validateUsd } from "../payrollTools";
import { getFakeUID } from "../../../tools";

export default class AdditionalPay {

    id;
    type;
    label;
    amount;
    isReoccuring;
    canBeReoccuring;

    constructor(type, label, amount = 0, isReoccuring, canBeReoccuring = true) {
        this.id = getFakeUID();
        this.type = type;
        this.label = label;
        this.amount = amount;
        this.isReoccuring = isReoccuring;
        this.canBeReoccuring = canBeReoccuring
    }

    static initDefault() {
        return new AdditionalPay('Holiday Bonus', 'ERR', 0, false, false);
    }

    static decodeFromEntry(json) {
        const decoder = new Decoder(json);
        const array = decoder.decode('additionalPay', Decoder.Array);
        if (decoder.checkForErrors()) {
            return array.filter(json => AdditionalPay.additionalPayTypes.includes(json.type)).map(json => AdditionalPay.decode(json));
        } else {
            return [];
        }
    }

    static decode(json) {
        const decoder = new Decoder(json);
        const type = decoder.decode('type', Decoder.StringStrict);
        const label = decoder.decode('label', Decoder.StringStrict, {defaultValue: undefined, warn: false});
        const amount = decoder.decode('amount', Decoder.Decimal);
        const isReoccuring = decoder.decode('isReoccuring', Decoder.Boolean, {defaultValue: false, warn: true});
        const canBeReoccuring = decoder.decode('canBeReoccuring', Decoder.Boolean, {defaultValue: true, warn: true});

        if (decoder.checkForErrors()) {
            return new AdditionalPay(type, label, amount, isReoccuring, canBeReoccuring);
        } else {
            return AdditionalPay.initDefault();
        }
    }

    encode() {
        return {
            type: this.type,
            label: this.label,
            amount: validateDecimal(this.amount),
            isReoccuring: this.isReoccuring,
            canBeReoccuring: this.canBeReoccuring,
        }
    }

    duplicate() {
        return new AdditionalPay(this.type, this.label, this.amount, this.isReoccuring, this.canBeReoccuring)
    }
    getAmount() {
        return validateUsd(this.amount);
    }
    isOther() {
        return this.type === 'Other Discretionary Bonus';
    }
    isPerDiem() {
        return this.type === 'Per Diem';
    }
    isExtraDayWage(){
        return this.type === 'Extra Day Wage'
    }
    isExpenseReimbursement() {
        return this.type === 'Expense Reimbursement';
    }
    isBackpay(){
        return this.type === 'Backpay';
    }
    
    
    static additionalPayTypes = [
        'Per Diem',
        'Expense Reimbursement', //now in gross
        'Extra Day Wage',
        'Backpay'
    ]
}