/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Table from 'react-bootstrap/Table';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';

function getDaysOfTheWeek(date) {
    const startOfWeek = (date.day() === 6 ? moment(date) : moment(date).subtract(date.day() + 1, 'days'))
    const daysOfWeek = [
        moment(startOfWeek), 
        moment(startOfWeek).add(1, 'days'), 
        moment(startOfWeek).add(2, 'days'), 
        moment(startOfWeek).add(3, 'days'), 
        moment(startOfWeek).add(4, 'days'), 
        moment(startOfWeek).add(5, 'days'), 
        moment(startOfWeek).add(6, 'days'),
    ];
    return daysOfWeek;
}

function TimesheetCalendar({timesheetData, handleMonthChange, selectedMonth}) {
    const rows = [0, 1, 2, 3, 4, 5].map((index) => {
        const columns = getDaysOfTheWeek(moment(selectedMonth).add(index, 'weeks')).map((date, index) => {
            const dataForDay = timesheetData.filter((entry) => {
                return moment(entry.inTime).isSame(moment(date), 'day');
            }).sort((a, b) => {return a.inTime < b.inTime ? -1 : a.inTime > b.inTime ? 1 : 0});

            const timesheetCards = dataForDay.map((entry) => {
                return (
                    <div key={entry.uid} style={{backgroundColor: 'white', display: 'flex', flexDirection: 'column', textAlign: 'start', paddingLeft: 4, paddingRight: 4, border: '1px solid var(--bs-primary)', borderLeft: '6px solid var(--bs-primary)', borderTopRightRadius: 6, borderBottomRightRadius: 6}}>
                        
                        { entry.outTime ?
                            <>
                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2}}>
                                    <span style={{color: 'black'}}>{ moment(entry.inTime).format('h:mm A')}</span>
                                    <FontAwesomeIcon icon={faArrowRight}/>
                                    <span style={{color: 'black'}}>{moment(entry.outTime).format('h:mm A')}</span>
                                </div>
                                <span style={{fontWeight: 'bold', opacity: 0.7, textAlign: 'center'}}>{'Total: ' + moment(entry.outTime).diff(moment(entry.inTime), 'hours', true).toFixed(2) + ' Hours'}</span>
                            </>
                            :
                            <span style={{color: 'green', opacity: 0.6}}>{'IN: ' + moment(entry.inTime).format('h:mm A')}</span>
                        }
                    </div>
                )
            })

            return (
                <td key={index} style={{padding: 0, textAlign: 'center', fontSize: '1.5vmin', opacity: date.format('MMM') !== moment(selectedMonth).format('MMM') ? 0.3 : 1.0}}>
                    <div style={{display: 'flex', height: '100%', flexDirection: 'column', gap: 2, justifyContent: 'space-between'}}>
                        <p style={{alignSelf: 'flex-end', marginBottom: 0, marginRight: 4}}>{date.format('D')}</p>
                        <div style={{display: 'flex', flexDirection: 'column', gap: 2, padding: 4}}>
                            {timesheetCards}
                        </div>
                    </div>
                </td>
            );
        });

        return (
            <tr key={index} style={{height: '16.66%'}}>
                {columns}
            </tr>
        );
    });

    const columnHeaders = ['Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri'].map((str) => {
        return (<th key={str} style={{fontSize: '0.75vmax', width: '14.3%', textAlign: 'center', fontWeight: 'normal', opacity: 0.66}}>{str}</th>);
    });

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%'}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 8}}>
                <Button variant={'outline-primary'} style={{padding: '2px 8px 2px 8px', display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={() => {handleMonthChange(moment(selectedMonth).subtract(1, 'month'))}}>
                    <FontAwesomeIcon icon={faChevronLeft} style={{height: '2vmin'}}/>
                </Button>
                <span style={{marginRight: 8, marginLeft: 8, fontSize: '3vmin', opacity: 0.7}}>{moment(selectedMonth).format('MMM YYYY')}</span>
                <Button variant={'outline-primary'} style={{padding: '2px 8px 2px 8px', display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={() => {handleMonthChange(moment(selectedMonth).add(1, 'month'))}}>
                    <FontAwesomeIcon icon={faChevronRight} style={{height: '2vmin'}}/>
                </Button>
            </div>
            
            
            <div style={{width: '100%', flex: 1}}>
                <Table bordered size='sm' style={{width: '100%', height: '100%'}}>
                    <thead>
                        <tr>
                            {columnHeaders}
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </Table>
            </div>
            
        </div>
    );
}

export default TimesheetCalendar;