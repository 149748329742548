/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../decoding";
import { validateDecimal, validateDecimalFixed, validateInteger } from "../../../tools";

export default class BudgetAssumptions {
	vehicleBranding;
	uniforms;
	packageCharge;
	stopCharge;
	eCommStopCharge;
	serviceCharge;
	perStopFuelSurcharge;
	largePackageMixDeliveryPercentage;
	largePackageMixCharge;
	advanceServiceCharge;
	totalDeliveryPackages;

    constructor(vehicleBranding, uniforms, packageCharge, stopCharge, eCommStopCharge, serviceCharge, perStopFuelSurcharge, largePackageMixDeliveryPercentage, largePackageMixCharge, advanceServiceCharge, totalDeliveryPackages) {
        this.vehicleBranding = vehicleBranding;
        this.uniforms = uniforms;
        this.packageCharge = packageCharge;
        this.stopCharge = stopCharge;
        this.eCommStopCharge = eCommStopCharge;
        this.serviceCharge = serviceCharge;
        this.perStopFuelSurcharge = perStopFuelSurcharge;
        this.largePackageMixDeliveryPercentage = largePackageMixDeliveryPercentage;
        this.largePackageMixCharge = largePackageMixCharge;
        this.advanceServiceCharge = advanceServiceCharge;
        this.totalDeliveryPackages = totalDeliveryPackages;
    }

    static initDefault() {
        return new BudgetAssumptions('', '', '', '', '', '', '', '', '', '', '');
    }

    static initFromPcat(pcat, proposalIndex) {
        const vehicleBranding = pcat.proposals[proposalIndex].vehicleBrandPromotionRevenue().toNumber();
        const uniforms = pcat.proposals[proposalIndex].apparelBrandPromotionRevenue().toNumber();
        const totalPackages = pcat.proposals[proposalIndex].totalNonECommPackages(pcat).plus(pcat.proposals[proposalIndex].totalECommPackages(pcat));
        const totalPackageRevenue = pcat.proposals[proposalIndex].nonECommPackageRevenue(pcat).plus(pcat.proposals[proposalIndex].eCommPackageRevenue(pcat));
        const packageCharge = totalPackages.eq(0) ? pcat.proposals[proposalIndex].packageCharge : totalPackageRevenue.div(totalPackages).toNumber();
        const stopCharge = pcat.proposals[proposalIndex].stopCharge;
        const eCommStopCharge = pcat.proposals[proposalIndex].eCommStopCharge;
        const serviceCharge = validateDecimal(pcat.proposals[proposalIndex].serviceCharge) * 52;
        const perStopFuelSurcharge = pcat.proposals[proposalIndex].perStopFuelSurcharge;
        const largePackageMixDeliveryPercentage = pcat.largePackageMixDeliveryPercentage;
        const largePackageMixCharge = pcat.proposals[proposalIndex].largePackageMixCharge;
        const advanceServiceCharge = pcat.proposals[proposalIndex].advanceServiceCharge;
        const totalDeliveryPackages = pcat.proposals[proposalIndex].totalDeliveryPackages(pcat).toNumber();

        return new BudgetAssumptions(vehicleBranding, uniforms, packageCharge, stopCharge, 
            eCommStopCharge, serviceCharge, perStopFuelSurcharge, largePackageMixDeliveryPercentage, largePackageMixCharge, advanceServiceCharge, totalDeliveryPackages);
    }

    static decode(json) {
        const decoder = new Decoder(json);
        const vehicleBranding = decoder.decode('vehicleBranding', Decoder.Decimal);
        const uniforms = decoder.decode('uniforms', Decoder.Decimal);
        const packageCharge = decoder.decode('packageCharge', Decoder.Decimal);
        const stopCharge = decoder.decode('stopCharge', Decoder.Decimal);
        const eCommStopCharge = decoder.decode('eCommStopCharge', Decoder.Decimal);
        const serviceCharge = decoder.decode('serviceCharge', Decoder.Decimal);
        const perStopFuelSurcharge = decoder.decode('perStopFuelSurcharge', Decoder.Decimal);
        const largePackageMixDeliveryPercentage = decoder.decode('largePackageMixDeliveryPercentage', Decoder.Decimal);
        const largePackageMixCharge = decoder.decode('largePackageMixCharge', Decoder.Decimal);
        const advanceServiceCharge = decoder.decode('advanceServiceCharge', Decoder.Decimal);
        const totalDeliveryPackages = decoder.decode('totalDeliveryPackages', Decoder.Integer);

        if (decoder.checkForErrors()) {
            return new BudgetAssumptions(vehicleBranding, uniforms, packageCharge, stopCharge, eCommStopCharge, serviceCharge, perStopFuelSurcharge, largePackageMixDeliveryPercentage, largePackageMixCharge, advanceServiceCharge, totalDeliveryPackages);
        } else {
            return BudgetAssumptions.initDefault();
        }
    }

    encode() {
        return {
            vehicleBranding: validateDecimalFixed(this.vehicleBranding, 2),
            uniforms: validateDecimalFixed(this.uniforms, 2),
            packageCharge: validateDecimalFixed(this.packageCharge, 2),
            stopCharge: validateDecimalFixed(this.stopCharge, 2),
            eCommStopCharge: validateDecimalFixed(this.eCommStopCharge, 2),
            serviceCharge: validateDecimalFixed(this.serviceCharge, 2),
            perStopFuelSurcharge: validateDecimalFixed(this.perStopFuelSurcharge, 2),
            largePackageMixDeliveryPercentage: validateDecimalFixed(this.largePackageMixDeliveryPercentage, 2),
            largePackageMixCharge: validateDecimalFixed(this.largePackageMixCharge, 2),
            advanceServiceCharge: validateDecimalFixed(this.advanceServiceCharge, 2),
            totalDeliveryPackages: validateInteger(this.totalDeliveryPackages),
        };
    }

    duplicate() {
        return new BudgetAssumptions(
            this.vehicleBranding,
            this.uniforms,
            this.packageCharge,
            this.stopCharge,
            this.eCommStopCharge,
            this.serviceCharge,
            this.perStopFuelSurcharge,
            this.largePackageMixDeliveryPercentage,
            this.largePackageMixCharge,
            this.advanceServiceCharge,
            this.totalDeliveryPackages
        );
    }

    equals(budgetAssumptions) {
        return this.vehicleBranding === budgetAssumptions.vehicleBranding &&
            this.uniforms === budgetAssumptions.uniforms &&
            this.packageCharge === budgetAssumptions.packageCharge &&
            this.stopCharge === budgetAssumptions.stopCharge &&
            this.eCommStopCharge === budgetAssumptions.eCommStopCharge &&
            this.serviceCharge === budgetAssumptions.serviceCharge &&
            this.perStopFuelSurcharge === budgetAssumptions.perStopFuelSurcharge &&
            this.largePackageMixDeliveryPercentage === budgetAssumptions.largePackageMixDeliveryPercentage &&
            this.largePackageMixCharge === budgetAssumptions.largePackageMixCharge &&
            this.advanceServiceCharge === budgetAssumptions.advanceServiceCharge &&
            this.totalDeliveryPackages === budgetAssumptions.totalDeliveryPackages
        ;
    }

}