/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useEffect, useReducer} from 'react';
import Form from 'react-bootstrap/Form';
import { faPlus, faTrash, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, InputGroup } from 'react-bootstrap';
import OptionalCol from './OptionalCol';
import { validateGreaterThanZero } from '../validation';

export default function AutomaticReimbursementControl({value, setValue, breakpoints}) {

    const handleSetAutomaticReimbursement = (index, key, newValue) => {
        const newAutomaticReimbursements = Array.from(value);
        newAutomaticReimbursements[index][key] = newValue;
        setValue(newAutomaticReimbursements);
    }

    const handleAddAutomaticReimbursement = () => {
        const newAutomaticReimbursements = Array.from(value);
        newAutomaticReimbursements.push({label: automaticReimbursementTypes[0], amount: ''});
        setValue(newAutomaticReimbursements);
    }

    const handleRemoveAutomaticReimbursement = (index) => {
        setValue(value.toSpliced(index, 1));
    }

    const automaticReimbursementTypes = [
        'Per Diem',
        'Expense Reimbursement',
        'Other (Flat Rate)',
        'Other (% of Gross)'
    ]

    const adElements = value.map((ad, index) => {
        const typeItems = automaticReimbursementTypes.map((item) => {
            return (
                <Dropdown.Item key={item} active={ad.label === item} onClick={() => {handleSetAutomaticReimbursement(index, 'label', item)}}>{item}</Dropdown.Item>
            )
        })

        return (
            <InputGroup key={index} style={{flexWrap: 'nowrap'}}>
                <Dropdown>
                    <Dropdown.Toggle variant='outline-primary' style={{display: 'flex', alignItems: 'center'}}>
                        <span style={{width: 100, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'block'}}>{ad.label ? ad.label : 'Select Type'}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{position: 'fixed'}}>
                        {typeItems}
                    </Dropdown.Menu>
                </Dropdown>
                <Form.Control type='number' placeholder='Amount' value={ad.amount} onChange={(event) => {handleSetAutomaticReimbursement(index, 'amount', event.target.value)}} style={{borderRadius: 0, minWidth: 85}}/>
                <Button variant='outline-primary'  onClick={() => {handleRemoveAutomaticReimbursement(index)}}>
                    <FontAwesomeIcon icon={faTrash}/>
                </Button>
            </InputGroup>

        )
    })

    return (
        <OptionalCol breakpoints={breakpoints}>
            <div style={{border: '1px solid lightgray', borderRadius: 6, padding: 12}}>
                <p style={{marginBottom: 4, fontSize: 14, opacity: 0.65}}>Automatic Reimbursements</p>
                <div style={{display: 'flex', flexWrap: 'wrap', gap: 12}}>
                    {adElements}
                    <Button variant='outline-primary' onClick={handleAddAutomaticReimbursement}><FontAwesomeIcon icon={faPlus}/></Button>
                </div>
            </div>
        </OptionalCol>
    )
}