/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import Button from 'react-bootstrap/Button';
import CustomSpinner from "../../../components/CustomSpinner";
import { ButtonGroup } from "react-bootstrap";
import PayrollCSADownloadModal from "../PayrollCSVDownloadModal";
import PayrollEntry from "../Models/PayrollEntry";
import PayrollPeriodTable from "./PayrollPeriodTable";
import PayrollPeriodPdf from "./PayrollPeriodPdf";
import { adminMode } from "../payrollTools";
import { ApiRequest } from "../../../ApiManager.tsx";

export default class PayrollPeriod extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            payrollData: [],
            approvedPayrollData: [],
            periodStart: undefined,
            periodEnd: undefined,
            tabSwitch: true,
            showModal: false,
            showPdf: false,
            isCustom: false
        }
    }

    sortEntries(a, b) {
        if (a.csaName < b.csaName) {
            return -1;
        } else if (a.csaName > b.csaName) {
            return 1;
        } else {
            if (a.isNew !== b.isNew) {
                if (a.isNew) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (a.name() < b.name()) {
                    return -1;
                } else if (a.name() > b.name()) {
                    return 1;
                } else {
                    return 0;
                }
            }
        }
    }

    loadData() {
        this.setState({isLoading: true});

        if (adminMode) {
            new ApiRequest('payroll', 'getPayrollPeriod', (value) => {this.setState({isLoading: value})}, (response) => {
                this.setState({
                    payrollData: response.data.entries ? PayrollEntry.decodeArray(response.data.entries,response.data.periodStart, response.data.periodEnd).sort(this.sortEntries) : undefined,
                    approvedPayrollData: response.data.approvedEntries ? PayrollEntry.decodeArray(response.data.approvedEntries, response.data.periodStart, response.data.periodEnd).sort(this.sortEntries) : undefined,
                    periodStart: response.data.periodStart, 
                    periodEnd: response.data.periodEnd,
                    isCustom: response.data.isCustom == '1',
                });
            }).withNoAlertOnSuccess().withUid(this.props.selectedPayPeriod.uid).send();
        } else {
            new ApiRequest('payroll', 'getPayrollPeriod', (value) => {this.setState({isLoading: value})}, (response) => {
                let entries = undefined;
                let approvedEntries = undefined;
    
                if (response.bcData && !response.data.entries && !response.data.approvedEntries) {
                    entries = PayrollEntry.decodeArray(response.bcData.entries, response.data.periodStart, response.data.periodEnd).sort(this.sortEntries);

                } else {
                    entries = response.data.entries ? PayrollEntry.decodeArray(response.data.entries,response.data.periodStart, response.data.periodEnd).sort(this.sortEntries) : undefined;
                    approvedEntries = response.data.approvedEntries ? PayrollEntry.decodeArray(response.data.approvedEntries,response.data.periodStart, response.data.periodEnd).sort(this.sortEntries) : undefined;
                }
    
                this.setState({
                    payrollData: entries,
                    approvedPayrollData: approvedEntries,
                    periodStart: response.data.periodStart, 
                    periodEnd: response.data.periodEnd,
                    isCustom: response.data.isCustom == '1'
                });
            }).withNoAlertOnSuccess().withUid(this.props.selectedPayPeriod.uid).send();
        }
        
    }

    componentDidMount() {
        this.loadData();
    }

    render() {

        const useTabbedView = this.state.approvedPayrollData !== undefined && this.state.payrollData !== undefined;
      
        const content = (
            <div style={{padding: 12, display: 'flex', flexDirection: 'column', gap: 12}}>
                { useTabbedView ?
                    <>
                        <ButtonGroup>
                            <Button style={{backgroundColor: this.state.tabSwitch ? 'white' : 'var(--bs-primary)', color: this.state.tabSwitch ? 'var(--bs-primary)' : 'white'}} onClick={() => {this.setState({tabSwitch: false})}}>Original IC Entries</Button>
                            <Button style={{backgroundColor: !this.state.tabSwitch ? 'white' : 'var(--bs-primary)', color: !this.state.tabSwitch ? 'var(--bs-primary)' : 'white'}} onClick={() => {this.setState({tabSwitch: true})}}>TTA-Approved Entries</Button>
                        </ButtonGroup>
                        {this.state.tabSwitch && 
                            <div style={{display:'flex', width:'20%'}}>
                                <span style={{backgroundColor:'yellow'}}>Updated From IC Entry</span>
                            </div>
                        }
                        <PayrollPeriodTable entries={this.state.tabSwitch ? this.state.approvedPayrollData : this.state.payrollData} originalEntries={this.state.tabSwitch ? this.state.payrollData : undefined} periodStart={this.state.periodStart} periodEnd={this.state.periodEnd}/> 
                    </>
                    :
                    <>
                        {this.state.approvedPayrollData &&
                            <>
                                <div style={{textAlign:'center'}}>
                                    <span style={{fontWeight:'bold', fontSize:18}}>TTA-Approved Payroll</span>
                                </div>
                                <PayrollPeriodTable entries={this.state.approvedPayrollData} originalEntries={undefined} periodStart={this.state.periodStart} periodEnd={this.state.periodEnd}/> 
                            </>
                        }
                        { this.state.payrollData &&
                            <>
                                <div style={{textAlign:'center'}}>
                                    <span style={{fontWeight:'bold', fontSize:18}}>IC-Approved Payroll</span>
                                </div>
                                <PayrollPeriodTable entries={this.state.payrollData} originalEntries={undefined} periodStart={this.state.periodStart} periodEnd={this.state.periodEnd}/> 
                            </>
                        }
                    </>
                }
            </div>
        );
       
        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>{(this.state.isCustom ? 'Custom ' : '') + 'Payroll for ' + this.props.companyName + ' Between ' + moment(this.state.periodStart).format('MMM D, YYYY') + ' - ' + moment(this.state.periodEnd).format('MMM D, YYYY')}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    {this.state.isLoading ? <CustomSpinner height={300}/> : content}
                </Modal.Body>
                <Modal.Footer>
                    { this.props.editPeriod &&
                        <Button variant={'outline-primary'} onClick={this.props.editPeriod}>Edit Period</Button>
                    }
                    <Button variant={'outline-primary'} onClick={() => {this.setState({showModal: true})}}>Download Payroll CSV</Button>
                    <Button variant={'outline-primary'} onClick={() => {this.setState({showPdf: true})}}>View Payroll PDF</Button>
                </Modal.Footer>
                <Modal show={this.state.showModal} backdrop='static' centered>
                    <PayrollCSADownloadModal 
                        entries={this.state.approvedPayrollData ? this.state.approvedPayrollData : this.state.payrollData} 
                        callBack={() => {this.setState({showModal: false})}}
                        periodStart={this.state.periodStart} 
                        periodEnd={this.state.periodEnd} 
                        companyName={this.props.companyName}
                    />
                </Modal>
                <Modal show={this.state.showPdf} onHide={()=>this.setState({showPdf:false})} fullscreen>
                    <PayrollPeriodPdf 
                        entries={this.state.approvedPayrollData ? this.state.approvedPayrollData : this.state.payrollData}
                        originalEntries={useTabbedView ? this.state.payrollData : undefined}
                        callBack={() => {this.setState({showModal: false})}}
                        periodStart={this.state.periodStart} 
                        periodEnd={this.state.periodEnd} 
                        companyName={this.props.companyName}
                    />
                </Modal>
            </>
        );
    }
}
