/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Table from 'react-bootstrap/Table';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft, faRoute} from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import Popover from "react-bootstrap/Popover";
import CalendarObject from './CalendarObject';
import Form from 'react-bootstrap/Form';
import { Button, OverlayTrigger } from 'react-bootstrap';
import List from '../../components/List/List';
import { formatTimeNew } from '../../tools';
import LoadingWrapper from '../../components/LoadingWrapper';
import { ApiRequest } from '../../ApiManager.tsx';

export default function MySchedule({}) {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [scheduleItems, setScheduleItems] = useState([]);
    const [routes, setRoutes] = useState([]);
    
    useEffect(() => {
        loadData()
    }, [selectedDate]);

    function loadData(){
        const startDate = moment(selectedDate).day() == 6 ? moment(selectedDate) : moment(selectedDate).startOf('week').subtract(1, 'day');
        const endDate = moment(startDate).add(5, 'weeks').add(6, 'days');

        new ApiRequest('mySchedule', 'getSchedule', setIsLoading, (response) => {setScheduleItems(response.scheduleItems); setRoutes(response.routes)}).withData({
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD') ,
        }).withNoAlertOnSuccess().send();
    }
        
    const startDate = moment(selectedDate).day() == 6 ? moment(selectedDate) : moment(selectedDate).startOf('week').subtract(1, 'day');
    const rows = [0, 1, 2, 3, 4, 5].map((rowIndex) => {
        const weekStart = moment(startDate).add(rowIndex, 'weeks');
        const columns = [0, 1, 2, 3, 4, 5, 6].map((colIndex) => {
            const date = moment(weekStart).add(colIndex, 'days');

            const scheduleItemsOnDay = scheduleItems.filter((entry) => {
                if (entry.endDate) {
                    return entry.date === date.format('YYYY-MM-DD') || date.isBetween(moment(entry.date), moment(entry.endDate), 'day', '[]');
                } else {
                    return entry.date === date.format('YYYY-MM-DD');
                }
            });

            const routesOnDay = routes.filter(r => r.date === date.format('YYYY-MM-DD'));

            let calendarObjects = scheduleItemsOnDay.map((data) => {
                return <CalendarObject key={data.uid} popup={rowIndex > 2} object={data}/>;
                
            });
            
            calendarObjects = calendarObjects.filter((obj) => {
                return obj !== '';
            })

            const routeElements = routesOnDay.map((r) => {
                return (
                    <div key={r.uid} className="cursor-pointer" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 4, backgroundColor: '#ffa496', borderRadius: 6}}>
                        <OverlayTrigger overlay={
                            <Popover style={{position:'fixed'}}>
                                <Popover.Body>
                                    <List responsive={true} items={[
                                            ['Role', r.role],
                                            ['Work Area', r.workArea.workAreaName],
                                            ['Vehicle', r.vehicle?.vehicleName ?? 'TBD'],
                                            ['Time Scheduled', formatTimeNew(r.timeScheduled)],
                                            ['Driver-Generated?', r.driverGenerated ? 'Yes' : 'No'],
                                            ['Notes', r?.notes ?? '']
                                        ]} 
                                    />
                                </Popover.Body>
                            </Popover>
                        }>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', paddingLeft: 6, paddingRight: 6}}>
                                <p style={{margin: 0, color: 'black', opacity: 0.7, fontSize: '1.1vmax'}}>Route</p>
                                <FontAwesomeIcon icon={faRoute} style={{height: '1.1vmax', color: 'black', opacity: 0.5}}/>
                            </div>
                        </OverlayTrigger>
                    </div>
                )
            })

            return (
                <td key={colIndex} style={{padding: 0}} className={'calendar-td' + (date.format('MMM D') === moment().format('MMM D') ? ' calendar-td-selected' : '') + (date.format('MMM') !== moment(selectedDate).format('MMM') ? ' calendar-td-off-month' : '')}>
                    <div style={{display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-between', padding: 4, backgroundColor: date.format('MMM D') === moment().format('MMM D') ? 'rgba(238, 255, 0, 0.3)' : ''}}>
                        <p style={{alignSelf: 'flex-end', fontSize: '1.5vmax', marginBottom: 0}}>{date.format('D')}</p>
                        <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                            {routeElements}
                            {calendarObjects}
                        </div>
                    </div>
                </td>
            );
        });

        return (
            <tr key={rowIndex} style={{height: '16.66%'}}>
                {columns}
            </tr>
        );
    });

    const columnHeaders = ['Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri'].map((str) => {
        return (<th key={str} className='calendar-th' style={{fontSize: '1vmax'}}>{str}</th>);
    });

    const content = (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', width: '100%'}}>
            <div style={{display: 'flex', flexDirection: 'row', margin: 8}}>
                <Button variant='outline-primary' onClick={() => setSelectedDate(moment(selectedDate).subtract(1, 'month').format('YYYY-MM-DD'))}>
                    <FontAwesomeIcon icon={faArrowLeft}/>
                </Button>
                <Form.Control style={{marginRight: 12, marginLeft: 12}} type='month' value={moment(selectedDate).format('YYYY-MM')} onChange={(event) => {setSelectedDate(moment(event.target.value).startOf('month').format('YYYY-MM-DD'))}}/>
                
                <Button variant='outline-primary' onClick={() => setSelectedDate(moment(selectedDate).add(1, 'month').format('YYYY-MM-DD'))}>
                    <FontAwesomeIcon icon={faArrowRight}/>
                </Button>
            </div>
            <div style={{width: '100%', flex: 1}}>
                <Table bordered size='sm' style={{width: '100%', height: '100%'}}>
                    <thead>
                        <tr>
                            {columnHeaders}
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </Table>
            </div>
        </div>
    )

    return (
        <LoadingWrapper isLoading={isLoading}>
            {content}
        </LoadingWrapper>
    );
    
}