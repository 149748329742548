import React, { useState } from "react"
import { Modal} from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import CustomControl from "../../components/CustomControl"
import { ValidationGroup, validatePhoneNumber } from "../../validation"
import CustomButton from "../../components/CustomButton"
import { ApiRequest } from "../../ApiManager.tsx"

export default function ForgotPassword({hideModal}){
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const validationGroup = new ValidationGroup();

    function handleRequestPasswordReset(){
        new ApiRequest('passwordReset', 'requestReset', setIsLoading, () => hideModal()).withData({phoneNumber}).send();
    }
    
    return(
        <>
            <Modal.Header closeButton>
                <Modal.Title>Forgot Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <CustomControl title='Phone Number' maxLength={10} validator={validationGroup.createValidator(() => validatePhoneNumber(phoneNumber))} value={phoneNumber} onChange={(event) => {setPhoneNumber(event.target.value)}}/>
            </Modal.Body>
            <Modal.Footer>
                <div style={{display: 'flex', alignItems: 'center', gap: 12, color: 'gray'}}>
                    <FontAwesomeIcon icon={faInfoCircle}/>
                    <span>After submitting you will receive a text with a link to reset your password</span>
                </div>
                <CustomButton label='Submit' isLoading={isLoading} disabled={!validationGroup.isValid()} variant={'outline-primary'} onClick={()=>handleRequestPasswordReset()}/>
            </Modal.Footer>
        </>
    )
}