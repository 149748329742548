/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Document, Page, pdfjs} from 'react-pdf';
import PageSpinner from './PageSpinner.js';
import { ApiRequest } from '../ApiManager.tsx';

export default function DocumentPreview({ document, hr, company, myDocument}) {
    const [url, setUrl] = useState('');
    const [base64, setBase64] = useState(undefined);
    const [numPages, setNumPages] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const options = {
        cMapUrl: 'cmaps/',
        cMapPacked: true,
        standardFontDataUrl: 'standard_fonts/', 
    };
    
      
    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
    }

    useEffect(()=>{
        loadData();
    }, [])

    function loadData(){
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        if(hr){
            new ApiRequest('hr', 'downloadCustomDocument', setIsLoading, async (response) =>  {
            const fetchResponse = await fetch(response.base64);
            const blob = await fetchResponse.blob();
            setUrl(URL.createObjectURL(blob));
            setBase64(response.base64);
            }).withUid(document.uid).withNoAlertOnSuccess().send();
        } else if (company){
            new ApiRequest('myCompany', 'downloadCompanyDocument', setIsLoading, async (response) =>  {
            const fetchResponse = await fetch(response.base64);
            const blob = await fetchResponse.blob();
            setUrl(URL.createObjectURL(blob));
            setBase64(response.base64);
            }).withUid(document.uid).withNoAlertOnSuccess().send();
        } else if (myDocument){
            new ApiRequest('myDocuments', 'downloadAcknowledgedDocument', setIsLoading, async (response) =>  {
            const fetchResponse = await fetch(response.base64);
            const blob = await fetchResponse.blob();
            setUrl(URL.createObjectURL(blob));
            setBase64(response.base64);
            }).withUid(document.uid).withNoAlertOnSuccess().send();
        }
  }

    let documentToReturn = '';
    if(base64 && document.documentType === 'pdf'){
        documentToReturn = (
        <Document file={base64} onLoadSuccess={onDocumentLoadSuccess} options={options} renderMode='canvas'>
            {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
        </Document> 
        )
    } else if (base64 && (document.documentType === 'png' || document.documentType === 'jpeg' || document.documentType === 'jpg')){
        documentToReturn = (
        <div style={{width: '100%', borderRadius: 6}}>
            <img src={base64} style={{objectFit: 'contain', width: '100%', aspectRatio: 1}}/>
        </div>
        )
    }
  
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{'Document Name: ' + document.documentName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{overflowY:'scroll', display:'flex', justifyContent:'center'}}>
                {base64 ? 
                    documentToReturn
                :
                <PageSpinner/>
                }
                </div>
            </Modal.Body>
        </>
    );
}
