/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { usdFormatter, validateDecimal, validateInteger } from "../../tools";

export default function PCATField({value, setValue, originalValue, readOnly, type, min, max, maxLength, intOnly, usdOnBlur, percentOnBlur, commasOnBlur, fontSize, roundOnBlur}) {
    const [isFocused, setIsFocused] = useState(false);

    const onBlur = () => {
        setIsFocused(false);

        if (min !== undefined && validateDecimal(value) < min) {
            setValue(min);
        } else if (max !== undefined && validateDecimal(value) > max) {
            setValue(max);
        } else if (intOnly) {
            setValue(validateInteger(value));
        }
    }

    const getDisplayValue = () => {
        if (!isFocused) {
            if(roundOnBlur){
                value = Math.round(validateDecimal(value));
            }

            if (usdOnBlur) {
                return usdFormatter.format(validateDecimal(value));
            } else if (percentOnBlur) {
                return `${validateDecimal(validateDecimal(value).toFixed(3))}%`
            } else if (commasOnBlur) {
                return validateDecimal(value).toLocaleString();
            }
        }
        return value;
    }

    const getType = () => {
        return (usdOnBlur || percentOnBlur || commasOnBlur) && !isFocused ? undefined : type;
    }

    const highlight = () => {
        return originalValue !== null && originalValue !== undefined && originalValue != value;
    }

    return (
        <Form.Control min={min} max={max} maxLength={maxLength} onBlur={onBlur} onFocus={() => {setIsFocused(true)}} style={{padding: '0px 6px 0px 6px', fontSize: fontSize, backgroundColor: highlight() ? '#faffe3' : undefined}} type={getType()} value={getDisplayValue()} onChange={setValue ? (event) => {setValue(event.target.value)} : undefined} readOnly={readOnly} disabled={readOnly}/>
    )
}