/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useRef, useState } from 'react';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import { ApiRequest } from '../../../ApiManager.tsx';
import { validateDecimal, validateInteger } from '../../../tools.js';
import CustomButton from '../../../components/CustomButton.js';
import WeeklyServiceWorksheetMatch from './WeeklyServiceWorksheetMatch.js';

export default function WeeklyServiceWorksheetUpload({createWswCallback, matchWswNamesCallback}) {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const fileRef = useRef();
    const [wswIsValid, swtWswIsValid] = useState(false);
    const [overwrite, setOverwrite] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [unmatchedCsaFedexIds, setUnmatchedCsaFedexIds] = useState([]);
    const [unmatchedWswNames, setUnmatchedWswNames] = useState([]);
    const [weeklyServiceWorksheets, setWeeklyServiceWorksheets] = useState(null);
    const [startDate, setStartDate] = useState(null)


    function handleSubmit(){
        new ApiRequest('wsw', 'createWeeklyServiceWorksheet', setIsSubmitting, (response) => {
            if(response.unmatchedWswNames?.length > 0 || response.unmatchedCsaFedexIds?.length > 0){
                if(response.unmatchedCsaFedexIds?.length > 0){
                    setUnmatchedCsaFedexIds(response.unmatchedCsaFedexIds);
                }
                if(response.unmatchedWswNames?.length > 0){
                    setUnmatchedWswNames(response.unmatchedWswNames);
                }
                setShowModal(true)
            }
            if (createWswCallback) {
                Object.entries(weeklyServiceWorksheets).forEach(([key, value]) => {
                    const wsw = {
                        uid: response['uids'][key],
                        csaName: response['csaNames'][key],
                        csaFedexId: key,
                        startDate: startDate,
                        wswRows: value,
                    };
                    createWswCallback(wsw);
            })
            }
        }).withData({weeklyServiceWorksheets: weeklyServiceWorksheets, startDate: startDate, overwrite: overwrite}).send()
    }

    async function handleFileChange() {
        swtWswIsValid(false);
        const file = fileRef.current.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onerror = () => {console.log('Something went wrong')}
            reader.onload = () => {
                try {
                    let str = reader.result;

                    let isOn = false;
                    for (let i = 0; i < str.length; i++) {
                        if (str.substring(i, i + 1) == "\"") {
                            isOn = !isOn;
                        } else if (str[i] == ',' && isOn) {
                            str = `${str.substring(0, i)}?${str.substring(i + 1)}`
                        }
                    }
                    
                    str = str.replaceAll('\"', '');
                    const rows = str.split(/\r\n|\r|\n/);

                    const startDateComponents = rows[0].split('-')[4].trim().substring(0, 10).split('/');
                    const startDate = `${startDateComponents[2]}-${startDateComponents[0]}-${startDateComponents[1]}`;
                    const wswRows = [];

                    for (let rowPointer = 1; rowPointer < rows.length - 2; rowPointer++) {
                        const row = rows[rowPointer].split(',').map(r => r.replaceAll('?', ','));

                        if (row[0] != 'Date' && !!row[0] && !!row[2]) {
                            const wswRow = {};
                            const date = `${row[0].substring(0, 4)}-${row[0].substring(4, 6)}-${row[0].substring(6, 8)}`;
                            
                            wswRow.dayIndex = moment(date).diff(moment(startDate), 'days', false);
                            wswRow.workAreaName = row[1];
                            wswRow.csaFedexId = row[2];
                            wswRow.vehicleFedexId = row[3] ? row[3] : null;
                            wswRow.wswName = row[4] ? row[4] : null;
                            wswRow.workAreaFedexId = row[5];
                            wswRow.preloadVScanPackages = validateInteger(row[6]);
                            wswRow.preloadDlStops = validateInteger(row[7]);
                            wswRow.preloadPuStops = validateInteger(row[8]);
                            wswRow.actualDlStops = validateInteger(row[10]);
                            wswRow.actualDlPackages = validateInteger(row[11]);
                            wswRow.actualPuStops = validateInteger(row[12]);
                            wswRow.actualPuPackages = validateInteger(row[13]);
                            wswRow.ils = validateDecimal(row[14]);
                            wswRow.ilsImpactPackages = validateInteger(row[15]);
                            wswRow.code85 = validateInteger(row[17]);
                            wswRow.allStatusCodePackages = validateInteger(row[18]);
                            wswRow.plml = validateInteger(row[19]);
                            wswRow.dna = validateInteger(row[20]);
                            wswRow.sndAgn = validateInteger(row[21]);
                            wswRow.excs = validateInteger(row[22]);
                            wswRow.vsaVsStar = validateInteger(row[23]);
                            wswRow.returnsScans = validateDecimal(row[24].substring(0, row[24].length - 1));
                            wswRow.miles = validateInteger(row[25]);
                            wswRow.orHours = row[26] ? row[26] : null;
                            wswRow.odHours = row[27] ? row[27] : null;
                            wswRow.potDotHrsViols = row[28] ? row[28] : null;
                            wswRow.nextAvailOnDuty = row[29] ? row[29] : null;
                            wswRow.potMissPus = row[30] ? row[30] : null;
                            wswRow.reqSig = validateInteger(row[32]);
                            wswRows.push(wswRow);
                        }
                    }

                    const wsws = {};
                    wswRows.forEach((el) => {
                        if(!wsws[el.csaFedexId]){
                            wsws[el.csaFedexId] = [el]
                        }else{
                            wsws[el.csaFedexId].push(el);
                        }
                    });

                    setWeeklyServiceWorksheets(wsws)
                    setStartDate(startDate)
                    swtWswIsValid(true)
                } catch (error) {
                    console.log(error);
                    toast.error('There seems to be something wrong with the csv you have uploaded. Are you sure that the file which was uploaded is a weekly service sheet?')
                }
            }
            reader.readAsText(file);
        }
    }

    return (
        <>
            <InputGroup style={{alignItems: 'center'}}>
                <Form.Control type='file' ref={fileRef} accept=".csv" onChange={handleFileChange}/>
                <InputGroup.Text style={{height: 38}}>
                    <Form.Check type='switch' label='Overwrite' checked={overwrite} onChange={()=> setOverwrite((cur) => !cur)}/>
                </InputGroup.Text>
                <CustomButton isLoading={isSubmitting} disabled={!wswIsValid} onClick={handleSubmit} label={'Submit'}/>
            </InputGroup>
            <Modal centered size="xl" fullscreen='lg-down' backdrop='static' show={showModal} onHide={() => {setShowModal(false); setUnmatchedWswNames([]); setUnmatchedCsaFedexIds([])}}>
                <WeeklyServiceWorksheetMatch unmatchedWswNames={unmatchedWswNames} unmatchedCsaFedexIds={unmatchedCsaFedexIds} setUnmatchedWswNames={setUnmatchedWswNames} setUnmatchedCsaFedexIds={setUnmatchedCsaFedexIds} matchWswNamesCallback={matchWswNamesCallback} hideModal={() => {setShowModal(false); setUnmatchedWswNames([]); setUnmatchedCsaFedexIds(false)}}/>
            </Modal>
        </>
    )
}