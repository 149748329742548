/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Big from "big.js";
import Decoder from "../../../decoding";
import { validateDecimal, validateInteger, validateBig } from "../payrollTools";
import NDBonus from "./NDBonus";
import Pay from "./Pay";
import AdditionalPay from "./AdditionalPay";
import Pto from "./Pto";
import Holiday from "./Holiday";

export default class PayrollEntryWeek {

    daysWorked; //int
    hoursWorked; //decimal
    pay; //Pay[]
    ndBonuses; //NDBonus[]
    under10k; //boolean
    hourlyWage; //decimal
    additionalPay;
    ptoArray; //[Pto]
    holidayArray; //[Holiday]
    otStopWageDefinitions;
    stops;

    constructor(daysWorked, hoursWorked, pay, ndBonuses, under10k, hourlyWage, additionalPay, ptoArray, holidayArray, otStopWageDefinitions, stops) {
        this.daysWorked = daysWorked;
        this.hoursWorked = hoursWorked;
        this.pay = pay;
        this.ndBonuses = ndBonuses;
        this.under10k = under10k;
        this.hourlyWage = hourlyWage;
        this.additionalPay = additionalPay;
        this.ptoArray = ptoArray;
        this.holidayArray = holidayArray;
        this.otStopWageDefinitions = otStopWageDefinitions;
        this.stops = stops;
    }

    static initDefault() {
        return new PayrollEntryWeek(0, 0.0, [], [], false, 0.0, [], [], [], [{}, {}, {}, {}, {}, {}, {}], [null, null, null, null, null, null, null]);
    }

    static decodeFromEntry(json, periodStart, periodEnd, hourlyWage) {
        const decoder = new Decoder(json);
        const array = decoder.decode('weeks', Decoder.Array, {defaultValue: undefined, warn: false});
        if (array) { //if encoded entry has weeks
            return array.map((weekJson, index) => {
                if (weekJson.additionalPay && weekJson.ptoArray && weekJson.holidayArray) { //if week has additionalPay, ptoArray, holidayArray properties
                    return PayrollEntryWeek.decode(weekJson);
                } else if (index === 0) {
                    const entryAdditionalPay = decoder.decode('additionalPay', Decoder.Array, {defaultValue: [], warn: true});
                    weekJson.additionalPay = entryAdditionalPay.filter(eap => AdditionalPay.additionalPayTypes.includes(eap.type));
                    weekJson.holidayArray = Holiday.decodeFromEntry(json, hourlyWage);
                    weekJson.ptoArray = Pto.decodeFromEntry(json, hourlyWage);
                    
                    return PayrollEntryWeek.decode(weekJson);
                } else {
                    weekJson.additionalPay = [];
                    weekJson.holidayArray = [];
                    weekJson.ptoArray = [];
                    return PayrollEntryWeek.decode(weekJson);
                }
            });
        } else {
            const daysWorked = decoder.decode('daysWorked', Decoder.Integer, {defaultValue: 0, warn: false});
            const hoursWorked = decoder.decode('hoursWorked', Decoder.Decimal, {defaultValue: 0, warn: false});
            const pay = Pay.decodeFromEntry(json, periodStart, periodEnd);
            const ndBonuses = NDBonus.decodeFromEntry(json);
            const under10k = decoder.decode('under10k', Decoder.Boolean);
            const hourlyWage = decoder.decode('hourlyWage', Decoder.Decimal);

            if (decoder.checkForErrors()) {
                return [new PayrollEntryWeek(daysWorked, hoursWorked, pay, ndBonuses, under10k, hourlyWage, [], [], [], [{}, {}, {}, {}, {}, {}, {}], [null, null, null, null, null, null, null])];
            } else {
                return [];
            }

        }
    }

    static decode(json) {
        const decoder = new Decoder(json);
        const daysWorked = decoder.decode('daysWorked', Decoder.Integer);
        const hoursWorked = decoder.decode('hoursWorked', Decoder.Decimal);
        const pay = (decoder.decode('pay', Decoder.Array) ?? []).map(p => Pay.decode(p));
        const ndBonuses = (decoder.decode('ndBonuses', Decoder.Array) ?? []).filter(b => NDBonus.ndBonusTypes.includes(b.type)).map(b => NDBonus.decode(b));
        const under10k = decoder.decode('under10k', Decoder.Boolean);
        const hourlyWage = decoder.decode('hourlyWage', Decoder.Decimal);
        const additionalPay = (decoder.decode('additionalPay', Decoder.Array ?? []).map(ap => AdditionalPay.decode(ap)));
        const ptoArray = (decoder.decode('ptoArray', Decoder.Array ?? []).map(pto => Pto.decode(pto)));
        const holidayArray = (decoder.decode('holidayArray', Decoder.Array ?? []).map(holiday => Holiday.decode(holiday)));
        const otStopWageDefinitions = decoder.decode('otStopWageDefinitions', Decoder.Array, {defaultValue: [{}, {}, {}, {}, {}, {}, {}], warn: false});
        const stops = decoder.decode('stops', Decoder.Array, {defaultValue: [0, 0, 0, 0, 0, 0, 0], warn: false});
        if (decoder.checkForErrors()) {
            return new PayrollEntryWeek(daysWorked, hoursWorked, pay, ndBonuses, under10k, hourlyWage, additionalPay, ptoArray, holidayArray, otStopWageDefinitions, stops);
        } else {
            return PayrollEntryWeek.initDefault();
        }
    }

    encode() {
        return {
            daysWorked: validateInteger(this.daysWorked),
            hoursWorked: validateDecimal(this.hoursWorked),
            pay: this.pay.map(p => p.encode()),
            ndBonuses: this.ndBonuses.map(b => b.encode()),
            under10k: this.under10k,
            hourlyWage: validateDecimal(this.hourlyWage), 
            additionalPay: this.additionalPay.map(ap => ap.encode()),
            ptoArray: this.ptoArray.map(pto => pto.encode()),
            holidayArray: this.holidayArray.map(holiday => holiday.encode()),
            otStopWageDefinitions: this.otStopWageDefinitions,
            stops: this.stops
        }
    }
    
    qualifiesForFLSA() {
        let hoursForCalc;
        if (this.pay.find(p => p.payType === 'ph')) {
            hoursForCalc = this.pay.filter((p) => p.payType === 'ph').reduce((prev, curr) => prev + validateDecimal(curr.unitsWorked), 0);
        } else {
            hoursForCalc = validateDecimal(this.hoursWorked);
        }
        let qualifies = validateDecimal(hoursForCalc) > 40 && this.under10k;
        const yearlyPay = this.pay.find(p => p.payType === 'py');
        if (yearlyPay) {
            qualifies = qualifies && validateDecimal(yearlyPay.payRate) <= 35568;
        }
        return qualifies;
    }

    // salary() {
    //     return !this.qualifiesForFLSA() && this.pay[0]?.payType === 'py' ? validateBig(this.pay[0].payRate) : new Big('0.0');
    // }
    
    // dailyRate() {
    //     return !this.qualifiesForFLSA() && this.pay[0]?.payType === 'pd' ? validateBig(this.pay[0].payRate) : new Big('0.0');
    // }
    // getDaysWorked() {
    //     if (!this.qualifiesForFLSA() && this.pay[0]?.payType === 'pd') {
    //         return validateInteger(this.pay[0].unitsWorked);
    //     } else {
    //         return validateInteger(this.daysWorked);
    //     }
    // }
    
    hourlyRate() {
        if (this.qualifiesForFLSA()) {
            return validateBig(this.hourlyWage);
        } else {
            return this.pay[0]?.payType === 'ph' ? validateBig(this.pay[0].payRate) : new Big('0.0');
        }
    }

    getHoursWorked() {
        if (this.qualifiesForFLSA() && this.pay.find(p => p.payType === 'ph')) {
            return this.pay.filter((p)=>p.payType === 'ph').reduce((prev, curr)=>prev + validateDecimal(curr.unitsWorked), 0.0);
        } else if (this.pay[0]?.payType === 'ph') {
            return validateDecimal(this.pay[0].unitsWorked);
        } else {
            return validateDecimal(this.hoursWorked);
        }
    }
    
    overtimeRate() {
        if (this.qualifiesForFLSA()) {
            return (validateBig(this.hourlyRate()).plus(this.ndBonusesIncorporatedIntoOvertimeRate().div(validateBig(this.getHoursWorked())))).div(2.0).add(validateBig(this.hourlyRate()));
        } else {
            return new Big('0.0');
        }
    }

    ndBonusesIncorporatedIntoOvertimeRate() {
        return this.getNDBonuses().plus(this.totalOTStopWages()).plus(this.totalIncentiveWages()).plus(this.totalStandByWages());
    }
    
    adjustedHourlyRate() {
        return this.qualifiesForFLSA() ? this.overtimeRate().div(1.5) : new Big('0.0');
    }
    
    // stopPayRate() {
    //     return !this.qualifiesForFLSA() && this.pay[0]?.payType === 'ps' ? validateBig(this.pay[0].payRate) : new Big('0.0');
    // }
    
    // milePayRate() {
    //     return !this.qualifiesForFLSA() && this.pay[0]?.payType === 'pm' ? validateBig(this.pay[0].payRate) : new Big('0.0');
    // }

    // extraDayPayRate() {
    //     return !this.qualifiesForFLSA() && this.pay[0]?.payType === 'xd' ? validateBig(this.pay[0].payRate) : new Big('0.0');
    // }
    
    straightTimeHoursWorked() {
        return this.qualifiesForFLSA() ? validateBig('40') : validateBig(this.getHoursWorked());
    }
    overtimeHoursWorked() {
        return this.qualifiesForFLSA() ? validateBig(this.getHoursWorked()).minus('40.00') : new Big('0.0');
    }
    // stops() {
    //     return !this.qualifiesForFLSA() && this.pay[0]?.payType === 'ps' ? validateInteger(this.pay[0].unitsWorked) : 0;
    // }
    // miles() {
    //     return !this.qualifiesForFLSA() && this.pay[0]?.payType === 'pm' ? validateDecimal(this.pay[0].unitsWorked) : 0.0;
    // }
    // extraDays() {
    //     return !this.qualifiesForFLSA() && this.pay[0]?.payType === 'xd' ? validateInteger(this.pay[0].unitsWorked) : 0;
    // }


    regularWages() {
        if (this.qualifiesForFLSA()) {
            return new Big('0.0');
        } else {
            return this.pay.filter(p => p.payType === 'pd' || p.payType === 'py').reduce((prev, curr) => {return prev.plus(curr.getWages())}, new Big('0.0'));
        }
    }
    
    hourlyWages() {
        if (this.qualifiesForFLSA()) {
            return this.hourlyRate().times(this.straightTimeHoursWorked());
        } else {
            return this.pay.filter(p => p.payType === 'ph').reduce((prev, curr) => {return prev.plus(curr.getWages())}, new Big('0.0'));
        }
    }
    
    overtimeWages() {
        return this.overtimeRate().times(this.overtimeHoursWorked());
    }
    
    stopWages() {
        if (this.qualifiesForFLSA()) {
            return new Big('0.0');
        } else {
            return this.pay.filter(p => p.payType === 'ps').reduce((prev, curr) => {return prev.plus(curr.getWages())}, new Big('0.0'));
        }
    }
    
    mileWages() {
        if (this.qualifiesForFLSA()) {
            return new Big('0.0');
        } else {
            return this.pay.filter(p => p.payType === 'pm').reduce((prev, curr) => {return prev.plus(curr.getWages())}, new Big('0.0'));
        }
    }

    extraDayWages() {
        if (this.qualifiesForFLSA()) {
            return new Big('0.0');
        } else {
            return this.pay.filter(p => p.payType === 'xd').reduce((prev, curr) => {return prev.plus(curr.getWages())}, new Big('0.0'));
        }
    }

    totalNdBonusesByType() {
        const totals = {};

        NDBonus.ndBonusTypes.forEach((type) => {
            totals[type] = this.ndBonuses.filter(b => b.type === type).reduce((prev, curr) => {
                return prev + curr.getAmount();
            }, 0.0);
        })
        return totals;
    }
    
    getNDBonuses() {
        return this.ndBonuses.reduce((prev, curr) => {
            return prev.plus(validateBig(curr.getAmount()));
        }, new Big('0.0'));
    }

    getAdditionalPay() {
        return this.additionalPay.reduce((prev, curr) => {
            return prev.plus(validateBig(curr.getAmount()));
        }, new Big('0.0'));
    }

    pto() {
        return this.ptoArray.reduce((prev, curr) => { return prev + validateInteger(curr.hours) }, 0);
    }

    totalPtoWages() {
        const ptoWages = this.ptoArray.reduce((prev, curr)=>{
            return prev.plus(curr.getPtoPay());
        }, new Big('0.00'));
        return ptoWages;
    }
    
    totalHolidayWages() {
        const holidayWages = this.holidayArray.reduce((prev,curr)=>{
            return prev.plus(curr.holidayPay());
        }, new Big('0.00'));
        return holidayWages;
    }

    getTotalPayWages() {
        return this.pay.reduce((prev, curr) => {
            if (['iw', 'sw', 'ot'].includes(curr.payType)) {
                return prev;
            }
            return prev.plus(curr.getWages());
        }, new Big('0.00'));
    }

    totalNdBonusOtStopWages() {
        return this.ndBonuses.filter(b => b.type === 'Over-Threshold Stop Wage').reduce((prev, curr) => {
            return prev.plus(new Big(curr.getAmount()));
        }, new Big(0))
    }

    totalOTStopWages() {
        return [0, 1, 2, 3, 4, 5, 6].reduce((wages, dayIndex) => {
            if (validateInteger(this.otStopWageDefinitions[dayIndex]?.threshold) > 0 
                && validateDecimal(this.otStopWageDefinitions[dayIndex]?.amount) > 0 
                && validateInteger(this.stops[dayIndex]) > validateInteger(this.otStopWageDefinitions[dayIndex]?.threshold)
            ) {
                return wages.plus(validateBig(validateInteger(this.stops[dayIndex]) - validateInteger(this.otStopWageDefinitions[dayIndex].threshold)).times(validateBig(this.otStopWageDefinitions[dayIndex].amount)));
            } else {
                return wages;
            }
        }, new Big(0));
    }

    totalStopAndOTStopWages() { // for payroll spreadsheet only
        const totalOtStopWages = this.totalOTStopWages().plus(this.totalNdBonusOtStopWages());

        return this.qualifiesForFLSA() ? totalOtStopWages : totalOtStopWages.plus(this.pay.filter(p => p.payType === 'ps').reduce((prev, curr) => {
            return prev.plus(curr.getWages());
        }, new Big('0.00')));
    }

    totalIncentiveWages() {
        return this.pay.filter(p => p.payType === 'iw').reduce((prev, curr) => {
            return prev.plus(curr.getWages());
        }, new Big('0.00'));
    }

    totalStandByWages() {
        return this.pay.filter(p => p.payType === 'sw').reduce((prev, curr) => {
            return prev.plus(curr.getWages());
        }, new Big('0.00'));
    }

    totalAutoOvertimeWages() {
        return this.pay.filter(p => p.payType === 'ot').reduce((prev, curr) => {
            return prev.plus(curr.getWages());
        }, new Big('0.00'));
    }

    
    getSubGross() {
        let subGross = this.getNDBonuses().plus(this.getAdditionalPay()).plus(this.totalOTStopWages()).plus(this.totalIncentiveWages()).plus(this.totalStandByWages()).plus(this.totalAutoOvertimeWages());

        if (this.qualifiesForFLSA()) {
            subGross = subGross.plus(this.hourlyWages()).plus(this.overtimeWages());
        } else {
            subGross = subGross.plus(this.getTotalPayWages());
        }
        return subGross;
    }

    getOvertimePayWages() {
       let subGross = this.hourlyWages().plus(this.overtimeWages());
        return subGross;
    }

    getColumnInclusion() {

        const includeOvertimeColumns = this.qualifiesForFLSA();
        const includeSalary = !includeOvertimeColumns && this.pay.find(pay => pay.payType === 'py') !== undefined;
        const includeDaily = !includeOvertimeColumns && this.pay.find(pay => pay.payType === 'pd') !== undefined;        

        return {
            includeSalary: includeSalary,
            includeDaily: includeDaily,
            includeOvertimeColumns: includeOvertimeColumns,
            includeHourly: includeOvertimeColumns || this.pay.find(pay => pay.payType === 'ph') !== undefined,
            includeStop: !includeOvertimeColumns && this.pay.find(pay => pay.payType === 'ps') !== undefined,
            includeOTStopWages: this.totalOTStopWages().plus(this.totalNdBonusOtStopWages()).toNumber() > 0,
            includeMile: !includeOvertimeColumns && this.pay.find(pay => pay.payType === 'pm') !== undefined,
            includeExtraDay: !includeOvertimeColumns && this.pay.find(pay => pay.payType === 'xd') !== undefined,
            includeIncentiveWages: this.pay.find(pay => pay.payType === 'iw') !== undefined,
            includeStandByWages: this.pay.find(pay => pay.payType === 'sw') !== undefined,
            includeAutoOvertime: this.pay.find(pay => pay.payType === 'ot') !== undefined,
            includeRegularWages: includeSalary || includeDaily,
            includePto: this.ptoArray.find(p => p.hours > 0),
            includeHolidayWages: this.holidayArray.find(h => h.holidayPay().toNumber() !== 0.00),
        }
    }

    getNDBonusColumnInclusion() {
        const inclusion = new Set();
        NDBonus.ndBonusTypes.forEach((type) => {
            if (type !== "Over-Threshold Stop Wage" && this.ndBonuses.find(b => b.type === type)) {
                inclusion.add(type);
            }
        });
        return inclusion;
    }
    
}