/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import moment from "moment";
import { Form, Table } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DaysOffRequestModal from "./DaysOffRequestModal";
import { dateIsInRange, dateRangeToString } from "../../tools";
import DateRangeSelector from "../../components/DateRangeSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faEllipsis, faMinusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import CustomButton from "../../components/CustomButton";
import { ApiRequest } from "../../ApiManager.tsx";
import LoadingWrapper from "../../components/LoadingWrapper";

export default function DaysOff(){
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [selectedScheduleItem, setSelectedScheduleItem] = useState(null);
    const [requestOffHistory, setRequestOffHistory] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDateRangeFilter, setShowDateRangeFilter] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [deleteString, setDeleteString] = useState('');

    useEffect(()=>{
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('mySchedule', 'getHistory', setIsLoading, (response) => setRequestOffHistory(response.requestOffHistory)).withNoAlertOnSuccess().send();
    }

    function handleDeleteScheduleItem(uid){
        new ApiRequest('mySchedule', 'deleteScheduleItem', setIsDeleting, ()=>{
            setRequestOffHistory(requestOffHistory.filter(item=>item.uid != selectedScheduleItem.uid));
            setShowDeleteModal(false);
            setDeleteString('');
        }).withUid(uid).send()
    }

    function handleSetDateRange(startDate, endDate){
        setStartDate(startDate);
        setEndDate(endDate);
    }

    const filteredRequestsOff =  requestOffHistory.filter((request)=>{
        return dateIsInRange(request.submissionDate, startDate, endDate);
     });

     const previousRequests = filteredRequestsOff.map((data) => {
         return (
             <tr key={data.uid}>
                 <td>{moment(data.submissionDate).format('MMM D, YYYY')}</td>
                 <td>{moment(data.date).format('MMM D, YYYY')}</td>
                 <td>{data.endDate ? moment(data.endDate).format('MMM D, YYYY'): ''}</td>
                 <td>{data.notes}</td>
                 <td>{
                     data.scheduleType === 'requestOff' ? 'Pending' : 
                     data.scheduleType === 'rejectedRequest' ? 'Rejected' : 
                     data.scheduleType === 'pto' ? 'Approved as PTO' : 
                     'Approved as day off'}
                 </td>
                 <td style={{textAlign: 'center'}}>{(moment(data.date).isAfter(moment()) && data.scheduleType !== 'rejectedRequest' ) && <FontAwesomeIcon color="var(--bs-danger)" icon={faTrash} style={{cursor: 'pointer'}} onClick={()=>{setShowDeleteModal(true); setSelectedScheduleItem(data)}}/>}</td>
             </tr>
         )
     });

     const content = (
         <div  style={{maxHeight: '100%', width:'100%', display: 'flex', flexDirection: 'column'}}>
             <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 12}}>
                 <p style={{fontWeight:'bold', fontSize:20, marginRight:10}}>Previous Requests</p>
                 <Button variant={'outline-primary'} onClick={() => setShowDateRangeFilter(true)}>{'Submission Date Filter: ' + dateRangeToString(startDate, endDate)}</Button>
                 <Button variant={'outline-primary'} onClick={() => setShowModal(true)}>Request Off</Button>
             </div>
             <div>
                 <Table striped bordered>
                     <thead>
                         <tr>
                             <th>Date Submitted</th>
                             <th>Start Date</th>
                             <th>End Date</th>
                             <th>Reason</th>
                             <th>Status</th>
                             <th></th>
                         </tr>
                     </thead>
                     <tbody>
                         {previousRequests}
                     </tbody>
                 </Table>
             </div>
         </div>
     );

     return (
        <div className="page-wrapper" style={{padding: 12}}>
            <LoadingWrapper isLoading={isLoading}>
                {content}
            </LoadingWrapper>
            <Modal show={showModal} onHide={() => setShowModal(false)} centered size='lg'>
                <DaysOffRequestModal requestOffHistory={requestOffHistory} hideModal={() => setShowModal(false)} loadData={loadData}/>
            </Modal>
            <Modal show={showDeleteModal} onHide={() => {setShowDeleteModal(false); setSelectedScheduleItem(null); setDeleteString('')}}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>This cannot be undone. Please type out the following phrase to delete this item.</p>
                    <h5 style={{textAlign: 'center'}}>"DELETE ITEM"</h5>
                    <div style={{position: 'relative'}}>
                        <Form.Control style={{border: deleteString === 'DELETE ITEM' ? ' 3px solid var(--bs-success)' : 'DELETE ITEM'.substring(0, deleteString.length) === deleteString ? '3px solid var(--bs-warning)' : '3px solid var(--bs-danger)'}}  value={deleteString} onChange={(e) => setDeleteString(e.target.value)}/>
                        <FontAwesomeIcon 
                            style={{position: 'absolute', top: 12, right: 12}} 
                            icon={deleteString === 'DELETE ITEM' ? faCircleCheck : 'DELETE ITEM'.includes(deleteString) ? faEllipsis : faMinusCircle} 
                            color={deleteString === 'DELETE ITEM' ? 'var(--bs-success)' : 'DELETE ITEM'.includes(deleteString) ? 'var(--bs-warning)' : 'var(--bs-danger)'}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton disabled={deleteString !== 'DELETE ITEM'} isLoading={isDeleting} label={'Delete'} onClick={() => handleDeleteScheduleItem(selectedScheduleItem.uid)}/>
                </Modal.Footer>
            </Modal>
            <DateRangeSelector show={showDateRangeFilter} onHide={() => setShowDateRangeFilter(false)} centered handleSetDateRange={handleSetDateRange} presetOptions={['pastYear', 'pastMonth', 'pastWeek', 'allTime']}/>
        </div>
    )
}