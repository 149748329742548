import React, { useEffect, useState } from "react";
import QuickTable from "../../components/QuickTable";
import { usdFormatter } from "../../tools";

export default function FutureAnnualRevenue({pcat}) {
    const getHeader = (index) => {
        if (pcat.proposals[index].isFedex) {
            let fedexProposalCount = 1;
            for (let i = 0; i < pcat.proposals.length; i++) {
                if (i == index) {
                    break;
                } else if (pcat.proposals[i].isFedex) {
                    fedexProposalCount++;
                }
            }
            return `FE #${fedexProposalCount}`;
        } else {
            let icProposalCount = 1;
            for (let i = 0; i < pcat.proposals.length; i++) {
                if (i == index) {
                    break;
                } else if (!pcat.proposals[i].isFedex) {
                    icProposalCount++;
                }
            }
            return `IC #${icProposalCount}`;
        }
    }

    const rows = [
        {
            label: 'Service Revenue & Advance Service Charge Revenue',
            type: 'Fixed',
            values: pcat.proposals.map(p => usdFormatter.format(p.serviceRevenueAndAdvanceServiceChargeRevenue().toNumber()))
        },
        {
            label: 'Non E-Commerce Stop Revenue',
            type: 'Variable',
            values: pcat.proposals.map(p => usdFormatter.format(p.nonECommStopRevenue(pcat).toNumber()))
        },
        {
            label: 'Surge Stop Revenue',
            type: 'Variable',
            values: pcat.proposals.map(p => usdFormatter.format(p.surgeStopRevenue().toNumber()))
        },
        {
            label: 'Fuel Stop Surcharge Revenue',
            type: 'Variable',
            values: pcat.proposals.map(p => usdFormatter.format(p.fuelStopSurchargeRevenue(pcat).toNumber()))
        },
        {
            label: 'Non E-Commerce Package Revenue',
            type: 'Variable',
            values: pcat.proposals.map(p => usdFormatter.format(p.nonECommPackageRevenue(pcat).toNumber()))
        },
        {
            label: 'Large Package Mix Revenue',
            type: 'Variable',
            values: pcat.proposals.map(p => usdFormatter.format(p.largePackageMixRevenue(pcat).toNumber()))
        },
        {
            label: 'Apparel Brand Promotion Revenue',
            type: 'Fixed',
            values: pcat.proposals.map(p => usdFormatter.format(p.apparelBrandPromotionRevenue().toNumber()))
        },
        {
            label: 'Vehicle Brand Promotion Revenue',
            type: 'Other Veh. Dep.',
            values: pcat.proposals.map(p => usdFormatter.format(p.vehicleBrandPromotionRevenue().toNumber()))
        },
        {
            label: 'E-Commerce Stop Revenue',
            type: 'Variable',
            values: pcat.proposals.map(p => usdFormatter.format(p.eCommStopRevenue(pcat).toNumber()))
        },
        {
            label: 'E-Commerce Package Revenue',
            type: 'Variable',
            values: pcat.proposals.map(p => usdFormatter.format(p.eCommPackageRevenue(pcat).toNumber()))
        },
        {
            label: 'Variable Revenue',
            type: '',
            values: pcat.proposals.map(p => usdFormatter.format(p.variableRevenue(pcat).toNumber()))
        },
        {
            label: 'Fixed/Other Revenue',
            type: '',
            values: pcat.proposals.map(p => usdFormatter.format(p.fixedAndOtherRevenue().toNumber()))
        },
        {
            label: 'Total Revenue',
            type: '',
            values: pcat.proposals.map(p => usdFormatter.format(p.totalRevenue(pcat).toNumber()))
        },
        {
            label: 'Variable Revenue %',
            type: '',
            values: pcat.proposals.map(p => p.variableRevenuePercentage(pcat))
        },
        {
            label: 'Fixed/Other Revenue %',
            type: '',
            values: pcat.proposals.map(p => p.fixedAndOtherRevenuePercentage(pcat))
        },
        {
            label: 'Annual Expenses',
            type: '',
            values: pcat.proposals.map((p, i) => p.getTotalExpenses(pcat, i))
        },
        {
            label: 'Annual Profit',
            type: '',
            values: pcat.proposals.map((p, i) => p.getProfit(pcat, i))
        },
        {
            label: 'Annual Margin %',
            type: '',
            values: pcat.proposals.map((p, i) => p.getMargin(pcat, i))
        },
        {
            label: 'Cost Per Dispatch',
            type: '',
            values: pcat.proposals.map((p, i) => p.getYearlyExpensesPerDispatch(pcat, i))
        },
        {
            label: 'Revenue Per Dispatch',
            type: '',
            values: pcat.proposals.map((p, i) => p.getYearlyRevenuePerDispatch(pcat, i))
        },
    ].map((row) => {
        return (
            <tr key={row.label}>
                <td style={{whiteSpace: 'nowrap'}}>{row.label}</td>
                <td style={{whiteSpace: 'nowrap'}}>{row.type}</td>
                {
                    pcat.proposals.map((_, index) => {
                        return (
                            <td key={index}>
                                {row.values[index]}
                            </td>
                        )
                    })
                }
            </tr>
        )
    })

    const headerElements = Array.from(Array(pcat.proposals.length).keys()).map((index => {
        return (
            <div key={index} style={{display: 'flex', alignItems: 'center', gap: 8}}>
                <span>{getHeader(index)}</span>
            </div>
        )
    }));

    return(
        <div style={{display:'flex', flexDirection:'row'}}>
            <QuickTable title='Future Annual Revenue Matrix' headers={['', ' '].concat(headerElements)} rows={rows} widths={[1, 1]} size='sm' responsive={false}/>
        </div>
    )
    
}