/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import QuickTable from "../../components/QuickTable";
import { Button } from "react-bootstrap";
import { availabilityToStringShort, encodeAvailability } from "../../tools";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faX } from "@fortawesome/free-solid-svg-icons";
import CustomControl from "../../components/CustomControl";
import { Modal } from "react-bootstrap";
import { ValidationGroup, validateExistence} from "../../validation";
import CustomButton from "../../components/CustomButton";
import RadioControl from "../../components/RadioControl";
import AvailabilityControl from "../../components/AvailabilityControl";
import { getFakeUID } from "../../tools";
import LoadingWrapper from "../../components/LoadingWrapper";
import { ApiRequest } from "../../ApiManager.tsx";

export default function CreateCompanyWorkAreas({handleMultipleWorkAreaSubmissions, restrictedToCsa}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [csas, setCsas] = useState([]);
    const [workAreaName, setWorkAreaName] = useState('');
    const [csaIdentifier, setCsaIdentifier] = useState(undefined);
    const [availability, setAvailability] = useState([false, false, false, false, false, false, false]);
    const [workAreaArray, setWorkAreaArray] = useState([]);
    const validationGroup = new ValidationGroup();

    useEffect(() => {
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('myCompany', 'getCsas', setIsLoading, (response) => restrictedToCsa ? setCsas([response.csas.find(csa => csa.uid == restrictedToCsa)]) : setCsas(response.csas)).withNoAlertOnSuccess().send()
    }

    function handleSubmit(){
        const arrayToSave = workAreaArray.map((wa) => {
            return {
                workAreaName: wa.workAreaName,
                csaIdentifier: wa.csaIdentifier,
                csa: csas.find(c => c.uid == wa.csaIdentifier),
                isActive: wa.isActive,
                workAreaAvailability: encodeAvailability(wa.workAreaAvailability)
            }
        });

        new ApiRequest('myCompany', 'createMultipleWorkAreas', setIsSubmitting, (response) => {
            clearForm();
            for (let i = 0; i < arrayToSave.length; i++) {
                arrayToSave[i].uid = response.data[i];
            }
            handleMultipleWorkAreaSubmissions(arrayToSave);
        }).withData({data: arrayToSave}).send()
    }

    function handleAddWorkArea(){
        const workAreaToSave = {
            uid: getFakeUID(),
            workAreaName: workAreaName,
            csaIdentifier: csaIdentifier,
            isActive: 1,
            workAreaAvailability: availability
        }
        let newArray = Array.from(workAreaArray);
        newArray.push(workAreaToSave);
        setWorkAreaArray(newArray);
        clearForm();
    }

    function handleRemoveWorkArea(index){
        const newArray = Array.from(workAreaArray);
        newArray.splice(index, 1);
        setWorkAreaArray(newArray);
    }

    function clearForm(){
        setWorkAreaName('');
        setAvailability([false, false, false, false, false, false, false]);
    }

    const workAreaRows = workAreaArray.map((wa,index) => {
        const csa = csas.find(c => c.uid === wa.csaIdentifier);
        return (
            <tr key={wa.uid}>
                <td>{wa.workAreaName + (wa.isActive === 1 ? '' : ' (inactive)')}</td>
                <td>{csa.csaName}</td>
                <td>{availabilityToStringShort(wa.workAreaAvailability)}</td>
                <td style={{width: 0}}>
                    <Button variant={'outline-primary'} onClick={() => {handleRemoveWorkArea(index)}}>
                        <FontAwesomeIcon icon={faX}/>
                    </Button>
                </td>
            </tr>
        )
    });
   
    const content = (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Add Multiple Work Areas</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                <QuickTable headers={['Name', 'CSA', 'Availability', '']} rows={workAreaRows} size='sm'/>
                <FontAwesomeIcon icon={faArrowUp} size='2x'/>
                <CustomControl validator={validationGroup.createValidator(()=>validateExistence(workAreaName))} type='text' title='Name' maxLength={50} value={workAreaName} onChange={(event) => {setWorkAreaName(event.target.value)}}/>
                <RadioControl validator={validationGroup.createValidator(()=>validateExistence(csaIdentifier))} title='CSA:' selection={csaIdentifier} setSelection={setCsaIdentifier} optionNames={csas.map(csa => csa.csaName)} optionValues={csas.map(csa => csa.uid)} inline/> 
                <AvailabilityControl title='Work Area Availability:' inline value={availability} setValue={setAvailability}/>
                <CustomButton disabled={!validationGroup.isValid()} onClick={handleAddWorkArea} label='Add Work Area'/>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton disabled={workAreaArray.length === 0} onClick={handleSubmit} isLoading={isSubmitting} label='Submit Work Areas'/>
            </Modal.Footer>
        </>
    )
    return (
        <LoadingWrapper isLoading={isLoading}>
            {content}
        </LoadingWrapper>
    )
}