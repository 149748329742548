/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CustomSpinner from '../../../components/CustomSpinner';
import HRUserScheduleModal from './HRUserScheduleModal';
import HRUserScheduleAddNoShow from './HRUserScheduleAddNoShow';
import { scheduleTypeDictionary } from '../../../tools';
import { Dropdown } from 'react-bootstrap';
import CustomButton from '../../../components/CustomButton';
import HRCreateScheduleItemModal from './HRCreateScheduleItemModal';
import Cookies from 'universal-cookie';
import { ApiRequest } from '../../../ApiManager.tsx';
import LoadingWrapper from '../../../components/LoadingWrapper.js';
import QuickTable from '../../../components/QuickTable.js';

export default function HRUserSchedule({selectedEmployee}){
    const [isLoading, setIsLoading] = useState(false);
    const [scheduleItems, setScheduleItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(undefined);
    const [modalSwitch, setModalSwitch] = useState('none');
    const [sortType, setSortType] = useState('Start Date');
    const [sortDirection, setSortDirection] = useState('Latest To Earliest');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const isAOorBC = new Cookies().get('userData').title == 'AO' || new Cookies().get('userData').title == 'BC';

    useEffect(() => {
        loadData();
    }, [selectedEmployee])

    function loadData(){
        new ApiRequest('hr', 'schedule', setIsLoading, (response) => {
            const scheduleItems = response.data.sort((a, b) => {
                if (moment(a.submissionDate).isBefore(moment(b.submissionDate))) {
                    return 1;
                } else if (moment(a.submissionDate).isAfter(moment(b.submissionDate))) {
                    return -1;
                }
                return 0;
            })
            setScheduleItems(scheduleItems);
        }).withData({companyUserIdentifier: selectedEmployee.companyUserUid}).withNoAlertOnSuccess().send();
    }

    function handleAddItem(item) {
        const newArray = Array.from(scheduleItems);
        newArray.unshift(item);
        setScheduleItems(newArray);
        hideModal();
    }

    function handleAnswerRequest(uid, answer) {
        const newArray = Array.from(scheduleItems);
        newArray.find(e => e.uid == uid).scheduleType = answer;
        setScheduleItems(newArray);
        hideModal();
    }

    function handleDropdownSelect(eventKey){
        setSortDirection(eventKey);
    };

    function handleDropdownToggle(isOpen){
        setIsDropdownOpen(isOpen);
    };

    function handleUpdateSelectedItem(key, value){
        const newArray = Array.from(scheduleItems);
        const index = newArray.findIndex(item =>item.uid == selectedItem.uid);
        const newObj = {...selectedItem};
        newObj[key] = value;
        newArray[index] = newObj;
        setScheduleItems(newArray)
        setSelectedItem(newObj);
    }

    function handleDeleteScheduleItem(uid){
        const newScheduleItems = Array.from(scheduleItems).filter(item=>item.uid != uid);
        setScheduleItems(newScheduleItems);
    }

    function hideModal() {
        setModalSwitch('none');
    }

    function handleAddPTO() {
        hideModal();
        loadData();
    }

    function sortScheduleItems(sortType, scheduleItems, sortDirection){
        if(sortDirection === 'Latest To Earliest'){
            if(sortType === 'Start Date'){
                scheduleItems.sort((a, b) => moment(b.date) - moment(a.date));
            } else if(sortType === 'End Date'){
                scheduleItems.sort((a,b) => moment(b.endDate) - moment(a.endDate));
            } else if(sortType === 'Submission Date'){
                scheduleItems.sort((a,b) => moment(b.submissionDate) - moment(a.submissionDate));
            }
        } else {
            if(sortType === 'Start Date'){
                scheduleItems.sort((a, b) => moment(a.date) - moment(b.date));
            } else if(sortType === 'End Date'){
                scheduleItems.sort((a,b) => moment(a.endDate) - moment(b.endDate));
            } else if(sortType === 'Submission Date'){
                scheduleItems.sort((a,b) => moment(a.submissionDate) - moment(b.submissionDate));
            }
        }
    }

    sortScheduleItems(sortType, scheduleItems, sortDirection);
    
    const pendingScheduleItems = scheduleItems.filter((item) => {
        return moment().isBefore(moment(item.date), 'day') && item.scheduleType === 'requestOff';
    });        
    const pendingScheduleItemElements = pendingScheduleItems.map((item) => {
        return (
            <tr key={item.uid} className='cursor-pointer' onClick={() => {setSelectedItem(item); setModalSwitch('viewItem')}}>
                <td>{moment(item.date).format('MMM D, YYYY')}</td>
                <td>{moment(item.endDate).format('MMM D, YYYY')}</td>
                <td>{moment(item.submissionDate).format('MMM D, YYYY')}</td>
                <td className='td-ellipsis'><span>{item.notes}</span></td>
            </tr>
        )
    });

    const upcomingScheduleItems = scheduleItems.filter(item => moment(item.date).isAfter(moment(), 'day') && (item.scheduleType === 'pto' || item.scheduleType === 'dayOff'));
    const upcomingScheduleItemElements = upcomingScheduleItems.map((item) => {
        return (
            <tr key={item.uid} className='cursor-pointer' onClick={() => {setSelectedItem(item); setModalSwitch('viewItem')}}>
                <td>{moment(item.date).format('MMM D, YYYY')}</td>
                <td>{scheduleTypeDictionary[item.scheduleType].label}</td>
                <td>{moment(item.submissionDate).format('MMM D, YYYY')}</td>
                <td className='td-ellipsis'><span>{item.notes}</span></td>
            </tr>
        )
    });

    const historicScheduleItems = scheduleItems.filter((item) => {
        if (item.scheduleType === 'rejectedRequest') {
            return true;
        } else if (item.scheduleType === 'requestOff') {
            return moment(item.date).isSameOrBefore(moment(), 'day');
        } else {
            return !moment(item.endDate ? item.endDate : item.date).isAfter(moment(), 'day');
        } 
    });
    
    const historicScheduleItemElements = historicScheduleItems.map((item) => {
        return (
            <tr key={item.uid} className='cursor-pointer' onClick={() => {setSelectedItem(item); setModalSwitch('viewItem')}}>
                <td>{moment(item.date).format('MMM D, YYYY') + (item.endDate && item.endDate !== item.date ? ' - ' + moment(item.endDate).format('MMM D, YYYY') : '')}</td>
                <td>{scheduleTypeDictionary[item.scheduleType].label}</td>
                <td>{moment(item.submissionDate).format('MMM D, YYYY')}</td>
                <td className='td-ellipsis'><span>{item.notes}</span></td>
            </tr>
        )
    });
    
    const content = (
        <div style={{height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden'}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 12, marginBottom: 12, justifyContent:'space-between'}}>
                <Dropdown show={isDropdownOpen} onToggle={handleDropdownToggle} onSelect={handleDropdownSelect}>
                    <Dropdown.Toggle variant={'outline-primary'}>
                        {sortDirection ? sortDirection : ' '}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item eventKey="Earliest To Latest">Earliest To Latest</Dropdown.Item>
                        <Dropdown.Item eventKey="Latest To Earliest">Latest To Earliest</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                {isAOorBC && <CustomButton label='Schedule Time Off' onClick={() => setModalSwitch('showCreateTimeOff')}/>}
                <div style={{display:'flex', justifyContent:'flex-end'}}>
                    {/* {ongoingScheduleItem ? 
                        <Alert style={{flex: 1, margin: 0}} className='cursor-pointer' variant='warning' onClick={() => {setSelectedItem(ongoingScheduleItem); setModalSwitch('viewItem')}}>
                            {'Employee is currently on ' + scheduleTypeDictionary[ongoingScheduleItem.scheduleType].label + ' until ' + moment(ongoingScheduleItem.endDate).format('dddd, MMMM D')}
                        </Alert>
                    : ''} */}
                    <Button variant={'outline-primary'} onClick={() => setModalSwitch('addItem')}>Report Call Off / No-Show</Button>
                </div>
            </div>
            <div style={{flex: 1, overflowY: 'auto'}}>
                <QuickTable title='Pending Requests' headers={['Start Date', 'End Date', 'Submission Date', 'Notes']} widths={[140, 140, 170, null]} rows={pendingScheduleItemElements} hover size='sm'/>
                <QuickTable title='Upcoming Time Off' headers={['Date', 'Type', 'Submission Date', 'Notes']} widths={[140, 200, 170, null]} rows={upcomingScheduleItemElements} hover size='sm'/>
                <QuickTable title='Schedule History' headers={['Date', 'Type', 'Submission Date', 'Notes']} widths={[260, 200, 170, null]} rows={historicScheduleItemElements} hover size='sm'/>
            </div>
        </div>
    )

    return (
        <>
            <LoadingWrapper isLoading={isLoading} onlyRenderIfTruthy={selectedEmployee ?? false}>{content}</LoadingWrapper>
            <Modal show={modalSwitch === 'viewItem'} onHide={hideModal}>
                <HRUserScheduleModal 
                    selectedItem={selectedItem} 
                    handleAnswerRequest={handleAnswerRequest} 
                    handleAddPTO={handleAddPTO}
                    handleUpdateSelectedItem={handleUpdateSelectedItem}
                    handleDeleteScheduleItem={handleDeleteScheduleItem}
                    hideModal={hideModal}
                    timeOff={upcomingScheduleItems.concat(pendingScheduleItems)}
                />
            </Modal>
            <Modal show={modalSwitch === 'addItem'} onHide={hideModal}>
                <HRUserScheduleAddNoShow companyUserIdentifier={selectedEmployee.companyUserUid} handleAddItem={handleAddItem}/>
            </Modal>
            <Modal show={modalSwitch === 'showCreateTimeOff'} onHide={hideModal} size='lg'>
                <HRCreateScheduleItemModal companyUserIdentifier={selectedEmployee.companyUserUid} handleAddPTO={handleAddPTO}/>
            </Modal>
        </>
    )

}