/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react"
import {Document, Page, View, Text, Image } from "@react-pdf/renderer"
import logo from '../../../assets/TTA-Logo-150.png';
import Cookies from "universal-cookie";
import moment from "moment";

export default function LoginReportPdf({loginData, startDate, endDate, selectedUser}){

    const headers = (
        <View style={{border:'1px solid black', display:'flex', flexDirection:'row'}} wrap={false}>
            <View style={{borderRight:'1px solid black', fontSize:14, width:'50%',padding:5}}>
                <Text style={{textAlign:'center'}}>Login Date</Text>
            </View>
            <View style={{borderRight:'1px solid black', fontSize:14,width:'50%',padding:5}}>
                <Text style={{textAlign:'center'}}>Login Time</Text>
            </View>
        </View>
    )

    const tables = [];
    loginData.forEach((log, index)=>{
        const rows = (
            <View key ={index} style={{display:'flex', border:'1px solid black', flexDirection:'row'}} wrap={false}>
                <View style={{borderRight:'1px solid black', fontSize:10, width:'50%', padding:5}}>
                    <Text style={{textAlign:'center'}}>{moment(log).format('MMM D, YYYY')}</Text>
                </View>
                <View style={{borderRight:'1px solid black', fontSize:10, width:'50%', padding:5}}>
                    <Text style={{textAlign:'center'}}>{moment(log).format('hh:mm:ss A')}</Text>
                </View>
            </View>
            
        )
        tables.push(rows);
    });

    return (
        <Document> 
            <Page size='LETTER' style={{padding: '0.5in', flexDirection: 'column'}} orientation='landscape'>
                <View fixed style={{flexDirection: 'row', width: '100%', justifyContent: 'space-between', marginBottom:15}}>
                    <Image src={logo} style={{width: 150}}/>
                    <Text style={{fontSize: 12, color: 'gray'}}>{new Cookies().get('companyName')}</Text>
                </View>
                <View style={{display:'flex', flexDirection:'row', gap:10, justifyContent:'center'}}>
                    <Text style={{textAlign:'center', paddingBottom:4, textDecoration:'underline'}}>{selectedUser.lastName + ', ' + selectedUser.firstName + ' ' + selectedUser.middleName.substring(0,1)}</Text>
                </View>
                    <Text></Text>
                <Text style={{opacity:0.5,textAlign:'center'}}>{moment(startDate).format('MMM D, YYYY')+' - '+moment(endDate).format('MMM D, YYYY')}</Text>
                
                <View style={{ display:'flex', marginTop:10}} >
                   {headers}
                   {tables}
                </View>
            </Page>
        </Document>
    )
}