/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Card, ListGroup, Modal } from 'react-bootstrap';
import DocumentPreview from '../../components/DocumentPreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationTriangle, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import LoadingWrapper from '../../components/LoadingWrapper';
import { OnboardingDocumentsReadOnly } from '../../tools';
import { ApiRequest } from '../../ApiManager.tsx';

export default function MyDocuments({}){
    const [isLoading, setIsLoading] = useState(false);
    const [companyDocuments, setCompanyDocuments] = useState([]);
    const [pandaDocs, setPandaDocs] = useState([]);
    const [edDocumentRequests, setEdDocumentRequests] = useState([]);
    const [displayAsStack, setDisplayAsStack] = useState(window.innerWidth < 1500);
    const [selectedCompanyDocument, setSelectedCompanyDocument] = useState(undefined);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        loadData();
    }, [])

    useEffect(()=>{
        window.addEventListener('resize', handleWindowResize);

        return ()=>{
            window.removeEventListener('resize', handleWindowResize)
        }
    })

    function loadData(){
        new ApiRequest('myDocuments', 'getAll', setIsLoading, (response) => {
            setCompanyDocuments(response.companyDocuments);
            setPandaDocs(response.pandaDocs);
            setEdDocumentRequests(response.edDocumentRequests);
        }).withNoAlertOnSuccess().send();
    }

    function handleWindowResize() {
        !displayAsStack && window.innerWidth < 1500 ? setDisplayAsStack(true) : setDisplayAsStack(false);
    }

    function handlePandaDocLink(link) {
        const elem = window.document.createElement('a');
        elem.href = link;
        elem.target = '_blank';
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }

    const employeeUncompletedOnboardingDocumentElements = edDocumentRequests.filter(eDoc=> Object.values(!eDoc.documents).reduce((acc, el)=>{
        if(!el.employeeCompleted){
            acc = false;
        }
        return acc;
    }, true)).map(eDoc=>{
        return (
            <ListGroup.Item key={eDoc.uid} onClick={() => window.location.replace(`https://mytacticalrecruiting.com/employee-onboarding/${eDoc.employeeToken}`)} action>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 8, flexWrap: 'wrap', gap: 4}}>
                    <h6 style={{margin: 0, marginRight: 8}}>{moment(eDoc.dateCreated).format('MMMM D, YYYY')}</h6>
                    <span style={{color: 'var(--bs-danger'}}>Requires Completion <FontAwesomeIcon icon={faTriangleExclamation} color='var(--bs-danger)'/></span>
                </div>
                <span style={{fontSize: 14,opacity: 0.5}}>
                    {Object.entries(eDoc.documents).map(([key, value])=>{
                        return OnboardingDocumentsReadOnly[key]
                    }).join(', ')}
                </span>
            </ListGroup.Item>
        )
    })

    const employeeCompletedOnboardingDocumentElements = edDocumentRequests.filter(eDoc=> Object.values(eDoc.documents).reduce((acc, el)=>{
        if(!el.employeeCompleted){
            acc = false;
        }
        return acc;
    }, true)).map(eDoc=>{
        return (
            <ListGroup.Item key={eDoc.uid} onClick={() => window.location.replace(`https://mytacticalrecruiting.com/employee-onboarding/${eDoc.employeeToken}`)} action>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 8, flexWrap: 'wrap', gap: 4}}>
                    <h6 style={{margin: 0, marginRight: 8}}>{moment(eDoc.dateCreated).format('MMMM D, YYYY')}</h6>
                    <span style={{color: 'var(--bs-success'}}>Finished <FontAwesomeIcon icon={faCheck} color='var(--bs-success)'/></span>
                </div>
                <span style={{fontSize: 14, opacity: 0.5}}>
                    {Object.entries(eDoc.documents).map(([key, value])=>{
                        return OnboardingDocumentsReadOnly[key]
                    }).join(', ')}
                </span>
            </ListGroup.Item>
        )
    })

    const uncompletedPandaDocElements = pandaDocs.filter(d => !d.completed).map((pDoc) => {
        return (
            <ListGroup.Item key={pDoc.uid} onClick={() => handlePandaDocLink(pDoc.link)} action>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 8, flexWrap: 'wrap', gap: 4}}>
                    <h6 style={{margin: 0, marginRight: 8}}>{moment(pDoc.dateCreated).format('MMMM D, YYYY')}</h6>
                    <span style={{color: 'var(--bs-danger'}}>Requires Completion <FontAwesomeIcon icon={faExclamationTriangle} color='var(--bs-danger)'/></span>
                </div>
                <span style={{fontSize: 14, opacity: 0.5}}>{pDoc.name}</span>
            </ListGroup.Item>
        )
    })
    const completedPandaDocElements = pandaDocs.filter(d => d.completed).map((pDoc) => {
        return (
            <ListGroup.Item key={pDoc.uid} onClick={() => handlePandaDocLink(pDoc.link)} action style={{display: 'flex', justifyContent: 'space-between'}}>
                 <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 8, flexWrap: 'wrap', gap: 4}}>
                    <h6 style={{margin: 0, marginRight: 8}}>{moment(pDoc.dateCreated).format('MMMM D, YYYY')}</h6>
                    <span style={{color: 'var(--bs-success'}}>Finished<FontAwesomeIcon icon={faCheck} color='var(--bs-success)'/></span>
                </div>
                <span style={{fontSize: 14, opacity: 0.5}}>{pDoc.name}</span>
            </ListGroup.Item>
        )
    })
    const companyDocumentElements = companyDocuments.map((cDoc) => {
        return (
            <ListGroup.Item key={cDoc.uid} onClick={() => {setSelectedCompanyDocument(cDoc); setShowModal(true)}} action style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                <span>{cDoc.documentName}</span>
                <span style={{opacity: 0.5}}>{moment(cDoc.dateApproved).format('[Acknowledged on] MMM D, YYYY [at] h:mm A')}</span>
            </ListGroup.Item>
        )
    })


    return (
        <LoadingWrapper isLoading={isLoading}>
            <div className="page-wrapper" style={{padding: 16, flexDirection: displayAsStack ? 'column' : 'row', gap: 12}}>
                <Card style={{flex: 1, overflowY: 'hidden'}}>
                    <Card.Body style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                        <Card.Title style={{marginBottom: 12}}>Employee Onboarding Document Requests</Card.Title>
                        <ListGroup style={{overflowY: 'scroll', flex: 1}}>
                            {employeeUncompletedOnboardingDocumentElements}
                            {employeeCompletedOnboardingDocumentElements}
                           {employeeUncompletedOnboardingDocumentElements.length === 0 && employeeCompletedOnboardingDocumentElements.length === 0 && 
                           <div style={{height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24}}>No Document Requests</div>}
                        </ListGroup>
                    </Card.Body>
                </Card>
                <div style={{display: 'flex', flexDirection: 'column', gap: 12, flex: 1, overflowY: 'hidden'}}>
                    <Card style={{flex: 1, overflowY: 'hidden'}}>
                        <Card.Body style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                            <Card.Title style={{marginBottom: 12}}>PandaDocs</Card.Title>
                            <div style={{overflowY: 'scroll', height: '100%'}}>
                                <ListGroup>
                                    {uncompletedPandaDocElements}
                                    {completedPandaDocElements}
                                </ListGroup>
                                    {uncompletedPandaDocElements.length === 0 && completedPandaDocElements.length === 0 &&
                                    <div style={{height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24}}>No PandaDocs</div>}
                            </div>
                        </Card.Body>
                    </Card>
                    <Card style={{flex: 1, overflowY: 'hidden'}}>
                        <Card.Body style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                            <Card.Title style={{marginBottom: 12}}>Acknowledged Company Documents</Card.Title>
                            <div style={{overflowY: 'scroll', height: '100%'}}>
                                <ListGroup>
                                    {companyDocumentElements}
                                </ListGroup>
                                { companyDocumentElements.length === 0 &&
                                <div style={{height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24}}>No Company Documents</div>}
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <Modal show={showModal} onHide={() => setShowModal(false)} fullscreen>
                    <DocumentPreview myDocument document={{...selectedCompanyDocument, documentType: 'pdf'}}/>
                </Modal>
            </div>
        </LoadingWrapper>
    )
}