/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { Validator, validateGreaterThanZero } from "../../../../../../validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import CustomToolTip from "../../../../../../components/CustomToolTip";
import QuickTable from "../../../../../../components/QuickTable";
import { Button, Modal } from "react-bootstrap";
import { usdFormatter } from "../../../../../../tools";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

export default function PayrollEmployeeNondiscretionaryBonuses({week, handleSetWeek, index, entry}) {

    const removeNondiscretionaryBonus = (id) => {
        const newArr = week.ndBonuses.filter((element) => {
            return element.id !== id;
        });
        handleSetWeek(index, 'ndBonuses', newArr )
    }

    const handleSetNondiscretionaryBonus = (id, key, value) => {
        const newArr = Array.from(week.ndBonuses);
        newArr.find(r => r.id === id)[key] = value;
        handleSetWeek(index, 'ndBonuses', newArr )
    }

    const ndBonusRows = week.ndBonuses.map((nd) => {
        const validator = new Validator(() => validateGreaterThanZero(nd.amount));

        return (
            <tr key={nd.id}>
                <td>{nd.type}</td>
                <td>
                    <InputGroup>
                        <InputGroup.Text style={{padding: '2px 6px 2px 6px'}}>$</InputGroup.Text>
                        <Form.Control isInvalid={!validator.isValid()} style={{padding: '2px 6px 2px 6px'}} type='number' min={0} value={nd.amount} placeholder='Amount' onChange={(event) => {handleSetNondiscretionaryBonus(nd.id, 'amount', event.target.value.replace('-', ''))}}/>
                        { validator ? <Form.Control.Feedback type='invalid'>{validator.getInvalidMessage()}</Form.Control.Feedback> : ''}
                    </InputGroup>
                </td>
                <td>
                    <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
                        <Form.Check disabled={!nd.canBeReoccuring} type='switch' checked={nd.isReoccuring} onChange={() => {handleSetNondiscretionaryBonus(nd.id, 'isReoccuring', !nd.isReoccuring)}}/>
                        { nd.canBeReoccuring ?
                            <CustomToolTip text={'Next payroll period, this bonus will be automatically added to this employee'}/>
                            :
                            <CustomToolTip text={'This bonus was automatically generated from this employee\'s pay'}/>
                        }
                    </div>
                </td>
                <td>
                    <Button style={{color: 'white', padding: '2px 6px 2px 6px', width: '100%'}} onClick={() => removeNondiscretionaryBonus(nd.id)}>
                        <FontAwesomeIcon icon={faTrashCan}/>
                    </Button>
                </td>
            </tr>
        )
    });

    return (
            <>
                { ndBonusRows.length > 0 &&
                    <QuickTable title='Nondiscretionary Bonuses' headers={['Type', 'Amount', 'Reoccuring?', '']} widths={[null, null, 100, 40]} rows={ndBonusRows} size={'sm'}/>
                }
            </>
        
    )
}

function ProjectedValueTooltip() {
    const [show, setShow] = useState(false);

    return (
        <>
            <FontAwesomeIcon className="cursor-pointer" onClick={() => {setShow(true)}} style={{color: 'var(--bs-primary)'}} icon={faQuestionCircle}/>
            <Modal show={show} onHide={() => {setShow(false)}} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Projected Value Explained</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>For Fine Ticket Damage which has "<i>Deduct From Safety Bonuses</i>" enabled, the installment will only be deducted from the employee's <b>Safety Bonuses</b>. The <b>Projected Value</b> represents the value which will be used to calculate the gross and what will show up outside the editor.</p>
                </Modal.Body>
            </Modal>
        </>
    )
}