/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import React, { useState } from "react"
import { Modal } from "react-bootstrap";
import CustomControl from "../../components/CustomControl";
import { ValidationGroup, validateGreaterThanZero } from "../../validation";
import CustomButton from "../../components/CustomButton";
import { validateDecimal } from "../../tools";

export default function PCATCalculateDeliveryPercentage({handleSetPcat, hideModal}) {
    const [nonECommPackages, setNonECommPackages] = useState('');
    const [eCommPackages, setECommPackages] = useState('');
    const [totalDeliveryPackages, setTotalDeliveryPackages] = useState('');
    const validationGroup = new ValidationGroup();

    function handleSubmit(){
        const totalPackages = validateDecimal(nonECommPackages) + validateDecimal(eCommPackages);
        const deliveryRatio = validateDecimal(totalDeliveryPackages) / totalPackages;
        handleSetPcat('deliveryPercentage', deliveryRatio * 100);
        hideModal();
    }

    return (
        <>
            <Modal.Header>
                <Modal.Title>Calculate Package Delivery Percentage</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                <p>The Package Delivery Percentage is used to calulate Total Delivery Packages and Large Package Mix Revenue.</p>
                <p>Please enter the values for the following three columns from your initial FedEx Proposal in the <b>Projected Service Area Growth & Matrix</b> table</p>
                <CustomControl validator={validationGroup.createValidator(() => validateGreaterThanZero(nonECommPackages))} title='Total Non E-Commerce Packages' value={nonECommPackages} onChange={(event) => {setNonECommPackages(event.target.value)}}/>
                <CustomControl validator={validationGroup.createValidator(() => validateGreaterThanZero(eCommPackages))} title='Total E-Commerce Packages' value={eCommPackages} onChange={(event) => {setECommPackages(event.target.value)}}/>
                <CustomControl validator={validationGroup.createValidator(() => validateGreaterThanZero(totalDeliveryPackages))} title='Total Delivery Packages' value={totalDeliveryPackages} onChange={(event) => {setTotalDeliveryPackages(event.target.value)}}/>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton label='Submit' disabled={!validationGroup.isValid()} onClick={handleSubmit}/>
            </Modal.Footer>
        </>
    )
}