/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './ScheduleMatch.css';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import CustomSpinner from '../../components/CustomSpinner';
import AlertModal from '../../components/AlertModals/AlertModal';
import ScheduleMatchTemplateImportModal from './ScheduleMatchTemplateImportModal';
import { ApiRequest } from '../../ApiManager.tsx';

export default function ScheduleMatchTemplateList({setModalSwitch, selectedTemplate, setSelectedTemplate}){
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [showImportAlert, setShowImportAlert] = useState(false);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);

    useEffect(() => {
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('scheduleMatch', 'getTemplates', setIsLoading, (response) =>  setTemplates(response.templates)).withNoAlertOnSuccess().send();
    }

    function handleEditTemplate(uid){
        new ApiRequest('scheduleMatch', 'getTemplate', setIsEditing, (response) => {
            response.data.routes = JSON.parse(response.data.routes);
            setSelectedTemplate(response.data);
            setModalSwitch('templateCreator');
        }).withUid(uid).withNoAlertOnSuccess().send()
    }

    function handleDeleteTemplate(){
        new ApiRequest('scheduleMatch', 'deleteTemplate', setIsDeleting, () => {setShowDeleteAlert(false); loadData()}).withUid(selectedTemplate.uid).send();
    }

    const templateItems = templates.map((t) => {
        return (
            <ListGroup.Item key={t.uid}>
                <div style={{display: 'flex', alignItems: 'center', gap: 12, justifyContent: 'space-between'}}>
                    <p style={{margin: 0}}>{t.templateName}</p>
                    <p style={{fontSize: 12, flex: 1, opacity: 0.5, margin: 0, whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis'}}>{t.templateDescription}</p>
                    <div style={{display: 'flex', gap: 8}}>
                        <Button variant={'outline-primary'} onClick={() => {setSelectedTemplate(t); setShowImportAlert(true)}}>Import</Button>
                        <Button variant={'outline-primary'} onClick={() => handleEditTemplate(t.uid)}>Edit</Button>
                        <Button variant={'outline-primary'} onClick={() => {setSelectedTemplate(t); setShowDeleteAlert(true)}}>Delete</Button>
                    </div>
                </div>
            </ListGroup.Item>
        )
    })

    const content = (
        <ListGroup>
            {templateItems}
        </ListGroup>
    );

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Templates</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading ? <CustomSpinner/> : content}
            </Modal.Body>
            <Modal show={showDeleteAlert} onHide={() => setShowDeleteAlert(false)} >
                <AlertModal
                    isLoading={isDeleting}
                    variant='outline-danger'
                    title={'Confirm Deletion'} 
                    message={'Permanently delete template? This cannot be undone.'} 
                    callBack={handleDeleteTemplate} 
                    buttonLabel={'Delete Template'}
                />
            </Modal>
            <Modal show={showImportAlert} onHide={() => setShowImportAlert(false)}>
                <ScheduleMatchTemplateImportModal uid={selectedTemplate?.uid ?? -1}/>
            </Modal>
        </>   
    )
}