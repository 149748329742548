/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../decoding";
import { validateDecimalFixed } from "../../../tools";
import { validateBig } from "../../Payroll/payrollTools";

export default class BudgetExpenses {
	driverWageLight;
	driverWageStandard;
	driverWageStraight;
	aoWages;
	bcWages;
    adminWages;
    aoPto;
    bcPto;
    adminPto;
	uniforms;
	payrollTaxes;
	driverWorkersComp;
	bcWorkersComp;
	healthInsurance;
	lifeInsurance;
	dentalAndVision;
	pensionAndProfitSharing;
    drugScreens;
	four01KMatch;
	four01KAdmin;
	dotPhysicals;
	adminFee;
	recruiting;
	fuel;
	maintenance;
	truckPayments;
	licenseAndRegistration;
	insurance;
	vanWashing;
    vanDecals;
	truckRentals;
	technology;
    bankCharges;
    officeSupplies;
    postage;
    computer;
    professional;
    internet;
    officeRent;
    officeUtilities;
    officeEquipmentLease;
    cellPhone;
    ePLI;
    otherGA;
    tolls;
    drivingTraining;
    accidents;
    medical15K;
    employeeMeals;
    shopSupplies;
	other;



    constructor(driverWageLight, driverWageStandard, driverWageStraight, aoWages, bcWages, adminWages, aoPto, bcPto, adminPto, uniforms, payrollTaxes, driverWorkersComp, bcWorkersComp,
            healthInsurance, lifeInsurance, dentalAndVision, pensionAndProfitSharing, drugScreens, 
            four01KMatch, four01KAdmin, dotPhysicals, adminFee, recruiting, fuel, maintenance, truckPayments, licenseAndRegistration, insurance, vanWashing, 
            vanDecals, truckRentals, technology, bankCharges, officeSupplies, postage, computer, professional, internet, officeRent, officeUtilities, officeEquipmentLease, cellPhone,
            ePLI, otherGA, tolls, drivingTraining, accidents, medical15K, employeeMeals, shopSupplies, other) {
        this.driverWageLight = driverWageLight;
        this.driverWageStandard = driverWageStandard;
        this.driverWageStraight = driverWageStraight;
        this.aoWages = aoWages;
        this.bcWages = bcWages;
        this.adminWages = adminWages;
        this.aoPto = aoPto;
        this.bcPto = bcPto;
        this.adminPto = adminPto;
        this.uniforms = uniforms;
        this.payrollTaxes = payrollTaxes;
        this.driverWorkersComp = driverWorkersComp;
        this.bcWorkersComp = bcWorkersComp;
        this.healthInsurance = healthInsurance;
        this.lifeInsurance = lifeInsurance;
        this.dentalAndVision = dentalAndVision;
        this.pensionAndProfitSharing = pensionAndProfitSharing;
        this.drugScreens = drugScreens;
        this.four01KMatch = four01KMatch;
        this.four01KAdmin = four01KAdmin;
        this.dotPhysicals = dotPhysicals;
        this.adminFee = adminFee;
        this.recruiting = recruiting;
        this.fuel = fuel;
        this.maintenance = maintenance;
        this.truckPayments = truckPayments;
        this.licenseAndRegistration = licenseAndRegistration;
        this.insurance = insurance;
        this.vanWashing = vanWashing;
        this.vanDecals = vanDecals;
        this.truckRentals = truckRentals;
        this.technology = technology;
        this.bankCharges = bankCharges;
        this.officeSupplies = officeSupplies;
        this.postage = postage;
        this.computer = computer;
        this.professional = professional;
        this.internet = internet;
        this.officeRent = officeRent;
        this.officeUtilities = officeUtilities;
        this.officeEquipmentLease = officeEquipmentLease;
        this.cellPhone = cellPhone;
        this.ePLI = ePLI;
        this.otherGA = otherGA;
        this.tolls = tolls;
        this.drivingTraining = drivingTraining;
        this.accidents = accidents;
        this.medical15K = medical15K;
        this.employeeMeals = employeeMeals;
        this.shopSupplies = shopSupplies;
        this.other = other;
    }

    static initDefault() {
        return new BudgetExpenses('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '');
    }

    static decode(json) {
        const decoder = new Decoder(json);
        const driverWageLight = decoder.decode('driverWageLight', Decoder.Decimal);
        const driverWageStandard = decoder.decode('driverWageStandard', Decoder.Decimal);
        const driverWageStraight = decoder.decode('driverWageStraight', Decoder.Decimal);
        const aoWages = decoder.decode('aoWages', Decoder.Decimal);
        const bcWages = decoder.decode('bcWages', Decoder.Decimal);
        const adminWages = decoder.decode('adminWages', Decoder.Decimal);
        const aoPto = decoder.decode('aoPto', Decoder.Decimal);
        const bcPto = decoder.decode('bcPto', Decoder.Decimal);
        const adminPto = decoder.decode('adminPto', Decoder.Decimal);
        const uniforms = decoder.decode('uniforms', Decoder.Decimal);
        const payrollTaxes = decoder.decode('payrollTaxes', Decoder.Decimal);
        const driverWorkersComp = decoder.decode('driverWorkersComp', Decoder.Decimal);
        const bcWorkersComp = decoder.decode('bcWorkersComp', Decoder.Decimal);
        const healthInsurance = decoder.decode('healthInsurance', Decoder.Decimal);
        const lifeInsurance = decoder.decode('lifeInsurance', Decoder.Decimal);
        const dentalAndVision = decoder.decode('dentalAndVision', Decoder.Decimal);
        const pensionAndProfitSharing = decoder.decode('pensionAndProfitSharing', Decoder.Decimal);
        const drugScreens = decoder.decode('drugScreens', Decoder.Decimal);
        const four01KMatch = decoder.decode('four01KMatch', Decoder.Decimal);
        const four01KAdmin = decoder.decode('four01KAdmin', Decoder.Decimal);
        const dotPhysicals = decoder.decode('dotPhysicals', Decoder.Decimal);
        const adminFee = decoder.decode('adminFee', Decoder.Decimal);
        const recruiting = decoder.decode('recruiting', Decoder.Decimal);
        const fuel = decoder.decode('fuel', Decoder.Decimal);
        const maintenance = decoder.decode('maintenance', Decoder.Decimal);
        const truckPayments = decoder.decode('truckPayments', Decoder.Decimal);
        const licenseAndRegistration = decoder.decode('licenseAndRegistration', Decoder.Decimal);
        const insurance = decoder.decode('insurance', Decoder.Decimal);
        const vanWashing = decoder.decode('vanWashing', Decoder.Decimal);
        const vanDecals = decoder.decode('vanDecals', Decoder.Decimal);
        const truckRentals = decoder.decode('truckRentals', Decoder.Decimal);
        const technology = decoder.decode('technology', Decoder.Decimal);
        const bankCharges = decoder.decode('bankCharges', Decoder.Decimal);
        const officeSupplies = decoder.decode('officeSupplies', Decoder.Decimal);
        const postage = decoder.decode('postage', Decoder.Decimal);
        const computer = decoder.decode('computer', Decoder.Decimal);
        const professional = decoder.decode('professional', Decoder.Decimal);
        const internet = decoder.decode('internet', Decoder.Decimal);
        const officeRent = decoder.decode('officeRent', Decoder.Decimal);
        const officeUtilities = decoder.decode('officeUtilities', Decoder.Decimal);
        const officeEquipmentLease = decoder.decode('officeEquipmentLease', Decoder.Decimal);
        const cellPhone = decoder.decode('cellPhone', Decoder.Decimal);
        const ePLI = decoder.decode('ePLI', Decoder.Decimal);
        const otherGA = decoder.decode('otherGA', Decoder.Decimal);
        const tolls = decoder.decode('tolls', Decoder.Decimal);
        const drivingTraining = decoder.decode('drivingTraining', Decoder.Decimal);
        const accidents = decoder.decode('accidents', Decoder.Decimal);
        const medical15K = decoder.decode('medical15K', Decoder.Decimal);
        const employeeMeals = decoder.decode('employeeMeals', Decoder.Decimal);
        const shopSupplies = decoder.decode('shopSupplies', Decoder.Decimal);
        const other = decoder.decode('other', Decoder.Decimal);

        if (decoder.checkForErrors()) {
            return new BudgetExpenses(driverWageLight, driverWageStandard, driverWageStraight, aoWages, bcWages, adminWages, aoPto, bcPto, adminPto, uniforms, payrollTaxes, driverWorkersComp, bcWorkersComp, 
                healthInsurance, lifeInsurance, dentalAndVision, pensionAndProfitSharing, drugScreens, 
                four01KMatch, four01KAdmin, dotPhysicals, adminFee, recruiting, fuel, maintenance, truckPayments, licenseAndRegistration, 
                insurance, vanWashing, vanDecals, truckRentals, technology, bankCharges, officeSupplies, postage, computer, professional, internet, officeRent, officeUtilities, officeEquipmentLease, cellPhone,
                ePLI, otherGA, tolls, drivingTraining, accidents, medical15K, employeeMeals, shopSupplies, other);
        } else {
            return BudgetExpenses.initDefault();
        }
    }

    encode() {
        return {
            driverWageLight: validateDecimalFixed(this.driverWageLight, 2),
            driverWageStandard: validateDecimalFixed(this.driverWageStandard, 2),
            driverWageStraight: validateDecimalFixed(this.driverWageStraight, 2),
            aoWages: validateDecimalFixed(this.aoWages, 2),
            bcWages: validateDecimalFixed(this.bcWages, 2),
            adminWages: validateDecimalFixed(this.adminWages, 2),
            aoPto: validateDecimalFixed(this.aoPto, 2),
            bcPto: validateDecimalFixed(this.bcPto, 2),
            adminPto: validateDecimalFixed(this.adminPto, 2),
            uniforms: validateDecimalFixed(this.uniforms, 2),
            payrollTaxes: validateDecimalFixed(this.payrollTaxes, 3),
            driverWorkersComp: validateDecimalFixed(this.driverWorkersComp, 3),
            bcWorkersComp: validateDecimalFixed(this.bcWorkersComp, 3),
            healthInsurance: validateDecimalFixed(this.healthInsurance, 2),
            lifeInsurance: validateDecimalFixed(this.lifeInsurance, 2),
            dentalAndVision: validateDecimalFixed(this.dentalAndVision, 2),
            pensionAndProfitSharing: validateDecimalFixed(this.pensionAndProfitSharing, 2),
            drugScreens: validateDecimalFixed(this.drugScreens, 2),
            four01KMatch: validateDecimalFixed(this.four01KMatch, 2),
            four01KAdmin: validateDecimalFixed(this.four01KAdmin, 2),
            dotPhysicals: validateDecimalFixed(this.dotPhysicals, 2),
            adminFee: validateDecimalFixed(this.adminFee, 3),
            recruiting: validateDecimalFixed(this.recruiting, 2),
            fuel: validateDecimalFixed(this.fuel, 2),
            maintenance: validateDecimalFixed(this.maintenance, 2),
            truckPayments: validateDecimalFixed(this.truckPayments, 2),
            licenseAndRegistration: validateDecimalFixed(this.licenseAndRegistration, 2),
            insurance: validateDecimalFixed(this.insurance, 2),
            vanWashing: validateDecimalFixed(this.vanWashing, 2),
            vanDecals: validateDecimalFixed(this.vanDecals, 2),
            truckRentals: validateDecimalFixed(this.truckRentals, 2),
            technology: validateDecimalFixed(this.technology, 2),
            bankCharges: validateDecimalFixed(this.bankCharges, 2),
            officeSupplies: validateDecimalFixed(this.officeSupplies, 2),
            postage: validateDecimalFixed(this.postage, 2),
            computer: validateDecimalFixed(this.computer, 2),
            professional: validateDecimalFixed(this.professional, 2),
            internet: validateDecimalFixed(this.internet, 2),
            officeRent: validateDecimalFixed(this.officeRent, 2),
            officeUtilities: validateDecimalFixed(this.officeUtilities, 2),
            officeEquipmentLease: validateDecimalFixed(this.officeEquipmentLease, 2),
            cellPhone: validateDecimalFixed(this.cellPhone, 2),
            ePLI: validateDecimalFixed(this.ePLI, 2),
            otherGA: validateDecimalFixed(this.otherGA, 2),
            tolls: validateDecimalFixed(this.tolls, 2),
            drivingTraining: validateDecimalFixed(this.drivingTraining, 2),
            accidents: validateDecimalFixed(this.accidents, 2),
            medical15K: validateDecimalFixed(this.medical15K, 2),
            employeeMeals: validateDecimalFixed(this.employeeMeals, 2),
            shopSupplies: validateDecimalFixed(this.shopSupplies, 2),
            other: validateDecimalFixed(this.other, 2),
        };
    }

    duplicate() {
        return new BudgetExpenses(
            this.driverWageLight,
            this.driverWageStandard,
            this.driverWageStraight,
            this.aoWages,
            this.bcWages,
            this.adminWages,
            this.aoPto,
            this.bcPto,
            this.adminPto,
            this.uniforms,
            this.payrollTaxes,
            this.driverWorkersComp,
            this.bcWorkersComp,
            this.healthInsurance,
            this.lifeInsurance,
            this.dentalAndVision,
            this.pensionAndProfitSharing,
            this.drugScreens,
            this.four01KMatch,
            this.four01KAdmin,
            this.dotPhysicals,
            this.adminFee,
            this.recruiting,
            this.fuel,
            this.maintenance,
            this.truckPayments,
            this.licenseAndRegistration,
            this.insurance,
            this.vanWashing,
            this.vanDecals,
            this.truckRentals,
            this.technology,
            this.bankCharges,
            this.officeSupplies,
            this.postage,
            this.computer,
            this.professional,
            this.internet,
            this.officeRent,
            this.officeUtilities,
            this.officeEquipmentLease,
            this.cellPhone,
            this.ePLI,
            this.otherGA,
            this.tolls,
            this.drivingTraining,
            this.accidents,
            this.medical15K,
            this.employeeMeals,
            this.shopSupplies,
            this.other
        );
    }

    equals(budgetExpenses) {
        return this.driverWageLight === budgetExpenses.driverWageLight && 
            this.driverWageStandard === budgetExpenses.driverWageStandard && 
            this.driverWageStraight === budgetExpenses.driverWageStraight && 
            this.aoWages === budgetExpenses.aoWages && 
            this.bcWages === budgetExpenses.bcWages && 
            this.adminWages === budgetExpenses.adminWages &&
            this.aoPto === budgetExpenses.aoPto && 
            this.bcPto === budgetExpenses.bcPto && 
            this.adminPto === budgetExpenses.adminPto && 
            this.uniforms === budgetExpenses.uniforms && 
            this.payrollTaxes === budgetExpenses.payrollTaxes && 
            this.driverWorkersComp === budgetExpenses.driverWorkersComp && 
            this.bcWorkersComp === budgetExpenses.bcWorkersComp && 
            this.healthInsurance === budgetExpenses.healthInsurance && 
            this.lifeInsurance === budgetExpenses.lifeInsurance && 
            this.dentalAndVision === budgetExpenses.dentalAndVision && 
            this.pensionAndProfitSharing === budgetExpenses.pensionAndProfitSharing && 
            this.drugScreens === budgetExpenses.drugScreens && 
            this.four01KMatch === budgetExpenses.four01KMatch && 
            this.four01KAdmin === budgetExpenses.four01KAdmin && 
            this.dotPhysicals === budgetExpenses.dotPhysicals && 
            this.adminFee === budgetExpenses.adminFee && 
            this.recruiting === budgetExpenses.recruiting && 
            this.fuel === budgetExpenses.fuel && 
            this.maintenance === budgetExpenses.maintenance && 
            this.truckPayments === budgetExpenses.truckPayments && 
            this.licenseAndRegistration === budgetExpenses.licenseAndRegistration && 
            this.insurance === budgetExpenses.insurance && 
            this.vanWashing === budgetExpenses.vanWashing && 
            this.vanDecals === budgetExpenses.vanDecals && 
            this.truckRentals === budgetExpenses.truckRentals && 
            this.technology === budgetExpenses.technology && 
            this.bankCharges === budgetExpenses.bankCharges && 
            this.officeSupplies === budgetExpenses.officeSupplies && 
            this.postage === budgetExpenses.postage && 
            this.computer === budgetExpenses.computer && 
            this.professional === budgetExpenses.professional && 
            this.internet === budgetExpenses.internet && 
            this.officeRent === budgetExpenses.officeRent && 
            this.officeUtilities === budgetExpenses.officeUtilities && 
            this.officeEquipmentLease === budgetExpenses.officeEquipmentLease && 
            this.cellPhone === budgetExpenses.cellPhone && 
            this.ePLI === budgetExpenses.ePLI && 
            this.otherGA === budgetExpenses.otherGA && 
            this.tolls === budgetExpenses.tolls && 
            this.drivingTraining === budgetExpenses.drivingTraining && 
            this.accidents === budgetExpenses.accidents && 
            this.medical15K === budgetExpenses.medical15K && 
            this.employeeMeals === budgetExpenses.employeeMeals && 
            this.shopSupplies === budgetExpenses.shopSupplies && 
            this.other === budgetExpenses.other
        ;
    }

    combineOtherExpenses(){
        return validateBig(this.bankCharges)
        .plus(validateBig(this.officeSupplies))
        .plus(validateBig(this.postage))
        .plus(validateBig(this.computer))
        .plus(validateBig(this.professional))
        .plus(validateBig(this.internet))
        .plus(validateBig(this.officeRent))
        .plus(validateBig(this.officeUtilities))
        .plus(validateBig(this.officeEquipmentLease))
        .plus(validateBig(this.cellPhone))
        .plus(validateBig(this.ePLI))
        .plus(validateBig(this.otherGA))
        .plus(validateBig(this.tolls))
        .plus(validateBig(this.drivingTraining))
        .plus(validateBig(this.accidents))
        .plus(validateBig(this.medical15K))
        .plus(validateBig(this.employeeMeals))
        .plus(validateBig(this.shopSupplies))
        .plus(validateBig(this.other))
    }

}