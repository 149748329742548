/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CustomToolTip from '../../../components/CustomToolTip';
import Table from 'react-bootstrap/Table';
import Cookies from 'universal-cookie';

export default function HRHirePermissions({handleSetPermissions, handleUseTemplate, permissions, permissionsTemplate}) {
    const userPermissions = new Cookies().get('permissions');
    const title = new Cookies().get('userData')['title'];

    const permissionRows = permissionsTemplate.filter(p => userPermissions[p.permissionName] == '1').map((p) => {
        return (
            <tr key={p.permissionName}>
                <td>
                    <span style={{marginRight: 8}}>{p.permissionTitle}</span>
                    <CustomToolTip text={p.description}/>
                </td>
                <td>
                    <Form.Check type='switch' checked={permissions[p.permissionName] === '1'} onChange={() => {handleSetPermissions(p.permissionName)}}/>
                </td>
                
            </tr>
        )
    });

    const staticPermissionRows = (
        <>
            <tr>
                <td>
                    <span style={{marginRight: 8}}>Profile</span>
                    <CustomToolTip text='Ability to view his or her own personal information, pay information, and ability to change his or her password'/>
                </td>
                <td>
                    <Form.Check type='switch' disabled={true} checked={true} onChange={() => {}}/>
                </td>
            </tr>
            <tr>
                <td>
                    <span style={{marginRight: 8}}>Time Clock</span>
                    <CustomToolTip text='Ability to view his or her own timesheet records, clock-in, and clock-out'/>
                </td>
                <td>
                    <Form.Check type='switch' disabled={true} checked={true} onChange={() => {}}/>
                </td>
            </tr>
            <tr>
                <td>
                    <span style={{marginRight: 8}}>My Schedule</span>
                    <CustomToolTip text='Ability to view his or her own personal calendar of routes, time off, etc.'/>
                </td>
                <td>
                    <Form.Check type='switch' disabled={true} checked={true} onChange={() => {}}/>
                </td>
            </tr>
            <tr>
                <td>
                    <span style={{marginRight: 8}}>Days Off</span>
                    <CustomToolTip text='Ability to view his or her own history of time off requests and their statuses (approved, rejected, pending); ability to request time off'/>
                </td>
                <td>
                    <Form.Check type='switch' disabled={true} checked={true} onChange={() => {}}/>
                </td>
            </tr>
        </>
    )

    const showBcTemplate = !permissionsTemplate.some(p =>{return p.bc == '1' && userPermissions[p.permissionName] != '1'});

    return (
        <>
            { (title === 'AO' || showBcTemplate) && 
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 12}}>
                    <div style={{display: 'flex', gap: 12, alignItems: 'center', border: '1px solid lightgray', borderRadius: 6, padding: 8, width: 'fit-content'}}>
                        <span>Default Permission Templates</span>
                        { title === 'AO' && 
                            <Button variant={'outline-primary'} onClick={() => {handleUseTemplate('ao')}}>Authorized Officer</Button>
                        }
                        <Button variant={'outline-primary'} onClick={() => {handleUseTemplate('bc')}}>Business Contact</Button>
                    </div>
                </div>
            }

            <Table responsive bordered striped size='sm'>
                <thead>
                    <tr>
                        <th>Permission</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {permissionRows}
                    {staticPermissionRows}
                </tbody>
            </Table>
        </>
    )
}

