import React from "react";
import moment from "moment";
import { Card } from "react-bootstrap";
import Wizard from '../../components/Wizard/Wizard.js';
import Modal from 'react-bootstrap/Modal';
import { usdFormatter } from "../../tools.js";
import PayrollEditor from "./PayrollEditor/PayrollEditor.js";
import PayrollPeriod from "./PayrollPeriod/PayrollPeriod.js";
import PageSpinner from "../../components/PageSpinner.js";
import CustomButton from '../../components/CustomButton.js';
import Table from 'react-bootstrap/Table';
import Cookies from "universal-cookie";
import Alert from 'react-bootstrap/Alert';
import QuickTable from "../../components/QuickTable.js";
import { ApiRequest } from "../../ApiManager.tsx";

export default class Payroll extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            periods: [],
            selectedPayPeriod: undefined,
            modalSwitch: 'none',
            bcPayrollEnabled: false,
            restrictBcsToCsa: false,
            userTitle: '',
            numberOfCsas: 0,
        }

        this.loadData = this.loadData.bind(this);
        // this.isAo = cookieMonster.get('userData').title === 'AO';
        this.companyName = new Cookies().get('companyName');
        // this.isBC = cookieMonster.get('userData').title === 'BC';
    }

    sortPayrollPeriods(a, b) {
        if (moment(a.periodStart).isBefore(moment(b.periodStart), 'days')) {
            return 1;
        } else if (moment(b.periodStart).isBefore(moment(a.periodStart), 'days')) {
            return -1;
        } else {
            if (a.isCustom == '1') {
                return -1;
            } else if (b.isCustom == '1') {
                return 1;
            } else {
                return 0;
            }
        }
    }

    loadData() {
        new ApiRequest('payroll', 'getHistory', (value) => {this.setState({isLoading: value})}, (response) => {
            this.setState({
                periods: response.data.sort(this.sortPayrollPeriods), 
                bcPayrollEnabled: response.bcPayrollEnabled, 
                restrictBcsToCsa:response.restrictBcsToCsa, 
                userTitle: response.userTitle,
                numberOfCsas: response.numberOfCsas
            });
        }).withNoAlertOnSuccess().send();
    }

    componentDidMount() {
        this.loadData();
    }

    payrollStatusUncompletedKey = ['Pending', 'Ready for Review', 'Sent to TTA'];
    payrollStatusCompletedKey = ['Pending', 'Reviewed', 'Approved by TTA'];
    payrollStatusKey = ['Pending', 'Ready for Review', 'Sent to Payroll', 'Approved by TTA'];

    bcPayrollUncompletedKey = ['Pending', 'Ready for Review', 'Sent to AO for Review', 'Sent to TTA'];
    bcPayrolCompletedKey = ['Pending', 'Reviewed', 'Approved by AO', 'Approved by TTA'];


    render() {
        

        const customPayrollPeriods = this.state.periods.filter(p => p.isCustom == '1');
        const currentCustomPayrollPeriods = customPayrollPeriods.filter(p => parseInt(p.status) < 3);

        const nonCustomPayrollPeriods = this.state.periods.filter(p => p.isCustom == '0');
        const currentPayrollPeriods = nonCustomPayrollPeriods.filter(p => parseInt(p.status) < 3);

        const historicPayrollPeriods = this.state.periods.filter(p => parseInt(p.status) >= 3);

        const currentPayrollCards = currentPayrollPeriods.map((period) => {
            const readyForAoReview = 
                !this.state.bcPayrollEnabled ? true
                : this.state.restrictBcsToCsa && !period.csaIdentifier ? this.state.numberOfCsas == period.bcData.filter(b => b.entries).length
                : period.bcData.find(b => b.entries)
            ;
            const csaRestricted = period.csaIdentifier ? true : false;

            const showGross = parseInt(period.status) > 1 && (this.state.userTitle !== 'BC' || !this.state.restrictBcsToCsa);


            return (
                <Card key={period.uid} style={{marginBottom: 12}}>
                    <Card.Body style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Card.Title>{`${period.csaName ? `${period.csaName} | ` : ''}${moment(period.periodStart).format('MMM D')} - ${moment(period.periodEnd).format('MMM D, YYYY')}`}</Card.Title>
                            { this.state.bcPayrollEnabled && this.state.userTitle === 'AO' &&
                                <>
                                    { this.state.restrictBcsToCsa && !csaRestricted ?
                                        <Alert style={{marginBottom: 0, padding: 6}} variant={readyForAoReview ? 'success' : 'warning'}>
                                            {'(' + period.bcData.reduce((prev, curr) => prev + (curr.entries ? 1 : 0), 0) + ' / ' + this.state.numberOfCsas + ') CSAs completed by BCs'}
                                        </Alert>
                                        :
                                        <Alert style={{marginBottom: 0, padding: 6}} variant={readyForAoReview ? 'success' : 'warning'}>
                                            {readyForAoReview ? 'This period has been completed by the BC' : 'This period has not yet been completed by the BC'}
                                        </Alert>
                                    }
                                </>
                            }
                            { period.isEditable ?
                                <CustomButton label={readyForAoReview || this.state.userTitle === 'BC' ? 'Review Payroll Period' : 'Review Payroll Period Early'} onClick={() => {this.setState({modalSwitch: 'editor', selectedPayPeriod: period})}}/>
                                : period.isViewable ?
                                    <CustomButton label='View Payroll Period' onClick={() => {this.setState({modalSwitch: 'viewer', selectedPayPeriod: period})}}/>
                                : 
                                    <span style={{fontStyle:'italic', opacity:0.5}}>
                                        {`${this.state.userTitle == 'AO' ? '' : 'The AO or '}TTA has taken over this payroll period`}
                                    </span>
                            }
                        </div>
                        
                        <div style={{minWidth: 500, marginBottom: 12, marginTop: 12}}>
                            { this.state.bcPayrollEnabled && this.state.userTitle === 'BC' ?
                                <Wizard 
                                    steps={this.bcPayrollUncompletedKey} 
                                    completedSteps={this.bcPayrolCompletedKey} 
                                    stepIndex={!period.isEditable ? parseInt(period.status) + 1 : parseInt(period.status)}
                                />
                                :
                                <Wizard 
                                    steps={this.payrollStatusUncompletedKey} 
                                    completedSteps={this.payrollStatusCompletedKey} 
                                    stepIndex={parseInt(period.status)}
                                />
                            }
                        </div>
                        { showGross &&
                            <Card.Title style={{alignSelf: 'flex-end', fontSize: 25, marginBottom: 0}}>{'Gross: ' + usdFormatter.format(period.gross)}</Card.Title>
                        }
                    </Card.Body>
                </Card>
            )
        })

        const payrollHistoryElements = historicPayrollPeriods.map((period) => {
            return (
                <tr className="cursor-pointer" key={period.uid} onClick={() => {
                        if (period.isEditable) {
                            this.setState({selectedPayPeriod: period, modalSwitch: 'editor'});
                        } else if (period.isViewable) {
                            this.setState({selectedPayPeriod: period, modalSwitch: 'viewer'});
                        }
                    }}>
                    <td>{moment(period.periodStart).format('MMM D') + ' - ' + moment(period.periodEnd).format('MMM D, YYYY')}</td>
                    <td>{period.csaName ?? 'All'}</td>
                    <td>{period.gross ? usdFormatter.format(period.gross) : 'N/A'}</td>
                </tr>
            )
        });
       

        const customPayrollCards = currentCustomPayrollPeriods.map((period)=>{

            return (
                <Card key ={period.uid} style={{marginBottom:12}}>
                    <Card.Body style={{display:'flex', flexDirection:'column'}}>
                        <div style={{display:'flex', justifyContent:'space-between'}}>
                            <Card.Title>{`Custom Period | ${moment(period.periodStart).format('MMM D')} - ${moment(period.periodEnd).format('MMM D, YYYY')}`}</Card.Title>
                            {period.isEditable ?
                                    <CustomButton label='Review Custom Payroll Period' onClick={() => {this.setState({modalSwitch: 'editor', selectedPayPeriod: period})}}/>
                                : period.isViewable ?
                                    <CustomButton label='View Custom Payroll Period' onClick={() => {this.setState({modalSwitch: 'viewer', selectedPayPeriod: period})}}/>
                                : 
                                    <span style={{fontStyle:'italic', opacity:0.5}}>TTA has taken over this payroll period</span>
                            } 
                        </div>
                        <div style={{minWidth: 500, marginBottom: 12, marginTop: 12}}>
                            <Wizard steps={this.payrollStatusUncompletedKey} completedSteps={this.payrollStatusCompletedKey} stepIndex={parseInt(period.status)}/>
                        </div>
                        { parseInt(period.status) > 1 &&
                            <Card.Title style={{alignSelf: 'flex-end', fontSize: 25, marginBottom: 0}}>{'Gross: ' + usdFormatter.format(period.gross)}</Card.Title>
                        }
                    </Card.Body>
                </Card>
            )
        })        
   
        const content = this.state.isLoading ? '' : (
            <>
                { (this.state.bcPayrollEnabled || this.state.userTitle !== 'BC') &&
                    currentPayrollCards
                }
                {customPayrollCards.length > 0 && this.state.userTitle === 'AO' &&
                    customPayrollCards
                }
                
                { (this.state.bcPayrollEnabled || this.state.userTitle !== 'BC') &&
                    <Card style={{flex: 1}}>
                        <Card.Body>
                            <Card.Title>Previous Pay Periods</Card.Title>
                            <QuickTable hover headers={['Period', 'CSA', 'Gross']} rows={payrollHistoryElements}/>
                        </Card.Body>
                    </Card>
                }
                
                <Modal show={this.state.modalSwitch === 'editor'} backdrop='static' fullscreen centered>
                    <PayrollEditor 
                        selectedPayPeriod={this.state.selectedPayPeriod} 
                        hideModal={() => {this.setState({modalSwitch: 'none'})}} 
                        loadData={this.loadData}
                        companyName={this.companyName}
                        isBc={this.state.userTitle === 'BC'}
                    />
                </Modal>
                <Modal show={this.state.modalSwitch === 'viewer'} fullscreen centered onHide={() => {this.setState({modalSwitch: 'none'})}}>
                    <PayrollPeriod selectedPayPeriod={this.state.selectedPayPeriod} companyName={this.companyName}/>
                </Modal>
                
            </>
        );
    
    
        return (
            <div className="page-wrapper" style={{padding: 12, display:'flex', flexDirection:'column'}}>
                {this.state.isLoading ? <PageSpinner/> : content}
            </div>
        )
    }
}