/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import CustomToolTip from '../../components/CustomToolTip';
import CustomButton from '../../components/CustomButton';
import Cookies from 'universal-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { ApiRequest } from '../../ApiManager.tsx';
import LoadingWrapper from '../../components/LoadingWrapper.js';

export default function HRUserPermissions({selectedEmployee}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [employeePermissions, setEmployeePermissions] = useState({});
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const userPermissions = new Cookies().get('permissions');
    const title = new Cookies().get('userData')['title'];

    useEffect(() => {
        loadData();
    }, [selectedEmployee])

    function loadData(){
        new ApiRequest('hr', 'getPermissions', setIsLoading, (response) => {
            setPermissions(response.template);
            setEmployeePermissions(response.data);
        }).withData({companyUserIdentifier: selectedEmployee.companyUserUid}).withNoAlertOnSuccess().send();
    }

    function handleSubmit(){
        new ApiRequest('hr', 'updatePermissions', setIsSubmitting).withData({permissions: employeePermissions}).send();
    }

    function handlePermissionChange(permissionName) {
        const newPermissions = structuredClone(employeePermissions);
        newPermissions[permissionName] = newPermissions[permissionName] == '0' ? '1' : '0';
        setEmployeePermissions(newPermissions)
    }

    function handleUseTemplate() {
        const newPermissions = structuredClone(employeePermissions);
        permissions.forEach((permObj) => newPermissions[permObj.permissionName] = permObj[selectedTemplate]);
        setEmployeePermissions(newPermissions);
        setSelectedTemplate(undefined);
    }

    const permissionRows = permissions.filter(p => userPermissions[p.permissionName] == '1').map((p) => {
        return (
            <tr key={p.permissionName}>
                <td>
                    <span style={{marginRight: 8}}>{p.permissionTitle}</span>
                    <CustomToolTip text={p.description}/>
                </td>
                <td>
                    <Form.Check type='switch' checked={employeePermissions[p.permissionName] === '1'} onChange={() => handlePermissionChange(p.permissionName)}/>
                </td>
            </tr>
        )
    });

    const staticPermissionRows = (
        <>
            <tr>
                <td>
                    <span style={{marginRight: 8}}>Profile</span>
                    <CustomToolTip text='Ability to view his or her own personal information, pay information, and ability to change his or her password'/>
                </td>
                <td>
                    <Form.Check type='switch' disabled={true} checked={true} onChange={() => {}}/>
                </td>
            </tr>
            <tr>
                <td>
                    <span style={{marginRight: 8}}>Time Clock</span>
                    <CustomToolTip text='Ability to view his or her own timesheet records, clock-in, and clock-out'/>
                </td>
                <td>
                    <Form.Check type='switch' disabled={true} checked={true} onChange={() => {}}/>
                </td>
            </tr>
            <tr>
                <td>
                    <span style={{marginRight: 8}}>My Schedule</span>
                    <CustomToolTip text='Ability to view his or her own personal calendar of routes, time off, etc.'/>
                </td>
                <td>
                    <Form.Check type='switch' disabled={true} checked={true} onChange={() => {}}/>
                </td>
            </tr>
            <tr>
                <td>
                    <span style={{marginRight: 8}}>Days Off</span>
                    <CustomToolTip text='Ability to view his or her own history of time off requests and their statuses (approved, rejected, pending); ability to request time off'/>
                </td>
                <td>
                    <Form.Check type='switch' disabled={true} checked={true} onChange={() => {}}/>
                </td>
            </tr>
        </>
    )

    const showBcTemplate = !permissions.some(p =>{return p.bc == '1' && userPermissions[p.permissionName] != '1'});

    const content = (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            { (title === 'AO' || showBcTemplate) && 
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 12}}>
                    <div style={{display: 'flex', gap: 12, alignItems: 'center', border: '1px solid lightgray', borderRadius: 6, padding: 8, width: 'fit-content'}}>
                        <span>Default Permission Templates</span>
                        { title === 'AO' && 
                            <Button variant={'outline-primary'} onClick={() => {setSelectedTemplate('ao')}}>Authorized Officer</Button>
                        }
                        <Button variant={'outline-primary'} onClick={() => {setSelectedTemplate('bc')}}>Business Contact</Button>
                    </div>
                </div>
            }
            <Table responsive bordered striped size='sm'>
                <thead>
                    <tr>
                        <th>Permission</th>
                        <th></th>
                        
                    </tr>
                </thead>
                <tbody>
                    {permissionRows}
                    {staticPermissionRows}
                </tbody>
            </Table>
            <div style={{alignSelf: 'flex-end'}}>
                <CustomButton label='Save Changes' isLoading={isSubmitting} onClick={handleSubmit}/>
            </div>
            <Modal show={selectedTemplate} centered onHide={() => setSelectedTemplate(undefined)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Load Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {'Replace current permissions with the default values for a(n) ' + selectedTemplate + '?'}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={'outline-primary'} onClick={handleUseTemplate}>Confirm</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );

    return (
        <LoadingWrapper isLoading={isLoading}>
            {content}
        </LoadingWrapper>
    )
}