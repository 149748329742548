/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RadioControl from '../../../components/RadioControl.js';
import CustomControl from '../../../components/CustomControl.js';
import { addressToString, availabilityToBinaryString, availabilityToString, defaultOfferLetter, employeeTypeDictionary, formatPhoneNumber, formatTimeNew, parseAvailability, payTypeDictionary, ptoAccrualTypeDictionary, usdFormatter, validateDecimal, validateDecimalFixed, validateInteger } from '../../../tools.js';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import CustomButton from '../../../components/CustomButton.js';
import { InputGroup, Dropdown, Popover} from 'react-bootstrap';
import { validateExistence, validateGreaterThanZero, validatePositiveNumber, ValidationGroup } from '../../../validation.js';
import TextAreaControl from '../../../components/TextAreaControl.js';
import BwcCodeControl from '../../../components/BwcCodeControl.js';
import AdditionalPay from '../../Payroll/Models/AdditionalPay.js';
import Deduction from '../../Payroll/Models/Deduction.js';
import QuickTable from '../../../components/QuickTable.js';
import { faTrashCan, faPlus } from '@fortawesome/free-solid-svg-icons';
import SwitchControl from '../../../components/SwitchControl.js';
import DropdownItemWithCheck from '../../../components/DropdownItemWithCheck.js';
import OfferLetterEditor from '../../../components/OfferLetterEditor.js';
import { ApiRequest } from '../../../ApiManager.tsx';
import LoadingWrapper from '../../../components/LoadingWrapper.js';
import ChildSupportControl from '../../../components/ChildSupportControl.js';
import AutomaticDeductionControl from '../../../components/AutomaticDeductionControl.js';
import AutomaticReimbursementControl from '../../../components/AutomaticReimbursementControl.js';


export default function HRUserPayEditor({companyUserIdentifier, handleAddPay, allPay}){
    const [date, setDate] = useState('');
    const [title, setTitle] = useState('');
    const [payRate, setPayRate] = useState('');
    const [payType, setPayType] = useState('');
    const [hourlyWage, setHourlyWage] = useState('');
    const [notes, setNotes] = useState('');
    const [employeeType, setEmployeeType] = useState('');
    const [medical, setMedical] = useState('');
    const [dental, setDental] = useState('');
    const [vision, setVision] = useState('');
    const [childSupport, setChildSupport] = useState([]);
    const [automaticDeductions, setAutomaticDeductions] = useState([]);
    const [automaticReimbursement, setAutomaticReimbursement] = useState([]);
    const [bwcCode, setBwcCode] = useState('');
    const [pto, setPto] = useState('');
    const [stopBonusWeekdayThreshold, setStopBonusWeekdayThreshold] = useState(0);
    const [stopBonusWeekendThreshold, setStopBonusWeekendThreshold] = useState(0);
    const [stopBonusWeekdayAmount, setStopBonusWeekdayAmount] = useState(0);
    const [stopBonusWeekendAmount, setStopBonusWeekendAmount] = useState(0);
    const [sendOfferLetter, setSendOfferLetter] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [ptoAccrualType, setPtoAccrualType] = useState('');
    const [company, setCompany] = useState({})
    const [csa, setCsa] = useState(null);
    const [offerLetters, setOfferLetters] = useState([])
    const [offerLetter, setOfferLetter] = useState(defaultOfferLetter)
    const [selectedOfferLetter, setSelectedOfferLetter] = useState(-1);
    const [showOfferLetter, setShowOfferLetter] = useState(false);
    const [signers, setSigners] = useState([]);
    const [supervisorName, setSupervisorName] = useState('');
    const [startTime, setStartTime] = useState('08:00');
    const [dailyStartTime, setDailyStartTime] = useState('08:00');
    const [selectedAo, setSelectedAo] = useState(null)
    const [startDate, setStartDate] = useState('');
    const [userInfo, setUserInfo] = useState(null);
    const validationGroup = new ValidationGroup();

    useEffect(()=>{
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('hr', 'getCompanyAndOfferLetters', setIsLoading, (response) => {
            response.offerLetters.forEach(ol=>{
                const associatedDefaults = [];

                if(response.company.defaultOfferLetterForDriver == ol.uid)
                    associatedDefaults.push('Driver')
                if(response.company.defaultOfferLetterForAvpDriver == ol.uid)
                    associatedDefaults.push('AVP Driver')
                if(response.company.defaultOfferLetterForJumper == ol.uid)
                    associatedDefaults.push('Jumper')
                if(response.company.defaultOfferLetterForBc == ol.uid)
                    associatedDefaults.push('BC')
                if(response.company.defaultOfferLetterForAdmin == ol.uid)
                    associatedDefaults.push('Admin')

                ol.associatedDefaults = associatedDefaults;
            })
            setOfferLetters(response.offerLetters);
            setSigners(response.signerCandidates);
            setCompany(response.company);
            setCsa(response.csa);
            setUserInfo(response.userInfo);
        }).withData({companyUserIdentifier: companyUserIdentifier}).withNoAlertOnSuccess().send();
    }

    function handleCreateUserPay(){
        let offerLetterToSend = {};
        const newPay = {
            companyUserIdentifier: companyUserIdentifier,
            date: date,
            title: title,
            payType: payType,
            payRate: payRate,
            hourlyWage: hourlyWage,
            notes: notes,
            employeeType: employeeType,
            medical: medical,
            dental: dental,
            vision: vision,
            childSupport: JSON.stringify(childSupport.map(cs => validateDecimal(cs) > 0 ? validateDecimal(cs) : 0).filter(cs => cs)),
            bwcCode: bwcCode,
            ptoAccrual: pto,
            ptoAccrualType: ptoAccrualType,
            stopBonusWeekdayThreshold: stopBonusWeekdayThreshold,
            stopBonusWeekendThreshold: stopBonusWeekendThreshold,
            stopBonusAmount: stopBonusWeekdayAmount,
            stopBonusWeekendAmount: stopBonusWeekendAmount,
            automaticReimbursement: JSON.stringify(automaticReimbursement.map(ar => {return {label: ar.label, amount: validateDecimal(ar.amount) > 0 ? validateDecimal(ar.amount) : 0}}).filter(ar => ar.amount)),
            automaticDeductions: JSON.stringify(automaticDeductions.map(ad => {return {label: ad.label, amount: validateDecimal(ad.amount) > 0 ? validateDecimal(ad.amount) : 0}}).filter(ad => ad.amount)),
            sendOfferLetter: sendOfferLetter
        };

        if (sendOfferLetter) {
            const ao = signers.find(s => s.uid == selectedAo);
            offerLetterToSend = {
                title: `${userInfo?.firstName} ${userInfo?.lastName} Offer Letter`,
                content: offerLetter,
                companyName: company.companyName,
                employeeType: employeeTypeDictionary[employeeType],
                startDate: startDate,
                supervisorName: supervisorName,
                terminalAddress: csa.address,
                startTime: startTime,
                payType: payType,
                payRate: payRate,
                stopBonusWeekdayThreshold: stopBonusWeekdayThreshold,
                stopBonusWeekendThreshold: stopBonusWeekendThreshold,
                stopBonusWeekdayAmount: stopBonusWeekendAmount,
                stopBonusWeekendAmount: stopBonusWeekendAmount,
                expectedAvailability: availabilityToBinaryString(userInfo.availability),
                dailyStartTime: dailyStartTime,
                benefits: company.benefits,
                aoName: `${ao.firstName} ${ao.lastName}`,
                aoPhoneNumber: ao.phoneNumber,
                aoEmail: ao.email,
            };
        }
        new ApiRequest('hr', 'createPay', setIsSubmitting, (response) => {newPay.uid = response.uid; handleAddPay(newPay)}).withData({data: newPay, offerLetter: offerLetterToSend}).send();
    }

    function handlePrefill(pay){
        setDate(pay.date);
        setTitle(pay.title);
        setPayRate(pay.payRate ?? '');
        setPayType(pay.payType ?? '');
        setHourlyWage(pay.hourlyWage ?? '');
        setNotes(pay.notes ?? '');
        setEmployeeType(pay.employeeType ?? '');
        setMedical(pay.medical ?? '');
        setDental(pay.dental ?? '');
        setVision(pay.vision ?? '');
        setChildSupport(pay.childSupport ? JSON.parse(pay.childSupport) : []);
        setAutomaticDeductions(pay.automaticDeductions ? JSON.parse(pay.automaticDeductions) : []);
        setAutomaticReimbursement(pay.automaticReimbursement ? JSON.parse(pay.automaticReimbursement) : []);
        setBwcCode(pay.bwcCode ?? '');
        setPto(pay.ptoAccrual ?? '');
        setStopBonusWeekdayThreshold(pay.stopBonusWeekdayThreshold ?? 0);
        setStopBonusWeekendThreshold(pay.stopBonusWeekendThreshold ?? 0);
        setStopBonusWeekdayAmount(pay.stopBonusAmount ?? 0);
        setStopBonusWeekendAmount(pay.stopBonusWeekendAmount ?? 0);
        setPtoAccrualType(pay.ptoAccrualType ?? '')
    }

    const allPayDropdownItems = allPay.map((p) => {
        return (
            <Dropdown.Item key={p.uid} onClick={() => {handlePrefill(p)}}>
                {`${moment(p.date).format('MMM D, YYYY')} - ${p.title}`}
            </Dropdown.Item>
        )
    })

    const offerLetterAos = signers.filter(s => s.title === 'AO');
    const offerLetterElements = offerLetters.map((ol) => {
        return (
            <Dropdown.Item key={ol.uid} onClick={() => {setOfferLetter(ol.content); setSelectedOfferLetter(ol.uid)}}>{ol.name}</Dropdown.Item>
        )
    })
    
    const bcRecommendationPopover = (
        <Popover style={{position:'fixed'}}>
            <Popover.Header></Popover.Header>
            <Popover.Body>
                <p style={{fontWeight:'bold'}}>Pay Tips</p>
                <ul>
                    <li>BC's recommended Pay Type is Per Year</li>
                    <li>Employees paid Per Year may only qualify for FLSA overtime if their Pay Rate is $35568.00 or below</li>
                </ul>
            </Popover.Body>
        </Popover>
    )
    
    const bcOverlay = (
        <OverlayTrigger  overlay={bcRecommendationPopover} placement="bottom">
            <FontAwesomeIcon size='lg' style={{color:'darkorange'}} icon={faCircleInfo}/>
        </OverlayTrigger>
    )

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>
                    Creating Pay
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoadingWrapper isLoading={isLoading}>
                    <Dropdown style={{alignSelf: 'flex-end'}}>
                        <Dropdown.Toggle style={{display: 'block', marginLeft: 'auto', marginBottom: 12}} variant='outline-primary'>Prefill With Existing Pay</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Header>All Pay</Dropdown.Header>
                            {allPayDropdownItems}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Row style={{border: '1px solid lightgray', borderRadius: 6, padding: 6, margin: 0, marginBottom: 18}}>
                        <Col xxl={12}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <p style={{margin: 0, padding: 6}}>Pay Details</p>
                                <div style={{display:'flex', justifyContent:'flex-end', padding:5}}>
                                    {bcOverlay}
                                </div>
                            </div>
                        </Col>
                        <CustomControl lg={4} disabled={isLoading} title='Date Effective' type='date' value={date} onChange={(event) => { setDate(event.target.value); }} validator={validationGroup.createValidator(()=>validateExistence(date))}/>
                        <CustomControl lg={4} maxDecimal={1000000} disabled={isLoading} title='Pay Rate' type='number' value={payRate} onChange={(event) => { setPayRate(event.target.value); }} validator={validationGroup.createValidator(()=>validatePositiveNumber(payRate))}/>
                        <CustomControl lg={4} maxDecimal={1000} disabled={isLoading} title='Hourly Wage' type='number' value={hourlyWage} onChange={(event) => { setHourlyWage(event.target.value); }} validator={validationGroup.createValidator(()=>validatePositiveNumber(hourlyWage))}/>
                        <Col xxl={12} style={{ marginBottom: 8 }}>
                            <RadioControl disabled={isLoading} title='Pay Type' inline selection={payType} setSelection={setPayType} optionNames={Object.values(payTypeDictionary)} optionValues={Object.keys(payTypeDictionary)} validator={validationGroup.createValidator(()=>validateExistence(payType))}/>
                        </Col>
                        <Col lg={6} style={{ marginBottom: 8 }}>
                            <RadioControl disabled={isLoading} title='Employee Type' inline selection={employeeType} setSelection={setEmployeeType} optionNames={['Full-Time', 'Part-Time']} optionValues={[0, 1]} validator={validationGroup.createValidator(()=>validateExistence(employeeType))}/>
                        </Col>
                        <Col lg={6} style={{ marginBottom: 8 }}>
                            <RadioControl disabled={isLoading} title='Title' inline selection={title} setSelection={setTitle} optionNames={['Driver', 'AVP Driver', 'Jumper', 'BC', 'Admin']} optionValues={['Driver', 'AVP Driver', 'Jumper', 'BC', 'Admin']} validator={validationGroup.createValidator(()=>validateExistence(title))}/>
                        </Col>
                        <BwcCodeControl disabled={isLoading} inline value={bwcCode} setValue={(value) => {setBwcCode(value)}} lg={6} validator={validationGroup.createValidator(() => validateExistence(bwcCode))}/>
                        <Col lg={6} style={{paddingBottom:10}}>
                            <div style={{marginBottom:8}}>
                                <span>PTO</span>
                            </div>
                            <div style={{border: '1px solid lightgray', borderRadius: 6,padding: 12,marginBottom: 8}}>
                                <CustomControl maxDecimal={1000000} disabled={isLoading} title='PTO Accrual (In Hours)' type='number' value={pto} onChange={(event) => { setPto(event.target.value); }} validator={validationGroup.createValidator(()=>validateExistence(pto))}/>
                                <div style={{marginTop:5}}>
                                    <RadioControl disabled={isLoading} title='PTO Type' inline selection={ptoAccrualType} setSelection={setPtoAccrualType} optionNames={Object.values(ptoAccrualTypeDictionary)} optionValues={[0, 1]} validator={validationGroup.createValidator(()=>validateExistence(ptoAccrualType))}/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                        <Row style={{border: '1px solid lightgray', borderRadius: 6, padding: 6, margin: 0, marginBottom: 18}}>
                            <Col xxl={12}><p style={{margin: 0, padding: 6}}>Stop Thresholds</p></Col>
                            <Col lg={6}>
                                <CustomControl maxInt={1000000} disabled={isLoading} title={'Weekday Stop Threshold'} type='number' value={stopBonusWeekdayThreshold} onChange={(event) => setStopBonusWeekdayThreshold(event.target.value)} validator={validationGroup.createValidator(()=>validateExistence(stopBonusWeekdayThreshold))}/> 
                                <div style={{paddingBottom:10}}>
                                    <span style={{fontSize:12}}>(Employee will recieve bonus for all stops above threshold)</span>
                                </div>
                            </Col>
                            <Col lg={6}>
                            <CustomControl maxInt={1000000} disabled={isLoading} title='Weekend Stop Threshold' type='number' value={stopBonusWeekendThreshold} onChange={(event) => setStopBonusWeekendThreshold(event.target.value)} validator={validationGroup.createValidator(()=>validateExistence(stopBonusWeekendThreshold))}/>
                                <div style={{paddingBottom:10}}>
                                    <span style={{fontSize:12}}>(Employee will recieve bonus for all stops above threshold)</span>
                                </div> 
                            </Col>
                        </Row>
                        <Row style={{border: '1px solid lightgray', borderRadius: 6, padding: 6, margin: 0, marginBottom: 18}}>
                            <Col xxl={12}><p style={{margin: 0, padding: 6}}>Stop Wages</p></Col>
                            <Col lg={6}>
                                <CustomControl maxDecimal={10000} disabled={isLoading} title='Weekday Stop Wage Dollar Amount' type='number' value={stopBonusWeekdayAmount} onChange={(event) => setStopBonusWeekdayAmount(event.target.value)} validator={validationGroup.createValidator(()=>validateExistence(stopBonusWeekdayAmount))}/> 
                                <div style={{paddingBottom:10}}>
                                    <span style={{fontSize:12}}>(Dollar amount per stop for all stops beyond the threshold)</span>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <CustomControl maxDecimal={10000} disabled={isLoading} title='Weekend Stop Wage Dollar Amount' type='number' value={stopBonusWeekendAmount} onChange={(event) => setStopBonusWeekendAmount(event.target.value)} validator={validationGroup.createValidator(()=>validateExistence(stopBonusWeekendAmount))}/>
                                <div style={{paddingBottom:10}}>
                                    <span style={{fontSize:12}}>(Dollar amount per stop for all stops beyond the threshold)</span>
                                </div> 
                            </Col>
                        </Row>
                    <Row style={{border: '1px solid lightgray', borderRadius: 6, padding: 6, margin: 0, marginBottom: 18}}>
                        <Col xxl={12}><p style={{margin: 0, padding: 6}}>Deductions</p></Col>
                        <CustomControl lg={4} maxDecimal={1000000} disabled={isLoading} title='Medical Insurance' type='number' value={medical} onChange={(event) => { setMedical(event.target.value);}}/>
                        <CustomControl lg={4} maxDecimal={1000000} disabled={isLoading} title='Dental Insurance' type='number' value={dental} onChange={(event) => { setDental(event.target.value);}}/>
                        <CustomControl lg={4} maxDecimal={1000000} disabled={isLoading} title='Vision Insurance' type='number' value={vision} onChange={(event) => { setVision(event.target.value);}}/>
                        <ChildSupportControl value={childSupport} setValue={setChildSupport} breakpoints={{xxl: 12}}/>
                        <AutomaticDeductionControl value={automaticDeductions} setValue={setAutomaticDeductions} breakpoints={{xxl: 12}}/>
                    </Row>
                    <Row style={{border: '1px solid lightgray', borderRadius: 6, padding: 6, margin: 0, marginBottom: 18}}>
                        <Col xxl={12}><p style={{margin: 0, padding: 6}}>Reimbursements</p></Col>
                        <AutomaticReimbursementControl value={automaticReimbursement} setValue={setAutomaticReimbursement} breakpoints={{xxl: 12}}/>
                    </Row>
                    <Row>
                        <Col xxl={12} style={{ marginBottom: 8 }}>
                            <TextAreaControl disabled={isLoading} label='Notes' max={65535} setValue={(event)=>setNotes(event.target.value)} value={notes} rows={6}/>
                        </Col>
                            <InputGroup style={{justifyContent: 'center', margin: '20px 0'}}>
                                <SwitchControl title='Send Offer Letter?' disabled={isLoading} value={sendOfferLetter} setValue={setSendOfferLetter}/>
                                {sendOfferLetter &&
                                    <>
                                        <Dropdown>
                                            <Dropdown.Toggle variant='outline-primary'>
                                            {`Offer Letter: ${selectedOfferLetter == -1 ? 'Default' : offerLetters.find(ol=>ol.uid == selectedOfferLetter)?.name ?? 'Custom'}`}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                            <DropdownItemWithCheck active={selectedOfferLetter == -1} label='TTA Default' onClick={()=>{setSelectedOfferLetter(-1); setOfferLetter(defaultOfferLetter)}}/>
                                            {offerLetters.map(ol=>{
                                                return <DropdownItemWithCheck key={ol.uid} active={selectedOfferLetter == ol.uid} label={`${ol.name}${ol.associatedDefaults.length ? ` (${ol.associatedDefaults.join(', ')})` : ''}`} onClick={()=>{setSelectedOfferLetter(ol.uid); setOfferLetter(ol.content)}}/>
                                            })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Button variant='outline-primary' onClick={() => {setShowOfferLetter(true)}}>
                                            <span>Edit Offer Letter</span>
                                        </Button>
                                    </>
                                }
                            </InputGroup>
                            {sendOfferLetter && 
                                <div style={{border: '1px solid lightgray', padding: 12, borderRadius: 6}}>
                                    <h5 style={{textAlign: 'center'}}>Required Information For Offer Letter</h5>
                                    <Container fluid style={{padding: 0}}>
                                        <Row>
                                            <CustomControl lg={6} title='Employment Start Date' value={startDate} onChange={(event) => {setStartDate(event.target.value)}} type='date' validator={validationGroup.createValidator(() => validateExistence(startDate))}/>
                                            <CustomControl lg={6} title='Name of Immediate Supervisor' value={supervisorName} onChange={(event) => {setSupervisorName(event.target.value)}} validator={validationGroup.createValidator(() => validateExistence(supervisorName))}/>
                                            <CustomControl lg={6} title='Start Time on First Day' value={startTime} onChange={(event) => {setStartTime(event.target.value)}} type='time' validator={validationGroup.createValidator(() => validateExistence(startTime))}/>
                                            <CustomControl lg={6} title='Daily Start Time' value={dailyStartTime} onChange={(event) => {setDailyStartTime(event.target.value)}} type='time' validator={validationGroup.createValidator(() => validateExistence(dailyStartTime))}/>
                                            <RadioControl lg={12} title='AO to Appear on Offer Letter' selection={selectedAo} setSelection={setSelectedAo} optionValues={offerLetterAos.map(ao => ao.uid)} optionNames={offerLetterAos.map(ao => `${ao.firstName} ${ao.lastName}`)} inline validator={validationGroup.createValidator(() => validateExistence(selectedAo))}/>
                                        </Row>
                                    </Container>
                                </div>
                            }
                    </Row>
                </LoadingWrapper>
            </Modal.Body>            
                <Modal.Footer>
                    <div style={{display:'flex', alignItems:'center', gap:10}}>
                        <CustomButton disabled={!validationGroup.isValid()} label='Submit' isLoading={isSubmitting} onClick={handleCreateUserPay}/>
                    </div>
                </Modal.Footer>
            <Modal show={showOfferLetter} onHide={() => {setShowOfferLetter(false)}} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Offer Letter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Dropdown style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Dropdown.Toggle variant='outline-primary'>Import Template</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {offerLetterElements}
                        </Dropdown.Menu>
                    </Dropdown>
                    <h5 style={{textAlign: 'center', color: 'gray', fontStyle: 'italic', margin: 0}}>Any modifications will be reflected in the offer letter sent to the employee</h5>
                    <OfferLetterEditor value={offerLetter} setValue={setOfferLetter} setSelectedOfferLetter={setSelectedOfferLetter} offerLetterData={{
                        companyName: company?.companyName,
                        employeeName: `${userInfo?.firstName} ${userInfo?.lastName}`,
                        employeeType: employeeType,
                        startDate: startDate,
                        supervisorName: supervisorName,
                        terminalAddress: csa?.address,
                        startTime: startTime,
                        payType: payType,
                        payRate: validateDecimalFixed(payRate, 2),
                        stopBonusWeekdayThreshold: validateInteger(stopBonusWeekdayThreshold),
                        stopBonusWeekendThreshold: validateInteger(stopBonusWeekendThreshold),
                        stopBonusWeekdayAmount: validateDecimalFixed(stopBonusWeekdayAmount, 2),
                        stopBonusWeekendAmount: validateDecimalFixed(stopBonusWeekendAmount, 2),
                        expectedAvailability: availabilityToBinaryString(userInfo?.availability ?? ''),
                        dailyStartTime: dailyStartTime,
                        benefits: company?.benefits,
                        aoName: `${signers.find(s => s.uid == selectedAo)?.firstName ?? ''} ${signers.find(s => s.uid == selectedAo)?.lastName ?? ''}`,
                        aoPhoneNumber: signers.find(s => s.uid == selectedAo)?.phoneNumber ?? '',
                        aoEmail: signers.find(s => s.uid == selectedAo)?.email ?? ''
                    }}/>     
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='outline-primary' onClick={() => {setShowOfferLetter(false)}}>Done</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}