/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './List.css';
import ListGroup from 'react-bootstrap/ListGroup';

function List({items, responsive}) {
    

    const elements = items.map((item) => {
        if (item[1]?.length > 20) {
            return (
                <ListGroup.Item key={item[0]}>
                    <p style={{margin: 0, marginRight: 12, fontSize: (responsive ? '1vmax' : '')}}>{item[0]}</p>
                    <p style={{margin: 0, opacity: 0.5, fontSize: (responsive ? '1vmax' : '')}}>{item[1]}</p>
                </ListGroup.Item>
            );
        } else {
            return (
                <ListGroup.Item key={item[0]}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        <p style={{margin: 0, marginRight: 12, fontSize: (responsive ? '1vmax' : '')}}>{item[0]}</p>
                        <p style={{margin: 0, opacity: 0.5, fontSize: (responsive ? '1vmax' : '')}}>{item[1]}</p>
                    </div>
                </ListGroup.Item>
            );
        }
        
    });
    return (
        <ListGroup>
            {elements}
        </ListGroup>
    )
}

export default List;