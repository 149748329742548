/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import Cookies from "universal-cookie";
import { usdFormatter, payTypeDictionary, availabilityToString} from "../../tools";
import moment from "moment";
import { Card, Container, Row, Col, ListGroup } from "react-bootstrap";
import { employeeTypeDictionary } from "../../tools";
import KeyValueRow from "../../components/KeyValueRow";

export default function PositionInfo({pay, user}){
    return(
        <Container fluid>
             <p style={{textAlign: 'center', margin: 0, fontSize: 24, fontWeight: 'bold', padding:10}}>{'Position at ' + new Cookies().get('companyName')}</p>
            <Row style={{paddingBottom: 5}}>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title style={{textAlign:'center', paddingBottom:10}}>Employment Details</Card.Title>
                            <ListGroup>
                                <KeyValueRow isListItem title={'Effective Date'} value={moment(pay.date).format('MMM D, YYYY')}/>
                                <KeyValueRow isListItem title={'Title'} value={pay.title}/>
                                <KeyValueRow isListItem title={'Employee Type'} value={employeeTypeDictionary[pay.employeeType]}/>
                                <KeyValueRow isListItem title={'BWC Code'} value={pay.bwcCode}/>
                                <KeyValueRow isListItem title={'MyGroundBiz Username'} value={user.fedexName}/>
                                <KeyValueRow isListItem title={'Expected Availability'} value={availabilityToString(user.availability)}/>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        
            <Row  style={{paddingBottom: 5}}>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title style={{textAlign:'center', paddingBottom:20}}>Pay Earnings</Card.Title>
                            <ListGroup>
                                <KeyValueRow isListItem title={'Pay Type'} value={payTypeDictionary[pay.payType]}/>
                                <KeyValueRow isListItem title={'Pay Rate'} value={pay.payRate ? usdFormatter.format(pay.payRate) : ''}/>
                                <KeyValueRow isListItem title={'Hourly Wage'} value={pay.hourlyWage ? usdFormatter.format(pay.hourlyWage): ''}/>
                                <KeyValueRow isListItem title= {'PTO Accrual'} value={pay.ptoAccrual ? pay.ptoAccrual : 0}/>
                                <KeyValueRow isListItem title={ 'Weekday Stop Threshold'} value={pay.stopBonusWeekdayThreshold ? pay.stopBonusWeekdayThreshold : 0}/>
                                <KeyValueRow isListItem title={ 'Weekend Stop Threshold'} value={pay.stopBonusWeekendThreshold ? pay.stopBonusWeekendThreshold : 0}/>
                                <KeyValueRow isListItem title={ 'Weekday Stop Wage Dollar Amount'} value={pay.stopBonusAmount ? usdFormatter.format(pay.stopBonusAmount) : '$0.00'}/>
                                <KeyValueRow isListItem title={ 'Weekend Stop Wage Dollar Amount'} value={pay.stopBonusWeekendAmount ? usdFormatter.format(pay.stopBonusWeekendAmount) : '$0.00'}/>
                                <KeyValueRow isListItem title={ 'Automatic Reimbursements'} value={pay.automaticReimbursement ? usdFormatter.format(JSON.parse(pay.automaticReimbursement).reduce((prev, curr) => {
                                    return prev + parseFloat(curr.amount);
                                }, 0)) : '$0.00'}/>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            
            <Row  style={{paddingBottom: 5}}>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title style={{textAlign:'center', paddingBottom: 10}}>Pay Deductions</Card.Title>
                            <ListGroup>
                                <KeyValueRow isListItem title= {'Medical'} value={pay.medical ? usdFormatter.format(pay.medical) : '$0.00'}/>
                                <KeyValueRow isListItem title={ 'Dental'} value={pay.dental ? usdFormatter.format(pay.dental) : '$0.00'}/>
                                <KeyValueRow isListItem title={ 'Vision'} value={pay.vision ? usdFormatter.format(pay.vision) : '$0.00'}/>
                                <KeyValueRow isListItem title={ 'Child Support'} value={pay.childSupport ? usdFormatter.format(JSON.parse(pay.childSupport).reduce((prev, curr) => {
                                    return prev + parseFloat(curr);
                                }, 0)) : '$0.00'}/>
                                <KeyValueRow isListItem title={ 'Automatic Deductions (Flat Rate)'} value={pay.automaticDeductions ? usdFormatter.format(JSON.parse(pay.automaticDeductions).filter((ad)=>ad.label !== '401K (% of Gross)' && ad.label !== 'Other (% of Gross)').reduce((prev, curr) => {
                                    return prev + parseFloat(curr.amount);
                                }, 0)) : '$0.00'}/>
                                <KeyValueRow isListItem title={ 'Automatic Deductions (% of Gross)'} value={pay.automaticDeductions ? JSON.parse(pay.automaticDeductions).filter((ad)=>ad.label === '401K (% of Gross)' || ad.label === 'Other (% of Gross)').reduce((prev, curr) => {
                                    return prev + parseFloat(curr.amount);
                                }, 0) + '%' : '0%'}/>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}