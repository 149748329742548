/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Cookies from "universal-cookie";
import CustomButton from "../../components/CustomButton";
import QuickTable from "../../components/QuickTable";
import Button from 'react-bootstrap/Button';
import { Accordion} from "react-bootstrap";
import './TimeClockRouteModal.css'

export default function TimeClockRouteModal({handleRouteSubmission, vehicles, workAreas, route, csas}) {
    const [selectedWorkArea, setSelectedWorkArea] = useState(null);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const userData = new Cookies().get('userData');
    const userCsaIdentifier = userData.csaIdentifier;
    let userTitle = userData.title;

    return (
        <>
            <Modal.Header>
                <Modal.Title>{!route && 'You are not scheduled for today'}. Please select the following:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Accordion defaultActiveKey={0}>
                    <Accordion.Item eventKey={0}>
                        <Accordion.Header>
                                <p style={{fontWeight: 'bold', textAlign: 'center', margin: 0}}>{csas.find(csa=>csa.uid == userCsaIdentifier).csaName}: Your CSA</p>
                        </Accordion.Header>
                        <Accordion.Body style={{flex: 1, padding: 6, textAlign: 'center', maxHeight: 450, overflowY: 'auto'}}>
                            <AccordionWorkAreasAndVehciles workAreas={workAreas.filter(wa=>wa.csaIdentifier == userCsaIdentifier)} vehicles={vehicles.filter(v=>v.csaIdentifier == userCsaIdentifier)} route={route}
                            userTitle={userTitle} selectedVehicle={selectedVehicle} selectedWorkArea={selectedWorkArea} setSelectedVehicle={setSelectedVehicle} setSelectedWorkArea={setSelectedWorkArea}/>
                        </Accordion.Body>
                    </Accordion.Item>
                    {csas.filter(csa=>csa.uid != userCsaIdentifier).map((csa, index)=>{
                        return (
                            <Accordion.Item key={index} eventKey={index+1}>
                                <Accordion.Header>
                                        <p style={{fontWeight: 'bold', textAlign: 'center', margin: 0}}>{csas.find(c=>c.uid == csa.uid).csaName}</p>
                                </Accordion.Header>
                                <Accordion.Body style={{flex: 1, padding: 6, textAlign: 'center', maxHeight: 450, overflowY: 'auto'}}>
                                    <AccordionWorkAreasAndVehciles workAreas={workAreas.filter(wa=>wa.csaIdentifier == csa.uid)} vehicles={vehicles.filter(v=>v.csaIdentifier == csa.uid)} route={route}
                                    userTitle={userTitle} selectedVehicle={selectedVehicle} selectedWorkArea={selectedWorkArea} setSelectedVehicle={setSelectedVehicle} setSelectedWorkArea={setSelectedWorkArea}/>
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })}
                </Accordion>
            </Modal.Body>
            <Modal.Footer style={{flexDirection: 'column', alignItems: 'start'}}>
                {!route && <span><strong>Selected Work Area: </strong>{selectedWorkArea ? `${workAreas.find(wa=>wa.uid == selectedWorkArea)?.workAreaName} (${csas.find(csa=>csa.uid == workAreas.find(wa=>wa.uid == selectedWorkArea).csaIdentifier)?.csaName})` : 'None Selected'}</span>}
                {userTitle !== 'AVP Driver' &&  <span><strong>Selected Vehicle: </strong>{selectedVehicle ? `${vehicles.find(v=>v.uid == selectedVehicle)?.vehicleName} (${csas.find(csa=>csa.uid == vehicles.find(v=>v.uid == selectedVehicle).csaIdentifier)?.csaName})` : 'None Selected'}</span>}
            </Modal.Footer>
            <Modal.Footer>
                <Button style={{background: 'none', border: 'none', color: 'var(--bs-primary)', boxShadow: 'none'}} onClick={() => {handleRouteSubmission()}}>Clock In Without Route</Button>
                <CustomButton disabled={(!selectedWorkArea && !route) || (!selectedVehicle && userTitle !== 'AVP Driver')} label={'Submit and Clock In'} onClick={() => {handleRouteSubmission(selectedWorkArea, selectedVehicle)}}/>
            </Modal.Footer>
        </>
    )
}

function AccordionWorkAreasAndVehciles({workAreas, vehicles, route, userTitle, selectedVehicle, selectedWorkArea, setSelectedVehicle, setSelectedWorkArea}){
    const workAreaRows = workAreas.map((wa) => {
        const isSelected = wa.uid == selectedWorkArea;
        return (
            <tr key={wa.uid} onClick={() => {setSelectedWorkArea(wa.uid)}} className="accordion-custom-item" style={{backgroundColor: isSelected ? 'var(--bs-primary)' : '', color: isSelected ? 'white' : 'black', marginBottom: 'auto', cursor: 'pointer'}}>
                <td>{wa.workAreaName}</td>
            </tr>
        )
    })
    const vehicleRows = vehicles.map((v) => {
        const isSelected = v.uid == selectedVehicle;

        return (
            <tr key={v.uid} onClick={() => {setSelectedVehicle(v.uid)}} className="accordion-custom-item" style={{backgroundColor: isSelected ? 'var(--bs-primary)' : '', color: isSelected ? 'white' : 'black', cursor: 'pointer'}}>
                <td>{v.vehicleName}</td>
            </tr>
        )
    })

    return (
        <div style={{display: 'flex', justifyContent: "space-evenly", flexWrap: 'wrap'}}>
               {!route && <QuickTable style={{minWidth: 150}} striped={false} headers={[]} title={'Work Areas'} rows={workAreaRows}/>}
                {userTitle !== 'AVP Driver' && <QuickTable style={{minWidth: 150}} striped={false} headers={[]} title={'Vehicles'} rows={vehicleRows}/>}
        </div>
    )
}