/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './ScheduleMatch.css';
import React, {useState} from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import QuickTable from '../../components/QuickTable';
import Modal from 'react-bootstrap/Modal';
import { Button, Dropdown} from 'react-bootstrap';
import DropdownItemWithCheck from '../../components/DropdownItemWithCheck';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import CustomButton from '../../components/CustomButton';
import { toast } from 'react-toastify';
import { ApiRequest } from '../../ApiManager.tsx';

function RouteCreator({routes, handleSetRoutes, handleSetOpenShifts, workArea, startOfWeek, selectedDay, users, scheduleItems, csas, vehicles, selectedCsas, showModal, setShowModal, editingRoute, setSelectedRoute, vehiclesNotFiltered}) {
    const [selectedDriver, setSelectedDriver] = useState(editingRoute ? editingRoute.companyUserIdentifier : null);
    const [selectedSecondaryDriver, setSelectedSecondaryDriver] = useState(editingRoute ? editingRoute.secondaryDriver : undefined);
    const [secondaryDriverType, setSecondaryDriverType] = useState(editingRoute ? editingRoute.secondaryDriverType : undefined);
    const [selectedVehicle, setSelectedVehicle] = useState(editingRoute ? editingRoute.vehicleIdentifier : null);
    const [timeScheduled, setTimeScheduled] = useState(editingRoute ? editingRoute.timeScheduled : '08:00');
    const [notes, setNotes] = useState(editingRoute ? editingRoute.notes : '');
    const [allCsasVisible, setAllCsasVisible] = useState(false);
    const [allUsersVisible, setAllUsersVisible] = useState(false);
    const [allVehicleCsasVisible, setAllVehicleCsasVisible] = useState(false);
    const [isAvpDriver, setIsAvpDriver] = useState(false);
    const [isOpenShift, setIsOpenShift] = useState((editingRoute && !editingRoute.companyUserIdentifier) ? true : false);
    const [driverSelectVehicle, setDriverSelectVehicle] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const drivers = structuredClone(users).filter(user => (allUsersVisible || (user.title === 'Driver' || user.title === 'AVP Driver')) && (allCsasVisible || selectedCsas.find(csa => csa.uid == user.csaIdentifier)));
    const [textCompanyUserIdentifiers, setTextCompanyUserIdentifiers] = useState(editingRoute?.textCompanyUserIdentifiers ? editingRoute.textCompanyUserIdentifiers : drivers.map(driver=>driver.companyUserUid));
    const todaysScheduleItems = scheduleItems.filter(item => moment(item.date).format('M-D-YYYY') === selectedDay.format('M-D-YYYY'));

    function handleSubmit(){
        if (!selectedDriver && !isOpenShift) {
            toast.error('You must select a driver');
            return;
        }
        
        const newRoute = {
            uid: editingRoute ? editingRoute.uid : undefined, 
            companyUserIdentifier: isOpenShift ? null : selectedDriver, 
            date: selectedDay.format('YYYY-MM-DD'), 
            timeScheduled: timeScheduled, 
            workAreaIdentifier: workArea.uid, 
            vehicleIdentifier: isOpenShift ? null : selectedVehicle, 
            secondaryDriver: isOpenShift ? null : selectedSecondaryDriver, 
            secondaryDriverType: isOpenShift ? null :secondaryDriverType,
            textCompanyUserIdentifiers: textCompanyUserIdentifiers.length > 0 ? textCompanyUserIdentifiers : [],
            notes: notes
        };

        if(editingRoute){
            new ApiRequest('scheduleMatch', 'updateRoute', setIsSubmitting, () => {
                if(isOpenShift){
                    handleSetOpenShifts('update', newRoute);
                }else{
                    handleSetRoutes('update', newRoute);
                }
                setSelectedRoute(undefined);
                setShowModal(false);
            }).withData({data: newRoute}).send();
        } else{
            new ApiRequest('scheduleMatch', 'createRoute', setIsSubmitting, (response) => {
                newRoute.uid = response.uid;
                if(isOpenShift){
                    handleSetOpenShifts('create', newRoute);
                }else{
                    handleSetRoutes('create', newRoute);
                }
                setSelectedRoute(undefined);
                setShowModal(false);
            }).withData({data: newRoute}).send();
        }
    }

    function handleDeleteRoute(){
        new ApiRequest('scheduleMatch', 'deleteRoute', setIsDeleting, () => {
            if(isOpenShift){
                handleSetOpenShifts('delete', editingRoute);
            }else{
                handleSetRoutes('delete', editingRoute);
            }
            setSelectedRoute(undefined);
            setShowModal(false);
        }).withUid(editingRoute.uid).send()
    }


    function handleAddUserToTextArray(companyUserIdentifier){
        let newTextCompanyUserIdentifiers = Array.from(textCompanyUserIdentifiers);

        if(newTextCompanyUserIdentifiers.includes(companyUserIdentifier)){
           newTextCompanyUserIdentifiers = newTextCompanyUserIdentifiers.filter(uid=> uid !== companyUserIdentifier);
        }else{
            newTextCompanyUserIdentifiers.push(companyUserIdentifier);
        }

        setTextCompanyUserIdentifiers(newTextCompanyUserIdentifiers);
    }

    function handleSelectAllToTextArray(type, isAll = true){
        let newTextCompanyUserIdentifiers = Array.from(textCompanyUserIdentifiers);

            if(type){
                if(isAll){
                    if(users.reduce((acc, el)=> {if(!textCompanyUserIdentifiers.includes(el.companyUserUid) && el.title == type){return false;} return acc;}, true)){
                        setTextCompanyUserIdentifiers(textCompanyUserIdentifiers.filter(tcui => !users.filter(d => d.title == type).map(d => d.companyUserUid).includes(tcui)));
                    } else{
                        users.forEach(user => {
                            if(user.title == type && !newTextCompanyUserIdentifiers.includes(user.companyUserUid))
                            newTextCompanyUserIdentifiers.push(user.companyUserUid);  
                    });
                    setTextCompanyUserIdentifiers(newTextCompanyUserIdentifiers);       
                    }
                }else{
                    if(users.filter(u=> u.csaIdentifier == csas.find(csa => csa.uid == workArea.csaIdentifier)?.uid).reduce((acc, el)=> {if(!textCompanyUserIdentifiers.includes(el.companyUserUid) && el.title == type){return false;} return acc;}, true)){
                        setTextCompanyUserIdentifiers(textCompanyUserIdentifiers.filter(tcui => !users.filter(user => user.title == type && user.csaIdentifier == csas.find(csa => csa.uid == workArea.csaIdentifier)?.uid).map(d => d.companyUserUid).includes(tcui)));
                    } else{
                        users.filter(u=> u.csaIdentifier == csas.find(csa => csa.uid == workArea.csaIdentifier)?.uid).forEach(user => {
                            if(user.title == type && !newTextCompanyUserIdentifiers.includes(user.companyUserUid))
                            newTextCompanyUserIdentifiers.push(user.companyUserUid);  
                    });
                    setTextCompanyUserIdentifiers(newTextCompanyUserIdentifiers);     
                    }   
                }
            }else{
                if(users.reduce((acc, el)=>{if(!textCompanyUserIdentifiers.includes(el.companyUserUid)){return false}return acc}, true)){
                    setTextCompanyUserIdentifiers([]);
                }else{
                    setTextCompanyUserIdentifiers(users.map(user=>user.companyUserUid));
                }
            }
    }

    let todaysRoutes = routes.filter(route => moment(route.date).format('M-D-YYYY') === selectedDay.format('M-D-YYYY'));
    if (editingRoute) {
        todaysRoutes = todaysRoutes.filter(r => r.uid != editingRoute.uid);
    }

    const vehiclesWithStatus = (!allVehicleCsasVisible ? structuredClone(vehicles) : structuredClone(vehiclesNotFiltered));
    for (let i = 0; i < vehiclesWithStatus.length; i++) {
        if (todaysRoutes.find(r => r.vehicleIdentifier === vehiclesWithStatus[i].uid)) {
            vehiclesWithStatus[i].status = 'Scheduled';
        } else {
            vehiclesWithStatus[i].status = 'Available';
        }
    }

    vehiclesWithStatus.sort((a, b) => {
        const aNum = parseFloat(a.vehicleName);
        const bNum = parseFloat(b.vehicleName);
      
        if (a.status === b.status) {
          if (!isNaN(aNum) && !isNaN(bNum)) {
            return aNum - bNum;
          } else {
            return a.vehicleName.localeCompare(b.vehicleName);
          }
        } else if (a.status === 'Available') {
          return -1;
        } else {
          return 1;
        }
    });

    const scheduleTypes = {
        'managerOnDuty': 'Manager On Duty',
        'paidTraining': 'Paid Training',
        'auxiliaryWorker': 'Auxilary Worker',
        'callOff': 'Call Off',
        'dayOff': 'Day Off',
        'pto': 'PTO',
        'requestOff': 'Request Off',
        'noShow': 'No Show'
    };
    
    for (let i = 0; i < drivers.length; i++) {
        if (todaysRoutes.find(r => r.companyUserIdentifier == drivers[i].companyUserUid || r.secondaryDriver == drivers[i].companyUserUid)) {
            drivers[i].status = 'Scheduled';
        } else {
            const scheduleItemForDriver = todaysScheduleItems.find(item => item.companyUserIdentifier == drivers[i].companyUserUid && item.scheduleType !== 'paidTraining' && item.scheduleType !== 'managerOnDuty');
            
            if(drivers[i].onLoa === '1'){
                drivers[i].status = 'Leave of Absence';
            } else if (scheduleItemForDriver) {
                drivers[i].status = scheduleTypes[scheduleItemForDriver.scheduleType];
            } else if (!drivers[i].availability[selectedDay.diff(startOfWeek, 'days')]) {
                drivers[i].status = 'Unavailable';
            } else {
                drivers[i].status = 'Available';
            }
        }
    }
    const sortedDrivers = Array.from(drivers);
    sortedDrivers.sort((a, b) => {
        const statusOrder = {
            'Available': 0,
            'Leave of Absence': 1,
            'Unavailable': 2
        };
        
        const statusComparison = statusOrder[a.status] - statusOrder[b.status];
        if (statusComparison !== 0) {
            return statusComparison;
        }
        
        return a.lastName.localeCompare(b.lastName);
    });

    return(
        <>
            <Modal.Header closeButton>
                <Modal.Title>{(editingRoute ? 'Editing Route' : 'Creating Route') + ' | Work Area ' + workArea.workAreaName + ' | ' + selectedDay.format('dddd, MMM D')}</Modal.Title>
                {
                    editingRoute ?
                    <Button variant={'outline-primary'} style={{marginLeft: 12}} onClick={handleDeleteRoute}>
                        Delete Route
                        <FontAwesomeIcon icon={faTrashCan} style={{marginLeft: 6}}/>
                    </Button>
                    : ''
                }
            </Modal.Header>
            {(moment().isBefore(selectedDay) || isOpenShift) &&
                <Modal.Header style={{flexDirection: 'column'}}>
                    <Form.Check style={{fontSize: 24}} reverse type='switch' label='Create As Open Shift' checked={isOpenShift} onChange={()=>{setIsOpenShift(!isOpenShift)}}/>    
                </Modal.Header>
            } 
                {!isOpenShift ?
                    <>
                        <Modal.Header>
                                <RouteCreatorPage1 
                                    key={1} 
                                    users={sortedDrivers} 
                                    selectedDriver={selectedDriver} 
                                    setSelectedDriver={setSelectedDriver} 
                                    selectedSecondaryDriver={selectedSecondaryDriver} 
                                    setSelectedSecondaryDriver={setSelectedSecondaryDriver} 
                                    secondaryDriverType={secondaryDriverType} 
                                    setSecondaryDriverType={setSecondaryDriverType} 
                                    csas={csas}
                                    allCsasVisible={allCsasVisible} 
                                    setAllCsasVisible={setAllCsasVisible} 
                                    allUsersVisible={allUsersVisible} 
                                    setAllUsersVisible={setAllUsersVisible}
                                    setIsAvpDriver={setIsAvpDriver}
                                    isAvpDriver={isAvpDriver}
                                />
                            </Modal.Header>
                            {!isAvpDriver &&
                                <Modal.Header>
                                    <RouteCreatorPage2 
                                        key={2} 
                                        vehicles={vehiclesWithStatus}
                                        selectedVehicle={selectedVehicle} 
                                        setSelectedVehicle={setSelectedVehicle} 
                                        csas={csas}
                                        setAllVehicleCsasVisible={setAllVehicleCsasVisible}
                                        allVehicleCsasVisible={allVehicleCsasVisible}
                                        driverSelectVehicle={driverSelectVehicle}
                                        setDriverSelectVehicle={setDriverSelectVehicle}
                                        />
                                </Modal.Header>
                            }
                    </>
                :
                    <Modal.Header>
                        <OpenShiftEmployeeSelector
                            users={sortedDrivers}
                            allUsers={users}
                            workArea={workArea}
                            csas={csas}
                            allUsersVisible={allUsersVisible}
                            allCsasVisible={allCsasVisible}
                            setAllUsersVisible={setAllUsersVisible}
                            setAllCsasVisible={setAllCsasVisible}
                            textCompanyUserIdentifiers={textCompanyUserIdentifiers}
                            handleAddUserToTextArray={handleAddUserToTextArray}
                            handleSelectAllToTextArray={handleSelectAllToTextArray}
                        />
                    </Modal.Header>
                }
                <Modal.Header style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                    <RouteCreatorPage3
                        key={3} 
                        timeScheduled={timeScheduled} 
                        setTimeScheduled={setTimeScheduled} 
                        notes={notes} 
                        setNotes={setNotes} 
                        selectedDriver={selectedDriver} 
                        handleSubmit={handleSubmit}
                        isOpenShift={isOpenShift}
                        textCompanyUserIdentifiers={textCompanyUserIdentifiers}
                        isSubmitting={isSubmitting}
                    />
                </Modal.Header>
        </>
    )
}


function DriverTR({driver, csaName, onClick, isSelected}) {
    return (
        <tr 
            onClick={onClick} 
            className='cursor-pointer' 
            style={{
                backgroundColor: 
                    isSelected ? 'rgb(0, 100, 220)' 
                    : driver.status === 'Available' ? 'rgba(0, 255, 0, 0.3)' 
                    : driver.status === 'Unavailable' || driver.status === 'Request Off' ? 'rgba(255, 255, 0, 0.3)' 
                    : driver.status === 'Leave of Absence' ? 'rgba(173, 216, 230, 0.5)'  : 'rgba(255, 0, 0, 0.3)'
            }}
            >
            <td style={isSelected ? {color: 'white'} : {}}>{driver.firstName + ' '+ (driver.showMiddleName ? driver.middleName.substring(0, 1) + '. ' : '')+ driver.lastName}</td>
            <td style={{color: isSelected ? 'white' : 'black'}}>{csaName}</td>
            <td style={{textAlign: 'center', color: isSelected ? 'white' : 'black'}}>{driver.status}</td>
        </tr>
    )
}

function NonDriverTR({driver, onClick, isSelected}) {
    return (
        <tr 
            onClick={onClick} 
            className='cursor-pointer'  
            style={{
                backgroundColor: 
                    isSelected ? 'rgb(0, 100, 220)' 
                    : driver.status === 'Available' ? 'rgba(0, 255, 0, 0.3)' 
                    : driver.status === 'Unavailable' || driver.status === 'Request Off' || driver.status === 'Leave of Absence' ? 'rgba(255, 255, 0, 0.3)' 
                    : 'rgba(255, 0, 0, 0.3)'
            }}
            >
            <td style={isSelected ? {color: 'white'} : {}}>
                <p style={{margin: 0}}>{driver.firstName + ' ' + (driver.showMiddleName ? driver.middleName.substring(0, 1) + '. ' : '') + driver.lastName}</p>
            </td>
            <td style={{color: isSelected ? 'white' : 'black'}}>{driver.title}</td>
            <td style={{color: isSelected ? 'white' : 'black'}}>{driver.status}</td>
        </tr>
    )
}

function OpenShiftEmployeeSelector({users, allUsers, workArea, csas, allUsersVisible, setAllUsersVisible, allCsasVisible, setAllCsasVisible, textCompanyUserIdentifiers, handleAddUserToTextArray, handleSelectAllToTextArray}){

    const userRows = users.filter(user=>{
        return user.companyUserIsEnabled != '0';
    }).map(user=>{
        const isSelected = textCompanyUserIdentifiers.includes(user.companyUserUid);
        return (
            <tr
                key={user.companyUserUid}
                onClick={()=>handleAddUserToTextArray(user.companyUserUid)}
                style={{ 
                    cursor: 'pointer',
                    backgroundColor: 
                     isSelected ? 'rgb(0, 100, 220)' 
                        : user.status === 'Available' ? 'rgba(0, 255, 0, 0.3)' 
                        : user.status === 'Unavailable' || user.status === 'Request Off' || user.status === 'Leave of Absence' ? 'rgba(255, 255, 0, 0.3)' 
                        : 'rgba(255, 0, 0, 0.3)'
                }}
            >
            <td>
                <p style={{margin: 0, color: isSelected ? 'white' : 'black'}}>{user.firstName + ' ' + ((user.showMiddleName && user.middleName) ? user.middleName.substring(0, 1) + '. ' : '') + user.lastName}</p>
            </td>
                <td style={{color: isSelected ? 'white' : 'black'}}>{csas.find(csa => csa.uid === user.csaIdentifier).csaName}</td>
                <td style={{color: isSelected ? 'white' : 'black'}}>{user.title}</td>
                <td style={{color: isSelected ? 'white' : 'black'}}>{user.status}</td>
            </tr>
        )
    })

    const userTable = <QuickTable striped={false} size='sm' hover title='Users' headers={['User', 'CSA', 'Title', 'Status']} rows={userRows}/>

    return (
        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', gap: 8, flexWrap: 'wrap', marginTop: 24, marginBottom: 12, alignItems: 'center'}}>
                <h4 style={{margin: 0, marginRight: 8}}>Open Shift Recipients ({textCompanyUserIdentifiers?.length})</h4>
                <div style={{display: 'flex', gap: 6, flexWrap: 'wrap'}}>
                    <Dropdown autoClose='outside'>
                        <Dropdown.Toggle variant='outline-primary'>Filter</Dropdown.Toggle>
                        <DropdownMenu>
                            <DropdownItemWithCheck label='Include drivers from all CSAs' active={allCsasVisible} onClick={()=>{setAllCsasVisible(!allCsasVisible)}}/>
                            <DropdownItemWithCheck label='Include non-drivers' active={allUsersVisible} onClick={() => {setAllUsersVisible(!allUsersVisible)}}/>
                        </DropdownMenu>
                    </Dropdown>
                    <Dropdown autoClose='outside'>
                        <Dropdown.Toggle variant='outline-primary'>Select/Deselect</Dropdown.Toggle>
                        <DropdownMenu>
                            <Dropdown.Header>Select/Deselect In CSA</Dropdown.Header>
                            {allUsers.filter(u=> u.csaIdentifier == csas.find(csa => csa.uid == workArea.csaIdentifier)?.uid).reduce((acc, el)=>{if(el.title === 'Driver'){return true} return acc}, false) && <DropdownItemWithCheck label='Drivers' onClick={()=>handleSelectAllToTextArray('Driver', false)} active={allUsers.filter(u=> u.csaIdentifier == csas.find(csa => csa.uid == workArea.csaIdentifier)?.uid).reduce((acc, el)=> {if(!textCompanyUserIdentifiers.includes(el.companyUserUid) && el.title == 'Driver'){return false;} return acc;}, true)}/>}
                            {allUsers.filter(u=> u.csaIdentifier == csas.find(csa => csa.uid == workArea.csaIdentifier)?.uid).reduce((acc, el)=>{if(el.title === 'AVP Driver'){return true} return acc}, false) && <DropdownItemWithCheck label='AVP Drivers' onClick={()=>handleSelectAllToTextArray('AVP Driver', false)} active={allUsers.filter(u=> u.csaIdentifier == csas.find(csa => csa.uid == workArea.csaIdentifier)?.uid).reduce((acc, el)=> {if(!textCompanyUserIdentifiers.includes(el.companyUserUid) && el.title == 'AVP Driver'){return false;} return acc;}, true)}/>}
                            {allUsers.filter(u=> u.csaIdentifier == csas.find(csa => csa.uid == workArea.csaIdentifier)?.uid).reduce((acc, el)=>{if(el.title === 'Jumper'){return true} return acc}, false) && <DropdownItemWithCheck label='Jumpers' onClick={()=>handleSelectAllToTextArray('Jumper', false)} active={allUsers.filter(u=> u.csaIdentifier == csas.find(csa => csa.uid == workArea.csaIdentifier)?.uid).reduce((acc, el)=> {if(!textCompanyUserIdentifiers.includes(el.companyUserUid) && el.title == 'Jumper'){return false;} return acc;}, true)}/>}
                            {allUsers.filter(u=> u.csaIdentifier == csas.find(csa => csa.uid == workArea.csaIdentifier)?.uid).reduce((acc, el)=>{if(el.title === 'BC'){return true} return acc}, false) && <DropdownItemWithCheck label='BCs' onClick={()=>handleSelectAllToTextArray('BC', false)} active={allUsers.filter(u=> u.csaIdentifier == csas.find(csa => csa.uid == workArea.csaIdentifier)?.uid).reduce((acc, el)=> {if(!textCompanyUserIdentifiers.includes(el.companyUserUid) && el.title == 'BC'){return false;} return acc;}, true)}/>}
                            {allUsers.filter(u=> u.csaIdentifier == csas.find(csa => csa.uid == workArea.csaIdentifier)?.uid).reduce((acc, el)=>{if(el.title === 'AO'){return true} return acc}, false) && <DropdownItemWithCheck label='AOs' onClick={()=>handleSelectAllToTextArray('AO', false)} active={allUsers.filter(u=> u.csaIdentifier == csas.find(csa => csa.uid == workArea.csaIdentifier)?.uid).reduce((acc, el)=> {if(!textCompanyUserIdentifiers.includes(el.companyUserUid) && el.title == 'AO'){return false;} return acc;}, true)}/>}
                            <Dropdown.Divider/>
                            <Dropdown.Header>Select/Deselect All</Dropdown.Header>
                            {allUsers.reduce((acc, el)=>{if(el.title === 'Driver'){return true} return acc}, false) && <DropdownItemWithCheck label='Drivers' onClick={()=>handleSelectAllToTextArray('Driver')} active={allUsers.reduce((acc, el)=> {if(!textCompanyUserIdentifiers.includes(el.companyUserUid) && el.title == 'Driver'){return false;} return acc;}, true)}/>}
                            {allUsers.reduce((acc, el)=>{if(el.title === 'AVP Driver'){return true} return acc}, false) && <DropdownItemWithCheck label='AVP Drivers' onClick={()=>handleSelectAllToTextArray('AVP Driver')} active={allUsers.reduce((acc, el)=> {if(!textCompanyUserIdentifiers.includes(el.companyUserUid) && el.title == 'AVP Driver'){return false;} return acc;}, true)}/>}
                            {allUsers.reduce((acc, el)=>{if(el.title === 'Jumper'){return true} return acc}, false) && <DropdownItemWithCheck label='Jumpers' onClick={()=>handleSelectAllToTextArray('Jumper')} active={allUsers.reduce((acc, el)=> {if(!textCompanyUserIdentifiers.includes(el.companyUserUid) && el.title == 'Jumper'){return false;} return acc;}, true)}/>}
                            {allUsers.reduce((acc, el)=>{if(el.title === 'BC'){return true} return acc}, false) && <DropdownItemWithCheck label='BCs' onClick={()=>handleSelectAllToTextArray('BC')} active={allUsers.reduce((acc, el)=> {if(!textCompanyUserIdentifiers.includes(el.companyUserUid) && el.title == 'BC'){return false;} return acc;}, true)}/>}
                            {allUsers.reduce((acc, el)=>{if(el.title === 'AO'){return true} return acc}, false) && <DropdownItemWithCheck label='AOs' onClick={()=>handleSelectAllToTextArray('AO')} active={allUsers.reduce((acc, el)=> {if(!textCompanyUserIdentifiers.includes(el.companyUserUid) && el.title == 'AO'){return false;} return acc;}, true)}/>}
                            <Dropdown.Divider/>
                            <DropdownItemWithCheck label='Select/Deselect All' onClick={()=>handleSelectAllToTextArray()} active={allUsers.reduce((acc, el)=>{if(!textCompanyUserIdentifiers.includes(el.companyUserUid)){return false}return acc}, true)}/>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>
            {userTable}
        </div>
    )
}

function RouteCreatorPage1({users, selectedDriver, setSelectedDriver, selectedSecondaryDriver, setSelectedSecondaryDriver, secondaryDriverType, setSecondaryDriverType, csas, allCsasVisible, setAllCsasVisible, allUsersVisible, setAllUsersVisible, setIsAvpDriver, isAvpDriver}) {

    const fulltimeDrivers = users.filter(driver => driver.companyUserIsEnabled != '0' && driver.employeeType == '0' && driver.title === 'Driver');
    const parttimeDrivers = users.filter(driver => driver.companyUserIsEnabled != '0' && driver.employeeType == '1' && driver.title === 'Driver');

    const nonDriverRowsPrimary = users.filter(u => u.companyUserIsEnabled != '0' && u.title !== 'Driver' && u.title !== 'AVP Driver' && u.companyUserUid !== selectedSecondaryDriver).map((user) => {
        return (
            <NonDriverTR key={user.companyUserUid} driver={user} onClick={() => {setSelectedDriver(user.companyUserUid); setIsAvpDriver(false)}} isSelected={selectedDriver == user.companyUserUid}/>
        )
    });

    const avpDriverRows = users.filter(u => u.companyUserIsEnabled != '0' && u.title === 'AVP Driver').map((driver) => {
        return (
            <DriverTR key={driver.companyUserUid} csaName={csas.find(csa => csa.uid === driver.csaIdentifier).csaName} driver={driver} onClick={() => {setSelectedDriver(driver.companyUserUid); setIsAvpDriver(true); setSecondaryDriverType(null); setSelectedSecondaryDriver(null);}} isSelected={selectedDriver == driver.companyUserUid}/>
        )
    });
    
    const avpDriverTable = <QuickTable size='sm' hover title='AVP Drivers' headers={['Driver', 'CSA', 'Status']} rows={avpDriverRows}/>

    const nonDriverTablePrimary = <QuickTable striped={false} size='sm' hover title='Non-Drivers' headers={['User', 'Role', 'Status']} rows={nonDriverRowsPrimary}/>;
    
    const nonDriverRowsSecondary = users.filter(u => u.companyUserIsEnabled != '0' && u.title !== 'Driver' && u.title !== 'AVP Driver' && u.companyUserUid != selectedDriver).map((user) => {
        return (
            <NonDriverTR key={user.companyUserUid} driver={user} onClick={() => {setSelectedSecondaryDriver(user.companyUserUid)}} isSelected={selectedSecondaryDriver == user.companyUserUid}/>
        )
    });
    const nonDriverTableSecondary = <QuickTable striped={false} size='sm' hover title='Non-Drivers' headers={['User', 'Role', 'Status']} rows={nonDriverRowsSecondary}/>;

    const fulltimeRowsPrimary = fulltimeDrivers.filter(driver => driver.companyUserUid != selectedSecondaryDriver).map((driver) => {
        return (
            <DriverTR key={driver.companyUserUid} driver={driver} csaName={csas.find(csa => csa.uid === driver.csaIdentifier).csaName} onClick={() => {setSelectedDriver(driver.companyUserUid); setIsAvpDriver(false)}} isSelected={selectedDriver == driver.companyUserUid}/>
        )
    });
    const parttimeRowsPrimary = parttimeDrivers.filter(driver => driver.companyUserUid != selectedSecondaryDriver).map((driver) => {
        return (
            <DriverTR key={driver.companyUserUid} driver={driver} csaName={csas.find(csa => csa.uid === driver.csaIdentifier).csaName} onClick={() => {setSelectedDriver(driver.companyUserUid); setIsAvpDriver(false)}} isSelected={selectedDriver == driver.companyUserUid}/>
        )
    });

    const fulltimeRowsSecondary = fulltimeDrivers.filter(driver => driver.companyUserUid != selectedDriver).map((driver) => {
        return (
            <DriverTR key={driver.companyUserUid} driver={driver} csaName={csas.find(csa => csa.uid === driver.csaIdentifier).csaName} onClick={() => setSelectedSecondaryDriver(driver.companyUserUid)} isSelected={selectedSecondaryDriver == driver.companyUserUid}/>
        )
    });
    const parttimeRowsSecondary = parttimeDrivers.filter(driver => driver.companyUserUid != selectedDriver).map((driver) => {
        return (
            <DriverTR key={driver.companyUserUid} driver={driver} csaName={csas.find(csa => csa.uid === driver.csaIdentifier).csaName} onClick={() => setSelectedSecondaryDriver(driver.companyUserUid)} isSelected={selectedSecondaryDriver == driver.companyUserUid}/>
        )
    });

    return (
        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 8, flexWrap: 'wrap', marginTop: 24, marginBottom: 12, alignItems: 'center'}}>
                <h4 style={{margin: 0, marginRight: 8}}>Driver Selection</h4>
                <div>
                    <div style={{display: 'flex', gap: 6, flexWrap: 'wrap'}}>
                        <Dropdown autoClose='outside'>
                            <Dropdown.Toggle variant='outline-primary'>Filter</Dropdown.Toggle>
                            <DropdownMenu>
                                <DropdownItemWithCheck label='Include drivers from all CSAs' active={allCsasVisible} onClick={()=>{setAllCsasVisible(!allCsasVisible)}}/>
                                <DropdownItemWithCheck label='Include non-drivers' active={allUsersVisible} onClick={() => {setAllUsersVisible(!allUsersVisible)}}/>
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown>
                            <Dropdown.Toggle disabled={isAvpDriver} variant='outline-primary'>Secondary Driver: {secondaryDriverType ? secondaryDriverType : 'None'}</Dropdown.Toggle>
                            <DropdownMenu>
                                <DropdownItemWithCheck label='Jumper' active={secondaryDriverType === 'Jumper'} onClick={() => {setSecondaryDriverType(secondaryDriverType === 'Jumper' ? null : 'Jumper')}}/>
                                <DropdownItemWithCheck label='Trainer' active={secondaryDriverType === 'Trainer'} onClick={() => {setSecondaryDriverType(secondaryDriverType === 'Trainer' ? null : 'Trainer')}}/>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', gap: 8, width: '100%', flexWrap: 'wrap'}}>
                {fulltimeRowsPrimary.length > 0 && <QuickTable striped={false} size='sm' hover title='Full-time Drivers' headers={['Driver', 'CSA', 'Status']} rows={fulltimeRowsPrimary}/>}
                {parttimeRowsPrimary.length > 0 && <QuickTable striped={false} size='sm' hover title='Part-time Drivers' headers={['Driver', 'CSA', 'Status']} rows={parttimeRowsPrimary}/>}
            </div>
            {avpDriverRows.length > 0 && avpDriverTable}
            {allUsersVisible ? nonDriverTablePrimary : ''}
            {secondaryDriverType && !isAvpDriver ?
                <div>
                    <h4 style={{marginTop: 24}}>Secondary Driver Selection</h4>
                    <div style={{display: 'flex', flexDirection: 'row', gap: 8, width: '100%', flexWrap: 'wrap'}}>
                        {fulltimeRowsSecondary.length > 0 && <QuickTable striped={false} size='sm' hover title='Full-time Drivers' headers={['Driver', 'CSA', 'Status']} rows={fulltimeRowsSecondary}/>}
                        {parttimeRowsSecondary.length > 0 && <QuickTable striped={false} size='sm' hover title='Part-time Drivers' headers={['Driver',' CSA', 'Status']} rows={parttimeRowsSecondary}/>}
                    </div>
                    {allUsersVisible ? nonDriverTableSecondary : ''}
                </div>
                : ''
            }
        </div>
    )
}

function RouteCreatorPage2({vehicles, selectedVehicle, setSelectedVehicle, csas, allVehicleCsasVisible, setAllVehicleCsasVisible, driverSelectVehicle, setDriverSelectVehicle}) {
    const rows = vehicles.map((vehicle) => {
        return (
            <tr 
                key={vehicle.uid} 
                onClick={() => setSelectedVehicle(vehicle.uid)} 
                className={'route-creator-selectable-row'} 
                style={{
                    backgroundColor: 
                        vehicle.uid == selectedVehicle ? 'rgb(0, 100, 220)' 
                        : vehicle.status !== 'Available' ? 'rgba(255, 0, 0, 0.3)'
                        : 'rgba(0, 255, 0, 0.3)'
                }}
                >
                <td style={{color: (vehicle.uid == selectedVehicle) ? 'white' : 'black'}}>{vehicle.vehicleName}</td>
                <td style={{color: (vehicle.uid == selectedVehicle) ? 'white' : 'black'}}>{csas.find(csa => csa.uid == vehicle.csaIdentifier).csaName}</td>
                <td style={{textAlign: 'center', color: (vehicle.uid == selectedVehicle) ? 'white' : 'black'}}>{vehicle.vehicleWeight}</td>
                <td style={{textAlign: 'center', color: (vehicle.uid == selectedVehicle) ? 'white' : 'black'}}>{vehicle.status}</td>
            </tr>
        )
    });

    return (
        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 8, flexWrap: 'wrap', marginBottom: 12, marginTop: 24, alignItems: 'center'}}>
                <h4 style={{margin: 0, marginRight: 14}}>Vehicle  Selection</h4>
                <div style={{display: 'flex', flexWrap: 'wrap', gap: 6}}>
                    <div style={{padding: 7, display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: '1px solid var(--bs-primary)', color: 'var(--bs-primary)', borderRadius: 6}}>
                        <Form.Check type='switch' checked={allVehicleCsasVisible} label='View vehicles from all CSAs' onChange={() => setAllVehicleCsasVisible(!allVehicleCsasVisible)}/>
                    </div>
                    <div style={{padding: 7, display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: '1px solid var(--bs-primary)', color: 'var(--bs-primary)', borderRadius: 6}}>
                        <Form.Check type='switch' label='Have driver select vehicle' checked={driverSelectVehicle} onChange={()=>{setDriverSelectVehicle(!driverSelectVehicle); setSelectedVehicle(null)}}/>
                    </div>
                </div>
            </div>
            {!driverSelectVehicle &&
                <div style={{maxHeight: 580, width: '100%', overflowY: 'scroll', padding: 4, marginBottom: 16}}>
                    <QuickTable striped={false} size='sm' hover headers={['Vehicle', 'CSA', 'GVW', 'Status']} rows={rows}/>
                </div>
            }
        </div>
    )
}

function RouteCreatorPage3({timeScheduled, setTimeScheduled, notes, setNotes, selectedDriver, handleSubmit, isOpenShift, textCompanyUserIdentifiers, isSubmitting}) {
    return (
        <>
            <h4 style={{marginTop: 24}}>Notes and Time</h4>
            <div style={{width: '100%', padding: 12, display: 'flex', flexDirection: 'column', gap: 12}}>
                <Form.Group>
                    <Form.Label>Enter Notes</Form.Label>
                    <Form.Control type='text' value={notes ? notes : ''} max={65535} onChange={(event) => {setNotes(event.target.value)}}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Select Time</Form.Label>
                    <Form.Control type='time' value={timeScheduled ? timeScheduled : ''} onChange={(event) => {setTimeScheduled(event.target.value ? event.target.value : '08:00')}}/>
                </Form.Group>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 12, borderRadius: 6, gap: 8, border: 'lightgray solid 1px'}}>
                    <Form.Label style={{fontWeight: 'bold'}}>Quick Time</Form.Label>
                    <div style={{display: 'flex', flexDirection: 'row', gap: 32, justifyContent: 'space-evenly', flexWrap: 'wrap'}}>
                        <Form.Label>Hour:</Form.Label>
                        <Form.Check type='radio' label={'5 AM'} name={'quickTimeHour'} onChange={() => {setTimeScheduled('05' + timeScheduled.substring(2))}}/>
                        <Form.Check type='radio' label={'6 AM'} name={'quickTimeHour'} onChange={() => {setTimeScheduled('06' + timeScheduled.substring(2))}}/>
                        <Form.Check type='radio' label={'7 AM'} name={'quickTimeHour'} onChange={() => {setTimeScheduled('07' + timeScheduled.substring(2))}}/>
                        <Form.Check type='radio' label={'8 AM'} name={'quickTimeHour'} onChange={() => {setTimeScheduled('08' + timeScheduled.substring(2))}}/>
                        <Form.Check type='radio' label={'9 AM'} name={'quickTimeHour'} onChange={() => {setTimeScheduled('09' + timeScheduled.substring(2))}}/>
                        <Form.Check type='radio' label={'10 AM'} name={'quickTimeHour'} onChange={() => {setTimeScheduled('10' + timeScheduled.substring(2))}}/>
                    </div>
                    <div style={{height: 8}}/>
                    <div style={{display: 'flex', flexDirection: 'row', gap: 32, justifyContent: 'space-evenly', flexWrap: 'wrap'}}>
                        <Form.Label>Minute:</Form.Label>
                        <Form.Check type='radio' label={':00'} name={'quickTimeMinute'} onChange={() => {setTimeScheduled(timeScheduled.substring(0, 3) + '00')}}/>
                        <Form.Check type='radio' label={':15'} name={'quickTimeMinute'} onChange={() => {setTimeScheduled(timeScheduled.substring(0, 3) + '15')}}/>
                        <Form.Check type='radio' label={':30'} name={'quickTimeMinute'} onChange={() => {setTimeScheduled(timeScheduled.substring(0, 3) + '30')}}/>
                        <Form.Check type='radio' label={':45'} name={'quickTimeMinute'} onChange={() => {setTimeScheduled(timeScheduled.substring(0, 3) + '45')}}/>
                    </div>
                </div>
                <CustomButton isLoading={isSubmitting} variant={'outline-primary'} disabled={(!isOpenShift && !selectedDriver)} onClick={() => handleSubmit()} label={`Submit ${(isOpenShift && textCompanyUserIdentifiers.length === 0) ? ' (No Recipients Selected)' : ''}`}/>
            </div>
        </>
    )
}

export default RouteCreator;