/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from 'react-bootstrap/Container';
import QuickTable from "../../components/QuickTable";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faX } from "@fortawesome/free-solid-svg-icons";
import CustomControl from "../../components/CustomControl";
import { Modal } from "react-bootstrap";
import { ValidationGroup, validateExistence, validatePositiveNumber } from "../../validation";
import CustomButton from "../../components/CustomButton";
import { useEffect, useState } from "react";
import RadioControl from "../../components/RadioControl";
import SwitchControl from "../../components/SwitchControl";
import { getFakeUID } from "../../tools";
import { ApiRequest } from "../../ApiManager.tsx";
import LoadingWrapper from "../../components/LoadingWrapper";


export default function CreateCompanyVehicles({handleMultipleVehicleSubmissions, restrictedToCsa}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [csas, setCsas] = useState([]);
    const [csaIdentifier, setCsaIdentifier] = useState(undefined);
    const [vehicleName, setVehicleName] = useState('');
    const [vehicleWeight, setVehicleWeight] = useState('');
    const [isStraight, setIsStraight] = useState('0');
    const [vehicleArray, setVehicleArray] = useState([]);
    const validationGroup= new ValidationGroup();

    useEffect(() => {
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('myCompany', 'getCsas', setIsLoading, (response) => restrictedToCsa ? setCsas([response.csas.find(csa => csa.uid == restrictedToCsa)]) : setCsas(response.csas)).withNoAlertOnSuccess().send()
    }

    function handleSubmit(){
        const arrayToSave = vehicleArray.map((v) => {
            return {
                vehicleName: v.vehicleName,
                vehicleWeight: v.vehicleWeight,
                isStraight: v.isStraight,
                isActive: 1,
                csaIdentifier: v.csaIdentifier,
                csa: csas.find(c => c.uid === v.csaIdentifier)
            }
        })

        new ApiRequest('myCompany', 'createMultipleVehicles', setIsSubmitting, (response) => {
            for(let i = 0; i < arrayToSave.length; i++) {
                arrayToSave[i].uid = response.data[i];
            }
            handleMultipleVehicleSubmissions(arrayToSave);
        }).withData({data: arrayToSave}).send();
    }

    function handleAddVehicle(){
        const vehicleToSave = {
            uid: getFakeUID(),
            vehicleName: vehicleName,
            vehicleWeight: vehicleWeight,
            isStraight: isStraight,
            isActive: 1,
            csaIdentifier: csaIdentifier,
        }
        let newArray=vehicleArray;
        newArray.push(vehicleToSave);
        setVehicleArray(newArray);
        clearForm();
    }

    function handleRemoveVehicle(index){
        const newArray = Array.from(vehicleArray);
        newArray.splice(index, 1);
        setVehicleArray(newArray);
    }

    function clearForm(){
        setVehicleName('');
        setVehicleWeight('');
        setIsStraight('0');
    }
        
    const vehicleRows = vehicleArray.map((vehicle,index) => {
        const csa = csas.find(c => c.uid == vehicle.csaIdentifier);
        return (
            <tr key={vehicle.uid}>
                <td>{vehicle.vehicleName}</td>
                <td>{vehicle.vehicleWeight}</td>
                <td>{vehicle.isStraight == '1' ? 'Yes' : 'No'}</td>
                <td>{csa.csaName}</td>
                <td style={{width: 0}}>
                    <Button variant={'outline-primary'} onClick={() => {handleRemoveVehicle(index)}}>
                        <FontAwesomeIcon icon={faX}/>
                    </Button>
                </td>
            </tr>
        )
    });
   
    const content = (
        <>
            <Modal.Header closeButton>
            <Modal.Title>Add Multiple Vehicles</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                <QuickTable headers={['Name', 'GVW', 'Straight Truck?', 'CSA', '']} rows={vehicleRows} widths={[null, null, 140, null, null]}/>
                <FontAwesomeIcon icon={faArrowUp} size='2x'/>
                <Container fluid>
                    <Row>
                        <CustomControl validator={validationGroup.createValidator(()=>validateExistence(vehicleName))} md={4} type='text' title='Name' maxLength={50} value={vehicleName} onChange={(event) => {setVehicleName(event.target.value)}}/>
                        <CustomControl validator={validationGroup.createValidator(()=>validatePositiveNumber(vehicleWeight))} md={4} type='number' title='GVW' min={0} maxDecimal={1000000} value={vehicleWeight} onChange={(event) => {setVehicleWeight(event.target.value)}}/>
                        <SwitchControl md={4} title='Straight Truck?' value={isStraight} setValue={setIsStraight}/>
                        <RadioControl validator={validationGroup.createValidator(()=>validateExistence(csaIdentifier))} xxl={12} title='CSA' selection={csaIdentifier} setSelection={setCsaIdentifier} optionNames={csas.map(csa => csa.csaName)} optionValues={csas.map(csa => csa.uid)} inline/>
                    </Row>
                    <Col style={{padding:4}}>                    
                    </Col>
                </Container>
                <CustomButton disabled={!validationGroup.isValid()} onClick={handleAddVehicle} label='Add Vehicle'/>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton disabled={vehicleArray.length === 0} onClick={handleSubmit} label='Submit Vehicles' isLoading={isSubmitting}/>
            </Modal.Footer>
        </>
    )

    return (
        <LoadingWrapper isLoading={isLoading}>
            {content}
        </LoadingWrapper>
    )
}