/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect, useState } from "react";
import { ApiRequest } from "../../../ApiManager.tsx";
import LoadingWrapper from "../../../components/LoadingWrapper.js";
import { Button, ListGroup, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import CustomButton from "../../../components/CustomButton.js";
import SearchBar from "../../../components/SearchBar.js";
import { getNameMatches } from "../../../tools.js";

export default function ChargeStatementMatchFedex({chargeStatement, unmatchedFedexIds, setUnmatchedFedexIds, csaIsUnmatched, matchFedexIdsCallback, hideModal}){
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [csas, setCsas] = useState([]);
    const [users, setUsers] = useState([]);
    const [matchedCsa, setMatchedCsa] = useState(null)
    const [matchedUsers, setMatchedUsers] = useState([]);
    const [selectedUnmatchedId, setSelectedUnmatchedId] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [userSearch, setUserSearch] = useState('');

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        if(selectedUser && selectedUnmatchedId){
            handleAddMatch();
        }
    }, [selectedUnmatchedId, selectedUser])

    function loadData(){
        new ApiRequest('chargeStatement', 'getMatchFedexIdsResources', setIsLoading, (response) => {
            setCsas(response.csas);
            setUsers(response.users);
        }).withNoAlertOnSuccess().send()
    }

    function handleMatchFedexIds(){
        new ApiRequest('chargeStatement', 'matchFedexIds', setIsSubmitting, (response) => {
            setMatchedCsa(null);
            setMatchedUsers([]);
            if (matchFedexIdsCallback) {
                matchFedexIdsCallback(matchedUsers, matchedCsa, users, csas);
            }
            if(hideModal){
                hideModal()
            }

        }).withData({matchedUsers: matchedUsers, matchedCsa: matchedCsa}).send();
    }

    function handleSetMatchedCsa(fedexId, csaIdentifier){
        if(matchedCsa?.csaIdentifier === csaIdentifier){
            setMatchedCsa(null)
        }else{
            setMatchedCsa({fedexId: fedexId, csaIdentifier: csaIdentifier});
        }
    }

    function handleAddMatch(){
        setMatchedUsers((prevState) => {
            return [...prevState, {fedexId: selectedUnmatchedId, companyUserUid: selectedUser.companyUserUid}]
        })
        setUnmatchedFedexIds((prevState) => prevState.filter(ps => ps !== selectedUnmatchedId));
        setSelectedUnmatchedId(null);
        setSelectedUser(null);
    }

    function handleRemoveMatch(fedexId){
        setMatchedUsers((prevState) => prevState.filter(ps => ps.fedexId !== fedexId))
        setUnmatchedFedexIds((prevState) => {
            const newArray = Array.from(prevState);
            newArray.push(fedexId);
            return newArray;
        })
    }

    function matchedWarning(fedexId, type){ 
        const message = type === 'user' ? `This user is currently matched in our system with a FedEx ID of ${fedexId}. Selecting this user will overwrite their current FedEx ID.`
        : `This CSA is currently matched in our system with a FedEx ID of ${fedexId}. Selecting this CSA will overwrite its current FedEx ID.`;

        return (
            <>
                <span>Matched &nbsp;</span>
                <OverlayTrigger overlay={
                    <Popover>
                        <Popover.Body>{message}</Popover.Body>
                    </Popover>
                }>
                    <FontAwesomeIcon color="gold" icon={faExclamationTriangle}/>
                </OverlayTrigger>   
            </>
        )
    }

    return (
        <>
            <Modal.Header>
                <Modal.Title>Match Unmatched FedEx IDs</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoadingWrapper isLoading={isLoading}>
                    {csaIsUnmatched && 
                        <div>  
                            <h5>Match CSA (Required)</h5>
                            <p>The Contracted Service Area ID for this Settlement Report is {chargeStatement.csaFedexId}. This currently does not correspond with any of the CSAs in our system. Please select the CSA this FedEx ID corresponds with.</p>
                            <ListGroup style={{maxHeight: 400, overflow: 'auto'}}>
                                {csas.sort((a, b) => a.fedexId ? 1 : b.fedexId ? -1 : 0).map(csa => {
                                    return (
                                        <ListGroup.Item action key={csa.uid} active={csa.uid === matchedCsa?.csaIdentifier} onClick={() => handleSetMatchedCsa(chargeStatement.csaFedexId, csa.uid)} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap'}}>
                                            <h6 style={{margin: 0}}>{csa.csaName}</h6>
                                            <span>{!csa.fedexId ? 'Not Matched' : matchedWarning(csa.fedexId, 'csa')}</span>
                                        </ListGroup.Item>
                                    )
                                })}
                            </ListGroup>
                        </div>
                    }
                    {csaIsUnmatched && unmatchedFedexIds.length > 0 && <hr/>}
                    {unmatchedFedexIds.length > 0 &&
                        <>
                            <div>
                                <h5>Match Employees (Optional)</h5>
                                <p>One or more employee FedEx IDs from the report are unmatched to users in our system. Please select the unmatched IDs and match them to your company employees.</p>
                                <div style={{display: 'flex', justifyContent: 'center', gap: 24, flexWrap: 'wrap'}}>
                                    <div style={{flex: 1}}>
                                        <h6 style={{textAlign: 'center'}}>Unmatched Fedex IDs/Names From Report</h6>
                                        <ListGroup style={{maxHeight: 400, minWidth: 300, overflow: 'auto'}}>
                                            {unmatchedFedexIds.map(id => {
                                                return <ListGroup.Item key={id} action active={id === selectedUnmatchedId} onClick={() => setSelectedUnmatchedId(id)}>{id} {chargeStatement.chargeStatementDrivers.find(srd => srd.fedexId === id)?.name ? ` (${chargeStatement.chargeStatementDrivers.find(srd => srd.fedexId === id)?.name})` : ''}</ListGroup.Item>
                                            })}
                                        </ListGroup>
                                    </div>
                                    <div style={{flex: 1}}>
                                        <h6 style={{textAlign: 'center'}}>Company Employees</h6>
                                        <div style={{margin: '8px 0'}}>
                                            <SearchBar label='Search by name' value={userSearch} setValue={setUserSearch}/>
                                        </div>
                                        <ListGroup style={{maxHeight: 355, minWidth: 300, overflow: 'auto'}}>
                                            {users.filter(user => !matchedUsers.find(mu => mu.companyUserUid === user.companyUserUid) && getNameMatches(user, userSearch)).sort((a, b) => a.fedexId ? 1 : b.fedexId ? -1 : 0).map(user => {
                                                const middleName = user?.middleName ? `${user?.middleName} ` : ''
                                                const name = `${user?.firstName} ${middleName}${user?.lastName}`
                                                return (
                                                    <ListGroup.Item key={user.companyUserUid} action active={user.companyUserUid == selectedUser?.companyUserUid} onClick={() => setSelectedUser(user)} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap'}}>
                                                        <h6 style={{margin: 0}}>{name}</h6>
                                                        <span>{!user.fedexId ? 'Not Matched' : matchedWarning(user.fedexId, 'user')}</span>
                                                    </ListGroup.Item>
                                                )
                                            })}
                                        </ListGroup>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    { matchedUsers.length > 0 &&
                        <div style={{marginTop: 24}}>
                            <h6 style={{textAlign: 'center'}}>Matched Users</h6>
                            <ListGroup>
                                {matchedUsers.map(mu => {
                                    const user = users.find(user => user.companyUserUid === mu.companyUserUid);
                                    const middleName = user?.middleName ? `${user?.middleName} ` : ''
                                    const name = `${user?.firstName} ${middleName}${user?.lastName}`
                                    return (
                                        <ListGroup.Item key={mu.companyUserUid} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <div style={{alignItems: 'center'}}>
                                                <span>{mu.fedexId}</span>
                                                <span>&nbsp;({name})</span>
                                            </div>
                                            <Button onClick={() => handleRemoveMatch(mu.fedexId)} variant="outline-danger">
                                                <FontAwesomeIcon icon={faTrashCan}/>
                                            </Button>
                                        </ListGroup.Item>
                                    )
                                })}
                            </ListGroup>
                        </div>
                    }
                </LoadingWrapper>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton label='Submit Matches' disabled={!matchedCsa && csaIsUnmatched} isLoading={isSubmitting} onClick={handleMatchFedexIds}/>
            </Modal.Footer>
        </>
    )
}