/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import { ValidationGroup, validateExistence } from '../../../validation';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import moment from 'moment';
import CustomButton from '../../../components/CustomButton';
import HRUserSchedulePTODaySelector from './HRUserSchedulePTODaySelector';
import HRTimeOffModal from './HRTimeOffModal';
import CustomDatePicker from '../../../components/CustomDatePicker/CustomDatePicker';
import PageSpinner from '../../../components/PageSpinner';
import { ApiRequest } from '../../../ApiManager.tsx';

function HRCreateScheduleItemModal({companyUserIdentifier, handleAddPTO}) {
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const [notes, setNotes] = useState('');
    const [scheduleItem, setScheduleItem] = useState(undefined);
    const [showPtoSelector, setShowPtoSelector] = useState(false);
    const [showTimeOffModal, setShowTimeOffModal] = useState(false);
    const [timeOff, setTimeOff] = useState([]);
    const validationGroup = new ValidationGroup();

    useEffect(() => {
        loadData();
    }, []);

    function loadData(){
        new ApiRequest('hr', 'timeOff', setIsLoading, (response)=>{setTimeOff(response.data)}).withData({companyUserIdentifier}).withNoAlertOnSuccess().send()
    }

    const handleCreatingScheduleItem = () => {
        const scheduleItem = {
            companyUserIdentifier:companyUserIdentifier,
            date: startDate,
            endDate: endDate,
            notes: notes,
        };

        setScheduleItem(scheduleItem);
        setShowPtoSelector(true);
    }

    const disabledDates = timeOff.reduce((prev, curr) => {
        if (curr.scheduleType === 'requestOff') {
            const dates = [];
            const numberOfDays = moment(curr.endDate).diff(curr.date, 'days') + 1;
            for (let i = 0; i < numberOfDays; i++) {
                dates.push(moment(curr.date).add(i, 'days').format('YYYY-MM-DD'));
            }
            return prev.concat(dates);

        } else {
            return prev.concat([curr.date]);
        }
    }, []);

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Schedule Time Off</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { isLoading ? <PageSpinner/> :
                    <>
                        <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 12}}>
                            <Button variant='outline-primary' onClick={() => {setShowTimeOffModal(true)}}>View Upcoming Pending & Accepted Time Off</Button>
                        </div>
                        <CustomDatePicker validator={validationGroup.createValidator(() => validateExistence(endDate))} title={'Date Range'} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} disabledDates={disabledDates}/>
                        <Form.Group>
                            <Form.Label>Reason</Form.Label>
                            <Form.Control as='textarea' maxLength={65535} rows={4} value={notes} onChange={(event) => {setNotes(event.target.value)}}/>
                        </Form.Group>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <CustomButton onClick={handleCreatingScheduleItem} disabled={!validationGroup.isValid()} label={'Submit'}/>
            </Modal.Footer>
            <Modal show={showPtoSelector} onHide={()=>setShowPtoSelector(false)}>
                <HRUserSchedulePTODaySelector selectedItem={scheduleItem} handleAddPTO={handleAddPTO}/>
            </Modal>
            <Modal show={showTimeOffModal} onHide={() => {setShowTimeOffModal(false)}} size='lg'>
                <HRTimeOffModal startDate={moment().format('YYYY-MM-DD')} endDate={moment().add(1, 'year').format('YYYY-MM-DD')}/>
            </Modal>
        </>
    )
}

export default HRCreateScheduleItemModal;