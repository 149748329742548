/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { Validator, validateGreaterThanZero } from "../../../../../../validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import QuickTable from "../../../../../../components/QuickTable";
import { payTypeDictionary, bigToUsd, getUnitsWorkedTypeString, payTypeDictionaryWithOt } from "../../../../payrollTools";
import Big from "big.js";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

export default function PayrollEmployeeWagesEditor({entry, index, handleSetWeek}) { 
    
    const removePay = (id) => {
        const newArr = entry.weeks[index].pay.filter((element) => {
            return element.id !== id;
        });
        handleSetWeek(index, 'pay', newArr);
    }

    const handleSetPay = (id, key, value) => {
        const newArr = Array.from(entry.weeks[index].pay);
        newArr.find(r => r.id === id)[key] = value
        handleSetWeek(index, 'pay', newArr);
    }

    const payElements = entry.weeks[index].pay.map((payObject) => {
        return (
            <PayElement 
                key={payObject.id}
                pay={payObject} 
                removePay={removePay}
                handleSetPay={handleSetPay}
                entry={entry}
                validator={new Validator(() => validateGreaterThanZero(payObject.payRate))}
                index={index}
            />
        )
    });
  
    return (
        <>
            { payElements.length > 0 ?
                <>
                    <QuickTable title='Wages' headers={['Pay Type', 'Pay Rate', 'Unit Type', 'Units Worked','Sub Gross', '']} widths={[null,null, null, null, null, 40]} rows={payElements} size={'sm'}/>
                </>
                :
                <p>This Employee did not have an effective pay during this week.</p>
            }
        </>
    )
}



function PayElement({pay, removePay, handleSetPay, entry, validator, index}) {
    
    const[showDropdown, setShowDropdowm] = useState(false);
    const [payTypeSearch, setPayTypeSearch] = useState(payTypeDictionaryWithOt[pay.payType])
    const handleSetPayType = (value)=>{
        handleSetPay(pay.id, 'payType', value);
        setPayTypeSearch(payTypeDictionary[value]);
    }

    const payTypeDropDownItems = Object.entries(payTypeDictionary).map(([key, value]) =>{
        return (
            
            <Dropdown.Item key={key} eventKey={key} active={payTypeSearch === value} onClick={() => {handleSetPayType(key); setShowDropdowm(false)}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <p style={{marginBottom: 0, marginRight: 8}}>{value}</p> 
                </div>
            </Dropdown.Item>  
        )
    });
    
    return (
        <tr>
            <td style={{width:'20%'}}>   
                <Form.Control readOnly style={{height:'90%'}} placeholder='Pay Type' type='text' value ={payTypeSearch} onChange={(event)=>{handleSetPayType(event.target.value)}} onFocus={()=>setShowDropdowm(true)} onBlur={() => setTimeout(() => {setShowDropdowm(false)}, 150)} />
                <div style={{position:'absolute', marginBottom:2, marginTop:-1.5, width:'18%'}}>
                    <Dropdown.Menu onMouseDown={(e)=>e.preventDefault()} key="dropdown-menu" show={showDropdown} style={{maxHeight: 300, overflowY: 'scroll'}}>
                        {payTypeDropDownItems}
                    </Dropdown.Menu>
                </div>
            </td>
            <td>
                <InputGroup>
                    <InputGroup.Text style={{padding: '2px 6px 2px 6px'}}>$</InputGroup.Text>
                    <Form.Control isInvalid={!validator.isValid()} style={{padding: '2px 6px 2px 6px'}} type='number' min={0} value={pay.payRate} placeholder='Pay Rate' onChange={(event) => {handleSetPay(pay.id, 'payRate', event.target.value.replace('-', ''))}}/>
                    { validator ? <Form.Control.Feedback type='invalid'>{validator.getInvalidMessage()}</Form.Control.Feedback> : ''}
                </InputGroup>
            </td>
            <td>{getUnitsWorkedTypeString(pay.payType)}</td>
            <td>
                { !['py', 'iw', 'sw', 'ot'].includes(pay.payType) && 
                    <Form.Control style={{padding: '2px 6px 2px 6px'}}  type='number' min={0} value={pay.unitsWorked} placeholder='Units Worked' onChange={(event) => {handleSetPay(pay.id, 'unitsWorked', event.target.value.replace('-', ''))}}/>
                }
            </td>
            <td>
                <span>{(entry.weeks[index].qualifiesForFLSA() && !['iw', 'sw', 'ot'].includes(pay.payType) ? bigToUsd(new Big('0.00')) : bigToUsd(pay.getWages()))}</span>
                { entry.weeks[index].qualifiesForFLSA() && !['iw', 'sw', 'ot'].includes(pay.payType) && 
                    <OverlayTrigger overlay={
                        <Popover style={{position: 'fixed'}}>
                            <Popover.Body>This Wage is not being incorporated into the gross because this employee qualifies for FLSA overtime.</Popover.Body>
                        </Popover>
                    }>
                        <FontAwesomeIcon style={{color: 'var(--bs-primary)', marginLeft: 4}} icon={faQuestionCircle}/>
                    </OverlayTrigger>
                }
            </td>
            <td>
                <Button disabled={entry.weeks[index].pay.length === 1} style={{color: 'white', padding: '2px 6px 2px 6px', width: '100%'}} onClick={() => removePay(pay.id)}>
                    <FontAwesomeIcon icon={faTrashCan}/>
                </Button>
            </td>
        </tr>
    );
}