/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Container from 'react-bootstrap/Container';
import QuickTable from "../../components/QuickTable";
import { Button } from "react-bootstrap";
import { addressToString, validateDecimalFixed } from "../../tools";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faX } from "@fortawesome/free-solid-svg-icons";
import AddressControl from "../../components/AddressControl";
import CustomControl from "../../components/CustomControl";
import DropdownControl from "../../components/DropdownControl";
import { packetIdentifierDictionary } from "../../tools";
import { Modal } from "react-bootstrap";
import { ValidationGroup, validateExistence, AddressValidator } from "../../validation";
import CustomButton from "../../components/CustomButton";
import { getFakeUID } from "../../tools";
import { ApiRequest } from "../../ApiManager.tsx";

export default function CreateCompanyCsas({handleMultipleCSASubmissions}) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [csaName, setCsaName] = useState('');
    const [address, setAddress] = useState({});
    const [clockInRadius, setClockInRadius] = useState('');
    const [packetIdentifier, setPacketIdentifier] = useState('');
    const [csaArray, setCsaArray] = useState([]);
    const validationGroup= new ValidationGroup();
    const addressValidator = new AddressValidator(address);

    function handleSubmit(){
        new ApiRequest('myCompany', 'createMultipleCsas', setIsSubmitting, (response) => {
            for(let i = 0; i < csaArray.length; i++) {
                csaArray[i].uid = response.data[i];
            }
            handleMultipleCSASubmissions(csaArray);
        }).withData({data: csaArray}).send()
    }

    function handleAddCSA(){
        const csaToSave = {
            uid: getFakeUID(),
            csaName: csaName,
            address: address,
            clockInRadius: validateDecimalFixed(clockInRadius, 2),
            isActive: 1,
            packetIdentifier: packetIdentifier
        }
        let newArray = Array.from(csaArray);
        newArray.push(csaToSave);
        setCsaArray(newArray);
        clearForm();
    }

    function handleRemoveCSA(index){
       const newArray = [...csaArray];
       newArray.splice(index,1)
       setCsaArray(newArray);
    }

    function clearForm(){
        setCsaName('');
        setAddress({});
        setClockInRadius('');
        setPacketIdentifier(undefined);
    }

    const csaRows = csaArray.map((csa,index) => {
        return (
            <tr key={csa.uid}>
                <td>{csa.csaName}</td>
                <td>{addressToString(csa.address)}</td>
                <td>{csa.clockInRadius}</td>
                <td>{packetIdentifierDictionary[csa.packetIdentifier]}</td>
                <td style={{width: 0}}>
                    <Button variant={'outline-primary'} onClick={() => {handleRemoveCSA(index)}}>
                        <FontAwesomeIcon icon={faX}/>
                    </Button>
                </td>
            </tr>
        )
    })

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Add Multiple CSAs</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                <QuickTable headers={['Name', 'Address', 'Clock-In Radius', 'OnBoarding Packet']} rows={csaRows}/>
                <FontAwesomeIcon icon={faArrowUp} size='2x'/>
                <Container fluid>
                    <Row>
                        <CustomControl validator={validationGroup.createValidator(()=>validateExistence(csaName))} md={6} type='text' title='Name' maxLength={50} value={csaName} onChange={(event) => {setCsaName(event.target.value)}}/>
                        <CustomControl validator={validationGroup.createValidator(()=>validateExistence(clockInRadius))} md={6} type='number' min={0.5} step={0.1} maxDecimal={1000000} title='Clock-In Radius (in miles)' value={clockInRadius} onChange={(event) => {setClockInRadius(event.target.value)}}/>
                        <DropdownControl validator={validationGroup.createValidator(()=>validateExistence(packetIdentifier))} selection={packetIdentifier} setSelection={setPacketIdentifier} itemNames={Object.values(packetIdentifierDictionary)} itemValues={Object.keys(packetIdentifierDictionary)} title='Onboarding Packet'/>
                        <AddressControl validator={addressValidator} md={6} title='Address' address={address} setAddress={setAddress}/>
                    </Row>
                </Container>
                <CustomButton disabled={!validationGroup.isValid() || !addressValidator.isValid()} onClick={handleAddCSA} label='Add CSA'/>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton disabled={csaArray.length === 0} onClick={handleSubmit} label='Submit CSAs' isLoading={isSubmitting}/>
            </Modal.Footer>
        </>
    )
}