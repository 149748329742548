/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { bigToUsd, validateBig,validateDecimal } from "../payrollTools";
import { usdFormatter } from "../../../tools";
import NDBonus from '../Models/NDBonus';
import Big from "big.js";
import DBonus from "../Models/DBonus";
import Deduction from "../Models/Deduction";
import PayrollPeriodNonBonusAPColumnInclusion from "./PayrollPeriodNonBonusAPColumnInclusion";

export default function PayrollPeriodTotalsRow(entries) {
    const apNonBonusColumnInclusion = PayrollPeriodNonBonusAPColumnInclusion(entries);

    const row = new Map();
    row.set('Regular Wages', bigToUsd(entries.reduce((prevEntry, currEntry) => {
        return prevEntry.plus(currEntry.weeks.reduce((prevWeek, currWeek) => {
            return prevWeek.plus(currWeek.regularWages());
        }, new Big('0.0')))
    }, new Big('0.0'))));
    row.set('Hourly Wages', bigToUsd(entries.reduce((prevEntry, currEntry) => {
        return prevEntry.plus(currEntry.weeks.reduce((prevWeek, currWeek) => {
            return prevWeek.plus(currWeek.hourlyWages());
        }, new Big('0.0')))
    }, new Big('0.0'))));
    row.set('Overtime Wages', bigToUsd(entries.reduce((prevEntry, currEntry) => {
        return prevEntry.plus(currEntry.weeks.reduce((prevWeek, currWeek) => {
            return prevWeek.plus(currWeek.overtimeWages());
        }, new Big('0.0')))
    }, new Big('0.0'))));
    row.set('Stop Wages', bigToUsd(entries.reduce((prevEntry, currEntry) => {
        return prevEntry.plus(currEntry.weeks.reduce((prevWeek, currWeek) => {
            return prevWeek.plus(currWeek.stopWages());
        }, new Big('0.0')))
    }, new Big('0.0'))));
    row.set('Mile Wages', bigToUsd(entries.reduce((prevEntry, currEntry) => {
        return prevEntry.plus(currEntry.weeks.reduce((prevWeek, currWeek) => {
            return prevWeek.plus(currWeek.mileWages());
        }, new Big('0.0')))
    }, new Big('0.0'))));
    row.set('Extra Day Wages', bigToUsd(entries.reduce((prevEntry, currEntry) => {
        return prevEntry.plus(currEntry.weeks.reduce((prevWeek, currWeek) => {
            return prevWeek.plus(currWeek.extraDayWages());
        }, new Big('0.0')))
    }, new Big('0.0'))));
    row.set('PTO Wages', bigToUsd(entries.reduce((prevEntry, currEntry) => {
        return prevEntry.plus(currEntry.totalPtoWages());
    }, new Big('0.0'))));
    row.set('Holiday Wages', bigToUsd(entries.reduce((prevEntry, currEntry) => {
        return prevEntry.plus(currEntry.totalHolidayWages());
    }, new Big('0.0'))));

    row.set('Incentive Wages', bigToUsd(entries.reduce((prevEntry, currEntry) => {
        return prevEntry.plus(currEntry.totalIncentiveWages());
    }, new Big('0.0'))));
    row.set('Stand-By Wages', bigToUsd(entries.reduce((prevEntry, currEntry) => {
        return prevEntry.plus(currEntry.totalStandByWages());
    }, new Big('0.0'))));
    row.set('Extra Day Pay', bigToUsd(entries.reduce((prevEntry, currEntry) => {
        return prevEntry.plus(currEntry.totalAutoOvertimeWages());
    }, new Big('0.0'))));


    const ndBonusTotals = {}
    NDBonus.ndBonusTypes.forEach(type => ndBonusTotals[type] = 0.0);
    entries.forEach((entry) => {
        const entryTotals = entry.totalNdBonusesByType();
        NDBonus.ndBonusTypes.forEach(type => ndBonusTotals[type] += entryTotals[type]);
    })

    Object.entries(ndBonusTotals).forEach(([key, value]) => {
        row.set(key, usdFormatter.format(value));
    })

    row.set('OT Stop Wages', bigToUsd(entries.reduce((prevEntry, currEntry) => {
        return prevEntry.plus(currEntry.totalOTStopWages().plus(currEntry.totalNdBonusOtStopWages()));
    }, new Big('0.0'))));

    row.set('Stop/OT Stop Wages', bigToUsd(entries.reduce((prevEntry, currEntry) => {
        return prevEntry.plus(currEntry.totalStopAndOTStopWages());
    }, new Big('0.0'))));

    DBonus.dBonusTypes.forEach((type) => {
        row.set(type, usdFormatter.format(entries.reduce((prevEntry, currEntry) => {
            return prevEntry + currEntry.dBonuses.filter(dBonus => dBonus.type === type).reduce((prev, curr) => {
                return prev + curr.getAmount();
            }, 0.0);
        }, 0.0)));
    })

    Object.entries(apNonBonusColumnInclusion).forEach(([key, value]) => {
        for (let i = 0; i < value; i++) {
            row.set(`${key} #${i + 1}`, bigToUsd(entries.reduce((prevEntry, currEntry) => {
                const additionalPayOfType = currEntry.getAllAdditionalPay().filter(ap => ap.type === key);
                if (i < additionalPayOfType.length) {
                    return prevEntry.plus(validateBig(additionalPayOfType[i].amount));
                } else {
                    return prevEntry;
                }
            }, new Big('0.0'))));
        }
    });

    const loanRowCount = entries.reduce((prev, curr) => {
        return Math.max(prev, curr.getEnabledLoans().length);
    }, 0);
    for (let i = 0; i < loanRowCount; i++) {
        row.set(`Loan #${i + 1}`, usdFormatter.format(entries.filter(e => e.getEnabledLoans().length > i).reduce((prevEntry, currEntry) => {
            return prevEntry + currEntry.getEnabledLoans()[i].getAmount();
        }, 0.0)));
    }

    const ticketRowCount = entries.reduce((prev, curr) => {
        return Math.max(prev, curr.getEnabledTickets().filter(t => t.getAmountToPay(curr) > 0).length);
    }, 0);
    for (let i = 0; i < ticketRowCount; i++) {
        row.set(`Fine Ticket Damage #${i + 1}`, usdFormatter.format(entries.filter(e => e.getEnabledTickets().length > i).reduce((prevEntry, currEntry) => {
            return prevEntry + currEntry.getEnabledTickets()[i].getAmountToPay(currEntry);
        }, 0.0)));
    }

    row.set('Gross', bigToUsd(entries.reduce((prev, curr) => {
        return prev.plus(curr.gross());
    }, new Big('0.0'))));
    row.set('Child Support', usdFormatter.format(entries.reduce((prevEntry, currEntry) => {
        return prevEntry + currEntry.totalChildSupport();
    }, 0.0)));
    row.set('Medical Insurance', usdFormatter.format(entries.reduce((prev, curr) => {
        return prev + validateDecimal(curr.medical);
    }, 0.0)));
    row.set('Dental Insurance', usdFormatter.format(entries.reduce((prev, curr) => {
        return prev + validateDecimal(curr.dental);
    }, 0.0)))
    row.set('Vision Insurance', usdFormatter.format(entries.reduce((prev, curr) => {
        return prev + validateDecimal(curr.vision);
    }, 0.0)));


    const deductionTotals = {}
    Deduction.deductionTypes.filter(t => t !== '401K (% of Gross)' && t !== 'Other (% of Gross)').forEach(type => deductionTotals[type] = 0.0);
    entries.forEach((entry) => {
        const entryTotals = entry.totalDeductionsByType();
        Deduction.deductionTypes.filter(t => t !== '401K (% of Gross)' && t !== 'Other (% of Gross)').forEach(type => deductionTotals[type] += entryTotals[type]);
    })

    Object.entries(deductionTotals).forEach(([key, value]) => {
        row.set(key, usdFormatter.format(value));
    })

    row.set('Total Deductions', bigToUsd(entries.reduce((prev, curr) => {
        return prev.plus(curr.totalDeductions());
    }, new Big('0.0'))))

    return row;
}