/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { Validator, validateGreaterThanZero } from "../../../../../../validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import QuickTable from "../../../../../../components/QuickTable";
import { bigToUsd, holidayPayTypeDictionary } from "../../../../payrollTools";
import { getHolidayUnitsTypeString } from "../../../../../../tools";
import Big from "big.js";



export default function PayrollEmployeeHolidaysEditor({entry, index, handleSetWeek}) {

    const removeHoliday = (id) => {
        const newArr = entry.weeks[index].holidayArray.filter((element) => {
            return element.id !== id;
        });
        handleSetWeek(index, 'holidayArray', newArr);
    }

    const handleSetHoliday = (id, key, value) => {
        const newArr = Array.from(entry.weeks[index].holidayArray);
        newArr.find(r => r.id === id)[key] = value;
        handleSetWeek(index, 'holidayArray', newArr);
    }

    const holidayElements = entry.weeks[index].holidayArray.map((holidayObject) => {
        return (
            <HolidayElement 
                key={holidayObject.id}
                holiday={holidayObject} 
                removeHoliday={removeHoliday}
                handleSetHoliday={handleSetHoliday}
                entry={entry}
                validator={new Validator(() => validateGreaterThanZero(holidayObject.payRate))}
            />
        )
    });


    return (
        <QuickTable title='Holidays' headers={['Pay Type', 'Pay Rate', 'Unit Type', 'Units Worked','Total', '']} widths={[null,null, null, null, 50, 20]} rows={holidayElements} size={'sm'}/>
    )
}


function HolidayElement({holiday, removeHoliday, handleSetHoliday, validator}) {
    
    const[showDropdown, setShowDropdowm] = useState(false);
    const [payTypeSearch, setPayTypeSearch] = useState(holidayPayTypeDictionary[holiday.payType])
    const handleSetPayType = (value)=>{
        handleSetHoliday(holiday.id, 'payType', value);
        setPayTypeSearch(holidayPayTypeDictionary[value]);
    }

    const payTypeDropDownItems = Object.entries(holidayPayTypeDictionary).map(([key, value]) =>{
        return (
            
            <Dropdown.Item key={key} eventKey={key} active={payTypeSearch === value} onClick={() => {handleSetPayType(key); setShowDropdowm(false)}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <p style={{marginBottom: 0, marginRight: 8}}>{value}</p> 
                </div>
            </Dropdown.Item>  
        )
    });

    
    return (
        <tr>
            <td style={{width:'21%'}}>   
                <div style={{position:'absolute', marginBottom:2, marginTop:-1.5, width:'11%'}}>
                    <Form.Control readOnly style={{height:'90%'}} placeholder='Pay Type' type='text' value ={payTypeSearch} onChange={(event)=>{handleSetPayType(event.target.value)}} onFocus={()=>setShowDropdowm(true)} onBlur={() => setTimeout(() => {setShowDropdowm(false)}, 150)} />
                    <Dropdown.Menu onMouseDown={(e)=>e.preventDefault()} key="dropdown-menu" show={showDropdown} style={{maxHeight: 300, overflowY: 'scroll'}}>
                        {payTypeDropDownItems}
                    </Dropdown.Menu>
                </div>
            </td>
            <td>
                <InputGroup>
                    <InputGroup.Text style={{padding: '2px 6px 2px 6px'}}>$</InputGroup.Text>
                    <Form.Control isInvalid={!validator.isValid()} style={{padding: '2px 6px 2px 6px'}} type='number' min={0} value={holiday.payRate} placeholder='Pay Rate' onChange={(event) => {handleSetHoliday(holiday.id, 'payRate', event.target.value.replace('-', ''))}}/>
                    { validator ? <Form.Control.Feedback type='invalid'>{validator.getInvalidMessage()}</Form.Control.Feedback> : ''}
                </InputGroup>
            </td>
            {holiday.payType !== 'flat' ? <td>{getHolidayUnitsTypeString(holiday.payType)}</td> : <td>Flat Amount</td>}
            {holiday.payType !== 'flat' ? <td>
                <Form.Control style={{padding: '2px 6px 2px 6px'}} type='number' min={0} value={holiday.unitsWorked} placeholder='Units' onChange={(event) => {handleSetHoliday(holiday.id, 'unitsWorked', event.target.value.replace('-', ''))}}/>
            </td> : <td></td>}
            <td>{holiday.payType !== 'flat' ? (holiday.payRate !=='' && holiday.unitsWorked !=='' ? bigToUsd(holiday.holidayPay()) : bigToUsd(new Big('0.00'))) : (holiday.payRate !=='' ? bigToUsd(holiday.holidayPay()) : bigToUsd(new Big('0.00')))}</td>
            <td>
                <Button style={{color: 'white', padding: '2px 6px 2px 6px', width: '100%'}} onClick={() => removeHoliday(holiday.id)}>
                    <FontAwesomeIcon icon={faTrashCan}/>
                </Button>
            </td>
        </tr>
    );
}