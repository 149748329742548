/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../decoding";
import { validateDecimalFixed, validateInteger } from "../../../tools";

export default class BudgetProductivity {
	dispatchesLight;
	dispatchesStandard;
	dispatchesStraight;
	eCommStopPercentage;
	stops;
	packages;

    constructor(dispatchesLight, dispatchesStandard, dispatchesStraight, eCommStopPercentage, stops, packages) {
        this.dispatchesLight = dispatchesLight;
        this.dispatchesStandard = dispatchesStandard;
        this.dispatchesStraight = dispatchesStraight;
        this.eCommStopPercentage = eCommStopPercentage;
        this.stops = stops;
        this.packages = packages;
    }

    static initDefault() {
        return new BudgetProductivity('', '', '', '', '', '');
    }

    static decode(json) {
        const decoder = new Decoder(json);
        const dispatchesLight = decoder.decode('dispatchesLight', Decoder.Integer);
        const dispatchesStandard = decoder.decode('dispatchesStandard', Decoder.Integer);
        const dispatchesStraight = decoder.decode('dispatchesStraight', Decoder.Integer);
        const eCommStopPercentage = decoder.decode('eCommStopPercentage', Decoder.Decimal);
        const stops = decoder.decode('stops', Decoder.Decimal);
        const packages = decoder.decode('packages', Decoder.Decimal);

        if (decoder.checkForErrors()) {
            return new BudgetProductivity(dispatchesLight, dispatchesStandard, dispatchesStraight, eCommStopPercentage, stops, packages);
        } else {
            return BudgetProductivity.initDefault();
        }
    }

    encode(index) {
        return {
            weekDayIndex: index,
            dispatchesLight: validateInteger(this.dispatchesLight),
            dispatchesStandard: validateInteger(this.dispatchesStandard),
            dispatchesStraight: validateInteger(this.dispatchesStraight),
            eCommStopPercentage: validateDecimalFixed(this.eCommStopPercentage, 3),
            stops: validateDecimalFixed(this.stops, 3),
            packages: validateDecimalFixed(this.packages, 3),
        };
    }

    duplicate() {
        return new BudgetProductivity(
            this.dispatchesLight,
            this.dispatchesStandard,
            this.dispatchesStraight,
            this.eCommStopPercentage,
            this.stops,
            this.packages
        );
    }

    equals(budgetProductivity) {
        return this.dispatchesLight === budgetProductivity.dispatchesLight &&
            this.dispatchesStandard === budgetProductivity.dispatchesStandard &&
            this.dispatchesStraight === budgetProductivity.dispatchesStraight &&
            this.eCommStopPercentage === budgetProductivity.eCommStopPercentage &&
            this.stops === budgetProductivity.stops &&
            this.packages === budgetProductivity.packages;
        ;
    }

}