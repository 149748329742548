/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import PayrollPeriodHeadersRow from "./PayrollPeriodHeadersRow";
import PayrollPeriodTotalsRow from "./PayrollPeriodTotalsRow";
import PayrollPeriodEntryRow from "./PayrollPeriodEntryRow";

export default function PayrollPeriodCsv(entries, companyName, periodStart, periodEnd) {

    const rows = [];
    rows.push(['', "\"" + companyName + "\"", "\"" + moment(periodStart).format('MMM D, YYYY') + ' - ' + moment(periodEnd).format('MMM D, YYYY') + "\""]);
    rows.push([]);

    const tableHeaders = PayrollPeriodHeadersRow(entries, periodStart, periodEnd);

    tableHeaders.splice(1, 1);
    tableHeaders.unshift('');
    tableHeaders.unshift('');
    rows.push(tableHeaders);

    const csas = [];
    entries.forEach((e) => {
        if (!csas.includes(e.csaName)) {
            csas.push(e.csaName);
        }
    });

    csas.forEach((csa) => {
        rows.push(['', csa]);
        const entriesInCsa = entries.filter(e => e.csaName === csa);

        entriesInCsa.forEach((entry) => {
            PayrollPeriodEntryRow(entry).forEach((entryPay, index) => {
                const entryRow = tableHeaders.map((key) => `\"${entryPay[key] ?? ''}\"`);
                if (index === 0 && entry.isNew) {
                    entryRow[1] = 'NEW';
                }
                rows.push(entryRow);
            })
        })

        const csaTotals = PayrollPeriodTotalsRow(entriesInCsa);

        const csaTotalsRow = tableHeaders.map((key) => `\"${csaTotals.get(key) ?? ''}\"`);
        csaTotalsRow[1] = 'Total for CSA';
        rows.push(csaTotalsRow);

    })

    rows.push([]);
    
    const totals = PayrollPeriodTotalsRow(entries);
    const totalsRow = tableHeaders.map((key) => `\"${totals.get(key) ?? ''}\"`);
    totals[0] = 'Totals';
    rows.push(totalsRow);

    const stringRows = rows.map(r => r.join(','));
    const fullString = stringRows.join('\r');
  
    return fullString;
}