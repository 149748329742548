/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import PDFSchedule from './PDFSchedule';
import { validateExistence, ValidationGroup } from '../../../validation';
import CustomControl from '../../../components/CustomControl';
import { PDFViewer } from '@react-pdf/renderer';
import CustomButton from '../../../components/CustomButton';
import { ApiRequest } from '../../../ApiManager.tsx';

export function ScheduleMatchScheduleDownload() {
    const [isLoading, setIsLoading] = useState(false)
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [data, setData] = useState(undefined);
    const validationGroup = new ValidationGroup();

    useEffect(() => {
        loadData();
    }, []);

    function loadData(startDate, endDate){
        const snappedStartDate = (moment(startDate).day() === 6 ? moment(startDate) : moment(startDate).startOf('week').subtract(1, 'days')).format('YYYY-MM-DD');
        const snappedEndDate = (moment(endDate).day() === 6 ? moment(endDate).add(6, 'days') : moment(endDate).endOf('week').subtract(1, 'days')).format('YYYY-MM-DD');
        new ApiRequest('scheduleMatch', 'get', setIsLoading, (response) => {
            response.scheduleItems.forEach((item) => {
                item.companyUser = response.users.find(u => u.companyUserUid == item.companyUserIdentifier);           
            })

            response.routes.forEach(route=>{
                route.companyUser = response.users.find(u => u.companyUserUid == route.companyUserIdentifier);  
                route.vehicle = response.vehicles.find(v => v.uid == route.vehicleIdentifier);
                if (route.secondaryDriver) {
                    route.secondaryDriver = response.users.find(u => u.companyUserUid == route.secondaryDriver);
                }
            })

            response.workAreas.forEach((wa) => {
                wa.routes = response.routes.filter(r => r.workAreaIdentifier == wa.uid);
                wa.openShifts = response.openShifts.filter(os => os.workAreaIdentifier == wa.uid);
            })
            response.csas.forEach((csa) => {
                csa.workAreas = response.workAreas.filter(wa => wa.csaIdentifier == csa.uid);
            })

            setData({
                companyName: response.companyName,
                startDate: snappedStartDate,
                endDate: snappedEndDate,
                csas: response.csas,
                routes: response.routes,
            });
        }).withData({startDate: snappedStartDate, endDate: snappedEndDate}).withNoAlertOnSuccess().send();
    }

    return (
        <>
            <Modal.Header closeButton>
                <div style={{display: 'flex', gap: 12, alignItems: 'center'}}>
                    <Modal.Title>Select A Date Range: </Modal.Title>
                    <CustomControl validator={validationGroup.createValidator(() => validateExistence(startDate))} type='date' max={endDate} title='Start Date' value={startDate} onChange={(event) => {setStartDate(event.target.value)}}/>
                    <CustomControl validator={validationGroup.createValidator(() => validateExistence(endDate))} type='date' min={startDate} title='End Date' value={endDate} onChange={(event) => {setEndDate(event.target.value)}}/>
                    <CustomButton disabled={!validationGroup.isValid()} isLoading={isLoading} label='Submit' onClick={() => {loadData(startDate, endDate)}}/>
                </div>
            </Modal.Header>
            <Modal.Body>
                { !isLoading && data &&
                    <PDFViewer style={{width: '100%', height: '99%'}}>
                        <PDFSchedule data={data}/>
                    </PDFViewer>
                }
            </Modal.Body>
        </>
    )
}