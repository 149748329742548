/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { PDFViewer } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import PDFAcknowledgement from "./PDFAcknowledgement";
import { Dropdown} from "react-bootstrap";
import { naturalSort } from "../../../tools";
import DropdownItemWithCheck from "../../../components/DropdownItemWithCheck";
import LoadingWrapper from "../../../components/LoadingWrapper";
import { ApiRequest } from "../../../ApiManager.tsx";

export default function AcknowledgementReport({}){
    const [isLoading, setIsLoading] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [users, setUsers] = useState([]);
    const [csas, setCsas] = useState([]);
    const [selectedCsas, setSelectedCsas] = useState([]);

    useEffect(()=>{
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('reports', 'companyAcknowledgementsReport', setIsLoading, (response) => {
            setUsers(response.users.sort((a, b) => `${a.lastName}${a.firstName}${a.middleName}`.localeCompare(`${b.lastName}${b.firstName}${b.middleName}`)));
            setDocuments(naturalSort(response.documents, 'documentName'));
            setCsas(response.csas)
            setSelectedCsas(response.csas)
        }).withNoAlertOnSuccess().send();
    }

    function handleSelectAllCsas(){
        if(selectedCsas.length !== csas.length){
            setSelectedCsas(csas);
        }else{
            setSelectedCsas([])
        }
    }

    function handleSelectCsa(csa){
        if(selectedCsas.find(c=> c.uid == csa.uid)){
            setSelectedCsas(selectedCsas.filter(c=>c.uid != csa.uid))
        }else{
            setSelectedCsas([...selectedCsas, csa])
        }
    }

    const csaElements = naturalSort(csas, 'csaName').map((csa) => {
        return (
            <DropdownItemWithCheck key={csa.uid} label={csa.csaName} active={selectedCsas.find(c => c.uid == csa.uid)} onClick={() => {handleSelectCsa(csa)}}/>
        )
    })

    return (
        <>
            <Modal.Header closeButton>
                { !isLoading &&
                    <Dropdown autoClose='outside'>
                        <Dropdown.Toggle variant="outline-primary">
                            {selectedCsas.length == 0 ? 'Filter CSA(s)' : selectedCsas.length > 1 ? `Filter CSA(s): ${selectedCsas[0].csaName} +${selectedCsas.length - 1}` : `Filter CSA(s): ${selectedCsas[0].csaName}`}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Header>CSAs</Dropdown.Header>
                            {csaElements}
                            <Dropdown.Divider/>
                            <DropdownItemWithCheck label='Select/Deselect All' active={selectedCsas.length === csas.length} onClick={() => handleSelectAllCsas()}/>
                        </Dropdown.Menu>
                    </Dropdown>
                }               
            </Modal.Header>
            <Modal.Body>
                <LoadingWrapper isLoading={isLoading}>
                    <PDFViewer style={{width: '100%', height: '99%'}}>
                        <PDFAcknowledgement key={selectedCsas.length} documents={documents} users={users.filter(u => selectedCsas.map(c => c.uid).includes(u.csaIdentifier))}/>
                    </PDFViewer>
                </LoadingWrapper>
            </Modal.Body>
        </>
    )
}