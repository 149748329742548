/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../decoding";
import { validateDecimal } from "../payrollTools";

export default class Ticket {
    uid;
    name;
    amount;
    ticketIdentifier;
    isEnabled;
    deductFromSafetyBonuses;

    constructor(uid, amount, ticketIdentifier, name, isEnabled, deductFromSafetyBonuses) {
        this.uid = uid;
        this.amount = amount;
        this.ticketIdentifier = ticketIdentifier;
        this.name = name;
        this.isEnabled = isEnabled;
        this.deductFromSafetyBonuses = deductFromSafetyBonuses;        
    }

    static initDefault() {
        return new Ticket(-1, 0.0, -1, '', true, false);
    }

    static decode(json) {
        const decoder = new Decoder(json);
        const uid = decoder.decode('uid', Decoder.Uid);
        const name = decoder.decode('name', Decoder.String);
        const amount = decoder.decode('amount', Decoder.Decimal);
        const ticketIdentifier = decoder.decode('ticketIdentifier', Decoder.Uid);
        const isEnabled = decoder.decode('isEnabled', Decoder.Boolean, {defaultValue: true, warn: false});
        const deductFromSafetyBonuses = decoder.decode('deductFromSafetyBonuses', Decoder.Boolean);
        if (decoder.checkForErrors()) {
            return new Ticket(uid, amount, ticketIdentifier, name, isEnabled, deductFromSafetyBonuses);
        } else {
            return Ticket.initDefault();
        }
    }

    encode() {
        return {
            uid: this.uid,
            name: this.name,
            amount: validateDecimal(this.amount),
            ticketIdentifier: this.ticketIdentifier,
            isEnabled: this.isEnabled,
            deductFromSafetyBonuses: this.deductFromSafetyBonuses
        }
    }

    duplicate() {
        return new Ticket(this.uid, this.amount, this.ticketIdentifier, this.name, this.isEnabled, this.deductFromSafetyBonuses);
    }

    getAmount() {
        return validateDecimal(this.amount);
    }

    getAmountToPay(entry) {
        if (this.deductFromSafetyBonuses) {
            let amountToDeduct = entry.getAllNdBonusesOfType('Safety Bonus').reduce((prev, curr) => {
                return prev + curr.getAmount();
            }, 0);

            const tickets = entry.getEnabledTickets().filter(t => t.deductFromSafetyBonuses);

            let index = 0;
            while (index < tickets.length && amountToDeduct > 0) {
                if (tickets[index].uid === this.uid) {
                    return Math.min(this.getAmount(), amountToDeduct);
                } else {
                    amountToDeduct -= Math.min(amountToDeduct, tickets[index].getAmount());
                }
                index++;
            }
            return 0;
        } else {
            return this.getAmount();
        }
    }

}