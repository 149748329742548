/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/


import { PDFViewer } from "@react-pdf/renderer";
import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import DateRangeSelector from "../../../components/DateRangeSelector";
import { Button } from "react-bootstrap";
import OSHAReportPdf from "./OSHAReportPdf";
import { ApiRequest } from "../../../ApiManager.tsx";

export default function OSHAReport({}) {
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [bwcData, setBwcData] = useState([]);
    
    function handleGetBWCData(startDate, endDate){
        setStartDate(startDate);
        setEndDate(endDate);
        new ApiRequest('reports', 'osha', setIsLoading, (response) => setBwcData(response.data)).withData({startDate: startDate, endDate: endDate}).withNoAlertOnSuccess().send();
    }

    return (
        <>
            <Modal.Header closeButton>
                <div style={{display: 'flex', gap: 12, alignItems: 'center'}}>
                    <Modal.Title>Select A Date Range: </Modal.Title>
                    <Button variant={'outline-primary'} onClick={()=>setShowModal(true)}>Select Date Range</Button>
                    <DateRangeSelector show={showModal} onHide={()=>setShowModal(false)} handleSetDateRange={handleGetBWCData} presetOptions={['pastMonth', 'pastWeek', 'yesterday']}/>  
                </div>
            </Modal.Header>
            <Modal.Body>
                {startDate && !isLoading && 
                <PDFViewer style={{width: '100%', height: '99%'}}>
                   <OSHAReportPdf bwcData={bwcData} startDate={startDate} endDate={endDate}/>
                </PDFViewer>}
            </Modal.Body>
        </>
    )
}