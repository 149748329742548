/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import '../HumanResources.css';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import QuickTable from '../../../components/QuickTable';
import { Button } from 'react-bootstrap';
import HRUserPtoModal from '../HRUserPTO/HRUserPtoModal';
import HRCreateScheduleItemModal from '../HRUserSchedule/HRCreateScheduleItemModal';
import CustomButton from '../../../components/CustomButton';
import DateRangeSelector from '../../../components/DateRangeSelector';
import { dateIsInRange, dateRangeToString } from '../../../tools';
import { toFixedMax } from '../../Payroll/payrollTools';
import { ApiRequest } from '../../../ApiManager.tsx';
import LoadingWrapper from '../../../components/LoadingWrapper';

export default function HRUserPto({selectedEmployee, startDateProp, endDateProp, handleRecalculate}){
    const [isLoading, setIsLoading] = useState(false);
    const [modalSwitch, setModalSwitch] = useState('');
    const [transactions, setTransactions] = useState([]);
    const [selectedTransaction, setSelectedTransaction] = useState(undefined);
    const [startDate, setStartDate] = useState(startDateProp);
    const [endDate, setEndDate] = useState(endDateProp);
    const [showDateRangeSelector, setShowDateRangeSelector] = useState(false);

    useEffect(() => {
        loadData();
    }, [selectedEmployee])

    function loadData(){
        new ApiRequest('hr', 'getPto', setIsLoading, (response) => setTransactions(response.pto)).withData({companyUserIdentifier: selectedEmployee.companyUserUid}).withNoAlertOnSuccess().send();
    }

    function sortTransactions(a, b) {
        if (a.dateandtime < b.dateandtime) {
            return 1;
        } else if (a.dateandtime > b.dateandtime) {
            return -1;
        }
        return 0;
    }

    function sortScheduledTransactions(a, b) {
        if (a.scheduleItem.date < b.scheduleItem.date) {
            return 1;
        } else if (a.scheduleItem.date > b.scheduleItem.date) {
            return -1;
        }
        return 0;
    }

    function sortAccruedTransactions(a, b) {
        if (a.payrollPeriod.periodStart < b.payrollPeriod.periodStart) {
            return 1;
        } else if (a.payrollPeriod.periodStart > b.payrollPeriod.periodStart) {
            return -1;
        }
        return 0;
    }


    function handleTransactionCrud(type, data) {
        let newArray = Array.from(transactions);
        if (type === 'create') {
            newArray.push(data);
        } else if (type === 'update') {
            newArray = newArray.filter(t => t.uid != data.uid);
            newArray.push(data);
        } else if (type === 'delete') {
            newArray = newArray.filter(t => t.uid != data.uid);
        }

        if (handleRecalculate) {
            handleRecalculate();
        }

        setModalSwitch('');
        setTransactions(newArray);
    }

    function handleSetSelectedDateRange(startDate, endDate) {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    const balance = transactions.reduce((prev, curr) => {
        return prev + curr.number;
    }, 0);


    const scheduledPtoRows = transactions.filter(i => i.scheduleItem && dateIsInRange(i.scheduleItem.date, startDate, endDate)).sort(sortScheduledTransactions).map((item) => {
        return (
            <tr className='cursor-pointer' key={item.uid} onClick={() => {setSelectedTransaction(item); setModalSwitch('transactionEditor')}}>

                <td>{moment(item.scheduleItem.date).format('MMM D, YYYY')}</td>
                <td>{moment(item.dateandtime).format('MMM D, YYYY [at] h:mm A')}</td>
                <td style={{color: item.number > 0 ? 'green' : 'red'}}>{item.number > 0 ? `+${item.number}` : item.number}</td>
                <td>{item.payrollPeriod ? `${moment(item.payrollPeriod.periodStart).format('MMM D, YYYY')} - ${moment(item.payrollPeriod.periodEnd).format('MMM D, YYYY')}` : ''}</td>
                <td style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 200, overflowX: 'hidden'}}>{item.notes}</td>
            </tr>
        );
    });

    const manualPtoRows = transactions.filter(i => !i.scheduleItem && !i.payrollPeriod).sort(sortTransactions).map((item) => {
        return (
            <tr className='cursor-pointer' key={item.uid} onClick={() => {setSelectedTransaction(item); setModalSwitch('transactionEditor')}}>
                <td>{moment(item.dateandtime).format('MMM D, YYYY [at] h:mm A')}</td>
                <td style={{color: item.number > 0 ? 'green' : 'red'}}>{item.number > 0 ? `+${item.number}` : item.number}</td>
                <td style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 200, overflowX: 'hidden'}}>{item.notes}</td>
            </tr>
        );
    });

    const accruedPtoRows = transactions.filter(i => !i.scheduleItem && i.payrollPeriod).sort(sortAccruedTransactions).map((item) => {
        return (
            <tr key={item.uid}>
                <td>{`${moment(item.payrollPeriod.periodStart).format('MMM D, YYYY')} - ${moment(item.payrollPeriod.periodEnd).format('MMM D, YYYY')}`}</td>
                <td style={{color: 'green'}}>{`+${item.number}`}</td>
            </tr>
        );
    })

    const content = (
        <div style={{height: '100%', display: 'flex', flexDirection: 'column', gap: 12, overflow: 'hidden'}}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <span>Current PTO Balance: <b>{`${toFixedMax(balance, 2)} Hours`}</b></span>
                <div style={{display: 'flex', gap: 12}}>
                    <Button variant='outline-primary' onClick={() => setShowDateRangeSelector(true)}>Viewing Scheduled PTO for: <b>{dateRangeToString(startDate, endDate)}</b></Button>
                    <DateRangeSelector handleSetDateRange={handleSetSelectedDateRange} show={showDateRangeSelector} onHide={() => setShowDateRangeSelector(false)} presetOptions={['pastYear', 'pastMonth', 'pastWeek', 'nextWeek', 'nextMonth', 'nextYear', 'allTime']}/>
                    <CustomButton label='Schedule Time Off' onClick={() => setModalSwitch('showCreateTimeOff')}/>
                    <Button variant={'outline-primary'} onClick={() => {setSelectedTransaction(null); setModalSwitch('transactionEditor')}}>
                        Add/Subtract PTO
                    </Button>
                </div>
            </div>
            <h3>PTO Transaction History</h3>
            <div style={{display: 'flex', flexDirection: 'column', flex: 1, gap: 12, overflowY: 'auto'}}>
                <QuickTable 
                    noMargin
                    title='Scheduled PTO'
                    headers={['Date of PTO', 'Date Approved', 'Amount (Hours)', 'Payroll Period Paid', 'Notes']}
                    rows={scheduledPtoRows}
                    hover
                    style={{flex: 0}}
                />
                <QuickTable 
                    noMargin
                    title='Manually Entered Transactions'
                    headers={['Date Created', 'Amount (Hours)', 'Notes']}
                    rows={manualPtoRows}
                    hover
                    style={{flex: 0}}
                />
                <QuickTable 
                    noMargin
                    title='Accrued PTO'
                    headers={['Payroll Period Earned', 'Amount (Hours)']}
                    rows={accruedPtoRows}
                    hover
                    style={{flex: 0}}
                />
            </div>
            <Modal show={modalSwitch === 'transactionEditor'} onHide={() => setModalSwitch('')} size='lg'>
                <HRUserPtoModal
                    handleTransactionCrud={handleTransactionCrud}
                    companyUserIdentifier={selectedEmployee.companyUserUid}
                    selectedTransaction={selectedTransaction}
                />
            </Modal>
            <Modal show={modalSwitch === 'showCreateTimeOff'} onHide={() => setModalSwitch('')} size='lg'>
                <HRCreateScheduleItemModal companyUserIdentifier={selectedEmployee.companyUserUid} handleAddPTO={() => {
                    setModalSwitch('')
                    loadData();
                    if (handleRecalculate) {
                        handleRecalculate();
                    }
                }}/>
            </Modal>
        </div>
    )

    return (
        <LoadingWrapper isLoading={isLoading} onlyRenderIfTruthy={selectedEmployee ?? false}>
            {content}
        </LoadingWrapper>
    )

}