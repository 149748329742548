/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import CustomControl from '../../components/CustomControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import CustomButton from '../../components/CustomButton';
import { ApiRequest } from '../../ApiManager.tsx';

export default function ScheduleMatchTemplateImportModal({uid}) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const startOfFirstWeekOffset = moment(startDate).day() === 6 ? 0 : moment(startDate).day() + 1;
    const startOfFirstWeek = moment(startDate).subtract(startOfFirstWeekOffset, 'days');
    const endOfLastWeekOffset = moment(endDate).day() + 1;
    const endOfLastWeek = moment(endDate).add(7 - endOfLastWeekOffset, 'days');
    const numberOfWeeks = endOfLastWeek.diff(startOfFirstWeek, 'weeks');

    function handleImportTemplate(){
        const weeks = [];
        for (let i = 0; i < numberOfWeeks; i++) {    
            weeks.push(moment(startOfFirstWeek).add(i, 'weeks').format('YYYY-MM-DD'));
        }
        new ApiRequest('scheduleMatch', 'importTemplate', setIsSubmitting, () =>  window.location.reload()).withData({uid: uid, weeks: weeks}).withNoAlertOnSuccess().send();
    }

    const weekElements = [];
    for (let i = 0; i < numberOfWeeks; i++) {
        const weekStart = moment(startOfFirstWeek).add(i, 'weeks');
        const weekEnd = moment(weekStart).add(6, 'days');

        weekElements.push(
            <li key={i}>{`${weekStart.format('MMM D, YYYY')} - ${weekEnd.format('MMM D, YYYY')}`}</li>
        )
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Importing Template</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <div style={{display: 'flex', gap: 8}}>
                    <div style={{flex: 1}}>
                        <CustomControl type='date' title='Start Date' value={startDate} onChange={(event) => {setStartDate(event.target.value)}}/>
                    </div>
                    <div style={{flex: 1}}>
                        <CustomControl type='date' title='End Date' value={endDate} onChange={(event) => {setEndDate(event.target.value)}}/>
                    </div>
                </div>
                { weekElements.length > 0 &&
                    <div>
                        <p>You have selected the following weeks:</p>
                        <ol>
                            {weekElements}
                        </ol>
                    </div>
                }
                <div>
                    <FontAwesomeIcon icon={faExclamationTriangle} style={{color: 'gold', marginRight: 8}}/>
                    <span>
                        Importing a template will delete any preexisting routes scheduled during the selected weeks
                    </span>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton onClick={handleImportTemplate} disabled={!startDate || !endDate || numberOfWeeks < 1} isLoading={isSubmitting} label='Import Template'/>
            </Modal.Footer>
        </>
    )
}