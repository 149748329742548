/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './ScheduleMatch.css';
import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';
import CustomButton from '../../components/CustomButton';
import { ApiRequest } from '../../ApiManager.tsx';
import moment from 'moment';

function ScheduleMatchDeleteAllModal({handleDeleteAllRoutes, hideModal, startOfWeek}) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [confirmation, setConfirmation] = useState('');

    function handleButtonPress(){
        new ApiRequest('scheduleMatch', 'deleteAllRoutes', setIsSubmitting, () => {
            handleDeleteAllRoutes();
            hideModal();
        }).withData({startDate: moment(startOfWeek).format('YYYY-MM-DD'), endDate: moment(startOfWeek).add(6, 'days').format('YYYY-MM-DD')}).send();
    }

    const confirmationCode = 'delete all routes';
    const letterElements = confirmationCode.split('').map((char, index) => {
        return (
            <p key={index} style={{display: 'inline', marginBottom: 0, color: (confirmation.length >= (index + 1) && confirmation.charAt(index) === char) ? 'green' : 'red'}}>{char}</p>
        )
    });

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 12}}>
            <Form.Group>
                <Form.Label>Type "{letterElements}" to confirm deletion</Form.Label>
                <Form.Control isValid={confirmation === confirmationCode} isInvalid={!confirmationCode.includes(confirmation)} value={confirmation} onChange={(event) => {setConfirmation(event.target.value)}} type='text' placeholder='delete all routes'/>
            </Form.Group>
            <CustomButton label='Confirm Deletion' isLoading={isSubmitting} disabled={confirmation !== confirmationCode} onClick={handleButtonPress}/>
        </div>
    )
}

export default ScheduleMatchDeleteAllModal;