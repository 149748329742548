/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { React, useEffect, useState } from "react";
import CustomButton from "../components/CustomButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, ListGroup } from "react-bootstrap";
import { faCircleCheck} from "@fortawesome/free-regular-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { ApiRequest } from "../ApiManager.tsx";
import LoadingWrapper from "../components/LoadingWrapper";

export default function Onboarding() {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [token, setToken] = useState();
    const [documents, setDocuments] = useState([]);
    const [viewedDocuments, setViewedDocuments] = useState([]);
    const [acknowledgedDocuments, setAcknowledgedDocuments] = useState([]);
    const [allDone, setAllDone] = useState(false);

    useEffect(() => {
        loadData();
    }, [])

    function loadData(){
        const onboardingToken = window.location.href.split('/')[4]
        new ApiRequest('onboarding', 'getRequiredDocuments', setIsLoading, (response) => {
            setToken(onboardingToken);
            if (response.documents.length == 0) {
                setAllDone(true);
            } else {
                setDocuments(response.documents);
            }
        }).withData({onboardingToken}).withNoAlertOnSuccess().send();
    }

    function handleSubmit(){
        new ApiRequest('onboarding', 'acknowledgeDocuments', setIsSubmitting, () => setAllDone(true)).withData({onboardingToken: token, companyDocumentUids: acknowledgedDocuments}).send()
    }

    function handleAcknowledgeDocument(uid){
        const newArray = Array.from(acknowledgedDocuments);
        newArray.push(uid);
        setAcknowledgedDocuments(newArray);
    }

    async function handleDownloadDocument(d){
        try {
            const fetchResponse = await fetch(d.base64);
            const blob = await fetchResponse.blob();
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = d.documentName;        
            document.body.appendChild(elem);
            elem.click();        
            document.body.removeChild(elem);

            if (!viewedDocuments.includes(d.uid)) {
                const newArray = Array.from(viewedDocuments);
                newArray.push(d.uid);
                setViewedDocuments(newArray);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const documentElements = documents.map((d) => {
        const viewed = viewedDocuments.includes(d.uid);
        const acknowledged = acknowledgedDocuments.includes(d.uid)

        return (
            <ListGroup.Item key={d.uid} style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 12}}>
                <span>{d.documentName}</span>
                <div style={{flex: 1, justifyContent: 'flex-end', display: 'flex', alignItems: 'center', gap: 4}}>
                    <Button variant={viewed ? "outline-success" : "outline-primary"} style={viewed ? {fontSize: 14, padding: '0px 6px 0px 6px'} : undefined} onClick={() => {handleDownloadDocument(d)}}>
                        { viewed ?
                            <>
                                <span style={{fontSize: 14}}>Viewed</span>
                                <FontAwesomeIcon style={{marginLeft: 8}} size='sm' icon={faCheck}/>
                            </>
                        :
                            <span>View</span>
                        }
                    </Button>
                    { acknowledged ?
                        <Button disabled variant="outline-success" style={{fontSize: 14, padding: '0px 6px 0px 6px'}}>
                            <span style={{fontSize: 14}}>Acknowledged</span>
                            <FontAwesomeIcon style={{marginLeft: 8}} size='sm' icon={faCheck}/>
                        </Button>
                    :
                        <Button disabled={!viewed} variant="outline-primary" onClick={() => {handleAcknowledgeDocument(d.uid)}}>Acknowledge</Button>
                    }
                </div>
            </ListGroup.Item>
        )
    })

    return(
        <div style={{position: 'relative', height: '100vh', width: '100vw', backgroundColor: 'var(--bs-primary)'}}>
            <div className='login-background' style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, opacity: 0.05}}/>
            <div style={{display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', alignItems: 'center', padding: 12}}>
                <LoadingWrapper isLoading={isLoading}>
                    {allDone 
                    ?   <Card>
                            <Card.Body style={{textAlign: 'center'}}>
                                <FontAwesomeIcon style={{color: 'green', marginBottom: 12}} size='4x' icon={faCircleCheck}/>
                                <h4 style={{opacity: 0.66, fontStyle: 'italic'}}>You have acknowledged all of your company's required documents and may now close this page.</h4>
                            </Card.Body>
                        </Card>
                    :   <Card>
                            <Card.Header>

                                <Card.Title>Please View and Acknowledge the Following Documents:</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <ListGroup>
                                    {documentElements}
                                </ListGroup>
                            </Card.Body>
                            { acknowledgedDocuments.length === documents.length && 
                                <Card.Footer style={{display: 'flex', flexDirection: 'column'}}>
                                    <CustomButton isLoading={isSubmitting} onClick={handleSubmit} label={'I Have Read and Acknowledge All of the Above Documents'}/>
                                </Card.Footer>
                            }
                        </Card>
                    }
                </LoadingWrapper>
            </div>
        </div>
    )
}