/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import { Card, Col, Container, Modal, Row, ListGroup } from "react-bootstrap";
import { Validator, validatePasswordMatch, validateExistence } from "../../validation";
import moment from "moment";
import CustomControl from "../../components/CustomControl";
import CustomButton from "../../components/CustomButton";
import KeyValueRow from "../../components/KeyValueRow";
import { addressToString, formatPhoneNumber } from "../../tools";
import EContactOne from "./EContactOne";
import EContactTwo from "./EContactTwo";
import { ApiRequest } from "../../ApiManager.tsx";

export default function PersonalInfo({user, eContact1Address, eContact2Address}){
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isChangingPassword, setIsChangingPassword] = useState(false);

    const validators = [
        new Validator(() => validateExistence(oldPassword)),
        new Validator(() => validatePasswordMatch(newPassword, confirmPassword)),
        new Validator(() => validatePasswordMatch(confirmPassword, newPassword)),
    ];

    function handlePasswordChange(){
        new ApiRequest('getProfileInformation', 'changePassword', setIsChangingPassword, () => {
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
            setShowModal(false);
        }).withData({currentPassword: oldPassword, newPassword: newPassword}).send()
    }
 
    return (
        <Container fluid>
            <div style={{padding: 5}}>
                <p style={{textAlign: 'center', margin: 0, fontSize: 24, fontWeight: 'bold', padding:10}}>Personal Details</p>
            </div>
            <Row style={{paddingBottom: 5}}>
                <Col>
                    <Card>
                        <Card.Body style={{display:'flex', flexDirection:'column'}}>
                            <div>
                                <Button variant={'outline-primary'} onClick={() => {setShowModal(true)}}>Change Password</Button>
                                <Card.Title style={{textAlign:'center', paddingBottom:10}}>Contact Information</Card.Title>
                            </div>
                            <ListGroup>
                                <KeyValueRow isListItem title={'First Name'} value={user.firstName}/>
                                <KeyValueRow isListItem title={'Middle Name'} value={user.middleName}/>
                                <KeyValueRow isListItem title={'Last Name'} value={user.lastName}/>
                                <KeyValueRow isListItem title={'Email Address'} value={user.email}/>
                                <KeyValueRow isListItem title={'Company Email Address'} value={`${user.companyUserUid}@mail.tacticalcontractor.com`}/>
                                <KeyValueRow isListItem title={'Phone Number'} value={user.phoneNumber ? formatPhoneNumber(user.phoneNumber) : ''}/>
                                <KeyValueRow isListItem title={'Address'} value={addressToString(user.address)}/>
                                <KeyValueRow isListItem title={'Date of Birth'} value={user.dateOfBirth ? moment(user.dateOfBirth).format('MMM D, YYYY') : ''}/>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Change Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                        <CustomControl validator={validators[0]} type="password" title='Old Password' value={oldPassword} onChange={(event) => setOldPassword(event.target.value)}/>
                        <CustomControl validator={validators[1]} type="password" title='New Password' value={newPassword} onChange={(event) => setNewPassword(event.target.value)}/>
                        <CustomControl validator={validators[2]} type="password" title='Confirm New Password' value={confirmPassword} onChange={(event) => setConfirmPassword(event.target.value)}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <CustomButton label='Submit' onClick={handlePasswordChange} isLoading={isChangingPassword}/>
                    </Modal.Footer>
                </Modal>
            </Row>
            <Row style={{paddingBottom: 5}}>
                <Col>
                    <EContactOne user={user} address={eContact1Address}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <EContactTwo user={user} address={eContact2Address}/>
                </Col>
            </Row>
        </Container>
    )
}