/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';


function QuickTimeSelector({selectedTime, setSelectedTime}) {

    const hourBack = () => {
        const hourAsNumber = parseInt(selectedTime.substring(0, 2));
        if (hourAsNumber === 12) {
            setSelectedTime('23' + selectedTime.substring(2));
        } else if (hourAsNumber === 0) {
            setSelectedTime('11' + selectedTime.substring(2));
        } else if (hourAsNumber < 11) {
            setSelectedTime('0' + (hourAsNumber - 1).toString() + selectedTime.substring(2));
        } else {
            setSelectedTime((hourAsNumber - 1).toString() + selectedTime.substring(2));
        }
    }

    const hourForward = () => {
        const hourAsNumber = parseInt(selectedTime.substring(0, 2));
        if (hourAsNumber === 11) {
            setSelectedTime('00' + selectedTime.substring(2));
        } else if (hourAsNumber === 23) {
            setSelectedTime('12' + selectedTime.substring(2));
        } else if (hourAsNumber < 9) {
            setSelectedTime('0' + (hourAsNumber + 1).toString() + selectedTime.substring(2));
        } else {
            setSelectedTime((hourAsNumber + 1).toString() + selectedTime.substring(2));
        }
    }

    const minuteBack = () => {
        const minuteAsNumber = parseInt(selectedTime.substring(3));
        let newTime;
        if (minuteAsNumber === 0 || minuteAsNumber > 45) {
            newTime = '45';
        } else if (minuteAsNumber <= 15) {
            newTime = '00';
        } else if (minuteAsNumber <= 30) {
            newTime = '15';
        } else {
            newTime = '30';
        }
        setSelectedTime(selectedTime.substring(0, 3) + newTime);
    }

    const minuteForward = () => {
        const minuteAsNumber = parseInt(selectedTime.substring(3));
        let newTime;
        if (minuteAsNumber >= 45) {
            newTime = '00';
        } else if (minuteAsNumber >= 30) {
            newTime = '45';
        } else if (minuteAsNumber >= 15) {
            newTime = '30';
        } else {
            newTime = '15';
        }
        setSelectedTime(selectedTime.substring(0, 3) + newTime);
    }

    const toggleAmPm = () => {
        const hourAsNumber = parseInt(selectedTime.substring(0, 2));
        let newNumber;
        if (hourAsNumber > 11) {
            newNumber = hourAsNumber - 12;
        } else {
            newNumber = hourAsNumber + 12;
        }
        const newHour = newNumber < 10 ? '0' + newNumber.toString() : newNumber.toString();
        setSelectedTime(newHour + selectedTime.substring(2));
    }

    const getAmPM = () => {
        const hourAsNumber = parseInt(selectedTime.substring(0, 2));
        return  hourAsNumber > 11 ? 'PM' : 'AM';
    }

    const getHour = () => {
        let hourAsNumber = parseInt(selectedTime.substring(0, 2));
        if (hourAsNumber > 12) {
            hourAsNumber -= 12;
        } else if (hourAsNumber === 0) {
            hourAsNumber = 12;
        }

        return hourAsNumber < 10 ? '0' + hourAsNumber.toString() : hourAsNumber.toString();
    }

    const getMinute = () => {
        return selectedTime.substring(3);
    }

    return (
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', border: 'lightgray solid 1px', borderRadius: 6, padding: 6}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 25}}>
                    <FontAwesomeIcon size='2x' icon={faCaretUp} style={{color: 'var(--bs-primary)', marginBottom: -10}} onClick={hourBack}/>
                    <p className='unselectable-text' style={{margin: 0, fontSize: 20}}>{getHour()}</p>
                    <FontAwesomeIcon size='2x' icon={faCaretDown} style={{color: 'var(--bs-primary)', marginTop: -10}} onClick={hourForward}/>
                </div>
                <p className='unselectable-text' style={{margin: 0}}>:</p>
                <div style={{display: 'flex', flexDirection: 'column', width: 25, alignItems: 'center'}}>
                    <FontAwesomeIcon size='2x' icon={faCaretUp} style={{color: 'var(--bs-primary)', marginBottom: -10}} onClick={minuteBack}/>
                    <p className='unselectable-text' style={{margin: 0, fontSize: 20}}>{getMinute()}</p>
                    <FontAwesomeIcon size='2x' icon={faCaretDown} style={{color: 'var(--bs-primary)', marginTop: -10}} onClick={minuteForward}/>
                </div>
                
                <div style={{display: 'flex', flexDirection: 'column', marginLeft: 4, alignItems: 'center', width: 30}}>
                    <FontAwesomeIcon size='2x' icon={faCaretUp} style={{color: 'var(--bs-primary)', marginBottom: -10}} onClick={toggleAmPm}/>
                    <p className='unselectable-text' style={{margin: 0, fontSize: 20}}>{getAmPM()}</p>
                    <FontAwesomeIcon size='2x' icon={faCaretDown} style={{color: 'var(--bs-primary)', marginTop: -10}} onClick={toggleAmPm}/>
                </div>
            </div>
        </div>
    )
}

export default QuickTimeSelector;