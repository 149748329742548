/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import { Button, InputGroup, ListGroup, Modal, Spinner } from "react-bootstrap";
import CustomButton from "../../components/CustomButton";
import PageSpinner from "../../components/PageSpinner";
import CustomControl from "../../components/CustomControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
import { ApiRequest } from "../../ApiManager.tsx";

export default function MyCompanyRequestOffBlackoutDates({}){
    const [isLoading, setIsLoading] = useState(true);
    const [isCreating, setIsCreating] = useState(false);
    const [blackoutRanges, setBlackoutRanges] = useState([]);
    const [newRangeStartDate, setNewRangeStartDate] = useState('');
    const [newRangeEndDate, setNewRangeEndDate] = useState('');
    const [savingRow, setSavingRow] = useState(undefined);
    const [deletingRow, setDeletingRow] = useState(undefined);

    useEffect(() => {
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('myCompany', 'getCompanyRequestOffBlackoutDates', setIsLoading, (response) => setBlackoutRanges(response.data.sort(sortRanges))).withNoAlertOnSuccess().send();
    }

    function handleCreateRange(){
        const requestToCreate = {
            startDate: newRangeStartDate,
            endDate: newRangeEndDate
        }
        new ApiRequest('myCompany', 'createRequestOffBlackoutDate', setIsCreating, (response) => {
            const newArray = Array.from(blackoutRanges);
            requestToCreate.uid = response.uid;
            newArray.push(requestToCreate);
            newArray.sort(sortRanges);
            setBlackoutRanges(newArray);
            setNewRangeStartDate('');
            setNewRangeEndDate('')
        }).withData({data: requestToCreate}).send();
    }

    function handleDeleteRange(uid){
        setDeletingRow(uid);
        new ApiRequest('myCompany', 'deleteRequestOffBlackoutDate', setIsLoading, () => setBlackoutRanges(blackoutRanges.filter(r => r.uid != uid))).withUid(uid).send();
        setDeletingRow(undefined);
    }

    function handleUpdateRange(range){
        setSavingRow(range.uid);
        new ApiRequest('myCompany', 'updateRequestOffBlackoutDate', setIsLoading, () => {
            const newArray = Array.from(blackoutRanges);
            newArray.find(r => r.uid == range.uid).edited = false;
        }).withData({data: range}).send();
        setSavingRow(undefined);
    }

    function handleSetRange(uid, key, value) {
        const newArray = Array.from(blackoutRanges);
        newArray.find(r => r.uid === uid)[key] = value;
        newArray.find(r => r.uid === uid).edited = true;
        setBlackoutRanges(newArray);
    }

    function sortRanges(a, b) {
        if (a.startDate < b.startDate) {
            return 1;
        } else if (a.startDate > b.startDate) {
            return -1;
        }
        return 0;   
    }

    const blackoutRangeRows = blackoutRanges.map((range) => {
        return (
            <ListGroup.Item key={range.uid} style={{display: 'flex', alignItems: 'center'}}>
                <InputGroup>
                    { moment(range.endDate).isBefore(moment(), 'days') &&
                        <InputGroup.Text style={{color: 'red'}}>Expired</InputGroup.Text>
                    }
                    { range.endDate < range.startDate &&
                        <InputGroup.Text style={{color: 'red'}}>Invalid Range</InputGroup.Text>
                    }
                    <CustomControl title='Start Date' type='date' min={moment().format('YYYY-MM-DD')} disabled={deletingRow == range.uid || savingRow == range.uid} value={range.startDate} onChange={(event) => handleSetRange(range.uid, 'startDate', event.target.value)}/>
                    <CustomControl title='End Date' type='date' min={moment().format('YYYY-MM-DD')} disabled={deletingRow == range.uid || savingRow == range.uid} value={range.endDate} onChange={(event) => handleSetRange(range.uid, 'endDate', event.target.value)}/>
                    { range.edited && range.endDate >= range.startDate &&
                        <CustomButton label='Save Changes' isLoading={savingRow == range.uid} onClick={() => handleUpdateRange(range)}/>
                    }
                </InputGroup>
                { deletingRow == range.uid ?
                    <Spinner size='sm' style={{marginLeft: 8}}/>
                    :
                    <Button style={{color: 'red', backgroundColor: 'transparent', border: 'none', boxShadow: 'none', padding: 0, marginLeft: 8}} onClick={() => handleDeleteRange(range.uid)}>
                        <FontAwesomeIcon icon={faCircleXmark}/>
                    </Button>
                }
            </ListGroup.Item>
        )
    })

    const creationRow = (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div style={{display: 'flex', gap: 12}}>
                <CustomControl title='Start Date' type='date' min={moment().format('YYYY-MM-DD')} value={newRangeStartDate} onChange={(event) => setNewRangeStartDate(event.target.value)}/>
                <CustomControl title='End Date' type='date' min={moment().format('YYYY-MM-DD')} value={newRangeEndDate} onChange={(event) => setNewRangeEndDate(event.target.value)}/>
                <CustomButton onClick={handleCreateRange} isLoading={isCreating} label='Add New Date Range' disabled={!newRangeStartDate || !newRangeEndDate || newRangeStartDate > newRangeEndDate}/>
            </div>
            { newRangeEndDate && newRangeEndDate < newRangeStartDate &&
                <span style={{color: 'red', textAlign: 'center', width: '100%'}}>Invalid Range</span>
            }
        </div>
    )

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Request-Off Blackout Date Ranges</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { isLoading ? <PageSpinner/> :
                    <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                        {creationRow}
                        <p>Employees are prevented from requesting off the following dates. AOs and BCs are still be able to manually override this feature by creating time off in <b>Human Resources - Schedule</b></p>
                        { blackoutRangeRows.length > 0 ?
                            <ListGroup>
                                {blackoutRangeRows}
                            </ListGroup>
                            :
                            <span style={{fontStyle: 'italic', opacity: 0.5, textAlign: 'center'}}>Request-Off blackout date ranges will appear here</span>
                        }
                    </div>
                }
            </Modal.Body>
        </>
    ) 
}