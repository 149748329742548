/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import DropdownControl from "../../components/DropdownControl";
import PageSpinner from "../../components/PageSpinner";
import BudgetAssumptions from "./Models/BudgetAssumptions";
import { Modal } from "react-bootstrap";
import PCAT from "../PCAT/Models/PCAT";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { ApiRequest } from "../../ApiManager.tsx";

export default function BudgetImportPcat({handleSetBudget, hideModal}) {
    const [isLoading, setIsLoading] = useState(true);
    const [isSelecting, setIsSelecting] = useState(false);
    const [pcats, setPcats] = useState([]);
    const [selectedPcat, setSelectedPcat] = useState(undefined);
    const [selectedPcatProposal, setSelectedPcatProposal] = useState(undefined);

    useEffect(() => {
        loadData();
        new ApiRequest()
    }, [])

    function loadData(){
        new ApiRequest('pcat', 'getAll', setIsLoading, (response) => setPcats(response.pcats)).withNoAlertOnSuccess().send();
    }

    function handleSelectPcat(value){
        setSelectedPcat(undefined);
        setSelectedPcatProposal(undefined);
        if(value){
            new ApiRequest('pcat', 'get', setIsSelecting, (response) => setSelectedPcat(PCAT.decode(response.pcat))).withUid(value).withNoAlertOnSuccess().send();
        }
    }

    const handleSubmit = () => {
        if (selectedPcat && selectedPcatProposal !== undefined) {
            handleSetBudget('budgetAssumptions', BudgetAssumptions.initFromPcat(selectedPcat, selectedPcatProposal));
            hideModal();
        }
    }

    const getHeader = (index) => {
        if (selectedPcat.proposals[index].isFedex) {
            if (index > 0) {
                let fedexProposalCount = 0;
                for (let i = 0; i < selectedPcat.proposals.length; i++) {
                    if (i == index) {
                        break;
                    } else if (selectedPcat.proposals[i].isFedex) {
                        fedexProposalCount++;
                    }
                }
                return `FedEx Counter Proposal #${fedexProposalCount}`;
            } else {
                return "Initial FedEx Proposal";
            }
        } else {
            let icProposalCount = 1;
            for (let i = 0; i < selectedPcat.proposals.length; i++) {
                if (i == index) {
                    break;
                } else if (!selectedPcat.proposals[i].isFedex) {
                    icProposalCount++;
                }
            }
            return `IC Counter Proposal #${icProposalCount}`;
        }
    }

    return (
        <>
            <Modal.Header>
                <Modal.Title>Import PCAT</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { isLoading ? <PageSpinner/> :
                    <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                        <DropdownControl disabled={isSelecting} title={'Select PCAT'} selection={selectedPcat?.uid} setSelection={handleSelectPcat} itemNames={pcats.map(p => p.title)} itemValues={pcats.map(p => p.uid)}/>
                        { selectedPcat && 
                            <DropdownControl disabled={isSelecting} title={'Select Proposal'} selection={selectedPcatProposal} setSelection={setSelectedPcatProposal} itemNames={selectedPcat.proposals.map((_, index) => {return getHeader(index)})} itemValues={selectedPcat.proposals.map((_, index) => {return index})}/>
                        }
                        <div style={{display: 'flex', gap: 8, alignItems: 'center'}}>
                            <FontAwesomeIcon icon={faTriangleExclamation} style={{color: 'gold'}}/>
                            <span>Importing a PCAT will override all of the values listed in the Assumptions table</span>
                        </div>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <CustomButton label='Import PCAT' disabled={!selectedPcat || selectedPcatProposal === undefined} onClick={handleSubmit}/>
            </Modal.Footer>
        </>
    )
}