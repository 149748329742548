/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../decoding";
import { validateDecimal, validateUsd } from "../payrollTools";
import NDBonus from "./NDBonus";
import { getFakeUID } from "../../../tools";

export default class DBonus {

    id;
    type;
    label;
    amount;

    constructor(type, label, amount = 0) {
        this.id = getFakeUID();
        this.type = type;
        this.label = label;
        this.amount = amount;
    }

    static initDefault() {
        return new DBonus('Bonus', 'ERR', 0);
    }

    static decodeFromEntry(json) {
        const decoder = new Decoder(json);
        const array = decoder.decode('dBonuses', Decoder.Array, {defaultValue: undefined, warn: false});
        if (array) {
            return array.map(json => DBonus.decode(json));
        } else {
            const additionalPay = decoder.decode('additionalPay', Decoder.Array);
            if (decoder.checkForErrors()) {
                return additionalPay.filter(ap => DBonus.dBonusTypes.includes(ap.type)).map(ap => NDBonus.decode(ap));
            } else {
                return [];
            }
        }
    }

    static decode(json) {
        const decoder = new Decoder(json);
        const type = decoder.decode('type', Decoder.StringStrict);
        const label = decoder.decode('label', Decoder.StringStrict, {defaultValue: undefined, warn: false});
        const amount = decoder.decode('amount', Decoder.Decimal);

        if (decoder.checkForErrors()) {
            return new DBonus(type, label, amount);
        } else {
            return DBonus.initDefault();
        }
    }

    encode() {
        return {
            type: this.type,
            label: this.label,
            amount: validateDecimal(this.amount)
        }
    }
    
    duplicate() {
        return new NDBonus(this.type, this.label, this.amount);
    }

    getAmount() {
        return validateUsd(this.amount);
    }
    
    static dBonusTypes = [
        'Holiday Bonus',
        'Other Discretionary Bonus'
    ];
}