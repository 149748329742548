/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import { Button, Form, InputGroup, ListGroup, Modal } from "react-bootstrap";
import OfferLetterEditor from "../../components/OfferLetterEditor";
import CustomButton from "../../components/CustomButton";
import PageSpinner from "../../components/PageSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import AlertModal from "../../components/AlertModals/AlertModal";
import { ApiRequest } from "../../ApiManager.tsx";
import { defaultOfferLetter } from "../../tools.js";


export default function MyCompanyOfferLetters({offerLetters, setOfferLetters}) {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOfferLetter, setSelectedOfferLetter] = useState(undefined);
    const [showEditor, setShowEditor] = useState(false);

    function updateOfferLetterState(offerLetter){
        setOfferLetters([...offerLetters.filter(o => o.uid != offerLetter.uid), offerLetter]);
        if (selectedOfferLetter) {
            setSelectedOfferLetter(offerLetter);
        } else {
            setShowEditor(false);
        }
    }

    function handleRemoveOfferLetter(uid){
        setOfferLetters(offerLetters.filter(o => o.uid != uid));
        setShowEditor(false);
    }

    const offerLetterItems = offerLetters.map((ol) => {
        return (
            <ListGroup.Item style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }} action key={ol.uid} onClick={() => {setSelectedOfferLetter(ol); setShowEditor(true)}}>
                <p style={{margin: 0}}>{ol.name}</p>
                {ol.defaultForTitle && <p style={{margin: 0}}><b>Default: </b>{ol.defaultForTitle}</p>}
            </ListGroup.Item>
        )

    })

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Company Offer Letter Templates</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { isLoading ? <PageSpinner/> :
                    <ListGroup>
                        {offerLetterItems}
                        <ListGroup.Item action onClick={() => {setSelectedOfferLetter(undefined); setShowEditor(true)}} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'var(--bs-primary)'}}>
                            <span>Create Offer Letter Template</span>
                            <FontAwesomeIcon icon={faChevronRight}/>
                        </ListGroup.Item>
                    </ListGroup>
                }
            </Modal.Body>
            <Modal show={showEditor} onHide={() => {setShowEditor(false)}} fullscreen>
                <MyCompanyOfferLetterEditor selectedOfferLetter={selectedOfferLetter} updateOfferLetterState={updateOfferLetterState} handleRemoveOfferLetter={handleRemoveOfferLetter}/>
            </Modal>
        </>
    )
}

function MyCompanyOfferLetterEditor({selectedOfferLetter, updateOfferLetterState, handleRemoveOfferLetter}) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false)
    const [content, setContent] = useState(selectedOfferLetter?.content ?? defaultOfferLetter);
    const [name, setName] = useState(selectedOfferLetter?.name ?? '');
    const [showDelete, setShowDelete] = useState(false);

    function handleSubmit(){
        const newOfferLetter = {
            uid: selectedOfferLetter?.uid,
            name: name,
            content: content
        }
        if(selectedOfferLetter){
            new ApiRequest('myCompany', 'updateOfferLetter', setIsSubmitting, () => updateOfferLetterState(newOfferLetter)).withData({offerLetter: newOfferLetter}).send();
        } else{
            new ApiRequest('myCompany', 'createOfferLetter', setIsSubmitting, (response) => {newOfferLetter.uid = response.uid; updateOfferLetterState(newOfferLetter)}).withData({offerLetter: newOfferLetter}).send();
        }
    }

    function handleDelete(){
        new ApiRequest('myCompany', 'deleteOfferLetter', setIsDeleting, () => {handleRemoveOfferLetter(selectedOfferLetter.uid); setShowDelete(false)}).withUid(selectedOfferLetter.uid).send();
    }

    const isSaved = content === selectedOfferLetter?.content  && name === selectedOfferLetter?.name;

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Editing Offer Letter Template</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <OfferLetterEditor value={content} setValue={setContent}/>
            </Modal.Body>
            <Modal.Footer>
                { selectedOfferLetter && 
                    <Button style={{border: 'none', background: 'none', color: 'red', boxShadow: 'none'}} onClick={() => {setShowDelete(true)}}>Delete Template</Button>
                }
                <InputGroup style={{maxWidth: 600, flexWrap: 'nowrap'}}>
                    <InputGroup.Text>Template Name</InputGroup.Text>
                    <Form.Control maxLength={30} value={name} isInvalid={!name} onChange={(event) => {setName(event.target.value)}}/>
                </InputGroup>
                <CustomButton label={selectedOfferLetter ? isSaved ? 'Saved' : 'Save Changes' : 'Submit'} disabled={isSaved || !name} isLoading={isSubmitting} onClick={handleSubmit}/>
            </Modal.Footer>
            <Modal show={showDelete} onHide={() => {setShowDelete(false)}}>
                <AlertModal isLoading={isDeleting} variant='outline-danger' title='Delete Offer Letter Template?' message='This cannot be undone.' buttonLabel='Delete Template' callBack={handleDelete}/>
            </Modal>
        </>
    )
}