/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState, useEffect } from 'react';
import { Card, Container, Modal, Row } from 'react-bootstrap';
import moment from 'moment';
import CustomButton from '../../../components/CustomButton.js';
import PageSpinner from '../../../components/PageSpinner.js';
import { ApiRequest } from '../../../ApiManager.tsx';

export default function HRFedexNameMatch({hideModal}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [users, setUsers] = useState([]);
    const [fedexNames, setFedexNames] = useState([]);
    const [matchedNames, setMatchedNames] = useState({});
    const [selectedUser, setSelectedUser] = useState(undefined);
    const [selectedFedexName, setSelectedFedexName] = useState(undefined);

    useEffect(()=>{
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('reports', 'productionReport', setIsLoading, (response) => {
            const fedexNameArray = []
            response.dsw.forEach((dsw) => {
                let dswRows = dsw.data.split(/\r?\n/);
                dswRows = dswRows.slice(4, dswRows.length - 5);
                dswRows = dswRows.map(row => row.split('*'));
                dswRows.forEach((row) => {
                    if (row[3] && !fedexNameArray.includes(row[3])) {
                        fedexNameArray.push(row[3]);
                    }
                })
                
            })
            const fedexNamesExcludingUsed = fedexNameArray.filter((fName) => {
                return !response.users.map(r => r.fedexName).includes(fName);
            })
            
            setUsers(response.users.filter(r => !r.fedexName));
            setFedexNames(fedexNamesExcludingUsed);
        }).withData({startDate: moment().subtract(14, 'days').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD')}).withNoAlertOnSuccess().send()
    }

    function handleSubmit(){
        new ApiRequest('hr', 'setFedexNames', setIsSubmitting, () => hideModal()).withData({data: matchedNames}).send();
    }

    const handleSetMatchedNames = (key, value) => {
        const newObj = structuredClone(matchedNames);
        newObj[key] = value;
        setMatchedNames(newObj);
    }

    const handleRemoveMatchedName = (key) => {
        const newObj = structuredClone(matchedNames);
        delete newObj[key];
        setMatchedNames(newObj);
    }

    const handleSetSelectedUser = (value) => {
        if (selectedFedexName) {
            handleSetMatchedNames(value, selectedFedexName);
            setSelectedFedexName(undefined);
        } else {
            setSelectedUser(value);
        }
    }

    const handleSetSelectedFedexName = (value) => {
        if (selectedUser) {
            handleSetMatchedNames(selectedUser, value);
            setSelectedUser(undefined);
        } else {
            setSelectedFedexName(value);
        }
    }

    const unusedUsers = users.filter(r => !Object.keys(matchedNames).includes(r.companyUserUid));
    const userRows = unusedUsers.map((user) => {
        const isSelected = selectedUser === user.companyUserUid;
        return (
            <p key={user.companyUserUid} className='cursor-pointer' onClick={() => handleSetSelectedUser(user.companyUserUid)} style={{borderRadius: 6, padding: '4px 8px 4px 8px', marginBottom: 4, border: '1px solid lightgray', backgroundColor: isSelected ? 'yellow' : 'white'}}>
                {user.firstName + ' ' + user.middleName + ' ' + user.lastName}
            </p>
        )
    })

    const unusedfedexNames = fedexNames.filter(r => !Object.values(matchedNames).includes(r));
    const fedexNameRows = unusedfedexNames.map((fedexName) => {
        const isSelected = selectedFedexName === fedexName;
        return (
            <p key={fedexName} className='cursor-pointer' onClick={() => handleSetSelectedFedexName(fedexName)} style={{borderRadius: 6, padding: '4px 8px 4px 8px', marginBottom: 4, border: '1px solid lightgray', backgroundColor: isSelected ? 'yellow' : 'white'}}>
                {fedexName}
            </p>
        )
    })

    const completedRows = Object.keys(matchedNames).map((key) => {
        const user = users.find(r => r.companyUserUid === key);

        return (
            <div key={key} className='cursor-pointer' onClick={() => handleRemoveMatchedName(key)} style={{display: 'flex', justifyContent: 'space-between', borderRadius: 6, padding: '4px 8px 4px 8px', marginBottom: 4, border: '1px solid lightgray'}}>
                <span>{user.firstName + ' ' + user.middleName + ' ' + user.lastName}</span>
                <span>{matchedNames[key]}</span>
            </div>
            
        )
    })

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>MyGroundBiz Employee Name Match</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { isLoading ? <PageSpinner/> : 
                    <>
                        <p>The following employees do not have MyGroundBiz names in our system. These names must be set in order to collect crucial performance data from FedEx on your employees. Please match the employees in the left-hand column to the fedex names from MyGroundBiz on the right-hand column.</p>
                        <div style={{display: 'flex', gap: 12, marginBottom: 12}}>
                            <Card style={{flex: 1}}>
                                <Card.Body>
                                    <Card.Title>Employees Without MyGroundBiz Names</Card.Title>
                                    {userRows}
                                </Card.Body>
                            </Card>
                            <Card style={{flex: 1}}>
                                <Card.Body>
                                    <Card.Title>Unused MyGroundBiz Names</Card.Title>
                                    {fedexNameRows}
                                </Card.Body>
                            </Card>
                        </div>
                        <Card>
                            <Card.Body>
                                <Card.Title>Matched Names (Click to Remove)</Card.Title>
                                {completedRows}
                            </Card.Body>
                        </Card>    
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <CustomButton isLoading={isSubmitting} label='Submit' disabled={Object.keys(matchedNames).length === 0} onClick={handleSubmit}/>
            </Modal.Footer>
        </>
    )
}