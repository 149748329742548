/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import CustomButton from '../../../components/CustomButton'
import Modal from 'react-bootstrap/Modal';
import Cookies from 'universal-cookie';
import CustomControl from '../../../components/CustomControl';
import { ApiRequest } from '../../../ApiManager.tsx';

export default function HRNotesEditor({onHide, note, handleCrud, selectedEmployee}) {
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState(note ? note.title : '');
    const [body, setBody] = useState(note ? note.body : '');

    function handleSave(){
        const newNote = {
            uid: note ? note.uid : undefined,
            submissionDate: note ? note.submissionDate : undefined,
            author: new Cookies().get('userData').companyUserUid,
            companyUserIdentifier: selectedEmployee.companyUserUid,
            title: title,
            body: body,
        }

        new ApiRequest('hr', 'createEmployeeNote', setIsLoading, (response) => {
            newNote.uid = response.uid;
            handleCrud('create', newNote);
            onHide();
        }).withData({data: newNote}).send();
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{note ? 'Employee Note' : 'Creating Employee Note'}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <CustomControl disabled={isLoading || note} title='Title' value={title} onChange={(event) => {setTitle(event.target.value)}} maxLength={20}/>
                <Form.Group>
                    <Form.Text>Notes</Form.Text>
                    <Form.Control disabled={isLoading || note} as='textarea' rows={12} value={body} onChange={(event) => {setBody(event.target.value)}}/>
                </Form.Group>
            </Modal.Body>
            { !note &&
                <Modal.Footer>
                    <CustomButton label='Submit' isLoading={isLoading} onClick={handleSave}/>
                </Modal.Footer>
            }
        </>
    )
}