/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import RichText from "../components/RichText/RichText";
import { CloseButton, Button } from "react-bootstrap";
import RichTextEditor from "../components/RichText/RichTextEditor";
import { ApiRequest } from "../ApiManager.tsx";


export default function NotificationModal({notifications, hideNotifications}){
    const [isLoading, setIsLoading] = useState(false);

    function handleNotificationHide(){
        let newArr = [];
        notifications.forEach((n)=>{
            newArr.push(n.uid);
        });

        new ApiRequest('notifications', 'acknowledgeNotifications', setIsLoading, () => hideNotifications()).withData({uids: newArr}).withNoAlertOnSuccess().send();
    }

    const notificationItems = notifications.map((n)=>{
        const editorState = RichText.decode(n.richText);
        return (
            <React.Fragment key={n.uid}>
                <div style={{ background: 'whitesmoke', padding: 6, borderRadius: 6 }}>
                    <RichTextEditor readOnly editorState={editorState}/>
                </div>
                {notifications.length > 1 && <hr/>}
            </React.Fragment>
        )
    })
    
    return (
        <>
            <Modal.Header>
                 <CloseButton onClick={()=>handleNotificationHide()}/>
            </Modal.Header> 
            <Modal.Body>
                {notificationItems}
            </Modal.Body> 
        </>
    )
}
