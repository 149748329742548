/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Modal, OverlayTrigger, Popover } from "react-bootstrap";

export default function PayrollEmployeeTicketsTooltip() {
    const [show, setShow] = useState(false);

    return (
        <>
            <FontAwesomeIcon className="cursor-pointer" onClick={() => {setShow(true)}} style={{color: 'var(--bs-primary)'}} icon={faQuestionCircle}/>
            <Modal show={show} onHide={() => {setShow(false)}} centered size="lg">
                <Modal.Header>
                    <Modal.Title>Fine Ticket Damage</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Based on the data in our system, fine ticket damage is generated for each employee. Each FTD contains the following:</p>
                    <ul>
                        <li><b>Installment Amount</b>: This defines how much money an employee is required to pay each payroll period to pay back his or her debt. This number will <b>not</b> be deducted from the employee's gross</li>
                        <li><b>Include In Payroll?</b>: This button can be used to manually exclude a loan/ticket from this payroll period.</li>
                        <li><b>Amount to Pay</b>: For Fine Ticket Damage which has "<i>Deduct From Safety Bonuses</i>" enabled, the installment will only be deducted from the employee's <i>Safety Bonuses</i>, otherwise, the installment will NOT be paid and will not show up outside the editor.</li>
                    </ul>
                    <p>An employee's fine ticket damage can be edited at any time by clicking the <b>View/Edit Loans & FTD</b> button. Any changes will immediately be reflected in payroll.</p>
                </Modal.Body>
            </Modal>
        </>
    )
}