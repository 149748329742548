/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import SearchBar from "../../components/SearchBar";
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import AlertModal from "../../components/AlertModals/AlertModal";
import CompanyFileUploadControl from "../../components/CompanyFileUploadControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFileIcon } from "../../tools";
import FileDownloadButton from "../../components/FileDownloadButton";
import MyCompanyDocumentEditor from "./MyCompanyDocumentEditor";
import moment from "moment";
import DocumentPreview from "../../components/DocumentPreview";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { ApiRequest } from "../../ApiManager.tsx";
import LoadingWrapper from "../../components/LoadingWrapper.js";

export default function MyCompanyDocuments({}){
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [filter, setFilter] = useState('');
    const [selectedDoc, setSelectedDoc] = useState(undefined);
    const [modalSwitch, setModalSwitch] = useState('');
    const [showAcknowledgements, setShowAcknowledgements] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [selectedDocumentAcknowledgements, setSelectedDocumentAcknowledgements] = useState([]);
    const [numberOfUsers, setNumberOfUsers] = useState(0);

    useEffect(()=>{
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('myCompany', 'getAllCompanyDocuments', setIsLoading, (response) => {
            setDocuments(response.data.sort((a, b) => b.uid - a.uid));
            setNumberOfUsers(response.numberOfUsers);
        }).withNoAlertOnSuccess().send()
    }

    function handleGetCompanyAcknowledgements(docUid){
        new ApiRequest('documentStorage', 'getCompanyAcknowledgements', setIsLoading, (response) => {
            setSelectedDocumentAcknowledgements(response.data.sort((a, b) => {
                return `${a.user.lastName}${a.user.firstName}${a.user.middleName}`.localeCompare(`${b.user.lastName}${b.user.firstName}${b.user.middleName}`);
            }))
            setShowAcknowledgements(true);
        }).withUid(docUid).withNoAlertOnSuccess().send();
    }

    function handleDeleteDocument(){
        new ApiRequest('myCompany', 'deleteCompanyDocument', setIsDeleting, () => {
            const newArray = Array.from(documents).filter(r => r.uid != selectedDoc.uid);
            newArray.sort((a, b) => a.documentName.localeCompare(b.documentName));
            setDocuments(newArray);
            setSelectedDoc(undefined);
            setModalSwitch('');
        }).withUid(selectedDoc.uid).send()
    }

    function handleUploadDocument(docObj) {
        const newArray = Array.from(documents);
        newArray.push(docObj);
        setDocuments(newArray);
        setModalSwitch('')
    }

    function handleEditDocument(document) {
        const newArray = Array.from(documents.filter(r => r.uid !== document.uid));
        newArray.push(document);
        newArray.sort((a, b)=>a.documentName.localeCompare(b.documentName));
        setDocuments(newArray);
        setModalSwitch('')
    }

    function handlePreviewButtonClick(doc){
        setSelectedDoc(doc);
        setShowPreview(true);
    }

    const filteredDocuments = documents.filter((doc) => {
        return doc.documentName.toLowerCase().includes(filter.toLowerCase());
    });

    const documentsElements = filteredDocuments.map((doc) => {
        return (
            <Card key={doc.uid} style={{display: 'flex', flexDirection: 'row', flex: 1}}>
                <div style={{display: 'flex', height: '100%', padding: 18, gap: 12, borderTopLeftRadius: 6, borderBottomLeftRadius: 6, backgroundColor: 'var(--bs-primary)', alignItems: 'center', flexShrink: 0}}>
                    <FontAwesomeIcon icon={getFileIcon(doc.documentType)} style={{color: 'white'}}/>
                </div>
                <div style={{borderTopRightRadius: 6, borderBottomRightRadius: 6, border: '2px solid var(--bs-primary)', padding: '0px 8px 0px 8px', borderLeft: 'none', flex: 1, alignItems: 'center', justifyContent: 'space-between', display: 'flex'}}>
                    <span style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '20%'}}>{doc.documentName}</span>
                
                    <div style={{display: 'flex', gap: 8, alignItems: 'center'}}>
                        { (doc.isRequired == '1' || doc.acknowledgements > 0) && 
                            <Button variant={'outline-primary'} style={{flexShrink: 0}} onClick={() => handleGetCompanyAcknowledgements(doc.uid)}>{'Acknowledgements (' + doc.acknowledgements + '/' + numberOfUsers +')'}</Button>
                        }
                        <Button variant={'outline-primary'} style={{flexShrink: 0}} onClick={() => {setSelectedDoc(doc); setModalSwitch('edit')}}>Edit</Button>
                        {(doc.documentType === 'jpeg' || doc.documentType === 'png' || doc.documentType === 'jpg') && <Button variant={'outline-primary'} onClick={() => handlePreviewButtonClick(doc)}><FontAwesomeIcon size='xl' icon={faMagnifyingGlass}/></Button>}
                        {doc.documentType === 'pdf' && <Button  variant={'outline-primary'} onClick={() => handlePreviewButtonClick(doc)}><FontAwesomeIcon size='xl' icon={faMagnifyingGlass}/></Button>}
                        <FileDownloadButton data={{uid: doc.uid}} doc={doc} type='myCompany' target='downloadCompanyDocument'/>
                        <Button variant={'outline-primary'} style={{flexShrink: 0}} onClick={() => {setSelectedDoc(doc); setModalSwitch('delete')}}>Delete</Button>                            
                    </div>
                </div>
            </Card>
        )
    });

    const content = (
        <div style={{height: '100%'}}>
            <div style={{display: 'flex', flex: 1, flexDirection: 'row', gap: 8, alignItems: 'center'}}>
                <SearchBar label='Search for a document by title' value={filter} setValue={(value) => setFilter(value)}/>
                <Button variant={'outline-primary'} style={{flexShrink: 0}} onClick={() => setModalSwitch('upload')}>Upload Document</Button>
            </div>
            <div style={{display: 'flex', flex: 1, flexDirection: 'column', gap: 12, marginTop: 12}}>
                {documentsElements}
            </div>
        </div>
    )

    const acknowledgements = selectedDocumentAcknowledgements.map((doc, index)=>{
        return (
            <tr key={index}>
                <td>{doc.user.lastName + ', ' + doc.user.firstName + ' ' + doc.user.middleName}</td>
                <td style={{paddingLeft:40}}>{moment(doc.dateApproved).format('MMM D, YYYY [at] h:mm A')}</td>
            </tr>
        )
    });

    return (
        <>
        <LoadingWrapper isLoading={isLoading}>
            {content}
        </LoadingWrapper>
            <Modal show={modalSwitch === 'upload'} onHide={() => setModalSwitch('')} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CompanyFileUploadControl handleUploadDocument={handleUploadDocument}/>
                </Modal.Body>
            </Modal>
            <Modal show={modalSwitch === 'edit'} onHide={() => setModalSwitch('')}>
                <MyCompanyDocumentEditor document={selectedDoc} handleEditDocument={handleEditDocument}/>
            </Modal>
            <Modal centered show={modalSwitch === 'delete'} onHide={() => {setSelectedDoc(undefined); setModalSwitch('')}} >
                <AlertModal 
                    isLoading={isDeleting}
                    variant='outline-danger'
                    title='Deletion Confirmation' 
                    message='Permanently delete document?' 
                    buttonLabel='Delete' 
                    callBack={handleDeleteDocument}
                />
            </Modal>
            <Modal show={showAcknowledgements} onHide={()=> setShowAcknowledgements(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Acknowledged Users</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table>
                        {acknowledgements.length>0 &&
                        <thead>
                            <tr>
                                <th style={{paddingLeft:20, textDecoration:'underline'}}>Employee</th>
                                <th style={{paddingLeft:40, textDecoration:'underline'}}>Date Acknowledged</th>
                            </tr>
                        </thead>
                        }
                        <tbody>
                        {acknowledgements}
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>
            <Modal show={showPreview} onHide={() => setShowPreview(false)} size='xl'>
                <DocumentPreview document={selectedDoc} company={true}/>
            </Modal>
        </>
    )
}