/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './Profile.css';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Cookies from 'universal-cookie';
import PersonalInfo from './PersonalInfo';
import PositionInfo from './PositionInfo';
import { ButtonGroup } from 'react-bootstrap';
import NotificationSettingsInfo from './NotificationSettingsInfo';
import LoadingWrapper from '../../components/LoadingWrapper';
import { ApiRequest } from '../../ApiManager.tsx';

export default function Profile({}){
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('Personal Details');
    const [user, setUser] = useState(undefined);
    const [pay, setPay] = useState(undefined);
    const [eContact1Address, setEcontact1Address] = useState('');
    const [eContact2Address, setEcontact2Address] = useState('');
    const title = new Cookies().get('userData').title;

    useEffect(() => {
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('getProfileInformation', 'getInfo', setIsLoading, (response) => {
            response.userData.address = response.userAddress;
            setUser(response.userData);
            setPay(response.payData);
            setEcontact1Address(response.eContact1Address);
            setEcontact2Address(response.eContact2Address);
        }).withNoAlertOnSuccess().send()
    }

    const tabs = !user ? {} : {
        'Personal Details': <PersonalInfo user={user} eContact1Address={eContact1Address} eContact2Address={eContact2Address}/>,
        'Position Details': <PositionInfo pay={pay} user={user} />,
    };

    if (user && (title == 'BC' || title == 'AO' || title == 'Admin')) {
        tabs['Notification Settings'] = <NotificationSettingsInfo title={title} />;
    }

    const tabButtons = Object.keys(tabs).map((key) => {
        return (
            <Button
                key={key}
                style={activeTab === key ? { color: 'white' } : { color: 'var(--bs-primary)', backgroundColor: 'white' }}
                onClick={() => setActiveTab(key)}
            >
                {key}
            </Button>
        )
    });
    
    return  (
        <div className="page-wrapper" style={{ padding: 12, flexDirection: 'column' }}>
            <LoadingWrapper isLoading={isLoading}>
                <ButtonGroup>
                    {tabButtons}
                </ButtonGroup>
                <div style={{ height: '100%', margin: '12px 0px 0px 12px', overflowY: 'scroll' }}>
                    <div style={{ height: '100%', marginRight: 12 }}>
                        {tabs[activeTab]}
                    </div>
                </div>
            </LoadingWrapper>
        </div>
    );
}