/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import CustomButton from '../../../components/CustomButton';
import { ApiRequest } from '../../../ApiManager.tsx';

function HRUserScheduleAddNoShow({handleAddItem, companyUserIdentifier}) {
    const [scheduleType, setScheduleType] = useState('callOff');
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [notes, setNotes] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    function handleCreateNoShow(){
        const newItem = {
            companyUserIdentifier: companyUserIdentifier,
            scheduleType: scheduleType, 
            date: date, 
            notes: notes, 
        }
        new ApiRequest('hr', 'reportCallOff', setIsLoading, (response) => {
            newItem.uid = response.uid;
            handleAddItem(newItem)
        }).withData({data: newItem}).send()
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Reporting Call Off / No-Show</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{display: 'flex', flexDirection: 'column', gap: 12, }}>
                    <Form.Group>
                        <Form.Label>Date</Form.Label>
                        <Form.Control type='date' disabled={isLoading} value={date} onChange={(event) => {setDate(event.target.value ? event.target.value : moment().format('YYYY-MM-DD'))}}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Notes</Form.Label>
                        <Form.Control as='textarea' max={65535} disabled={isLoading} rows={6} value={notes} onChange={(event) => {setNotes(event.target.value)}}/>
                    </Form.Group>
                    <Form.Check style={{alignSelf: 'center'}} disabled={isLoading} type='switch' label='Did employee notify manager?' checked={scheduleType === 'callOff'} onChange={() => setScheduleType(scheduleType === 'callOff' ? 'noShow' : 'callOff')}/>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton label='Submit' isLoading={isLoading} onClick={handleCreateNoShow}/>
            </Modal.Footer>    
        </>
    )
}

export default HRUserScheduleAddNoShow;