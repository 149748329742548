/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../decoding";
import { validateDecimal, validateUsd } from "../payrollTools";
import { getFakeUID } from "../../../tools";

export default class NDBonus {

    id;
    type;
    label;
    amount;
    isReoccuring;
    canBeReoccuring;

    constructor(type, label, amount = 0, isReoccuring, canBeReoccuring = true) {
        this.id = getFakeUID();
        this.type = type;
        this.label = label;
        this.amount = amount;
        this.isReoccuring = isReoccuring;
        this.canBeReoccuring = canBeReoccuring;
    }

    static initDefault() {
        return new NDBonus('Bonus', 'ERR', 0, false, false);
    }

    static decodeFromEntry(json) {
        const decoder = new Decoder(json);
        const array = decoder.decode('additionalPay', Decoder.Array);
        if (decoder.checkForErrors()) {
            return array.filter(json => NDBonus.ndBonusTypes.includes(json.type)).map(json => NDBonus.decode(json));
        } else {
            return [];
        }
    }

    static decode(json) {
        const decoder = new Decoder(json);
        const type = decoder.decode('type', Decoder.StringStrict);
        const label = decoder.decode('label', Decoder.StringStrict, {defaultValue: undefined, warn: false});
        const amount = decoder.decode('amount', Decoder.Decimal);
        const isReoccuring = decoder.decode('isReoccuring', Decoder.Boolean, {defaultValue: false, warn: true});
        const canBeReoccuring = decoder.decode('canBeReoccuring', Decoder.Boolean, {defaultValue: true, warn: true});

        if (decoder.checkForErrors()) {
            return new NDBonus(type, label, amount, isReoccuring, canBeReoccuring);
        } else {
            return NDBonus.initDefault();
        }
    }

    encode() {
        return {
            type: this.type,
            label: this.label,
            amount: validateDecimal(this.amount),
            isReoccuring: this.isReoccuring,
            canBeReoccuring: this.canBeReoccuring,
        }
    }
    
    duplicate() {
        return new NDBonus(this.type, this.label, this.amount, this.isReoccuring, this.canBeReoccuring)
    }

    getAmount() {
        return validateUsd(this.amount);
    }

    // getProjectedValue(entry) {
    //     if (this.type === 'Safety Bonus') {
    //         let amountToDeduct = entry.getEnabledTickets().filter(t => t.deductFromSafetyBonuses).reduce((prev, curr) => {
    //             return prev + curr.getAmount();
    //         }, 0);
    //         const bonuses = entry.getAllNdBonusesOfType('Safety Bonus');

    //         let index = 0;
    //         while (index < bonuses.length && amountToDeduct > 0) {
    //             if (bonuses[index].id === this.id) {
    //                 return Math.max(0, this.getAmount() - amountToDeduct);
    //             } else {
    //                 amountToDeduct -= Math.min(amountToDeduct, bonuses[index].getAmount());
    //                 index++;
    //             }
    //         }
    //         return this.getAmount();
    //     } else {
    //         return this.getAmount();
    //     }
    // }
    
    static ndBonusTypes = [
        'Bonus',
        'Safety Bonus',
        'Service Bonus',
        'Travel Wage',
        'Over-Threshold Stop Wage',
        'Small Truck Variance'
    ]
}