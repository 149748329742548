/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './ScheduleMatch.css';
import React, {useState} from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation, faCheck, faInfoCircle, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

export default function ScheduleMatchRoute({users, vehicles, route, setSelectedRoute, day, setSelectedDay, workArea, setSelectedWorkArea, setShowModal, routeCardFilter}) {

    function handleButtonPress(){
        setSelectedRoute(route);
        setSelectedDay(moment(day));
        setSelectedWorkArea(workArea);
        setShowModal(true);
    }

    const information = [];

    if (routeCardFilter[0]) {
        const driver = users.find(u => u.companyUserUid == route.companyUserIdentifier);
        if(driver){
            const driverName = driver.firstName + ' '+ (driver.showMiddleName ? driver.middleName.substring(0, 1) + '. ' : '')+ driver.lastName;
            information.push(driverName);
        }else{
            information.push('Open Shift')
        }
    }

    if (route.secondaryDriver && parseInt(route.secondaryDriver) > 0 && routeCardFilter[1]) {
        const secondaryDriver = users.find(u => u.companyUserUid == route.secondaryDriver);
        information.push(secondaryDriver.firstName + ' ' + (secondaryDriver.showMiddleName ? secondaryDriver.middleName.substring(0, 1) + '. ' : '') + secondaryDriver.lastName + ' (' + route.secondaryDriverType + ')');
    }

    if (routeCardFilter[2]) {
        information.push(moment(route.date + ' ' + route.timeScheduled).format('h:mm A'));
    }

    if (routeCardFilter[3]) {
        if(route.companyUserIdentifier){
            information.push(`Vehicle: ${vehicles.find(v => v.uid == route.vehicleIdentifier)?.vehicleName ? vehicles.find(v => v.uid == route.vehicleIdentifier)?.vehicleName : 'None'}`);
        }
    }

    const informationElements = information.map((info, index) => {
        return (
            <p key={index} style={{margin: 0}}>{info}</p>
        )
    });

    const conflictSeverity = route.conflicts?.reduce((accumulator, currentValue) => Math.max(accumulator, currentValue.severity), 0);
    const icon = (conflictSeverity === 0 || !route.companyUserIdentifier) ? faCheck : faTriangleExclamation;
    const conflictListItems = route.conflicts?.map((element, index) => {
        return (
            <div key={index} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8}}>
                <FontAwesomeIcon icon={faTriangleExclamation} style={{color: element.severity === 3 ? 'red' : element.severity === 2 ? 'orange' : 'gold'}}/>
                <p style={{margin: 0,}}>{element.message}</p>
            </div>
        )
    });

    const noConflictElement = (
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8}}>
                <FontAwesomeIcon icon={faCheck} style={{color: 'lightgreen'}}/>
                <p style={{margin: 0}}>No conflicts! Scheduling conflicts will appear here.</p>
        </div>
    )

    const popover = (
        <Popover style={{position:'fixed'}}>
            <Popover.Header></Popover.Header>
            <Popover.Body>
                {conflictListItems?.length > 0 ? conflictListItems : noConflictElement}
            </Popover.Body>
        </Popover>
    )

    const extraPopoverInfo = (
        <Popover style={{position:'fixed'}}>
            <Popover.Header></Popover.Header>
            <Popover.Body>
                {route.driverGenerated == 1 && <>This route was generated by a driver on clock-in.</>}
                {users.find(u => u.companyUserUid == route.companyUserIdentifier)?.companyUserIsEnabled == 0 && moment(day).isBefore(moment(), 'day') && <>This driver has been terminated.</>}
            </Popover.Body>
        </Popover>
    )

    return (
        <button className='schedule-match-route-button' style={{border: route.secondaryDriver ? 'purple 2px solid' : 'var(--bs-primary) 2px solid'}} type='button' text='' onClick={() => {handleButtonPress()}}>
            {informationElements}
            <OverlayTrigger placement='top' overlay={popover}>
                <FontAwesomeIcon icon={icon} style={{color: (conflictSeverity === 0 || !route.companyUserIdentifier )? 'lightgreen' : conflictSeverity === 3 ? 'red' : conflictSeverity === 2 ? 'orange' : 'gold', position: 'absolute', top: 4, right: 4}}/>
            </OverlayTrigger>
            { (route.driverGenerated == 1 || (users.find(u => u.companyUserUid == route.companyUserIdentifier)?.companyUserIsEnabled == 0 && moment(day).isBefore(moment(), 'day'))) &&
                <OverlayTrigger placement='top' overlay={extraPopoverInfo}>
                    <FontAwesomeIcon icon={faQuestionCircle} style={{color: 'gold', position: 'absolute', top: 4, left: 4}}/>
                </OverlayTrigger>
            }
        </button>
    )
}