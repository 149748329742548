/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import CustomControl from '../../../components/CustomControl';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { ValidationGroup, validateExistence } from '../../../validation';
import { KeyedValidationGroup } from '../../../validation';
import APIModal from '../../../components/APIModal';
import Cookies from 'universal-cookie';
import ApiManager, { ApiRequest } from '../../../ApiManager.tsx';
import DateTimePicker from '../../../components/DateTimePicker.js';

function HRUserTimesheetEditor({selectedEmployee, selectedEntry, hideModal, handleAddEntry, handleUpdateEntry, handleDeleteEntry, handleJumpToBwcRecord}) {
    const [inTime, setInTime] = useState(selectedEntry ? selectedEntry.inTime : moment().format('YYYY-MM-DD HH:mm'));
    const [outTime, setOutTime] = useState(selectedEntry?.outTime ? selectedEntry.outTime : '');
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [inPicture, setInPicture] = useState(undefined);
    const [outPicture, setOutPicture] = useState(undefined);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const validationGroup = new KeyedValidationGroup();

    useEffect(() => {
        if(selectedEntry){
            if (selectedEntry.inPicture) {
                handleDownloadFile(selectedEntry.inPicture).then((response) => {
                    setInPicture(response);
                })
            }
            if (selectedEntry.outPicture) {
                handleDownloadFile(selectedEntry.outPicture).then((response) => {
                    setOutPicture(response);
                })
            }
        }
    }, []);

    function handleSubmit(){
        const newEntry = {
            uid: selectedEntry ? selectedEntry.uid : undefined,
            companyUserIdentifier: selectedEmployee.companyUserUid,
            inTime: moment(inTime).format('YYYY-MM-DD HH:mm'),
            outTime: outTime ? moment(outTime).format('YYYY-MM-DD HH:mm') : undefined,
            inLat: selectedEntry ? selectedEntry.inLat : undefined,
            inLong: selectedEntry ? selectedEntry.inLong : undefined,
            outLat: selectedEntry ? selectedEntry.outLat : undefined,
            outLong: selectedEntry ? selectedEntry.outLong : undefined
        }

        if(selectedEntry){
            new ApiRequest('hr', 'updateTimesheet', setIsLoading, () => {
                handleUpdateEntry(newEntry);
                hideModal();
            }).withData({data: newEntry}).send();
        } else{
            new ApiRequest('hr', 'createTimesheet', setIsLoading, (response) => {
                newEntry.uid = response.uid;
                handleAddEntry(newEntry);
                hideModal();
            }).withData({data: newEntry}).send();
        }
    }

    function handleDelete(){
        if(selectedEntry){
            new ApiRequest('hr', 'deleteTimesheet', setIsDeleting, () => {
                handleDeleteEntry(selectedEntry.uid);
                hideModal();
            }).withUid(selectedEntry.uid).send()
        }
    }

    function handleDownloadFile(uid){
        let base64 = undefined;
        new ApiRequest('hr', 'downloadCustomDocument', setIsDownloading, (response) => base64 = response.base64).withUid(uid).withNoAlertOnSuccess().send();  
        return base64;
    }
    
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{selectedEntry ? 'Editing Timesheet Entry' : 'Creating Timesheet Entry'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>        
                    <Row>
                        <Col lg={6} style={{marginBottom: 8}}>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <DateTimePicker title='In Time' type='dateTime' value={inTime} setValue={setInTime}/>
                            </div>
                        </Col>
                        <Col lg={6} style={{marginBottom: 8}}>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <DateTimePicker title='Out Time' type='dateTime' value={outTime} setValue={setOutTime} optional/>
                            </div>
                        </Col>
                        {/* <CustomControl validator={validationGroup.createValidator('inTime', inTime, validateExistence)} lg={6} disabled={isLoading} title='In Time' type='datetime-local' value={inTime} onChange={(event) => {setInTime(moment(event.target.value).format('YYYY-MM-DD HH:mm'))}}/> */}
                        {/* <CustomControl lg={6} disabled={isLoading} title='Out Time' type='datetime-local' value={outTime} onChange={(event) => {setOutTime(event.target.value ? moment(event.target.value).format('YYYY-MM-DD HH:mm') : event.target.value)}}/> */}

                        { selectedEntry && 
                            <>
                                { inPicture || outPicture ?
                                    <>
                                        <Col lg={6} style={{marginBottom: 8}}>
                                            {inPicture &&   
                                            <div style={{width: '100%', backgroundColor: 'black', borderRadius: 6}}>
                                                <img src={inPicture} style={{objectFit: 'contain', width: '100%', aspectRatio: 1}}/>
                                            </div>
                                               
                                            }
                                        </Col>
                                        <Col lg={6} style={{marginBottom: 8}}>
                                            {outPicture &&
                                            <div style={{width: '100%', backgroundColor: 'black', borderRadius: 6}}>
                                                <img src={outPicture} style={{objectFit: 'contain', width: '100%', aspectRatio: 1}}/>
                                            </div>
                                            }
                                        </Col> 
                                    </>
                                    :
                                    <>
                                        <Col lg={6} style={{marginBottom: 8}}>
                                            {  selectedEntry.inLat && selectedEntry.inLong && !ApiManager.DEV_MODE ?    
                                                <LoadScript googleMapsApiKey="AIzaSyBO6pLOjJq8W63VtQ3TPkpUW1mF7gdfJgo">
                                                    <GoogleMap mapContainerStyle={{width: '100%', aspectRatio: 1}} center={{lat: parseFloat(selectedEntry.inLat), lng: parseFloat(selectedEntry.inLong)}} zoom={16}>
                                                        <Marker label='In' position={{lat: parseFloat(selectedEntry.inLat), lng: parseFloat(selectedEntry.inLong)}}/>
                                                    </GoogleMap>
                                                </LoadScript>
                                                : 
                                                <div style={{width: '100%', aspectRatio: 1, display: 'flex', fontSize: 20, justifyContent: 'center', alignItems: 'center', border: '1px solid lightgray', borderRadius: 6}}>
                                                    Clock-In Location Unknown
                                                </div>
                                            }
                                        </Col>
                                        <Col lg={6} style={{marginBottom: 8}}>
                                            { selectedEntry.outLat && selectedEntry.outLong && !ApiManager.DEV_MODE ?
                                                <LoadScript googleMapsApiKey="AIzaSyBO6pLOjJq8W63VtQ3TPkpUW1mF7gdfJgo">
                                                    <GoogleMap mapContainerStyle={{width: '100%', aspectRatio: 1}} center={{lat: parseFloat(selectedEntry.inLat), lng: parseFloat(selectedEntry.inLong)}} zoom={16}>
                                                        <Marker label='Out' position={{lat: parseFloat(selectedEntry.inLat), lng: parseFloat(selectedEntry.inLong)}}/>
                                                    </GoogleMap>
                                                </LoadScript>
                                                :
                                                <div style={{width: '100%', aspectRatio: 1, display: 'flex', fontSize: 20, justifyContent: 'center', alignItems: 'center', border: '1px solid lightgray', borderRadius: 6}}>
                                                    Clock-Out Location Unknown
                                                </div>
                                            }
                                        </Col> 
                                    </>

                                }
                                <CustomControl lg={4} type='text' value={selectedEntry.incidents === '1' ? 'Yes' : 'No'} title='Reported Incident(s)?' disabled/>
                                <CustomControl lg={4} type='text' value={selectedEntry.accidents === '1' ? 'Yes' : 'No'} title='Reported Accident(s)?' disabled/>
                                <CustomControl lg={4} type='text' value={selectedEntry.injuries === '1' ? 'Yes' : 'No'} title='Reported Injury(s)?' disabled/>
                                { selectedEntry.incidentStatement && 
                                    <Col xxl={12} >
                                        <div style={{backgroundColor: '#e9ecef', borderRadius: 6, padding: 8, marginBottom: 8}}>
                                            <p style={{marginBottom: 6, fontWeight: 'bold'}}>Driver Statement of Incident</p>
                                            <span style={{fontStyle: 'italic', opacity: 0.65}}>{selectedEntry.incidentStatement}</span>
                                        </div>
                                    </Col>
                                }
                                { selectedEntry.accidentStatement && 
                                    <Col xxl={12}>
                                        <div style={{backgroundColor: '#e9ecef', borderRadius: 6, padding: 8, marginBottom: 8}}>
                                            <p style={{marginBottom: 6, fontWeight: 'bold'}}>Driver Statement of Accident</p>
                                            <span style={{fontStyle: 'italic', opacity: 0.65}}>{selectedEntry.accidentStatement}</span>
                                        </div>
                                    </Col>
                                }
                                { selectedEntry.bwcRecord && new Cookies().get('permissions').hr_bwc == '1' &&
                                    <Col xxl={12}>
                                        <div style={{backgroundColor: '#e9ecef', borderRadius: 6, padding: 8, marginBottom: 8, display: 'flex', justifyContent: 'space-between'}}>
                                            <span style={{fontWeight: 'bold'}}>Driver BWC Record of Injury</span>
                                            <Button onClick={() => {hideModal(); handleJumpToBwcRecord(selectedEntry.bwcRecord);}} variant='outline-primary' style={{padding: '0px 6px 0px 6px'}}>Click to View</Button>
                                        </div>
                                    </Col>
                                }
                            </>
                        }
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                { selectedEntry &&
                    <Button onClick={() => setShowDeleteConfirmation(true)} style={{color: 'red', backgroundColor: 'transparent', border: 'none', boxShadow: 'none', padding: 0, marginRight: 8}}>
                        Delete Timesheet Entry
                    </Button>
                }
                <Button variant={'outline-primary'} disabled={isLoading || !validationGroup.isValid()} onClick={handleSubmit}>
                    {isLoading ? <Spinner size='sm'/> : selectedEntry ? 'Save Changes' : 'Submit'}
                </Button>
                
            </Modal.Footer>
            <APIModal show={showDeleteConfirmation} title={'Delete Confirmation'} message={'Permanently delete this timesheet entry?'} buttonLabel={'Delete'} onHide={() => {setShowDeleteConfirmation(false)}} centered callBack={handleDelete}/>
        </>
    )
}

export default HRUserTimesheetEditor;