/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import CustomControl from "../../../../../../components/CustomControl";
import Modal from 'react-bootstrap/Modal';
import RadioControl from "../../../../../../components/RadioControl";
import { validateExistence, ValidationGroup, validateGreaterThanZero } from "../../../../../../validation";
import SwitchControl from "../../../../../../components/SwitchControl";
import { Col, Container, Row } from "react-bootstrap";
import AdditionalPay from "../../../../Models/AdditionalPay";

export default function AddAdditionalPayForm({handleAddAdditionalPay}) {
    const [amount, setAmount] = useState('');
    const [type, setType] = useState('');
    const [label, setLabel] = useState('');
    const [isReoccuring, setIsReoccuring] = useState(false);

    const [applyToAll, setApplyToAll] = useState(false);

    const validationGroup = new ValidationGroup();

    const handleSetType = (value) => {
        setLabel(value);
        setType(value);
    }

    const handleSetAmount = (value) => {
        setAmount(value.replace('-', ''));
    }

    const handleSubmit = () => {
        if (validationGroup.isValid()) {
            handleAddAdditionalPay(new AdditionalPay(type, label, applyToAll ? '' : amount, isReoccuring), applyToAll);
        }
    }

    return (
        <>
            <Modal.Body>
                <Container>
                    <Row>        
                        <RadioControl xxl={12} title='Type' validator={validationGroup.createValidator(() => validateExistence(type))} inline selection={type} setSelection={handleSetType} optionNames={AdditionalPay.additionalPayTypes} optionValues={AdditionalPay.additionalPayTypes}/>
                        { !applyToAll &&
                            <CustomControl lg={6} validator={validationGroup.createValidator(() => validateGreaterThanZero(amount))} type='number' min={0} value={amount} onChange={(event) => {handleSetAmount(event.target.value)}} title='Amount'/>
                        }
                        <SwitchControl lg={6} value={isReoccuring} setValue={setIsReoccuring} title={'Reoccuring'}/>
                    </Row>
                </Container>
                <PayrollAdditionalPayModalTooltip boldText='Reoccuring' text={' - Next payroll period, this Additional Pay will be automatically added to the employee(s)'}/>                
            </Modal.Body>
            <Modal.Footer style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Form.Check type='switch' checked={applyToAll} onChange={() => {setApplyToAll(!applyToAll)}} label='Add bonus to all employees'/>
                <Button style={{color: 'white'}} disabled={!validationGroup.isValid()} onClick={handleSubmit}>Submit</Button>
            </Modal.Footer>
        </>
    )
}

export function PayrollAdditionalPayModalTooltip({boldText, text}) {
    return (
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 12}}>
            <FontAwesomeIcon style={{color: 'var(--bs-primary)'}} icon={faInfoCircle}/>
            <div>
                <span style={{fontWeight: 'bold'}}>{boldText + ' '}</span>
                <span>{text}</span>
            </div>
        </div>
    )
}