/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import RadioControl from "../../components/RadioControl";
import Button from 'react-bootstrap/Button';
import { ValidationGroup, Validator, validateExistence } from "../../validation"
import CustomButton from "../../components/CustomButton";
import { Col, Row } from "react-bootstrap";
import CustomTextArea from "../../components/CustomTextArea";

export default function TimeClockQuestionnaire({handleQuestionnaireSubmission}) {
    const [hadIncident, setHadIncident] = useState(false)
    const [hadAccident, setHadAccident] = useState(false)
    const [hadInjury, setHadInjury] = useState(false)
    const [incidentStatement, setIncidentStatement] = useState('')
    const [accidentStatement, setAccidentStatement] = useState('')
    const [injuryStatement, setInjuryStatement] = useState('')
    const [showModal, setShowModal] = useState(false)

    const validationGroup = new ValidationGroup();

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Clock-Out Questionnaire</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <span style={{fontWeight: 'bold'}}>All drivers are required to answer the following questions upon ending their shift.</span>
                <RadioControl 
                    selection={hadIncident} 
                    title='Did you have any incidents during your shift?' 
                    setSelection={setHadIncident} 
                    optionNames={['No', 'Yes']} 
                    optionValues={[false, true]} 
                    validator={validationGroup.createValidator(() => validateExistence(hadIncident))}
                />
                <RadioControl 
                    selection={hadAccident} 
                    title='Did you have any accidents during your shift?' 
                    setSelection={setHadAccident} 
                    optionNames={['No', 'Yes']} 
                    optionValues={[false, true]} 
                    validator={validationGroup.createValidator(() => validateExistence(hadAccident))}
                />
                <RadioControl 
                    selection={hadInjury} 
                    title='Did you have any injuries during your shift?' 
                    setSelection={setHadInjury} 
                    optionNames={['No', 'Yes']} 
                    optionValues={[false, true]} 
                    validator={validationGroup.createValidator(() => validateExistence(hadInjury))}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant={'outline-primary'} disabled={!validationGroup.isValid()} onClick={(hadIncident || hadAccident || hadInjury) ? ()=>setShowModal(true) : () => handleQuestionnaireSubmission(false, false, false, '', '', '')}>Submit and Clock Out</Button>
            </Modal.Footer>
            <Modal show={showModal} onHide={()=>setShowModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Please provide a statement for the following</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                    {hadIncident && 
                        <Col lg={12}>
                            <CustomTextArea rows={5} maxLength={1000} validator={validationGroup.createValidator(() => validateExistence(incidentStatement))} title='Incident Statement' value={incidentStatement} onChange={(e)=>setIncidentStatement(e.target.value)}/>
                        </Col>
                    }
                    {hadAccident && 
                        <Col lg={12}>
                            <CustomTextArea rows={5} maxLength={1000} validator={validationGroup.createValidator(() => validateExistence(accidentStatement))} title='Accident Statement' value={accidentStatement} onChange={(e)=>setAccidentStatement(e.target.value)}/>
                        </Col>
                    } 
                    {hadInjury && 
                        <Col lg={12}>
                            <CustomTextArea rows={5} maxLength={1000} validator={validationGroup.createValidator(() => validateExistence(injuryStatement))} title='Injury Statement' value={injuryStatement} onChange={(e)=>setInjuryStatement(e.target.value)}/>
                        </Col>
                    }
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton label='Submit and Clock Out' disabled={!validationGroup.isValid()} onClick={() => handleQuestionnaireSubmission(hadIncident, hadAccident, hadInjury, incidentStatement, accidentStatement, injuryStatement)}/>
                </Modal.Footer>
            </Modal>
        </>
    )
}