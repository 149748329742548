/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { ApiRequest } from '../../../ApiManager.tsx';
import CustomButton from '../../../components/CustomButton.js';

export default function HRUserDocumentEditor({document, handleEditHrDocumentName}){
    const [documentName, setDocumentName] = useState(document.documentName ? document.documentName : '');
    const [isLoading, setIsLoading] = useState(false);

    function handleEditDocument(){
        const updatedDocument = { 
            ...document,
            documentName: documentName
        };
        new ApiRequest('hr', 'updateCustomDocument', setIsLoading, () => handleEditHrDocumentName(updatedDocument)).withData({data: updatedDocument}).send();
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Edit Document Name</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Control type='text' placeholder={documentName} value={documentName} onChange={((event)=>{setDocumentName(event.target.value)})}/>
            </Modal.Body> 
            <Modal.Footer>
                <CustomButton label='Submit' variant={'outline-primary'} isLoading={isLoading} onClick={handleEditDocument}/>
            </Modal.Footer>
        </>

    )
}