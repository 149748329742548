/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useState } from "react";
import CustomButton from "../../../components/CustomButton";
import { faDownload, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import { faThumbsDown} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApiRequest } from "../../../ApiManager.tsx";
import { OnboardingDocumentsReadOnly, downloadBase64 } from "../../../tools";
import {Button} from "react-bootstrap";
import moment from "moment";

export default function EdDocumentItem({documentType, documentRequest, selectedEmployee, setShowSendBackModal, setDocToPreview, setShowPreview, setDocToSendBack}){
    const [isDownloading, setIsDownloading] = useState(false);
    const [isPreviewing, setIsPreviewing] = useState(false);
    const doc = documentRequest.documents[documentType];

    function handleDownloadDocument(documentType){
        if (documentRequest.documents[documentType].base64) {
            downloadBase64(documentRequest.documents[documentType].base64, `${selectedEmployee.firstName} ${selectedEmployee.lastName} ${OnboardingDocumentsReadOnly[documentType]}`);
        } else {
            new ApiRequest('hr', 'downloadEdDocument', setIsDownloading, (response) => {
                documentRequest.documents[documentType].base64 = response.document;
                downloadBase64(response.document, `${selectedEmployee.firstName} ${selectedEmployee.lastName} ${OnboardingDocumentsReadOnly[documentType]}`);
            }).withData({
                uid: documentRequest.documents[documentType].uid,
                documentType: documentRequest.documents[documentType].archived ? 'archivedDocument' : documentType
            }).withNoAlertOnSuccess().send();
        }
    }

    function handlePreviewDocument(documentType){
        if (documentRequest.documents[documentType].base64) {
            setDocToPreview(documentRequest.documents[documentType]);
            setShowPreview(true);
        } else {
            new ApiRequest('hr', 'downloadEdDocument', setIsPreviewing, (response) => {
                documentRequest.documents[documentType].base64 = response.document;
                setDocToPreview(documentRequest.documents[documentType]);
                setShowPreview(true);
            }).withData({
                uid: documentRequest.documents[documentType].uid,
                documentType: documentRequest.documents[documentType].archived ? 'archivedDocument' : documentType
            }).withNoAlertOnSuccess().send();
        }
    }

    return (
        <tr>
            <td>{OnboardingDocumentsReadOnly[documentType]}</td>
            <td style={{color: !doc.employeeCompleted ? 'red' : undefined}}>{doc.employeeCompleted ? moment(doc.employeeCompleted).format('MMM D, YYYY') : 'Awaiting Completion'}</td>
            <td style={{color: 'employerCompleted' in doc && !doc.employerCompleted ? 'red' : undefined}}>
                { 'employerCompleted' in doc ? doc.employerCompleted ? 
                    moment(doc.employerCompleted).format('MMM D, YYYY') : 'Awaiting Completion' : ''
                }
            </td>
            <td>
                <div style={{display: 'flex', gap: 6, justifyContent: 'flex-end'}}>
                    { doc.employeeCompleted && 
                        <>
                            <CustomButton title='Preview' style={{padding: '0px 6px 0px 6px'}} label={<FontAwesomeIcon icon={faMagnifyingGlass}/>} isLoading={isPreviewing} onClick={() => handlePreviewDocument(documentType)}/>
                            <CustomButton title='Download' style={{padding: '0px 6px 0px 6px'}} label={<FontAwesomeIcon icon={faDownload}/>} isLoading={isDownloading} onClick={() => handleDownloadDocument(documentType)}/>
                            <Button variant='outline-danger' title='Request Revision' style={{padding: '0px 6px 0px 6px', whiteSpace: 'nowrap'}} onClick={() => {setDocToSendBack(documentType); setShowSendBackModal(true)}}>
                                <FontAwesomeIcon icon={faThumbsDown}/>
                            </Button>
                        </>
                    }
                </div>
            </td>
        </tr>
    )
}