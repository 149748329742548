/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import React, { useEffect, useState } from "react";
import Budget from "./Models/Budget";
import {Dropdown, Modal } from "react-bootstrap";
import CustomButton from "../../components/CustomButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import BudgetAssumptions from "./BudgetAssumptions";
import BudgetExpenses from "./BudgetExpenses";
import BudgetDerivatives from "./BudgetDerivatives";
import BudgetProductivity from "./BudgetProductivity";
import BudgetLoadModal from "./BudgetLoadModal";
import CustomControl from "../../components/CustomControl";
import AlertModal from "../../components/AlertModals/AlertModal";
import { Validator, validateExistence } from "../../validation";
import { PDFViewer } from "@react-pdf/renderer";
import BudgetPDF from './BudgetPDF';
import ProjectedBudgetReportPDF from './ProjectedBudgetReportPDF.js';
import { Prompt } from "react-router-dom";
import { ApiRequest } from "../../ApiManager.tsx";

export default function BudgetTool({}){
    const [budget, setBudget] = useState(Budget.initDefault());
    const [originalBudget, setOriginalBudget] = useState(undefined);
    const [modalSwitch, setModalSwitch] = useState('load');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const unsavedChanges = budget && (!originalBudget || !budget.equals(originalBudget));

    useEffect(()=>{
        window.addEventListener("beforeunload", handleBeforeUnload)

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    })

    function handleSubmit(){
        if(budget.uid > 0){
            new ApiRequest('budget', 'update', setIsSubmitting, () => setOriginalBudget(budget.duplicate())).withData({budget: budget.encode()}).send();
        } else {
            new ApiRequest('budget', 'create', setIsSubmitting, (response) => {handleSetBudget('uid', response.uid); setOriginalBudget(budget.duplicate())}).withData({budget: budget.encode()}).send();
        }
    }

    function handleDeleteBudget(){
        new ApiRequest('budget', 'delete', setIsDeleting, () => {setBudget(Budget.initDefault()); setOriginalBudget(undefined); setModalSwitch('load')}).withUid(budget.uid).send();
    }

    function handleBeforeUnload(event) {
        event.preventDefault();
        return (event.returnValue = "");
    }

    function handleSetBudget(key, value) {
        const newBudget = budget.duplicate();
        newBudget[key] = value;
        setBudget(newBudget);
    }

    function handleLoadBudget(budget) {
        const loadedBudget = Budget.decode(budget);
        setBudget(loadedBudget);
        setOriginalBudget(loadedBudget);
        setModalSwitch('');
    }

    function handleCreateBudget(){
        const budget = Budget.initDefault();
        setBudget(budget);
        setOriginalBudget(undefined);
        setModalSwitch('');
    }

    function hideModal() {
        setModalSwitch('');
    }

    return (
        <div style={{height:'100%', overflowY:'scroll', display: 'flex', flexDirection: 'column', backgroundColor: 'white', padding: 12}}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 12}}>
                <div/>
                <h3>{`Budget Tool`}</h3>
                <div style={{display: 'flex', gap: 8}}>
                    <CustomButton label={unsavedChanges ? (budget.uid > 0 ? 'Save Changes' : 'Save Budget') : 'Saved'} disabled={!unsavedChanges || !budget.title} onClick={handleSubmit} isLoading={isSubmitting}/>
                    <Dropdown>
                        <Dropdown.Toggle variant="outline-primary"><FontAwesomeIcon icon={faBars}/></Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setModalSwitch('load')}>Load Budget</Dropdown.Item>
                            <Dropdown.Item onClick={() => setModalSwitch('create')}>Create New Budget</Dropdown.Item>
                            <Dropdown.Item onClick={() => setModalSwitch('print')}>Print Full Budget</Dropdown.Item>                     
                            <Dropdown.Item onClick={() => setModalSwitch('printProjected')}>Print Projected Budget Report</Dropdown.Item>                     
                            { budget.uid > 0 &&
                                <>
                                    <Dropdown.Divider/>
                                    <Dropdown.Item onClick={() => setModalSwitch('delete')}>Delete Budget</Dropdown.Item>
                                </>
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginBottom: 12}}>
                <div style={{maxWidth: '50%', width: 600}}>
                    <CustomControl validator={new Validator(() => validateExistence(budget.title))} title='Budget Title' maxLength={80} value={budget.title} originalValue={originalBudget?.title} onChange={(event) => handleSetBudget('title', event.target.value)}/>
                </div>
            </div>
            <div style={{display: 'flex', gap: 12}}>
                <div style={{width: '34%'}}>
                    <BudgetAssumptions budgetAssumptions={budget.budgetAssumptions} originalBudgetAssumptions={originalBudget?.budgetAssumptions} handleSetBudget={handleSetBudget}/>
                    <BudgetExpenses budgetExpenses={budget.budgetExpenses} originalBudgetExpenses={originalBudget?.budgetExpenses} handleSetBudget={handleSetBudget}/>
                </div>
                <div style={{width: '66%'}}>
                    <BudgetProductivity budget={budget} originalBudget={originalBudget} handleSetBudget={handleSetBudget}/>
                    <BudgetDerivatives budget={budget}/>
                </div>
            </div>
            <Modal show={modalSwitch === 'load'} onHide={hideModal} size='lg'>
                <BudgetLoadModal handleLoadBudget={handleLoadBudget}/>
            </Modal>
            <Modal show={modalSwitch === 'print'} onHide={hideModal} size='fullscreen'>
                <Modal.Header closeButton>
                    <Modal.Title>Print Budget</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{overflowY: 'hidden'}}>   
                    <PDFViewer style={{width: '100%', height: '100%'}}>
                        <BudgetPDF budget={budget}/>
                    </PDFViewer>
                </Modal.Body>
            </Modal>
            <Modal show={modalSwitch === 'printProjected'} onHide={hideModal} size='fullscreen'>
                <Modal.Header closeButton>
                    <Modal.Title>Print Projected Budget Report</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{overflowY: 'hidden'}}>   
                    <PDFViewer style={{width: '100%', height: '100%'}}>
                        <ProjectedBudgetReportPDF budget={budget}/>
                    </PDFViewer>
                </Modal.Body>
            </Modal>
            <Modal show={modalSwitch === 'create'} onHide={hideModal} title='Create Budget' >
                <AlertModal
                    isLoading={isSubmitting}
                    title='Create Budget?' 
                    message='WARNING: Any unsaved changes will be lost!' 
                    buttonLabel='Create New Budget' 
                    callBack={handleCreateBudget}
                />
            </Modal>
            <Modal show={modalSwitch === 'delete'} onHide={() => {}} >
                <AlertModal
                    isLoading={isDeleting}
                    variant={'outline-danger'}
                    title='Delete Budget?' 
                    message='This cannot be undone.' 
                    buttonLabel='Delete Budget' 
                    callBack={handleDeleteBudget}
                />
            </Modal>
            <Prompt when={true} message={'Any unsaved changes will be lost!'}/>
        </div>
    )

}