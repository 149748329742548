/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Card from 'react-bootstrap/Card';
import { Button, Form } from 'react-bootstrap';
import SearchBar from '../../../components/SearchBar';
import { dateIsInRange, dateRangeToString } from '../../../tools';
import DateRangeSelector from '../../../components/DateRangeSelector';
import HRNotesEditor from './HRNotesEditor';
import Modal from 'react-bootstrap/Modal';
import Cookies from 'universal-cookie';
import { ApiRequest } from '../../../ApiManager.tsx';
import LoadingWrapper from '../../../components/LoadingWrapper';

export default function HRNotes({users, selectedEmployee}){
    const [isLoading, setIsLoading] = useState(false);
    const [notes, setNotes] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filter, setFilter] = useState('');
    const [filterType, setFilterType] = useState('title');
    const [showDateRangeFilter, setShowDateRangeFilter] = useState(false);
    const [onlyShowAuthoredNotes, setOnlyShowAuthoredNotes] = useState(false);
    const [showEditor, setShowEditor] = useState(false);
    const [selectedNote, setSelectedNote] = useState(undefined);
    const cookieMonster = new Cookies();

    useEffect(() => {
        loadData();
    }, [selectedEmployee])

    function loadData(){
        new ApiRequest('hr', 'getEmployeeNotes', setIsLoading, (response) => setNotes(response.data.sort(sortNotes))).withData({companyUserIdentifier: selectedEmployee.companyUserUid}).withNoAlertOnSuccess().send();
    }

    function handleSetDateRange(startDate, endDate) {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    function sortNotes(a, b) {
        if (moment(a.submissionDate).isBefore(moment(b.submissionDate), 'days')) {
            return 1;
        } else if (moment(a.submissionDate).isAfter(moment(b.submissionDate), 'days')) {
            return -1;
        } else 
        return 0;
    }

    function handleCrud(type, obj) {
        let newArray = Array.from(notes);
        if (type === 'create') {
            newArray.push(obj);
        } else if (type === 'delete') {
            newArray = newArray.filter(r => r.uid != obj.uid);
        }

        setNotes(newArray.sort(sortNotes));
    }

    const filteredNotes = notes.filter((note) => {
        const isInRange = dateIsInRange(note.submissionDate, startDate, endDate);
        const isInSearch = !filter || note[filterType].toLocaleLowerCase().includes(filter.toLocaleLowerCase());
        const obeysOnlyShowAuthoredNotes = !onlyShowAuthoredNotes || cookieMonster.get('userData').companyUserUid === note.author;
        return isInRange && isInSearch && obeysOnlyShowAuthoredNotes;
    })

    const noteElements = filteredNotes.map((note) => {
        const author = users.find(u => u.companyUserUid == note.author);

        return (
            <Card key={note.uid}>
                <Card.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                    <Card.Title>{note.title}</Card.Title>
                    <div style={{backgroundColor: 'rgba(0, 0, 0, 0.05)', borderRadius: 6, padding: 6}}>
                        <span style={{whiteSpace: 'pre-line'}}>{note.body}</span>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <span style={{opacity: 0.5}}>{(note.lastModified ? 'Last edited on ' + moment(note.lastModified).format('MMM D, YYYY') : 'Created on ' + moment(note.submissionDate).format('MMM D, YYYY'))}</span>
                        <span style={{color: 'var(--bs-primary)'}}>{'- ' + author.firstName + ' ' + author.lastName}</span>
                    </div>
                </Card.Body>
            </Card>
        )
    });

    const content = (
        <div style={{height: '100%', display: 'flex', flexDirection: 'column', gap: 12, overflowY: 'hidden'}}>
            <div style={{display: 'flex', gap: 12, alignItems: 'center'}}>
                <SearchBar label='Filter by...' value={filter} setValue={setFilter} typeNames={['Title', 'Content']} typeValues={['title', 'body']} type={filterType} setType={setFilterType}/>
                <Button variant={'outline-primary'} style={{flexShrink: 0}} onClick={() => setShowDateRangeFilter(true)}>
                    {'Submission Date Filter: ' + dateRangeToString(startDate, endDate)}
                </Button>
                <div style={{flexShrink: 0}}>
                    <Form.Check type='switch' label='Only Show My Notes' value={onlyShowAuthoredNotes} onChange={() => setOnlyShowAuthoredNotes(!onlyShowAuthoredNotes)}/>
                </div>
                <Button variant={'outline-primary'} style={{flexShrink: 0}} onClick={() => {setShowEditor(true); setSelectedNote(undefined)}}>Create Note</Button>
            </div>
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', gap: 12, overflowY: 'auto'}}>
                {noteElements}
            </div>
            <DateRangeSelector show={showDateRangeFilter} onHide={() => setShowDateRangeFilter(false)} centered handleSetDateRange={handleSetDateRange} presetOptions={['pastYear', 'pastMonth', 'pastWeek', 'allTime']}/>
        </div>
    );

    return (
        <>
            <LoadingWrapper isLoading={isLoading}>{content}</LoadingWrapper>
            <Modal show={showEditor} onHide={() => setShowEditor(false)} size='lg'>
                <HRNotesEditor onHide={() => setShowEditor(false)} note={selectedNote} handleCrud={handleCrud} selectedEmployee={selectedEmployee}/>
            </Modal>
        </>
    )

}