/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import {useRef, useState } from "react";
import moment from "moment";
import { Form, InputGroup, Modal} from "react-bootstrap";
import { useStateObject } from "../../../hooks.tsx";
import SettlementReportNewModel from "./Models/ChargeStatement.js";
import { toast } from "react-toastify";
import CustomButton from "../../../components/CustomButton.js";
import { ApiRequest } from "../../../ApiManager.tsx";
import ChargeStatementMatchFedex from "./ChargeStatementMatchFedex.js";
import ChargeStatement from './Models/ChargeStatement.js'
import ChargeStatementDriver from './Models/ChargeStatementDriver.js'
import ChargeStatementDay from './Models/ChargeStatementDay.js'

export default function ChargeStatementUpload({createChargeStatementCallback, matchFedexIdsCallback}) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const fileRef = useRef();
    const [chargeStatement, setChargeStatement, setChargeStatementKey] = useStateObject(ChargeStatement.initDefault())
    const [chargeStatementIsValid, setChargeStatementIsValid] = useState(false);
    const [overwrite, setOverwrite] = useState(false);
    const [showMatchModal, setShowMatchModal] = useState(false);
    const [unmatchedFedexIds, setUnmatchedFedexIds] = useState([]);
    const [csaIsUnmatched, setCsaIsUnmatched] = useState(false);

    function handleSubmit(){
        new ApiRequest('chargeStatement', 'createChargeStatement', setIsSubmitting, (response) => {
            chargeStatement.uid = response.uid;
            chargeStatement.csaName = response.csaName;
            if(response.csaIsUnmatched || response.unmatchedFedexIds?.length > 0){
                if(response.csaIsUnmatched){
                    setCsaIsUnmatched(response.csaIsUnmatched);
                }
                if(response.unmatchedFedexIds?.length > 0){
                    setUnmatchedFedexIds(response.unmatchedFedexIds);
                }
                setShowMatchModal(true)
            }
            if (createChargeStatementCallback) {
                createChargeStatementCallback(chargeStatement);
            }
        }).withData({chargeStatement: chargeStatement.encode(), overwrite: overwrite}).send()
    }

    async function handleFileChange() {
        setChargeStatementIsValid(false);
        const file = fileRef.current.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onerror = () => {console.log('Something went wrong')}
            reader.onload = () => {
                try {
                    let str = reader.result;
                    str = str.replaceAll('\"', '')
                    let rows = str.split(/\r\n|\r|\n/);
                    const chargeStatementDriverRows = [];
                    const chargeStatementDayRows = [];
                    let startDate;
                    let endDate;
                    let rowLoopCounter = 0;
                    // Throw if not charge statement
                    if(rows[1].split(',').slice(0, 1)?.[0].toLowerCase() !== 'contracted service area:'){
                        throw new Error('This CSV does not seem to be a charge statement')
                    }
                    // grab csa id
                    const csaFedexId = rows[1].split(',').slice(1, 2)?.[0];
                    let netAmount = 0;
                    let otherAmount = 0;
                    for (let i = 0; i < rows.length; i++) {
                        const firstColumn = rows[i].split(',')[0].toLowerCase()

                        // get weekly totals for packages and amounts as well as desig
                        if(firstColumn === `date` && rowLoopCounter < 2){
                            let oneValidDate = false;
                            let j = i;
                            let concatIndexCounter = 0;
                            let stopColumn = rows[j].split(',')[0].toLowerCase();
                            while(moment(stopColumn).isValid() || !oneValidDate){
                                stopColumn = rows[j].split(',')[0].toLowerCase();
                                if(moment(stopColumn).isValid()){
                                    oneValidDate = true;
                                    if(rowLoopCounter === 0){
                                        chargeStatementDayRows.push(rows[j].split(',').slice(0, 12))
                                    }else if(rowLoopCounter === 1){
                                        chargeStatementDayRows[concatIndexCounter] = chargeStatementDayRows[concatIndexCounter].concat(rows[j].split(',').slice(5, 11));
                                        concatIndexCounter++;
                                    }
                                }
                                j++;
                            }
                            startDate = moment(rows[i+1].split(',')[0]).format('YYYY-MM-DD');
                            rowLoopCounter++;
                        }

                        // get other charges
                        // if(firstColumn === `type`){
                        //     let j = i+1;
                        //     let stopColumn;;
                        //     while(stopColumn !== `other p&d charges total:`){
                        //         if(stopColumn === 'brand promotion - vehicles'){
                        //             rowsWithData.push(rows[j].split(',').slice(1, 2)[0].split(' ')[1]);
                        //         }
                        //         rowsWithData.push(rows[j].split(',').slice(12, 13))
                        //         stopColumn = rows[j+1].split(',')[0].toLowerCase();
                        //         j++;
                        //     }
                        // }

                        // get net amount/grand total
                        if(firstColumn === `other p&d charges total:`){
                            otherAmount = rows[i].split(',').slice(13, 14)?.[0]
                        }

                        // get net amount/grand total
                        if(firstColumn === `net amount due`){
                            netAmount = rows[i].split(',').slice(13, 14)?.[0]
                        }

                        // get all driver chargeStatementDays
                        if(firstColumn === 'driver #:'){
                            let oneValidDate = false;
                            let j = i;
                            let stopColumn = rows[j].split(',')[0].toLowerCase();
                            while(moment(stopColumn).isValid() || !oneValidDate){
                                stopColumn = rows[j].split(',')[0].toLowerCase();
                                if(moment(stopColumn).isValid()){
                                    oneValidDate = true;
                                    chargeStatementDriverRows.push(rows[j].split(',').slice(0, 10))
                                }
                                j++;
                            }
                        }

                        // get other information at bottom of csv file
                        // if(firstColumn === `other information:`){
                        //     let j = i+1;
                        //     let stopColumn;
                        //     do{
                        //         stopColumn = rows[j].split(',')[0].toLowerCase();
                        //         rowsWithData.push(rows[j].split(',').slice(1, 2))
                        //         j++;
                        //     }while(rows[j]);
                        // }
                    }

                    setChargeStatement(
                        new ChargeStatement(
                            -1,
                            csaFedexId,
                            startDate ?? moment().format('YYYY-MM-DD'),
                            otherAmount,
                            netAmount,
                            chargeStatementDriverRows.map(srdr => ChargeStatementDriver.initFromCsvRow(srdr, startDate)),
                            chargeStatementDayRows.map(srdr => ChargeStatementDay.initFromCsvRow(srdr, startDate)),
                    )
                )
                setChargeStatementIsValid(true)
                } catch (error) {
                    console.log(error.message)
                    toast.error('There seems to be something wrong with the csv you have uploaded. Are you sure that the file which was uploaded is a charge statement?')
                }
            }
            reader.readAsText(file);
        }
    }

    return (
        <>
            <InputGroup style={{alignItems: 'center'}}>
                <Form.Control type='file' ref={fileRef} accept=".csv" onChange={handleFileChange}/>
                <InputGroup.Text style={{height: 38}}>
                    <Form.Check type='switch' label='Overwrite' checked={overwrite} onChange={()=> setOverwrite((cur) => !cur)}/>
                </InputGroup.Text>
                <CustomButton isLoading={isSubmitting} disabled={!chargeStatementIsValid} onClick={handleSubmit} label={'Submit'}/>
            </InputGroup>
            <Modal centered size="xl" fullscreen='lg-down' backdrop='static' show={showMatchModal} onHide={() => {setShowMatchModal(false); setUnmatchedFedexIds([]); setCsaIsUnmatched(false)}}>
                <ChargeStatementMatchFedex chargeStatement={chargeStatement} unmatchedFedexIds={unmatchedFedexIds} csaIsUnmatched={csaIsUnmatched} setUnmatchedFedexIds={setUnmatchedFedexIds} matchFedexIdsCallback={matchFedexIdsCallback} hideModal={() => {setShowMatchModal(false); setUnmatchedFedexIds([]); setCsaIsUnmatched(false)}}/>
            </Modal>
        </>
    )
}