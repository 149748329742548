/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import QuickTable from "../../components/QuickTable";
import PCATField from "./PCATField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faWallet, faWrench, faXmark } from "@fortawesome/free-solid-svg-icons";
import AlertModal from "../../components/AlertModals/AlertModal";
import { validateInteger } from "../../tools";
import moment from "moment";
import { faCopy, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import PCATProposalBudget from "./PCATProposalBudget";

export default function ProposalElements({pcat, originalPcat, handleSetPcat}) {
    const [showDelete, setShowDelete] = useState(false);
    const [showBudgetModal, setShowBudgetModal] = useState(false);
    const [selectedProposalIndex, setSelectedProposalIndex] = useState(undefined);

    const handleDuplicateProposal = (index) => {
        const newProposal = pcat.proposals[index].duplicate();
        newProposal.isFedex = !pcat.proposals[pcat.proposals.length - 1].isFedex;
        if (newProposal.budget) {
            newProposal.budget.title = `Budget for PCAT \"${pcat.title}\" | Proposal #${pcat.proposals.length + 1} (${newProposal.isFedex ? 'FedEx' : 'IC'})`;
        }
        handleSetPcat('proposals', pcat.proposals.concat([newProposal]));
    }

    const handleDeleteProposal = async () => {
        if (selectedProposalIndex !== undefined) {
            const proposals = pcat.proposals.toSpliced(selectedProposalIndex, 1);
            handleSetPcat('proposals', proposals);
            setSelectedProposalIndex(undefined);
        }
        setShowDelete(false);
        return true;
    }
    
    const handleSetProposal = (index, key, value) => {
        const proposals = Array.from(pcat.proposals);
        proposals[index][key] = value;
        handleSetPcat('proposals', proposals);
    }

    const getHeader = (index) => {
        if (pcat.proposals[index].isFedex) {
            if (index > 0) {
                let fedexProposalCount = 0;
                for (let i = 0; i < pcat.proposals.length; i++) {
                    if (i == index) {
                        break;
                    } else if (pcat.proposals[i].isFedex) {
                        fedexProposalCount++;
                    }
                }
                return `FedEx Counter Proposal #${fedexProposalCount}`;
            } else {
                return "Initial FedEx Proposal";
            }
        } else {
            let icProposalCount = 1;
            for (let i = 0; i < pcat.proposals.length; i++) {
                if (i == index) {
                    break;
                } else if (!pcat.proposals[i].isFedex) {
                    icProposalCount++;
                }
            }
            return `IC Counter Proposal #${icProposalCount}`;
        }
    }

    const getHeaderSimple = (index) => {
        if (pcat.proposals[index].isFedex) {
            let fedexProposalCount = 1;
            for (let i = 0; i < pcat.proposals.length; i++) {
                if (i == index) {
                    break;
                } else if (pcat.proposals[i].isFedex) {
                    fedexProposalCount++;
                }
            }
            return `FE #${fedexProposalCount}`;
        } else {
            let icProposalCount = 1;
            for (let i = 0; i < pcat.proposals.length; i++) {
                if (i == index) {
                    break;
                } else if (!pcat.proposals[i].isFedex) {
                    icProposalCount++;
                }
            }
            return `IC #${icProposalCount}`;
        }
    }

    const dateEffectiveRow = (
        <tr key='dateEffective'>
            <td style={{whiteSpace: 'nowrap'}}>Effective Date</td>
            {
                pcat.proposals.map((_, index) => {
                    const value = pcat.proposals[index].dateEffective;
                    const originalValue = originalPcat?.proposals[index]?.dateEffective;
                    return (
                        <td key={index}>
                            <PCATField type='date' value={value} originalValue={originalValue} setValue={(value)=>{handleSetProposal(index, 'dateEffective', value)}}/>
                        </td>
                    )
                })
            }
        </tr>
    )

    const dockLoadingPositionsRow = (
        <tr key='dockLoadingPositions'>
            <td style={{whiteSpace: 'nowrap'}}>Dock Loading Positions</td>
            {
                pcat.proposals.map((_, index) => {
                    const value = pcat.proposals[index].dockLoadingPositions;
                    const originalValue = originalPcat?.proposals[index]?.dockLoadingPositions;
                    return (
                        <td key={index}>
                            <PCATField commasOnBlur min={0} max={9999} type='number' value={value} originalValue={originalValue} setValue={(value)=>{handleSetProposal(index, 'dockLoadingPositions', value)}}/>
                        </td>
                    )
                })
            }
        </tr>
    )

    const brandVehiclesRow = (
        <tr key='brandVehicles'>
            <td style={{whiteSpace: 'nowrap'}}>Vehicles in Brand Promotion Program</td>
            {
                pcat.proposals.map((_, index) => {
                    const value = pcat.proposals[index].brandVehicles;
                    const originalValue = originalPcat?.proposals[index]?.brandVehicles;
                    return (
                        <td key={index}>
                            <PCATField commasOnBlur min={0} max={9999} type='number' value={value} originalValue={originalValue} setValue={(value)=>{handleSetProposal(index, 'brandVehicles', value)}}/>
                        </td>
                    )
                })
            }
        </tr>
    )

    const proposalInformationRows = [dateEffectiveRow, dockLoadingPositionsRow, brandVehiclesRow];
    const proposalElementsColumns = [
        {
            key: 'serviceCharge',
            label: 'Service Charge',
            frequency: 'Weekly',
            max: 99999.99,
        }, 
        {
            key: 'stopCharge',
            label: 'Stop Charge',
            frequency: 'Activity Based',
            max: 99999.99,
        }, 
        {
            key: 'surgeStopCharge',
            label: 'Surge Stop Charge',
            frequency: 'Activity Based',
            max: 99999.99,
        }, 
        {
            key: 'perStopFuelSurcharge',
            label: 'Per Stop Fuel Surcharge',
            frequency: 'Activity Based',
            max: 99999.99,
        }, 
        {
            key: 'packageCharge',
            label: 'Package Charge',
            frequency: 'Activity Based',
            max: 99.99,
        }, 
        {
            key: 'largePackageMixCharge',
            label: 'Large Package Mix Charge',
            frequency: 'Activity Based',
            max: 99.99,
        }, 
        {
            key: 'apparelBrandCharge',
            label: 'Apparel Brand Promotion Charge',
            frequency: 'Weekly',
            max: 9999.99,
        }, 
        {
            key: 'vehicleBrandCharge',
            label: 'Vehicle Brand Promotion Charge',
            frequency: 'Weekly / Vehicle',
            max: 9999.99,
        }, 
        {
            key: 'eCommStopCharge',
            label: 'E-Commerce Stop Charge',
            frequency: 'Activity Based',
            max: 99.99,
        }, 
        {
            key: 'eCommPackageCharge',
            label: 'E-Commerce Package Charge',
            frequency: 'Activity Based',
            max: 99.99,
        },
        {
            key: 'advanceServiceCharge',
            label: 'Advance Contract Service Charge',
            frequency: 'Activity Based',
            max: 999999.99,
        }
    ].map((prop) => {
        const columns = pcat.proposals.map((p, index) => {
            const originalValue = originalPcat?.proposals[index] ? originalPcat.proposals[index][prop.key] : undefined;
            return (
                <td key={index}>
                    <PCATField usdOnBlur type='number' max={prop.max} value={p[prop.key]} originalValue={originalValue} setValue={(value)=>{handleSetProposal(index, prop.key, value)}}/>
                </td>
            )
        })
        return (
            <tr key={prop.key}>
                <td style={{whiteSpace: 'nowrap'}}>{prop.label}</td>
                <td style={{whiteSpace: 'nowrap'}}>{prop.frequency}</td>
                {columns}
            </tr>
        )
    })

    const nonECommPackageGrowth = (
        <tr key='nonECommPackageGrowth'>
            <td style={{whiteSpace: 'nowrap'}}>Non E-Commerce Package Growth %</td>
            {
                pcat.proposals.map((p, index) => {
                    const originalValue = originalPcat?.proposals[index]?.nonECommPackageGrowth;
                    return (
                        <td key={index}>
                            <PCATField percentOnBlur type='number' max={99999.999} value={p.nonECommPackageGrowth} originalValue={originalValue} setValue={(value)=>{handleSetProposal(index, 'nonECommPackageGrowth', value)}}/>
                        </td>
                    )
                })
            }
        </tr>
    )
    const nonECommStopGrowth = (
        <tr key='nonECommStopGrowth'>
            <td style={{whiteSpace: 'nowrap'}}>Non E-Commerce Stop Growth %</td>
            {
                pcat.proposals.map((p, index) => {
                    const originalValue = originalPcat?.proposals[index]?.nonECommStopGrowth;
                    return (
                        <td key={index}>
                            <PCATField percentOnBlur type='number' max={99999.999} value={p.nonECommStopGrowth} originalValue={originalValue} setValue={(value)=>{handleSetProposal(index, 'nonECommStopGrowth', value)}}/>
                        </td>
                    )
                })
            }
        </tr>
    )
    const overThresholdStops = (
        <tr key='overThresholdStops'>
            <td style={{whiteSpace: 'nowrap'}}>Over Threshold Stops</td>
            {
                pcat.proposals.map((p, index) => {
                    const originalValue = originalPcat?.proposals[index]?.overThresholdStops;
                    return (
                        <td key={index}>
                            <PCATField intOnly type='number' max={999999} value={p.overThresholdStops} originalValue={originalValue} setValue={(value)=>{handleSetProposal(index, 'overThresholdStops', value)}}/>
                        </td>
                    )
                })
            }
        </tr>
    )
    const nonECommPackagesPerStop = (
        <tr key='Non E-Commerce Packages / Stop'>
            <td style={{whiteSpace: 'nowrap'}}>Non E-Commerce Packages / Stop</td>
            {
                pcat.proposals.map((p, index) => {
                    return (
                        <td key={index}>
                            {p.nonECommPackagesPerStop(pcat)}
                        </td>
                    )
                })
            }
        </tr>
    )
    const totalNonECommStops = (
        <tr key='Total Non E-Commerce Stops'>
            <td style={{whiteSpace: 'nowrap'}}>Total Non E-Commerce Stops</td>
            {
                pcat.proposals.map((p, index) => {
                    return (
                        <td key={index}>
                            {validateInteger(p.totalNonECommStops(pcat).toFixed(0)).toLocaleString()}
                        </td>
                    )
                })
            }
        </tr>
    )
    const totalNonECommPackages = (
        <tr key='Total Non E-Commerce Packages'>
            <td style={{whiteSpace: 'nowrap'}}>Total Non E-Commerce Packages</td>
            {
                pcat.proposals.map((p, index) => {
                    return (
                        <td key={index}>
                            {validateInteger(p.totalNonECommPackages(pcat).toFixed(0)).toLocaleString()}
                        </td>
                    )
                })
            }
        </tr>
    )

    const totalDeliveryPackages = (
        <tr key='Total Delivery Packages'>
            <td style={{whiteSpace: 'nowrap'}}>Total Delivery Packages</td>
            {
                pcat.proposals.map((p, index) => {
                    return (
                        <td key={index}>
                            {validateInteger(p.totalDeliveryPackages(pcat).toFixed(0)).toLocaleString()}
                        </td>
                    )
                })
            }
        </tr>
    )

    const largePackageMixDeliveryPercentage = (
        <tr key='Large Package Mix Delivery %'>
            <td style={{whiteSpace: 'nowrap'}}>Large Package Mix Delivery %</td>
            {
                pcat.proposals.map((p, index) => {
                    return (
                        <td key={index}>
                            {`${pcat.largePackageMixDeliveryPercentage}%`}
                        </td>
                    )
                })
            }
        </tr>
    )

    const eCommPackageGrowth = (
        <tr key='eCommPackageGrowth'>
            <td style={{whiteSpace: 'nowrap'}}>E-Commerce Package Growth %</td>
            {
                pcat.proposals.map((p, index) => {
                    const originalValue = originalPcat?.proposals[index]?.eCommPackageGrowth;
                    return (
                        <td key={index}>
                            <PCATField percentOnBlur max={99999.999} type='number' value={p.eCommPackageGrowth} originalValue={originalValue} setValue={(value)=>{handleSetProposal(index, 'eCommPackageGrowth', value)}}/>
                        </td>
                    )
                })
            }
        </tr>
    )
    const eCommStopGrowth = (
        <tr key='eCommStopGrowth'>
            <td style={{whiteSpace: 'nowrap'}}>E-Commerce Stop Growth %</td>
            {
                pcat.proposals.map((p, index) => {
                    const originalValue = originalPcat?.proposals[index]?.eCommStopGrowth;
                    return (
                        <td key={index}>
                            <PCATField percentOnBlur max={99999.999} type='number' value={p.eCommStopGrowth} originalValue={originalValue} setValue={(value)=>{handleSetProposal(index, 'eCommStopGrowth', value)}}/>
                        </td>
                    )
                })
            }
        </tr>
    )
    const totalECommPackages = (
        <tr key='Total E-Commerce Packages'>
            <td style={{whiteSpace: 'nowrap'}}>Total E-Commerce Packages</td>
            {
                pcat.proposals.map((p, index) => {
                    return (
                        <td key={index}>
                            {validateInteger(p.totalECommPackages(pcat).toFixed(0)).toLocaleString()}
                        </td>
                    )
                })
            }
        </tr>
    )
    const totalECommStops = (
        <tr key='Total E-Commerce Stops'>
            <td style={{whiteSpace: 'nowrap'}}>Total E-Commerce Stops</td>
            {
                pcat.proposals.map((p, index) => {
                    return (
                        <td key={index}>
                            {validateInteger(p.totalECommStops(pcat).toFixed(0)).toLocaleString()}
                        </td>
                    )
                })
            }
        </tr>
    )
    const eCommPackagesPerStop = (
        <tr key='E-Commerce Packages / Stop'>
            <td style={{whiteSpace: 'nowrap'}}>E-Commerce Packages / Stop</td>
            {
                pcat.proposals.map((p, index) => {
                    return (
                        <td key={index}>
                            {p.eCommPackagesPerStop(pcat)}
                        </td>
                    )
                })
            }
        </tr>
    )
    
    const projectedGrowthRows = [
        nonECommPackageGrowth, nonECommStopGrowth, overThresholdStops, nonECommPackagesPerStop, totalNonECommStops, totalNonECommPackages, 
        totalDeliveryPackages, largePackageMixDeliveryPercentage, eCommPackageGrowth, eCommStopGrowth, totalECommPackages, totalECommStops, eCommPackagesPerStop
    ]

    const headerElements = Array.from(Array(pcat.proposals.length).keys()).map((index => {
        return (
            <div key={index} style={{display: 'flex', alignItems: 'center', gap: 8}}>
                <span style={{color: pcat.acceptedProposal == index ? 'limegreen' : undefined}}>{getHeader(index)}</span>
                { pcat.acceptedProposal == index &&
                    <FontAwesomeIcon icon={faCheck} style={{color: 'limegreen'}}/>
                }
                <Dropdown>
                    <Dropdown.Toggle variant="outline-primary" style={{padding: '0px 6px 0px 6px'}}><FontAwesomeIcon icon={faWrench}/></Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => {setSelectedProposalIndex(index); setShowBudgetModal(true);}}>
                            <FontAwesomeIcon icon={faWallet}/>
                            <span style={{marginLeft: 8}}>{pcat.proposals[index].budget ? 'View/Edit Budget' : 'Associate Budget With Proposal'}</span>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => {handleDuplicateProposal(index)}}>
                            <FontAwesomeIcon icon={faCopy}/>
                            <span style={{marginLeft: 8}}>Create New Proposal With Data</span>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => {handleSetPcat('acceptedProposal', pcat.acceptedProposal == index ? undefined : index)}}>
                            <FontAwesomeIcon icon={pcat.acceptedProposal == index ? faXmark : faCheck}/>
                            <span style={{marginLeft: 8}}>{pcat.acceptedProposal == index ? 'Deselect as Accepted Proposal' : 'Set as Accepted Proposal'}</span>
                        </Dropdown.Item>
                        <Dropdown.Divider/>
                        <Dropdown.Item  onClick={() => {setSelectedProposalIndex(index); setShowDelete(true);}}>
                            <FontAwesomeIcon icon={faTrashAlt}/>
                            <span style={{marginLeft: 8}}>Delete Proposal</span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        )
    }));

    const headerSimpleElements = Array.from(Array(pcat.proposals.length).keys()).map((index => {
        return (
            <span>{`${getHeaderSimple(index)} ${pcat.proposals[index].dateEffective ? `(${moment(pcat.proposals[index].dateEffective).format('MMM D, YYYY')})` : ''}`}</span>
        )
    }));

    return (
        <div>
            <QuickTable title='Proposal Information' rows={proposalInformationRows} widths={[1]} headers={[''].concat(headerElements)} size='sm' responsive={false}/>
            <QuickTable title='Proposal Elements' rows={proposalElementsColumns} widths={[1, 1]} headers={['Element', 'Frequency'].concat(headerSimpleElements)} size='sm' responsive={false}/>
            <QuickTable title='Projected Service Area Growth & Matrix' rows={projectedGrowthRows} widths={[1]} headers={[''].concat(headerSimpleElements)} size='sm' responsive={false}/>
            <Modal show={showDelete} onHide={() => {setShowDelete(false)}}>
                <AlertModal
                    variant='outline-danger'
                    title='Delete Proposal?' 
                    message='This cannot be undone. A deleted proposal may still be recoverable by loading this PCAT from its last save point' 
                    buttonLabel='Delete Proposal' 
                    callBack={handleDeleteProposal}
                />
            </Modal>
            <Modal show={showBudgetModal} onHide={() => {setShowBudgetModal(false)}} size="xl">
                <PCATProposalBudget pcat={pcat} proposalIndex={selectedProposalIndex} handleSetProposal={handleSetProposal} hideModal={() => {setShowBudgetModal(false)}}/>
            </Modal>
        </div>
    )
    
}