/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import React, { useState } from "react"
import QuickTable from "../../components/QuickTable";
import PCATField from "../PCAT/PCATField";
import { Button, Modal } from "react-bootstrap";
import BudgetImportPcat from "./BudgetImportPcat";

export default function BudgetAssumptions({budgetAssumptions, originalBudgetAssumptions, handleSetBudget}) {
    const [showImport, setShowImport] = useState(false);

    const handleSetBudgetAssumptions = (key, value) => {
        const newBudgetAssumptions = budgetAssumptions.duplicate();
        newBudgetAssumptions[key] = value;
        handleSetBudget('budgetAssumptions', newBudgetAssumptions);
    }

    const rows = [
        {
            key: 'vehicleBranding',
            label: 'Vehicle Branding',
            type: 'Yearly',
            onBlur: 'usd',
            max: 99999999.99,
        },
        {
            key: 'uniforms',
            label: 'Uniforms',
            type: 'Yearly',
            onBlur: 'usd',
            max: 99999999.99,
        },
        {
            key: 'packageCharge',
            label: 'Package Charge',
            type: 'Activity Based',
            onBlur: 'usd',
            max: 99.99,
        },
        {
            key: 'stopCharge',
            label: 'Ground Stop Charge',
            type: 'Activity Based',
            onBlur: 'usd',
            max: 99999.99,
        },
        {
            key: 'eCommStopCharge',
            label: 'E-Comm Stop Charge',
            type: 'Activity Based',
            onBlur: 'usd',
            max: 99.99,
        },
        {
            key: 'serviceCharge',
            label: 'Service Charge',
            type: 'Yearly',
            onBlur: 'usd',
            max: 99999999.99,
        },
        {
            key: 'perStopFuelSurcharge',
            label: 'Per Stop Fuel Charge',
            type: 'Activity Based',
            onBlur: 'usd',
            max: 99999.99,
        },
        {
            key: 'largePackageMixDeliveryPercentage',
            label: 'Large Package Mix %',
            type: '%',
            onBlur: 'percent',
            max: 999.999,
        },
        {
            key: 'largePackageMixCharge',
            label: 'Large Package Charge',
            type: 'Activity Based',
            onBlur: 'usd',
            max: 99.99,
        },
        {
            key: 'advanceServiceCharge',
            label: 'Advanced Contract Service Charge',
            type: 'Yearly',
            onBlur: 'usd',
            max: 999999.99,
        },
        {
            key: 'totalDeliveryPackages',
            label: 'Total Delivery Packages',
            type: 'Yearly',
            onBlur: 'comma',
            max: 999999999,
        },
    ].map((row) => {
        return (
            <tr key={row.key}>
                <td>{row.label}</td>
                <td>{row.type}</td>
                <td>
                    <PCATField 
                        fontSize={15}
                        max={row.max}
                        value={budgetAssumptions[row.key]} 
                        originalValue={originalBudgetAssumptions ? originalBudgetAssumptions[row.key] : undefined}
                        setValue={(value) => {handleSetBudgetAssumptions(row.key, value)}} 
                        usdOnBlur={row.onBlur === 'usd'} 
                        commasOnBlur={row.onBlur === 'comma'} 
                        percentOnBlur={row.onBlur === 'percent'}
                    />
                </td>
            </tr>
        )
    })

    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
            <Button  style={{margin: 'auto'}} variant="outline-primary" onClick={() => {setShowImport(true)}}>Import PCAT Into Assumptions</Button>
            <QuickTable fontSize={15} title='Assumptions' responsive={false} headers={['Assumption', 'Frequency', 'Amount']} rows={rows} size='sm'/>
            <Modal show={showImport} onHide={() => {setShowImport(false)}}>
                <BudgetImportPcat handleSetBudget={handleSetBudget} hideModal={() => {setShowImport(false)}}/>
            </Modal>
        </div>
    )
}