/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import QuickTable from '../../../components/QuickTable';
import HRUserLoanEditor from './HRUserLoanEditor';
import CustomButton from '../../../components/CustomButton';
import { usdFormatter, validateDecimal } from '../../../tools';
import HRUserTicketEditor from './HRUserTicketEditor';
import { ApiRequest } from '../../../ApiManager.tsx';
import LoadingWrapper from '../../../components/LoadingWrapper';

export default function HRUserLoan({selectedEmployee, handleRecalculateEntry}) {
    const [isLoading, setIsLoading] = useState(false);
    const [loans, setLoans] = useState([]);
    const [tickets, setTickets] = useState([]);
    const [showLoanModal, setShowLoanModal] = useState(false);
    const [showTicketModal, setShowTicketModal] = useState(false);
    const [selectedLoan, setSelectedLoan] = useState(undefined);
    const [selectedTicket, setSelectedTicket] = useState(undefined);

    useEffect(() => {
        loadData();
    }, [selectedEmployee])

    function loadData(){
        new ApiRequest('hr', 'getAllLoans', setIsLoading, (response) => {
            setLoans(response.loans);
            setTickets(response.tickets);
        }).withData({companyUserIdentifier: selectedEmployee.companyUserUid}).withNoAlertOnSuccess().send()
    }

    function handleLoanCrud(type, data) {
        let newArr = Array.from(loans);
        if (type === 'create') {
            newArr.push(data);
        } else if (type === 'update') {
            newArr = newArr.filter(t => t.uid != data.uid);
            newArr.push(data);
        } else if (type === 'delete') {
            newArr = newArr.filter(t => t.uid != data);
        } else if (type === 'installment') {
            const loan = newArr.find(l => l.uid == data.loanIdentifier);
            loan.installmentSum = validateDecimal(loan.installmentSum) + validateDecimal(data.amount);
        }

        setLoans(newArr)
        if (type !== 'installment') {
            setShowLoanModal(false);
        }
        if (handleRecalculateEntry) {
            handleRecalculateEntry();
        }
    }

    function handleTicketCrud(type, data) {
        let newArr = Array.from(tickets);
        if (type === 'create') {
            newArr.push(data);
        } else if (type === 'update') {
            newArr = newArr.filter(t => t.uid != data.uid);
            newArr.push(data);
        } else if (type === 'delete') {
            newArr = newArr.filter(t => t.uid != data);
        } else if (type === 'installment') {
            const ticket = newArr.find(l => l.uid == data.ticketIdentifier);
            ticket.installmentSum = validateDecimal(ticket.installmentSum) + validateDecimal(data.amount);
        }
        setTickets(newArr);

        if (type !== 'installment') {
            setShowTicketModal(false);
        }
        if (handleRecalculateEntry) {
            handleRecalculateEntry();
        }
    }

    const loanRows = loans.filter(l => validateDecimal(l.installmentSum) < validateDecimal(l.amount)).map((l)=>{
        const amountLeft = validateDecimal(l.amount) - validateDecimal(l.installmentSum);
        return (
           <tr key={l.uid} className='cursor-pointer' onClick={() => {setSelectedLoan(l.uid); setShowLoanModal(true)}}>
                <td>{l.name}</td>
                <td>{usdFormatter.format(l.amount)}</td>
                <td>{usdFormatter.format(l.installment)}</td>
                <td>{usdFormatter.format(l.installmentSum)}</td>
                <td>{usdFormatter.format(amountLeft)}</td>
           </tr>
        )
    });

    const ticketRows = tickets.filter(t => validateDecimal(t.installmentSum) < validateDecimal(t.amount)).map((ticket) => {
        const amountLeft = ticket.amount - ticket.installmentSum;
        return (
           <tr key={ticket.uid} className='cursor-pointer' onClick={() => {setSelectedTicket(ticket.uid); setShowTicketModal(true)}}>
                <td>{ticket.name}</td>
                <td>{usdFormatter.format(ticket.amount)}</td>
                <td>{usdFormatter.format(ticket.installment)}</td>
                <td>{usdFormatter.format(ticket.installmentSum)}</td>
                <td>{usdFormatter.format(amountLeft)}</td>
                <td>{ticket.deductFromSafetyBonuses ? 'Yes' : 'No'}</td>
           </tr>
        )
    });

    const loanHistoryRows = loans.filter(l => validateDecimal(l.installmentSum) >= validateDecimal(l.amount)).map((l) => {
        return (
            <tr key={'loan' + l.uid} className='cursor-pointer' onClick={() => {setSelectedLoan(l.uid); setShowLoanModal(true)}}>
                <td>Loan</td>
                <td>{l.name}</td>
                <td>{l.amount}</td>
            </tr>
        )
    })

    const ticketHistoryRows = tickets.filter(t => validateDecimal(t.installmentSum) >= validateDecimal(t.amount)).map((t) => {
        return (
            <tr key={'ticket' + t.uid} className='cursor-pointer' onClick={() => {setSelectedTicket(t.uid); setShowTicketModal(true)}}>
                <td>Fine Ticket Damage</td>
                <td>{t.name}</td>
                <td>{t.amount}</td>
            </tr>
        )
    })

    return (
        <LoadingWrapper isLoading={isLoading} onlyRenderIfTruthy={selectedEmployee ?? false}>
            <div style={{display: 'flex', flexDirection: 'column', gap: 12, haight: '100%', overflow: 'hidden'}}>
                <div style={{display:'flex', justifyContent:'flex-end', gap: 8, alignItems: 'center'}}>
                    <CustomButton label={'Create Loan'} onClick={() => {setSelectedLoan(undefined); setShowLoanModal(true)}}/>
                    <CustomButton label={'Create Ticket'} onClick={() => {setSelectedTicket(undefined); setShowTicketModal(true)}}/>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: 12, flex: 1, overflowY: 'auto'}}>
                    <QuickTable noMargin title='Current Loans' headers={['Name','Amount Borrowed', 'Installment', 'Amount Paid', 'Amount Remaining']} rows={loanRows}  size={'sm'}/>
                    <QuickTable noMargin title='Current Fine Ticket Damage' headers={['Name','Amount Borrowed', 'Installment', 'Amount Paid', 'Amount Remaining', 'Deduct From Safety Bonuses']} rows={ticketRows} size={'sm'}/>
                    <QuickTable noMargin title='Paid Loans / Fine Ticket Damage' headers={['Type', 'Name', 'Amount']} rows={loanHistoryRows.concat(ticketHistoryRows)} size='sm'/>
                </div>

            </div>
            <Modal show={showLoanModal} onHide={() => setShowLoanModal(false)} size='lg'>
                <HRUserLoanEditor companyUserIdentifier={selectedEmployee.companyUserUid} selectedLoan={selectedLoan} handleLoanCrud={handleLoanCrud}/>
            </Modal>
            <Modal show={showTicketModal} onHide={() => setShowTicketModal(false)} size='lg'>
                <HRUserTicketEditor companyUserIdentifier={selectedEmployee.companyUserUid} selectedTicket={selectedTicket} handleTicketCrud={handleTicketCrud}/>
            </Modal>
        </LoadingWrapper>
    )
}