/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import '../HumanResources.css';
import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import CustomButton from '../../../components/CustomButton.js';
import TextAreaControl from '../../../components/TextAreaControl.js';
import RadioControl from '../../../components/RadioControl.js';
import { validateExistence, ValidationGroup } from '../../../validation.js';
import CustomControl from '../../../components/CustomControl.js';
import FileControl from '../../../components/FileControl.js';
import { Form } from 'react-bootstrap';
import { ApiRequest } from '../../../ApiManager.tsx';
//resignation, abandonment, retirement, terminateForCause, layoff, endOfContract

export default function HRTerminate({companyUserUid, user}) {
    const [isLoading, setIsLoading] = useState(false);
    const [terminationType, setTerminationType] = useState('');
    const [date, setDate] = useState('');
    const [synopsis, setSynopsis] = useState('');
    const [complaints, setComplaints] = useState('');
    const [disciplinaryRightups1, setDisciplinaryRightups1] = useState('');
    const [disciplinaryRightups2, setDisciplinaryRightup2] = useState('');
    const [textsScreenshots1, setTextsScreenshots1] = useState('');
    const [textsScreenshots2, setTextsScreenshots2] = useState('');
    const [terminationLetter, setTerminationLetter] = useState('');
    const [eligibleToRehire, setEligibleToRehire] = useState(false);
    const [deleteScheduleItems, setDeleteScheduleItems] = useState(true);
    const validationGroup = new ValidationGroup();

    function handleTerminate(){
        const notes = {
            synopsis: synopsis,
            complaints: complaints,
            disciplinaryRightups1: disciplinaryRightups1,
            disciplinaryRightups2: disciplinaryRightups2,
            textsScreenshots1: textsScreenshots1,
            textsScreenshots2: textsScreenshots2,
            terminationLetter: terminationLetter,
            eligibleToRehire: eligibleToRehire
        }
        const payObject = {
            companyUserUid: companyUserUid,
            title: terminationType,
            date: date,
            notes:JSON.stringify(notes)
        }

        new ApiRequest('hr', 'terminateEmployee', setIsLoading, () => window.location.reload(false)).withData({data: payObject, deleteScheduleItems: deleteScheduleItems}).send();
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{`Terminating ${user.firstName} ${user.lastName}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 6}}>
                <span>The employee will no longer show up in Scheduling or future payroll periods. The employee and all his or her data will not be deleted and will still be viewable in Human Resources.</span>
                <div style={{display: 'flex', flexWrap: 'wrap', margin: '16px 0', gap: 4}}>
                    <Form.Check style={{marginRight: 24}} type='switch' label='Delete Future Routes And Schedule Items' checked={deleteScheduleItems} onChange={()=>{setDeleteScheduleItems(!deleteScheduleItems)}} />
                    <Form.Check type='switch' label='Make Eligible To Rehire' checked={eligibleToRehire} onChange={()=>{setEligibleToRehire(!eligibleToRehire)}} />
                </div>
                <CustomControl 
                    title='Date Effective' 
                    type='date' 
                    value={date} 
                    onChange={(event) => {setDate(event.target.value)}} 
                    disabled={isLoading} 
                    validator={validationGroup.createValidator(() => validateExistence(date))}
                />
                <RadioControl 
                    inline
                    title='Termination Type' 
                    optionNames={['Resignation', 'Abandonment', 'Retirement', 'Terminate for Cause', 'Layoff', 'End of Contract']} 
                    optionValues={['resignation', 'abandonment', 'retirement', 'terminateForCause', 'layoff', 'endOfContract']} 
                    selection={terminationType} 
                    setSelection={setTerminationType} 
                    disabled={isLoading} 
                    validator={validationGroup.createValidator(() => validateExistence(terminationType))}
                />  
                <FileControl excludeDownloadButton={true} value={disciplinaryRightups1} companyUserUid={companyUserUid} label={'Disciplinary Write Up 1'} setValue={setDisciplinaryRightups1}/>
                <FileControl excludeDownloadButton={true} value={disciplinaryRightups2} companyUserUid={companyUserUid} label={'Disciplinary Write Up 2'} setValue={setDisciplinaryRightup2}/>
                <FileControl excludeDownloadButton={true} value={textsScreenshots1} companyUserUid={companyUserUid} label={'Text/Screenshot 1'} setValue={setTextsScreenshots1}/>
                <FileControl excludeDownloadButton={true} value={textsScreenshots2} companyUserUid={companyUserUid} label={'Text/Screenshot 2'} setValue={setTextsScreenshots2}/>
                <FileControl excludeDownloadButton={true} value={terminationLetter} companyUserUid={companyUserUid} label={'Termination Letter'} setValue={setTerminationLetter}/>
                <TextAreaControl 
                    label='Customer Complaints' 
                    value={complaints} 
                    setValue={(event) => {setComplaints(event.target.value)}} 
                    rows={4} 
                    max={65535} 
                    disabled={isLoading}
                />
                <TextAreaControl 
                    label='Termination Synopsis' 
                    value={synopsis} 
                    setValue={(event) => {setSynopsis(event.target.value)}} 
                    rows={4} 
                    max={65535} 
                    disabled={isLoading}
                    validator={validationGroup.createValidator(() => validateExistence(synopsis))}
                />
                 
            </Modal.Body>
            <Modal.Footer>
                <CustomButton label='Terminate Employee' isLoading={isLoading} disabled={!validationGroup.isValid()} onClick={handleTerminate}/>
            </Modal.Footer>
        </>
    )
}