/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './ScheduleMatch.css';
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import RouteTable from './RouteTable';
import ScheduleMatchDatePicker from './ScheduleMatchDatePicker';
import ScheduleMatchDeleteAllModal from './ScheduleMatchDeleteAllModal';
import ScheduleMatchTemplateCreator from './ScheduleMatchTemplateCreator';
import ScheduleMatchTemplateList from './ScheduleMatchTemplateList';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import { getStartOfWeek, naturalSort, parseAvailability } from '../../tools';
import { ScheduleMatchScheduleDownload } from './SchedulePDFGenerator/ScheduleMatchScheduleDownload';
import Cookies from 'universal-cookie';
import { Dropdown} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import DropdownItemWithCheck from '../../components/DropdownItemWithCheck';
import { ApiRequest } from '../../ApiManager.tsx';
import { debugResponse } from '../DebugView.js';

export default function ScheduleMatch({}){
    const [isLoading, setIsLoading] = useState(false);
    const [isImporting, setIsImporting] = useState(false);
    const [datePointer, setDatePointer] = useState(moment().day() == 6 ? moment() : moment().startOf('week').subtract(1, 'days'));
    const [selectedCsas, setSelectedCsas] = useState([]);
    const [users, setUsers] = useState([]);
    const [csas, setCsas] = useState([]);
    const [workAreas, setWorkAreas] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [routes, setRoutes] = useState([]);
    const [openShifts, setOpenShifts] = useState([]);
    const [scheduleItems, setScheduleItems] = useState([]);
    const [modalSwitch, setModalSwitch] = useState('none');
    const [allowMultipleCsas, setAllowMultipleCsas] = useState(false);
    const [routeCardFilter, setRouteCardFilter] = useState([true, true, true, false]);
    const [selectedTemplate, setSelectedTemplate] = useState(undefined);
    const [filterConflicts, setFilterConflicts] = useState(false);
    const cookieMonster = new Cookies();
    const limitViewToCSA = cookieMonster.get('userData').title != 'AO' && cookieMonster.get('restrictBcsToCsa') == '1';

    useEffect(() => {
        loadData();
    }, [])

    function loadData(startOfWeek = getStartOfWeek().format('YYYY-MM-DD')){
        new ApiRequest('scheduleMatch', 'get', setIsLoading, (response) => {
            // response = debugResponse;
            response.users.forEach(u => u.availability = parseAvailability(u.availability));

            for (let i = 0; i < response.users.length; i++) {
                const user = response.users[i];
                const payData = response.payData.find(r => r.companyUserIdentifier == user.companyUserUid);
                user.title = payData.title;
                user.employeeType = payData.employeeType;
                for (let j = i + 1; j < response.users.length; j++){
                    if (user.firstName == response.users[j].firstName && user.lastName == response.users[j].lastName) {
                        user.showMiddleName = true;
                        response.users[j].showMiddleName = true;
                    }
                }                    
            }

            const csaOfUser = response.csas.find(csa => csa.uid == new Cookies().get('userData').csaIdentifier);
            const sortedUsers = response.users.sort((a, b) => {
                const lastNameComparison = a.lastName.localeCompare(b.lastName);
                if (lastNameComparison != 0) {
                    return lastNameComparison;
                }
                const firstNameComparison = a.firstName.localeCompare(b.firstName);
                if (firstNameComparison != 0) {
                    return firstNameComparison;
                }
                return a.middleName.localeCompare(b.middleName);
            });

            const scheduleItems = response.scheduleItems.filter((item)=>item.scheduleType != 'rejectedRequest');

            validateAllRoutes(response.routes, scheduleItems, startOfWeek, response.users);
            setDatePointer(moment(startOfWeek));
            setCsas(response.csas);
            setWorkAreas(response.workAreas);
            setVehicles(response.vehicles);
            setSelectedCsas([csaOfUser ? csaOfUser : response.csas[0]]);
            setRoutes(response.routes);
            setOpenShifts(response.openShifts);
            setScheduleItems(scheduleItems);
            setUsers(sortedUsers);
        }).withData({startDate: startOfWeek, endDate: moment(startOfWeek).add(6, 'days').format('YYYY-MM-DD')}).withNoAlertOnSuccess().send();
    }

    // function handleImportTemplate(uid){
    //     new ApiRequest('scheduleMatch', 'importTemplate', setIsImporting, () => window.location.reload()).withData({uid: uid, weeks: datePointer}).withNoAlertOnSuccess().send();
    // }

    function validateAllRoutes(newRoutes, newScheduleItems, startOfWeek, usersArray = users) {
        for (let i = 0; i < 7; i++) {
            validateRoutesOnDay(newRoutes, newScheduleItems, moment(startOfWeek).add(i, 'days'), usersArray);
        }
        setIsLoading(false);
    }

    function validateRoutesOnDay(newRoutes, newScheduleItems, dateToValidate, usersArray) {
        const routesToValidate = newRoutes.filter(r => moment(r.date).format('M/D/YYYY') == moment(dateToValidate).format('M/D/YYYY'));    
        const scheduleItemsOnDay = newScheduleItems.filter(i => moment(i.date).format('M/D/YYYY') == moment(dateToValidate).format('M/D/YYYY'));

        for (let i = 0; i < routesToValidate.length; i++) { //remove all old conflicts
            routesToValidate[i].conflicts = [];
        }

        for (let i = 0; i < routesToValidate.length; i++) { //find conflicts
            //from other routes
            for (let j = i + 1; j < routesToValidate.length; j++) {
                //Vehicle is scheduled twice on the same day
                if (routesToValidate[i].vehicleIdentifier == routesToValidate[j].vehicleIdentifier && routesToValidate[i].vehicleIdentifier != null) {
                    routesToValidate[i].conflicts.push({
                        severity: 3,
                        message: 'Vehicle is scheduled twice on the same day.'
                    });
                    routesToValidate[j].conflicts.push({
                        severity: 3,
                        message: 'Vehicle is scheduled twice on the same day.'
                    });
                }
                //Driver is scheduled twice on the same day
                let foundDrivers = [parseInt(routesToValidate[i].companyUserIdentifier), parseInt(routesToValidate[j].companyUserIdentifier)];
                if (routesToValidate[i].secondaryDriver) {
                    foundDrivers.push(routesToValidate[i].secondaryDriver)
                }
                if (routesToValidate[j].secondaryDriver) {
                    foundDrivers.push(routesToValidate[j].secondaryDriver)
                }
                for (let k = foundDrivers.length - 1; k > 0; k--) {
                    const d = parseInt(foundDrivers.pop());
                    if (foundDrivers.includes(d)) {
                        routesToValidate[i].conflicts.push({
                            severity: 3,
                            message: 'Driver is scheduled twice on the same day.'
                        });
                        routesToValidate[j].conflicts.push({
                            severity: 3,
                            message: 'Driver is scheduled twice on the same day.'
                        });
                        break;
                    }
                }
            }
            
            //from schedule items
            for (let j = 0; j < scheduleItemsOnDay.length; j++) {
                if ((routesToValidate[i].companyUserIdentifier == scheduleItemsOnDay[j].companyUserIdentifier || (routesToValidate[i].secondaryDriver && routesToValidate.secondaryDriver == scheduleItemsOnDay[j].companyUserIdentifier)) && scheduleItemsOnDay[j].scheduleType != 'paidTraining' && scheduleItemsOnDay[j].scheduleType != 'managerOnDuty' && scheduleItemsOnDay[j].scheduleType != 'rejectedRequest' && scheduleItemsOnDay[j].scheduleType != 'auxiliaryWorker') {
                    let conflictMessage;
                    let severity;
                    switch (scheduleItemsOnDay[j].scheduleType) {
                        case 'requestOff':
                            conflictMessage = 'Driver requested off this day.';
                            severity = 3;
                            break;
                        case 'callOff':
                            conflictMessage = 'Driver called off on this day.';
                            severity = 3;
                            break;
                        case 'pto':
                            conflictMessage = 'Driver is on PTO.';
                            severity = 3;
                            break;
                        case 'dayOff':
                            conflictMessage = 'Driver has the day off.';
                            severity = 3;
                            break;
                        case 'noShow':
                            conflictMessage = 'Driver was a no show today.';
                            severity = 3;
                            break;
                    }
                    
                    routesToValidate[i].conflicts.push({
                        severity: severity,
                        message: conflictMessage
                    });  
                }
            }
            
            //from availablity
            const dayIndex = moment(dateToValidate).day() == 6 ? 0 : moment(dateToValidate).day() + 1;
            const usersForRoute = [usersArray.find(u => u.companyUserUid == routesToValidate[i].companyUserIdentifier)];
            if (routesToValidate[i].secondaryDriver && parseInt(routesToValidate[i].secondaryDriver) > 0) {
                usersForRoute.push(usersArray.find(u => u.companyUserUid == routesToValidate[i].secondaryDriver));
            }

            // console.log('problem route', routesToValidate[i])
            // console.log('users for route', usersForRoute)
            // console.log('current route i companyuserIdentifier', routesToValidate[i].companyUserIdentifier)
            // console.log('showing up as undefined when it shouldnt', usersArray.find(u => u.companyUserUid == routesToValidate[i].companyUserIdentifier))
            // console.log('this console log randomly fixes it', usersArray.find(u => u.companyUserUid === 593))
            // console.log('all users', usersArray);

            for (const u of usersForRoute) {
                if (!u.availability[dayIndex]) {
                    routesToValidate[i].conflicts.push({
                        severity: 1,
                        message: 'Driver\'s availability does not include ' + moment(dateToValidate).format('dddd') + 's.'
                    })
                }
                if(u.lightDuty){
                    routesToValidate[i].conflicts.push({
                        severity: 1,
                        message: 'Driver is currently on light duty.'
                    })
                }
                if(u.onLoa == '1'){
                    routesToValidate[i].conflicts.push({
                        severity: 3,
                        message: 'Driver is currently on a leave of absence.'
                    })
                }

                if(u.companyUserIsEnabled == 0 && moment(dateToValidate).isSameOrAfter(moment(), 'day')){
                    routesToValidate[i].conflicts.push({
                        severity: 3,
                        message: 'Driver is terminated.'
                    })
                }
            }
        }
    }

    function handleRouteCardFilter(indexToToggle) {
        const newArray = Array.from(routeCardFilter);
        newArray[indexToToggle] = !newArray[indexToToggle];
        setRouteCardFilter(newArray);
    }

    function handleCsaSelection(csa) {
        if (allowMultipleCsas) {
            let newArray = Array.from(selectedCsas);
            if (newArray.find(element => element.uid == csa.uid)) {
                newArray = newArray.filter(element => element.uid != csa.uid);
            } else {
                newArray.push(csa);
            }
            setSelectedCsas(newArray);
        } else {
            const newArray = [csa];
            setSelectedCsas(newArray);
        }
    }

    function handleAllowMultipleCSAs() {
        if (allowMultipleCsas) {
            setSelectedCsas(selectedCsas.slice(0, 1));
        }
        setAllowMultipleCsas(!allowMultipleCsas)
    }

    function handleSetOpenShifts(type, value){
        let newArray = Array.from(openShifts);
        if (type == 'create') {
            newArray.push(value);
        } else if (type == 'update') {
            newArray = newArray.filter(os => os.uid != value.uid);
            setRoutes(routes.filter(r=>r.uid != value.uid));
            newArray.push(value);
        } else if (type == 'delete') {
            newArray = newArray.filter(os => os.uid != value.uid);
        }
        setOpenShifts(newArray);
    }

    function handleSetRoutes(type, value) {
        let newArray = Array.from(routes);
        if (type == 'create') {
            newArray.push(value);
        } else if (type == 'update') {
            newArray = newArray.filter(r => r.uid != value.uid);
            setOpenShifts(openShifts.filter(r=>r.uid != value.uid))
            newArray.push(value);
        } else if (type == 'delete') {
            newArray = newArray.filter(r => r.uid != value.uid);
        }

        validateRoutesOnDay(newArray, scheduleItems, value.date, users);
        setRoutes(newArray);
    }

    function handleSetScheduleItems(type, value) {
        let newArray = Array.from(scheduleItems);
        if (type == 'create') {
            newArray.push(value);
        } else if (type == 'update') {
            newArray = newArray.filter(r => r.uid != value.uid);
            newArray.push(value);
        } else if (type == 'delete') {
            newArray = newArray.filter(r => r.uid != value.uid);
        }
        const date = value.date;
        const endDate = value.endDate ? value.endDate : value.date;
        if (moment(date).isBetween(datePointer, moment(datePointer).add(6, 'days'), 'days', '[]') || 
            moment(endDate).isBetween(datePointer, moment(datePointer).add(6, 'days'), 'days', '[]')) {
            const numberOfDays = (moment(endDate).diff(moment(date), 'days') + 1);
            for (let i = 0; i < numberOfDays; i++) {
                validateRoutesOnDay(routes, newArray, moment(value.date).add(i, 'days'), users);
            }
        }
        setScheduleItems(newArray)
    }

    function handleDeleteAllRoutes() {
        setRoutes([]);
        setOpenShifts([]);
    }
    
    function hideModal() {
       setModalSwitch('none');
    }

    const weekOfMonth = (Math.floor((parseInt(datePointer.format('D')) + (moment(datePointer).startOf('month').day() == 6 ? 0 : moment(datePointer).startOf('month').day() + 1)) / 7) + 1);

    const selectedCsaIds = selectedCsas.map(csa => csa.uid);
    const workAreasInCsa = workAreas.filter((wa) => {
        return selectedCsaIds.includes(parseInt(wa.csaIdentifier));
    });

    const vehiclesInCsa = vehicles.filter((v) => {
        return selectedCsaIds.includes(parseInt(v.csaIdentifier));
    });
    const routesInCsa = routes.filter((r) => {
        return workAreasInCsa.find(wa => wa.uid == r.workAreaIdentifier);
    });
    const openShiftsInCsa = openShifts.filter((os) => {
        return workAreasInCsa.find(wa => wa.uid == os.workAreaIdentifier);
    });

    //////////////////////
    /// HTML Elements
    //////////////////////

    const datePickerCard = (
        <Button variant={'outline-primary'} style={{padding: '2px 10px'}} onClick={() => setModalSwitch('datePicker')}>
            {datePointer.format('MMMM YYYY') + ' | Week ' + weekOfMonth}
        </Button>
    );

    const csaDropdownMenu = (
        <Dropdown autoClose='outside'>
            <Dropdown.Toggle variant="outline-primary">
            {selectedCsas.length == 0 ? 'CSA' : selectedCsas.length > 1 ? `${selectedCsas[0].csaName} +${selectedCsas.length - 1}` : selectedCsas[0].csaName}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {naturalSort(csas.filter(c=> c.isActive == 1), 'csaName').map((csa) => {
                return <DropdownItemWithCheck key={csa.uid} label={csa.csaName} active={selectedCsas.find(element => element.uid == csa.uid) ? true : false} onClick={() => handleCsaSelection(csa)} />
                })}
            </Dropdown.Menu>
        </Dropdown>
    )
            
    const routeCardInfoCard = (
        <Dropdown autoClose={allowMultipleCsas ? 'outside' : true}>
            <Dropdown.Toggle variant="outline-primary">
                Route Card Info
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <DropdownItemWithCheck label='Driver' active={routeCardFilter[0]} onClick={() => handleRouteCardFilter(0)}/>
                <DropdownItemWithCheck label='Secondary' active={routeCardFilter[1]} onClick={() => handleRouteCardFilter(1)}/>
                <DropdownItemWithCheck label='Time Scheduled' active={routeCardFilter[2]} onClick={() => handleRouteCardFilter(2)}/>
                <DropdownItemWithCheck label='Vehicle' active={routeCardFilter[3]} onClick={() => handleRouteCardFilter(3)}/>
            </Dropdown.Menu>
        </Dropdown>
    );

    const toolsDropdownMenu = (
        <Dropdown>
            <Dropdown.Toggle variant="outline-primary">
                <FontAwesomeIcon icon={faBars}/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Header>Templates</Dropdown.Header>
                <Dropdown.Item onClick={() => setModalSwitch('templateCreator')}>Create Template</Dropdown.Item>
                <Dropdown.Item onClick={() => setModalSwitch('templateList')}>View Templates</Dropdown.Item>
                <Dropdown.Divider/>
                <Dropdown.Header>Routes</Dropdown.Header>
                <Dropdown.Item onClick={() => setModalSwitch('deleteAllRoutes')}>Delete All Routes</Dropdown.Item>
                <DropdownItemWithCheck label='Only Show Conflicts' active={filterConflicts} onClick={() => setFilterConflicts(!filterConflicts)}/>
                <Dropdown.Divider/> 
                <Dropdown.Header>Download</Dropdown.Header>
                <Dropdown.Item onClick={() => setModalSwitch('downloadSchedule')}>Download Schedule</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );

    return (
        <div className='page-wrapper' style={{flexDirection: 'column', padding: 12}}>
            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 16}}>
                <div style={{display: 'flex', gap: 4, flexWrap: 'wrap'}}>
                    {datePickerCard}
                    {csaDropdownMenu}
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: 'var(--bs-primary)', border: '1px solid var(--bs-primary', borderRadius: 6, padding: '0 12px'}}>
                        <Form.Check type='switch' label='Enable multiple CSA selection' value={allowMultipleCsas} onChange={handleAllowMultipleCSAs}/>
                    </div>
                </div>
                <div style={{display: 'flex', gap: 8}}>
                    {routeCardInfoCard}
                    {toolsDropdownMenu}
                </div>
            </div>
            {isLoading ? 
                <div style={{width: '100%', flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Spinner style={{width: 100, height: 100}} role='status' animation='border'/>
                </div>
                
                :
                <RouteTable 
                    datePointer={datePointer} 
                    routes={routesInCsa} 
                    handleSetRoutes={handleSetRoutes} 
                    handleSetOpenShifts={handleSetOpenShifts}
                    openShifts={openShiftsInCsa}
                    users={users} 
                    workAreas={workAreasInCsa} 
                    scheduleItems={scheduleItems} 
                    handleSetScheduleItems={handleSetScheduleItems} 
                    selectedCsas={selectedCsas} 
                    csas={csas} 
                    vehicles={vehiclesInCsa} 
                    routeCardFilter={routeCardFilter}
                    vehiclesNotFiltered={vehicles}
                    filterConflicts={filterConflicts}
                />
            }    
            <Modal show={modalSwitch == 'datePicker'} onHide={hideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Week</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ScheduleMatchDatePicker 
                        onSubmit={loadData} 
                        hideModal={hideModal} 
                        date={datePointer}
                    />
                </Modal.Body>
            </Modal>
            <Modal show={modalSwitch == 'deleteAllRoutes'} onHide={hideModal} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>{'Delete all routes for the week of ' + datePointer.format('MMM D') + ' - ' + moment(datePointer).add(6, 'days').format('MMM D') + '?'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ScheduleMatchDeleteAllModal 
                        handleDeleteAllRoutes={handleDeleteAllRoutes}
                        hideModal={hideModal} 
                        startOfWeek={datePointer} 
                    />
                </Modal.Body>
            </Modal>
            <Modal show={modalSwitch == 'templateCreator'} size='xl' backdrop='static'>
                <ScheduleMatchTemplateCreator 
                    csas={csas} 
                    users={users} 
                    vehicles={vehicles} 
                    workAreas={workAreas} 
                    hideModal={hideModal} 
                    selectedTemplate={selectedTemplate}
                    deselectTemplate={() => setSelectedTemplate(undefined)} 
                    limitViewToCSA={limitViewToCSA}
                />
            </Modal>
            <Modal show={modalSwitch == 'templateList'} size='lg' onHide={hideModal}>
                <ScheduleMatchTemplateList 
                    setModalSwitch={(value) => setModalSwitch(value)} 
                    selectedTemplate={selectedTemplate} 
                    setSelectedTemplate={(value) => setSelectedTemplate(value)}
                />
            </Modal>
            <Modal show={modalSwitch == 'downloadSchedule'} fullscreen onHide={hideModal}>
                <ScheduleMatchScheduleDownload/>
            </Modal>
        </div>
    );
}