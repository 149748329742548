/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { PDFViewer } from "@react-pdf/renderer";
import PDFTimesheet from "./PDFTimesheet";
import DateRangeSelector from "../../../components/DateRangeSelector";
import { Button, Dropdown } from "react-bootstrap";
import PageSpinner from "../../../components/PageSpinner";
import CustomButton from "../../../components/CustomButton";
import { dateRangeToString } from "../../../tools";
import { ApiRequest } from "../../../ApiManager.tsx";

export default function TimesheetReport() {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false)  
    const [data, setData] = useState(undefined);
    const [showModal, setshowModal] = useState('');
    const [scheduleData, setScheduleData] = useState(undefined);
    const [routeData, setRouteData] = useState(undefined);
    const [vehicleData, setVehicleData] = useState(undefined);
    const [workAreaData, setWorkAreaData] = useState(undefined);
    const [csas, setCsas] = useState([]);
    const [selectedCsas, setSelectedCsas] = useState([]);
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);

    useEffect(() => {
        loadData();
    }, []);

    function loadData(){
        new ApiRequest('scheduleMatch', 'getCompanyResources', setIsLoading, (response) => {
            setVehicleData(response.vehicles);
            setWorkAreaData(response.workAreas);
            setCsas(response.csas);
            setSelectedCsas(response.csas);
        }).withNoAlertOnSuccess().send();
    }

    function handleGetTimesheet(){
        new ApiRequest('reports', 'getTimesheetReport', setIsSubmitting, (response) => {
            setData({
                csas: selectedCsas,
                users: response.users,
                timesheets: response.data,
                startDate: startDate,
                endDate: endDate
            });
            setScheduleData(response.scheduleItems);
            setRouteData(response.routes)
        }).withData({startDate: startDate, endDate: endDate}).withNoAlertOnSuccess().send();
    }

    function handleSelectCsa(csa){
        if (selectedCsas.find(c => c.uid == csa.uid)) {
            setSelectedCsas(selectedCsas.filter(c => c.uid != csa.uid));
        } else {
            setSelectedCsas([...selectedCsas, csa])
        }
    }

    const csaElements = csas.map((csa) => {
        return (
            <Dropdown.Item key={csa.uid} active={selectedCsas.find(c => c.uid == csa.uid)} onClick={() => {handleSelectCsa(csa)}}>{csa.csaName}</Dropdown.Item>
        )
    })

    return (
        <>
            <Modal.Header closeButton>
                { isLoading ? <PageSpinner/> :
                    <div style={{display: 'flex', gap: 12, alignItems: 'center'}}>
                        <Button variant={'outline-primary'} onClick={()=>setshowModal(true)}>{!startDate || !endDate ? 'Select Date Range' : dateRangeToString(startDate, endDate)}</Button>
                        <DateRangeSelector show={showModal} onHide={()=>setshowModal(false)} handleSetDateRange={(startDate, endDate) => {setStartDate(startDate); setEndDate(endDate)}} presetOptions={['pastYear','pastMonth','pastWeek']}/>  
                        <Dropdown autoClose='outside'>
                            <Dropdown.Toggle variant="outline-primary">Filter By CSA</Dropdown.Toggle>
                            <Dropdown.Menu>
                                {csaElements}
                            </Dropdown.Menu>
                        </Dropdown>
                        <CustomButton style={{fontWeight: 'bold'}} disabled={!startDate || !endDate} label={'Generate Timesheet Report'} onClick={handleGetTimesheet} isLoading={isSubmitting}/>
                    </div>
                }
            </Modal.Header>
            <Modal.Body>
                {!isSubmitting && data &&
                <PDFViewer style={{width: '100%', height: '99%'}}>
                    <PDFTimesheet data={data} scheduleData={scheduleData} routeData={routeData} vehicleData={vehicleData} workAreaData={workAreaData}/>
                </PDFViewer>  
            }
            </Modal.Body>
        </>
    )
}