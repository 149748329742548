/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import CustomControl from "../../../../../components/CustomControl";
import Modal from 'react-bootstrap/Modal';
import RadioControl from "../../../../../components/RadioControl";
import { validateExistence, ValidationGroup, validateGreaterThanZero } from "../../../../../validation";
import SwitchControl from "../../../../../components/SwitchControl";
import { Alert, Col, Container, Row } from "react-bootstrap";
import DBonus from "../../../Models/DBonus";

export default function PayrollDBonusesModal({handleAddDBonus}) {
    const [amount, setAmount] = useState('');
    const [type, setType] = useState('');
    const [label, setLabel] = useState('');
    const [isReoccuring, setIsReoccuring] = useState(false);

    const [applyToAll, setApplyToAll] = useState(false);

    const validationGroup = new ValidationGroup();

    const handleSetType = (value) => {
        setLabel(value);
        setType(value);
    }

    const handleSetAmount = (value) => {
        setAmount(value.replace('-', ''));
    }

    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }
        if (validationGroup.isValid()) {
            handleAddDBonus(new DBonus(type, label, applyToAll ? '' : amount, isReoccuring), applyToAll);
        }
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Adding Discretionary Bonus</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert variant="warning">Discretionary Bonuses are not based in merit or expectation for service provided. Timing of the bonus is not known in advance (ex: Christmas bonus).</Alert>
                <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                    <Container>
                        <Row>
                            <Col xl={12} style={{marginBottom: 8}}>
                                <RadioControl validator={validationGroup.createValidator(() => validateExistence(type))} title='Type' inline selection={type} setSelection={handleSetType} optionNames={DBonus.dBonusTypes} optionValues={DBonus.dBonusTypes}/>
                            </Col>
                            { !applyToAll &&
                                <CustomControl xl={12} validator={validationGroup.createValidator(() => validateGreaterThanZero(amount))} type='number' min={0} value={amount} onChange={(event) => {handleSetAmount(event.target.value)}} title='Amount'/>
                            }
                        </Row>
                    </Container>
                    <PayrollAdditionalPayModalTooltip boldText='Reoccuring' text={' - Next payroll period, this Discretionary Bonus will be automatically added to the employee(s)'}/>                
                    <button style={{width: 0, height: 0, padding: 0, margin: -7, opacity: 0}} type="submit" className='button-primary'/>
                </form>
            </Modal.Body>
            <Modal.Footer style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Form.Check type='switch' checked={applyToAll} onChange={() => {setApplyToAll(!applyToAll)}} label='Add bonus to all employees'/>
                <Button style={{color: 'white'}} disabled={!validationGroup.isValid()} onClick={handleSubmit}>Submit</Button>
                
            </Modal.Footer>
        </>
    )
}

function PayrollAdditionalPayModalTooltip({boldText, text}) {
    return (
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 12}}>
            <FontAwesomeIcon style={{color: 'var(--bs-primary)'}} icon={faInfoCircle}/>
            <div>
                <span style={{fontWeight: 'bold'}}>{boldText + ' '}</span>
                <span>{text}</span>
            </div>
        </div>
    )
}