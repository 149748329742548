/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import SwitchControl from "../../components/SwitchControl";
import { ApiRequest } from "../../ApiManager.tsx";
import LoadingWrapper from "../../components/LoadingWrapper";
import CustomButton from "../../components/CustomButton";

export default function NotificationSettingsInfo({title}){
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [settings, setSettings] = useState(undefined);
    const [originalSettings, setOriginalSettings] = useState(undefined);

    useEffect(() => {
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('notificationSettings', 'get', setIsLoading, (response) => {
            setSettings(response.notificationSettings);
            setOriginalSettings(response.notificationSettings);
        }).withNoAlertOnSuccess().send();
    }

    function handleSaveSettings(){
        new ApiRequest('notificationSettings', 'update', setIsSubmitting, () => setOriginalSettings(structuredClone(settings))).withData({notificationSettings: settings}).send();
    }

    function handleSetSettings(key, value) {
        const newSettings = structuredClone(settings);
        newSettings[key] = value;
        setSettings(newSettings);
    }

    const content = !settings ? '' : (
        <div style={{display: 'flex', flexDirection: 'column', gap: 16, padding: 6}}>
            <div style={{padding:5}}>
                <p style={{textAlign: 'center', margin: 0, fontSize: 24, fontWeight: 'bold', padding:10}}>Notification Settings</p>
            </div>
            <SwitchControl
                title='Receive notifications for time off requests'
                originalValue={originalSettings.timeOff}
                value={settings.timeOff} 
                setValue={(value) => handleSetSettings('timeOff', value)}
            />
            
            {settings.timeOff == 1 &&
                <div style={{width: '100%', paddingLeft: 24, borderLeft: '1px solid lightgray'}}>
                    <SwitchControl
                        title={'Only receive time off notifications from my CSA'}
                        originalValue={originalSettings.onlyMyCsaTimeOff}
                        value={settings.onlyMyCsaTimeOff} 
                        setValue={(value) => handleSetSettings('onlyMyCsaTimeOff', value)}
                     />
                    <SwitchControl
                        title='Only receive time off notifications from BCs'
                        originalValue={originalSettings.onlyBcTimeOff}
                        value={settings.onlyBcTimeOff} 
                        setValue={(value) => handleSetSettings('onlyBcTimeOff', value)}
                    />
                </div>
            }
            <SwitchControl
                title='Receive notifications when payroll is ready to submit'
                originalValue={originalSettings.payrollReady}
                value={settings.payrollReady} 
                setValue={(value) => handleSetSettings('payrollReady', value)}
            />
            {title == 'AO' &&<SwitchControl
                title='Receive notifcations for when BC has submitted payroll'
                originalValue={originalSettings.bcSubmittedPayroll}
                value={settings.bcSubmittedPayroll} 
                setValue={(value) => handleSetSettings('bcSubmittedPayroll', value)}
            />}
            <SwitchControl
                title='Receive notifications for incidents, injuries or accidents'
                originalValue={originalSettings.incidents}
                value={settings.incidents} 
                setValue={(value) => handleSetSettings('incidents', value)}
            />

            <SwitchControl 
                title='Receive notifications when an employee is hired or terminated'
                originalValue={originalSettings.newHireAndTermination} 
                value={settings.newHireAndTermination}
                setValue={(value) => handleSetSettings('newHireAndTermination', value)}
            />
            <CustomButton label={'Save Changes'} isLoading={isSubmitting} variant={'outline-primary'} style={{maxWidth: 400}} onClick={handleSaveSettings}/>
        </div>
    )

    return (
        <LoadingWrapper isLoading={isLoading}>
            {content}
        </LoadingWrapper>
    )
}