/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import { Validator, validateGreaterThanZero } from "../../../../../validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faPlus } from "@fortawesome/free-solid-svg-icons";
import { validateDecimal } from "../../../payrollTools";
import CustomToolTip from "../../../../../components/CustomToolTip";
import PayrollMoneyCustomControl from "../PayrollMoneyCustomControl";
import QuickTable from "../../../../../components/QuickTable";
import PayrollDeductionModal from "./PayrollDeductionModal";


export default function PayrollEmployeeDeductionsEditor({entry, handleSet, handleAddDeductionToAllEntries}) {
    const [showModal, setShowModal] = useState(false);

    const addChildSupport = () => {
        const newArr = Array.from(entry.childSupport);
        newArr.push('0');
        handleSet(entry.companyUserIdentifier, 'childSupport', newArr);
    }
    const handleSetChildSupport = (value, index) => {
        const newArr = Array.from(entry.childSupport);
        newArr[index] = value;
        handleSet(entry.companyUserIdentifier, 'childSupport', newArr);
    }
    const removeChildSupport = (index) => {
        const newArr = [...entry.childSupport]; 
        newArr.splice(index, 1);
        handleSet(entry.companyUserIdentifier, 'childSupport', newArr);
    };

    
    const handleAddDeduction = (deduction, applyToAll) => {
        if (applyToAll) {
            handleAddDeductionToAllEntries(deduction);
        } else {
            const newArr = Array.from(entry.deductions);
            newArr.push(deduction);
            handleSet(entry.companyUserIdentifier, 'deductions', newArr);
        }
        setShowModal(false);
    }
    const handleSetDeduction = (id, key, value) => {
        const newArr = Array.from(entry.deductions);
        newArr.find(r => r.id === id)[key] = value;
        handleSet(entry.companyUserIdentifier, 'deductions', newArr);
    }
    const removeDeduction = (id) => {
        const newArr = entry.deductions.filter((element) => {
            return element.id !== id;
        });
        handleSet(entry.companyUserIdentifier, 'deductions', newArr);
    }

    const childSupportRows = entry.childSupport.length > 0 ? entry.childSupport.map((value, index)=>{
        const validator = new Validator(() => validateGreaterThanZero(value));
        return (
            <tr key={index}>
                <td>Child Support #{index + 1}</td>
                <td>
                    <InputGroup>
                        <InputGroup.Text style={{padding: '2px 6px 2px 6px'}}>$</InputGroup.Text>
                        <Form.Control 
                            isInvalid={!validator.isValid()} 
                            style={{padding: '2px 6px 2px 6px'}} 
                            type='number' 
                            min={0} 
                            value={value} 
                            placeholder='Amount' 
                            onChange={(event)=>{handleSetChildSupport(event.target.value, index)}}
                        />
                        { validator ? <Form.Control.Feedback type='invalid'>{validator.getInvalidMessage()}</Form.Control.Feedback> : ''}
                    </InputGroup>
                </td>
                <td>
                    <Button style={{color: 'white', padding: '2px 6px 2px 6px', width: '100%'}} onClick={() => removeChildSupport(index)}>
                        <FontAwesomeIcon icon={faTrashCan}/>
                    </Button>
                </td>
            </tr>
        )
    }) : [];
    
    const totalChildSupport = entry.childSupport.reduce((prev, curr) => prev + validateDecimal(curr), 0);

    const deductionRows = entry.deductions.map((deduction) => {
        const validator = new Validator(() => validateGreaterThanZero(deduction.amount));
        return (
            <tr key={deduction.id}>
                <td>{deduction.label}</td>
                <td>
                    <InputGroup>
                        {deduction.label !== '401K (% of Gross)' && deduction.label !== 'Other (% of Gross)' ? <InputGroup.Text style={{padding: '2px 6px 2px 6px'}}>$</InputGroup.Text> : <InputGroup.Text style={{padding: '2px 6px 2px 6px'}}>%</InputGroup.Text> }
                        <Form.Control isInvalid={!validator.isValid()} style={{padding: '2px 6px 2px 6px'}} type='number' min={0} value={deduction.amount} placeholder='Amount' onChange={(event) => {handleSetDeduction(deduction.id, 'amount', event.target.value.replace('-', ''))}}/>
                        { validator ? <Form.Control.Feedback type='invalid'>{validator.getInvalidMessage()}</Form.Control.Feedback> : ''}
                    </InputGroup>
                </td>
                <td>
                    <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
                        <Form.Check disabled={!deduction.canBeReoccuring} type='switch' checked={deduction.isReoccuring} onChange={() => {handleSetDeduction(deduction.id, 'isReoccuring', !deduction.isReoccuring)}}/>
                        { deduction.canBeReoccuring ?
                            <CustomToolTip text={'Next payroll period, this ' + 'Deduction' + ' will be automatically added to this employee'}/>
                            :
                            <CustomToolTip text={'This ' + 'Deduction' + ' was automatically generated from this employee\'s pay'}/>
                        }
                    </div>
                </td>
                <td>
                    <Button style={{color: 'white', padding: '2px 6px 2px 6px', width: '100%'}} onClick={() => removeDeduction(deduction.id)}>
                        <FontAwesomeIcon icon={faTrashCan}/>
                    </Button>
                </td>
            </tr>
        )
    });

    return (
        <Card>
            <Card.Body>
                <Card.Title>Deductions</Card.Title>
                <Row>
                    <PayrollMoneyCustomControl lg={6} xxl={3} label='Medical Insurance' value={entry.medical} onChange={(event) => {handleSet(entry.companyUserIdentifier, 'medical', event.target.value)}}/>
                    <PayrollMoneyCustomControl lg={6} xxl={3} label='Dental Insurance' value={entry.dental} onChange={(event) => {handleSet(entry.companyUserIdentifier, 'dental', event.target.value)}}/>
                    <PayrollMoneyCustomControl lg={6} xxl={3} label='Vision Insurance' value={entry.vision} onChange={(event) => {handleSet(entry.companyUserIdentifier, 'vision', event.target.value)}}/>
                    <PayrollMoneyCustomControl readOnly={true} lg={6} xxl={3} label='Total Child Support' value={totalChildSupport} onChange={(event) => {handleSet(entry.companyUserIdentifier, 'childSupport', event.target.value)}}/>
                </Row>
                <div style={{display:'flex'}}>
                    <div style={{width:'50%', paddingTop:8, display:'flex', justifyContent:'center'}}>
                        <span style={{marginRight: 8}}>Additional Deductions</span>
                        <Button style={{marginBottom: 8, borderRadius: 14, padding: '0px 5px 0px 5px'}} onClick={() => {setShowModal(true)}}>
                            <FontAwesomeIcon icon={faPlus} style={{color: 'white'}}/>
                        </Button>
                    </div>
                    <div style={{width:'50%', paddingTop:8, display:'flex', justifyContent:'center'}}>
                        <span style={{marginRight: 8}}>Child Support</span>
                        <Button style={{marginBottom: 8, borderRadius: 14, padding: '0px 5px 0px 5px'}} onClick={addChildSupport}>
                            <FontAwesomeIcon icon={faPlus} style={{color: 'white'}}/>
                        </Button>
                    </div>
                </div>
                <div style={{display:'flex'}}>
                    <div style={{width:'50%', padding:8}}>
                        { deductionRows.length > 0 &&
                            <QuickTable title='Additional Deductions' headers={['Deduction', 'Amount', 'Reoccuring?', '']} widths={[ null, null, 100, 40]} rows={deductionRows} size={'sm'}/>
                        }
                    </div>
                    <div style={{width:'50%', padding:8}}>
                        {childSupportRows.length > 0 &&
                            <QuickTable title='Child Support' headers={['Deduction', 'Amount', '']} widths={[null,300,40]} rows={childSupportRows} size={'sm'}/>
                        }
                    </div>
                </div>
            </Card.Body>
            <Modal show={showModal} onHide={() => {setShowModal(false)}} centered size='xl'>
                <PayrollDeductionModal handleAddDeduction={handleAddDeduction}/>
            </Modal>
        </Card>
    )
}

