/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import HRHire from '../HRHire/HRHire';
import { Dropdown } from 'react-bootstrap';
import { titleDictionary, naturalSort, getNameMatches } from '../../../tools';
import SearchBar from '../../../components/SearchBar';
import HRHireCSV from '../HRHire/HRHireCSV/HRHireCSV';
import HRFedexNameMatch from './HRFedexNameMatch.js';
import Cookies from 'universal-cookie';
import DropdownItemWithCheck from '../../../components/DropdownItemWithCheck';
import HumanResourcesUserRow from './HumanResourcesUserRow.js';
import HRHubDateSelectionModal from './HRHubDateSelectionModal.js';
import QuickTable from '../../../components/QuickTable.js';
import { ApiRequest } from '../../../ApiManager.tsx';

export default function HRHub({setSelectedEmployee, selectedDate, setSelectedDate, users, csas, timeData, setTimeData}) {

    const [search, setSearch] = useState('');
    const [showDisabledUsers, setShowDisabledUsers] = useState(false);
    const [selectedCsas, setSelectedCsas] = useState([]);
    const [selectedTitles, setSelectedTitles] = useState(['Driver','AVP Driver', 'Jumper', 'Admin', 'AO', 'BC']);
    const [filterByScheduled, setFilterByScheduled] = useState(false);
    const [filterByUnscheduled, setFilterByUnscheduled] = useState(false);
    const [sortByScheduled, setSortByScheduled] = useState(false);
    const [sortByUnscheduled, setSortByUnscheduled] = useState(false);
    const [sortByClockedIn, setSortByClockedIn] = useState(false);
    const [sortByClockedOut, setSortByClockedOut] = useState(false);
    const [modalSwitch, setModalSwitch] = useState('');
    const [showInactiveCsa, setShowInactiveCsa] = useState(false);
    const permissions = new Cookies().get('permissions');
    const isRestrictedBc = new Cookies().get('userData').title === 'BC' && new Cookies().get('restrictBcsToCsa') == '1';

    useEffect(() => {
        const intervalId = setInterval(handleRefreshTimeData, 20000);
        return () => {
            clearInterval(intervalId);
        }
    }, [])

    function handleRefreshTimeData() {
        new ApiRequest('hr', 'getTimeData', () => {}, (response) => {
            setTimeData(response.timeData);
        }).withData({date: selectedDate}).sendInBackground();
    }

    useEffect(() => {
        setModalSwitch('');
    }, [selectedDate]);

    useEffect(() => {
        setSelectedCsas(csas.filter(c => c.isActive == 1).map(c => c.uid));
    }, [csas])

    function handleSetUserTimeDataForSelectedDate(companyUserIdentifier, newTimeData) {
        const newArray = [
            ...timeData.filter((t) => {return !moment(t.inTime).isSame(moment(selectedDate), 'day') || t.companyUserIdentifier != companyUserIdentifier}),
            ...newTimeData
        ];
        setTimeData(newArray);
    }

    function handleSelectCsa(uid) {
        let newArray = Array.from(selectedCsas);
        if (newArray.includes(uid)) {
            newArray = newArray.filter(e => e != uid);
        } else {
            newArray.push(uid);
        }
        setSelectedCsas(newArray);
    }

    function handleSelectTitle(value) {
        let newArray = Array.from(selectedTitles);
        if (newArray.includes(value)) {
            newArray = newArray.filter(e => e != value);
        } else {
            newArray.push(value);
        }
        setSelectedTitles(newArray);
    }
    const timeDataOnSelectedDate = timeData.filter((t) => moment(t.inTime).isSame(moment(selectedDate), 'day'));
    const totalWeeklyHoursWorked = timeData.reduce((prev, curr) => {
        return curr.outTime ? (prev + moment(curr.outTime).diff(moment(curr.inTime), 'hours', true)) : prev;
    }, 0)

    const filteredUsers = users.filter((u) => {
        const matchesSearch = getNameMatches(u, search);
        const inCSA = selectedCsas.includes(parseInt(u.csaIdentifier)) || (showInactiveCsa && csas.find(c=> u.csaIdentifier == c.uid).isActive == 0);
        const titleIsIncluded = selectedTitles.includes(u.title) || u.isTerminated;
        const isEnabledOrAllowDisabled = !u.isTerminated || showDisabledUsers;
        const userIsScheduled = u.scheduleItems.find((item) => ['paidTraining', 'auxiliaryWorker', 'managerOnDuty'].includes(item.scheduleType)) || u.routes.length > 0;
        const passesScheduleFilter = (filterByScheduled && userIsScheduled) || (filterByUnscheduled && !userIsScheduled) || (!filterByScheduled && !filterByUnscheduled);
        return (inCSA && matchesSearch && isEnabledOrAllowDisabled && titleIsIncluded && passesScheduleFilter);
    });


    
    const sortedUsers = filteredUsers.sort((a, b) => {
        const aIsScheduled = a.scheduleItems.some((item) => ['paidTraining', 'auxiliaryWorker', 'managerOnDuty'].includes(item.scheduleType)) || a.routes.length > 0;
        const bIsScheduled = b.scheduleItems.some((item) => ['paidTraining', 'auxiliaryWorker', 'managerOnDuty'].includes(item.scheduleType)) || b.routes.length > 0;
        if (aIsScheduled !== bIsScheduled) {
            if (sortByScheduled) {
                return aIsScheduled ? -1 : 1;
            } else if (sortByUnscheduled) {
                return aIsScheduled ? 1 : -1;
            }
        }

        const aClockedIn = timeDataOnSelectedDate.some(t => t.companyUserIdentifier == a.companyUserUid && !t.outTime);
        const bClockedIn = timeDataOnSelectedDate.some(t => t.companyUserIdentifier == b.companyUserUid && !t.outTime);
        if (aClockedIn !== bClockedIn) {
            if (sortByClockedIn) {
                return aClockedIn ? -1 : 1;
            } else if (sortByClockedOut) {
                return aClockedIn ? 1 : -1;
            }
        }
        return 0;
    })
    
    const userElements = sortedUsers.map((user) => {
        return (
            <HumanResourcesUserRow 
                key={user.uid} 
                user={user} 
                timeData={timeData} 
                selectedDate={selectedDate} 
                csas={csas} 
                permissions={permissions} 
                setSelectedEmployee={setSelectedEmployee}
                handleSetUserTimeDataForSelectedDate={handleSetUserTimeDataForSelectedDate} 
                users={users}
            />
        )
    });

    const filterDropdownMenu = (
        <Dropdown autoClose={'outside'}>
            <Dropdown.Toggle variant="outline-primary">Filter By</Dropdown.Toggle>
            <Dropdown.Menu>
                { !isRestrictedBc && 
                    <>
                        <Dropdown.Header>CSAs</Dropdown.Header>
                        {naturalSort(csas.filter(c=> c.isActive == 1), 'csaName').map((csa) => {
                            return <DropdownItemWithCheck key={csa.uid} label={csa.csaName} active={selectedCsas.includes(csa.uid)} onClick={() => {handleSelectCsa(csa.uid)}}/>
                        })}
                        <DropdownItemWithCheck label='Select All CSAs' onClick={() => setSelectedCsas(csas.map(csa => csa.uid))}/>
                        <DropdownItemWithCheck label='Unselect All CSAs' onClick={() => setSelectedCsas([])}/>
                        <DropdownItemWithCheck label={'Inactive CSAs'} active={showInactiveCsa} onClick={() => setShowInactiveCsa(!showInactiveCsa)}/>
                        <Dropdown.Divider/>
                    </>
                }
                <Dropdown.Header>Titles</Dropdown.Header>
                {Object.keys(titleDictionary).map((key) => {
                    return <DropdownItemWithCheck label={key} key={key} active={selectedTitles.includes(key)} onClick={() => {handleSelectTitle(key)}}/>
                })}
                <DropdownItemWithCheck label='Select All Titles' onClick={() => setSelectedTitles(['Admin', 'AO', 'BC', 'Jumper', 'AVP Driver', 'Driver'])}/>
                <DropdownItemWithCheck label='Unselect All Titles' onClick={() => setSelectedTitles([])}/>
                <Dropdown.Divider/>
                <Dropdown.Header>Other</Dropdown.Header>
                <DropdownItemWithCheck label='Only Show Scheduled Employees' active={filterByScheduled} onClick={() => {setFilterByScheduled(!filterByScheduled); setFilterByUnscheduled(false)}}/>
                <DropdownItemWithCheck label='Only Show Unscheduled Employees' active={filterByUnscheduled} onClick={() => {setFilterByUnscheduled(!filterByUnscheduled); setFilterByScheduled(false)}}/>
                <DropdownItemWithCheck label='Show Terminated/Disabled Employees' active={showDisabledUsers} onClick={() => setShowDisabledUsers(!showDisabledUsers)}/>
            </Dropdown.Menu>
        </Dropdown>
    )

    const sortByDropDownMenu = (
        <Dropdown autoClose={'outside'}>
            <Dropdown.Toggle variant="outline-primary">Sort By</Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Header>Scheduled Status</Dropdown.Header>
                <DropdownItemWithCheck label='Scheduled' active={sortByScheduled} onClick={() => {setSortByScheduled(!sortByScheduled); setSortByUnscheduled(false)}}/>
                <DropdownItemWithCheck label='Unscheduled' active={sortByUnscheduled} onClick={() => {setSortByUnscheduled(!sortByUnscheduled); setSortByScheduled(false)}}/>
                <Dropdown.Divider/>
                <Dropdown.Header>Timeclock Status</Dropdown.Header>
                <DropdownItemWithCheck label='Clocked-In' active={sortByClockedIn} onClick={() => {setSortByClockedIn(!sortByClockedIn); setSortByClockedOut(false)}}/>
                <DropdownItemWithCheck label='Clocked-Out' active={sortByClockedOut} onClick={() => {setSortByClockedOut(!sortByClockedOut); setSortByClockedIn(false)}} />
            </Dropdown.Menu>
        </Dropdown>
    )

    const toolsDropdownMenu = (
        <Dropdown>
            <Dropdown.Toggle variant="outline-primary">
                <FontAwesomeIcon icon={faBars}/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Header>Hire</Dropdown.Header>
                <Dropdown.Item onClick={() => setModalSwitch('hire')}>Hire Employee</Dropdown.Item>
                <Dropdown.Item onClick={() => setModalSwitch('csvHire')}>Upload Onboarding CSV</Dropdown.Item>
                <Dropdown.Divider/>
                <Dropdown.Header>Other</Dropdown.Header>
                <Dropdown.Item onClick={() => setModalSwitch('fedexNameMatch')}>Match Employee MyGroundBiz Names</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );

    return (
        <div style={{height: '100%'}}>
            <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', gap: 12}}>
                
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Button variant={'outline-primary'}  onClick={() => setModalSwitch('selectDate')}>{`Viewing Schedules and Timesheets | ` + (moment(selectedDate).diff(moment(),'days') == 0 ? 'Today' : moment(selectedDate).format('MMM D, YYYY'))}</Button>
                    <p style={{margin: 0}}>Total Hours Worked This Week: <strong>{totalWeeklyHoursWorked.toFixed(2)}</strong></p>
                    <div style={{display: 'flex', gap: 12}}>
                        { permissions.hr_profile == '1' && 
                            toolsDropdownMenu
                        }
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: 12}}>
                    <InputGroup style={{width: 'fit-content', flexShrink: 0}}>
                        {filterDropdownMenu}
                        {sortByDropDownMenu}
                    </InputGroup>
                    <SearchBar label='Search' value={search} setValue={setSearch}/>
                </div>
                <div style={{flex: 1, overflowY: 'auto'}}>
                    <QuickTable headers={['Name', 'CSA', 'Title', 'Hours Worked', 'Schedule', 'Punches']} widths={[null, 1, 1, 1, 1, 1]} rows={userElements} size='sm' noWrap/>
                </div>
            </div>
            <Modal backdrop='static' show={modalSwitch === 'hire'} size='xl'>
                <HRHire csas={csas} hideModal={() => setModalSwitch('')}/>
            </Modal>
            <Modal show={modalSwitch === 'csvHire'} onHide={() => setModalSwitch('')} fullscreen>
                <HRHireCSV csas={csas}/>
            </Modal>
            <Modal show={modalSwitch === 'fedexNameMatch'} onHide={() => setModalSwitch('')} size='xl' centered>
                <HRFedexNameMatch hideModal={() => setModalSwitch('')}/>
            </Modal>
            <Modal show={modalSwitch === 'selectDate'} onHide={() => setModalSwitch('')}>
                <HRHubDateSelectionModal selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>
            </Modal>
        </div>
    )
}