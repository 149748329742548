/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import RadioControl from '../../../components/RadioControl';

function HRUserTimesheetDateSelector({hideModal, loadData}) {
    const [presetDateRange, setPresetDateRange] = useState('Past Week');
    const [selectedStartDate, setSelectedStartDate] = useState(moment().subtract(6, 'days').format('YYYY-MM-DD'));
    const [selectedEndDate, setSelectedEndDate] = useState(moment().format('YYYY-MM-DD'));

    function handleSubmit(){
        if (presetDateRange) {
            const startDate = moment().subtract(1, (presetDateRange === 'Past Week' ? 'weeks' : presetDateRange === 'Past Month' ? 'months' : 'years')).add(1, 'day').format('YYYY-MM-DD');
            const endDate = moment().format('YYYY-MM-DD');
            loadData(startDate, endDate);
        } else {
            loadData(selectedStartDate, selectedEndDate);
        }
        hideModal();
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Select Date Range</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <RadioControl inline bordered={false} selection={presetDateRange} setSelection={setPresetDateRange} optionNames={['Past Week', 'Past Month', 'Past Year', 'Custom']} optionValues={['Past Week', 'Past Month', 'Past Year', '']}/>
                { presetDateRange ? '' :
                    <>
                        <Form.Control type='date' max={selectedEndDate} value={selectedStartDate} onChange={(event) => setSelectedStartDate(event.target.value)}/>
                        <Form.Control type='date' min={selectedStartDate} value={selectedEndDate} onChange={(event) => setSelectedEndDate(event.target.value)}/>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant={'outline-primary'} onClick={handleSubmit}>Submit</Button>
            </Modal.Footer>
        </>
    )
}

export default HRUserTimesheetDateSelector;