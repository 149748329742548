/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import { bwcCodeDictionary } from '../tools';
import RadioControl from './RadioControl';
import CustomControl from './CustomControl';
import Col from 'react-bootstrap/Col';
import { Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';


export default function BwcCodeControl({value, setValue, originalValue, xs, sm, md, lg, xl, xxl, disabled, validator, inline, forceTouched}) {
    const [useCustom, setUseCustom] = useState(true); 

    const handleSetValue = (value) => {
       
        if (value === '') {
            setValue('');
            setUseCustom(true);
            return;
        } else if (useCustom) {
            setUseCustom(false);
        } 
        setValue(value);
    }

    return (
        <>
            <Col style={{position: 'relative', marginBottom: 8}} xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                <RadioControl
                    forceTouched={forceTouched} 
                    title={'BWC Code'} 
                    selection={value} 
                    setSelection={handleSetValue} 
                    optionValues={Object.keys(bwcCodeDictionary)} 
                    optionNames={Object.keys(bwcCodeDictionary).map(key => `${bwcCodeDictionary[key]} (${key})`)} 
                    inline={inline} 
                    disabled={disabled} 
                    originalValue={originalValue} 
                    validator={validator}
                    useCustom={useCustom} 
                />
                { useCustom &&
                    <div style={{marginTop:3}}>
                        <CustomControl maxLength={10} disabled={disabled} originalValue={originalValue}  title='BWC Code' type='text' value={value}
                            onChange={(event) => {
                                setValue(event.target.value);
                            }}
                            validator={validator}
                        />
                    </div>
                }
            </Col>
        </>
    )
}