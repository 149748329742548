/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import { Page, Document, View, Text, Image } from "@react-pdf/renderer";
import moment from "moment";
import { formatTime, naturalSort } from "../../../tools";
import logo from '../../../assets/TTA-Logo-150.png';

export default function PDFSchedule({data}) {
    const tables = [];
    
    for (let weekIndex = 0; weekIndex <= Math.ceil(moment(data.endDate).diff(moment(data.startDate), 'weeks')); weekIndex++) {
        data.csas.forEach((csa) => {
            tables.push(
                <PDFScheduleTable key={csa.csaName + weekIndex.toString()} startDate={moment(data.startDate).add(weekIndex, 'weeks')} routes={data.routes} csaName={csa.csaName} workAreas={csa.workAreas} />
            );
        });
    }

    return (
        <Document>
            <Page orientation='landscape' size='LETTER' style={{padding: '0.5in', flexDirection: 'column'}}>
                <View fixed style={{flexDirection: 'row', width: '100%', justifyContent: 'space-between'}}>
                    <Image src={logo} style={{width: 100}}/>
                    <Text style={{fontSize: 12, color: 'gray'}}>{data.companyName}</Text>
                </View>
                <View style={{flexDirection: 'column', gap: 6}}>
                    {tables}
                </View>
                <Text 
                    style={{position: 'absolute', fontSize: 12, bottom: '0.5in', left: 0, right: 0, textAlign: 'center', color: 'gray'}} 
                    render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed 
                />
            </Page>
        </Document>
    )
}

function PDFScheduleTable({startDate, csaName, workAreas, routes}) {
    const headerElements = [0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
        const day = moment(startDate).add(dayIndex, 'days');
        return (
            <View key={dayIndex} style={{border: '1px gray solid', padding: 2, fontSize: 12, width: '12.5%', flexDirection: 'column', alignItems: 'center'}}>
                <Text>{day.format('ddd') + ' ' + day.format('D')}</Text>
            </View>
        )
    });

    const rows = naturalSort(workAreas.filter(w => w.isActive == 1 || routes.find(r => r.workAreaIdentifier == w.uid)), 'workAreaName').map((workArea) => { //for each work area

        const cells = [0, 1, 2, 3, 4, 5, 6].map((dayIndex) => { //for each day of the week
            const route = workArea.routes.find(r => r.date === moment(startDate).add(dayIndex, 'days').format('YYYY-MM-DD'));
            const openShift = workArea.openShifts.find(r => r.date === moment(startDate).add(dayIndex, 'days').format('YYYY-MM-DD'))
            
            if (!route && !openShift) {
                return (
                    <View key={dayIndex} style={{border: '1px gray solid', width: '12.5%', flexDirection: 'column', alignItems: 'center'}}/>
                )
            } else if(route){
                return (
                    <View key={dayIndex} style={{border: '1px gray solid', padding: 2, width: '12.5%', flexDirection: 'column', alignItems: 'center', fontSize: 9}}>
                        <Text>{route.companyUser.firstName + ' ' + route.companyUser.lastName}</Text>
                        { route.secondaryDriver ?
                            <Text>{route.secondaryDriver.firstName + ' ' + route.secondaryDriver.lastName + ' (' + route.secondaryDriverType + ')'}</Text>
                        : <View/>}
                        <Text>{route.vehicle ? route.vehicle.vehicleName : 'No Vehicle'}</Text>
                        <Text>{formatTime(route.timeScheduled, 'h:mm A')}</Text>
                    </View>
                )
            } else if (openShift){
                return (
                    <View key={dayIndex} style={{border: '1px gray solid', padding: 2, width: '12.5%', flexDirection: 'column', alignItems: 'center', fontSize: 9}}>
                        <Text>Open Shift</Text>
                        <Text>{formatTime(openShift.timeScheduled, 'h:mm A')}</Text>
                    </View>
                )
            }
        });
        cells.unshift(
            <View key={'work area ' + workArea.uid} style={{border: '1px gray solid', padding: 2, width: '12.5%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <Text style={{fontSize: 10}}>{workArea.workAreaName}</Text>
            </View>
        );
        return (
            <View key={workArea.uid} style={{flexDirection: 'row', width: '100%'}}>
                {cells}
            </View>
        )
    });

    return (
        <View style={{flexDirection: 'column', gap: 6, alignItems: 'center'}} wrap={false}>
            <Text style={{fontSize: 15, fontWeight: 'bold'}}>{moment(startDate).format('MMM D') + ' - ' + moment(startDate).add(6, 'days').format('MMM D') + ' | ' + csaName}</Text>
            <View>
                <View style={{flexDirection: 'row'}}>
                    <View style={{border: '1px gray solid', padding: 4, width: '12.5%', fontSize: 10, alignContent: 'center', justifyContent: 'flex-end', textAlign: 'center'}}/>
                    {headerElements}
                </View>
                {rows}
            </View>
        </View>
    )
}



  

