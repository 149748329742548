/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import { Letter } from 'react-letter';
import { Modal } from 'react-bootstrap';
import moment from 'moment';


export default function EmailModal({email}) {


    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{`${email.sender} - ${moment(email.dateTime).format('MMM D, YYYY [at] h:mm A')}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Letter html={email.html}/>
            </Modal.Body>
        </>
    )
}