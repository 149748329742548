/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { Document, PDFViewer, Page, Text, View, Image } from "@react-pdf/renderer";
import moment from "moment";
import { Modal } from "react-bootstrap";
import Cookies from "universal-cookie";
import { getFakeUID, usdFormatter, validateDecimal } from "../../../tools";
import logo from '../../../assets/TTA-Logo-150.png';


export default function ChargeStatementPDF({chargeStatement, selectedChargeStatement, weeklySummary, miscSummary}){

    function getDriverTypeTotal(type, fedexId){
        return chargeStatement?.chargeStatementDrivers?.reduce((acc, el) => {if(el.fedexId !== fedexId){return acc}{return acc + validateDecimal(el[type])}}, 0)
    }
    
    function findDriverAmountDayIndex(dayIndex, fedexId, type){
        return chargeStatement?.chargeStatementDrivers?.find((csd) => csd.dayIndex == dayIndex && fedexId === csd.fedexId)?.[type] ?? 0;
    }

    const weeklySummaryObj = [
        {label: 'Pickup Packages', key: 'puPackageAmount'},
        {label: 'Pickup Stops', key: 'puStopAmount'},
        {label: 'Delivery Packages', key: 'dlPackageAmount'},
        {label: 'Large Packages', key: 'desigAmount'},
        {label: 'E-commerce Packages', key: 'ecPackageAmount'},
        {label: 'E-commerce Deliveries', key: 'ecStopAmount'},
        {label: 'Fuel', key: 'fuelAmount'},
        {label: 'Surge', key: 'surgeAmount'},
        {label: 'Other P&D Charges', key: 'otherAmount'},
        {label: 'Total', key: 'netAmount'},
    ]

    const miscSummaryObj = [
        {label: 'Average Large Package %', key: 'desigPercentage'},
    ]

    const summaryTable = (
        <View style={{border: '1px black solid', width: '100%', fontSize: 10}}>
            <View style={{borderBottom: 'none', borderRight: 'none', flexDirection:'row', backgroundColor: 'rgb(92, 117, 244)', color: 'white', fontFamily: 'Helvetica-Bold'}}>
                <Text style={{flex: 1, borderRight: '1px solid black', padding: 4}}>Label</Text>
                <Text style={{flex: 1, borderRight: '1px solid black', padding: 4}}>Value</Text>
            </View>
            {weeklySummaryObj.map(obj => {
                return (
                    <View key={obj.key} style={{display:'flex', flexDirection:'row', alignContent:'center', borderTop:'1px gray solid'}}>
                        <Text style={{flex: 1, borderRight: '1px solid gray', padding: 4}}>{obj.label}</Text>
                        <Text style={{flex: 1, borderRight: '1px solid gray', padding: 4}}>{usdFormatter.format(weeklySummary[obj.key])}</Text>
                    </View>
                )
            })}   
        </View>
    )

    const miscTable = (
        <View style={{border: '1px black solid', width: '100%', fontSize: 10, marginBottom: 120}}>
            <View style={{borderBottom: 'none', borderRight: 'none', flexDirection:'row', backgroundColor: 'rgb(92, 117, 244)', color: 'white', fontFamily: 'Helvetica-Bold'}}>
                <Text style={{flex: 1, borderRight: '1px solid black', padding: 4}}>Label</Text>
                <Text style={{flex: 1, borderRight: '1px solid black', padding: 4}}>Value</Text>
            </View>
            {miscSummaryObj.map(obj => {
                return (
                    <View key={obj.key} style={{display:'flex', flexDirection:'row', alignContent:'center', borderTop:'1px gray solid'}}>
                        <Text style={{flex: 1, borderRight: '1px solid gray', padding: 4}}>{obj.label}</Text>
                        <Text style={{flex: 1, borderRight: '1px solid gray', padding: 4}}>{miscSummary[obj.key]}</Text>
                    </View>
                )
            })}   
        </View>
    )

    const driverTables = chargeStatement?.chargeStatementDrivers?.reduce((acc, el) => {
        if(acc.some(obj => obj.fedexId == el.fedexId)){
            return acc;
        }
        acc.push(el);
        return acc
    }, []).map((csd) => {
        const driverDayRows = [0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
            const puPackages = findDriverAmountDayIndex(dayIndex, csd.fedexId, 'puPackages');
            const puStops = findDriverAmountDayIndex(dayIndex, csd.fedexId, 'puStops');
            const dlPackages = findDriverAmountDayIndex(dayIndex, csd.fedexId, 'dlPackages');
            const dlStops = findDriverAmountDayIndex(dayIndex, csd.fedexId, 'dlStops');
            const ecPackages = findDriverAmountDayIndex(dayIndex, csd.fedexId, 'ecPackages')
            const ecStops = findDriverAmountDayIndex(dayIndex, csd.fedexId, 'ecStops');
            return (
                <View key={dayIndex} style={{display:'flex', flexDirection:'row', alignContent:'center', borderTop:'1px gray solid'}}>
                    <Text style={{flex: 1, borderRight: '1px solid gray', padding: 4}}>{moment(chargeStatement.startDate).add(dayIndex, 'days').format('DD/MM/YYYY')}</Text>
                    <Text style={{flex: 1, borderRight: '1px solid gray', padding: 4}}>{puPackages}</Text>
                    <Text style={{flex: 1, borderRight: '1px solid gray', padding: 4}}>{puStops}</Text>
                    <Text style={{flex: 1, borderRight: '1px solid gray', padding: 4}}>{dlPackages}</Text>
                    <Text style={{flex: 1, borderRight: '1px solid gray', padding: 4}}>{dlStops}</Text>
                    <Text style={{flex: 1, borderRight: '1px solid gray', padding: 4}}>{ecPackages}</Text>
                    <Text style={{flex: 1, borderRight: '1px solid gray', padding: 4}}>{ecStops}</Text>
                    <Text style={{flex: 1, borderRight: '1px solid gray', padding: 4}}>{puPackages + dlPackages + ecPackages}</Text>
                    <Text style={{flex: 1, borderRight: '1px solid gray', padding: 4}}>{puStops + dlStops + ecStops}</Text>
                </View>
            )
        })

        const puPackagesTotal = getDriverTypeTotal('puPackages', csd.fedexId)
        const puStopsTotal = getDriverTypeTotal('puStops', csd.fedexId)
        const dlPackagesTotal = getDriverTypeTotal('dlPackages', csd.fedexId)
        const dlStopsTotal = getDriverTypeTotal('dlStops', csd.fedexId)
        const ecPackagesTotal = getDriverTypeTotal('ecPackages', csd.fedexId)
        const ecStopsTotal = getDriverTypeTotal('ecStops', csd.fedexId)

        const totalRows = (
            <View style={{display:'flex', flexDirection:'row', alignContent:'center', borderTop:'1px gray solid'}}>
                <Text style={{flex: 1, borderRight: '1px solid gray', padding: 4}}>Totals</Text>
                <Text style={{flex: 1, borderRight: '1px solid gray', padding: 4}}>{puPackagesTotal}</Text>
                <Text style={{flex: 1, borderRight: '1px solid gray', padding: 4}}>{puStopsTotal}</Text>
                <Text style={{flex: 1, borderRight: '1px solid gray', padding: 4}}>{dlPackagesTotal}</Text>
                <Text style={{flex: 1, borderRight: '1px solid gray', padding: 4}}>{dlStopsTotal}</Text>
                <Text style={{flex: 1, borderRight: '1px solid gray', padding: 4}}>{ecPackagesTotal}</Text>
                <Text style={{flex: 1, borderRight: '1px solid gray', padding: 4}}>{ecStopsTotal}</Text>
                <Text style={{flex: 1, borderRight: '1px solid gray', padding: 4}}>{puPackagesTotal + dlPackagesTotal + ecPackagesTotal}</Text>
                <Text style={{flex: 1, borderRight: '1px solid gray', padding: 4}}>{puStopsTotal + dlStopsTotal + ecStopsTotal}</Text>
            </View>
        )

        return (
            <View wrap={false} key={getFakeUID()} style={{marginBottom: 36}}>
                <Text style={{paddingLeft: 8, marginBottom: 12, fontSize: 15}}>{csd.firstName ? `${csd.firstName} ${csd.middleName} ${csd.lastName}` : csd.fedexId + ' (Not Matched)'}</Text>
                <View style={{border: '1px black solid', width: '100%', fontSize: 10}}>
                    <View style={{borderBottom: 'none', borderRight: 'none', flexDirection:'row', backgroundColor: 'rgb(92, 117, 244)', color: 'white', fontFamily: 'Helvetica-Bold'}}>
                        <Text style={{flex: 1, borderRight: '1px solid black', padding: 4}}>DATE</Text>
                        <Text style={{flex: 1, borderRight: '1px solid black', padding: 4}}>PU PKGS</Text>
                        <Text style={{flex: 1, borderRight: '1px solid black', padding: 4}}>PU STOPS</Text>
                        <Text style={{flex: 1, borderRight: '1px solid black', padding: 4}}>DL PKGS</Text>
                        <Text style={{flex: 1, borderRight: '1px solid black', padding: 4}}>DL STOPS</Text>
                        <Text style={{flex: 1, borderRight: '1px solid black', padding: 4}}>ECOMM DL PKGS</Text>
                        <Text style={{flex: 1, borderRight: '1px solid black', padding: 4}}>ECOMM DL STOPS</Text>
                        <Text style={{flex: 1, borderRight: '1px solid black', padding: 4}}>TOTAL PKGS</Text>
                        <Text style={{flex: 1, borderRight: '1px solid black', padding: 4}}>TOTAL STOPS</Text>
                    </View>
                    {driverDayRows}
                    {totalRows}
                </View>
            </View>
        )
    })

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Viewing Charge Statement PDF</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PDFViewer style={{width: '100%', height: '100%'}}>
                    <Document> 
                        <Page size='LETTER' style={{padding: '0.5in', flexDirection: 'column'}} orientation='portrait'>
                            <View fixed style={{flexDirection: 'row', width: '100%', justifyContent: 'space-between', marginBottom:15}}>
                                <Image src={logo} style={{width: 150}}/>
                                <Text style={{fontSize: 12, color: 'gray'}}>{new Cookies().get('companyName')}</Text>
                            </View>
                            <Text style={{textAlign: 'center', paddingBottom: 4, textDecoration: 'underline'}}>Charge Statement Report</Text>
                            <Text style={{opacity: 0.5, textAlign: 'center', fontSize: 12}}>{`${selectedChargeStatement?.csaName ?? selectedChargeStatement.csaFedexId} | ${moment(chargeStatement.startDate).format('MMM D, YYYY')} - ${moment(chargeStatement.startDate).add(6, 'days').format('MMM D, YYYY')}`}</Text>
                            <Text style={{textAlign: 'center', fontSize: 14, margin: 12, marginTop: 18}}>Weekly Summary</Text>
                            {summaryTable}
                            <Text style={{textAlign: 'center', fontSize: 14, margin: 12, marginTop: 36}}>Misc.</Text>
                            {miscTable}
                            <Text style={{textAlign: 'center', fontSize: 16, marginBottom: 24}}>Driver Stops & Packages</Text>
                            {driverTables}
                        </Page>
                    </Document>
                </PDFViewer>
            </Modal.Body>
        </>
    )
}