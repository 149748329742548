import { Page, Document, View, Text, Image } from "@react-pdf/renderer";
import React from "react";
import moment from "moment";
import logo from '../../../assets/TTA-Logo-150.png';
import Cookies from "universal-cookie";

export default function PDFAcknowledgement({documents, users}) {
    const includedCompanyUserIdentifiers = users.map(u => u.companyUserUid);
    const tables = documents.map((document) => {
        const acknowledgementRows = document.acknowledgements.filter(ack => includedCompanyUserIdentifiers.includes(parseInt(ack.companyUserIdentifier))).sort((a, b) => {
            const userA = users.find(user=>user.companyUserUid == a.companyUserIdentifier);
            const userB = users.find(user=>user.companyUserUid == b.companyUserIdentifier);
            return `${userA.lastName}${userA.firstName}${userA.middleName}`.localeCompare(`${userB.lastName}${userB.firstName}${userB.middleName}`);
        }).map((ack)=>{
            ack.user = users.find(user=>user.companyUserUid == ack.companyUserIdentifier);
            return (
                <View key={ack.uid} style={{display:'flex', flexDirection:'row', alignContent:'center', justifyContent: 'space-between', gap: 6, borderBottom:'1px solid gray', borderRight: '1px solid gray', padding:2}} wrap={false}>
                    <Text>{ack.user.lastName + ', ' + ack.user.firstName + ' ' + ack.user.middleName}</Text>
                    <Text>{moment(ack.dateApproved).format('MMM D, YYYY [at] h:mm A')}</Text>
                </View>
            )
        })

        const unacknowledgedRows = users.filter(u => !document.acknowledgements.map(ack => ack.companyUserIdentifier).includes(u.companyUserUid)).map((user) => {
            return (
                <View key={user.companyUserUid} style={{display:'flex', flexDirection:'row', alignContent:'center', justifyContent: 'space-between', gap: 6, borderBottom:'1px solid gray', borderRight: '1px solid gray', padding:2}} wrap={false}>
                    <Text>{user.lastName + ', ' + user.firstName + ' ' + user.middleName}</Text>
                </View>
            )
        })

        return (
            <View key={document.uid} style={{display:'flex', flexDirection:'column', gap:8, marginBottom:10}}>
                <View style={{display: 'flex', flexDirection:'row',justifyContent:'space-between', alignContent:'center'}}>
                    <Text>{document.documentName}</Text>
                </View>
                <View style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: 12}}>
                    <View style={{borderTop: '1px gray solid', borderLeft: '1px solid gray', width: '66%', fontSize: 12}}>
                        <View style={{flexDirection:'row', paddingBottom:2, justifyContent: 'center', borderRight: '1px solid gray', borderBottom: '1px solid gray'}}>
                            <Text>Acknowledged</Text>
                        </View>
                        {acknowledgementRows}
                    </View>
                    { unacknowledgedRows.length > 0 && 
                        <View style={{borderTop: '1px gray solid', borderLeft: '1px solid gray', width: '34%', fontSize: 12}}>
                                <View style={{flexDirection:'row', paddingBottom:2, justifyContent: 'center', borderRight: '1px solid gray', borderBottom: '1px solid gray'}}>
                                    <Text>Have Not Acknowledged</Text>
                                </View>
                            {unacknowledgedRows}
                        </View>
                    }
                </View>
            </View>
        )
    })

    return (
        <Document>
            <Page size='LETTER' style={{padding: '0.5in', flexDirection: 'column'}}>
                <View fixed style={{flexDirection: 'row', width: '100%', justifyContent: 'space-between', marginBottom:15}}>
                    <Image src={logo} style={{width: 150}}/>
                    <Text style={{fontSize: 12, color: 'gray'}}>{new Cookies().get('companyName')}</Text>
                </View>
                <Text style={{textAlign:'center', paddingBottom:4, textDecoration:'underline'}}>Company Acknowledgements</Text>
                {tables}
            </Page>
        </Document>
    )
}