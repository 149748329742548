/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Modal from "react-bootstrap/Modal";
import CustomControl from '../../components/CustomControl';
import { validateExistence, ValidationGroup } from '../../validation';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import CustomButton from '../../components/CustomButton';
import { Dropdown } from 'react-bootstrap';
import DropdownItemWithCheck from '../../components/DropdownItemWithCheck';
import { ApiRequest } from '../../ApiManager.tsx';

export default function DaysOffRequestModal({hideModal, loadData, requestOffHistory}) {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [notes, setNotes] = useState('');
    const [asPto, setAsPto] = useState(false);
    const [requestOffError, setRequestOffError] = useState('');
    const [isLoadingRequest, setIsLoadingRequest] = useState(false);
    const [invalidDates, setInvalidDates] = useState(undefined);
    const validationGroup = new ValidationGroup();

    function handleSubmit(){
        setInvalidDates(undefined);
        if (requestOffError) {
            setRequestOffError(false);
        }

        const scheduleItem = {
            date: startDate,
            endDate: endDate,
            notes: notes,
            asPto: asPto
        };

        const momentStart = moment(startDate);
        const momentEnd = moment(endDate);
        for(let i = 0; i < requestOffHistory.length; i++) {
            if (requestOffHistory[i].scheduleType === 'requestOff') {
                if(momentStart.isBetween(moment(requestOffHistory[i].date), moment(requestOffHistory[i].endDate),'days','[]') || momentEnd.isBetween(moment(requestOffHistory[i].date), moment(requestOffHistory[i].endDate),"days","[]")){
                    setRequestOffError(true);
                    setIsLoadingRequest(false);
                    return;
                }
            } else if (requestOffHistory[i].scheduleType !== 'rejectedRequest') {
                if (moment(requestOffHistory[i].date).isBetween(momentStart, momentEnd,'days','[]')){
                    setRequestOffError(true);
                    setIsLoadingRequest(false);
                    return;
                }
            }
        }

        new ApiRequest('mySchedule', 'requestOff', setIsLoadingRequest, () => {loadData(); hideModal()}, (response) => {
            if(response.invalidDates)
                setInvalidDates(response.invalidDates)
        }).withData({data: scheduleItem}).withNoAlertOnStatuses([400]).send();
    }

    const invalidDateElements = invalidDates?.map((date) => {
        return (
            <li key={date}>{moment(date).format('MMM D, YYYY')}</li>
        )
    })

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Request Off</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <CustomControl lg={4} min={moment().format('YYYY-MM-DD')} max={endDate} validator={validationGroup.createValidator(() => validateExistence(startDate))} type='date' title='Start Date' value={startDate} onChange={(event) => {setStartDate(event.target.value)}}/>
                        <CustomControl lg={4} min={startDate} validator={validationGroup.createValidator(() => validateExistence(endDate))} type='date' title='End Date' value={endDate} onChange={(event) => {setEndDate(event.target.value)}}/>
                        <Col lg={4} style={{marginBottom: 24}}>
                            <Dropdown autoClose='outside'>
                                <Dropdown.Toggle style={{padding: 16, width: '100%'}} variant='outline-primary'>
                                    Request off as {asPto ? 'PTO' : 'Unpaid'}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                <DropdownItemWithCheck active={asPto} onClick={()=>setAsPto(true)} label='Paid Time Off'/>
                                <DropdownItemWithCheck active={!asPto} onClick={()=>setAsPto(false)} label='Unpaid Time Off'/>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col xxl={12}>
                            <Form.Group>
                                <Form.Label>Reason</Form.Label>
                                <Form.Control as='textarea' maxLength={65535} rows={4} value={notes} onChange={(event) => {setNotes(event.target.value)}}/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            { invalidDates &&
                <Modal.Footer style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                    <span style={{fontStyle: 'italic', color: 'red'}}>You cannot request off the following dates because other employees have already requested them off or your company has blacklisted them:</span>
                    <ul style={{marginBottom: 0}}>
                        {invalidDateElements}
                    </ul>
                </Modal.Footer>
            }
            <Modal.Footer>
                <span style={{color:'red'}}>{requestOffError ? 'Date range must not include date previously requested off ':''}</span>
                <CustomButton onClick={handleSubmit} disabled={!validationGroup.isValid()} isLoading={isLoadingRequest} label={'Submit'}/> 
            </Modal.Footer>
        </>
    )
}