/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import { ApiRequest } from '../../ApiManager.tsx';
import { ListGroup, Modal } from 'react-bootstrap';
import EmailModal from './EmailModal.js';
import { formatPastDateTime } from '../../tools.js';
import SearchBar from '../../components/SearchBar.js';
import LoadingWrapper from '../../components/LoadingWrapper.js';

export default function Messages() {

    const [isLoading, setIsLoading] = useState(false);
    const [emails, setEmails] = useState([]);
    const [selectedEmail, setSelectedEmail] = useState([]);
    const [showEmailModal, setShowEmailModal] = useState(false);

    const [search, setSearch] = useState('');
    const [searchType, setSearchType] = useState('subject');

    useEffect(() => {
        loadData();
    }, []);

    function loadData() {
        new ApiRequest("getProfileInformation", "getMessages", setIsLoading, (response) => {
            setEmails(response.emails);
        }).withNoAlertOnSuccess().send();
    }

    function handleSetEmailAsViewed(uid) {
        new ApiRequest("getProfileInformation", "setEmailAsViewed", () => {}, () => {
            const newArray = Array.from(emails);
            newArray.find(e => e.uid == uid).viewed = 1;
            setEmails(newArray);
        }).withUid(uid).withNoAlertOnSuccess().send()
    }

    function handleViewEmail(email) {
        setSelectedEmail(email);
        setShowEmailModal(true);
        if (email.viewed == 0) {
            handleSetEmailAsViewed(email.uid);
        }
    }

    const emailElements = emails.filter(e => e[searchType].toLowerCase().includes(search.toLowerCase())).map((email) => {
        return (
            <ListGroup.Item key={email.uid} action onClick={() => {handleViewEmail(email)}} style={{opacity: email.viewed == 1 ? 0.5 : 1.0}}>
                <div style={{display: 'flex', justifyContent: 'space-between', gap: 12}}>
                    <span style={{fontSize: 14}}>{email.sender}</span>
                    <span style={{fontWeight: 'bold', fontSize: 14}}>{formatPastDateTime(email.dateTime)}</span>
                </div>
                <span>{email.subject}</span>
            </ListGroup.Item>
        )
    })

    return (
        <div style={{padding: 16, height: '100%', display: 'flex', flexDirection: 'column', gap: 12}}>
            <SearchBar value={search} setValue={setSearch} type={searchType} setType={setSearchType} typeValues={['subject', 'sender']} typeNames={['Subject', 'Sender']}/>
            <LoadingWrapper isLoading={isLoading}>
                <ListGroup style={{flex: 1, overflowY: 'auto'}}>{emailElements}</ListGroup>
            </LoadingWrapper>

            <Modal show={showEmailModal} onHide={() => {setShowEmailModal(false)}} size='xl'>
                <EmailModal email={selectedEmail}/>
            </Modal>
        </div>
    )

}

