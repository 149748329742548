/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomControl from '../../components/CustomControl';
import AddressControl from '../../components/AddressControl';
import RadioControl from '../../components/RadioControl';
import AvailabilityControl from '../../components/AvailabilityControl';
import { parseAvailability, encodeAvailability } from '../../tools';
import moment from 'moment';
import CustomButton from '../../components/CustomButton';
import PasswordProtectedControl from '../../components/PasswordProtectedControl';
import { AddressValidator, ValidationGroup, Validator, validateEmail, validateExistence, validatePhoneNumber, validateSsn} from '../../validation';
import Cookies from 'universal-cookie';
import SwitchControl from '../../components/SwitchControl';
import DropdownControl from '../../components/DropdownControl';
import { ApiRequest } from '../../ApiManager.tsx';
import LoadingWrapper from '../../components/LoadingWrapper';
import HRTerminate from './HRHub/HRTerminate.js';
import HRHire from './HRHire/HRHire.js';
import { Modal, Button } from 'react-bootstrap';

export default function HRUserProfile({selectedEmployee}) {
    const [isLoading, setIsLoading] = useState(false);
    const [csas, setCsas] = useState([]);
    const [user, setUser] = useState(undefined);
    const [originalUser, setOriginalUser] = useState(undefined);
    const [userIsEditable, setUserIsEditable] = useState(false);
    const [employmentDuration, setEmploymentDuration] = useState('');
    const [effectivePay, setEffectivePay] = useState(undefined);
    const [companyUsers, setCompanyUsers] = useState([]);
    const cookieMonster = new Cookies();
    const validationGroup = new ValidationGroup();
    const ssnValidator = new Validator(() => validateSsn(user.ssn));
    const [modalSwitch, setModalSwitch] = useState('');

    useEffect(() => {
        loadData();
    }, [selectedEmployee])

    function loadData(){
        new ApiRequest('hr', 'get', setIsLoading, (response)=>{
            response.user.availability = parseAvailability(response.user.availability);
            response.user.address = response.userAddress;
            response.user.eContact1Address = response.eContact1Address ? response.eContact1Address : {};
            response.user.eContact2Address = response.eContact2Address ? response.eContact2Address : {};
            let employmentDuration = '';
            if(response.user.startDate){
                const duration = moment.duration(moment().diff(moment(response.user.startDate)));
                if (duration.asDays() > 365) {
                    const years = Math.floor(duration.as('years'));
                    const days = Math.floor(duration.as('days') - (years * 365));
                    employmentDuration = years + ' year(s) ' + days + ' day(s)';
                } else {
                    const days = Math.round(duration.asDays());
                    employmentDuration = days + ' day(s)';
                }
                
            }
            setCsas(response.csas);
            setUser(response.user);
            setOriginalUser(response.user);
            setUserIsEditable(response.userIsEditable);
            setEmploymentDuration(employmentDuration);
            setCompanyUsers(response.companyUsers.sort((a,b)=>a.lastName.localeCompare(b.lastName)))
            setEffectivePay(response.effectivePay);
        }).withUid(selectedEmployee.companyUserUid).withNoAlertOnSuccess().send()
    }

    function handleSaveChanges() {
        const userToSend = structuredClone(user);
        userToSend.availability = encodeAvailability(userToSend.availability);
        userToSend.address= new AddressValidator(user.address).isValid() ? user.address : null;
        userToSend.eContact1Address = new AddressValidator(user.eContact1Address).isValid() ? user.eContact1Address : null;
        userToSend.eContact2Address = new AddressValidator(user.eContact2Address).isValid() ? user.eContact2Address : null;
        const userObject = cookieMonster.get('userData');
        new ApiRequest('hr', 'update', setIsLoading, ()=>{
            if(userObject.userIdentifier === userToSend.userIdentifier){
                userObject.csaIdentifier = userToSend.csaIdentifier;
                cookieMonster.set('userData', userObject);               
            }
            // loadData();
            setOriginalUser(structuredClone(user));
        }).withData({user: userToSend}).send()
    }

    function handleSetUser(key, value) {
        const newUser = structuredClone(user);
        newUser[key] = value;
        setUser(newUser);
    }

    function handleSetOriginalUser(key, value) {
        const newUser = structuredClone(originalUser);
        newUser[key] = value;
        setOriginalUser(newUser);
    }

    const terminated = effectivePay?.payType == 'termination';

    const content = !user ? '' : (
        <>
            <Container fluid style={{overflowY: 'auto'}}>
                <Row>
                    <Col style={{marginBottom: 12}}>
                        { terminated 
                        ?
                            !selectedEmployee.firstUpcomingPayDateEffective 
                            ?   <div style={{display:'flex', gap:8, justifyContent: 'space-between', alignItems:'center'}}>
                                    <h5 style={{fontWeight:'bold', margin: 0}}>{`Terminated ${moment(effectivePay.date).format('MMM D, YYYY')}`}</h5> 
                                    <Button variant='outline-primary' onClick={() => setModalSwitch('rehire')}>Rehire Employee</Button>
                                </div>  
                            :   <h5 style={{fontWeight:'bold', margin: 0, textAlign: 'center'}}>Upcoming Pay {moment(selectedEmployee.firstUpcomingPayDateEffective).format('LL')}</h5>
                                
                        :
                            !user.firstUpcomingTerminationDateEffective 
                            ?   <div style={{display:'flex', justifyContent: 'flex-end'}}>
                                    <Button variant='outline-primary' onClick={() => setModalSwitch('terminate')}>Terminate Employee</Button>
                                </div>  
                            :   <h5 style={{fontWeight:'bold', margin: 0, textAlign: 'center'}}>Upcoming Termination {moment(user.firstUpcomingTerminationDateEffective).format('LL')}</h5>
                            
                        }
                    </Col>
                </Row>
                <Row style={{padding: 8, border: '1px solid lightgray', borderRadius: 6, marginBottom: 8}}>
                    <Col lg={12}>
                        <p style={{fontWeight: 'bold', width: '100%', textAlign: 'center'}}>Employment Information</p>
                    </Col>
                    <CustomControl 
                        lg={6}
                        disabled={terminated}
                        type='text' 
                        maxLength={60} 
                        title='MyGroundBiz Username' 
                        originalValue={originalUser.fedexName ? originalUser.fedexName : ''} 
                        value={user.fedexName ? user.fedexName : ''} 
                        onChange={(event) => {handleSetUser('fedexName', event.target.value)}}
                    />
                    <CustomControl 
                        lg={6}
                        disabled={terminated}
                        type='text' 
                        maxLength={75} 
                        title='Weekly Service Worksheet Name' 
                        originalValue={originalUser.wswName ? originalUser.wswName : ''} 
                        value={user.wswName  ? user.wswName : ''} 
                        onChange={(event) => {handleSetUser('wswName', event.target.value)}}
                    />
                    <CustomControl 
                        lg={6}
                        disabled={terminated}
                        type='date' 
                        title='Start Date' 
                        originalValue={originalUser.startDate ? originalUser.startDate : ''} 
                        value={user.startDate ? user.startDate : ''} 
                        onChange={(event) => {handleSetUser('startDate', event.target.value)}}
                    />
                    <CustomControl 
                        lg={6}
                        disabled={terminated}
                        type='time' 
                        title='Daily Start Time' 
                        originalValue={originalUser.dailyStartTime ? originalUser.dailyStartTime : ''} 
                        value={user.dailyStartTime ? user.dailyStartTime : ''} 
                        onChange={(event) => {handleSetUser('dailyStartTime', event.target.value)}}
                    />
                    <CustomControl 
                        lg={6}
                        disabled={true}
                        type='text' 
                        title='Employment Duration' 
                        value={employmentDuration} 
                    />
                    <CustomControl 
                        lg={6}
                        disabled={terminated}
                        type='date' 
                        title='Med-Card Expiration Date' 
                        originalValue={originalUser.medcoExpiration ? originalUser.medcoExpiration : ''} 
                        value={user.medcoExpiration ? user.medcoExpiration : ''} 
                        onChange={(event) => {handleSetUser('medcoExpiration', event.target.value)}} 
                    />
                    <CustomControl 
                        lg={6}
                        disabled
                        title='Company Email Address' 
                        value={`${user.companyUserUid}@mail.tacticalcontractor.com`} 
                    />
                    <AvailabilityControl 
                        lg={6}
                        disabled={terminated}
                        title='Availability' 
                        originalValue={originalUser.availability} 
                        inline 
                        value={user.availability} 
                        setValue={(value) => {handleSetUser('availability', value)}}
                    />
                    <Col>
                        <DropdownControl title={'Supervisor'} selection={user.supervisor} setSelection={(value)=>handleSetUser('supervisor', value)} itemNames={companyUsers.map(u => u.firstName + ' ' + u.lastName)} itemValues={companyUsers.map(u => u.companyUserUid)}/>
                    </Col> 
                    <RadioControl 
                        lg={6}
                        disabled={terminated}
                        originalValue={originalUser.csaIdentifier} 
                        title='CSA' 
                        inline
                        selection={user.csaIdentifier} 
                        setSelection={(value) => {handleSetUser('csaIdentifier', value)}} 
                        optionNames={csas.filter(c=> c.isActive == 1).map(c => c.csaName)} 
                        optionValues={csas.filter(c=> c.isActive == 1).map(c => c.uid)}
                    />
                    <SwitchControl lg={6} value={user.onLoa} setValue={(value) => {handleSetUser('onLoa', value)}} originalValue={originalUser.onLoa} title='Place on Leave of Absence'/>
                    { (!terminated && !userIsEditable) &&
                        <CustomButton label='Save Changes' onClick={handleSaveChanges} isLoading={isLoading}/>
                    }
                </Row>
                { !userIsEditable &&
                    <Row>
                        <Col lg={12}>
                            <p style={{width: '100%', textAlign: 'center'}}>Employee information below is not editable because the employee belongs to multiple companies</p>
                        </Col>
                    </Row>
                }
                <Row style={{padding: 8, border: '1px solid lightgray', borderRadius: 6, marginBottom: 8}} >
                    <Col lg={12}>
                        <p style={{fontWeight: 'bold', width: '100%', textAlign: 'center'}}>Personal Information</p>
                    </Col>
                    <CustomControl maxLength={30} lg={4} validator={validationGroup.createValidator(() => validateExistence(user.firstName))} disabled={terminated || !userIsEditable} title='First Name' value={user.firstName} originalValue={originalUser.firstName} onChange={(event) => {handleSetUser('firstName', event.target.value)}}/>
                    <CustomControl maxLength={30} lg={4} disabled={terminated || !userIsEditable} title='Middle Name' value={user.middleName} originalValue={originalUser.middleName} onChange={(event) => {handleSetUser('middleName', event.target.value)}}/>
                    <CustomControl maxLength={30} lg={4} validator={validationGroup.createValidator(() => validateExistence(user.lastName))} disabled={terminated || !userIsEditable} title='Last Name' value={user.lastName} originalValue={originalUser.lastName} onChange={(event) => {handleSetUser('lastName', event.target.value)}}/>
                    <CustomControl maxLength={50} lg={4} validator={validationGroup.createValidator(() => validateEmail(user.email))} disabled={terminated || !userIsEditable} title='Email Address' value={user.email} originalValue={originalUser.email} onChange={(event) => {handleSetUser('email', event.target.value)}}/>
                    <CustomControl maxLength={10} lg={4} validator={validationGroup.createValidator(() => validatePhoneNumber(user.phoneNumber))} disabled={terminated || !userIsEditable} title='Phone Number' value={user.phoneNumber} originalValue={originalUser.phoneNumber} onChange={(event) => {handleSetUser('phoneNumber', event.target.value)}}/>
                    <CustomControl lg={4} disabled={terminated || !userIsEditable} title='Date of Birth' type='date' value={user.dateOfBirth} originalValue={originalUser.dateOfBirth} onChange={(event) => {handleSetUser('dateOfBirth', event.target.value)}}/>
                    <PasswordProtectedControl maxLength={9} lg={4} user={user} handleSetOriginalUser={handleSetOriginalUser} handleSetUser={handleSetUser} disabled={terminated || !userIsEditable} label='Social Security Number' max={9} value={user.ssn} originalValue={originalUser.ssn} onChange={(event) => {handleSetUser('ssn', event.target.value)}}/>
                    <AddressControl disabled={terminated || !userIsEditable} title='Address' lg={4} address={user.address} originalValue={originalUser.address} setAddress={(value) => {handleSetUser('address', value)}}/>
                </Row>
                <Row style={{padding: 8, border: '1px solid lightgray', borderRadius: 6, marginBottom: 8}} >
                    <Col lg={12}>
                        <p style={{fontWeight: 'bold', width: '100%', textAlign: 'center'}}>Emergency Contact #1</p>
                    </Col>
                    <CustomControl maxLength={20} lg={4} disabled={terminated || !userIsEditable} title='First Name' value={user.eContact1FirstName} originalValue={originalUser.eContact1FirstName} onChange={(event) => {handleSetUser('eContact1FirstName', event.target.value)}}/>
                    <CustomControl maxLength={20} lg={4} disabled={terminated || !userIsEditable} title='Last Name' value={user.eContact1LastName} originalValue={originalUser.eContact1LastName} onChange={(event) => {handleSetUser('eContact1LastName', event.target.value)}}/>
                    <CustomControl maxLength={10} lg={4} disabled={terminated || !userIsEditable} title='Phone Number' value={user.eContact1PhoneNumber} originalValue={originalUser.eContact1PhoneNumber} onChange={(event) => {handleSetUser('eContact1PhoneNumber', event.target.value)}}/>
                    <CustomControl maxLength={50} lg={4} disabled={terminated || !userIsEditable} title='Email Address' value={user.eContact1Email} originalValue={originalUser.eContact1Email} onChange={(event) => {handleSetUser('eContact1Email', event.target.value)}}/>
                    <CustomControl maxLength={20} lg={4} disabled={terminated || !userIsEditable} title='Relationship' value={user.eContact1Relationship} originalValue={originalUser.eContact1Relationship} onChange={(event) => {handleSetUser('eContact1Relationship', event.target.value)}}/>
                    <AddressControl lg={4} disabled={terminated || !userIsEditable} title='Address' address={user.eContact1Address} originalValue={originalUser.eContact1Address} setAddress={(value) => {handleSetUser('eContact1Address', value)}}/>
                </Row>
                <Row style={{padding: 8, border: '1px solid lightgray', borderRadius: 6, marginBottom: 8}}>
                    <Col lg={12}>
                        <p style={{fontWeight: 'bold', width: '100%', textAlign: 'center'}}>Emergency Contact #2</p>
                    </Col>
                    <CustomControl maxLength={20} lg={4} disabled={terminated || !userIsEditable} title='First Name' value={user.eContact2FirstName} originalValue={originalUser.eContact2FirstName} onChange={(event) => {handleSetUser('eContact2FirstName', event.target.value)}}/>
                    <CustomControl maxLength={20} lg={4} disabled={terminated || !userIsEditable} title='Last Name' value={user.eContact2LastName} originalValue={originalUser.eContact2LastName} onChange={(event) => {handleSetUser('eContact2LastName', event.target.value)}}/>
                    <CustomControl maxLength={10} lg={4} disabled={terminated || !userIsEditable} title='Phone Number' value={user.eContact2PhoneNumber} originalValue={originalUser.eContact2PhoneNumber} onChange={(event) => {handleSetUser('eContact2PhoneNumber', event.target.value)}}/>
                    <CustomControl maxLength={50} lg={4} disabled={terminated || !userIsEditable} title='Email Address' value={user.eContact2Email} originalValue={originalUser.eContact2Email} onChange={(event) => {handleSetUser('eContact2Email', event.target.value)}}/>
                    <CustomControl maxLength={20} lg={4} disabled={terminated || !userIsEditable} title='Relationship' value={user.eContact2Relationship} originalValue={originalUser.eContact2Relationship} onChange={(event) => {handleSetUser('eContact2Relationship', event.target.value)}}/>
                    <AddressControl lg={4} disabled={terminated || !userIsEditable} title='Address' address={user.eContact2Address} originalValue={originalUser.eContact2Address} setAddress={(value) => {handleSetUser('eContact2Address', value)}}/>
                </Row>
                { (!terminated && userIsEditable) &&
                    <Row>
                        <CustomButton label='Save Changes' disabled={!validationGroup.isValid() || (!ssnValidator.isValid() && originalUser.ssn)} onClick={handleSaveChanges} isLoading={isLoading}/>
                    </Row>
                }
            </Container>
            <Modal show={modalSwitch === 'terminate'} onHide={() => setModalSwitch('')} centered size='lg'>
                <HRTerminate user={user} companyUserUid={user.companyUserUid}/>
            </Modal>
            <Modal backdrop='static' show={modalSwitch === 'rehire'} size='xl'>
                <HRHire csas={csas} hideModal={() => setModalSwitch('')} selectedUser={user}/>
            </Modal>
        </>
    );

    return (
        <LoadingWrapper isLoading={isLoading} onlyRenderIfTruthy={selectedEmployee ?? false}>
            {content}
        </LoadingWrapper>
    )
}