/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Decoder from "../../../decoding";
import { validateDecimal } from "../payrollTools";
import { getFakeUID } from "../../../tools";

export default class Deduction {
    id;
    type;
    label;
    amount;
    isReoccuring;
    canBeReoccuring;

    constructor(type, label, amount = 0, isReoccuring, canBeReoccuring = true) {
        this.id = getFakeUID();
        this.type = type;
        this.label = label;
        this.amount = amount;
        this.isReoccuring = isReoccuring;
        this.canBeReoccuring = canBeReoccuring
    }

    static initDefault() {
        return new Deduction(this.deductionTypes[0], 'ERR', 0, false, false);
    }

    static decodeFromEntry(json) {
        const decoder = new Decoder(json);
        
        const deductionArray = decoder.decode('deductions', Decoder.Array, {defaultValue: undefined, warn: true});
        if (deductionArray) {
            return deductionArray.map(dJson => Deduction.decode(dJson));
        } else {
            const apArray = decoder.decode('additionalPay', Decoder.Array, {defaultValue: [], warn: false});
            const oldDeductions = apArray.filter(ap => ap.type === 'Deduction');
            oldDeductions.forEach((d) => {
                d.type = 'Other (Flat Rate)';
            })
            return oldDeductions.map(oldDJson => Deduction.decode(oldDJson));
        }
    }

    static decode(json) {
        const decoder = new Decoder(json);
        const type = decoder.decode('type', Decoder.StringStrict);
        const label = decoder.decode('label', Decoder.StringStrict, {defaultValue: undefined, warn: false});
        const amount = decoder.decode('amount', Decoder.Decimal);
        const isReoccuring = decoder.decode('isReoccuring', Decoder.Boolean, {defaultValue: false, warn: true});
        const canBeReoccuring = decoder.decode('canBeReoccuring', Decoder.Boolean, {defaultValue: true, warn: true});

        if (decoder.checkForErrors()) {
            return new Deduction(type, label, amount, isReoccuring, canBeReoccuring);
        } else {
            return Deduction.initDefault();
        }
    }

    encode() {
        return {
            type: this.type,
            label: this.label,
            amount: validateDecimal(this.amount),
            isReoccuring: this.isReoccuring,
            canBeReoccuring: this.canBeReoccuring,
        }
    }

    duplicate() {
        return new Deduction(this.type, this.label, this.amount, this.isReoccuring, this.canBeReoccuring)
    }
    getAmount() {
        return validateDecimal(this.amount);
    }

    isPercentage() {
        return this.type === '401K (% of Gross)' || this.type === 'Other (% of Gross)';
    }

    is401KPercentage() {
        return this.label === '401K (% of Gross)' || this.type === '401K (% of Gross)';
    }
    isOtherPercentage(){
        return this.label === 'Other (% of Gross)' || this.type === 'Other (% of Gross)';
    }

    static automaticDeductionTypes() {
        return [
            '401k (Flat Rate)',
            '401K (% of Gross)',
            'Other (Flat Rate)',
            'Other (% of Gross)'
        ]
    }
    
    static deductionTypes = [
        '401k (Flat Rate)',
        '401K (% of Gross)',
        'Garnishments',
        'Other (Flat Rate)',
        'Other (% of Gross)',
        'Advance',
    ]
}