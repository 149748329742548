/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import PageSpinner from '../../../components/PageSpinner';
import QuickTable from '../../../components/QuickTable';
import CustomControl from '../../../components/CustomControl';
import CustomButton from '../../../components/CustomButton';
import Big from 'big.js';
import { Container, Modal, Row } from 'react-bootstrap';
import { bigToUsd, validateBig, validateUsd } from '../../Payroll/payrollTools';
import moment from 'moment';
import { usdFormatter} from '../../../tools';
import AlertModal from '../../../components/AlertModals/AlertModal';
import HRUserLoanInstallmentModal from './HRUserLoanInstallmentModal';
import { ApiRequest } from '../../../ApiManager.tsx';
import LoadingWrapper from '../../../components/LoadingWrapper.js';

export default function HRUserLoanEditor({companyUserIdentifier, selectedLoan, handleLoanCrud}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [name, setName] = useState('');
    const [amount, setAmount] = useState(0);
    const [installment, setInstallment] = useState(0);
    const [installments, setInstallments] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        if (selectedLoan) {
            loadData();
        }
    }, []);

    function loadData(){
        new ApiRequest('hr', 'getLoan', setIsLoading, (response) => {
            setName(response.loan.name);
            setAmount(response.loan.amount);
            setInstallment(response.loan.installment);
            setInstallments(response.loan.installments.sort(sortInstallments));
        }).withUid(selectedLoan).withNoAlertOnSuccess().send()
    }

    function handleSubmit(){
        const loan = {
            uid: selectedLoan,
            name: name,
            companyUserIdentifier: companyUserIdentifier,
            amount: validateUsd(amount),
            installment: validateUsd(installment),
            installmentSum: 0,
        }

        if(selectedLoan){
            new ApiRequest('hr', 'updateLoan', setIsSubmitting, () => {handleLoanCrud('update', loan)}).withData({loan: loan}).send();
        } else {
            new ApiRequest('hr', 'createLoan', setIsSubmitting, (response) => {loan.uid = response.uid; handleLoanCrud('create', loan)}).withData({loan: loan}).send();
        }
    }

    function deleteLoan(){
        new ApiRequest('hr', 'deleteLoan', setIsDeleting, () => handleLoanCrud('delete', selectedLoan)).withUid(selectedLoan).send();
    }

    const handleCreateInstallment = (installment) => {
        const newArray = Array.from(installments);
        newArray.push(installment);
        setInstallments(newArray.sort(sortInstallments));
        setShowModal(false);
        handleLoanCrud('installment', installment);
    }

    function sortInstallments(a, b){
        if (a.payrollPeriod?.periodStart ?? a.date < b.payrollPeriod?.periodStart ?? b.date) {
            return -1;
        } else if(a.payrollPeriod?.periodStart ?? a.date > b.payrollPeriod?.periodStart ?? b.date) {
            return 1;
        }
        return 0;
    }

    const payments = installments.map((i, index) => {
        let totalPaid = 0;
        for (let i = 0; i <= index; i++) {
            totalPaid += validateUsd(installments[i].amount);
        }
        let totalRemaining = validateUsd(amount) - totalPaid;
        return (
            <tr key={index}>
                <td>{i.date ? moment(i.date).format('MMM D, YYYY') : moment(i.payrollPeriod.periodStart).format('MMM D, YYYY') + ' - ' + moment(i.payrollPeriod.periodEnd).format('MMM D, YYYY')}</td>
                <td>{usdFormatter.format(i.amount)}</td>
                <td>{usdFormatter.format(totalPaid)}</td>
                <td>{usdFormatter.format(totalRemaining)}</td>
            </tr>
        )
    });

    const amountPaid = installments.reduce((prev, curr) => prev.plus(new Big(curr.amount)), new Big('0.00'));
    const amountRemaining =  validateBig(amount).sub(installments.reduce((prev, curr) => prev.plus(new Big(curr.amount)), new Big('0.00')));

    return (
        <>
            <Modal.Header closeButton>
                <div style={{display:'flex', gap:12}}>
                    <Modal.Title>{selectedLoan ? 'View/Edit Loan' : 'Create Loan'}</Modal.Title>
                    { selectedLoan && 
                        <CustomButton label={'Delete Loan'} onClick={()=>setShowDeleteModal(true)}/>
                    }
                </div>
            </Modal.Header>
            <Modal.Body>
                <LoadingWrapper isLoading={isLoading}>
                    <Container fluid>
                        <Row>
                            <CustomControl lg={4} type='text' title='Loan Name' maxLength={30} value={name} onChange={(event)=>{setName(event.target.value)}}/>
                            <CustomControl lg={4} type='number' title='Amount Borrowed' maxDecimal={99999.99} value={amount} onChange={(event)=>{setAmount(event.target.value)}}/>
                            <CustomControl lg={4} type='number' title='Installment Amount' maxDecimal={99999.99} value={installment} onChange={(event)=>{setInstallment(event.target.value)}}/>
                        </Row>
                    </Container>
                    { selectedLoan &&
                        <>
                            <div style={{display:'flex', flexDirection:'column', gap:10}}>
                                <span style={{fontWeight:'bold'}}>{'Total Amount Paid: ' + bigToUsd(amountPaid)}</span>
                                <span style={{marginTop:10, marginBottom:10, fontWeight:'bold'}}>{'Total Amount Remaining: ' + bigToUsd(amountRemaining)}</span>
                            </div>
                            <QuickTable title='Loan Payments' headers={['Payroll Period / Date', 'Payment', 'Running Total', 'Running Loan Balance']} rows={payments} size='sm'/>
                        </> 
                    }
                </LoadingWrapper>
            </Modal.Body>
            <Modal.Footer>
                {isLoading ? <></> :
                    <>
                        { selectedLoan && 
                            <CustomButton label='Create Manual Installment' onClick={() => {setShowModal(true)}}/>
                        }
                        <CustomButton isLoading={isSubmitting} label={selectedLoan ? 'Save Changes' : 'Create Loan'} onClick={handleSubmit}/>
                    </>
                }
            </Modal.Footer>
            <Modal show={showDeleteModal} onHide={() => {setShowDeleteModal(false)}} >
                <AlertModal
                    isLoading={isDeleting}
                    variant={'outline-danger'}
                    title='Delete Loan?' 
                    message='This loan data will not be recoverable upon deletion. Please be sure you want to delete this loan.' 
                    callBack={deleteLoan}
                    buttonLabel='Delete Loan'
                />
            </Modal>
            <Modal show={showModal} onHide={() => {setShowModal(false)}}>
                <HRUserLoanInstallmentModal loanIdentifier={selectedLoan} handleCreateInstallment={handleCreateInstallment}/>
            </Modal>
        </>
          
    );
}