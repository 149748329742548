/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { faBinoculars, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import Shadow from "./Shadow";
import CustomButton from "../../components/CustomButton";
import { ApiRequest } from "../../ApiManager.tsx";

export default function Admin({handleCheckAuthentication}) {
    const [modalSwitch, setModalSwitch] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleLogout = () => {
        new ApiRequest('authentication', 'logout', setIsLoading, () => {
            handleCheckAuthentication();
        }).send();
    }

    return (
        <div style={{padding: 12, height: '100%', width: '100%', display: 'flex', flexDirection: 'column', gap: 12}}>
            <Card>
                <Card.Body style={{textAlign: 'center'}}>
                    <h3 style={{color: 'var(--bs-primary)', fontWeight: 'bold'}}>Tactical Contractor</h3>
                    <h4 style={{margin: 0, opacity: 0.65}}>~ Admin Dashboard ~</h4>
                </Card.Body>
            </Card>
            <Container fluid style={{padding: 0}}>
                <Row>
                    <Col lg={6} style={{marginBottom: 12}}>
                        <Card style={{minHeight: 200}}>
                            <Card.Header style={{display: 'flex', alignItems: 'center', gap: 12}}>
                                <FontAwesomeIcon icon={faBinoculars} size='2x'/>
                                <h4 style={{margin: 0}}>Shadow User</h4>
                            </Card.Header>
                            <Card.Body>
                                <span style={{opacity: 0.65}}>Login as a user to view the website from their perspective. Stop shadowing at any time by pressing the <b>Stop Shadowing</b> button on the navbar.</span>
                            </Card.Body>
                            <Card.Footer style={{display: 'flex', justifyContent: 'flex-end', gap: 12}}>
                                <Button variant="outline-primary" onClick={() => {setModalSwitch('shadow')}}>Shadow User</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col lg={6} style={{marginBottom: 12}}>
                        <Card style={{minHeight: 200}}>
                            <Card.Header style={{display: 'flex', alignItems: 'center', gap: 12}}>
                                <FontAwesomeIcon icon={faRightFromBracket} size='2x'/>
                                <h4 style={{margin: 0}}>Logout</h4>
                            </Card.Header>
                            <Card.Body>
                                <span style={{opacity: 0.65}}>Return to the login page.</span>
                            </Card.Body>
                            <Card.Footer style={{display: 'flex', justifyContent: 'flex-end', gap: 12}}>
                                <CustomButton label='Logout' onClick={handleLogout} isLoading={isLoading}/>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal backdrop='static' show={modalSwitch == 'shadow'} onHide={() => {setModalSwitch('')}} centered size='xl'>
                <Shadow handleCheckAuthentication={handleCheckAuthentication}/>
            </Modal>
        </div>
    )
}