/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import './NavigationBar.css';
import './NavigationBarLink.css'
import Modal from 'react-bootstrap/Modal';
import { faUser, faClock, faCalendar, faCalendarXmark, faFolderOpen, faMessage, faHandshake } from '@fortawesome/free-regular-svg-icons';
import { 
    faChartLine,
    faDollarSign,
    faTableColumns, 
    faBuilding, 
    faGripLines, 
    faUsers, 
    faToolbox,
    faUsersBetweenLines,
    faRightFromBracket,
    faCalculator,
    faWallet,
    faFileSignature,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../assets/logo.png';
import NavigationBarHeader from './NavigationBarHeader';
import NavigationBarLink from './NavigationBarLink';
import CompanySelector from '../CompanySelector';
import Cookies from 'universal-cookie';
import LogoutModal from './LogoutModal';
import moment from 'moment';
import { ApiRequest } from '../../ApiManager.tsx';

function NavigationBar({auth, company, companies, handleCheckAuthentication}) {
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const permissions = new Cookies().get('permissions');
    const userData = new Cookies().get('userData');
    const hasHRPermission = 
        permissions.hr_profile === '1' 
        || permissions.hr_schedule === '1' 
        || permissions.hr_timesheet === '1' 
        || permissions.hr_pay === '1' 
        || permissions.hr_safety === '1' 
        || permissions.hr_bwc === '1' 
        || permissions.he_documents === '1' 
        || permissions.hr_permissions === '1'
    ;

    const handleStopShadowing = () => {
        new ApiRequest('admin', 'stopShadowing', () => {}, () => {
            handleCheckAuthentication();
        }).send();
    }

    return (
        <div className='nav-bar-wrapper' style={{flexShrink: 0}}>
            {/* <button type="button" className={'nav-bar-collapse-button' + (collapsed ? ' nav-bar-collapse-button-collapsed' : '')} onClick={() => {setCollapsed(!collapsed)}}>
                <FontAwesomeIcon icon={collapsed ? faCaretRight : faCaretLeft}/>
            </button> */}
            <img src={logo} alt={'TTA LOGO'} id='logo' style={{paddingLeft: 8, paddingRight: 8}}/>
            <div className='hidden-scrollbar' style={{width: '100%', flex: 1, overflowY: 'scroll'}}>
                <div style={{padding: '0px 9px 0px 8px'}}>
                    <CompanySelector companies={companies}/>
                    <div style={{marginBottom: 16}}>
                        <NavigationBarHeader title={'Account'} icon={faUser}/>
                        <NavigationBarLink exact title={'Dashboard'} icon={faTableColumns} destination={'/'}/>
                        { permissions.profile === '1' &&
                            <NavigationBarLink title={userData.firstName + '\'s Profile'} icon={faUser} destination={'/profile'}/>
                        }
                        { permissions.timeClock === '1' &&
                            <NavigationBarLink title={'Time Clock'} icon={faClock} destination={'/time-clock'}/>
                        }
                        { permissions.mySchedule === '1' &&
                            <NavigationBarLink title={'My Schedule'} icon={faCalendar} destination={'/my-schedule'}/>
                        }
                        { permissions.daysOff === '1' &&
                            <NavigationBarLink title={'Days Off'} icon={faCalendarXmark} destination={'/days-off'}/>
                        }
                        <NavigationBarLink title={'My Documents'} icon={faFolderOpen} destination={'/my-documents'}/>
                        <NavigationBarLink title={'My Messages'} icon={faMessage} destination={'/my-messages'}/>
                    </div>
                    <div style={{marginBottom: 16}}>
                        { (permissions.myCompany === '1' || permissions.scheduleMatch === '1') &&    
                            <>
                                <NavigationBarHeader title={'ISP Management'} icon={faBuilding}/>

                                { permissions.myCompany === '1' &&
                                    <NavigationBarLink title={'My Company'} icon={faUsers} destination={'/my-company'}/>
                                }
                                { permissions.scheduleMatch === '1' &&
                                    <NavigationBarLink title={'Scheduling'} icon={faGripLines} destination={'/schedule-match'}/>
                                }
                            </>
                        }
                    </div>
                    <div style={{marginBottom: 16}}>
                        { (hasHRPermission || permissions.payroll === '1') && 
                            <>
                                <NavigationBarHeader title={'Operations'} icon={faToolbox}/>

                                { permissions.payroll === '1' &&
                                    <NavigationBarLink title={'Payroll'} icon={faDollarSign} destination={'/payroll'}/>
                                }
                                {permissions.reports === '1' && <NavigationBarLink title={'Reports'} icon={faChartLine} destination={'/reports'}/>}
                                { hasHRPermission &&
                                    <NavigationBarLink title={'Human Resources'} icon={faUsersBetweenLines} destination={'/human-resources'}/>
                                }
                                {
                                    userData['recruitingUrl'] 
                                    ?   <div style={{paddingRight: 0, paddingLeft: 0}}>
                                            <a href={userData['recruitingUrl']} target='_blank' className='nav-bar-link' style={{cursor: 'pointer'}}>
                                                <div style={{width: 25, display: 'flex', justifyContent: 'center'}}>
                                                    <FontAwesomeIcon icon={faHandshake} style={{marginRight: 8}}/>
                                                </div>
                                                <p style={{fontSize: 18, margin: 0, }}>Recruiting</p>
                                            </a>
                                        </div> 
                                    : permissions.hr_pay === '1' 
                                        ? <NavigationBarLink title={'Recruiting Sign-Up'} icon={faFileSignature} destination={'/recruiting'}/>
                                    : <></>
                                }
                            </>
                        }
                    </div>
                    { permissions.budget === '1' &&
                        <>
                            <NavigationBarHeader title={'Finance'} icon={faToolbox}/>
                            <NavigationBarLink title={'PCAT'} icon={faCalculator} destination={'/pcat'}/>
                            <NavigationBarLink title={'Budget'} icon={faWallet} destination={'/budget'}/>
                            { userData.title == 'AO' && !!company.bookkeepingEnabled && 
                                <NavigationBarLink title={'Bookkeeping'} icon={faCalculator} destination={'/bookkeeping'}/>
                            }
                        </>
                    }
                </div>
                <div style={{width:'100%', height: 1, backgroundColor: 'lightgray', margin: '16px 0px 16px 0px'}}/>
                <div style={{padding: '0px 9px 0px 8px'}}>
                    <button className='nav-bar-link' style={{border: 'none', width: '100%'}} onClick={() => {setShowLogoutModal(true)}}>
                        <div style={{width: 25, display: 'flex', justifyContent: 'center'}}>
                            <FontAwesomeIcon icon={faRightFromBracket} style={{marginRight: 8}}/>
                        </div>
                        <p style={{fontSize: 18, margin: 0, }}>Logout</p>
                    </button>
                </div>
                { auth?.adminIdentifier && 
                    <div style={{padding: '0px 9px 0px 8px'}}>
                        <button className='nav-bar-link' style={{border: 'none', width: '100%', color: 'red'}} onClick={handleStopShadowing}>
                            <div style={{width: 25, display: 'flex', justifyContent: 'center'}}>
                                <FontAwesomeIcon icon={faRightFromBracket} style={{marginRight: 8}}/>
                            </div>
                            <p style={{fontSize: 18, margin: 0}}>Stop Shadowing</p>
                        </button>
                    </div>
                }
            </div>
            
            <div style={{textAlign: 'center', fontSize: 12}}>
                <p style={{margin: 0}}>{`Copyright © ${moment().format('YYYY')}`}</p>
                <p style={{margin: 0}}>Tactical Transportation Advisors</p>
                <p style={{margin: 0}}>{`Version 1.9.6`}</p>
            </div>
            <Modal show={showLogoutModal} onHide={() => {setShowLogoutModal(false)}} centered>
                <LogoutModal handleCheckAuthentication={handleCheckAuthentication}/>
            </Modal>
        </div>
    );
}

export default NavigationBar;