/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react"
import {Document, Page, View, Text, Image } from "@react-pdf/renderer"
import logo from '../../../assets/TTA-Logo-150.png';
import Cookies from "universal-cookie";
import moment from "moment";

export default function OSHAReportPdf({startDate, endDate, bwcData}){

    const headers = (
        <View style={{border:'1px solid black', display:'flex', flexDirection:'row'}}>
            <View style={{borderRight:'1px solid black', fontSize:11, width:'12.5%',padding:5}}>
                <Text style={{textAlign:'center'}}>Name</Text>
            </View>
            <View style={{borderRight:'1px solid black', fontSize:11, width:'12.5%',padding:5}}>
                <Text style={{textAlign:'center'}}>Job Title</Text>
            </View>
            <View style={{borderRight:'1px solid black', fontSize:11,width:'12.5%',padding:5}}>
                <Text style={{textAlign:'center'}}>Date Of Injury</Text>
            </View>
            <View style={{borderRight:'1px solid black', fontSize:11,width:'12.5%',padding:5}}>
                <Text style={{textAlign:'center'}}>Where Event Occurred</Text>
            </View>
            <View style={{borderRight:'1px solid black', fontSize:11,width:'22.5%',padding:5}}>
                <Text style={{textAlign:'center'}}>Injury / Illness Description</Text>
            </View>
            <View style={{borderRight:'1px solid black', fontSize:11,width:'10%',padding:5}}>
                <Text style={{textAlign:'center'}}>Classify Case</Text>
            </View>
            <View style={{borderRight:'1px solid black', fontSize:11,width:'10%',padding:5}}>
                <Text style={{textAlign:'center'}}>Days Off Work</Text>
            </View>
            <View style={{borderRight:'1px solid black', fontSize:11,width:'10%',padding:5}}>
                <Text style={{textAlign:'center'}}>Days On Restriction</Text>
            </View>
            <View style={{borderRight:'1px solid black', fontSize:11,width:'10%',padding:5}}>
                <Text style={{textAlign:'center'}}>Injury or Illness</Text>
            </View>
        </View>
    )

    const tables = [];
    bwcData.forEach((bwc)=>{

        let daysOffWork = 0;
        if(!bwc.rtwLightDutyDate && !bwc.rtwFullDutyDate){
                daysOffWork = moment().diff(moment(bwc.injuryReportDate),'days');
        } else if (bwc.rtwLightDutyDate){
                daysOffWork = moment(bwc.rtwLightDutyDate).diff(moment(bwc.injuryReportDate),'days');
        } else if(!bwc.rtwLightDutyDate && bwc.rtwFullDutyDate){
                daysOffWork = moment(bwc.rtwFullDutyDate).diff(moment(bwc.injuryReportDate),'days');
        }

        let daysOnRestriction = 0;
        if(bwc.rtwLightDutyDate && !bwc.rtwFullDutyDate){
                daysOnRestriction = moment().diff(moment(bwc.rtwLightDutyDate),'days');
        } else if(bwc.rtwLightDutyDate && bwc.rtwFullDutyDate){
                daysOnRestriction = moment(bwc.rtwFullDutyDate).diff(moment(bwc.rtwLightDutyDate),'days');
        }

        const rows = (
            <View key ={bwc.uid} style={{display:'flex', border:'1px solid black', flexDirection:'row'}}>
                <View style={{borderRight:'1px solid black', fontSize:10, width:'12.5%', padding:5}}>
                    <Text style={{textAlign:'center'}}>{bwc.user.lastName + ', ' + bwc.user.firstName + ' ' + bwc.user.middleName.substring(0,1)}</Text>
                </View>
                <View style={{borderRight:'1px solid black', fontSize:10, width:'12.5%', padding:5}}>
                    <Text style={{textAlign:'center'}}>{bwc.user.title}</Text>
                </View>
                <View style={{borderRight:'1px solid black', fontSize:10,width:'12.5%',padding:5}}>
                    <Text style={{textAlign:'center'}}>{moment(bwc.dateOfInjury).format('MMM D, YYYY')}</Text>
                </View>
                <View style={{borderRight:'1px solid black', fontSize:10,width:'12.5%',padding:5}} wrap={true}>
                    <Text style={{textAlign:'center'}}>{bwc.eventLocation}</Text>
                </View>
                <View style={{borderRight:'1px solid black', fontSize:10,width:'22.5%',padding:5}} wrap={true}>
                    <Text style={{textAlign:'center'}}>{bwc.notes}</Text>
                </View>
                <View style={{borderRight:'1px solid black', fontSize:10,width:'10%',padding:5}}>
                    <Text style={{textAlign:'center'}}>{bwc.classifyCase}</Text>
                </View>
                <View style={{borderRight:'1px solid black', fontSize:10,width:'10%',padding:5}}>
                    <Text style={{textAlign:'center'}}>{daysOffWork}</Text>
                </View>
                <View style={{borderRight:'1px solid black', fontSize:10,width:'10%',padding:5}}>
                    <Text style={{textAlign:'center'}}>{daysOnRestriction}</Text>
                </View>
                <View style={{borderRight:'1px solid black', fontSize:10,width:'10%',padding:5}}>
                    <Text style={{textAlign:'center'}}>{bwc.injuryOrIllness}</Text>
                </View>
            </View>
            
        )
        tables.push(rows);
    });

    return (
        <Document> 
            <Page size='LETTER' style={{padding: '0.5in', flexDirection: 'column'}} orientation='landscape'>
                <View fixed style={{flexDirection: 'row', width: '100%', justifyContent: 'space-between', marginBottom:15}}>
                    <Image src={logo} style={{width: 150}}/>
                    <Text style={{fontSize: 12, color: 'gray'}}>{new Cookies().get('companyName')}</Text>
                </View>
                <Text style={{textAlign:'center', paddingBottom:4, textDecoration:'underline'}}>OHSA Report</Text>
                <Text style={{opacity:0.5,textAlign:'center'}}>{moment(startDate).format('MMM D, YYYY')+' - '+moment(endDate).format('MMM D, YYYY')}</Text>
                <View style={{border: '1px solid black', display:'flex', marginTop:10}}>
                   {headers}
                   {tables}
                </View>
            </Page>
        </Document>
    )
}