/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import { Validator, validateGreaterThanZero } from "../../../../../validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faPlus } from "@fortawesome/free-solid-svg-icons";
import CustomToolTip from "../../../../../components/CustomToolTip";
import QuickTable from "../../../../../components/QuickTable";
import PayrollDBonusesModal from "./PayrollDBonusesModal";

export default function PayrollEmployeeDBonusesEditor({entry, handleSet, handleAddDBonusToAllEntries}) {
    const [showModal, setShowModal] = useState(false);

    const handleRemoveDBonus = (id) => {
        const newArr = entry.dBonuses.filter((element) => {
            return element.id !== id;
        });
        handleSet(entry.companyUserIdentifier, 'dBonuses', newArr);
    }

    const handleSetDBonus = (id, key, value) => {
        const newArr = Array.from(entry.dBonuses);
        newArr.find(r => r.id === id)[key] = value;
        handleSet(entry.companyUserIdentifier, 'dBonuses', newArr);
    }

    const handleAddDBonus = (dBonus, applyToAll) => {
        if (applyToAll) {
            handleAddDBonusToAllEntries(dBonus);
        } else {
            const newArr = Array.from(entry.dBonuses);
            newArr.push(dBonus);
            handleSet(entry.companyUserIdentifier, 'dBonuses', newArr);
        }
        setShowModal(false);
    }

    const dBonusRows = entry.dBonuses.map((dBonus) => {
        const validator = new Validator(() => validateGreaterThanZero(dBonus.amount));
        return (
            <tr key={dBonus.id}>
                <td>{dBonus.type}</td>
                <td>
                    <InputGroup>
                        <InputGroup.Text style={{padding: '2px 6px 2px 6px'}}>$</InputGroup.Text>
                        <Form.Control isInvalid={!validator.isValid()} style={{padding: '2px 6px 2px 6px'}} type='number' min={0} value={dBonus.amount} placeholder='Amount' onChange={(event) => {handleSetDBonus(dBonus.id, 'amount', event.target.value.replace('-', ''))}}/>
                        { validator ? <Form.Control.Feedback type='invalid'>{validator.getInvalidMessage()}</Form.Control.Feedback> : ''}
                    </InputGroup>
                </td>
                <td>
                    <Button style={{color: 'white', padding: '2px 6px 2px 6px', width: '100%'}} onClick={() => handleRemoveDBonus(dBonus.id)}>
                        <FontAwesomeIcon icon={faTrashCan}/>
                    </Button>
                </td>
            </tr>
        )
    });

    return (
        <Card>
            <Card.Body>
                <div style={{display: 'flex', alignItems:'center', gap: 12}}>
                    <Card.Title>Discretionary Bonuses</Card.Title>
                    <Button style={{color: 'white', marginBottom: 8, borderRadius: 14, padding: '0px 5px 0px 5px'}} onClick={() => {setShowModal(true)}}>
                        <FontAwesomeIcon icon={faPlus} style={{color: 'white'}}/>
                    </Button>
                </div>
                { dBonusRows.length > 0 &&
                    <QuickTable headers={['Type', 'Amount', '']} widths={[null, null, 40]} rows={dBonusRows} size={'sm'}/>
                }
            </Card.Body>
            <Modal show={showModal} onHide={() => {setShowModal(false)}} centered size='lg'>
                <PayrollDBonusesModal handleAddDBonus={handleAddDBonus}/>
            </Modal>
        </Card>
    )
}