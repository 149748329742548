/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import React, { useEffect, useState } from "react"
import CustomButton from "../../components/CustomButton";
import HistoricalCSASummary from "./HistoricalCSASummary";
import { Row, Modal, Container, Button, Col, InputGroup, Dropdown } from "react-bootstrap";
import Big from "big.js";
import ProposalElements from "./ProposalElements";
import PCATPdf from "./PCATPdf";
import { PDFViewer } from "@react-pdf/renderer";
import CustomControl from "../../components/CustomControl";
import { Prompt } from "react-router-dom";
import PCATModel from "./Models/PCAT";
import { Validator, validateExistence } from "../../validation";
import AlertModal from "../../components/AlertModals/AlertModal";
import FutureAnnualRevenue from "./FutureAnnualRevenue";
import { validateDecimal } from "../../tools";
import PCATCalculateDeliveryPercentage from "./PCATCalculateDeliveryPercentage";
import { toFixedMax } from "../Payroll/payrollTools";
import PCATLoadModal from "./PCATLoadModal";
import PCATProposal from "./Models/PCATProposal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { ApiRequest } from "../../ApiManager.tsx";

export default function PCAT({}){
    const [pcat, setPcat] = useState(PCATModel.initDefault());
    const [originalPcat, setOriginalPcat] = useState(undefined);
    const [modalSwitch, setModalSwitch] = useState('load');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    Big.DP = 5;

    useEffect(()=>{
        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        }
    })

    function handleSubmit(){
        if(pcat.uid > 0){
            new ApiRequest('pcat', 'update', setIsSubmitting, () => setOriginalPcat(pcat.duplicate())).withData({pcat: pcat.encode()}).send();
        }else{
            new ApiRequest('pcat', 'create', setIsSubmitting, (response) => {handleSetPcat('uid', response.uid); setOriginalPcat(pcat.duplicate())}).withData({pcat: pcat.encode()}).send();
        }
    }

    function handleDeletePcat(){
        new ApiRequest('pcat', 'delete', setIsDeleting, () => {
            setPcat(PCATModel.initDefault());
            setOriginalPcat(undefined);
            setModalSwitch('load');
        }).withUid(pcat.uid).send()
    }

    function handleCreatePcat() {
        const pcat = PCATModel.initDefault();
        setPcat(pcat);
        setOriginalPcat(undefined);
        hideModal();
    }

    function handleAddProposal(isFedex) {
        const proposals = Array.from(pcat.proposals);
        const proposal = PCATProposal.initDefault();
        proposal.isFedex = isFedex;
        proposals.push(proposal);
        handleSetPcat('proposals', proposals);
    }

    function handleSetPcat(key, value) {
        const newPcat = pcat.duplicate();
        newPcat[key] = value;
        setPcat(newPcat);
    }

    function handleLoadPcat(pcat) {
        setPcat(PCATModel.decode(pcat));
        setOriginalPcat(PCATModel.decode(pcat));
        hideModal();
    }

    function handleBeforeUnload(event) {
        event.preventDefault();
        return (event.returnValue = "");
    }

    function hideModal() {
        setModalSwitch('')
    }

    const unsavedChanges = pcat && (!originalPcat || !pcat.equals(originalPcat));

    return (
        <div style={{height:'100%', overflowY:'scroll', display: 'flex', flexDirection: 'column', backgroundColor: 'white', padding: 12}}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 12}}>
                <div/>
                <h3>{`Proposal Capture & Analysis Tool`}</h3>
                <div style={{display: 'flex', gap: 8}}>
                    <CustomButton label={unsavedChanges ? 'Save Changes' : 'Saved'} disabled={!unsavedChanges || !pcat.title} onClick={handleSubmit} isLoading={isSubmitting}/>
                    <Dropdown>
                        <Dropdown.Toggle variant="outline-primary"><FontAwesomeIcon icon={faBars}/></Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setModalSwitch('load')}>Load PCAT</Dropdown.Item>
                            <Dropdown.Item onClick={() => setModalSwitch('create')}>Create New PCAT</Dropdown.Item>
                            <Dropdown.Item onClick={() => setModalSwitch('print')}>Print</Dropdown.Item>
                            { pcat.uid > 0 &&
                                <>
                                    <Dropdown.Divider/>
                                    <Dropdown.Item onClick={() => setModalSwitch('delete')}>Delete PCAT</Dropdown.Item>
                                </>
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div style={{display:'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', gap: 12}}>
                <Container fluid>
                    <Row style={{justifyContent: 'center'}}>
                        <CustomControl maxLength={50} lg={6} validator={new Validator(() => validateExistence(pcat.title))} title='PCAT Title' value={pcat.title} originalValue={originalPcat?.title} onChange={(event) => handleSetPcat('title', event.target.value)}/>
                    </Row>
                    <Row>
                        <Col lg={6} xl={6} xxl={3}>
                            <InputGroup>
                                <CustomControl originalValue={(originalPcat ? toFixedMax(validateDecimal(originalPcat.deliveryPercentage), 3) : undefined)} disabled title='Package Delivery Percentage' value={toFixedMax(validateDecimal(pcat.deliveryPercentage), 3)}/>
                                <Button variant="outline-primary" onClick={() => setModalSwitch('deliveryPercentage')}>Edit</Button>
                            </InputGroup>
                        </Col>
                        <CustomControl lg={6} xl={6} xxl={3} type='number' title='Base Pump Price' value={pcat.basePumpPrice} originalValue={originalPcat?.basePumpPrice} onChange={(event) => handleSetPcat('basePumpPrice', event.target.value)}/>
                        <CustomControl lg={6} xl={6} xxl={3} type='date' title='3-Offer Deadline' value={pcat.threeOfferDeadline} originalValue={originalPcat?.threeOfferDeadline} onChange={(event) => handleSetPcat('threeOfferDeadline', event.target.value)}/>
                        <CustomControl lg={6} xl={6} xxl={3} type='date' title='Negotiations Deadline' value={pcat.negotiationsDeadline} originalValue={originalPcat?.negotiationsDeadline} onChange={(event) => handleSetPcat('negotiationsDeadline', event.target.value)}/>
                    </Row>
                </Container>
            </div>
            <div>
                <HistoricalCSASummary pcat={pcat} originalPcat={originalPcat} handleSetPcat={handleSetPcat}/>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginBottom: 8, width: '100%'}}>
                <Dropdown style={{width: '100%', textAlign: 'center'}}>
                    <Dropdown.Toggle variant="outline-primary" style={{width: '50%', maxWidth: 300}}>Add Proposal</Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleAddProposal(false)}>IC Proposal</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleAddProposal(true)}>FedEx Proposal</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            { pcat.proposals.length > 0 &&
                <>
                    <ProposalElements pcat={pcat} originalPcat={originalPcat} handleSetPcat={handleSetPcat}/>
                    <FutureAnnualRevenue pcat={pcat}/>
                </>
            
            }
            <Modal show={modalSwitch == 'load'} onHide={hideModal} size="lg">
                <PCATLoadModal handleCreatePcat={handleCreatePcat} handleLoadPcat={handleLoadPcat}/>
            </Modal>
            <Modal show={modalSwitch === 'deliveryPercentage'} onHide={hideModal}>
                <PCATCalculateDeliveryPercentage handleSetPcat={handleSetPcat} hideModal={hideModal}/>
            </Modal>
            <Modal show={modalSwitch === 'print'} onHide={hideModal} size='fullscreen'>
                <Modal.Header closeButton>
                    <Modal.Title>Print PCAT</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{overflowY: 'hidden'}}>   
                    <PDFViewer style={{width: '100%', height: '100%'}}>
                        <PCATPdf pcat={pcat}/>
                    </PDFViewer>
                </Modal.Body>
            </Modal>
            <Modal show={modalSwitch === 'create'} onHide={hideModal}>
                <AlertModal isLoading={isSubmitting} title='Create PCAT' message='WARNING: Any unsaved changes will be lost!' buttonLabel='Create New PCAT' callBack={handleCreatePcat}/>
            </Modal>
            <Modal show={modalSwitch === 'delete'} onHide={hideModal} >
                <AlertModal isDeleting={isDeleting} variant='outline-danger' title='Delete PCAT?' message='This cannot be undone.' buttonLabel='Delete PCAT' callBack={handleDeletePcat}/>
            </Modal>
            <Prompt when={true} message={'Any unsaved changes will be lost!'}/>
        </div>
        
    )
}