/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Validator, validateGreaterThanZero } from "../../../../../../validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import CustomToolTip from "../../../../../../components/CustomToolTip";
import QuickTable from "../../../../../../components/QuickTable";

export default function PayrollEmployeeAdditionalPayEditor({week, handleSetWeek, index}) {

    const removeAdditionalPay = (id) => {
        const newArr = week.additionalPay.filter((element) => {
            return element.id !== id;
        });
        handleSetWeek(index, 'additionalPay', newArr);
    }

    const handleSetAdditionalPay = (id, key, value) => {
        const newArr = Array.from(week.additionalPay);
        newArr.find(r => r.id === id)[key] = value;
        handleSetWeek(index, 'additionalPay', newArr);
    }

    const additionalPayRows = week.additionalPay.map((additionalPay) => {
        const validator = new Validator(() => validateGreaterThanZero(additionalPay.amount));
        return (
            <tr key={additionalPay.id}>
                <td>{additionalPay.type}</td>
                <td>
                    <InputGroup>
                        <InputGroup.Text style={{padding: '2px 6px 2px 6px'}}>$</InputGroup.Text>
                        <Form.Control isInvalid={!validator.isValid()} style={{padding: '2px 6px 2px 6px'}} type='number' min={0} value={additionalPay.amount} placeholder='Amount' onChange={(event) => {handleSetAdditionalPay(additionalPay.id, 'amount', event.target.value.replace('-', ''))}}/>
                        { validator ? <Form.Control.Feedback type='invalid'>{validator.getInvalidMessage()}</Form.Control.Feedback> : ''}
                    </InputGroup>
                </td>
                <td>
                    <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
                        <Form.Check disabled={!additionalPay.canBeReoccuring} type='switch' checked={additionalPay.isReoccuring} onChange={() => {handleSetAdditionalPay(additionalPay.id, 'isReoccuring', !additionalPay.isReoccuring)}}/>
                        { additionalPay.canBeReoccuring ?
                            <CustomToolTip text={'Next payroll period, this Additional Pay will be automatically added to this employee'}/>
                            :
                            <CustomToolTip text={'This Additional Pay was automatically generated from this employee\'s pay'}/>
                        }
                    </div>
                </td>
                <td>
                    <Button style={{color: 'white', padding: '2px 6px 2px 6px', width: '100%'}} onClick={() => removeAdditionalPay(additionalPay.id)}>
                        <FontAwesomeIcon icon={faTrashCan}/>
                    </Button>
                </td>
            </tr>
        )
    });

    return (
        <>
            { additionalPayRows.length > 0 &&
                <QuickTable title='Additional Pay' headers={['Type', 'Amount', 'Reoccuring?', '']} widths={[null, null, 100, 40]} rows={additionalPayRows} size={'sm'}/>
            }
        </>
    )
}