/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import PayrollPeriodColumnInclusion from "./PayrollPeriodColumnInclusion";
import PayrollPeriodNDBonusColumnInclusion from "./PayrollPeriodNDBonusColumnInclusion";
import PayrollPeriodDeductionColumnInclusion from "./PayrollPeriodDeductionColumnInclusion";
import PayrollPeriodNonBonusAPColumnInclusion from "./PayrollPeriodNonBonusAPColumnInclusion";

export default function PayrollPeriodHeadersRow(entries, periodStart, periodEnd) {
    const columnInclusion = PayrollPeriodColumnInclusion(entries);
    const ndBonusColumnInclusion = PayrollPeriodNDBonusColumnInclusion(entries);
    const deductionColumnInclusion = PayrollPeriodDeductionColumnInclusion(entries);
    const apNonBonusColumnInclusion = PayrollPeriodNonBonusAPColumnInclusion(entries);

    const tableHeaders = ['Name', 'CSA', 'BWC Code'];
    if (columnInclusion.includeOvertimeColumns && (moment(periodEnd).add(1,'day')).diff(moment(periodStart), 'weeks') > 1) {
        tableHeaders.push('Week #');
    }
    if (columnInclusion.includeOvertimeColumns) {tableHeaders.push('GVWR < 10k');}
    if (columnInclusion.includeSalary) {tableHeaders.push('Salary');}
    if (columnInclusion.includeDaily) {tableHeaders.push('Daily Rate');}
    if (columnInclusion.includeHourly) {tableHeaders.push('Hourly Rate');}
    if (columnInclusion.includeOvertimeColumns) {
        tableHeaders.push('Adjusted Hourly Rate');
        tableHeaders.push('Overtime Rate');
    }
    if (columnInclusion.includeStop) {tableHeaders.push('Rate Per Stop');}
    if (columnInclusion.includeMile) {tableHeaders.push('Rate Per Mile');}
    if (columnInclusion.includeExtraDay) {tableHeaders.push('Extra Day Rate');}
    if (columnInclusion.includeDaily) {tableHeaders.push('Days Worked');}
    if (columnInclusion.includeHourly) {tableHeaders.push('Hours Worked');}
    if (columnInclusion.includeOvertimeColumns) {tableHeaders.push('Overtime Hours');}
    if (columnInclusion.includeStop) {tableHeaders.push('Stops');}
    if (columnInclusion.includeMile) {tableHeaders.push('Miles');}
    if (columnInclusion.includeExtraDay) {tableHeaders.push('Extra Days');}
    if (columnInclusion.includePto) {tableHeaders.push('PTO Hours');}
    if (columnInclusion.includeRegularWages) {tableHeaders.push('Regular Wages');}
    if (columnInclusion.includeHourly) {tableHeaders.push('Hourly Wages');}
    if (columnInclusion.includeOvertimeColumns) {tableHeaders.push('Overtime Wages');}
    if (columnInclusion.includeStop) {tableHeaders.push('Stop Wages');}
    if (columnInclusion.includeOTStopWages) {tableHeaders.push('OT Stop Wages');}
    if (columnInclusion.includeStop && columnInclusion.includeOTStopWages) {tableHeaders.push('Stop/OT Stop Wages');}
    if (columnInclusion.includeMile) {tableHeaders.push('Mile Wages');}
    if (columnInclusion.includeExtraDay) {tableHeaders.push('Extra Day Wages');}
    if (columnInclusion.includeIncentiveWages) {tableHeaders.push('Incentive Wages');}
    if (columnInclusion.includeStandByWages) {tableHeaders.push('Stand-By Wages');}
    if (columnInclusion.includeAutoOvertime) {tableHeaders.push('Extra Day Pay');}
    if (columnInclusion.includePto) {tableHeaders.push('PTO Wages');}
    if (columnInclusion.includeHolidayWages) {tableHeaders.push('Holiday Wages');}
    
    ndBonusColumnInclusion.forEach((ndBonusType) => {
        tableHeaders.push(ndBonusType);
    })
    
    if (columnInclusion.includeHolidayBonus) {tableHeaders.push('Holiday Bonus');}
    if (columnInclusion.includeOtherDiscretionaryBonus) {tableHeaders.push('Other Discretionary Bonus');}
    
    Object.entries(apNonBonusColumnInclusion).forEach(([key, value]) => {
        for (let i = 0; i < value; i++) {
            tableHeaders.push(`${key} #${i + 1}`);
        }
    })
    
    tableHeaders.push('Gross');

    const loanRowCount = entries.reduce((prev, curr) => {
        return Math.max(prev, curr.getEnabledLoans().length);
    }, 0);

    for (let i = 1; i <= loanRowCount; i++) {
        tableHeaders.push(`Loan #${i}`);
    }

    const ticketRowCount = entries.reduce((prev, curr) => {
        return Math.max(prev, curr.getEnabledTickets().filter(t => t.getAmountToPay(curr) > 0).length);
    }, 0);

    for (let i = 1; i <= ticketRowCount; i++) {
        tableHeaders.push(`Fine Ticket Damage #${i}`);
    }
    if (columnInclusion.inlcudeChildSupport) {tableHeaders.push('Child Support');}
    if (columnInclusion.includeMedical) {tableHeaders.push('Medical Insurance');}
    if (columnInclusion.includeDental) {tableHeaders.push('Dental Insurance');}
    if (columnInclusion.includeVision) {tableHeaders.push('Vision Insurance');}

    deductionColumnInclusion.forEach((deductionType) => {
        tableHeaders.push(deductionType);
    })

    tableHeaders.push('Total Deductions');
    tableHeaders.push('Notes');

    return tableHeaders;
}