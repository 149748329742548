/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import CustomControl from "../../components/CustomControl";
import RadioControl from "../../components/RadioControl";
import CustomButton from "../../components/CustomButton";
import { ValidationGroup,validateExistence } from "../../validation";
import AvailabilityControl from "../../components/AvailabilityControl";
import { encodeAvailability, parseAvailability } from "../../tools";
import LoadingWrapper from "../../components/LoadingWrapper";
import { ApiRequest } from "../../ApiManager.tsx";

export default function MyCompanyWorkArea({data, handleWorkAreaSubmission, restrictedToCsa}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [csas, setCsas] = useState([]);
    const [workAreaName, setWorkAreaName] = useState(data ? data.workAreaName : '');
    const [csa, setCsa] = useState(data ? data.csaIdentifier : undefined);
    const [isActive, setIsActive] = useState(data ? data.isActive : 1);
    const [availability, setAvailability] = useState(data ? parseAvailability(data.workAreaAvailability) : [false, false, false, false, false, false, false]);
    const [showModal, setShowModal] = useState(false);
    const validationGroup = new ValidationGroup();

    useEffect(() => {
        loadData();
    }, []); 

    function loadData(){
        new ApiRequest('myCompany', 'getCsas', setIsLoading, (response) => restrictedToCsa ? setCsas([response.csas.find(csa => csa.uid == restrictedToCsa)]) : setCsas(response.csas)).withNoAlertOnSuccess().send();
    }

    function handleSubmit(){
        const workAreaToSave = {
            uid: data ? data.uid : undefined,
            workAreaName: workAreaName,
            csaIdentifier: csa,
            csa: csas.find(c => c.uid == csa),
            isActive: isActive,
            workAreaAvailability: encodeAvailability(availability)
        }
        if(data){
            new ApiRequest('myCompany', 'updateWorkArea', setIsSubmitting, () => handleWorkAreaSubmission(workAreaToSave, true)).withData({data: workAreaToSave}).send();
        } else {
            new ApiRequest('myCompany', 'createWorkArea', setIsSubmitting, (response) => {workAreaToSave.uid = response.uid; handleWorkAreaSubmission(workAreaToSave, false)}).withData({data: workAreaToSave}).send();
        }
    }

    const content = (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{data ? 'Editing Work Area' : 'Creating Work Area'}</Modal.Title>
                { data ? 
                    <Button variant={'outline-primary'} style={{marginLeft: 24}} onClick={() => {setShowModal(true)}}>{isActive === 1 ? 'Set work area as Inactive' : 'Set work area as active'}</Button>                                             
                : ''}
            </Modal.Header>
            <Modal.Body>
                <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
                    <div>
                        <CustomControl validator={validationGroup.createValidator(()=>validateExistence(workAreaName))} md={6} type='text' maxLength={50} title='Name' originalValue={data ? data.workAreaName : undefined} value={workAreaName} onChange={(event) => {setWorkAreaName(event.target.value)}}/>
                    </div>
                    <div>
                        <RadioControl validator={validationGroup.createValidator(()=>validateExistence(csa))} title='CSA:' selection={csa} setSelection={setCsa} originalValue={data ? data.csaIdentifier : undefined} optionNames={csas.map(csa => csa.csaName)} optionValues={csas.map(csa => csa.uid)} inline/> 
                    </div>
                    <div style={{paddingTop:5}}>
                        <AvailabilityControl title='Work Area Availability:' inline value={availability} setValue={setAvailability} originalValue={data ? parseAvailability(data.workAreaAvailability) : undefined}/>
                    </div>
                </div> 
            </Modal.Body>
            <Modal.Footer>
                <CustomButton disabled={!workAreaName || !csa} label={data ? 'Save Changes' : 'Create Work Area'} isLoading={isSubmitting} onClick={handleSubmit}/>
            </Modal.Footer>
            <Modal show={showModal} onHide={() => {setShowModal(false)}} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{isActive === 1 ? 'Confirm Deactivation' : 'Confirm Reactivation'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { isActive === 1 ? 'Deactivating a work area will prevent it from appearing in Scheduling.' 
                        : 'Reactivating a work area will make it appear in Scheduling.'
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={'outline-primary'} onClick={() => {setShowModal(false); setTimeout(() => {setIsActive(isActive === 0 ? 1 : 0)}, 200)}}>Confirm</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
    return (
        <LoadingWrapper isLoading={isLoading}>
            {content}
        </LoadingWrapper>
    )
}