/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import CustomControl from "../../components/CustomControl";
import RadioControl from "../../components/RadioControl";
import Col from 'react-bootstrap/Col';
import CustomButton from "../../components/CustomButton";
import { ValidationGroup,validateExistence, validatePositiveNumber} from "../../validation";
import SwitchControl from "../../components/SwitchControl";
import { ApiRequest } from "../../ApiManager.tsx";
import LoadingWrapper from "../../components/LoadingWrapper.js";

function MyCompanyVehicle({data, handleVehicleSubmission, restrictedToCsa}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [csas, setCsas] = useState([]);
    const [vehicleName, setVehicleName] = useState(data ? data.vehicleName : '');
    const [vehicleWeight, setVehicleWeight] = useState(data ? data.vehicleWeight : 0);
    const [isStraight, setIsStraight] = useState(data ? data.isStraight : '0');
    const [csaIdentifier, setCsaIdentifier] = useState(data ? data.csaIdentifier : undefined);
    const [isActive, setIsActive] = useState(data ? data.isActive : 1);
    const [showModal, setShowModal] = useState(false);

    const validationGroup = new ValidationGroup();

    useEffect(() => {
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('myCompany', 'getCsas', setIsLoading, (response) => restrictedToCsa ? setCsas([response.csas.find(csa => csa.uid == restrictedToCsa)]) : setCsas(response.csas)).withNoAlertOnSuccess().send();
    }
    
    function handleSubmit(){
        const vehicleToSave = {
            uid: data ? data.uid : undefined,
            vehicleName: vehicleName,
            vehicleWeight: vehicleWeight,
            isActive: isActive,
            isStraight: isStraight,
            csaIdentifier: csaIdentifier,
            csa: csas.find(c => c.uid == csaIdentifier)
        }
        if(data){
            new ApiRequest('myCompany', 'updateVehicle', setIsSubmitting, () => handleVehicleSubmission(vehicleToSave, true)).withData({data: vehicleToSave}).send();
        } else{
            new ApiRequest('myCompany', 'createVehicle', setIsSubmitting, (response) => {vehicleToSave.uid = response.uid; handleVehicleSubmission(vehicleToSave, false)}).withData({data: vehicleToSave}).send();
        }
    }
   
    const content = (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{data ? 'Editing Vehicle' : 'Creating Vehicle'}</Modal.Title> 
                <Button variant={'outline-primary'} style={{marginLeft: 24}} onClick={() => {setShowModal(true)}}>{isActive === 1 ? 'Set vehicle as Inactive' : 'Set vehicle as active'}</Button>                             
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <CustomControl validator={validationGroup.createValidator(()=>validateExistence(vehicleName))} md={4} type='text' maxLength={50} title='Name' originalValue={data ? data.vehicleName : undefined} value={vehicleName} onChange={(event) => {setVehicleName(event.target.value)}}/>
                        <CustomControl validator={validationGroup.createValidator(()=>validatePositiveNumber(vehicleWeight))} md={4} type='number' min={0} maxDecimal={1000000} title='GVW' originalValue={data ? data.vehicleWeight.toString() : undefined} value={vehicleWeight} onChange={(event) => {setVehicleWeight(event.target.value)}}/>
                        <SwitchControl md={4} title='Straight Truck?' originalValue={data ? data.isStraight : undefined} value={isStraight} setValue={setIsStraight}/>
                        <Col>
                            <RadioControl validator={validationGroup.createValidator(()=>validateExistence(csaIdentifier))} title='CSA' selection={csaIdentifier} setSelection={setCsaIdentifier} originalValue={data ? data.csaIdentifier : undefined} optionNames={csas.map(csa => csa.csaName)} optionValues={csas.map(csa => csa.uid)} inline/>
                        </Col> 
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton disabled={!validationGroup.isValid()} label={data ? 'Save Changes' : 'Create Vehicle'} isLoading={isSubmitting} onClick={handleSubmit}/>
            </Modal.Footer>
            <Modal show={showModal} onHide={() => {setShowModal(false)}} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{isActive === 1 ? 'Confirm Deactivation' : 'Confirm Reactivation'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { isActive === 1 ? 'Deactivating a vehicle will prevent it from appearing in Scheduling.' 
                        : 'Reactivating a vehicle will make it appear in Scheduling.'
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={'outline-primary'} onClick={() => {setShowModal(false); setTimeout(() => {setIsActive(isActive === 0 ? 1 : 0)}, 200)}}>Confirm</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
    return (
        <LoadingWrapper isLoading={isLoading}>
            {content}
        </LoadingWrapper>
    )
}

export default MyCompanyVehicle;