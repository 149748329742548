/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import './RichTextEditor.css';
import { Editor, RichUtils} from "draft-js";
import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";

class RichTextEditor extends React.Component {
    constructor(props) {
        super(props);
        this.myBlockStyleFn = this.myBlockStyleFn.bind(this);
        this.toggleBlockType = this.toggleBlockType.bind(this);
        this.toggleInlineStyle = this.toggleInlineStyle.bind(this);
        this.handleKeyCommand = this.handleKeyCommand.bind(this);
    }

    myBlockStyleFn(contentBlock) {
        switch (contentBlock.getType()) {
            case 'blockquote':
                return 'RichTextEditor-blockquote';
            case 'header-title':
                return 'h1 RichTextEditor-header-title'
            default:
                return '';
        }
    }

    toggleBlockType(blockType) {
        this.props.setEditorState(RichUtils.toggleBlockType(this.props.editorState, blockType));
    }

    toggleInlineStyle(inlineStyle) {
        this.props.setEditorState(RichUtils.toggleInlineStyle(this.props.editorState, inlineStyle));
    }

    handleKeyCommand(command) {
        const newEditorState = RichUtils.handleKeyCommand(this.props.editorState, command);
        if (newEditorState) {
            this.props.setEditorState(newEditorState);
            return 'handled';
        } else {
            return 'not-handled';
        }
    }

    BLOCK_TYPES = [
        { label: 'Title', style: 'header-title'},
        { label: 'H1', style: 'header-one' },
        { label: 'H2', style: 'header-two' },
        { label: 'H3', style: 'header-three' },
        { label: 'H4', style: 'header-four' },
        { label: 'H5', style: 'header-five' },
        { label: 'H6', style: 'header-six' },
        { label: 'Blockquote', style: 'blockquote' },
        { label: 'Unordered List', style: 'unordered-list-item' },
        { label: 'Ordered List', style: 'ordered-list-item' },
        // { label: 'Code Block', style: 'code-block' },
    ];

    INLINE_STYLES = [
        { label: 'Bold', style: 'BOLD' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' },
    ];

    render() {
        const selection = this.props.editorState.getSelection();
        const blockType = this.props.editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();
        const currentStyle = this.props.editorState.getCurrentInlineStyle();

        const BlockStyleControls = this.BLOCK_TYPES.map((type) => {
            const isActive = blockType === type.style;
            return (
                <Button variant={isActive ? 'primary' : 'outline-primary'}style={{ padding: '2px 6px 2px 6px' }} key={type.label} onMouseDown={(event) => {event.preventDefault();this.toggleBlockType(type.style)}}>
                    {type.label}
                </Button>
            );
        });

        const inlineStyleControls = this.INLINE_STYLES.map((type) => {
            const isActive = currentStyle.has(type.style);
            return (
                <Button variant={isActive ? 'primary' : 'outline-primary'} style={{ padding: '2px 6px 2px 6px' }} key={type.label} onMouseDown={(event) => {event.preventDefault();this.toggleInlineStyle(type.style);}} >
                    {type.label}
                </Button>
            );
        });

        return (
            
            <div style={{ background: 'whitesmoke', padding: 6, borderRadius: 6}}>
                <Editor readOnly editorState={this.props.editorState}  blockStyleFn={this.myBlockStyleFn} handleKeyCommand={this.handleKeyCommand}/>
            </div>
            
        );
    }
}

export default RichTextEditor;