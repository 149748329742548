/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import { addressToString, formatPhoneNumber } from "../../tools";
import KeyValueRow from "../../components/KeyValueRow";

export default function EContactTwo({user, address}){
    return (
        <Card>
            <Card.Body style={{paddingBottom:30}}>
                <Card.Title style={{textAlign:'center', paddingBottom:10}}>Emergency Contact #2</Card.Title>
            <ListGroup>
                <KeyValueRow isListItem title={'Emergency Contact #2 First Name'} value={user.eContact2FirstName}/>
                <KeyValueRow isListItem title={'Emergency Contact #2 Last Name'} value={user.eContact2LastName}/>
                <KeyValueRow isListItem title={'Emergency Contact #2 Phone Number'} value={user.eContact2PhoneNumber ? formatPhoneNumber(user.eContact2PhoneNumber) : ''}/>
                <KeyValueRow isListItem title={'Emergency Contact #2 Email Address'} value={user.eContact2Email}/>
                <KeyValueRow isListItem title={'Emergency Contact #2 Relationship'} value={user.eContact2Relationship}/>
                <KeyValueRow isListItem title={'Emergency Contact #2 Address'} value={addressToString(address)}/>     
            </ListGroup>
            </Card.Body>
        </Card>
     
    )
}