/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import HRHirePermissions from './HRHirePermissions';
import CloseButton from 'react-bootstrap/CloseButton';
import { encodeAvailability, ptoAccrualTypeDictionary, validateDecimal } from '../../../tools';
import CustomButton from '../../../components/CustomButton';
import {ValidationGroup, validateAvailablity, validateEmail, validateExistence, validatePhoneNumber} from '../../../validation';
import PageSpinner from '../../../components/PageSpinner';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import CustomControl from '../../../components/CustomControl.js';
import AddressControl from '../../../components/AddressControl.js';
import RadioControl from '../../../components/RadioControl.js';
import Cookies from 'universal-cookie';
import AvailabilityControl from '../../../components/AvailabilityControl.js';
import BwcCodeControl from '../../../components/BwcCodeControl.js';
import ChildSupportControl from '../../../components/ChildSupportControl.js';
import AutomaticDeductionControl from '../../../components/AutomaticDeductionControl.js';
import AutomaticReimbursementControl from '../../../components/AutomaticReimbursementControl.js';
import DocumentRequestModal from '../HRDocuments/DocumentRequestModal.js';
import { ApiRequest } from '../../../ApiManager.tsx';

export default function HRHire({csas, hideModal, selectedUser}){
    const [personalInformation, setPersonalInformation] = useState({
        firstName: selectedUser?.firstName ?? '',
        middleName: selectedUser?.middleName ?? '',
        lastName: selectedUser?.lastName ?? '',
        email: selectedUser?.email ?? '',
        phoneNumber: selectedUser?.phoneNumber ?? '',
        dateOfBirth: '',
        ssn: '',
        address: {thoroughfare: '', premise: '', administrativeArea: '', locality: '', postalCode: ''},
    });
    const [employmentDetails, setEmploymentDetails] = useState({
        employeeType: '',
        title: '',
        csaIdentifier: '',
        availability:[false, false, false, false, false, false, false],
        payType: '',
        payRate: '',
        hourlyWage: '',
        startDate: '',
        ptoAccrual: '',
        ptoType:'',
        bwcCode: '7231',
        stopBonusWeekdayThreshold: '',
        stopBonusWeekendThreshold: '',
        stopBonusAmount: '',
        stopBonusWeekendAmount: '',
        medical: '',
        dental: '',
        vision: '',
        childSupport: [],
        automaticDeductions: [],
        automaticReimbursement: [],
    });
    const [permissions, setPermissions] = useState(undefined);
    const [permissionsTemplate, setPermissionsTemplate] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showCloseWarning, setShowCloseWarning] = useState(false);
    const [sendOnboardingDocuments, setSendOnboardingDocuments] = useState(true);
    const [showOnboardingModal, setShowOnboardingModal] = useState(false);
    const [companyUserIdentifier, setCompanyUserIdentifier] = useState(selectedUser?.companyUserUid);

    const isRestrictedBc = new Cookies().get('userData').title === 'BC' && new Cookies().get('restrictBcsToCsa') == '1';
    const validationGroup = new ValidationGroup();
    const filteredCsas = isRestrictedBc ? [csas.find(csa => csa.uid == new Cookies().get('userData').csaIdentifier)] : csas;

    useEffect(()=>{
        loadData();
    }, [])

    async function loadData() {
        new ApiRequest('hr', 'getHireResources', setIsLoading, (response) => {
            const ePermissions = {};
            response.template.forEach((permObj) => {
                ePermissions[permObj.permissionName] = '0';
            });
            setPermissions(ePermissions);
            setPermissionsTemplate(response.template);
            handleSetEmploymentDetails('benefits', response.benefits);
            if (selectedUser) {
                getSelectedUserInfo();
            }
        }).withNoAlertOnSuccess().send();
    }

    function getSelectedUserInfo(){
        new ApiRequest('hr', 'get', setIsLoading, (response) => {
            const personalInfo = {
                address: response.userAddress,
                dateOfBirth: response.user.dateOfBirth,
                ssn: response.user?.ssn ?? '',
            }
            setPersonalInformation({...personalInformation, ...personalInfo});
        }).withUid(selectedUser.companyUserUid).withNoAlertOnSuccess().send();
    }

    function handleSubmit(){
        const data = {
            ...personalInformation,
            ...employmentDetails,
            availability: encodeAvailability(employmentDetails.availability),
            automaticReimbursement: JSON.stringify(employmentDetails.automaticReimbursement.map(ar => {return {label: ar.label, amount: validateDecimal(ar.amount) > 0 ? validateDecimal(ar.amount) : 0}}).filter(ar => ar.amount)),
            automaticDeductions: JSON.stringify(employmentDetails.automaticDeductions.map(ad => {return {label: ad.label, amount: validateDecimal(ad.amount) > 0 ? validateDecimal(ad.amount) : 0}}).filter(ad => ad.amount)),
            childSupport: JSON.stringify(employmentDetails.childSupport.map(cs => validateDecimal(cs) > 0 ? validateDecimal(cs) : 0).filter(cs => cs)),
            permissions: permissions,
        }

        if(selectedUser){
            data['userIdentifier'] = selectedUser.userIdentifier;
            data['companyUserUid'] = selectedUser.companyUserUid;
            new ApiRequest('hr', 'rehire', setIsSubmitting, (response) => {
                if (sendOnboardingDocuments) {
                    setShowOnboardingModal(true); 
                } else {
                    window.location.reload();
                }
                
            }).withData({data: data}).send();
        } else{
            new ApiRequest('hr', 'newHire', setIsSubmitting, (response) => {
                setCompanyUserIdentifier(response.companyUserIdentifier);
                if (sendOnboardingDocuments) {
                    setShowOnboardingModal(true); 
                } else {
                    window.location.reload();
                }
            }).withData({data: data}).send();
        }
    }

    function handleSetPersonalInformation(key, value) {
        const newObj = structuredClone(personalInformation);
        newObj[key] = value;
        setPersonalInformation(newObj);
    }

    function handleSetEmploymentDetails(key, value) {
        const newObj = structuredClone(employmentDetails);
        newObj[key] = value;
        setEmploymentDetails(newObj);
    }
   
    function handleSetPermissions(permissionName){
        const newPermissions = structuredClone(permissions);
        newPermissions[permissionName] = newPermissions[permissionName] === '0' ? '1' : '0';
        setPermissions(newPermissions);
    }

    function handleUseTemplate(templateName){
        const newPermissions = structuredClone(permissions);
        permissionsTemplate.forEach((permObj) => {
            newPermissions[permObj.permissionName] = permObj[templateName];
        });
        setPermissions(newPermissions);
    }

    return (
        <>
            <Modal.Header>
                <Modal.Title>{selectedUser ? 'Rehire Employee' : 'Hire New Employee'}</Modal.Title>
                <CloseButton onClick={() => setShowCloseWarning(true)}/>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12, backgroundColor: 'lightgray'}}>
                { isLoading ? <PageSpinner/> :
                    <>
                        <div style={{borderRadius: 6, padding: 12, backgroundColor: 'white'}}>
                            <h5 style={{textAlign:'center'}}>Personal Information</h5>
                            <Container fluid style={{padding: 0}}>
                                <Row>
                                    <CustomControl maxLength={30} forceTouched={true} validator={validationGroup.createValidator(() => validateExistence(personalInformation.firstName))} lg={3} type='text' max={30} title='First Name' value={personalInformation.firstName} onChange={(event) => handleSetPersonalInformation('firstName', event.target.value)}/>
                                    <CustomControl maxLength={30} forceTouched={true} lg={3} type='text' max={30} title='Middle Name' value={personalInformation.middleName} onChange={(event) => handleSetPersonalInformation('middleName', event.target.value)}/>
                                    <CustomControl maxLength={30} forceTouched={true} validator={validationGroup.createValidator(() => validateExistence(personalInformation.lastName))} lg={3} type='text' max={30} title='Last Name' value={personalInformation.lastName} onChange={(event) => handleSetPersonalInformation('lastName', event.target.value)}/>
                                    <CustomControl maxLength={50} forceTouched={true} validator={validationGroup.createValidator(() => validateEmail(personalInformation.email))} required lg={3} max={50} type='text' title='Email Address' value={personalInformation.email} onChange={(event) => handleSetPersonalInformation('email', event.target.value)}/>
                                    <CustomControl maxLength={10} forceTouched={true} validator={validationGroup.createValidator(() => validatePhoneNumber(personalInformation.phoneNumber))} required lg={3} max={10} type='text' title='Phone Number' value={personalInformation.phoneNumber} onChange={(event) => handleSetPersonalInformation('phoneNumber', event.target.value)}/>
                                    <CustomControl max={moment().format('YYYY-MM-DD')} lg={3} type='date' title='Date of Birth' value={personalInformation.dateOfBirth} onChange={(event) => handleSetPersonalInformation('dateOfBirth', event.target.value)}/>
                                    <CustomControl maxLength={9} lg={3} max={9} type='text' title='Social Security #' value={personalInformation.ssn} onChange={(event) => handleSetPersonalInformation('ssn', event.target.value)}/>
                                    <Col xxl={12}>
                                        <AddressControl lg={4} title='Address' address={personalInformation.address} setAddress={(value) => handleSetPersonalInformation('address', value)}/>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div style={{borderRadius: 6, padding: 12, backgroundColor: 'white'}}>
                            <h5 style={{textAlign:'center'}}>Employment Information</h5>

                            <Container fluid style={{padding: 0}}>
            
                                <Row>
                                    <RadioControl lg={6} forceTouched={true} validator={validationGroup.createValidator(() => validateExistence(employmentDetails.employeeType))} inline title='Employee Type' selection={employmentDetails.employeeType} setSelection={(value) => handleSetEmploymentDetails('employeeType', value)} optionNames={['Full-Time', 'Part-Time']} optionValues={[0, 1]}/>
                                    <RadioControl lg={6} forceTouched={true} validator={validationGroup.createValidator(() => validateExistence(employmentDetails.title))} inline title='Title' selection={employmentDetails.title} setSelection={(value) => handleSetEmploymentDetails('title', value)} optionNames={['Driver', 'AVP Driver', 'Jumper', 'BC']} optionValues={['Driver', 'AVP Driver', 'Jumper', 'BC']}/>
                                    <RadioControl lg={12}
                                        forceTouched={true} 
                                        validator={validationGroup.createValidator(() => validateExistence(employmentDetails.csaIdentifier))} 
                                        inline 
                                        title='CSA' 
                                        selection={employmentDetails.csaIdentifier} 
                                        setSelection={(value) => handleSetEmploymentDetails('csaIdentifier', value)} 
                                        optionNames={filteredCsas.filter(c=> c.isActive == 1).map(c => c.csaName)} 
                                        optionValues={filteredCsas.filter(c=> c.isActive == 1).map(c => c.uid)}
                                    />
                                    <AvailabilityControl forceTouched={true} lg={6} validator={validationGroup.createValidator(() => validateAvailablity(employmentDetails.availability))} inline title='Expected Availability' value={employmentDetails.availability} setValue={(value) => handleSetEmploymentDetails('availability', value)}/>
                                    <Col lg={6} style={{marginBottom: 8}}>
                                        <RadioControl forceTouched={true} validator={validationGroup.createValidator(() => validateExistence(employmentDetails.payType))} inline title='Pay Type' selection={employmentDetails.payType} setSelection={(value) => handleSetEmploymentDetails('payType', value)} optionNames={['Per Year', 'Per Day', 'Per Hour', 'Per Stop', 'Per Mile']} optionValues={['py', 'pd', 'ph', 'ps', 'pm']}/>
                                        {employmentDetails.payType !== 'py' &&  employmentDetails.title === 'BC' && <Alert variant='warning' style={{padding:5, fontSize:12, margin:5}}>BC's are recommended to be paid Per Year </Alert> }
                                    </Col>
                                    <Col lg={4}>
                                        <CustomControl maxDecimal={1000000} forceTouched={true} validator={validationGroup.createValidator(() => validateExistence(employmentDetails.payRate))} type='number' title='Pay Rate' value={employmentDetails.payRate} onChange={(event) => handleSetEmploymentDetails('payRate', event.target.value)}/>
                                        {employmentDetails.payRate < 35568 && employmentDetails.payType === 'py' &&  <Alert variant='warning' style={{padding:5, fontSize:12}}>Per Year Employees will receive overtime if their pay rate is equal to or below $35568.00 </Alert> }
                                    </Col>
                                    <CustomControl lg={4} maxDecimal={1000} forceTouched={true} validator={validationGroup.createValidator(() => validateExistence(employmentDetails.hourlyWage))} type='number' title='Hourly Wage' value={employmentDetails.hourlyWage} onChange={(event) => handleSetEmploymentDetails('hourlyWage', event.target.value)}/>
                                    <CustomControl lg={4} forceTouched={true} validator={validationGroup.createValidator(() => validateExistence(employmentDetails.startDate))} type='date' title='Start Date' value={employmentDetails.startDate} onChange={(event) => handleSetEmploymentDetails('startDate', event.target.value)}/>
                                    

                                    <Col lg={6}>
                                        <Form.Label>PTO</Form.Label>
                                        <CustomControl forceTouched={true} validator={validationGroup.createValidator(() => validateExistence(employmentDetails.ptoAccrual))} maxDecimal={1000000} type='number' title='PTO Accrual (In Hours)' value={employmentDetails.ptoAccrual} onChange={(event) => handleSetEmploymentDetails('ptoAccrual', event.target.value)}/>
                                        <div style={{marginTop:5}}>
                                            <RadioControl forceTouched={true} lg={12} validator={validationGroup.createValidator(() => validateExistence(employmentDetails.ptoAccrualType))} inline selection={employmentDetails.ptoAccrualType} setSelection={(value) => handleSetEmploymentDetails('ptoAccrualType', value)} optionNames={Object.values(ptoAccrualTypeDictionary)} optionValues={Object.keys(ptoAccrualTypeDictionary)}/>
                                        </div>
                                    </Col>
                                    <BwcCodeControl lg={6} forceTouched={true} validator={validationGroup.createValidator(() => validateExistence(employmentDetails.bwcCode))} inline value={employmentDetails.bwcCode} setValue={(value) => handleSetEmploymentDetails('bwcCode', value)}/>

                                    <Col lg={6} style={{marginBottom: 8}}>
                                        <CustomControl maxInt={1000000} forceTouched={true} max={10}  type='number' title='Weekday Stop Threshold' value={employmentDetails.stopBonusWeekdayThreshold} onChange={(event) => handleSetEmploymentDetails('stopBonusWeekdayThreshold', event.target.value)}/>
                                        <span style={{fontSize:12}}>(Employee will recieve a bonus for all stops above this threshold.)</span>
                                    </Col>
                                    <Col lg={6} style={{marginBottom: 8}}>
                                        <CustomControl maxDecimal={1000} forceTouched={true} max={10}  type='number' title='Weekday Stop Wage Dollar Amount' value={employmentDetails.stopBonusAmount} onChange={(event) => handleSetEmploymentDetails('stopBonusAmount', event.target.value)}/>
                                        <span style={{fontSize:12}}>(Dollar amount per stop for all stops beyond the threshold.)</span>
                                    </Col>
                                    <Col lg={6} style={{marginBottom: 8}}>
                                        <CustomControl maxInt={1000000} forceTouched={true} max={10}  type='number' title='Weekend Stop Threshold' value={employmentDetails.stopBonusWeekendThreshold} onChange={(event) => handleSetEmploymentDetails('stopBonusWeekendThreshold', event.target.value)}/>
                                        <span style={{fontSize:12}}>(Employee will recieve a bonus for all stops above this threshold.)</span>
                                    </Col>
                                    <Col lg={6} style={{marginBottom: 8}}>
                                        <CustomControl maxDecimal={1000} forceTouched={true} max={10}  type='number' title='Weekend Stop Wage Dollar Amount' value={employmentDetails.stopBonusWeekendAmount} onChange={(event) => handleSetEmploymentDetails('stopBonusWeekendAmount', event.target.value)}/>
                                        <span style={{fontSize:12}}>(Dollar amount per stop for all stops beyond the threshold.)</span>
                                    </Col>

                                    <CustomControl lg={4} maxDecimal={1000000} title='Medical Insurance' type='number' value={employmentDetails.medical} onChange={(event) => handleSetEmploymentDetails('medical', event.target.value)}/>
                                    <CustomControl lg={4} maxDecimal={1000000} title='Dental Insurance' type='number' value={employmentDetails.dental} onChange={(event) => handleSetEmploymentDetails('dental', event.target.value)}/>
                                    <CustomControl lg={4} maxDecimal={1000000} title='Vision Insurance' type='number' value={employmentDetails.vision} onChange={(event) => handleSetEmploymentDetails('vision', event.target.value)}/>   

                                    <ChildSupportControl value={employmentDetails.childSupport} setValue={(value) => handleSetEmploymentDetails('childSupport', value)} breakpoints={{xxl: 12}}/>
                                    <AutomaticDeductionControl value={employmentDetails.automaticDeductions} setValue={(value) => handleSetEmploymentDetails('automaticDeductions', value)} breakpoints={{xxl: 12}}/>
                                    <AutomaticReimbursementControl value={employmentDetails.automaticReimbursement} setValue={(value) => handleSetEmploymentDetails('automaticReimbursement', value)} breakpoints={{xxl: 12}}/>

                                </Row>
                            </Container>
                        </div>
                        <div style={{borderRadius: 6, padding: 12, backgroundColor: 'white'}}>
                            <h5 style={{textAlign:'center'}}>Permissions</h5>
                            <HRHirePermissions 
                                handleSetPermissions={handleSetPermissions} 
                                handleUseTemplate={handleUseTemplate} 
                                permissions={permissions} 
                                permissionsTemplate={permissionsTemplate}
                            />
                        </div>
                    </>

                }
            </Modal.Body>
            <Modal.Footer>
                <Form.Check type='switch' label='Send Employee Onboarding Documents?' checked={sendOnboardingDocuments} onChange={() => setSendOnboardingDocuments(!sendOnboardingDocuments)}/>
                <CustomButton label='Submit' isLoading={isSubmitting} disabled={!validationGroup.isValid()} onClick={handleSubmit}/>
            </Modal.Footer>  
            <Modal show={showCloseWarning} onHide={() => setShowCloseWarning(false)}>
                <Modal.Header>
                    <Modal.Title>Discard Unsubmitted Progress?</Modal.Title>
                </Modal.Header>
                <Modal.Body>You will lose all progress if you close out of this modal before submitting.</Modal.Body>
                <Modal.Footer>
                    <Button variant={'outline-primary'} onClick={() => setShowCloseWarning(false)}>Cancel</Button>
                    <Button variant={'outline-primary'} onClick={() => {setShowCloseWarning(false); hideModal()}}>Discard Progress</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showOnboardingModal} onHide={() => window.location.reload()} size='xl'>
                <DocumentRequestModal defaultAllDocumentsOn companyUserIdentifier={companyUserIdentifier} hideModal={() => window.location.reload()}/>
            </Modal>
        </>
    )
}