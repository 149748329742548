/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/


import { PDFViewer } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import DateRangeSelector from "../../../components/DateRangeSelector";
import DropdownControl from "../../../components/DropdownControl";
import LoginReportPdf from "./LoginReportPdf";
import { ApiRequest } from "../../../ApiManager.tsx";

export default function LoginReport() {
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [loginData, setLoginData] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedUser, setSelectedUser] = useState(undefined);

    useEffect(()=>{
       loadData();
    },[]);

    function loadData(){
        new ApiRequest('myCompany', 'getCompanyUsers', setIsLoading, (response) => {
            setUsers(response.users.sort((a,b)=>a.lastName.localeCompare(b.lastName)));
        }).withNoAlertOnSuccess().send();
    }

    function handleGetLogins(startDate, endDate){
        setStartDate(startDate);
        setEndDate(endDate);

        new ApiRequest('reports', 'loginReport', setIsLoading, (response) => setLoginData(response.logins))
        .withData({
            companyUserIdentifier: selectedUser,
            startDate: startDate,
            endDate: endDate
        }).withNoAlertOnSuccess().send()
    }

    function handleSetSelectedUser(selectedUser){
        if(users.find((u)=>u.companyUserUid == selectedUser)){
            setShowModal(true);
            setStartDate('');
            setSelectedUser(selectedUser);
        }
    }

    const userItemNames = users.map((u)=>{
        return u.lastName + ', ' + u.firstName + ' ' + u.middleName.substring(0,1);
    });

    const userItemValues = users.map((u)=>{
        return u.companyUserUid;
    });

    return (
        <>
            <Modal.Header closeButton>
                <div style={{display: 'flex', gap: 20, alignItems: 'center'}}>
                    <DropdownControl title='Employee Selection' selection={selectedUser} setSelection={handleSetSelectedUser} itemNames={userItemNames} itemValues={userItemValues}/>
                     <DateRangeSelector show={showModal} onHide={() => setShowModal(false)} handleSetDateRange={handleGetLogins} presetOptions={['pastMonth', 'pastWeek', 'yesterday']}/>
                </div>
            </Modal.Header>
            <Modal.Body>
                {startDate && !isLoading && 
                <PDFViewer style={{width: '100%', height: '99%'}}>
                   <LoginReportPdf loginData={loginData} startDate={startDate} endDate={endDate} selectedUser={users.find((u)=>u.companyUserUid == selectedUser)} />
                </PDFViewer>}
            </Modal.Body>
        </>
    )
}