/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Big from "big.js";

export const adminMode = false;

export const payTypeDictionary = {
    ph: 'Per Hour',
    pd: 'Per Day',
    py: 'Per Year',
    pm: 'Per Mile',
    ps: 'Per Stop',
    xd: 'Extra Day Wage',
    iw: 'Incentive Wage',
    sw: 'Stand-By Wage'
}
export const payTypeDictionaryWithOt = {
    ph: 'Per Hour',
    pd: 'Per Day',
    py: 'Per Year',
    pm: 'Per Mile',
    ps: 'Per Stop',
    xd: 'Extra Day Wage',
    iw: 'Incentive Wage',
    sw: 'Stand-By Wage',
    ot: 'Overtime'
}
export const payRateDictionary = {
    ph: 'Hourly Rate',
    pd: 'Daily Rate',
    py: 'Salary',
    pm: 'Pay Per Mile',
    ps: 'Pay Per Stop',
    xd: 'Extra Day Rate'
}
export const wageDictionary = {
    ph: 'Hourly Wages',
    pd: 'Regular Wages',
    py: 'Regular Wages',
    pm: 'Mile Wages',
    ps: 'Stop Wages',
    xd: 'Extra Day Wages'
}

export const unitsWorkedDictionary = {
    ph: 'Hours Worked',
    pd: 'Days Worked',
    pm: 'Miles',
    ps: 'Stops',
    xd: 'Extra Days Worked'
}

export const holidayPayTypeDictionary = {
    ph: 'Per Hour',
    pd: 'Per Day',
    flat: 'Flat Amount',
}

export function getUnitsWorkedTypeString(payType) {
    if (payType === 'ph') {
        return 'Hours Worked';
    } else if (payType === 'pm') {
        return 'Number of Miles';
    } else if (payType === 'ps') {
        return 'Number of Stops';
    } else if (payType === 'pd' || payType === 'xd') {
        return 'Days Worked';
    } else if (payType === 'py') {
        return 'Yearly';
    } else if (payType === 'iw' || payType === 'sw' || payType === 'ot') {
        return 'Flat Amount';
    }
}

export const employeeTypeDictionary = {
    0: 'Full-Time',
    1: 'Part-Time'
}

export function validateDecimal(value) {
    return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
}

export function validateInteger(value) {
    return isNaN(parseInt(value)) ? 0 : parseInt(value);
}

export function validateBig(value, defaultValue = new Big('0.0')) {
    try {
        return new Big(value);
    } catch {
        return defaultValue;
    }
}

export function bigToUsd(value) {
    return '$' + value.toFixed(2)
}

export function validateUsd(value) {
    return isNaN(parseFloat(value)) ? 0.0 : parseFloat((parseFloat(value)).toFixed(2));
}

export function toFixedMax(number, decimalPlaces) {
    return parseFloat(parseFloat(number).toFixed(decimalPlaces));
}
