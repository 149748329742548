/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import CustomButton from './CustomButton';
import { ApiRequest } from '../ApiManager.tsx';

export default function FileDownloadButton({label = 'Download', doc, type, target, data}){
    const [isLoading, setIsLoading] = useState();
    const [base64, setBase64] = useState(doc && doc.base64 ? doc.base64 : undefined);

    async function downloadDoc (docObject){
        setBase64(docObject.base64);
        const fetchResponse = await fetch(docObject.base64);
        const blob = await fetchResponse.blob();
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = docObject.data.documentName + '.' + docObject.data.documentType;        
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }

    function handleDownload(){
        if(base64){
            downloadDoc({base64: base64, data: doc});
        }else{
            new ApiRequest(type, target, setIsLoading, async (response) => {
                downloadDoc(response);
            }).withData(data).withNoAlertOnSuccess().send();
        }
    }

    return (
        <CustomButton label={label} isLoading={isLoading} onClick={handleDownload}/>
    )
}