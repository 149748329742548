/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomControl from '../../../components/CustomControl';
import AddressControl from '../../../components/AddressControl';
import DropdownControl from '../../../components/DropdownControl';
import CustomButton from '../../../components/CustomButton';
import TextAreaControl from '../../../components/TextAreaControl';
import SwitchControl from '../../../components/SwitchControl';
import FileControl from '../../../components/FileControl';
import { Validator, validateExistence } from '../../../validation';
import RadioControl from '../../../components/RadioControl';
import { ApiRequest } from '../../../ApiManager.tsx';

export default function HRUserBWCForm({user, users, selectedBWC, handleAddRecord, handleReviseRecord}) {
    const [dateOfInjury, setDateOfInjury] = useState(selectedBWC ? selectedBWC.dateOfInjury : moment().format('YYYY-MM-DD'));
    const [injuryReportDate, setInjuryReportDate] = useState(selectedBWC ? selectedBWC.injuryReportDate : moment().format('YYYY-MM-DD'));
    const [injuryReportedTo, setInjuryReportedTo] = useState(selectedBWC ? selectedBWC.injuryReportedTo : undefined);
    const [treatmentFacilityName, setTreatmentFacilityName] = useState(selectedBWC ? selectedBWC.treatmentFacilityName : '');
    const [treatmentFacilityAddress, setTreatmentFacilityAddress] = useState(selectedBWC && selectedBWC.treatmentFacilityAddress ? selectedBWC.treatmentFacilityAddress : {thoroughfare: '', premise: '', administrativeArea: '', locality: '', postalCode: ''});
    const [treatmentFacilityAdmittedDateTime, setTreatmentFacilityAdmittedDateTime] = useState(selectedBWC ? selectedBWC.treatmentFacilityAdmittedDateTime : '');
    const [salaryContinuation, setSalaryContinuation] = useState(selectedBWC ? selectedBWC.salaryContinuation : '0');
    const [rtwLightDutyDate, setRtwLightDutyDate] = useState(selectedBWC ? selectedBWC.rtwLightDutyDate : '');
    const [lightDutyDescription, setLightDutyDescription] = useState(selectedBWC ? selectedBWC.lightDutyDescription : '');
    const [rtwFullDutyDate, setRtwFullDutyDate] = useState(selectedBWC ? selectedBWC.rtwFullDutyDate : '');
    const [bwcInjuryClaimNumber, setBwcInjuryClaimNumber] = useState(selectedBWC ? selectedBWC.bwcInjuryClaimNumber : '');
    const [notes, setNotes] = useState(selectedBWC ? selectedBWC.notes : '');
    const [medco14, setMedco14] = useState(selectedBWC ? selectedBWC.medco14 : undefined);
    const [c55, setC55] = useState(selectedBWC ? selectedBWC.c55 : undefined);
    const [attachment1, setAttachment1] = useState(selectedBWC && selectedBWC.attachments && JSON.parse(selectedBWC.attachments).length > 0 ? JSON.parse(selectedBWC.attachments)[0]: undefined);
    const [attachment2, setAttachment2] = useState(selectedBWC && selectedBWC.attachments && JSON.parse(selectedBWC.attachments).length > 1 ? JSON.parse(selectedBWC.attachments)[1]: undefined);
    const [attachment3, setAttachment3] = useState(selectedBWC && selectedBWC.attachments && JSON.parse(selectedBWC.attachments).length > 2 ? JSON.parse(selectedBWC.attachments)[2]: undefined);
    const [attachment4, setAttachment4] = useState(selectedBWC && selectedBWC.attachments && JSON.parse(selectedBWC.attachments).length > 3 ? JSON.parse(selectedBWC.attachments)[3]: undefined);
    const [injuryOrIllness, setInjuryOrIllness] = useState(selectedBWC ? selectedBWC.injuryOrIllness : '');
    const [eventLocation, setEventLocation] = useState(selectedBWC ? selectedBWC.eventLocation : '');
    const [classifyCase, setClassifyCase] = useState(selectedBWC ? selectedBWC.classifyCase : '');
    const [confirmSubmission, setConfirmSubmission] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    function handleSubmit(){
        setConfirmSubmission(false);

        const attachments = [];
        if (attachment1) {
            attachments.push(attachment1);
        }
        if (attachment2) {
            attachments.push(attachment2);
        }
        if (attachment3) {
            attachments.push(attachment3);
        }
        if (attachment4) {
            attachments.push(attachment4);
        }

        let address = false;
        if(treatmentFacilityAddress.thoroughfare !== '' || treatmentFacilityAddress.premise !== '' || treatmentFacilityAddress.administrativeArea !== '' || treatmentFacilityAddress.locality !== '' || treatmentFacilityAddress.postalCode !== ''){
            address = true;
        }

        const newBWC = {
            bwcRecordsIdentifier: selectedBWC ? selectedBWC.bwcRecordsIdentifier : undefined,
            dateOfInjury: dateOfInjury,
            injuryReportDate: injuryReportDate,
            injuryReportedTo: injuryReportedTo,
            treatmentFacilityName: treatmentFacilityName,
            treatmentFacilityAddress: address ? treatmentFacilityAddress : '',
            treatmentFacilityAdmittedDateTime: treatmentFacilityAdmittedDateTime,
            salaryContinuation: salaryContinuation,
            rtwLightDutyDate: rtwLightDutyDate,
            lightDutyDescription: lightDutyDescription,
            rtwFullDutyDate: rtwFullDutyDate,
            bwcInjuryClaimNumber: bwcInjuryClaimNumber,
            notes: notes,
            medco14: medco14,
            c55: c55,
            attachments: JSON.stringify(attachments),
            eventLocation:eventLocation,
            injuryOrIllness:injuryOrIllness,
            classifyCase:classifyCase,
            csaIdentifier:user.csaIdentifier,
            firstAndLastName:user.firstName + ' ' + user.lastName
        }

        if(selectedBWC){
            new ApiRequest('hr', 'reviseBwcRecord', setIsLoading, (response) => {
                newBWC.uid = response.uid;
                newBWC.author = response.author;
                newBWC.submissionDateTime = moment().format('YYYY-MM-DD HH:mm:ss');
                handleReviseRecord(newBWC);
            }).withData({data: newBWC}).send();
        } else {
            new ApiRequest('hr', 'createBwcRecord', setIsLoading, (response) => {
                newBWC.bwcRecordsIdentifier = response.bwcRecordsIdentifier;
                newBWC.uid = response.uid;
                newBWC.author = response.author;
                newBWC.submissionDateTime = moment().format('YYYY-MM-DD HH:mm:ss');
                handleAddRecord({uid: response.bwcRecordsIdentifier, latestVersion: newBWC});
            }).withData({data: newBWC, companyUserUid: user.companyUserUid}).send();
        }
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{selectedBWC ? 'Revising BWC Report' : 'Creating New BWC Report'}</Modal.Title>
            </Modal.Header>
            { selectedBWC?.driverStatement && 
                <Modal.Header style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                    <span>Driver Statement:</span>
                    <span style={{opacity: 0.65, fontStyle: 'italic'}}>{selectedBWC.driverStatement}</span>
                </Modal.Header>
            }
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <CustomControl lg={4} disabled={isLoading} originalValue={selectedBWC ? selectedBWC.dateOfInjury : undefined} type='date' title='Date of Injury' value={dateOfInjury} onChange={(event) => {setDateOfInjury(event.target.value)}}/>
                        <CustomControl lg={4} validator={new Validator(()=>validateExistence(injuryReportDate))} disabled={isLoading} originalValue={selectedBWC ? selectedBWC.injuryReportDate : undefined} type='date' title='Date Reported' value={injuryReportDate} onChange={(event) => {setInjuryReportDate(event.target.value)}}/>
                        <Col lg={4} style={{marginBottom: 8}}>
                            <DropdownControl title={'Injury Reported To'} selection={injuryReportedTo} setSelection={setInjuryReportedTo} itemNames={users.map(u => u.firstName + ' ' + u.lastName)} itemValues={users.map(u => u.companyUserUid)}/>
                        </Col>
                        <CustomControl lg={6} disabled={isLoading} maxLength={50} originalValue={selectedBWC ? selectedBWC.treatmentFacilityName : undefined} type='text' title='Treatment Facility Name' value={treatmentFacilityName} onChange={(event) => {setTreatmentFacilityName(event.target.value)}}/>
                        <CustomControl lg={6} disabled={isLoading} originalValue={selectedBWC ? selectedBWC.treatmentFacilityAdmittedDateTime : undefined} type='datetime-local' title='Date & Time Admitted to Facility' value={treatmentFacilityAdmittedDateTime} onChange={(event) => {setTreatmentFacilityAdmittedDateTime(event.target.value)}}/>
                        <Col xxl={12} style={{marginBottom: 8}}>
                            <AddressControl lg={4} disabled={isLoading} originalValue={selectedBWC ? selectedBWC.treatmentFacilityAddress : undefined} title='Treatment Facility Address' address={treatmentFacilityAddress} setAddress={setTreatmentFacilityAddress}/>
                        </Col>
                        <Col style={{marginBottom: 8}}>
                            <SwitchControl disabled={isLoading} originalValue={selectedBWC ? selectedBWC.salaryContinuation : undefined} title='Salary Continuation?' value={salaryContinuation} setValue={(value) => {setSalaryContinuation(value)}}/>
                        </Col>
                        <CustomControl lg={6} disabled={isLoading} originalValue={selectedBWC ? selectedBWC.rtwLightDutyDate : undefined} type='date' title='Date Returned to Work for Light Duty' value={rtwLightDutyDate} onChange={(event) => {setRtwLightDutyDate(event.target.value)}}/>
                        { !rtwLightDutyDate ? '' :
                            <Col xxl={12} style={{marginBottom: 8}}>
                                <TextAreaControl disabled={isLoading} max={65535} originalValue={selectedBWC ? selectedBWC.lightDutyDescription : undefined} label='Description of Light Duty' rows={4} value={lightDutyDescription} setValue={(event) => {setLightDutyDescription(event.target.value)}}/>
                            </Col>
                        }
                        <CustomControl lg={6} disabled={isLoading} originalValue={selectedBWC ? selectedBWC.rtwFullDutyDate : undefined} type='date' title='Date Returned to Work for Full Duty' value={rtwFullDutyDate} onChange={(event) => {setRtwFullDutyDate(event.target.value)}}/>
                        <CustomControl lg={6} disabled={isLoading} maxLength={20} originalValue={selectedBWC ? selectedBWC.bwcInjuryClaimNumber : undefined} type='text' title='BWC Injury Claim #' value={bwcInjuryClaimNumber} onChange={(event) => {setBwcInjuryClaimNumber(event.target.value)}}/>
                        <CustomControl lg={12} type={'text'} title={'Where did the event occur?'} value={eventLocation} onChange={(event)=>{setEventLocation(event.target.value)}}/>
                        <Row>
                            <RadioControl title='Injury or Illness' lg={6} inline optionNames={['Injury','Illness']} optionValues={['Injury','Illness']} selection={injuryOrIllness} setSelection={setInjuryOrIllness}/>
                            <RadioControl title='Classify Case' lg={6} inline optionNames={['Off Work', 'Other', 'Death']} optionValues={['Off Work', 'Other', 'Death']} selection={classifyCase} setSelection={setClassifyCase}/>
                        </Row>
                        <Col xxl={12} style={{marginBottom: 8}}>
                            <TextAreaControl disabled={isLoading} max={65535} originalValue={selectedBWC ? selectedBWC.notes : undefined} label='Injury / Illness Description' rows={4} value={notes} setValue={(event) => {setNotes(event.target.value)}}/>
                        </Col>
                        <Col lg={12} style={{marginBottom: 8}}>
                            <FileControl label={'Physician\'s Report of Work Ability (MEDCO-14)'} value={medco14} setValue={setMedco14} companyUserUid={user.companyUserUid}/>
                        </Col>
                        <Col lg={12} style={{marginBottom: 8}}>
                            <FileControl label={'Salary Continuation Agreement (C-55)'} value={c55} setValue={setC55} companyUserUid={user.companyUserUid}/>
                        </Col>
                        <Col lg={12} style={{marginBottom: 8}}>
                            <FileControl label={'Custom Attachment #1'} value={attachment1} setValue={setAttachment1} companyUserUid={user.companyUserUid}/>
                        </Col>
                        <Col lg={12} style={{marginBottom: 8}}>
                            <FileControl label={'Custom Attachment #2'} value={attachment2} setValue={setAttachment2} companyUserUid={user.companyUserUid}/>
                        </Col>
                        <Col lg={12} style={{marginBottom: 8}}>
                            <FileControl label={'Custom Attachment #3'} value={attachment3} setValue={setAttachment3} companyUserUid={user.companyUserUid}/>
                        </Col>
                        <Col lg={12} style={{marginBottom: 8}}>
                            <FileControl label={'Custom Attachment #4'} value={attachment4} setValue={setAttachment4} companyUserUid={user.companyUserUid}/>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton label='Submit' isLoading={isLoading}  disabled={!injuryReportDate} onClick={() => {setConfirmSubmission(true)}}/>
            </Modal.Footer>
            <Modal show={confirmSubmission} centered onHide={() => {setConfirmSubmission(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Submission</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Once submitted, the entered information will become a permanent record. Proceed?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={'outline-primary'} onClick={() => {setConfirmSubmission(false)}}>Cancel</Button>
                    <Button variant={'outline-primary'} onClick={handleSubmit}>Confirm Submission</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
    
}

