/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import PageSpinner from "../../components/PageSpinner";
import { Modal } from "react-bootstrap";
import DropdownControl from "../../components/DropdownControl";
import CustomButton from "../../components/CustomButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { ApiRequest } from "../../ApiManager.tsx";

export default function BudgetLoadModal({handleLoadBudget}) {
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedBudget, setSelectedBudget] = useState(undefined);
    const [budgets, setBudgets] = useState([]);

    useEffect(() => {
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('budget', 'getAll', setIsLoading, (response) => setBudgets(response.budgets)).withNoAlertOnSuccess().send();
    }

    function handleGetBudget(){
        new ApiRequest('budget', 'get', setIsSubmitting, (response) => handleLoadBudget(response.budget)).withUid(selectedBudget).withNoAlertOnSuccess().send();
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Load Budget</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { isLoading ? <PageSpinner/> :
                    <DropdownControl title='Select Budget' selection={selectedBudget} setSelection={setSelectedBudget} itemNames={budgets.map(p => `${p.title} (Created ${moment(p.dateCreated).format('MMM D, YYYY')})`)} itemValues={budgets.map(p => p.uid)}/>
                }
            </Modal.Body>
            <Modal.Footer>
                <FontAwesomeIcon style={{color: 'red'}} icon={faTriangleExclamation}/>
                <span style={{color: 'red'}}>{'Any unsaved changes will be lost'}</span>
                <CustomButton label='Load Budget' disabled={!selectedBudget} isLoading={isSubmitting} onClick={handleGetBudget}/>
            </Modal.Footer>
        </>
    )
}