/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Big from "big.js";
import Decoder from "../../../decoding";
import { validateBig } from "../../Payroll/payrollTools";
import BudgetAssumptions from "./BudgetAssumptions";
import BudgetExpenses from "./BudgetExpenses";
import BudgetProductivity from "./BudgetProductivity";
import { validateDecimal, validateInteger } from "../../../tools";

export default class Budget {

    uid;
    title;
    budgetExpenses;
    budgetAssumptions;
    budgetProductivity;

    constructor(uid, title, budgetExpenses, budgetAssumptions, budgetProductivity) {
        this.uid = uid;
        this.title = title;
        this.budgetExpenses = budgetExpenses;
        this.budgetAssumptions = budgetAssumptions;
        this.budgetProductivity = budgetProductivity;
    }

    static initDefault() {
        return new Budget(-1, 'Untitled Budget', BudgetExpenses.initDefault(), BudgetAssumptions.initDefault(), [0, 1, 2, 3, 4, 5, 6].map(index => BudgetProductivity.initDefault()));
    }

    static initFromPcat(pcat, proposalIndex) {

        const totalStops = pcat.proposals[proposalIndex].totalNonECommStops(pcat).plus(pcat.proposals[proposalIndex].totalECommStops(pcat));
        const stopsInWeek = totalStops.div(52);
        const stopsOnEachDay = [
            parseFloat((stopsInWeek.times(0.10)).toFixed(3)),
            parseFloat((stopsInWeek.times(0.05)).toFixed(3)),
            parseFloat((stopsInWeek.times(0.15)).toFixed(3)),
            parseFloat((stopsInWeek.times(0.175)).toFixed(3)),
            parseFloat((stopsInWeek.times(0.175)).toFixed(3)),
            parseFloat((stopsInWeek.times(0.175)).toFixed(3)),
            parseFloat((stopsInWeek.times(0.175)).toFixed(3)),
        ]
        const totalPackages = pcat.proposals[proposalIndex].totalNonECommPackages(pcat).plus(pcat.proposals[proposalIndex].totalECommPackages(pcat));
        const packagesInWeek = totalPackages.div(52);
        const packagesOnEachDay = [
            parseFloat((packagesInWeek.times(0.10)).toFixed(3)),
            parseFloat((packagesInWeek.times(0.05)).toFixed(3)),
            parseFloat((packagesInWeek.times(0.15)).toFixed(3)),
            parseFloat((packagesInWeek.times(0.175)).toFixed(3)),
            parseFloat((packagesInWeek.times(0.175)).toFixed(3)),
            parseFloat((packagesInWeek.times(0.175)).toFixed(3)),
            parseFloat((packagesInWeek.times(0.175)).toFixed(3)),
        ]

        const eCommPercentage = totalStops.eq(0) ? new Big(0) : pcat.proposals[proposalIndex].totalECommStops(pcat).div(totalStops).times(100);

        const budgetProductivity = [0, 1, 2, 3, 4, 5, 6].map((index) => {
            return new BudgetProductivity(0, 0, 0, eCommPercentage.toNumber(), stopsOnEachDay[index], packagesOnEachDay[index]);
        })

        return new Budget(-1, `Budget for PCAT \"${pcat.title}\" | Proposal #${proposalIndex + 1} (${pcat.proposals[proposalIndex].isFedex ? 'FedEx' : 'IC'})`, BudgetExpenses.initDefault(), BudgetAssumptions.initDefault(), budgetProductivity);
    }

    static decode(json, titlePreset) {
        const decoder = new Decoder(json);
        
        const uid = decoder.decode('uid', Decoder.Uid);
        const title = titlePreset ?? decoder.decode('title', Decoder.String);
        const budgetExpenses = BudgetExpenses.decode(json['budgetExpenses']);
        const budgetAssumptions = BudgetAssumptions.decode(json['budgetAssumptions']);
        const budgetProductivity = (decoder.decode('budgetProductivity', Decoder.Array) ?? []).map(bp => BudgetProductivity.decode(bp));

        if (decoder.checkForErrors()) {
            return new Budget(uid, title, budgetExpenses, budgetAssumptions, budgetProductivity);
        } else {
            return Budget.initDefault();
        }
    }

    encode() {
        return {
            uid: this.uid,
            title: this.title,
            budgetExpenses: this.budgetExpenses.encode(),
            budgetAssumptions: this.budgetAssumptions.encode(),
            budgetProductivity: this.budgetProductivity.map((bp, index) => {return bp.encode(index)}),
        };
    }

    duplicate() {
        return new Budget(
            this.uid,
            this.title,
            this.budgetExpenses.duplicate(),
            this.budgetAssumptions.duplicate(),
            this.budgetProductivity.map(bp => bp.duplicate())
        );
    }

    equals(budget) {

        for (let i = 0; i < 7; i++) {
            if (!budget.budgetProductivity[i].equals(this.budgetProductivity[i])) {
                return false;
            }
        }

        return this.title === budget.title && 
            this.budgetExpenses.equals(budget.budgetExpenses) &&
            this.budgetAssumptions.equals(budget.budgetAssumptions)
        ;
    }

    applyPcatToProduction(pcat, proposalIndex) {
        const newBudget = this.duplicate();

        const totalStops = pcat.proposals[proposalIndex].totalNonECommStops(pcat).plus(pcat.proposals[proposalIndex].totalECommStops(pcat));
        const stopsInWeek = totalStops.div(52);
        
        newBudget.budgetProductivity[0].stops = parseFloat((stopsInWeek.times(0.10)).toFixed(3));
        newBudget.budgetProductivity[1].stops = parseFloat((stopsInWeek.times(0.05)).toFixed(3));
        newBudget.budgetProductivity[2].stops = parseFloat((stopsInWeek.times(0.15)).toFixed(3));
        newBudget.budgetProductivity[3].stops = parseFloat((stopsInWeek.times(0.175)).toFixed(3));
        newBudget.budgetProductivity[4].stops = parseFloat((stopsInWeek.times(0.175)).toFixed(3));
        newBudget.budgetProductivity[5].stops = parseFloat((stopsInWeek.times(0.175)).toFixed(3));
        newBudget.budgetProductivity[6].stops = parseFloat((stopsInWeek.times(0.175)).toFixed(3));

        const totalPackages = pcat.proposals[proposalIndex].totalNonECommPackages(pcat).plus(pcat.proposals[proposalIndex].totalECommPackages(pcat));
        const packagesInWeek = totalPackages.div(52);

        newBudget.budgetProductivity[0].packages = parseFloat((packagesInWeek.times(0.10)).toFixed(3));
        newBudget.budgetProductivity[1].packages = parseFloat((packagesInWeek.times(0.05)).toFixed(3));
        newBudget.budgetProductivity[2].packages = parseFloat((packagesInWeek.times(0.15)).toFixed(3));
        newBudget.budgetProductivity[3].packages = parseFloat((packagesInWeek.times(0.175)).toFixed(3));
        newBudget.budgetProductivity[4].packages = parseFloat((packagesInWeek.times(0.175)).toFixed(3));
        newBudget.budgetProductivity[5].packages = parseFloat((packagesInWeek.times(0.175)).toFixed(3));
        newBudget.budgetProductivity[6].packages = parseFloat((packagesInWeek.times(0.175)).toFixed(3));

        const eCommPercentage = pcat.proposals[proposalIndex].totalECommStops(pcat).div(totalStops).times(100);

        newBudget.budgetProductivity.forEach((bp) => {
            bp.eCommStopPercentage = eCommPercentage.toNumber();
        })

        return newBudget;
    }

    redistributeStopsAndPackages(){
        
    }

    ///////////////////////
    //// INCOME FUNCTIONS
    ///////////////////////
    
    //Vehicle Branding
    dailyVehicleBrandingPromotionIncome(dayIndex){
        const dailyVehicleBrandingAverage = validateBig(this.budgetAssumptions.vehicleBranding).div(52).div(7);
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        if(dayDispatches.eq(0))
            return new Big('0.00');

        if(!this.getDaysThatHaveZeroDispatches()){   
            return dailyVehicleBrandingAverage;
        }

        return validateBig(this.budgetAssumptions.vehicleBranding).div(52).div(this.getDaysThatHaveZeroDispatches());
    }

    weeklyVehicleBrandingPromotionIncome(){
        return this.yearlyVehicleBrandingPromotionIncome().div(52);
    }

    yearlyVehicleBrandingPromotionIncome(){
        return validateBig(this.budgetAssumptions.vehicleBranding);
    }

    //Uniform Income
    dailyUniformIncome(dayIndex){
        const dailyVehicleBrandingAverage = validateBig(this.budgetAssumptions.uniforms).div(52).div(7);
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        if(dayDispatches.eq(0))
            return new Big('0.00');

        if(!this.getDaysThatHaveZeroDispatches()){   
            return dailyVehicleBrandingAverage;
        }

        return validateBig(this.budgetAssumptions.uniforms).div(52).div(this.getDaysThatHaveZeroDispatches());
    }

    weeklyUniformIncome(){
        return validateBig(this.budgetAssumptions.uniforms).div(52);
    }

    yearlyUniformIncome(){
        return validateBig(this.budgetAssumptions.uniforms);
    }

    // Package Payment
    dailyPackagePaymentIncome(dayIndex){
        let dailyPackagePayment = validateBig(this.budgetProductivity[dayIndex].packages).times(validateBig(this.budgetAssumptions.packageCharge));
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        if(dayDispatches.eq(0))
            return new Big('0.00');

        if(!this.getDaysThatHaveZeroDispatches()){   
            return dailyPackagePayment;
        }

        for(let i =0; i < 7; i++){
            if(i === dayIndex)
                continue;
            
            if(validateBig(this.budgetProductivity[i].dispatchesLight).plus(validateBig(this.budgetProductivity[i].dispatchesStandard)).plus(validateBig(this.budgetProductivity[i].dispatchesStraight)).eq(0)){
               dailyPackagePayment = dailyPackagePayment.plus(validateBig(this.budgetProductivity[i].packages).times(validateBig(this.budgetAssumptions.packageCharge)).div(this.getDaysThatHaveZeroDispatches()));
            }
        }
        return dailyPackagePayment;
    }

    weeklyPackagePaymentIncome(){
        let weekIncome = new Big('0.00');
        for(let i = 0; i<7; i++){
            weekIncome = weekIncome.plus(this.dailyPackagePaymentIncome(i));
        }
        return weekIncome;
    }

    yearlyPackagePaymentIncome(){
        return this.weeklyPackagePaymentIncome().times(52);
    }

    //Stop Payment
    dailyStopPaymentIncome(dayIndex){
        let groundStopMixPercentage = validateBig(100 - validateDecimal(this.budgetProductivity[dayIndex].eCommStopPercentage)).div(100);
        let eCommStopMixPercentage = validateBig(this.budgetProductivity[dayIndex].eCommStopPercentage).div(100);
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        let dailyStopPayment = validateBig(this.budgetProductivity[dayIndex].stops)
            .times(validateBig(this.budgetAssumptions.eCommStopCharge))
            .times(validateBig(eCommStopMixPercentage))
            .add(validateBig(this.budgetAssumptions.stopCharge)
            .times(validateBig(this.budgetProductivity[dayIndex].stops))
            .times(validateBig(groundStopMixPercentage)));

        if(dayDispatches.eq(0))
            return new Big('0.00');

        if(!this.getDaysThatHaveZeroDispatches()){   
            return dailyStopPayment;
        }

        for(let i =0; i < 7; i++){
            if(i === dayIndex)
                continue;
            
            if(validateBig(this.budgetProductivity[i].dispatchesLight).plus(validateBig(this.budgetProductivity[i].dispatchesStandard)).plus(validateBig(this.budgetProductivity[i].dispatchesStraight)).eq(0)){
                groundStopMixPercentage = validateBig(100 - validateDecimal(this.budgetProductivity[i].eCommStopPercentage)).div(100);
                eCommStopMixPercentage = validateBig(this.budgetProductivity[i].eCommStopPercentage).div(100);

               dailyStopPayment = dailyStopPayment.plus(validateBig(this.budgetProductivity[i].stops)
               .times(validateBig(this.budgetAssumptions.eCommStopCharge))
               .times(validateBig(eCommStopMixPercentage))
               .add(validateBig(this.budgetAssumptions.stopCharge)
               .times(validateBig(this.budgetProductivity[i].stops))
               .times(validateBig(groundStopMixPercentage))).div(this.getDaysThatHaveZeroDispatches()));
            }
        }
        return dailyStopPayment;
    }

    weeklyStopPaymentIncome(){
        let weekIncome = new Big('0.00');
        for(let i = 0; i<7; i++){
            weekIncome = weekIncome.plus(this.dailyStopPaymentIncome(i));
        }
        return weekIncome;
    }

    yearlyStopPaymentIncome(){
        return this.weeklyStopPaymentIncome().times(52);
    }

    //Service Charge
    dailyServiceChargeIncome(dayIndex){
        const dailyServiceCharge = validateBig(this.budgetAssumptions.serviceCharge).div(52).div(7);
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        if(dayDispatches.eq(0))
            return new Big('0.00');

        if(!this.getDaysThatHaveZeroDispatches()){   
            return dailyServiceCharge;
        }

        return dailyServiceCharge.times(7).div(this.getDaysThatHaveZeroDispatches());
    }

    weeklyServiceChargeIncome(){
        return validateBig(this.budgetAssumptions.serviceCharge).div(52);
    }

    yearlyServiceChargeIncome(){
        return validateBig(this.budgetAssumptions.serviceCharge);
    }

    // Fuel Stop Charge
    dailyFuelStopChargeIncome(dayIndex){
        let dailyFuelStopPayment = validateBig(this.budgetProductivity[dayIndex].stops).times(validateBig(this.budgetAssumptions.perStopFuelSurcharge));
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        if(dayDispatches.eq(0))
            return new Big('0.00');

        if(!this.getDaysThatHaveZeroDispatches()){   
            return dailyFuelStopPayment;
        }

        for(let i =0; i < 7; i++){
            if(i === dayIndex)
                continue;
            
            if(validateBig(this.budgetProductivity[i].dispatchesLight).plus(validateBig(this.budgetProductivity[i].dispatchesStandard)).plus(validateBig(this.budgetProductivity[i].dispatchesStraight)).eq(0)){
               dailyFuelStopPayment = dailyFuelStopPayment.plus(validateBig(this.budgetProductivity[i].stops).times(validateBig(this.budgetAssumptions.perStopFuelSurcharge)).div(this.getDaysThatHaveZeroDispatches()));
            }
        }
        return dailyFuelStopPayment;
    }

    weeklyFuelStopChargeIncome(){
        let weekIncome = new Big('0.00');
        for(let i = 0; i<7; i++){
            weekIncome = weekIncome.plus(this.dailyFuelStopChargeIncome(i));
        }
        return weekIncome;
    }

    yearlyFuelStopChargeIncome(){
        return this.weeklyFuelStopChargeIncome().times(52);
    }
    ///////////////
    dailyPackagePaymentIncome(dayIndex){
        let dailyPackagePayment = validateBig(this.budgetProductivity[dayIndex].packages).times(validateBig(this.budgetAssumptions.packageCharge));
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        if(dayDispatches.eq(0))
            return new Big('0.00');

        if(!this.getDaysThatHaveZeroDispatches()){   
            return dailyPackagePayment;
        }

        for(let i =0; i < 7; i++){
            if(i === dayIndex)
                continue;
            
            if(validateBig(this.budgetProductivity[i].dispatchesLight).plus(validateBig(this.budgetProductivity[i].dispatchesStandard)).plus(validateBig(this.budgetProductivity[i].dispatchesStraight)).eq(0)){
               dailyPackagePayment = dailyPackagePayment.plus(validateBig(this.budgetProductivity[i].packages).times(validateBig(this.budgetAssumptions.packageCharge)).div(this.getDaysThatHaveZeroDispatches()));
            }
        }
        return dailyPackagePayment;
    }
/////////////// 

    //Large Package Mix
    dailyLargePackageMixIncome(dayIndex){
        let largePackageMix = validateBig(this.budgetAssumptions.largePackageMixDeliveryPercentage).div(100);
        const dailyLargePackageMix = validateBig(largePackageMix).times(validateBig(this.budgetAssumptions.largePackageMixCharge)).times(validateBig(this.budgetAssumptions.totalDeliveryPackages)).div(52).div(7);

        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        if(dayDispatches.eq(0))
            return new Big('0.00');

        if(!this.getDaysThatHaveZeroDispatches()){   
            return dailyLargePackageMix;
        }

        return dailyLargePackageMix.times(7).div(this.getDaysThatHaveZeroDispatches());
    }

    weeklyLargePackageMixIncome(){
        return this.yearlyLargePackageMixIncome().div(52)
    }

    yearlyLargePackageMixIncome(){
        let largePackageMix = validateBig(this.budgetAssumptions.largePackageMixDeliveryPercentage).div(100);
        return validateBig(largePackageMix).times(validateBig(this.budgetAssumptions.largePackageMixCharge)).times(validateBig(this.budgetAssumptions.totalDeliveryPackages));
    }

    // Advanced Contract Service Charge
    dailyAdvancedContractServiceChargeIncome(dayIndex){
        const dailyAdvanceServiceCharge = validateBig(this.budgetAssumptions.advanceServiceCharge).div(52).div(7);
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        if(dayDispatches.eq(0))
            return new Big('0.00');

        if(!this.getDaysThatHaveZeroDispatches()){   
            return dailyAdvanceServiceCharge;
        }

        return dailyAdvanceServiceCharge.times(7).div(this.getDaysThatHaveZeroDispatches());
    }

    weeklyAdvancedContractServiceChargeIncome(){
        return validateBig(this.budgetAssumptions.advanceServiceCharge).div(52);
    }

    yearlyAdvancedContractServiceChargeIncome(){
        return validateBig(this.budgetAssumptions.advanceServiceCharge);
    }

    // TOTAL INCOME
    totalDailyRevenue(dayIndex){
        return this.dailyVehicleBrandingPromotionIncome(dayIndex).plus(this.dailyUniformIncome(dayIndex)).plus(this.dailyPackagePaymentIncome(dayIndex)).plus(this.dailyStopPaymentIncome(dayIndex))
                .plus(this.dailyServiceChargeIncome(dayIndex)).plus(this.dailyFuelStopChargeIncome(dayIndex)).plus(this.dailyLargePackageMixIncome(dayIndex)).
                plus(this.dailyAdvancedContractServiceChargeIncome(dayIndex));
    }

    totalWeeklyRevenue(){
        let revenue = new Big('0.00');
        for(let i = 0; i < 7; i++){
            revenue = revenue.plus(this.totalDailyRevenue(i));
        }
        return revenue;
    }

    totalYearlyRevenue(){
        return this.totalWeeklyRevenue().times(52);
    }

    ///////////////////////
    //// EXPENSES FUNCTIONS
    ///////////////////////

    // DRIVER WAGES
    dailyDriverWage(dayIndex){
        const lightWages = validateBig(this.budgetExpenses.driverWageLight).times(validateBig((this.budgetProductivity[dayIndex].dispatchesLight)));
        const standardWages = validateBig(this.budgetExpenses.driverWageStandard).times(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard));
        const straightWages = validateBig(this.budgetExpenses.driverWageStraight).times(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        return lightWages.plus(standardWages).plus(straightWages);
    }

    weeklyDriverWages(){
        let wages = new Big('0.00');
        for(let i =0; i<7; i++){
            wages = wages.plus(this.dailyDriverWage(i));
        }
        return wages;
    }

    yearlyDriverWages(){
        return this.weeklyDriverWages().times(52);
    }

     // AO WAGES
    dailyAoWages(dayIndex){
        const dailyAoWageAverage = validateBig(this.budgetExpenses.aoWages).times(12).div(52).div(7);
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        if(dayDispatches.eq(0))
            return new Big('0.00');

        if(!this.getDaysThatHaveZeroDispatches()){   
            return dailyAoWageAverage;
        }

        return validateBig(this.budgetExpenses.aoWages).times(12).div(52).div(this.getDaysThatHaveZeroDispatches());
    }

    weeklyAoWages(){
        return validateBig(this.budgetExpenses.aoWages).times(12).div(52);
    }

    yearlyAoWages(){
         return validateBig(this.budgetExpenses.aoWages).times(12);
    }

    // BC WAGES
    dailyBcWages(dayIndex){
        let dailyBcWageAverage = validateBig(this.budgetExpenses.bcWages).times(12).div(52).div(7);
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        if(dayDispatches.eq(0))
            return new Big('0.00');

        if(!this.getDaysThatHaveZeroDispatches()){   
            return dailyBcWageAverage;
        }

        return validateBig(this.budgetExpenses.bcWages).times(12).div(52).div(this.getDaysThatHaveZeroDispatches());
    }

    weeklyBcWages(){
        return validateBig(this.budgetExpenses.bcWages).times(12).div(52);
    }

    yearlyBcWages(){
        return validateBig(this.budgetExpenses.bcWages).times(12);
    }

    //Admin Wages

    dailyAdminWages(dayIndex){

        const dailyAdminWageAverage = validateBig(this.budgetExpenses.adminWages).times(12).div(52).div(7);
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        if(dayDispatches.eq(0))
            return new Big('0.00');

        if(!this.getDaysThatHaveZeroDispatches()){   
            return dailyAdminWageAverage;
        }

        return validateBig(this.budgetExpenses.adminWages).times(12).div(52).div(this.getDaysThatHaveZeroDispatches());
    }

    weeklyAdminWages(){
        return validateBig(this.budgetExpenses.adminWages).times(12).div(52);
    }

    yearlyAdminWages(){
        return validateBig(this.budgetExpenses.adminWages).times(12);
    }

    // AO PTO
     dailyAoPto(dayIndex){
        const dailyAoPtoAverage = validateBig(this.budgetExpenses.aoPto).times(12).div(52).div(7);
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        if(dayDispatches.eq(0))
            return new Big('0.00');

        if(!this.getDaysThatHaveZeroDispatches()){   
            return dailyAoPtoAverage;
        }

        return validateBig(this.budgetExpenses.aoPto).times(12).div(52).div(this.getDaysThatHaveZeroDispatches());
    }

    weeklyAoPto(){
        return validateBig(this.budgetExpenses.aoPto).times(12).div(52);
    }

    yearlyAoPto(){
         return validateBig(this.budgetExpenses.aoPto).times(12);
    }

    // BC PTO
    dailyBcPto(dayIndex){
        const dailyBcPtoAverage = validateBig(this.budgetExpenses.bcPto).times(12).div(52).div(7);
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        if(dayDispatches.eq(0))
            return new Big('0.00');

        if(!this.getDaysThatHaveZeroDispatches()){   
            return dailyBcPtoAverage;
        }

        return validateBig(this.budgetExpenses.bcPto).times(12).div(52).div(this.getDaysThatHaveZeroDispatches());
    }

    weeklyBcPto(){
        return validateBig(this.budgetExpenses.bcPto).times(12).div(52);
    }

    yearlyBcPto(){
         return validateBig(this.budgetExpenses.bcPto).times(12);
    }

    // Admin PTO
    dailyAdminPto(dayIndex){
        const dailyAdminPtoAverage = validateBig(this.budgetExpenses.adminPto).times(12).div(52).div(7);
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        if(dayDispatches.eq(0))
            return new Big('0.00');

        if(!this.getDaysThatHaveZeroDispatches()){   
            return dailyAdminPtoAverage;
        }

        return validateBig(this.budgetExpenses.adminPto).times(12).div(52).div(this.getDaysThatHaveZeroDispatches());
    }

    weeklyAdminPto(){
        return validateBig(this.budgetExpenses.adminPto).times(12).div(52);
    }

    yearlyAdminPto(){
         return validateBig(this.budgetExpenses.adminPto).times(12);
    }

    // UNIFORM
    dailyUniformCost(dayIndex){
        let dailyUniformAverage = validateBig(this.budgetExpenses.uniforms).times(12).div(52).div(7);
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        if(dayDispatches.eq(0))
            return new Big('0.00');

        if(!this.getDaysThatHaveZeroDispatches()){   
            return dailyUniformAverage;
        }

        return validateBig(this.budgetExpenses.uniforms).times(12).div(52).div(this.getDaysThatHaveZeroDispatches());
    }

    weeklyUniformCost(){
        return validateBig(this.budgetExpenses.uniforms).times(12).div(52);
    }

    yearlyUniformCost(){
        return this.weeklyUniformCost().times(52);
    }

    //Payroll
    dailyPayrollTaxExpense(dayIndex){
        let payrollExpense = validateBig(this.budgetExpenses.payrollTaxes).div(100);
        const driverDayWage = this.dailyDriverWage(dayIndex);
        const bcDayWage = this.dailyBcWages(dayIndex);
        const adminDayWage = this.dailyAdminWages(dayIndex);
        const dailyAoWages = this.dailyAoWages(dayIndex);
        const aoPto = this.dailyAoPto(dayIndex);
        const bcPto = this.dailyBcPto(dayIndex);
        const adminPto = this.dailyAdminPto(dayIndex);
        return validateBig(driverDayWage.plus(bcDayWage).plus(adminDayWage).plus(dailyAoWages).plus(aoPto).plus(bcPto).plus(adminPto).times(payrollExpense));
    }

    weeklyPayrollTaxExpense(){
        let expense = new Big('0.00');
        for(let i =0; i<7; i++){
            expense = expense.plus(this.dailyPayrollTaxExpense(i));
        }
        return expense;
    }

    yearlyPayrollTaxExpense(){
        return this.weeklyPayrollTaxExpense().times(52);
    }

    // Workmans Comp
    dailyWorkersCompExpense(dayIndex){
        let driversWorkersComp = validateBig(this.budgetExpenses.driverWorkersComp).div(100);
        let bcWorkersComp = validateBig(this.budgetExpenses.bcWorkersComp).div(100);
        const driverDayWage = this.dailyDriverWage(dayIndex);
        const bcDayWage = this.dailyBcWages(dayIndex);
        const adminDayWage = this.dailyAdminWages(dayIndex);
        const dailyAoWages = this.dailyAoWages(dayIndex);
        const aoPto = this.dailyAoPto(dayIndex);
        const bcPto = this.dailyBcPto(dayIndex);
        const adminPto = this.dailyAdminPto(dayIndex);

        const totalNonDriverWages = bcDayWage.plus(adminDayWage).plus(dailyAoWages);
        const totalNonDriverPto = (aoPto).plus(bcPto).plus(adminPto)

        return driverDayWage.times(driversWorkersComp).plus((totalNonDriverWages.plus(totalNonDriverPto)).times(bcWorkersComp)); 
    }

    weeklyWorkersCompExpense(){
        let expense = new Big('0.00');
        for(let i =0; i<7; i++){
            expense = expense.plus(this.dailyWorkersCompExpense(i));
        }
        return expense;
    }

    yearlyWorkersCompExpense(){
        return this.weeklyWorkersCompExpense().times(52);
    }

    

    // // Employee Benefits
    // dailyEmployeeBenefits(){
    //     return validateBig(this.budgetExpenses.benefits).times(12).div(52).div(7);
    // }

    // weeklyEmployeeBenefits(){
    //     return this.dailyEmployeeBenefits().times(7);
    // }

    // yearlyEmployeeBenefits(){
    //     return this.weeklyEmployeeBenefits().times(52);
    // }

    //Health Insurance

    dailyHealthInsurance(dayIndex){
        let dailyHealthInsuranceAverage = validateBig(this.budgetExpenses.healthInsurance).times(12).div(52).div(7);
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        if(dayDispatches.eq(0))
            return new Big('0.00');

        if(!this.getDaysThatHaveZeroDispatches()){   
            return dailyHealthInsuranceAverage;
        }

        return validateBig(this.budgetExpenses.healthInsurance).times(12).div(52).div(this.getDaysThatHaveZeroDispatches());
    }
    
    weeklyHealthInsurance(){
        return validateBig(this.budgetExpenses.healthInsurance).times(12).div(52);
    }

    yearlyHealthInsurance(){
        return validateBig(this.budgetExpenses.healthInsurance).times(12);
    }

    //Life Insurance
    dailyLifeInsurance(dayIndex){
        let dailyLifeInsuranceAverage = validateBig(this.budgetExpenses.lifeInsurance).times(12).div(52).div(7);
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        if(dayDispatches.eq(0))
            return new Big('0.00');

        if(!this.getDaysThatHaveZeroDispatches()){   
            return dailyLifeInsuranceAverage;
        }

        return validateBig(this.budgetExpenses.lifeInsurance).times(12).div(52).div(this.getDaysThatHaveZeroDispatches());
    }

    weeklyLifeInsurance(){
        return this.yearlyLifeInsurance().div(52);
    }

    yearlyLifeInsurance(){
        return validateBig(this.budgetExpenses.lifeInsurance).times(12);
    }

    //Dental & Vision

    dailyDentalAndVision(dayIndex){
        let dailyDentalAndVisionAverage = validateBig(this.budgetExpenses.dentalAndVision).times(12).div(52).div(7);
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        if(dayDispatches.eq(0))
            return new Big('0.00');

        if(!this.getDaysThatHaveZeroDispatches()){   
            return dailyDentalAndVisionAverage;
        }

        return validateBig(this.budgetExpenses.dentalAndVision).times(12).div(52).div(this.getDaysThatHaveZeroDispatches());
    }

    weeklyDentalAndVision(){
        return this.yearlyDentalAndVision().div(52);
    }

    yearlyDentalAndVision(){
        return validateBig(this.budgetExpenses.dentalAndVision).times(12);
    }

    //Pension/Profit Sharing
    dailyPensionAndProfitSharing(dayIndex){
        let dailyPensionAndProfitSharingAverage = validateBig(this.budgetExpenses.pensionAndProfitSharing).times(12).div(52).div(7);
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        if(dayDispatches.eq(0))
            return new Big('0.00');

        if(!this.getDaysThatHaveZeroDispatches()){   
            return dailyPensionAndProfitSharingAverage;
        }

        return validateBig(this.budgetExpenses.pensionAndProfitSharing).times(12).div(52).div(this.getDaysThatHaveZeroDispatches());
    }

    weeklyPensionAndProfitSharing(){
        return this.yearlyPensionAndProfitSharing().div(52);
    }

    yearlyPensionAndProfitSharing(){
        return validateBig(this.budgetExpenses.pensionAndProfitSharing).times(12);
    }

    //Drug Screens

    dailyDrugScreens(dayIndex){
        let dailyDrugScreenAverage = validateBig(this.budgetExpenses.drugScreens).times(12).div(52).div(7);
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        if(dayDispatches.eq(0))
            return new Big('0.00');

        if(!this.getDaysThatHaveZeroDispatches()){   
            return dailyDrugScreenAverage;
        }

        return validateBig(this.budgetExpenses.drugScreens).times(12).div(52).div(this.getDaysThatHaveZeroDispatches());
    }

    weeklyDrugScreens(){
        return this.yearlyDrugScreens().div(52);
    }

    yearlyDrugScreens(){
        return validateBig(this.budgetExpenses.drugScreens).times(12);
    }

    // 401k Match
    daily401KMatch(dayIndex){
        let daily401KMatchAverage = validateBig(this.budgetExpenses.four01KMatch).times(12).div(52).div(7);
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        if(dayDispatches.eq(0))
            return new Big('0.00');

        if(!this.getDaysThatHaveZeroDispatches()){   
            return daily401KMatchAverage;
        }
        return validateBig(this.budgetExpenses.four01KMatch).times(12).div(52).div(this.getDaysThatHaveZeroDispatches());
    }

    weekly401KMatch(){
        return validateBig(this.budgetExpenses.four01KMatch).times(12).div(52);
    }

    yearly401KMatch(){
        return this.weekly401KMatch().times(52);
    }

    //401K Admin
    daily401KAdmin(dayIndex){
        let daily401KAdminAverage = validateBig(this.budgetExpenses.four01KAdmin).times(12).div(52).div(7);
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        if(dayDispatches.eq(0))
            return new Big('0.00');

        if(!this.getDaysThatHaveZeroDispatches()){   
            return daily401KAdminAverage;
        }

        return validateBig(this.budgetExpenses.four01KAdmin).times(12).div(52).div(this.getDaysThatHaveZeroDispatches());
    }

    weekly401KAdmin(){
        return validateBig(this.budgetExpenses.four01KAdmin).times(12).div(52);
    }

    yearly401KAdmin(){
        return this.weekly401KAdmin().times(52);
    }

    // DOT PHYSICALS
    dailyDotPhysicalExpense(dayIndex){
        let dailyDotPhysicalsAverage = validateBig(this.budgetExpenses.dotPhysicals).times(12).div(52).div(7);
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        if(dayDispatches.eq(0))
            return new Big('0.00');

        if(!this.getDaysThatHaveZeroDispatches()){   
            return dailyDotPhysicalsAverage;
        }

        return validateBig(this.budgetExpenses.dotPhysicals).times(12).div(52).div(this.getDaysThatHaveZeroDispatches());
    }

    weeklyDotPhysicalExpense(){
        return validateBig(this.budgetExpenses.dotPhysicals).times(12).div(52);
    }

    yearlyDotPhysicalExpense(){
        return this.weeklyDotPhysicalExpense().times(52);
    }

    // ADMIN FEE
    dailyAdminExpense(dayIndex){
        let adminFee = validateBig(this.budgetExpenses.adminFee).div(100);
        const driverDayWage = this.dailyDriverWage(dayIndex);
        const bcDayWage = this.dailyBcWages(dayIndex);
        const aoDayWage = this.dailyAoWages(dayIndex);
        const adminDayWage = this.dailyAdminWages(dayIndex);

        const aoPto = this.dailyAoPto(dayIndex);
        const bcPto = this.dailyBcPto(dayIndex);
        const adminPto = this.dailyAdminPto(dayIndex);
        return validateBig(driverDayWage.plus(bcDayWage).plus(aoDayWage).plus(adminDayWage).plus(aoPto).plus(bcPto).plus(adminPto)).times(new Big(adminFee));
    }

    weeklyAdminExpense(){
        let admin = new Big('0.00');
        for(let i=0; i<7; i++){
            admin = admin.plus(this.dailyAdminExpense(i));
        }
        return admin;
    }

    yearlyAdminExpense(){
        return this.weeklyAdminExpense().times(52);
    }

    // RECRUITING
    dailyRecruitingExpense(dayIndex){
        let dailyRecruitingAverage = validateBig(this.budgetExpenses.recruiting).times(12).div(52).div(7);
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        if(dayDispatches.eq(0))
            return new Big('0.00');

        if(!this.getDaysThatHaveZeroDispatches()){   
            return dailyRecruitingAverage;
        }

        return validateBig(this.budgetExpenses.recruiting).times(12).div(52).div(this.getDaysThatHaveZeroDispatches());
    }
    
    weeklyRecruitingExpense(){
        return validateBig(this.budgetExpenses.recruiting).times(12).div(52);
    }

    yearlyRecruitingExpense(){
        return this.weeklyRecruitingExpense().times(52);
    }
    
    //FUEL
    dailyFuelExpense(dayIndex){
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));
        const weekDispatches = this.budgetProductivity.reduce((prev, curr)=>{
            return prev += (validateInteger(curr.dispatchesLight) + validateInteger(curr.dispatchesStandard) + validateInteger(curr.dispatchesStraight))
        }, 0)

        if(weekDispatches === 0){
            return new Big(0);
        }
        return validateBig(this.budgetExpenses.fuel).times(new Big(12).div(52)).div(weekDispatches).times(dayDispatches);
    }

    weeklyFuelExpense(){
        let fuel = new Big('0.00');
        for(let i=0; i<7; i++){
            fuel = fuel.plus(this.dailyFuelExpense(i));
        }
        return fuel;
    }

    yearlyFuelExpense(){
        return this.weeklyFuelExpense().times(52);
    }

    // Maintenance
    dailyMaintenanceExpense(dayIndex){
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));
        const weekDispatches = this.budgetProductivity.reduce((prev, curr)=>{
            return prev += (validateInteger(curr.dispatchesLight) + validateInteger(curr.dispatchesStandard) + validateInteger(curr.dispatchesStraight))
        }, 0)

        if(weekDispatches === 0){
            return new Big(0);
        }
        return validateBig(this.budgetExpenses.maintenance).times(new Big(12).div(52)).div(weekDispatches).times(dayDispatches);   
    }

    weeklyMaintenanceExpense(){
        let maintenance = new Big('0.00');
        for(let i=0; i<7; i++){
            maintenance = maintenance.plus(this.dailyMaintenanceExpense(i));
        }
        return maintenance;
    }

    yearlyMaintenanceExpense(){
        return validateBig(this.budgetExpenses.maintenance).times(12);
    }

    //Truck Payments
    dailyTruckPaymentExpense(dayIndex){
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));
        const weekDispatches = this.budgetProductivity.reduce((prev, curr)=>{
            return prev += (validateInteger(curr.dispatchesLight) + validateInteger(curr.dispatchesStandard) + validateInteger(curr.dispatchesStraight))
        }, 0)

        if(weekDispatches === 0){
            return new Big(0);
        }
        return validateBig(this.budgetExpenses.truckPayments).times(new Big(12).div(52)).div(weekDispatches).times(dayDispatches);  
    }

    weeklyTruckPaymentExpense(){
        let truck = new Big('0.00');
        for(let i=0; i<7; i++){
            truck = truck.plus(this.dailyTruckPaymentExpense(i));
        }
        return truck;
    }

    yearlyTruckPaymentExpense(){
        return validateBig(this.budgetExpenses.truckPayments).times(12);
    }

    // License and Registration
    dailyLicenseRegistrationExpense(dayIndex){
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));
        const weekDispatches = this.budgetProductivity.reduce((prev, curr)=>{
            return prev += (validateInteger(curr.dispatchesLight) + validateInteger(curr.dispatchesStandard) + validateInteger(curr.dispatchesStraight))
        }, 0)

        if(weekDispatches === 0){
            return new Big(0);
        }
        return validateBig(this.budgetExpenses.licenseAndRegistration).times(new Big(12).div(52)).div(weekDispatches).times(dayDispatches); 
    }

    weeklyLicenseRegistrationExpense(){
        let license = new Big('0.00');
        for(let i=0; i<7; i++){
            license = license.plus(this.dailyLicenseRegistrationExpense(i));
        }
        return license;
    }

    yearlyLicenseRegistrationExpense(){
        return validateBig(this.budgetExpenses.licenseAndRegistration).times(12);
    }

    // Insurance
    dailyInsuranceExpense(dayIndex){
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));
        const weekDispatches = this.budgetProductivity.reduce((prev, curr)=>{
            return prev += (validateInteger(curr.dispatchesLight) + validateInteger(curr.dispatchesStandard) + validateInteger(curr.dispatchesStraight))
        }, 0)

        if(weekDispatches === 0){
            return new Big(0);
        }
        return validateBig(this.budgetExpenses.insurance).times(new Big(12).div(52)).div(weekDispatches).times(dayDispatches);
    }

    weeklyInsuranceExpense(){
        let insurance = new Big('0.00');
        for(let i=0; i<7; i++){
            insurance = insurance.plus(this.dailyInsuranceExpense(i));
        }
        return insurance;
    }

    yearlyInsuranceExpense(){
        return this.weeklyInsuranceExpense().times(52);
    }

    // Van Washing
    dailyVanWashingExpense(dayIndex){
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));
        const weekDispatches = this.budgetProductivity.reduce((prev, curr)=>{
            return prev += (validateInteger(curr.dispatchesLight) + validateInteger(curr.dispatchesStandard) + validateInteger(curr.dispatchesStraight))
        }, 0)

        if(weekDispatches === 0){
            return new Big(0);
        }
        return validateBig(this.budgetExpenses.vanWashing).times(new Big(12).div(52)).div(weekDispatches).times(dayDispatches);
    }

    weeklyVanWashingExpense(){
        let van = new Big('0.00');
        for(let i=0; i<7; i++){
            van = van.plus(this.dailyVanWashingExpense(i));
        }
        return van;
    }

    yearlyVanWashingExpense(){
        return this.weeklyVanWashingExpense().times(52);
    }

    // Van Decals

    dailyVanDecals(dayIndex) {


        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));
        const weekDispatches = this.budgetProductivity.reduce((prev, curr)=>{
            return prev += (validateInteger(curr.dispatchesLight) + validateInteger(curr.dispatchesStandard) + validateInteger(curr.dispatchesStraight))
        }, 0)

        if(weekDispatches === 0){
            return new Big(0);
        }
        return validateBig(this.budgetExpenses.vanDecals).times(12).div(52).div(weekDispatches).times(dayDispatches);
    }

    weeklyVanDecals(){
        return this.yearlyVanDecals().div(52);
    }

    yearlyVanDecals(){
        return validateBig(this.budgetExpenses.vanDecals).times(12);
    }

    //Truck Rental
    dailyTruckRentalExpense(dayIndex){
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));
        const weekDispatches = this.budgetProductivity.reduce((prev, curr)=>{
            return prev += (validateInteger(curr.dispatchesLight) + validateInteger(curr.dispatchesStandard) + validateInteger(curr.dispatchesStraight))
        }, 0)

        if(weekDispatches === 0){
            return new Big(0);
        }
        return validateBig(this.budgetExpenses.truckRentals).times(new Big(12).div(52)).div(weekDispatches).times(dayDispatches);
    }

    weeklyTruckRentalExpense(){
        let truck = new Big('0.00');
        for(let i=0; i<7; i++){
            truck = truck.plus(this.dailyTruckRentalExpense(i));
        }
        return truck;
    }

    yearlyTruckRentalExpense(){
        return this.weeklyTruckRentalExpense().times(52);
    }

    //Technology
    dailyTechnologyExpense(dayIndex){
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));
        const weekDispatches = this.budgetProductivity.reduce((prev, curr)=>{
            return prev += (validateInteger(curr.dispatchesLight) + validateInteger(curr.dispatchesStandard) + validateInteger(curr.dispatchesStraight))
        }, 0)

        if(weekDispatches === 0){
            return new Big(0);
        }
        return validateBig(this.budgetExpenses.technology).times(new Big(12).div(52)).div(weekDispatches).times(dayDispatches);
    }

    weeklyTechnologyExpense(){
        let tech = new Big('0.00');
        for(let i=0; i<7; i++){
            tech = tech.plus(this.dailyTechnologyExpense(i));
        }
        return tech;
    }

    yearlyTechnologyExpense(){
        return validateBig(this.budgetExpenses.technology).times(12)
    }

    //OTHER / MISC
    dailyOtherExpense(dayIndex){
        let dailyOtherAverage = validateBig(this.budgetExpenses.combineOtherExpenses()).times(12).div(52).div(7);
        const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));

        if(dayDispatches.eq(0))
            return new Big('0.00');

        if(!this.getDaysThatHaveZeroDispatches()){   
            return dailyOtherAverage;
        }

        return dailyOtherAverage.times(7).div(this.getDaysThatHaveZeroDispatches());
    }
    // dailyOtherExpense(dayIndex){
    //     const dayDispatches = validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));
    //     const weekDispatches = this.budgetProductivity.reduce((prev, curr)=>{
    //         return prev += (validateInteger(curr.dispatchesLight) + validateInteger(curr.dispatchesStandard) + validateInteger(curr.dispatchesStraight))
    //     }, 0)

    //     if(weekDispatches === 0){
    //         return new Big(0);
    //     }
    //     return validateBig(this.budgetExpenses.other).times(new Big(12).div(52)).div(weekDispatches).times(dayDispatches);
    // }

    weeklyOtherExpense(){
        return validateBig(this.budgetExpenses.combineOtherExpenses()).times(12).div(52);
    }

    // weeklyOtherExpense(){
    //     let other = new Big('0.00');
    //     for(let i=0; i<7; i++){
    //         other = other.plus(this.dailyOtherExpense(i));
    //     }
    //     return other;
    // }

    weekly401KAdmin(){
        return validateBig(this.budgetExpenses.four01KAdmin).times(12).div(52);
    }

    yearlyOtherExpense(){
        return this.budgetExpenses.combineOtherExpenses().times(12);
    }

    //Total Expense
    totalDailyExpenses(dayIndex){
        return this.dailyDriverWage(dayIndex)
            .plus(this.dailyAoWages(dayIndex))
            .plus(this.dailyBcWages(dayIndex)) 
            .plus(this.dailyAdminWages(dayIndex))
            .plus(this.dailyAoPto(dayIndex))
            .plus(this.dailyBcPto(dayIndex)) 
            .plus(this.dailyAdminPto(dayIndex))  
            .plus(this.dailyUniformCost(dayIndex))
            .plus(this.dailyPayrollTaxExpense(dayIndex))
            .plus(this.dailyWorkersCompExpense(dayIndex))
            .plus(this.dailyHealthInsurance(dayIndex))
            .plus(this.dailyLifeInsurance(dayIndex))
            .plus(this.dailyDentalAndVision(dayIndex))
            .plus(this.dailyPensionAndProfitSharing(dayIndex))
            .plus(this.dailyDrugScreens(dayIndex))
            .plus(this.daily401KMatch(dayIndex))
            .plus(this.daily401KAdmin(dayIndex))
            .plus(this.dailyDotPhysicalExpense(dayIndex))
            .plus(this.dailyAdminExpense(dayIndex))
            .plus(this.dailyRecruitingExpense(dayIndex))
            .plus(this.dailyFuelExpense(dayIndex))
            .plus(this.dailyMaintenanceExpense(dayIndex))
            .plus(this.dailyTruckPaymentExpense(dayIndex))
            .plus(this.dailyLicenseRegistrationExpense(dayIndex))
            .plus(this.dailyInsuranceExpense(dayIndex))
            .plus(this.dailyVanWashingExpense(dayIndex))
            .plus(this.dailyVanDecals(dayIndex))
            .plus(this.dailyTruckRentalExpense(dayIndex))
            .plus(this.dailyTechnologyExpense(dayIndex))
            .plus(this.dailyOtherExpense(dayIndex));
    }

    totalWeeklyExpenses(){
        let expenses = new Big('0.00');
        for(let i = 0; i < 7; i++){
            expenses = expenses.plus(this.totalDailyExpenses(i));
        }
        return expenses;
    }

    totalYearlyExpenses(){
        return this.totalWeeklyExpenses().times(52);
    }

    //Dispatches
    totalDailyDispatches(dayIndex){
        return validateBig(this.budgetProductivity[dayIndex].dispatchesLight).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStandard)).plus(validateBig(this.budgetProductivity[dayIndex].dispatchesStraight));
    }

    totalWeeklyDispatches(){
        const weekDispatches = this.budgetProductivity.reduce((prev, curr)=>{
            return prev += (validateInteger(curr.dispatchesLight) + validateInteger(curr.dispatchesStandard) + validateInteger(curr.dispatchesStraight))
        }, 0)

        return validateBig(weekDispatches);
    }

    totalYearlyDispatches(){
        return this.totalWeeklyDispatches().times(52);
    }

    //Revenue per Dispatch
    dailyRevenuePerDispatch(dayIndex){
        if(this.totalDailyDispatches(dayIndex).eq(0)){
            return new Big('0.00');
        } else {
            return this.totalDailyRevenue(dayIndex).div(this.totalDailyDispatches(dayIndex));
        }

    }

    weeklyRevenuePerDispatch(){
        if(this.totalWeeklyDispatches().eq(0)){
            return new Big('0.00');
        } else {
            return this.totalWeeklyRevenue().div(this.totalWeeklyDispatches());
        }
    }

    yearlyRevenuePerDispatch(){
        if(this.totalYearlyDispatches().eq(0)){
            return new Big('0.00');
        } else {
            return this.totalYearlyRevenue().div(this.totalYearlyDispatches());
        }
    }

    //Cost per dispatches

    dailyExpensesPerDispatch(dayIndex){
        if(this.totalDailyDispatches(dayIndex).eq(0)){
            return new Big('0.00');
        } else {
            return this.totalDailyExpenses(dayIndex).div(this.totalDailyDispatches(dayIndex));
        }

    }

    weeklyExpensesPerDispatch(){
        if(this.totalWeeklyDispatches().eq(0)){
            return new Big('0.00');
        } else {
            return this.totalWeeklyExpenses().div(this.totalWeeklyDispatches());
        }
    }

    yearlyExpensesPerDispatch(){
        if(this.totalYearlyDispatches().eq(0)){
            return new Big('0.00');
        } else {
            return this.totalYearlyExpenses().div(this.totalYearlyDispatches());
        }
    }

    ///////////////////////
    /// PROFIT FUNCTIONS
    //////////////////////

    //Profit
    dailyNetProfit(dayIndex){
        return validateBig(this.totalDailyRevenue(dayIndex).minus(this.totalDailyExpenses(dayIndex)));
    }

    weeklyNetProfit(){
        return validateBig(this.totalWeeklyRevenue().minus(this.totalWeeklyExpenses()));
    }

    yearlyNetProfit(){
        return validateBig(this.totalYearlyRevenue().minus(this.totalYearlyExpenses()));
    }

    weeklyMargin(){
        if(this.totalWeeklyRevenue().eq(0)){
            return new Big('0.00');
        } else {
            return validateBig(this.weeklyNetProfit().div(this.totalWeeklyRevenue())).times(100);
        }
    }

    yearlyMargin(){
        if(this.totalYearlyRevenue().eq(0)){
            return new Big('0.00');
        } else {
            return validateBig(this.yearlyNetProfit().div(this.totalYearlyRevenue())).times(100);
        }
    }


    //HELPER FUNCTION
    getDaysThatHaveZeroDispatches(){
        let totalDaysThatHaveZeroDispatches = 0;
        for(let i =0; i < 7; i++){
            const totalDispatches = validateBig(this.budgetProductivity[i].dispatchesStandard).plus(validateBig(this.budgetProductivity[i].dispatchesLight)).plus(validateBig(this.budgetProductivity[i].dispatchesStraight))  
            if(totalDispatches.eq(0)){
                totalDaysThatHaveZeroDispatches++;
            }
        }

        if(!totalDaysThatHaveZeroDispatches)
            return 0;
        
        return 7-totalDaysThatHaveZeroDispatches;
    }
}