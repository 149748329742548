/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { bigToUsd, validateDecimal, validateInteger, validateUsd } from "../payrollTools";
import { usdFormatter } from "../../../tools";
import PayrollPeriodEntryPayRow from "./PayrollPeriodEntryPayRow";
import AdditionalPay from "../Models/AdditionalPay";
import DBonus from "../Models/DBonus";
import Deduction from "../Models/Deduction";

export default function PayrollPeriodEntryRow(entry) {
    let rows = [];

    const entryColumnInclusion = entry.getColumnInclusion();
    
    if (entryColumnInclusion.includeOvertimeColumns) {
        entry.weeks.forEach((week, index) => {
            let weekRows;
            if (!week.qualifiesForFLSA()) {
                weekRows = week.pay.map(pay => PayrollPeriodEntryPayRow(pay));
                if (weekRows.length === 0) {
                    weekRows.push({});
                }
            } else {
                weekRows = [{
                    'GVWR < 10k': 'Yes',
                    'Hourly Rate': bigToUsd(week.hourlyRate()),
                    'Adjusted Hourly Rate': bigToUsd(week.adjustedHourlyRate()),
                    'Overtime Rate': bigToUsd(week.overtimeRate()),
                    'Days Worked': validateDecimal(week.daysWorked),
                    'Hours Worked': week.straightTimeHoursWorked().toFixed(2),
                    'Overtime Hours': week.overtimeHoursWorked().toFixed(2),
                    'Hourly Wages': bigToUsd(week.hourlyWages()),
                    'Overtime Wages': bigToUsd(week.overtimeWages())
                }];
            }
            rows = rows.concat(weekRows);
            weekRows.forEach(r => r['Week #'] = index + 1);
        })
    } else {
        const reducedWeeks = entry.getReducedWeeks();
        rows = reducedWeeks.pay.filter(p => !['iw', 'sw'].includes(p.payType)).map(p => PayrollPeriodEntryPayRow(p));
        if (rows.length === 0) {
            rows.push({});
        }

        if (!rows[0].hasOwnProperty('Days Worked')) {
            rows[0]['Days Worked'] = validateDecimal(reducedWeeks.daysWorked);
        }
        if (!rows[0].hasOwnProperty('Hours Worked')) {
            rows[0]['Hours Worked'] = validateDecimal(reducedWeeks.hoursWorked);
        }
    }

    const firstRow = rows[0];

    firstRow['Name'] = entry.name();
    firstRow['CSA'] = entry.csaName;
    firstRow['BWC Code'] = entry.bwcCode;
    if (entryColumnInclusion.includePto) {
        firstRow['PTO Hours'] = entry.pto();
    }
    if (entryColumnInclusion.includePto) {
        firstRow['PTO Wages'] = bigToUsd(entry.totalPtoWages());
    }
    if (entryColumnInclusion.includeHolidayWages) {
        firstRow['Holiday Wages'] = bigToUsd(entry.totalHolidayWages());
    }
    if (entryColumnInclusion.includeOTStopWages) {
        firstRow['OT Stop Wages'] = bigToUsd(entry.totalOTStopWages().plus(entry.totalNdBonusOtStopWages()));
    }
    if (entryColumnInclusion.includeStop && entryColumnInclusion.includeOTStopWages) {
        firstRow['Stop/OT Stop Wages'] = bigToUsd(entry.totalStopAndOTStopWages());
    }
    
    firstRow['Incentive Wages'] = bigToUsd(entry.totalIncentiveWages());
    firstRow['Stand-By Wages'] = bigToUsd(entry.totalStandByWages());
    firstRow['Extra Day Pay'] = bigToUsd(entry.totalAutoOvertimeWages());

    const ndBonusColumnInclusion = entry.getNDBonusColumnInclusion();
    const ndBonusTotals = entry.totalNdBonusesByType();

    ndBonusColumnInclusion.forEach((type) => {
        firstRow[type] = usdFormatter.format(ndBonusTotals[type]);
    })

    // if (entryColumnInclusion.includeHolidayBonus) {firstRow['Holiday Bonus'] = usdFormatter.format(entry.totalHolidayBonuses());}
    // if (entryColumnInclusion.includeOtherDiscretionaryBonus) {firstRow['Other Discretionary Bonus'] = usdFormatter.format(entry.totalOtherDiscretionaryBonuses());}

    DBonus.dBonusTypes.forEach((type) => {
        const total = entry.dBonuses.filter(dBonus => dBonus.type === type).reduce((prev, curr) => {
            return prev + curr.getAmount();
        }, 0.0);
        if (total > 0) {
            firstRow[type] = usdFormatter.format(total);
        }
    })

    AdditionalPay.additionalPayTypes.forEach((apType) => {
        entry.getAllAdditionalPay().filter(ap => ap.type === apType).forEach((ap, index) => {
            firstRow[`${apType} #${index + 1}`] = usdFormatter.format(ap.getAmount());
        });
    })

    
    firstRow['Gross'] = bigToUsd(entry.gross());

    for (let i = 0; i < entry.getEnabledLoans().length; i++) {
        firstRow[`Loan #${i + 1}`] = usdFormatter.format(entry.getEnabledLoans()[i].getAmount());
    }

    const filteredTickets = entry.getEnabledTickets().filter(t => t.getAmountToPay(entry) > 0);
    for (let i = 0; i < filteredTickets.length; i++) {
        firstRow[`Fine Ticket Damage #${i + 1}`] = usdFormatter.format(filteredTickets[i].getAmountToPay(entry));
    }

    if (entryColumnInclusion.inlcudeChildSupport) {
        firstRow['Child Support'] = usdFormatter.format(entry.totalChildSupport())
    };
    if (entryColumnInclusion.includeMedical) {
        firstRow['Medical Insurance'] = usdFormatter.format(validateDecimal(entry.medical))
    };
    if (entryColumnInclusion.includeDental) {
        firstRow['Dental Insurance'] = usdFormatter.format(validateDecimal(entry.dental))
    };
    if (entryColumnInclusion.includeVision) {
        firstRow['Vision Insurance'] = usdFormatter.format(validateDecimal(entry.vision))
    };

    Deduction.deductionTypes.forEach((type) => {
        const total = entry.deductions.filter(d => d.type === type).reduce((prev, curr) => {
            return prev + curr.getAmount();
        }, 0.0);
        if (total > 0) {
            firstRow[type] = type === '401K (% of Gross)' || type === 'Other (% of Gross)' ? `${total}%` : usdFormatter.format(total);
        }
    })

    firstRow['Total Deductions'] = bigToUsd(entry.totalDeductions());

    firstRow['Notes'] = entry.getNotesWithLoansAndTickets();
    
    return rows;
}