/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import AddressControl from "../../components/AddressControl";
import CustomControl from "../../components/CustomControl";
import { validateInteger } from "../../tools";
import { AddressValidator, KeyedValidationGroup, validatePhoneNumber} from "../../validation";
import { ApiRequest } from "../../ApiManager.tsx";
import LoadingWrapper from "../../components/LoadingWrapper";

export default function MyCompanyProfile({}){
    const [isLoading, setIsLoading] = useState(false);
    const [profile, setProfile] = useState(undefined);
    const [originalProfile, setOriginalProfile] = useState(undefined);

    const addressValidator = profile ? new AddressValidator(profile.address) : undefined;
    const validationGroup = new KeyedValidationGroup();
    validationGroup.pushValidator('address', addressValidator);

    useEffect(()=>{
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('myCompany', 'getProfile', setIsLoading, (response) => {setProfile(response.profile); setOriginalProfile(response.profile)}).withNoAlertOnSuccess().send();
    }

    function handleSaveSettings(){
        const dataToSave = {
            ...profile,
            ein: validateInteger(profile.ein)
        }
        new ApiRequest('myCompany', 'updateProfile', setIsLoading, () => setOriginalProfile(structuredClone(dataToSave))).withData({data: dataToSave}).send()
    }

    function handleSetSettings(key, value) {
        const newProfile = structuredClone(profile);
        newProfile[key] = value;
        setProfile(newProfile);
    }

    const content = !profile ? '' : (
        <div>
            <h3 style={{textAlign: 'center', margin: 0, fontWeight: 'bold'}}>{profile.companyName}</h3>
            <Container fluid>
                <Row>
                    <AddressControl validator={addressValidator} md={6} title='Company Address' originalValue={originalProfile.address} address={profile.address} setAddress={(value) => handleSetSettings('address', value)}/>
                    <CustomControl md={12} type='text' title='Company Benefits' originalValue={originalProfile.benefits} value={profile.benefits} onChange={(event) => handleSetSettings('benefits', event.target.value)}/>
                    <CustomControl maxLength={10} validator={validationGroup.createValidator('phoneNumber', profile.phoneNumber, validatePhoneNumber)} md={6} xl={4} type='text' title='Phone Number' originalValue={originalProfile.phoneNumber} value={profile.phoneNumber} onChange={(event) => handleSetSettings('phoneNumber', event.target.value)}/>
                    <CustomControl maxLength={30} md={6} xl={4} type='text' title='MyGroundBiz Username' originalValue={originalProfile.fedexUsername} value={profile.fedexUsername} onChange={(event) => handleSetSettings('fedexUsername', event.target.value)}/>
                    <CustomControl maxLength={30} md={6} xl={4} type='text' title='MyGroundBiz Password' originalValue={originalProfile.fedexPassword} value={profile.fedexPassword} onChange={(event) => handleSetSettings('fedexPassword', event.target.value)}/>
                    <CustomControl maxLength={30} md={6} xl={4} type='text' title='BWC Username' originalValue={originalProfile.wcUsername} value={profile.wcUsername} onChange={(event) => handleSetSettings('wcUsername', event.target.value)}/>
                    <CustomControl maxLength={30} md={6} xl={4} type='text' title='BWC Password' originalValue={originalProfile.wcPassword} value={profile.wcPassword} onChange={(event) => handleSetSettings('wcPassword', event.target.value)}/>
                    <CustomControl maxLength={9} md={6} xl={4} type='number' title='EIN' originalValue={originalProfile.ein ? originalProfile.ein : ''} value={profile.ein ? profile.ein : ''} onChange={(event) => handleSetSettings('ein', event.target.value)}/>
                    <CustomControl maxLength={10} md={6} xl={4} type='text' title='BWC Policy #' originalValue={originalProfile.wcPolicyNumber ? originalProfile.wcPolicyNumber : ''} value={profile.wcPolicyNumber ? profile.wcPolicyNumber : ''} onChange={(event) => handleSetSettings('wcPolicyNumber', event.target.value)}/>
                    <Col xxl={12} style={{marginBottom: 8, display: 'flex', justifyContent: 'flex-end'}}>
                        <Button variant={'outline-primary'} disabled={!validationGroup.isValid()} onClick={handleSaveSettings}>Save Changes</Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )

    return (
        <LoadingWrapper isLoading={isLoading}>
            {content}
        </LoadingWrapper>
    )
}