/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import CustomButton from '../../../components/CustomButton';
import CustomControl from '../../../components/CustomControl';
import TextAreaControl from '../../../components/TextAreaControl';
import { validateExistence, ValidationGroup } from '../../../validation';
import { validateDecimal } from '../../../tools';
import { Button } from 'react-bootstrap';
import AlertModal from '../../../components/AlertModals/AlertModal';
import { ApiRequest } from '../../../ApiManager.tsx';
import CustomTextArea from '../../../components/CustomTextArea.js';

export default function HRUserPtoModal({companyUserIdentifier, selectedTransaction, handleTransactionCrud}) {
    const [notes, setNotes] = useState(selectedTransaction ? selectedTransaction.notes : '');
    const [ptoAmount, setPtoAmount] = useState(selectedTransaction ? selectedTransaction.number : '');
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState();
    const [showDelete, setShowDelete] = useState(false);
    const validationGroup = new ValidationGroup();

    function handleSubmit(){
        const transaction = {
            ...selectedTransaction,
            companyUserIdentifier: companyUserIdentifier,
            dateandtime: moment().format('YYYY-MM-DD HH:mm:ss'),
            number: validateDecimal(ptoAmount),
            notes: notes,
        };

        if(selectedTransaction){
            new ApiRequest('hr', 'updatePto', setIsLoading, () => handleTransactionCrud('update', transaction)).withData({pto: transaction}).send();
        } else {
            new ApiRequest('hr', 'createPtoTransaction', setIsLoading, (response) => {transaction.uid = response.uid; handleTransactionCrud('create', transaction)})
            .withData({
                companyUserIdentifier: transaction.companyUserIdentifier,
                number: transaction.number,
                notes: transaction.notes
            }).send();
        }
    }

    function handleDelete(){
        if(selectedTransaction){
            new ApiRequest('hr', 'deletePto', setIsDeleting, () => handleTransactionCrud('delete', selectedTransaction)).withUid(selectedTransaction.uid).send();
        }
    }

    return (
        <>
            <Modal.Header closeButton style={{gap: 12}}>
                <Modal.Title>{selectedTransaction ? 'Edit PTO Transaction' : 'Create PTO Transaction'}</Modal.Title>
                <Button variant='outline-primary' onClick={() => {setShowDelete(true)}}>Delete Transaction</Button>
            </Modal.Header>
            <Modal.Body>
                <div style={{ paddingBottom: 10 }}>
                    <CustomControl
                        type='number'
                        maxInt={9999}
                        value={ptoAmount}
                        onChange={(event) => {setPtoAmount(event.target.value)}}
                        title='PTO Amount'
                        disabled={isLoading}
                        validator={validationGroup.createValidator(()=>validateExistence(ptoAmount))}
                    />
                </div>
                <div>
                    <TextAreaControl
                        rows={10}
                        value={notes}
                        max={300}
                        setValue={(event) => {setNotes(event.target.value)}}
                        label='Transaction Notes'
                        disabled={isLoading}
                        validator={validationGroup.createValidator(()=>validateExistence(notes))}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton
                    onClick={handleSubmit}
                    isLoading={isLoading}
                    label={selectedTransaction ? 'Save Changes' : 'Submit'}
                    disabled={!validationGroup.isValid()}
                />
            </Modal.Footer>
            <Modal show={showDelete} onHide={() => {setShowDelete(false)}}>
                <AlertModal isLoading={isDeleting} callBack={handleDelete} variant='outline-danger' title='Delete PTO Transaction?' message='This cannot be undone. If this transaction is associated with scheduled PTO, the scheduled item will be deleted as well.' buttonLabel='Delete Transaction'/>
            </Modal>
        </>
    );
}
