/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './HumanResources.css';
import React, {useEffect, useState} from 'react';
import HRHub from './HRHub/HRHub.js';
import { ApiRequest } from '../../ApiManager.tsx';
import moment from 'moment';
import LoadingWrapper from '../../components/LoadingWrapper.js';
import HumanResourcesUser from './HumanResourcesUser.js';

export default function HumanResources() {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
    const [users, setUsers] = useState([]);
    const [csas, setCsas] = useState([]);
    const [timeData, setTimeData] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);

    useEffect(() => {
        loadData();
    }, [selectedDate])

    function loadData() {
        new ApiRequest('hr', 'getAll', setIsLoading, (response) => {
            response.users.forEach((user) => {
                user.scheduleItems = response.scheduleItems.filter(r => r.companyUserIdentifier == user.companyUserUid);
                user.routes = response.routes.filter(r => (r.companyUserIdentifier == user.companyUserUid || r.secondaryDriver == user.companyUserUid));
            });
            setUsers(response.users.sort(sortUsers));
            setCsas(response.csas);
            setTimeData(response.timeData);
        }).withData({date: selectedDate}).withNoAlertOnSuccess().send();
    }

    function handleGoBackToHub() {
        setSelectedEmployee(undefined);
        loadData();
    }

    function sortUsers(a, b) {
        var nameA = `${a.lastName}${a.firstName}${a.middleName}`.toLowerCase();
        var nameB = `${b.lastName}${b.firstName}${b.middleName}`.toLowerCase();
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    }

    return (
        <LoadingWrapper isLoading={isLoading}>
            <div style={{display: 'flex', flexDirection: 'column', padding: 12, height: '100%'}}>
                {selectedEmployee ?
                    <HumanResourcesUser selectedEmployee={selectedEmployee} handleGoBackToHub={handleGoBackToHub} users={users} csas={csas}/>
                :
                    <HRHub selectedEmployee={selectedEmployee} setSelectedEmployee={setSelectedEmployee} selectedDate={selectedDate} setSelectedDate={setSelectedDate} users={users} csas={csas} timeData={timeData} setTimeData={setTimeData}/>
                }
            </div>
        </LoadingWrapper>
        
    )
}