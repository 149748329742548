/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import MyCompanyWorkArea from "./MyCompanyWorkArea";
import CreateCompanyWorkAreas from "./CreateCompanyWorkAreas";
import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import DropdownItemWithCheck from "../../components/DropdownItemWithCheck";
import LoadingWrapper from "../../components/LoadingWrapper";
import { ApiRequest } from "../../ApiManager.tsx";
import QuickTable from "../../components/QuickTable";

export default function MyCompanyWorkAreas({restrictedToCsa}) {
    const [showInactiveItems, setShowInactiveItems] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showCreateMultipleModal, setShowCreateMultipleModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [workAreas, setWorkAreas] = useState([]);
    const [csas, setCsas] = useState([]);
    const [selectedCsas, setSelectedCsas] = useState([]);

    useEffect(() => {
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('myCompany', 'getWorkAreas', setIsLoading, (response) => {
            const csas = (response.workAreas.reduce((prev, curr) => {
                if (!prev.includes(curr.csa.uid)) {
                    return prev.concat([curr.csa.uid]);
                }
                return prev;
            }, []));
            setWorkAreas(response.workAreas.sort(sortWorkAreas));
            setCsas(csas);
            restrictedToCsa ? setSelectedCsas([parseInt(restrictedToCsa)]) :  setSelectedCsas(csas);
        }).withNoAlertOnSuccess().send()
    }

    function handleWorkAreaSubmission(workArea, isUpdating){
        let newArray = Array.from(workAreas);
        if (isUpdating) {
            newArray = newArray.filter(wa => wa.uid != workArea.uid);
        }
        newArray.push(workArea);
        newArray.sort(sortWorkAreas);
        setWorkAreas(newArray);
        setShowModal(false)
    }

    function handleMultipleWorkAreaSubmissions(workAreaArray){
        let newArray = workAreas.concat(workAreaArray);
        newArray.sort(sortWorkAreas);
        setWorkAreas(newArray);
    }

    function handleSelectCsa(uid){
        let newArr = Array.from(selectedCsas);
        if(newArr.find((u)=>u == uid)){
            newArr = newArr.filter((u)=> u != uid);
        } else {
            newArr.push(uid);
        }
        setSelectedCsas(newArr);
    }

    function sortWorkAreas(a, b){
        var textA = a.workAreaName.toUpperCase();
        var textB = b.workAreaName.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    }

    const csaDropdownMenu = (
        <Dropdown autoClose='outside'>
            <Dropdown.Toggle variant="outline-primary">
                {selectedCsas.length == 0 ? 'Filter CSA(s)' : selectedCsas.length > 1 ? `Filter CSA(s): ${workAreas.find(wa => wa.csa.uid === selectedCsas[0])?.csa?.csaName} +${selectedCsas.length - 1}` : `Filter CSA(s): ${workAreas.find(wa => wa.csa.uid === selectedCsas[0])?.csa?.csaName}`}
            </Dropdown.Toggle>
            <Dropdown.Menu>
            {csas.map((csa) => {
                const name = workAreas.find(wa => wa.csa.uid === csa)?.csa?.csaName;
                return <DropdownItemWithCheck key={csa} label={name} active={selectedCsas.includes(csa)} onClick={()=>handleSelectCsa(csa)} />
                })}
            </Dropdown.Menu>
        </Dropdown>
    )

    const workAreaRows = workAreas.filter(wa => (showInactiveItems || wa.isActive === 1) && selectedCsas.includes(wa.csa.uid)).map((wa) => {
        return (
            <tr key={wa.uid} style={wa.isActive === 1 ? {} : {opacity: 0.5}} className='cursor-pointer' onClick={() => {setSelectedItem(wa); setShowModal(true);}}>
                <td>{wa.workAreaName + (wa.isActive === 1 ? '' : ' (inactive)')}</td>
                <td>{wa.csa.csaName}</td>
            </tr>
        )
    });

    const content = (
        <div>
            <div style={{display: 'flex', marginBottom: 12, justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap-reverse', gap: 8}}>
                <div style={{display: 'flex', gap: 6}}>
                    { !restrictedToCsa && 
                        <div>
                            {csaDropdownMenu}
                        </div>
                    }
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: 'var(--bs-primary)', border: '1px solid var(--bs-primary', borderRadius: 6, padding: '0 12px'}}>
                        <Form.Check type='switch' label='Show inactive work areas' value={showInactiveItems}  onChange={() => {setShowInactiveItems(!showInactiveItems)}}/>
                    </div>
                </div>
                <div style={{display: 'flex', gap: 6}}>
                    <Button variant={'outline-primary'} onClick={() => {setSelectedItem(undefined); setShowModal(true);}}>
                        Add Work Area
                    </Button>
                    <Button variant={'outline-primary'} onClick={() => {setShowCreateMultipleModal(true);}}>
                        Add Multiple Work Areas
                    </Button>
                </div>
            </div>
            <QuickTable headers={['Name', 'CSA']} rows={workAreaRows}/>
            <Modal show={showModal} onHide={() => {setShowModal(false)}} centered size='lg'>
                <MyCompanyWorkArea restrictedToCsa={restrictedToCsa} data={selectedItem} handleWorkAreaSubmission={handleWorkAreaSubmission}/>
            </Modal>

            <Modal show={showCreateMultipleModal} onHide={()=>{setShowCreateMultipleModal(false)}} centered size='lg'>
                <CreateCompanyWorkAreas restrictedToCsa={restrictedToCsa} csas={[]} handleMultipleWorkAreaSubmissions={(workAreaArray)=>{handleMultipleWorkAreaSubmissions(workAreaArray);setShowCreateMultipleModal(false)}}/>
            </Modal>
        </div>
    )

    return (
        <LoadingWrapper isLoading={isLoading}>
            {content}
        </LoadingWrapper>
    )
}