/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ReportCard({title, description, icon, onClick}) {
    return (
        <Card className="cursor-pointer" onClick={onClick} style={{border: '1px solid var(--bs-primary)'}}>
            <Card.Body>
                <div style={{display: 'flex', alignItems: 'center', gap: 12}}>
                    <FontAwesomeIcon icon={icon} style={{color: 'var(--bs-primary)'}}/>
                    <Card.Title style={{marginBottom: 0}}>{title}</Card.Title>
                    <span style={{opacity: 0.5}}>{description}</span>
                    <div style={{flex: 1}}/>
                    
                </div>
            </Card.Body>
        </Card>
    )
}