/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import AddressControl from "../../components/AddressControl";
import CustomControl from "../../components/CustomControl";
import CustomButton from '../../components/CustomButton';
import DropdownControl from "../../components/DropdownControl";
import { packetIdentifierDictionary, validateDecimalFixed } from "../../tools";
import { validateExistence, AddressValidator, KeyedValidationGroup } from "../../validation";
import { ApiRequest } from "../../ApiManager.tsx";

export default function MyCompanyCSA({data, handleCSASubmission}) {
    const [csaName, setCsaName] = useState(data ? data.csaName : '');
    const [address, setAddress] = useState(data ? data.address : {});
    const [clockInRadius, setClockInRadius] = useState(data ? data.clockInRadius.toString() : '1');
    const [isActive, setIsActive] = useState(data ? data.isActive : 1);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [packetIdentifier, setPacketIdentifier] = useState(data ? data.packetIdentifier : undefined);
    const [autoImportTemplateIdentifier, setAutoImportTemplateIdentifier] = useState(data ? data.autoImportTemplateIdentifier : undefined);
    const [templates, setTemplates] = useState([]);
    const validationGroup = new KeyedValidationGroup();
    const addressValidator = new AddressValidator(address)

    validationGroup.pushValidator('address', addressValidator);

    useEffect(() => {
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('scheduleMatch', 'getTemplates', setIsLoading, (response) => setTemplates(response.templates)).withNoAlertOnSuccess().send();
    }

    function handleSubmit(){
        const csaToSave = {
            uid: data ? data.uid : undefined,
            csaName: csaName,
            address: address,
            clockInRadius: validateDecimalFixed(clockInRadius, 2),
            isActive: isActive,
            packetIdentifier: packetIdentifier,
            autoImportTemplateIdentifier: autoImportTemplateIdentifier
        }

        if(data){
            new ApiRequest('myCompany', 'updateCsa', setIsLoading, () => handleCSASubmission(csaToSave, true)).withData({data: csaToSave}).send()
        } else{
            new ApiRequest('myCompany', 'createCsa', setIsLoading, (response) => {csaToSave.uid = response.uid; handleCSASubmission(csaToSave, false)}).withData({data: csaToSave}).send()
        }
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>{data ? 'Editing CSA' : 'Creating CSA'}</Modal.Title>  
                {data ? 
                    <Button variant={'outline-primary'} style={{marginLeft: 24}} onClick={() => {setShowModal(true)}}>{isActive === 1 ? 'Set CSA as Inactive' : 'Set CSA as active'}</Button>              
                : ''}
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <CustomControl validator={validationGroup.createValidator('csaName', csaName, validateExistence)} md={6} type='text' title='Name' maxLength={50} originalValue={data ? data.csaName : undefined} value={csaName} onChange={(event) => {setCsaName(event.target.value)}}/>
                        <CustomControl validator={validationGroup.createValidator('clockInRadius', clockInRadius, validateExistence)} md={6} type='number' min={0.5} step={0.1} maxDecimal={1000000} title='Clock-In Radius (in miles)' originalValue={data ? data.clockInRadius.toString() : undefined} value={clockInRadius} onChange={(event) => {setClockInRadius(event.target.value)}}/>
                        <AddressControl validator={addressValidator} md={6} title='Terminal Address' originalValue={data ? data.address : undefined} address={address} setAddress={setAddress}/>
                        <Col lg={6}>
                            <DropdownControl validator={validationGroup.createValidator('packetIdentifier', packetIdentifier, validateExistence)} selection={packetIdentifier} setSelection={setPacketIdentifier} itemNames={Object.values(packetIdentifierDictionary)} itemValues={Object.keys(packetIdentifierDictionary)} title='Onboarding Packet'/>
                        </Col>
                        <Col lg={6}>
                            <DropdownControl  selection={autoImportTemplateIdentifier} setSelection={setAutoImportTemplateIdentifier} itemValues={templates.map(t => t.uid)} itemNames={templates.map(t => t.templateName)} title='Auto-Import Template'/>
                            <p style={{margin: 0}}>Every week, the selected template will be automatically imported for this CSA.</p>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton  disabled={!validationGroup.isValid()} label={data ? 'Save Changes' : 'Create CSA'} isLoading={isLoading} onClick={handleSubmit}/>
            </Modal.Footer>
            <Modal show={showModal} onHide={() => {setShowModal(false)}} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{isActive === 1 ? 'Confirm Deactivation' : 'Confirm Reactivation'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { isActive === 1 ? 'Deactivating a CSA will prevent it from appearing in Scheduling. Users, Vehicles, and work areas will no longer be able to be assigned to the CSA.' 
                        : 'Reactivating a CSA will make it appear in Scheduling and allow users, vehicles, and work areas to be assigned to it.'
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={'outline-primary'} onClick={() => {setShowModal(false); setTimeout(() => {setIsActive(isActive === 0 ? 1 : 0)}, 200)}}>Confirm</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}