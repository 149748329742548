/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { InputGroup } from 'react-bootstrap';
import CustomButton from './CustomButton';
import { toast } from 'react-toastify';
import { ApiRequest } from '../ApiManager.tsx';

export default function FileUploadControl({companyUserUid, handleUploadDocuments, multiple}) { 
    const [isLoading, setIsLoading] = useState(false);
    const [documentNames, setDocumentNames] = useState([]);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [uploadResponse, setUploadResponse] = useState(undefined);
    const ref = useRef();

    function handleOnChange(){
        const files = ref.current.files;
        const documentNames = [];
        const documentTypes = [];

        for(let i = 0; i < files.length; i++){
            const indexOfDot = files[i].name.lastIndexOf('.');
            documentNames[i] = files[i].name.slice(0, indexOfDot)
            documentTypes[i] = files[i].name.slice(indexOfDot + 1)
        }
        
        setDocumentNames(documentNames);
        setDocumentTypes(documentTypes);
    }
    
    async function handleUpload(){
        const filesToSend = [];
        const files = ref.current.files;
        if (files.length) {
            setIsLoading(true);
            for(let i = 0; i < files.length; i++){
                const file = await new Promise((resolve, reject)=>{
                    const reader = new FileReader();
                    reader.onerror = () => {
                        setIsLoading(false);
                        reject(null);

                    } 
                    reader.onload = () => {   
                        const fileObj = {
                            base64: reader.result,
                            documentName: documentNames[i],
                            documentType: documentTypes[i],
                            companyUserUid: companyUserUid
                        };
                        resolve(fileObj)  
                    }
                    reader.readAsDataURL(files[i]);
                })

                if(!file){
                    toast.error('No file selected');
                    return;
                }else{
                    filesToSend.push(file)
                }
            }

            new ApiRequest('hr', 'createCustomDocuments', setIsLoading, (response) => {
                setUploadResponse('success');
                    const uids = response.uids;
                    filesToSend.forEach((file, index)=>{
                        file.uid = uids[index];
                    })
                    handleUploadDocuments(filesToSend);
            }).withData({files: filesToSend}).send();
        }
    }

    return (
        <>
            <input style={{width: 0, height: 0, opacity: 0}} multiple='multiple' ref={ref} type='file' onChange={handleOnChange}/>
            <InputGroup>
                { uploadResponse ? '' :
                    <CustomButton 
                        onClick={() => {ref.current.click()}} 
                        label={ref.current && ref.current.files[0] ? `Change File${multiple ? '(s)' : ''}` : `Choose File${multiple ? '(s)' : ''}`} 
                        isLoading={isLoading}
                    />
                }
                <FloatingLabel label={`File${multiple ? '(s)' : ''} to Upload`}>
                    <Form.Control type='text' placeholder=' ' disabled={true} max={100} value={documentNames.join(', ')}/>
                </FloatingLabel>
                { ref.current && ref.current.files[0] ?
                    <>
                        { uploadResponse ?
                            uploadResponse === 'success' ?
                            <InputGroup.Text style={{color: 'green'}}>Successfully Uploaded</InputGroup.Text>
                            :
                            <InputGroup.Text style={{color: 'red'}}>Failed to upload</InputGroup.Text>
                            :
                            <CustomButton label='Upload' isLoading={isLoading} onClick={handleUpload}/>
                        }
                    </>
                : ''}
            </InputGroup>
        </>
    )

}