/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import CustomControl from "../../../components/CustomControl";
import { ApiRequest } from "../../../ApiManager.tsx";
import CustomButton from "../../../components/CustomButton.js";

export default function SASReport() {
    const [isLoading, setIsLoading] = useState(false);
    const [reportDate, setReportDate] = useState('');

    function handleGetSAS(date){
        new ApiRequest('reports', 'sas', setIsLoading, (response) => {
            const data = response.data.data;
            const replaceCommas = data.replaceAll(',', ' ');
            const replaceAster = replaceCommas.replaceAll('*', ',');
            const blob = new Blob([replaceAster], { type: 'text/csv;charset=utf-8,' });
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = 'sas.csv';
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        }).withData({date: date}).withNoAlertOnSuccess().send()
    }
    
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Download SAS Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CustomControl title='Select A Date' type='date' value={reportDate} onChange={(event)=>{  
                        setReportDate(event.target.value)                  
                }}/>
                <div style={{marginTop:10}}>
                    <CustomButton label='Submit Date' variant={'outline-primary'} isLoading={isLoading} style={{marginBottom:10}} onClick={()=>handleGetSAS(reportDate)} disabled={!reportDate}/>
                </div>
            </Modal.Body>
        </>
    )
}