/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useMemo, useState } from "react"
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import { ApiRequest } from "../../../ApiManager.tsx";
import { getFakeUID, usdFormatter, validateDecimal } from "../../../tools.js";
import QuickTable from "../../../components/QuickTable.js";
import LoadingWrapper from "../../../components/LoadingWrapper.js";
import ChargeStatementMatchFedex from "../../Bookkeeping/ChargeStatement/ChargeStatementMatchFedex.js";
import ChargeStatementPDF from "./ChargeStatementPDF.js";

export default function ChargeStatementReport({selectedChargeStatement, callback}) {
    const [isLoading, setIsLoading] = useState(true);
    const [chargeStatement, setChargeStatement] = useState(null);
    const [unmatchedFedexIds, setUnmatchedFedexIds] = useState([]);
    const [csaIsUnmatched, setCsaIsUnmatched] = useState(selectedChargeStatement?.csaName ? false : true);
    const [modalSwitch, setModalSwitch] = useState('')

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        const unmatchedFedexIds = chargeStatement?.chargeStatementDrivers?.reduce((acc, el) => {
            if(el.firstName || acc.includes(el.fedexId)){
                return acc;
            }
            acc.push(el.fedexId);
            return acc;
        }, [])
        setUnmatchedFedexIds(unmatchedFedexIds);
    }, [modalSwitch])

    const weeklySummary = useMemo(() => {
        return {
            puPackageAmount: getTypeTotal('puPackageAmount'),
            puStopAmount: getTypeTotal('puStopAmount'),
            dlPackageAmount: getTypeTotal('dlPackageAmount'),
            desigAmount: getTypeTotal('desigAmount'),
            ecPackageAmount: getTypeTotal('ecPackageAmount'),
            ecStopAmount: getTypeTotal('ecStopAmount'),
            fuelAmount: getTypeTotal('fuelAmount'),
            surgeAmount: getTypeTotal('surgeAmount'),
            otherAmount: chargeStatement?.['otherAmount'],
            netAmount: chargeStatement?.['netAmount'],
        }
    }, [chargeStatement])

    const miscSummary = useMemo(() => {
        let count = 0;
        const desigPercentage = chargeStatement?.chargeStatementDays?.reduce((acc, el)=> {count++ ;return acc + validateDecimal(el['desigPercentage'])}, 0)/count + '%';

        return {
            desigPercentage: desigPercentage,
        }
    }, [chargeStatement])

    function loadData(successCallback){
        new ApiRequest('reports', 'getChargeStatement', setIsLoading, (response) => {
            setChargeStatement(response.chargeStatement);
            const unmatchedFedexIds = response.chargeStatement?.chargeStatementDrivers?.reduce((acc, el) => {
                if(el.firstName || acc.includes(el.fedexId)){
                    return acc;
                }
                acc.push(el.fedexId);
                return acc;
            }, [])
            setUnmatchedFedexIds(unmatchedFedexIds);
            if(successCallback){
                successCallback()
            }
        }).withUid(selectedChargeStatement.uid).withNoAlertOnSuccess().send();
    }

    function matchFedexIdsCallback(_matchedUsers, _matchedCsa, _users, _csas){
        loadData(callback);
        hideModal();
    }

    function hideModal(){
        setModalSwitch('')
    }

    function getTypeTotal(type){
        return chargeStatement?.chargeStatementDays?.reduce((acc, el)=> {return acc + validateDecimal(el[type])}, 0);
    }

    function getDriverTypeTotal(type, fedexId){
        return chargeStatement?.chargeStatementDrivers?.reduce((acc, el) => {if(el.fedexId !== fedexId){return acc}{return acc + validateDecimal(el[type])}}, 0)
    }
    
    function findDriverAmountDayIndex(dayIndex, fedexId, type){
        return chargeStatement?.chargeStatementDrivers?.find((csd) => csd.dayIndex == dayIndex && fedexId === csd.fedexId)?.[type] ?? 0;
    }

    const weeklySummaryRows = [
        {label: 'Pickup Packages', key: 'puPackageAmount'},
        {label: 'Pickup Stops', key: 'puStopAmount'},
        {label: 'Delivery Packages', key: 'dlPackageAmount'},
        {label: 'Large Packages', key: 'desigAmount'},
        {label: 'E-commerce Packages', key: 'ecPackageAmount'},
        {label: 'E-commerce Deliveries', key: 'ecStopAmount'},
        {label: 'Fuel', key: 'fuelAmount'},
        {label: 'Surge', key: 'surgeAmount'},
        {label: 'Other P&D Charges', key: 'otherAmount'},
        {label: 'Total', key: 'netAmount'},
    ].map((row) => {
        return (
            <tr key={row.key}>
                <td>{row.label}</td>
                <td>{usdFormatter.format(weeklySummary?.[row.key])}</td>
            </tr>
        )
    })

    const miscSummaryRows = [
        {label: 'Average Large Package %', key: 'desigPercentage'},
    ].map((row) => {
        return (
            <tr key={row.key}>
                <td>{row.label}</td>
                <td>{miscSummary?.[row.key]}</td>
            </tr>
        )
    })

    const driverTables = chargeStatement?.chargeStatementDrivers?.reduce((acc, el) => {
        if(acc.some(obj => obj.fedexId == el.fedexId)){
            return acc;
        }
        acc.push(el);
        return acc
    }, []).map((csd) => {
        const driverDayRows = [0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
            const puPackages = findDriverAmountDayIndex(dayIndex, csd.fedexId, 'puPackages');
            const puStops = findDriverAmountDayIndex(dayIndex, csd.fedexId, 'puStops');
            const dlPackages = findDriverAmountDayIndex(dayIndex, csd.fedexId, 'dlPackages');
            const dlStops = findDriverAmountDayIndex(dayIndex, csd.fedexId, 'dlStops');
            const ecPackages = findDriverAmountDayIndex(dayIndex, csd.fedexId, 'ecPackages')
            const ecStops = findDriverAmountDayIndex(dayIndex, csd.fedexId, 'ecStops');
            return (
                <tr key={dayIndex}>
                    <td>{moment(chargeStatement.startDate).add(dayIndex, 'days').format('DD-MM-YYYY')}</td>
                    <td>{puPackages}</td>
                    <td>{puStops}</td>
                    <td>{dlPackages}</td>
                    <td>{dlStops}</td>
                    <td>{ecPackages}</td>
                    <td>{ecStops}</td>
                    <td>{puPackages + dlPackages + ecPackages}</td>
                    <td>{puStops + dlStops + ecStops}</td>
                </tr>
            )
        })

        const puPackagesTotal = getDriverTypeTotal('puPackages', csd.fedexId)
        const puStopsTotal = getDriverTypeTotal('puStops', csd.fedexId)
        const dlPackagesTotal = getDriverTypeTotal('dlPackages', csd.fedexId)
        const dlStopsTotal = getDriverTypeTotal('dlStops', csd.fedexId)
        const ecPackagesTotal = getDriverTypeTotal('ecPackages', csd.fedexId)
        const ecStopsTotal = getDriverTypeTotal('ecStops', csd.fedexId)

        const totalRows = (
            <tr key={7}>
                <td>Weekly Total</td>
                <td>{puPackagesTotal}</td>
                <td>{puStopsTotal}</td>
                <td>{dlPackagesTotal}</td>
                <td>{dlStopsTotal}</td>
                <td>{ecPackagesTotal}</td>
                <td>{ecStopsTotal}</td>
                <td>{puPackagesTotal + dlPackagesTotal + ecPackagesTotal}</td>
                <td>{puStopsTotal + dlStopsTotal + ecStopsTotal}</td>
            </tr>
        )

        return (
            <div key={getFakeUID()} style={{marginBottom: 36}}>
                <h5 style={{paddingLeft: 8, marginBottom: 12}}>{csd.firstName ? `${csd.firstName} ${csd.middleName} ${csd.lastName}` : csd.fedexId + ' (Not Matched)'}</h5>
                <QuickTable headers={['DATE', 'PU PKGS', 'PU STOPS', 'DL PKGS', 'DL STOPS', 'ECOMM DL PKGS', 'ECOMM DL STOPS', 'TOTAL PKGS', 'TOTAL STOPS']} rows={[...driverDayRows, totalRows]}/>
            </div>
        )
    })

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Viewing Charge Statement</Modal.Title>
            </Modal.Header>
            {(csaIsUnmatched || unmatchedFedexIds?.length > 0) && 
                <Modal.Header>
                    <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 16}}>
                        <span>
                            This charge statement has incomplete data. You can match any unmatched FedEx IDs to have a complete report.
                        </span>
                        <Button onClick={() => setModalSwitch('match')} variant="outline-primary">Match FedEx IDs</Button>
                    </div>
                </Modal.Header>
            }
            <Modal.Body>
                <LoadingWrapper isLoading={isLoading}>
                    <h4 style={{textAlign: 'center', paddingBottom: 4, textDecoration: 'underline'}}>Charge Statement Report</h4>
                    <h5 style={{opacity: 0.5, textAlign: 'center'}}>{`${selectedChargeStatement?.csaName ?? selectedChargeStatement.csaFedexId} | ${moment(chargeStatement?.startDate).format('MMM D, YYYY')} - ${moment(chargeStatement?.startDate).add(6, 'days').format('MMM D, YYYY')}`}</h5>
                    <h4 style={{textAlign: 'center', margin: 12, marginTop: 24}}>Weekly Summary</h4>
                    <QuickTable rows={weeklySummaryRows}/>
                    <h4 style={{textAlign: 'center', margin: 12, marginTop: 24}}>Misc.</h4>
                    <QuickTable rows={miscSummaryRows}/>
                    <h4 style={{textAlign: 'center', margin: 18, marginTop: 24}}>Driver Stops & Packages</h4>
                    {driverTables}
                </LoadingWrapper>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={() => setModalSwitch('pdf')}>Preview PDF</Button>
            </Modal.Footer>
            <Modal centered size="xl" fullscreen='lg-down' show={modalSwitch === 'match'} onHide={hideModal}>
                <ChargeStatementMatchFedex chargeStatement={chargeStatement} unmatchedFedexIds={unmatchedFedexIds} setUnmatchedFedexIds={setUnmatchedFedexIds} csaIsUnmatched={csaIsUnmatched} matchFedexIdsCallback={matchFedexIdsCallback}/>
            </Modal>
            <Modal fullscreen show={modalSwitch === 'pdf'} onHide={hideModal}>
                <ChargeStatementPDF chargeStatement={chargeStatement} selectedChargeStatement={selectedChargeStatement} weeklySummary={weeklySummary} miscSummary={miscSummary}/>
            </Modal>
        </>
    )
}