/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Modal, Row} from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { GoogleMap, InfoWindow, LoadScript} from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import PageHeader from '../../components/PageHeader/PageHeader';
import moment from 'moment';
import { faChevronRight} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import QuickTable from '../../components/QuickTable';
import HRUserScheduleModal from '../HumanResources/HRUserSchedule/HRUserScheduleModal';
import PageSpinner from '../../components/PageSpinner';
import Cookies from 'universal-cookie';
import { faPerson } from '@fortawesome/free-solid-svg-icons';
import { scheduleTypeDictionary } from '../../tools';
import ApiManager, { ApiRequest } from '../../ApiManager.tsx';
import DateRangeSelector from '../../components/DateRangeSelector.js';
import CustomDatePicker from '../../components/CustomDatePicker/CustomDatePicker.js';

export default function Dashboard({}){
    const [users, setUsers] = useState([]);
    const [requestOffItems, setRequestOffItems] = useState([]);
    const [selectedRequestOff, setSelectedRequestOff] = useState(undefined);
    const [showScheduleModal, setShowScheduleModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [numberOfConflicts, setNumberOfConflicts] = useState(0);
    const [pendingRequests, setPendingRequests] = useState([]);
    const [timesheetEntries, setTimesheetEntries] = useState([]);
    const [upcomingTimeOff, setUpcomingTimeOff] = useState([]);
    const [isSettingEndDate, setIsSettingEndDate] = useState(false);
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().add(1, 'month').format('YYYY-MM-DD'))
    const cookieMonster = new Cookies();
    const isMounted = useRef(false);
    const isAo = cookieMonster.get('userData').title === 'AO';
    const isBc = cookieMonster.get('userData').title === 'BC';

    useEffect(()=>{
        if(isAo || isBc){
            loadData();
        }
    }, [])

    useEffect(() => {
        if(startDate && endDate && isMounted.current === true){
            handleSetUpcomingTimeOffEndDate();
        }
        isMounted.current = true;
    }, [startDate, endDate])

    function loadData(){
        new ApiRequest('dashboard', 'getDashboard', setIsLoading, (response) => {
            setUsers(response.users);
            setNumberOfConflicts(response.numberOfConflicts);
            setPendingRequests(response.pendingRequests);
            setTimesheetEntries(response.timesheetEntries.sort(sortTimesheetEntries));
            setUpcomingTimeOff(response.upcomingTimeOff);
        }).withNoAlertOnSuccess().send()
    }

    function handleSetUpcomingTimeOffEndDate(){
        new ApiRequest('dashboard', 'getUpcomingTimeOff', setIsSettingEndDate, (response) => {setUpcomingTimeOff(response.upcomingTimeOff)}).withData({startDate: startDate, endDate: endDate}).send();
    }

    function handleAddPto(){
        const newArray = Array.from(requestOffItems).filter((item)=>item.uid != selectedRequestOff.uid );
        setRequestOffItems(newArray);
        setShowScheduleModal(false);
    }

    function handleUpdateSelectedItem(key, value){
        const newObj = {...selectedRequestOff};
        newObj[key] = value;
        setSelectedRequestOff(newObj)
    }

    function handleAnswerRequest(uid){
        const newArray = Array.from(requestOffItems).filter((item)=>item.uid != uid);
        setRequestOffItems(newArray);
        setShowScheduleModal(false);
    }

    function sortTimesheetEntries(a, b) {
        if (a.date < b.date) {
            return -1;
        } else if (a.date > b.date) {
            return 1;
        }
        return 0;
    }

    const pendingRequestElements = pendingRequests.filter(item => {
        const employee = users.find(user => user.companyUserUid == item.companyUserIdentifier);
        return employee.pay.payType != 'termination';
    }).map((item) => {
        const employee = users.find(user => user.companyUserUid == item.companyUserIdentifier);
        return (
            <tr style={{ cursor: 'pointer' }} key={item.uid} onClick={() => {setSelectedRequestOff(item); setShowScheduleModal(true)}}>
                <td>{`${employee.lastName}, ${employee.firstName} ${employee?.middleName.substring(0, 1)}`}</td>
                <td>{moment(item.date).format('MMM D, YYYY')}</td>
                <td>{moment(item.endDate).format('MMM D, YYYY')}</td>
                <td>{moment(item.submissionDate).format('MMM D, YYYY')}</td>
            </tr>
        )
    });

    const upcomingTimeOffElements = upcomingTimeOff.filter(item => {
        const employee = users.find(user => user.companyUserUid == item.companyUserIdentifier);
        return employee.pay.payType != 'termination';
    }).map((item) => {
        const employee = users.find(user => user.companyUserUid == item.companyUserIdentifier);
        return (
            <tr style={{ cursor: 'pointer' }} key={item.uid} onClick={() => {setSelectedRequestOff(item); setShowScheduleModal(true)}}>
                <td>{`${employee.lastName}, ${employee.firstName} ${employee?.middleName.substring(0, 1)}`}</td>
                <td>{scheduleTypeDictionary[item.scheduleType].label}</td>
                <td>{moment(item.date).format('MMM D, YYYY')}</td>
                <td>{moment(item.submissionDate).format('MMM D, YYYY')}</td>
            </tr>
        )
    });
    
    const pageHeader = (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
            <h1 style={{color: 'white'}}>Welcome to the TTA Web Application</h1>
        </div>
    );

    const featureOverviewItems = {
        'Company Selector': 'Page content will only include information associated with the currently selected company in the drop-down menu.',
        'Profile': 'View personal information and pay information. Change your password.',
        'Time Clock': 'View personal timesheet records. Clock in and out.',
        'My Schedule': 'View personal calendar of scheduled routes, time off, etc.',
        'Days Off': 'View personal history of time off requests and their statuses (pending, accepted, rejected). Request time off.',
        'My Company': 'Manage company CSAs, work areas, and vehicles. Modify company settings.',
        'Scheduling': 'Comprehensive tool for scheduling routes.',
        'Payroll': 'Effortless tool for approving payroll periods. Also contains a complete history of the company\'s payroll.',
        'Human Resources': 'Collection of tools allowing complete control over company employees as well as detailed data and analytics.',
    };

    const featureOverviewElements = Object.keys(featureOverviewItems).map((key) => {
        return (
            <div key={key} style={{textAlign: 'center'}}>
                <p style={{marginBottom: 0, fontSize: 20, fontWeight: 'bold'}}>{key}</p>
                <p>{featureOverviewItems[key]}</p>
            </div>
        )
    });

    const timeDataWithCoords = timesheetEntries.filter((time) => time.inLat && time.inLong);
    const googleMapClockIns = timeDataWithCoords.map((time)=>{
        const employee = users.find((user) => {return user.companyUserUid === time.companyUserIdentifier});
        return(  
            <Marker key={time.uid} icon={{path:faPerson.icon[4],  fillColor:'#1DA1F2', fillOpacity:1, strokeWeight:1, scale:0.055}}  position={{lat: parseFloat(time.inLat), lng: parseFloat(time.inLong)}}>
                <InfoWindow  position={{lat: parseFloat(time.inLat), lng: parseFloat(time.inLong)+0.0000055}}>
                    <div style={{width:'75px', fontSize:11}}>{(employee.lastName ? employee.lastName : '') + ', ' + (employee.firstName ? employee.firstName : '') + (employee.middleName ? (' ' + employee.middleName.substring(0,1)) : '')}</div>
                </InfoWindow>
            </Marker>
        )
    });

    const latSum = timeDataWithCoords.reduce((sum, time) => sum + parseFloat(time.inLat), 0);
    const lngSum = timeDataWithCoords.reduce((sum, time) => sum + parseFloat(time.inLong), 0);
    const avgLat = latSum / timeDataWithCoords.length;
    const avgLng = lngSum / timeDataWithCoords.length;

    const clockedIn = users.filter((u) => {
        const filteredTmesheetEntries = timesheetEntries.filter(t => t.companyUserIdentifier == u.companyUserUid);
        return filteredTmesheetEntries.length > 0 && !filteredTmesheetEntries[filteredTmesheetEntries.length - 1].outTime;
    })
    const scheduled = users.filter(u => u.isScheduled);

    let clockedInColor = 'green';
    if (clockedIn.length > scheduled.length) {
        clockedInColor = 'orange';
    } else if (clockedIn.length < scheduled.length) {
        clockedInColor = 'red';
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100%', overflowY:'scroll'}}>
            {!isAo && !isBc ? 
                <>
                    <PageHeader content={pageHeader}/>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 12}}>
                        <Card.Title style={{fontSize: 30, marginBottom: 12, fontWeight: 'bold'}}>Overview of Features</Card.Title>
                        {featureOverviewElements}
                    </div> 
                </>
            : isLoading ? <PageSpinner/> :
                <>
                    <div style={{padding:20}}>
                        <Card.Title style={{fontSize: 40, marginBottom: 12, fontWeight: 'bold'}}>Dashboard</Card.Title>
                        <ListGroup>
                            <ListGroup.Item style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <p style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 0 }}>Clocked-In Vs. Scheduled: <span style={{ color: clockedInColor }}>{clockedIn.length}</span><span style={{ color: clockedInColor }}>{' '+ '/' + ' '}</span><span style={{color:clockedInColor}}>{scheduled.length}</span></p>
                                <div style={{paddingRight: 8, paddingLeft: 8}}>
                                    <NavLink style={{color:'rgb(29, 74, 249)'}} className={({isActive}) => isActive ? 'nav-bar-link nav-bar-link-active' : 'nav-bar-link'} to={'/human-resources'}>
                                        <p style={{fontSize: 18, margin: 0, }}> {'View in Human Resources'}</p>
                                        <FontAwesomeIcon icon={faChevronRight} style={{marginLeft: 8}}/>
                                    </NavLink>
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <p style={{fontSize:18, fontWeight:'bold', marginBottom: 0 }}>Schedule Conflicts: <span style={{ color: numberOfConflicts === 0 ? 'green' : '#EF3C39' }}>{numberOfConflicts}</span></p>
                                <div style={{paddingRight: 8, paddingLeft: 8}}>
                                    <NavLink style={{color:'rgb(29, 74, 249)'}} className={({isActive}) => isActive ? 'nav-bar-link nav-bar-link-active' : 'nav-bar-link'} to={'/schedule-match'}>
                                        <p style={{fontSize: 18, margin: 0, }}> {'View Scheduling'}</p>
                                        <FontAwesomeIcon icon={faChevronRight} style={{marginLeft: 8}}/>
                                    </NavLink>
                                </div>
                            </ListGroup.Item>
                        </ListGroup>
                        <div style={{marginTop:20}}>
                            <div>
                                <span style={{padding: '0 5px', fontSize:'16px', color: 'rgba(128, 128, 128, 1)', marginLeft:10}}>PENDING TIME-OFF REQUESTS</span>
                            </div>
                            <Card>
                                {!isLoading ? 
                                <>
                                    {pendingRequestElements.length > 0 ?
                                    <QuickTable hover striped responsive headers={['Employee', 'Start Date', 'End Date', 'Submission Date']} rows={pendingRequestElements}/>
                                    :
                                    <div style={{marginLeft:15, marginTop:10}}>
                                        <p style={{fontSize:18, fontWeight:'bold'}}>None</p>
                                    </div>
                                    }
                                </>
                                :
                                <div style={{padding:10}}>
                                    <PageSpinner/>
                                </div>
                                }
                            </Card>
                        </div>
                        <div style={{marginTop:20}}>
                            <div>
                                <span style={{padding: '0 5px', fontSize:'16px', color: 'rgba(128, 128, 128, 1)', marginLeft:10}}>UPCOMING TIME-OFF</span>
                            </div>
                            <Card>
                                <div style={{style: 'display', justifyContent: 'center', alignItems: 'center', alignItems: 'center', padding: 8}}>
                                    <CustomDatePicker title='Date Range' startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} minDate={moment().format('YYYY-MM-DD')}/>
                                </div>
                                {upcomingTimeOffElements.length > 0 ?
                                    <QuickTable hover striped responsive headers={['Employee', 'Type', 'Date', 'Submission Date']} rows={upcomingTimeOffElements}/>
                                    :
                                    <div style={{marginLeft:15, marginTop:10}}>
                                        <p style={{fontSize:18, fontWeight:'bold'}}>None</p>
                                    </div>
                                }
                            </Card>
                        </div>
                        {!ApiManager.DEV_MODE &&
                            <div style={{marginTop:20}}>
                                <div>
                                    <span style={{padding: '0 5px', fontSize:'16px', color: 'rgba(128, 128, 128, 1)', marginLeft:10}}>TODAY'S EMPLOYEE CLOCK-IN LOCATIONS</span>
                                </div>
                                <Card>
                                    {googleMapClockIns.length > 0 ?
                                        <div style={{padding:5}}>
                                            <LoadScript googleMapsApiKey="AIzaSyBO6pLOjJq8W63VtQ3TPkpUW1mF7gdfJgo">
                                                <GoogleMap mapContainerStyle={{width: '100%', height:'600px', aspectRatio: 1}} center={{lat: avgLat, lng: avgLng}} zoom={18}>
                                                    {googleMapClockIns}
                                                </GoogleMap>
                                            </LoadScript>
                                        </div>
                                    :
                                        <div style={{marginLeft:15, marginTop:10}}>
                                            <p style={{fontSize:18, fontWeight:'bold'}}>No clock-in locations to display</p>
                                        </div>
                                    }
                                </Card>
                            </div>
                        }
                    </div>
                    <Modal show={showScheduleModal} onHide={() => setShowScheduleModal(false)}>
                        <HRUserScheduleModal 
                            selectedItem={selectedRequestOff} 
                            handleAddPTO={handleAddPto} handleUpdateSelectedItem={handleUpdateSelectedItem} 
                            handleAnswerRequest={handleAnswerRequest} 
                            timeOff={upcomingTimeOff.filter(si => si.companyUserIdentifier == selectedRequestOff?.companyUserIdentifier).concat(pendingRequests.filter(si => si.companyUserIdentifier == selectedRequestOff?.companyUserIdentifier))}
                        />
                    </Modal>
                </>
            }
        </div>
    )
}