/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import Decoder from "../../../decoding";
import { validateDecimal, validateBig, validateInteger, validateUsd } from "../payrollTools";
import Big from "big.js";

export default class Pto {
    uid;
    payRate;
    hours;
    date;
    isEnabled;

    constructor(uid, payRate, hours, date, isEnabled) {
        this.uid = uid;
        this.payRate = payRate;
        this.hours = hours;
        this.date = date;
        this.isEnabled = isEnabled;
    }

    static initDefault() {
        return new Pto(-1, 0.0, 0, moment().format('YYYY-MM-DD'), true);
    }

    static decode(json) {
        const decoder = new Decoder(json);
        const uid = decoder.decode('uid', Decoder.Uid);
        const payRate = decoder.decode('payRate', Decoder.Decimal);
        const hours = decoder.decode('hours', Decoder.Integer);
        const date = decoder.decode('date', Decoder.StringStrict);
        const isEnabled = decoder.decode('isEnabled', Decoder.BooleanFlexible, {defaultValue: true, warn: false});

        if (decoder.checkForErrors()) {
            return new Pto(uid, payRate, hours, date, isEnabled);
        } else {
            return Pto.initDefault();
        }
    }

    static decodeFromScheduleItem(json, payJson) {
        const decoder = new Decoder(json);
        const uid = decoder.decode('uid', Decoder.Uid);
        const payRate = !payJson ? 0.0 : payJson.payType === 'ph' ? validateDecimal(payJson.payRate) : payJson.payType === 'pd' ? validateUsd(payJson.payRate / 8.0) : validateDecimal(payJson.hourlyWage);
        const hours = decoder.decode('number', Decoder.Integer);
        const date = decoder.decode('date', Decoder.StringStrict);
        if (decoder.checkForErrors()) {
            return new Pto(uid, payRate, hours, date, true);
        } else {
            return Pto.initDefault();
        }
    }

    encode() {
        return {
            uid: this.uid,
            payRate: validateDecimal(this.payRate),
            hours: validateDecimal(this.hours),
            date: this.date,
            isEnabled: this.isEnabled
        }
    }

    duplicate() {
        return new Pto(this.uid, this.payRate, this.hours, this.date, this.isEnabled);
    }

    getPtoPay() {
        if (this.isEnabled) {
            return validateBig(validateInteger(this.hours)).times(validateBig(this.payRate));
        } else {
            return new Big('0.0');
        }
    }
}