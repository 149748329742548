/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import React, { useState } from "react"
import QuickTable from "../../components/QuickTable";
import PCATField from "../PCAT/PCATField";
import { validateDecimal, validateInteger } from "../../tools";
import { toFixedMax, validateBig } from "../Payroll/payrollTools";
import { Button, Modal } from "react-bootstrap";

export default function BudgetProductivity({budget, originalBudget, handleSetBudget}) {
    const [showModal, setShowModal] = useState(false);

    const handleSetBudgetProductivity = (index, key, value) => {
        const newBudgetProductivity = Array.from(budget.budgetProductivity);
        newBudgetProductivity[index][key] = value;
        handleSetBudget('budgetProductivity', newBudgetProductivity);
    }

    function handleRedistribute(){
        const newBudgetProductivity = budget.budgetProductivity.map(el=> el.duplicate());
        [0, 1, 2, 3, 4, 5, 6].map(weekDayIndex=>{

            let dailyStops = validateBig(budget.budgetProductivity[weekDayIndex]['stops']);
            let dailyPackages = validateBig(budget.budgetProductivity[weekDayIndex]['packages']);
            const dayDispatches = validateBig(budget.budgetProductivity[weekDayIndex].dispatchesLight).plus(validateBig(budget.budgetProductivity[weekDayIndex].dispatchesStandard)).plus(validateBig(budget.budgetProductivity[weekDayIndex].dispatchesStraight));
                
            if(dayDispatches.eq(0)){
                dailyStops = 0;
                dailyPackages = 0;
            } else{
                for(let i =0; i < 7; i++){
                    if(i === weekDayIndex)
                        continue;
                    
                    if(validateBig(budget.budgetProductivity[i].dispatchesLight).plus(validateBig(budget.budgetProductivity[i].dispatchesStandard)).plus(validateBig(budget.budgetProductivity[i].dispatchesStraight)).eq(0)){
                        dailyStops = dailyStops.plus(validateBig(budget.budgetProductivity[i]['stops']).div(budget.getDaysThatHaveZeroDispatches()));
                        dailyPackages = dailyPackages.plus(validateBig(budget.budgetProductivity[i]['packages']).div(budget.getDaysThatHaveZeroDispatches()));
                    }
                }
            }
            
            newBudgetProductivity[weekDayIndex]['stops'] = dailyStops;
            newBudgetProductivity[weekDayIndex]['packages'] = dailyPackages;
        })
            handleSetBudget('budgetProductivity', newBudgetProductivity);
    }

    const dispatchHeader = (
        <tr key={'dispatchHeader'}>
            <td style={{textAlign: 'center'}} colSpan={10}>Dispatches</td>
        </tr>
    )

    const dispatchRows = [
        {key: 'dispatchesLight', label: 'Light', max: 999},
        {key: 'dispatchesStandard', label: 'Standard', max: 999},
        {key: 'dispatchesStraight', label: 'Straight', max: 999}
    ].map((row) => {
        const columns = [0, 1, 2, 3, 4, 5, 6].map((weekDayIndex) => {
            return (
                <td key={weekDayIndex}>
                    <PCATField 
                        fontSize={15}
                        max={row.max}
                        value={budget.budgetProductivity[weekDayIndex][row.key]} 
                        originalValue={originalBudget?.budgetProductivity[weekDayIndex][row.key]} 
                        setValue={(value) => {handleSetBudgetProductivity(weekDayIndex, row.key, value)}} 
                        commasOnBlur
                    />
                </td>
            )
        })

        return (
            <tr key={row.key}>
                <td>{row.label}</td>
                {columns}
                <td></td>
                <td></td>
            </tr>
        )
    });

    const weeklyDispatches = [0, 1, 2, 3, 4, 5, 6].reduce((prev, curr) => {
        return prev + validateInteger(budget.budgetProductivity[curr]['dispatchesLight']) +
            validateInteger(budget.budgetProductivity[curr]['dispatchesStandard']) +
            validateInteger(budget.budgetProductivity[curr]['dispatchesStraight'])
        ;
    }, 0);

    const totalDispatchesRow = (
        <tr key={'totals'}>
            <td style={{whiteSpace: 'nowrap'}}>Total Dispatches</td>
            {
                [0, 1, 2, 3, 4, 5, 6].map((weekDayIndex) => {
                    return (
                        <td key={weekDayIndex}>
                            {
                                validateInteger(budget.budgetProductivity[weekDayIndex]['dispatchesLight']) +
                                validateInteger(budget.budgetProductivity[weekDayIndex]['dispatchesStandard']) +
                                validateInteger(budget.budgetProductivity[weekDayIndex]['dispatchesStraight'])
                            }
                        </td>
                    )
                })
            }
            <td>{weeklyDispatches.toLocaleString()}</td>
            <td>{(weeklyDispatches * 52).toLocaleString()}</td>
        </tr>
    )

    const stopMixHeader = (
        <tr key={'stopMixHeader'}>
            <td style={{textAlign: 'center'}} colSpan={10}>Stop Mix Percentage</td>
        </tr>
    )

    const stopMixECommPercentageRow = (
        <tr key={'stopMixECommPercentageRow'}>
            <td>E-Commerce</td>
            { 
                [0, 1, 2, 3, 4, 5, 6].map((weekDayIndex) => {
                    return (
                        <td key={weekDayIndex}>
                            <PCATField 
                                fontSize={15}
                                min={0} 
                                max={100} 
                                value={budget.budgetProductivity[weekDayIndex]['eCommStopPercentage']} 
                                originalValue={originalBudget?.budgetProductivity[weekDayIndex]['eCommStopPercentage']}
                                setValue={(value) => {handleSetBudgetProductivity(weekDayIndex, 'eCommStopPercentage', value)}} 
                                percentOnBlur
                            />
                        </td>
                    )
                })
            }
            <td></td>
            <td></td>
        </tr>
    )

    const stopMixGroundPercentageRow = (
        <tr key={'stopMixGroundPercentageRow'}>
            <td>Ground</td>
            { 
                [0, 1, 2, 3, 4, 5, 6].map((weekDayIndex) => {

                    return (
                        <td key={weekDayIndex}>
                            {`${toFixedMax((100.0 - validateDecimal(budget.budgetProductivity[weekDayIndex]['eCommStopPercentage'])), 3)}%`}
                        </td>
                    )
                })
            }
            <td></td>
            <td></td>
        </tr>
    )

    const stopAndPackagesHeader = (
        <tr key={'stopAndPackagesHeader'}>
            <td style={{textAlign: 'center'}} colSpan={10}>Stops & Packages</td>
        </tr>
    )


    const weeklyStops = [0, 1, 2, 3, 4, 5, 6].reduce((prev, curr) => {
        return (prev + validateDecimal(budget.budgetProductivity[curr].stops));
    }, 0);
    
    const stopsRow = (
        <tr key={'stopsRow'}>
            <td>Stops</td>
            {
                [0, 1, 2, 3, 4, 5, 6].map((weekDayIndex) => {
                    
                    return (
                        <td key={weekDayIndex}>
                            <PCATField 
                                fontSize={15}
                                max={99999.999}
                                value={toFixedMax(validateDecimal(budget.budgetProductivity[weekDayIndex]['stops']), 2)} 
                                originalValue={originalBudget?.budgetProductivity[weekDayIndex]['stops']}
                                setValue={(value) => {handleSetBudgetProductivity(weekDayIndex, 'stops', value)}} 
                                commasOnBlur
                                roundOnBlur
                            />
                        </td>
                    )
                })
            }
            <td>{Math.round(weeklyStops).toLocaleString()}</td>
            <td></td>
        </tr>
    )

    const avgStopsPerTruckRow = (
        <tr key={'avgStopsPerTruckRow'}>
            <td>Avg Stops/Truck</td>
            {
                [0, 1, 2, 3, 4, 5, 6].map((weekDayIndex) => {

                    const totalDispatchesOnDay = validateInteger(budget.budgetProductivity[weekDayIndex]['dispatchesLight']) +
                    validateInteger(budget.budgetProductivity[weekDayIndex]['dispatchesStandard']) +
                    validateInteger(budget.budgetProductivity[weekDayIndex]['dispatchesStraight']);

                    return (
                        <td key={weekDayIndex}>
                            { totalDispatchesOnDay === 0 ?  0 : 
                                toFixedMax(validateInteger(budget.budgetProductivity[weekDayIndex]['stops']) / totalDispatchesOnDay, 2)
                            }
                        </td>
                    )
                })
            }
            <td>
                {  weeklyDispatches === 0 ? 0 : 
                    toFixedMax(weeklyStops / weeklyDispatches, 2)
                }
            </td>
            <td></td>
        </tr>
    )

    const weeklyPackages = [0, 1, 2, 3, 4, 5, 6].reduce((prev, curr) => {
        return (prev + validateDecimal(budget.budgetProductivity[curr].packages));
    }, 0);

    const packagesRow = (
        <tr key={'packages'}>
            <td>Packages</td>
            {
                [0, 1, 2, 3, 4, 5, 6].map((weekDayIndex) => {
                    return (
                        <td key={weekDayIndex}>
                            <PCATField 
                                fontSize={15}
                                max={99999.999}
                                value={toFixedMax(validateDecimal(budget.budgetProductivity[weekDayIndex]['packages']), 2)} 
                                originalValue={originalBudget?.budgetProductivity[weekDayIndex]['packages']}
                                setValue={(value) => {handleSetBudgetProductivity(weekDayIndex, 'packages', value)}} 
                                commasOnBlur
                                roundOnBlur
                            />
                        </td>
                    )
                })

            }
            <td>
                {Math.round(weeklyPackages).toLocaleString()}
            </td>
            <td></td>
        </tr>
    )

    const allRows = [dispatchHeader].concat(dispatchRows).concat([
        , totalDispatchesRow, stopMixHeader, stopMixGroundPercentageRow, stopMixECommPercentageRow, stopAndPackagesHeader, stopsRow, avgStopsPerTruckRow, packagesRow
    ]);

    return (
        <>
        <QuickTable fontSize={15} title='Productivity' responsive={false} headers={['', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Weekly', 'Yearly']} rows={allRows} size='sm'/>
        <div style={{display: 'flex', justifyContent: 'center' , marginBottom: 18}}>
            <Button onClick={()=> setShowModal(true)} variant="outline-primary">Redistribute Stops And Packages</Button>
        </div>
        <Modal show={showModal} onHide={()=>setShowModal(false)}  size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Redistribute Stops and Packages</Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    Warning: This will evenly distribute stops and packages on days with zero dispatches to all other days with dispatches. This cannot be undone.
                </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-danger" onClick={()=>{handleRedistribute(); setShowModal(false)}}>Redistribute</Button>
            </Modal.Footer>
        </Modal>
        </>
    )
}