/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './ScheduleMatch.css';
import React, {useState} from 'react';
import moment from 'moment';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import RouteCreator from './RouteCreator';
import ScheduleMatchRoute from './ScheduleMatchRoute';
import ScheduleItemCreator from './ScheduleItemCreator';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { naturalSort } from '../../tools';

function RouteTable({datePointer, routes, handleSetRoutes, users, workAreas, scheduleItems, handleSetScheduleItems, selectedCsas, csas, vehicles, routeCardFilter, vehiclesNotFiltered, filterConflicts, handleSetOpenShifts, openShifts}) {
    const [showModal, setShowModal] = useState(false);
    const [showScheduleItemModal, setShowScheduleItemModal] = useState(false);
    const [selectedWorkArea, setSelectedWorkArea] = useState(undefined);
    const [selectedDay, setSelectedDay] = useState(moment(datePointer));
    const [selectedRoute, setSelectedRoute] = useState(undefined);
    const [selectedScheduleItem, setSelectedScheduleItem] = useState(undefined);
    const [showScheduleItems, setShowScheduleItems] = useState(true);
    const selectedCsaIds = selectedCsas.map(csa => csa.uid);
    const enabledUsers = users.filter(u=>u.companyUserIsEnabled == 1)
    const startOfWeek = (datePointer.day() == 6 ? moment(datePointer) : moment(datePointer).subtract(datePointer.day() + 1, 'days'));
    const daysOfWeek = [moment(startOfWeek), 
        moment(startOfWeek).add(1, 'days'), 
        moment(startOfWeek).add(2, 'days'), 
        moment(startOfWeek).add(3, 'days'), 
        moment(startOfWeek).add(4, 'days'), 
        moment(startOfWeek).add(5, 'days'), 
        moment(startOfWeek).add(6, 'days'),
    ];

    function getScheduledDriversForDay(dayIndex){
        
        const routesForDay = routes.filter((item) => {
            if (item.endDate) {
                return moment(item.date).diff(moment(datePointer).add(dayIndex, 'days'), 'days') <= 0 
                && moment(item.endDate).diff(moment(datePointer).add(dayIndex, 'days'), 'days') >= 0;
            } else {
                return moment(item.date).format('MM-DD-YYYY') == moment(datePointer).add(dayIndex, 'days').format('MM-DD-YYYY');
            }
        });

        const routeTotal = routesForDay.reduce((acc, route)=>{
                if(route.secondaryDriver){
                    return acc+2
                }
                return ++acc;
        }, 0)
    
        return routeTotal;
    }

    const tableHeadRows = daysOfWeek.map((date, index) => {
        return (
            <th key={index} style={{width: '13%'}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <p style={{margin: 0, fontWeight: 'normal', fontSize: 20}}>{date.format('D')}</p>
                    <p style={{margin: 0, fontWeight: 'bold', fontSize: 30}}>{date.format('ddd')}</p>
                </div>
            </th>
        )
    });

    const scheduleItemsInCsa = scheduleItems.filter((si) => {
        const userOfItem = users.find(u => u.companyUserUid == si.companyUserIdentifier);
        if (!userOfItem) {
            return false; // Skip the filter condition if userOfItem is undefined
        }
        return selectedCsaIds.includes(parseInt(userOfItem.csaIdentifier));
    });

    const scheduleItemElements = [0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {

        const scheduleItemsForDay = scheduleItemsInCsa.filter((item) => {
            if (item.endDate) {
                return moment(item.date).diff(moment(datePointer).add(dayIndex, 'days'), 'days') <= 0 
                && moment(item.endDate).diff(moment(datePointer).add(dayIndex, 'days'), 'days') >= 0;
            } else {
                return moment(item.date).format('MM-DD-YYYY') == moment(datePointer).add(dayIndex, 'days').format('MM-DD-YYYY');
            }
        });

        const itemTypes = [
            {key: 'managerOnDuty', value: 'MANAGER(S) ON DUTY'},
            {key: 'paidTraining', value: 'RECIEVING PAID TRAINING'},
            {key: 'callOff', value: 'CALLED OFF'},
            {key: 'dayOff', value: 'UNPAID DAY OFF'},
            {key: 'pto', value: 'PAID TIME OFF'},
            {key: 'requestOff', value: 'REQUESTED OFF'},
            {key: 'auxiliaryWorker', value: 'AUXILIARY WORKER(S)'},
            {key: 'noShow', value: 'NO SHOW'}
        ];

        ///ITEMS AND ROUTES LIST ITEMS
        const itemArrays = itemTypes.map((itemType) => {
            const itemsOfType = scheduleItemsForDay.filter(item => item.scheduleType == itemType.key);
            if (itemsOfType.length > 0) {
                const scheduleItemButtons = itemsOfType.map((itemOfType) => {
                    const user = users.find(u => u.companyUserUid == itemOfType.companyUserIdentifier);
                    return (
                        <div key={itemOfType.uid} className='schedule-match-click-to-view' onClick={() => {
                            setSelectedDay(moment(datePointer).add(dayIndex, 'days'));
                            setSelectedScheduleItem(itemOfType);
                            setShowScheduleItemModal(true);
                            }}>
                            <p style={{fontWeight: 'normal', marginBottom: 0, marginLeft: 8, fontSize: 14}}>
                                {user.firstName + ' ' + (user.showMiddleName ? user.middleName.substring(0, 1) + '. ' : '') + user.lastName}
                            </p>
                            <div className='schedule-match-click-to-view-text'>Click To View</div>
                        </div>
                    )
                });
                return (
                    <div key={itemType.key} style={{display: 'flex', flexDirection: 'column'}}>
                        <p style={{marginBottom: 0, fontSize: 11, fontWeight: 'bold'}}>{itemType.value} ({scheduleItemsForDay.reduce((acc, item)=>{
                             if(item.scheduleType == itemType.key)
                                return ++acc;
                            return acc;
                        }, 0)})
                        </p>
                        {scheduleItemButtons}
                    </div>
                )
            } else {
                return '';
            }

        })

        return (
            <th style={{verticalAlign: 'top', height: 'inherit'}} key={dayIndex}>
                <Card style={{height: '100%'}}>
                    <Card.Body style={{display: 'flex', flexDirection: 'column', gap: 8, justifyContent: 'space-between'}}>
                        <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                            {itemArrays}
                        </div>
                        <Button 
                            variant={'outline-primary'} 
                            style={{fontSize: 15, padding: 0}} 
                            onClick={() => {
                                setSelectedDay(moment(datePointer).add(dayIndex, 'days')); 
                                setSelectedScheduleItem(undefined);
                                setShowScheduleItemModal(true);
                            }}
                            >
                            Add Item
                        </Button>
                    </Card.Body>
                </Card>
            </th>
        )
    });

    const rows = naturalSort(workAreas.filter(w => w.isActive == '1' || routes.find(r => r.workAreaIdentifier == w.uid)), 'workAreaName').map((workArea) => { //for each work area
        const routesInWorkArea = routes.filter(route => route.workAreaIdentifier == workArea.uid);
        const openShiftsInWorkArea = openShifts.filter(os => os.workAreaIdentifier == workArea.uid);
        const cells = [0, 1, 2, 3, 4, 5, 6].map((dayIndex) => { //for each day of the week
            const routes = routesInWorkArea.filter(item => moment(item.date).format('M-D-YYYY') == moment(datePointer).add(dayIndex, 'days').format('M-D-YYYY'));
            const openShifts = openShiftsInWorkArea.filter(item => moment(item.date).format('M-D-YYYY') == moment(datePointer).add(dayIndex, 'days').format('M-D-YYYY'));

            const routeElements = routes.filter((route=>{
                return filterConflicts ? route.conflicts.length : true;
            })).map((route)=>{
                return (
                    <ScheduleMatchRoute 
                        key={route.uid}
                        users={users} 
                        vehicles={vehiclesNotFiltered} 
                        route={route} 
                        setSelectedRoute={setSelectedRoute} 
                        day={moment(datePointer).add(dayIndex, 'days')} 
                        setSelectedDay={setSelectedDay} 
                        workArea={workArea} 
                        setSelectedWorkArea={setSelectedWorkArea} 
                        setShowModal={setShowModal} 
                        routeCardFilter={routeCardFilter}
                    />
                )
            })

            const openShiftElements = openShifts.filter(()=>{
                return !filterConflicts;
            }).map((openShift)=>{
                return (
                    <ScheduleMatchRoute 
                        key={openShift.uid}
                        users={users} 
                        vehicles={vehiclesNotFiltered} 
                        route={openShift} 
                        setSelectedRoute={setSelectedRoute} 
                        day={moment(datePointer).add(dayIndex, 'days')} 
                        setSelectedDay={setSelectedDay} 
                        workArea={workArea} 
                        setSelectedWorkArea={setSelectedWorkArea} 
                        setShowModal={setShowModal} 
                        routeCardFilter={routeCardFilter}
                    />
                )
            })
            
            if (workArea.workAreaAvailability) {
                const daysOfWeek = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
      
                if(routes.length == 0 && openShifts.length == 0 && workArea.workAreaAvailability.includes(daysOfWeek[dayIndex])){
                    return (
                      <td key={dayIndex} style={{ height: 'inherit', padding: 0, position: 'relative' }}>
                            <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(280, 280, 102, 0.6)'}}>
                                <p style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', margin: 0, color:'white'}}><FontAwesomeIcon size='3x' icon={faExclamationTriangle}/></p>
                            </div>
                            <div className='schedule-driver-button' onClick={() => {
                            setSelectedDay(moment(datePointer).add(dayIndex, 'days'));
                            setSelectedWorkArea(workArea);
                            setShowModal(true);
                            }}>
                            <FontAwesomeIcon style={{ height: 20}} icon={faPlus} />
                            <div style={{ width: '5%', height: '15%', borderTop: '2px solid var(--bs-primary)', borderRight: '2px solid var(--bs-primary)', position: 'absolute', right: 4, top: 4 }} />
                            <div style={{ width: '5%', height: '15%', borderBottom: '2px solid var(--bs-primary)', borderRight: '2px solid var(--bs-primary)', position: 'absolute', right: 4, bottom: 4 }} />
                            <div style={{ width: '5%', height: '15%', borderLeft: '2px solid var(--bs-primary)', borderBottom: '2px solid var(--bs-primary)', position: 'absolute', left: 4, bottom: 4 }} />
                            <div style={{ width: '5%', height: '15%', borderLeft: '2px solid var(--bs-primary)', borderTop: '2px solid var(--bs-primary)', position: 'absolute', left: 4, top: 4 }} />
                            </div>
                      </td>
                    );
                };  
            }
             
            if (routes.length == 0 && openShifts.length == 0) {
                return (
                    <td key={dayIndex} style={{height: 'inherit', padding: 0}}>
                        <div className='schedule-driver-button' onClick={() => {
                                    setSelectedDay(moment(datePointer).add(dayIndex, 'days'));
                                    setSelectedWorkArea(workArea);
                                    setShowModal(true)
                                }}>
                            <FontAwesomeIcon style={{height: 20}} icon={faPlus}/>
                            <div style={{width: '5%', height: '15%', borderTop: '2px solid var(--bs-primary)', borderRight: '2px solid var(--bs-primary)', position: 'absolute', right: 4, top: 4}}/>
                            <div style={{width: '5%', height: '15%', borderBottom: '2px solid var(--bs-primary)', borderRight: '2px solid var(--bs-primary)', position: 'absolute', right: 4, bottom: 4}}/>
                            <div style={{width: '5%', height: '15%', borderLeft: '2px solid var(--bs-primary)', borderBottom: '2px solid var(--bs-primary)', position: 'absolute', left: 4, bottom: 4}}/>
                            <div style={{width: '5%', height: '15%', borderLeft: '2px solid var(--bs-primary)', borderTop: '2px solid var(--bs-primary)', position: 'absolute', left: 4, top: 4}}/>
                        </div>                        
                    </td>
                )
            } else {
                return (
                    <td key={dayIndex}>
                        <div style={{display:'flex', flexDirection:'column', gap:6}}>
                            {routeElements}
                            {openShiftElements}
                        </div>
                    </td>
                )
            }
        });
        cells.unshift(<td key={'work area ' + workArea.uid}>{workArea.workAreaName}</td>);
        return <tr style={{height: 1}} key={workArea.uid}>{cells}</tr>;
    });

    return (
        <div>
            <Table striped bordered repsonsive='true' size='sm'>
                <thead>
                    <tr>
                        <th></th>
                        {tableHeadRows}
                    </tr>
                    <tr style={{height: 1}}>
                        {showScheduleItems ? 
                            <th key='title' style={{textAlign: 'center', height: 'inherit'}}>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, height: '100%'}}>
                                    <Button style={{backgroundColor: 'white', color: 'var(--bs-primary)', padding: 2, fontSize: 12}} onClick={() => {setShowScheduleItems(!showScheduleItems)}}>
                                        {(showScheduleItems ? 'Hide' : 'Show') + ' Schedule Items'}
                                    </Button>
                                </div>
                            </th>
                        : ''}
                        {showScheduleItems ? scheduleItemElements : 
                            <th colSpan={8} style={{textAlign: 'center'}}>
                                <Button style={{backgroundColor: 'white', color: 'var(--bs-primary)', padding: 2, fontSize: 12}} onClick={() => {setShowScheduleItems(true)}}>
                                    {'Show Schedule Items'}
                                </Button>
                            </th>
                        }
                    </tr>
                    <tr>
                        <th style={{textAlign: 'center'}}>Work Area</th>
                        <th colSpan={7} style={{textAlign: 'center'}}>Routes</th>
                    </tr>
                    <tr style={{height: 1}}>
                        <th></th>
                        {[0, 1, 2, 3, 4, 5, 6].map(dayIndex=>{
                            return <th key={dayIndex} style={{fontWeight: 'normal', fontSize: 12, textAlign: 'center'}}>{getScheduledDriversForDay(dayIndex)} Scheduled Drivers</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </Table>
            <Modal show={showModal} onHide={() => {setShowModal(false); setSelectedRoute(undefined);}} size='xl'>
                <RouteCreator 
                    routes={routes} 
                    handleSetRoutes={handleSetRoutes} 
                    startOfWeek={startOfWeek} 
                    selectedDay={selectedDay} 
                    workArea={selectedWorkArea} 
                    users={enabledUsers} 
                    scheduleItems={scheduleItems} 
                    csas={csas} 
                    vehicles={vehicles} 
                    selectedCsas={selectedCsas} 
                    showModal={showModal} 
                    setShowModal={setShowModal} 
                    editingRoute={selectedRoute} 
                    setSelectedRoute={setSelectedRoute}
                    vehiclesNotFiltered={vehiclesNotFiltered}
                    handleSetOpenShifts={handleSetOpenShifts}
                />
            </Modal>
            <Modal show={showScheduleItemModal} onHide={() => setShowScheduleItemModal(false)} size='lg'>
                <ScheduleItemCreator 
                    csas={csas} 
                    selectedCsas={selectedCsas} 
                    users={users} 
                    selectedDay={selectedDay}
                    selectedScheduleItem={selectedScheduleItem} 
                    setSelectedScheduleItem={setSelectedScheduleItem} 
                    handleSetScheduleItems={handleSetScheduleItems} 
                    setShowScheduleItemModal={setShowScheduleItemModal}
                />
            </Modal>
        </div>
    )
}
export default RouteTable;