/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect, useState } from "react";
import { ApiRequest } from "../../../ApiManager.tsx";
import LoadingWrapper from "../../../components/LoadingWrapper.js";
import { Button, ListGroup, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import CustomButton from "../../../components/CustomButton.js";
import SearchBar from "../../../components/SearchBar.js";
import { getNameMatches } from "../../../tools.js";

export default function WeeklyServiceWorksheetMatch({unmatchedWswNames, setUnmatchedWswNames, unmatchedCsaFedexIds, setUnmatchedCsaFedexIds, matchWswNamesCallback, hideModal}){
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [csas, setCsas] = useState([]);
    const [users, setUsers] = useState([]);
    const [matchedCsas, setMatchedCsas] = useState([])
    const [matchedUsers, setMatchedUsers] = useState([]);
    const [selectedUnmatchedWswName, setSelectedUnmatchedWswName] = useState(null);
    const [selectedUnmatchedIdCsa, setSelectedUnmatchedIdCsa] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedCsa, setSelectedCsa] = useState(null);
    const [userSearch, setUserSearch] = useState('');

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        if(selectedUser && selectedUnmatchedWswName){
            handleAddMatchUser();
        }
        if(selectedCsa && selectedUnmatchedIdCsa){
            handleAddMatchCsa();
        }
    }, [selectedUnmatchedWswName, selectedUser, selectedUnmatchedIdCsa, selectedCsa])

    function loadData(){
        new ApiRequest('chargeStatement', 'getMatchFedexIdsResources', setIsLoading, (response) => {
            setCsas(response.csas);
            setUsers(response.users);
        }).withNoAlertOnSuccess().send()
    }

    function handleMatchFedexIds(){
        new ApiRequest('wsw', 'matchWswNames', setIsSubmitting, (response) => {
            setMatchedCsas([]);
            setMatchedUsers([]);
            if (matchWswNamesCallback) {
                matchWswNamesCallback(matchedUsers, matchedCsas, users, csas);
            }
            if(hideModal){
                hideModal()
            }

        }).withData({matchedUsers: matchedUsers, matchedCsas: matchedCsas}).send();
    }

    function handleAddMatchUser(){
        setMatchedUsers((prevState) => {
            return [...prevState, {wswName: selectedUnmatchedWswName, companyUserUid: selectedUser.companyUserUid}]
        })
        setUnmatchedWswNames((prevState) => prevState.filter(ps => ps !== selectedUnmatchedWswName));
        setSelectedUnmatchedWswName(null);
        setSelectedUser(null);
    }

    function handleRemoveMatchUser(wswName){
        setMatchedUsers((prevState) => prevState.filter(ps => ps.wswName !== wswName))
        setUnmatchedWswNames((prevState) => {
            const newArray = Array.from(prevState);
            newArray.push(wswName);
            return newArray;
        })
    }

    function handleAddMatchCsa(){
        setMatchedCsas((prevState) => {
            return [...prevState, {fedexId: selectedUnmatchedIdCsa, csaIdentifier: selectedCsa.uid}]
        })
        setUnmatchedCsaFedexIds((prevState) => prevState.filter(ps => ps !== selectedUnmatchedIdCsa));
        setSelectedUnmatchedIdCsa(null);
        setSelectedCsa(null);
    }

    function handleRemoveMatchCsa(fedexId){
        setMatchedCsas((prevState) => prevState.filter(ps => ps.fedexId !== fedexId))
        setUnmatchedCsaFedexIds((prevState) => {
            const newArray = Array.from(prevState);
            newArray.push(fedexId);
            return newArray;
        })
    }

    function matchedWarning(wswName, type){ 
        const message = type === 'user' ? `This user is currently matched in our system with a WSW Name of ${wswName}. Selecting this user will overwrite their current WSW Name.`
        : `This CSA is currently matched in our system with a FedEx ID of ${wswName}. Selecting this CSA will overwrite its current FedEx ID.`;

        return (
            <>
                <span>Matched &nbsp;</span>
                <OverlayTrigger overlay={
                    <Popover>
                        <Popover.Body>{message}</Popover.Body>
                    </Popover>
                }>
                    <FontAwesomeIcon color="gold" icon={faExclamationTriangle}/>
                </OverlayTrigger>   
            </>
        )
    }

    return (
        <>
            <Modal.Header>
                <Modal.Title>Match Unmatched FedEx IDs</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoadingWrapper isLoading={isLoading}>
                    {unmatchedCsaFedexIds.length > 0 &&
                        <>
                            <div>
                                <h5>Match CSAs (Required)</h5>
                                <p>One or more CSA FedEx IDs from the report are unmatched to CSAs in our system. Please select the unmatched IDs and match them to your company's CSAs.</p>
                                <div style={{display: 'flex', justifyContent: 'center', gap: 24, flexWrap: 'wrap'}}>
                                    <div style={{flex: 1}}>
                                        <h6 style={{textAlign: 'center'}}>Unmatched Fedex IDs/Names From Report</h6>
                                        <ListGroup style={{maxHeight: 400, minWidth: 300, overflow: 'auto'}}>
                                            {unmatchedCsaFedexIds.map(id => {
                                                return <ListGroup.Item key={id} action active={id === selectedUnmatchedIdCsa} onClick={() => setSelectedUnmatchedIdCsa(id)}>{id}</ListGroup.Item>
                                            })}
                                        </ListGroup>
                                    </div>
                                    <div style={{flex: 1}}>
                                        <h6 style={{textAlign: 'center'}}>CSAs</h6>
                                        <ListGroup style={{maxHeight: 355, minWidth: 300, overflow: 'auto'}}>
                                            {csas.filter(csa => !matchedCsas.find(mc => mc.csaIdentifier === csa.csaIdentifier)).sort((a, b) => a.fedexId ? 1 : b.fedexId ? -1 : 0).map(csa => {
                                                return (
                                                    <ListGroup.Item key={csa.uid} action active={csa.uid == selectedCsa?.uid} onClick={() => setSelectedCsa(csa)} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap'}}>
                                                        <h6 style={{margin: 0}}>{csa.csaName}</h6>
                                                        <span>{!csa.fedexId ? 'Not Matched' : matchedWarning(csa.fedexId, 'csa')}</span>
                                                    </ListGroup.Item>
                                                )
                                            })}
                                        </ListGroup>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    { matchedCsas.length > 0 &&
                        <div style={{marginTop: 18}}>
                            <h6 style={{textAlign: 'center'}}>Matched CSAs</h6>
                            <ListGroup>
                                {matchedCsas.map(mc => {
                                    const csa = csas.find(csa => csa.uid == mc.csaIdentifier);
                                    return (
                                        <ListGroup.Item key={mc.fedexId} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <div style={{alignItems: 'center'}}>
                                                <span>{mc.fedexId}</span>
                                                <span>&nbsp;({csa?.csaName})</span>
                                            </div>
                                            <Button onClick={() => handleRemoveMatchCsa(mc.fedexId)} variant="outline-danger">
                                                <FontAwesomeIcon icon={faTrashCan}/>
                                            </Button>
                                        </ListGroup.Item>
                                    )
                                })}
                            </ListGroup>
                            {unmatchedWswNames.length > 0 && <hr/>}
                        </div>
                    }
                    {unmatchedCsaFedexIds.length > 0 && unmatchedWswNames.length > 0 && <hr/>}
                    {unmatchedWswNames.length > 0 &&
                        <>
                            <div>
                                <h5>Match Employees (Optional)</h5>
                                <p>One or more employee WSW Names from the report are unmatched to users in our system. Please select the unmatched WSW Name and match them to your company employees.</p>
                                <div style={{display: 'flex', justifyContent: 'center', gap: 24, flexWrap: 'wrap'}}>
                                    <div style={{flex: 1}}>
                                        <h6 style={{textAlign: 'center'}}>Unmatched WSW Names/User Names From Report</h6>
                                        <ListGroup style={{maxHeight: 400, minWidth: 300, overflow: 'auto'}}>
                                            {unmatchedWswNames.map(wswName => {
                                                return <ListGroup.Item key={wswName} action active={wswName === selectedUnmatchedWswName} onClick={() => setSelectedUnmatchedWswName(wswName)}>{wswName}</ListGroup.Item>
                                            })}
                                        </ListGroup>
                                    </div>
                                    <div style={{flex: 1}}>
                                        <h6 style={{textAlign: 'center'}}>Company Employees</h6>
                                        <div style={{margin: '8px 0'}}>
                                            <SearchBar label='Search by name' value={userSearch} setValue={setUserSearch}/>
                                        </div>
                                        <ListGroup style={{maxHeight: 355, minWidth: 300, overflow: 'auto'}}>
                                            {users.filter(user => !matchedUsers.find(mu => mu.companyUserUid === user.companyUserUid) && getNameMatches(user, userSearch)).sort((a, b) => a.wswName ? 1 : b.wswName ? -1 : 0).map(user => {
                                                const middleName = user?.middleName ? `${user?.middleName} ` : ''
                                                const name = `${user?.firstName} ${middleName}${user?.lastName}`
                                                return (
                                                    <ListGroup.Item key={user.companyUserUid} action active={user.companyUserUid == selectedUser?.companyUserUid} onClick={() => setSelectedUser(user)} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap'}}>
                                                        <h6 style={{margin: 0}}>{name}</h6>
                                                        <span>{!user.wswName ? 'Not Matched' : matchedWarning(user.wswName, 'user')}</span>
                                                    </ListGroup.Item>
                                                )
                                            })}
                                        </ListGroup>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    { matchedUsers.length > 0 &&
                        <div style={{marginTop: 24}}>
                            <h6 style={{textAlign: 'center'}}>Matched Users</h6>
                            <ListGroup>
                                {matchedUsers.map(mu => {
                                    const user = users.find(user => user.companyUserUid === mu.companyUserUid);
                                    const middleName = user?.middleName ? `${user?.middleName} ` : ''
                                    const name = `${user?.firstName} ${middleName}${user?.lastName}`
                                    return (
                                        <ListGroup.Item key={mu.companyUserUid} style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <div style={{alignItems: 'center'}}>
                                                <span>{mu.wswName}</span>
                                                <span>&nbsp;({name})</span>
                                            </div>
                                            <Button onClick={() => handleRemoveMatchUser(mu.wswName)} variant="outline-danger">
                                                <FontAwesomeIcon icon={faTrashCan}/>
                                            </Button>
                                        </ListGroup.Item>
                                    )
                                })}
                            </ListGroup>
                        </div>
                    }
                </LoadingWrapper>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton label='Submit Matches' disabled={unmatchedCsaFedexIds.length > 0} isLoading={isSubmitting} onClick={handleMatchFedexIds}/>
            </Modal.Footer>
        </>
    )
}