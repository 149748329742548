/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import QuickTable from '../../../components/QuickTable';
import { durationToString } from '../../../tools.js';
import HRUserTimesheetDateSelector from './HRUserTimesheetDateSelector';
import HRUserTimesheetEditor from './HRUserTimesheetEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faWarning } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ApiRequest } from '../../../ApiManager.tsx';
import LoadingWrapper from '../../../components/LoadingWrapper.js';

export default function HRUserTimesheet({selectedEmployee, handleJumpToBwcRecord}){
    const [isLoading, setIsLoading] = useState(false);
    const [entries, setEntries] = useState([]);
    const [selectedStartDate, setSelectedStartDate] = useState(moment().subtract(6, 'days'));
    const [selectedEndDate, setSelectedEndDate] = useState(moment());
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [selectedEntry, setSelectedEntry] = useState(undefined);
    const [showEditor, setShowEditor] = useState(false);

    useEffect(() => {
        loadData();
    }, [selectedEmployee])

    function loadData(startDate = selectedStartDate.format('YYYY-MM-DD'), endDate = selectedEndDate.format('YYYY-MM-DD')) {
        new ApiRequest('hr', 'getTimesheet', setIsLoading, (response) => {
            setEntries(response.timesheets.sort(sortEntries));
            setSelectedStartDate(moment(startDate));
            setSelectedEndDate(moment(endDate));
        }).withData({
            companyUserIdentifier: selectedEmployee.companyUserUid,
            startDate: startDate,
            endDate: endDate
        }).withNoAlertOnSuccess().send();
    }

    function handleAddEntry(entry) {
        const newArray = Array.from(entries);
        newArray.push(entry);
        newArray.sort(sortEntries);
        setEntries(newArray);
        setEntries(newArray);
    }

    function handleUpdateEntry(entry) {
        const newArray = Array.from(entries.filter(e => e.uid !== entry.uid));
        newArray.push(entry);
        newArray.sort(sortEntries);
        setEntries(newArray);
    }

    function handleDeleteEntry(uid) {
        const newArray = Array.from(entries.filter(e => e.uid !== uid));
        setEntries(newArray);
    }

    function handleTotalHours(){
        let totalHours = 0;
        entries.filter((t)=>t.inTime && t.outTime).forEach((e)=>{
            totalHours += moment(e.outTime).diff(moment(e.inTime), 'hours', 'minutes');
        })
        return totalHours.toFixed(2)
    }

    function sortEntries(a, b) {

        if (a.inTime < b.inTime) {
            return 1;
        } else if (a.inTime > b.inTime) {
            return -1;
        }
        return 0;
    }

    const totalHours = handleTotalHours();
    const historyRows = entries.map((item) => {
        return (
            <tr key={item.uid} className='cursor-pointer' onClick={() => {setSelectedEntry(item); setShowEditor(true)}}>
                <td>{moment(item.inTime).format('MMM D, YYYY')}</td>
                <td>{moment(item.inTime).format('hh:mm A')}</td>
                <td>{item.outTime ? !moment(item.outTime).isSame(moment(item.inTime),'day') ? moment(item.outTime).format('MMMM D, YYYY hh:mm A') : moment(item.outTime).format('hh:mm A') : ''}</td>
                <td>{item.outTime && item.outTime !== 'ACO' ? durationToString(moment(item.outTime).diff(moment(item.inTime), 'minutes')) : 'N/A'}</td>
                <td>
                    {item.incidents == '1' || item.accidents == '1' || item.injuries == '1' ? 
                        <OverlayTrigger overlay={<Tooltip>An incident, accident, or injury was reported on this day</Tooltip>}>
                            <FontAwesomeIcon style={{color: 'gold'}} icon={faWarning}/>
                        </OverlayTrigger>
                    : 
                        <OverlayTrigger overlay={<Tooltip>No incidents, accidents, or injuries were reported on this day</Tooltip>}>
                            <FontAwesomeIcon style={{color: 'green'}} icon={faCheck}/>
                        </OverlayTrigger>
                    }
                </td>
            </tr>
        )
    });

    const content = (
        <div style={{display: 'flex', flexDirection: 'column', gap: 12, height: '100%', overflowY: 'hidden'}}>
            <Button variant={'outline-primary'} style={{alignSelf: 'flex-end'}} onClick={() => {setShowEditor(true); setSelectedEntry(undefined)}}>New Timesheet Entry</Button>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <Card.Title>{'Timesheet History for ' + selectedStartDate.format('MMM D, YYYY') + ' - ' + selectedEndDate.format('MMM D, YYYY')}</Card.Title>
                <Card.Title>{'Total Hours Worked: ' + totalHours}</Card.Title>
                <Button variant={'outline-primary'} style={{justifySelf: 'flex-end'}} onClick={() => setShowDatePicker(true)}>Change Date Range</Button>
            </div>
            <QuickTable style={{overflowY: 'auto'}} headers={['Date', 'In Time', 'Out Time', 'Total Time', 'Safety']} rows={historyRows} hover size='sm'/>
        </div>
    )

    return (
        <>
            <LoadingWrapper isLoading={isLoading} onlyRenderIfTruthy={selectedEmployee ?? false}>
                {content}
            </LoadingWrapper>
            <Modal show={showDatePicker} onHide={() => setShowDatePicker(false)} centered>
                <HRUserTimesheetDateSelector 
                    hideModal={() => setShowDatePicker(false)} 
                    loadData={loadData}
                />
            </Modal>
            
            <Modal show={showEditor} onHide={() => setShowEditor(false)} centered size='lg'>
                <HRUserTimesheetEditor 
                    selectedEmployee={selectedEmployee}
                    selectedEntry={selectedEntry} 
                    hideModal={() => setShowEditor(false)} 
                    handleAddEntry={handleAddEntry} 
                    handleUpdateEntry={handleUpdateEntry} 
                    handleDeleteEntry={handleDeleteEntry} 
                    entries={entries}
                    handleJumpToBwcRecord={handleJumpToBwcRecord}
                />
            </Modal>
        </>
    )
}