/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Dropdown, ListGroup, Modal, Row } from 'react-bootstrap';
import { ApiRequest } from '../../ApiManager.tsx';
import DropdownControl from '../../components/DropdownControl.js';
import QuickTable from '../../components/QuickTable.js';
import LoadingWrapper from '../../components/LoadingWrapper.js';
import SearchBar from '../../components/SearchBar.js';
import DropdownItemWithCheck from '../../components/DropdownItemWithCheck.js';
import './Shadow.css'
import AlertModal from '../../components/AlertModals/AlertModal.js';
import { getNameMatches, validateInteger } from '../../tools.js';

export default function Shadow({handleCheckAuthentication}) {
    const ref = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingUsers, setIsLoadingUsers] = useState(false);
    const [isShadowing, setIsShadowing] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedCompanyUser, setSelectedCompanyUser] = useState(null)
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [titleFilter, setTitleFilter] = useState(null);
    const [csaFilter, setCsaFilter] = useState(null);
    const [terminatedFilter, setTerminatedFilter] = useState(true);
    const allTitles = ['Admin', 'AO', 'AVP Driver', 'BC', 'Driver', 'Jumper'];
    const allCsas = [...new Set(users.map(u => u.csa.csaName))].sort((a, b) => a.localeCompare(b));
    const numberFiltersActive = (titleFilter ? 1 : 0) + (csaFilter ? 1 : 0) + (terminatedFilter ? 1 : 0);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(()=>{
        if(selectedCompany){
            handleGetCompanyUsers();
        }
    }, [selectedCompany])

    function loadData(){
        new ApiRequest('admin', 'getCompanies', setIsLoading, (response) => setCompanies(response.companies)).withNoAlertOnSuccess().send();
    }

    function handleGetCompanyUsers(){
        new ApiRequest('admin', 'getCompanyUsers', setIsLoadingUsers, (response) => setUsers(response.users)).withUid(selectedCompany).withNoAlertOnSuccess().send()
    }

    function handleShadowUser(){
        new ApiRequest('admin', 'shadow', setIsShadowing, () => {
            handleCheckAuthentication();
        }).withData({companyUserIdentifier: selectedCompanyUser.companyUserUid}).send();
    }

    ////////////////////////////
    /// DESKTOP QUICKTABLE ROWS
    ////////////////////////////

    const companyUserRows = users.filter(user => {
        if(titleFilter && user.title !== titleFilter)
            return false;
        if(csaFilter && user.csa.csaName !== csaFilter)
            return false;
        if(!terminatedFilter && (user.isEnabled == 0 || user.companyUserIsEnabled == 0)){
            return false;
        }

        return getNameMatches(user, search);

    }).map((user) => {
        return (
            <tr style={{cursor: 'pointer'}} key={user.companyUserUid} onClick={() => {setShowModal(true); setSelectedCompanyUser(user)}}>
                <td>{`${user.firstName} ${user.lastName}${user.isEnabled == 0 ? ' (Disabled)' : user.companyUserIsEnabled == 0 ? ' (Terminated)' : ''}`}</td>
                <td>{user.title}</td>
                <td>{user.csa.csaName}</td>
            </tr>
        )
    })

    /////////////////////////////
    /// LIST GROUP ITEMS MOBILE
    /////////////////////////////

    const comapnyUserRowsMobile = users.filter(user=>{
        if(titleFilter && user.title !== titleFilter)
            return false;
        if(csaFilter && user.csa.csaName !== csaFilter)
            return false;
        if(!terminatedFilter && (user.isEnabled == 0 || user.companyUserIsEnabled == 0)){
            return false;
        }

        const fullName = `${user.firstName}${user.lastName}`;
        const fullNameReversed = `${user.lastName}${user.firstName}`;

        return (user.firstName.toLocaleLowerCase().includes(search.toLocaleLowerCase()) 
            || user.lastName.toLocaleLowerCase().includes(search.toLocaleLowerCase())
            || fullName.toLocaleLowerCase().includes(search.replaceAll(' ','').toLocaleLowerCase()))
            || fullNameReversed.toLocaleLowerCase().includes(search.replaceAll(' ','').toLocaleLowerCase())

    }).map((user) => {
        return (
            <ListGroup.Item className='cursor-pointer' action key={user.companyUserUid} onClick={() => {setShowModal(true); setSelectedCompanyUser(user)}}>
                <div style={{display: 'flex', flexDirection: 'column', padding: 8}}>
                        <h4 style={{fontWeight: 'bold', marginBottom: 12}}>{`${user.firstName} ${user.lastName}${user.isEnabled == 0 ? ' (Disabled)' : user.companyUserIsEnabled == 0 ? ' (Terminated)' : ''}`}</h4>
                        <h6 style={{marginBottom: 6}}><strong>Job Title: </strong>{user.title}</h6>
                        <h6 style={{marginBottom: 6}}><strong>CSA: </strong>{user.csa.csaName}</h6>
                </div>
            </ListGroup.Item>
        )
    })

    /////////////////////////////
    /// Filter Dropdown Element
    ////////////////////////////

    const filter = (
        <Dropdown autoClose='outside' >
            <Dropdown.Toggle style={{display: 'block', marginLeft: 'auto', width: 117}} variant='outline-primary'>Filter(s) {numberFiltersActive != 0 && `+${numberFiltersActive}`}</Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Header>Show Terminated/Disabled</Dropdown.Header>
                <DropdownItemWithCheck label='Show Terminated and Disabled Users' active={terminatedFilter} onClick={() => setTerminatedFilter(!terminatedFilter)}/>
                <Dropdown.Divider/>
                <Dropdown.Header>Filter by Title</Dropdown.Header>
                {allTitles.map((title) => {
                    return <DropdownItemWithCheck key={title} label={title} active={titleFilter === title} onClick={() => title === titleFilter ? setTitleFilter(null) : setTitleFilter(title)}/>
                })}
                <Dropdown.Divider/>
                <Dropdown.Header>Filter by CSA</Dropdown.Header>
                {allCsas.map((csa) => {
                   return  <DropdownItemWithCheck key={csa} label={csa} active={csaFilter === csa} onClick={() => csa === csaFilter ? setCsaFilter(null) : setCsaFilter(csa)}/>
                })}
            </Dropdown.Menu>
        </Dropdown>
    )

    return (
        <>
            <Modal.Header ref={ref} closeButton>
                <Modal.Title>Shadow User</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                <Container fluid style={{border: '1px solid lightgray', borderRadius: 6, padding: 6}}>
                    <Row>
                        <Col sm={12}>
                            <DropdownControl title='Select Company' selection={selectedCompany} setSelection={setSelectedCompany} itemNames={companies.map(c=>c.companyName)} itemValues={companies.map(c=>c.uid)}/>
                        </Col>
                    </Row>
                </Container>
                <LoadingWrapper isLoading={isLoadingUsers}>
                    {validateInteger(selectedCompany) !== 0 &&
                        <>
                            {users.length > 0 ? 
                                <Row>
                                    <Col lg={12} className='search-bar-filter'>
                                        <SearchBar label={`Search`} value={search} setValue={setSearch}/>
                                        {filter}
                                    </Col>
                                    { companyUserRows.length > 0 ?
                                        <>
                                            <div className='desktop-shadow-company-users'>
                                                <QuickTable
                                                    responsive={false}
                                                    hover
                                                    headers={['Name', 'Job Title', 'CSA ']}
                                                    rows={[companyUserRows]}
                                                />
                                            </div>
                                            <div className='mobile-shadow-company-users'>
                                                <ListGroup>
                                                    {comapnyUserRowsMobile}
                                                </ListGroup>
                                            </div>
                                        </>
                                    : <h4 style={{textAlign: 'center'}}>No users in this company with the applied filters/search.</h4>
                                    }
                                </Row>
                            : <h4 style={{textAlign: 'center'}}>No users in this company.</h4>
                            }
                        </>
                    }
                </LoadingWrapper>
            </Modal.Body>
            <Modal centered show={showModal} onHide={() => setShowModal(false)}>
                <AlertModal 
                    isLoading={isShadowing}
                    message={`Do you want to shadow ${selectedCompanyUser?.firstName} ${selectedCompanyUser?.lastName} for company ${companies.find(c=>c.uid === selectedCompany)?.companyName}?`}
                    title='Shadow User' 
                    buttonLabel='Confirm'
                    callBack={handleShadowUser}
                />
            </Modal>
        </>
    )
}