/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { createRef, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { InputGroup } from 'react-bootstrap';
import CustomButton from './CustomButton';
import { ApiRequest } from '../ApiManager.tsx';

export default function FileControl({excludeDownloadButton, value, companyUserUid, label, setValue}){
    const [documentName, setDocumentName] = useState(undefined);
    const [documentType, setDocumentType] = useState(undefined);
    const [base64, setBase64] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isDeletting, setIsDeleting] = useState(false);
    const ref = createRef();

    useEffect(()=> {
        loadData();
    }, [])

    function loadData(){
        if(value){
            new ApiRequest('hr', 'getCustomDocument', setIsLoading, (response) => {
                setDocumentName(response.data.documentName);
                setDocumentType(response.data.documentType);
            }).withUid(value).withNoAlertOnSuccess().send();
        }
    }

    function handleOnChange(){
        const file = ref.current.files[0];
        if(file){
            setIsLoading(true);
            const reader = new FileReader();
    
            reader.onerror = () => {
                console.log(reader.error);
                setIsLoading(false)
            }
    
            reader.onload = () => {
                const indexOfDot = file.name.lastIndexOf('.');
    
                const fileObj = {
                    base64: reader.result,
                    documentName: file.name.slice(0, indexOfDot),
                    documentType: file.name.slice(indexOfDot + 1),
                    companyUserUid: companyUserUid
                };

                new ApiRequest('hr', 'createCustomDocument', setIsLoading, (response) => {
                    setValue(response.uid);
                    setBase64(fileObj.base64);
                    setDocumentName(fileObj.documentName);
                    setDocumentType(fileObj.documentType);
                }).withData({file: fileObj}).withNoAlertOnSuccess().send();
            }
            reader.readAsDataURL(file);
        }
    }

    function handleDownload(){
        new ApiRequest('hr', 'downloadCustomDocument', setIsDownloading, async (response) => {
            setBase64(response.base64);
            const fetchResponse = await fetch(response.base64);
            const blob = await fetchResponse.blob();
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = documentName;        
            document.body.appendChild(elem);
            elem.click();        
            document.body.removeChild(elem);
        }).withUid(value).withNoAlertOnSuccess().send()
    }

    function handleDelete(){
        new ApiRequest('hr', 'deleteCustomDocument', setIsLoading, (response) => {
            setDocumentName(undefined);
            setDocumentType(undefined);
            setBase64(undefined);
            setValue(undefined);
        }).withUid(value).withNoAlertOnSuccess().send()
    }

    return (
        <>
            <input style={{width: 0, height: 0, opacity: 0}} ref={ref} type='file' onChange={handleOnChange}/>
            <InputGroup>
                <CustomButton 
                    onClick={() => {ref.current.click()}} 
                    label={value ? 'Change File' : 'Choose File'} 
                    isLoading={isSubmitting}
                />
                <FloatingLabel label={label}>
                    <Form.Control type='text' placeholder=' ' disabled value={documentName ? documentName : ''}/>
                </FloatingLabel>
                { value ?
                    <>
                        <InputGroup.Text>{documentType}</InputGroup.Text>
                        {!excludeDownloadButton &&
                        <CustomButton label='Download' isLoading={isDownloading} onClick={handleDownload}/>
                        }
                        <CustomButton label='Remove' isLoading={isDeletting} onClick={handleDelete}/>
                    </>
                : ''}
            </InputGroup>
        </>
    )

}