/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import React from "react"
import Decoder from "../../../decoding";
import { usdFormatter, validateDecimal, validateInteger } from "../../../tools";
import Big from "big.js";
import { bigToUsd, toFixedMax, validateBig } from "../../Payroll/payrollTools";
import Budget from "../../Budget/Models/Budget";
import BudgetAssumptions from "../../Budget/Models/BudgetAssumptions";

export default class PCATProposal {
    uid;
    dateEffective;
    isFedex;
    brandVehicles;
    dockLoadingPositions;
    serviceCharge;
    stopCharge;
    surgeStopCharge;
    perStopFuelSurcharge;
    packageCharge;
    largePackageMixCharge;
    apparelBrandCharge;
    vehicleBrandCharge;
    eCommStopCharge;
    eCommPackageCharge;
    advanceServiceCharge;
    nonECommStopGrowth;
    nonECommPackageGrowth;
    eCommStopGrowth;
    eCommPackageGrowth;
	overThresholdStops;
    budget;
    

    constructor(uid, dateEffective, isFedex, brandVehicles, dockLoadingPositions, serviceCharge, stopCharge, 
            surgeStopCharge, perStopFuelSurcharge, packageCharge, largePackageMixCharge, apparelBrandCharge, vehicleBrandCharge, 
            eCommStopCharge, eCommPackageCharge, advanceServiceCharge, nonECommStopGrowth, nonECommPackageGrowth, eCommStopGrowth, eCommPackageGrowth, overThresholdStops, budget) {
        this.uid = uid;
        this.dateEffective = dateEffective;
        this.isFedex = isFedex;
        this.brandVehicles = brandVehicles;
        this.dockLoadingPositions = dockLoadingPositions;
        this.serviceCharge = serviceCharge; //
        this.stopCharge = stopCharge; //
        this.surgeStopCharge = surgeStopCharge; //?
        this.perStopFuelSurcharge = perStopFuelSurcharge; //
        this.packageCharge = packageCharge; //
        this.largePackageMixCharge = largePackageMixCharge; //
        this.apparelBrandCharge = apparelBrandCharge; //
        this.vehicleBrandCharge = vehicleBrandCharge; //
        this.eCommStopCharge = eCommStopCharge; //
        this.eCommPackageCharge = eCommPackageCharge; //?
        this.advanceServiceCharge = advanceServiceCharge; //
        this.nonECommStopGrowth = nonECommStopGrowth;
        this.nonECommPackageGrowth = nonECommPackageGrowth;
        this.eCommStopGrowth = eCommStopGrowth;
        this.eCommPackageGrowth = eCommPackageGrowth;
        this.overThresholdStops = overThresholdStops;
        this.budget = budget;
    }

    static initDefault() {
        return new PCATProposal(-1, '', false, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', undefined);
    }


    static decode(json) {
        const decoder = new Decoder(json);

        const uid = decoder.decode('uid', Decoder.Uid);
        const dateEffective = decoder.decode('dateEffective', Decoder.Date, {defaultValue: '', warn: false});
        const isFedex = decoder.decode('isFedex', Decoder.BooleanFlexible);
        const brandVehicles = decoder.decode('brandVehicles', Decoder.Integer);
        const dockLoadingPositions = decoder.decode('dockLoadingPositions', Decoder.Integer);
        const serviceCharge = decoder.decode('serviceCharge', Decoder.Decimal);
        const stopCharge = decoder.decode('stopCharge', Decoder.Decimal);
        const surgeStopCharge = decoder.decode('surgeStopCharge', Decoder.Decimal);
        const perStopFuelSurcharge = decoder.decode('perStopFuelSurcharge', Decoder.Decimal);
        const packageCharge = decoder.decode('packageCharge', Decoder.Decimal);
        const largePackageMixCharge = decoder.decode('largePackageMixCharge', Decoder.Decimal);
        const apparelBrandCharge = decoder.decode('apparelBrandCharge', Decoder.Decimal);
        const vehicleBrandCharge = decoder.decode('vehicleBrandCharge', Decoder.Decimal);
        const eCommStopCharge = decoder.decode('eCommStopCharge', Decoder.Decimal);
        const eCommPackageCharge = decoder.decode('eCommPackageCharge', Decoder.Decimal);
        const advanceServiceCharge = decoder.decode('advanceServiceCharge', Decoder.Decimal);
        const nonECommStopGrowth = decoder.decode('nonECommStopGrowth', Decoder.Decimal);
        const nonECommPackageGrowth = decoder.decode('nonECommPackageGrowth', Decoder.Decimal);
        const eCommStopGrowth = decoder.decode('eCommStopGrowth', Decoder.Decimal);
        const eCommPackageGrowth = decoder.decode('eCommPackageGrowth', Decoder.Decimal);
        const overThresholdStops = decoder.decode('overThresholdStops', Decoder.Integer);
        const budget = json['budget'] ? Budget.decode(json['budget']) : undefined;
        

        if (decoder.checkForErrors()) {
            return new PCATProposal(uid, dateEffective, isFedex, brandVehicles, dockLoadingPositions, serviceCharge, stopCharge, surgeStopCharge, 
                perStopFuelSurcharge, packageCharge, largePackageMixCharge, apparelBrandCharge, vehicleBrandCharge, eCommStopCharge, eCommPackageCharge, 
                advanceServiceCharge, nonECommStopGrowth, nonECommPackageGrowth, eCommStopGrowth, eCommPackageGrowth, overThresholdStops, budget);
        } else {
            return PCATProposal.initDefault();
        }
    }

    encode(pcatIndex) {
        return {
            uid: this.uid,
            pcatIndex: pcatIndex,
            dateEffective: this.dateEffective ? this.dateEffective : null,
            isFedex: this.isFedex,
            brandVehicles: validateInteger(this.brandVehicles),
            dockLoadingPositions: validateInteger(this.dockLoadingPositions),
            serviceCharge: validateDecimal(this.serviceCharge),
            stopCharge: validateDecimal(this.stopCharge),
            surgeStopCharge: validateDecimal(this.surgeStopCharge),
            perStopFuelSurcharge: validateDecimal(this.perStopFuelSurcharge),
            packageCharge: validateDecimal(this.packageCharge),
            largePackageMixCharge: validateDecimal(this.largePackageMixCharge),
            apparelBrandCharge: validateDecimal(this.apparelBrandCharge),
            vehicleBrandCharge: validateDecimal(this.vehicleBrandCharge),
            eCommStopCharge: validateDecimal(this.eCommStopCharge),
            eCommPackageCharge: validateDecimal(this.eCommPackageCharge),
            advanceServiceCharge: validateDecimal(this.advanceServiceCharge),
            nonECommStopGrowth: validateDecimal(this.nonECommStopGrowth),
            nonECommPackageGrowth: validateDecimal(this.nonECommPackageGrowth),
            eCommStopGrowth: validateDecimal(this.eCommStopGrowth),
            eCommPackageGrowth: validateDecimal(this.eCommPackageGrowth),
            overThresholdStops: validateInteger(this.overThresholdStops),
            budget: this.budget?.encode()
        }
    }

    duplicate() {
        return new PCATProposal(
            this.uid,
            this.dateEffective,
            this.isFedex,
            this.brandVehicles,
            this.dockLoadingPositions,
            this.serviceCharge,
            this.stopCharge,
            this.surgeStopCharge,
            this.perStopFuelSurcharge,
            this.packageCharge,
            this.largePackageMixCharge,
            this.apparelBrandCharge,
            this.vehicleBrandCharge,
            this.eCommStopCharge,
            this.eCommPackageCharge,
            this.advanceServiceCharge,
            this.nonECommStopGrowth,
            this.nonECommPackageGrowth,
            this.eCommStopGrowth,
            this.eCommPackageGrowth,
            this.overThresholdStops,
            this.budget?.duplicate()
        )
    }

    equals(pcatProposal) {

        if (this.budget && pcatProposal.budget) {
            if (!this.budget.equals(pcatProposal.budget)) {
                return false;
            }
        } else if (this.budget || pcatProposal.budget) {
            return false;
        }


        return this.uid === pcatProposal.uid &&
        this.dateEffective === pcatProposal.dateEffective &&
        this.isFedex === pcatProposal.isFedex &&
        this.brandVehicles === pcatProposal.brandVehicles &&
        this.dockLoadingPositions === pcatProposal.dockLoadingPositions &&
        this.serviceCharge === pcatProposal.serviceCharge &&
        this.stopCharge === pcatProposal.stopCharge &&
        this.surgeStopCharge === pcatProposal.surgeStopCharge &&
        this.perStopFuelSurcharge === pcatProposal.perStopFuelSurcharge &&
        this.packageCharge === pcatProposal.packageCharge &&
        this.largePackageMixCharge === pcatProposal.largePackageMixCharge &&
        this.apparelBrandCharge === pcatProposal.apparelBrandCharge &&
        this.vehicleBrandCharge === pcatProposal.vehicleBrandCharge &&
        this.eCommStopCharge === pcatProposal.eCommStopCharge &&
        this.eCommPackageCharge === pcatProposal.eCommPackageCharge &&
        this.advanceServiceCharge === pcatProposal.advanceServiceCharge &&
        this.nonECommStopGrowth === pcatProposal.nonECommStopGrowth &&
        this.nonECommPackageGrowth === pcatProposal.nonECommPackageGrowth &&
        this.eCommStopGrowth === pcatProposal.eCommStopGrowth &&
        this.eCommPackageGrowth === pcatProposal.eCommPackageGrowth &&
        this.overThresholdStops === pcatProposal.overThresholdStops;
    }


    nonECommPackagesPerStop(pcat) {
        const pacakgeMult = validateBig(this.nonECommPackageGrowth).div(new Big(100));
        const historicPackages = validateBig(pcat.nonECommPackages);
        const nonECommPackages = historicPackages.plus(historicPackages.times(pacakgeMult));
        const stopMult = validateBig(this.nonECommStopGrowth).div(new Big(100));
        const historicStops = validateBig(pcat.nonECommStops);
        const nonECommStops = historicStops.plus(historicStops.times(stopMult));
        if (nonECommStops.toNumber() == 0) {
            return 'N/A';
        } else {

            return nonECommPackages.div(nonECommStops).toNumber().toLocaleString();
        }
    }

    eCommPackagesPerStop(pcat) {
        const pacakgeMult = validateBig(this.eCommPackageGrowth).div(new Big(100));
        const historicPackages = validateBig(pcat.eCommPackages);
        const eCommPackages = historicPackages.plus(historicPackages.times(pacakgeMult));
        const stopMult = validateBig(this.eCommStopGrowth).div(new Big(100));
        const historicStops = validateBig(pcat.eCommStops);
        const eCommStops = historicStops.plus(historicStops.times(stopMult));
        if (eCommStops.toNumber() == 0) {
            return 'N/A';
        } else {
            return eCommPackages.div(eCommStops).toNumber().toLocaleString();
        }
    }

    totalNonECommStops(pcat) {
        const stopMult = validateBig(this.nonECommStopGrowth).div(new Big(100));
        const historicStops = validateBig(pcat.nonECommStops);
        const nonECommStops = historicStops.plus(historicStops.times(stopMult));
        return nonECommStops;
    }

    totalNonECommPackages(pcat) {
        const packageMult = validateBig(this.nonECommPackageGrowth).div(new Big(100));
        const historicPackages = validateBig(pcat.nonECommPackages);
        const nonECommPackages = historicPackages.plus(historicPackages.times(packageMult));
        return nonECommPackages;
    }

    totalECommStops(pcat) {
        const stopMult = validateBig(this.eCommStopGrowth).div(new Big(100));
        const historicStops = validateBig(pcat.eCommStops);
        const eCommStops = historicStops.plus(historicStops.times(stopMult));
        return eCommStops;
    }

    totalECommPackages(pcat) {
        const packageMult = validateBig(this.eCommPackageGrowth).div(new Big(100));
        const historicPackages = validateBig(pcat.eCommPackages);
        const eCommPackages = historicPackages.plus(historicPackages.times(packageMult));
        return eCommPackages;
    }

    // totalLargePackages(pcat) {
    //     const largePackageRatio = validateBig(pcat.largePackageMixDeliveryPercentage).div(new Big('100'));
    //     const largePackages = validateBig(pcat.totalDeliveryPackages).times(largePackageRatio).times(validateBig(this.nonECommPackageGrowth));
    //     return largePackages;
    // }

    totalDeliveryPackages(pcat) {
        const deliveryRatio = validateBig(pcat.deliveryPercentage).div(100);
        return this.totalNonECommPackages(pcat).plus(this.totalECommPackages(pcat)).times(deliveryRatio);
    }

    //Future Annual Revenue
    serviceRevenueAndAdvanceServiceChargeRevenue() {
        return validateBig(this.serviceCharge).times(new Big('52')).plus(validateBig(this.advanceServiceCharge));
    }

    nonECommStopRevenue(pcat) {
        return validateBig(this.stopCharge).times(this.totalNonECommStops(pcat));
    }

    surgeStopRevenue() {
        return validateBig(this.surgeStopCharge).times(validateBig(this.overThresholdStops));
    }

    fuelStopSurchargeRevenue(pcat) {
        return this.totalECommStops(pcat).plus(this.totalNonECommStops(pcat)).times(validateBig(this.perStopFuelSurcharge));
    }

    nonECommPackageRevenue(pcat) {
        return validateBig(this.packageCharge).times(this.totalNonECommPackages(pcat));
    }

    largePackageMixRevenue(pcat) {
        const largePackageRatio = validateBig(pcat.largePackageMixDeliveryPercentage).div(new Big('100'));
        const totalPackages = this.totalDeliveryPackages(pcat);
        const largePackages = totalPackages.times(largePackageRatio);
        return largePackages.times(validateBig(this.largePackageMixCharge));
    }

    apparelBrandPromotionRevenue() {
        return validateBig(this.apparelBrandCharge).times(new Big(52));
    }

    vehicleBrandPromotionRevenue() {
        return validateBig(this.brandVehicles).times(validateBig(this.vehicleBrandCharge)).times(new Big('52'));
    }

    eCommStopRevenue(pcat) {
        return validateBig(this.eCommStopCharge).times(this.totalECommStops(pcat));
    }

    eCommPackageRevenue(pcat) {
        return validateBig(this.eCommPackageCharge).times(this.totalECommPackages(pcat));
    }

    variableRevenue(pcat) {
        return this.nonECommStopRevenue(pcat)
            .plus(this.surgeStopRevenue())
            .plus(this.fuelStopSurchargeRevenue(pcat))
            .plus(this.nonECommPackageRevenue(pcat))
            .plus(this.largePackageMixRevenue(pcat))
            .plus(this.eCommStopRevenue(pcat))
            .plus(this.eCommPackageRevenue(pcat))
        ;
    }

    fixedAndOtherRevenue() {
        return this.serviceRevenueAndAdvanceServiceChargeRevenue()
            .plus(this.apparelBrandPromotionRevenue())
            .plus(this.vehicleBrandPromotionRevenue())
            //.plus(validateBig(this.advanceServiceCharge))
        ;
    }

    totalRevenue(pcat) {
        return this.variableRevenue(pcat).plus(this.fixedAndOtherRevenue());
    }

    variableRevenuePercentage(pcat) {
        const total = this.totalRevenue(pcat);
        const variable = this.variableRevenue(pcat);
        if (total.toNumber() == 0) {
            return 'N/A';
        }
        return variable.div(total).times(new Big('100')).toFixed(2) + '%';
    }

    fixedAndOtherRevenuePercentage(pcat) {
        const total = this.totalRevenue(pcat);
        const fixedAndOther = this.fixedAndOtherRevenue();
        if (total.toNumber() == 0) {
            return 'N/A';
        }
        return fixedAndOther.div(total).times(new Big('100')).toFixed(2) + '%';
    }


    getTotalExpenses() {
        if (!this.budget) {
            return 'N/A';
        }
        return usdFormatter.format(this.budget.totalYearlyExpenses().toNumber());

    }

    getProfit(pcat, index){
        if(!this.budget){
            return 'N/A';
        }



        const totalYearlyExpenses = this.budget.totalYearlyExpenses();
        const totalRevenue = this.totalRevenue(pcat);


        // const budgetWithAssumptions = this.budget.duplicate();
        // const newAssumptions = BudgetAssumptions.initFromPcat(pcat, index);
        // budgetWithAssumptions.budgetAssumptions = newAssumptions;

        return usdFormatter.format(totalRevenue.minus(totalYearlyExpenses).toNumber());
    }

    getMargin(pcat, index){
        if(!this.budget){
            return 'N/A';
        }

        // const budgetWithAssumptions = this.budget.duplicate();
        // const newAssumptions = BudgetAssumptions.initFromPcat(pcat, index);
        // budgetWithAssumptions.budgetAssumptions = newAssumptions;

        const totalYearlyExpenses = this.budget.totalYearlyExpenses();
        const totalRevenue = this.totalRevenue(pcat);
        if (totalRevenue.eq(0)) {
            return 'N/A';
        }
        const profit = totalRevenue.minus(totalYearlyExpenses);


        return toFixedMax(profit.div(totalRevenue).times(100).toNumber(), 3) + '%';
    } 

    getYearlyExpensesPerDispatch(pcat, index){
        if(!this.budget){
            return 'N/A';
        }

        const budgetWithAssumptions = this.budget.duplicate();
        const newAssumptions = BudgetAssumptions.initFromPcat(pcat, index);
        budgetWithAssumptions.budgetAssumptions = newAssumptions;

        return bigToUsd(budgetWithAssumptions.yearlyExpensesPerDispatch());
    }

    getYearlyRevenuePerDispatch(pcat, index){
        if(!this.budget){
            return 'N/A';
        }

        const budgetWithAssumptions = this.budget.duplicate();
        const newAssumptions = BudgetAssumptions.initFromPcat(pcat, index);
        budgetWithAssumptions.budgetAssumptions = newAssumptions;

        return bigToUsd(budgetWithAssumptions.yearlyRevenuePerDispatch());
    } 

}