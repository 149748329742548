/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { usdFormatter } from "../../../tools";
import { bigToUsd, validateInteger, validateDecimal, validateUsd } from "../payrollTools";

export default function PayrollPeriodEntryPayRow(pay) {
    const row = {};
    
    if (pay.payType === 'py') {row['Salary'] = usdFormatter.format(validateUsd(pay.payRate));}
    if (pay.payType === 'pd') {row['Daily Rate'] = usdFormatter.format(validateUsd(pay.payRate));}
    if (pay.payType === 'ph') {row['Hourly Rate'] = usdFormatter.format(validateUsd(pay.payRate));}
    if (pay.payType === 'ps') {row['Rate Per Stop'] = usdFormatter.format(validateUsd(pay.payRate));}
    if (pay.payType === 'pm') {row['Rate Per Mile'] = usdFormatter.format(validateUsd(pay.payRate));}
    if (pay.payType === 'xd') {row['Extra Day Rate'] = usdFormatter.format(validateUsd(pay.payRate));}
    if (pay.payType === 'pd') {row['Days Worked'] = validateDecimal(pay.unitsWorked);}
    if (pay.payType === 'ph') {row['Hours Worked'] = validateDecimal(pay.unitsWorked);}
    if (pay.payType === 'ps') {row['Stops'] = validateInteger(pay.unitsWorked);}
    if (pay.payType === 'pm') {row['Miles'] = validateDecimal(pay.unitsWorked);}
    if (pay.payType === 'xd') {row['Extra Days'] = validateDecimal(pay.unitsWorked);}
    if (pay.payType === 'py' || pay.payType === 'pd') {row['Regular Wages'] = bigToUsd(pay.getWages());}
    if (pay.payType === 'ph') {row['Hourly Wages'] = bigToUsd(pay.getWages());}
    if (pay.payType === 'ps') {row['Stop Wages'] = bigToUsd(pay.getWages());}
    if (pay.payType === 'pm') {row['Mile Wages'] = bigToUsd(pay.getWages());}
    if (pay.payType === 'xd') {row['Extra Day Wages'] = bigToUsd(pay.getWages());}
    
    return row;
}