/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import KeyValueRow from "../../components/KeyValueRow";
import { formatPhoneNumber, addressToString } from "../../tools";

export default function EContactOne({user, address}){
    return ( 
        <Card>
            <Card.Body style={{paddingBottom:30}}>
                <Card.Title style={{textAlign:'center', paddingBottom:10}}>Emergency Contact #1</Card.Title>
                <ListGroup>
                    <KeyValueRow isListItem title={'Emergency Contact #1 First Name'} value={user.eContact1FirstName}/>
                    <KeyValueRow isListItem title={'Emergency Contact #1 Last Name'} value={user.eContact1LastName}/>
                    <KeyValueRow isListItem title={'Emergency Contact #1 Phone Number'} value={user.eContact1PhoneNumber ? formatPhoneNumber(user.eContact1PhoneNumber) : ''}/>
                    <KeyValueRow isListItem title={'Emergency Contact #1 Email Address'} value={user.eContact1Email}/>
                    <KeyValueRow isListItem title={'Emergency Contact #1 Relationship'} value={user.eContact1Relationship}/>
                    <KeyValueRow isListItem title={'Emergency Contact #1 Address'} value={addressToString(address)}/>   
                </ListGroup>
            </Card.Body>
        </Card>
    )
}